import styled from "@emotion/styled";
import appConfig from "../../../config/config";

export type FontFamilies = "assistant" | "alt" | "base";

export type FontWeights = 100 | 200 | 300 | 400 | 500 | 600 | 700;

export interface ITextProps extends React.HTMLAttributes<HTMLElement> {
	size?: number | string;
	family?: FontFamilies;
	fontWeight?: FontWeights;
	color?: "primary" | "text" | "danger" | string;
	tag?: "div" | "span" | "h1" | "h2" | "h3";
	align?: "start" | "center" | "end";
	width?: number | string | "fit-content";
}

const Text = ({ tag = "div", align = "start", family = "base", fontWeight = 400, width = "fit-content", ...props }: ITextProps) => {
	// @ts-ignore
	const Element = styled[tag]({}, (props: ITextProps) => ({
		fontSize: props.size,
		// @ts-ignore
		fontFamily: family.split(".").reduce((o, i: string) => o[i], appConfig.style.fonts),
		fontWeight: fontWeight,
		textAlign: align,
		width,
		...(props.color
			? {
				color:
					props.color === "primary"
						? appConfig.style.colors.color1
						: props.color === "text"
							? appConfig.style.colors.color1
							: props.color === "danger"
								? appConfig.style.colors.danger
								: props.color,
			}
			: {}),
	}));
	return <Element {...props} />;
};

export default Text;
