import React, { useState } from 'react'
import PagedTable, { PagedTableKeys } from '../../../../Shared/Components/Table/PagedTable/PagedTable'
import pagedDrawerStyle from './PagedTableDrawer.module.css'
import { IC_CHEVRON_DOWN, IC_CHEVRON_UP } from '../../../../Assets'
import { trusteeGrantKeys } from '../../StaticData/tableTypes'
import { PagedTableReqParams } from '../../Types/ReqRes'
interface PagedTableDrawerProps<T> {
    tableData: T[]
    headers: PagedTableKeys<T>[]
    totalRows: number
    newDataReq: (params: PagedTableReqParams) => Promise<number | undefined>
    isOpen?: boolean
    header?: string
}

const PagedTableDrawer = <T extends Object>(props: PagedTableDrawerProps<T>) => {
    const [drawerOpen, setDrawerOpen] = useState(props.isOpen ?? false)

    const drawerHandler = () => {
        setDrawerOpen((prevValue) => !prevValue);
    };


    return (
        <div style={pagedDrawerStyle} className={pagedDrawerStyle.drawerContainer}>
            <div onClick={drawerHandler} className={pagedDrawerStyle.drawerHeader}>
                <h3>{props.header && props.header} </h3>
                <img src={drawerOpen ? IC_CHEVRON_UP : IC_CHEVRON_DOWN} />

                {/* {props.actionBtn && !drawerOpen && <AddButton qaid="" label={""} tooltip={props.actionBtn?.desc} onClick={props.actionBtn?.func} />} */}
            </div>

            {drawerOpen && (
                <div className={pagedDrawerStyle.drawerTable}>
                    <PagedTable
                        tableKeys={props.headers}
                        tableData={props.tableData}
                        totalRows={props.totalRows}
                        newDataReq={props.newDataReq}
                    />
                </div>
            )}

        </div>
    )
}

export default PagedTableDrawer