import { DragEvent, SyntheticEvent, forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { IC_DROP_FILE, IC_X_CANCEL_CIRCLE } from '../../../Assets'
import DropStyles from './DropFiles.module.css'

interface DropProps {
    onFileDropped: (file: File) => any
    clear?: () => boolean
}

const DropFiles = forwardRef((props: DropProps, ref) => {

    const [fileContained, setFileContained] = useState(false)
    const [fileName, setFileName] = useState<string>()

    useImperativeHandle(ref, () => ({
        clearAll() {
            console.log('Clearing...')
            setFileContained(false)
            setFileName(undefined)
        }
    }))

    const fileDropHandler = async (e: DragEvent) => {
        console.log('dropped', e)
        e.preventDefault()

        // console.log('data transfer file', e.dataTransfer.files[0])
        // console.log('data transfer item', e.dataTransfer.items[0])

        const file = e.dataTransfer.files[0]
        setFileName(file.name)
        props.onFileDropped(file)

        // const baFile = await file.arrayBuffer()
        // console.log('baFile', baFile)

        // const b64file = bufferToBase64(baFile)
        // console.log('b64file', b64file)
    }

    const fileEnterHandler = (e: any) => {
        // console.log('fileEnterHAndler e', e)
        setFileContained(true)
        e.stopPropagation()
    }

    const onFileDragOver = (e: any) => {
        // console.log('Stoping propogation!')
        e.stopPropagation()
        e.preventDefault()
    }

    const onFileSelect = (e: any) => {
        console.log('EEEEEEEEEE', e.target.files)
        const file = e.target.files[0]
        setFileContained(file)
        props.onFileDropped(file)
        setFileName(file.name)
    }

    const clearFile = () => {
        setFileContained(false)
        setFileName(undefined)
    }



    // props.clear
    // useEffect(() => {
    //     clearAll()
    // }, [props.clear])

    return (
        <div
            style={DropStyles}
            className={`${DropStyles.dropContainer} ${fileContained && DropStyles.drag}`}
            onDragEnter={fileEnterHandler}
            onDragOver={onFileDragOver}
            onDragLeave={() => setFileContained(false)}
            onDrop={fileDropHandler}
        >
            <div className={DropStyles.centerContainer}>
                {
                    fileName === undefined ? (
                        <>
                            <img src={IC_DROP_FILE} />
                            <div className={DropStyles.centerTxt}>
                                <p>Drag & Drop or</p>
                                <label>
                                    <p><span className={DropStyles.purple}>Click here</span> to upload</p>
                                    <input
                                        style={{ display: 'none' }}
                                        onChange={onFileSelect}

                                        type="file"
                                        name=""
                                        id=""
                                    />
                                </label>
                            </div>
                        </>
                    ) : (
                        <>
                            <p>{fileName}</p>
                            <img
                                src={IC_X_CANCEL_CIRCLE}
                                onClick={clearFile}
                                style={{ cursor: 'pointer' }}
                            />
                        </>
                    )
                }
            </div>
        </div>
    )
})

export default DropFiles