import { css } from "@emotion/css";
import appConfig from "../../../../config/config";

const GrantDetailsStyle = css({
    label: "GrantDetails",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: "7rem 8rem 4rem",
    height: "100%",
    overflow: "auto",
    "@media screen and (max-width: 768px)": {
        padding: "8rem 4rem 4rem",
    },
    ".type-selection": {
        marginBottom: "5rem",
    },
    "&__header": {
        display: "flex",
        marginBottom: "6rem",
        fontSize: "2.5rem",
        alignItems: "center",
        ".beneficiary-name": {
            marginLeft: "2.5rem",
            color: appConfig.style.colors.text1,
            fontWeight: 500,
        },
        ".grant-id": {
            marginLeft: "0.6rem",
            color: appConfig.style.colors.color1,
        },
    },
    "&__grant-details": {
        display: "flex",
        flexDirection: "column",
        ".grant-form": {
            display: "grid",
            gridTemplateColumns: "repeat(7, 1fr)",
            gridRowGap: "2rem",
            gridColumnGap: "1.2rem",
        },
    },
    "&__bs-parameters": {
        marginTop: "1.6rem",
        display: "flex",
        flexDirection: "column",
        ".bs-form": {
            display: "grid",
            gridTemplateColumns: "repeat(6, 1fr)",
            gridRowGap: "2rem",
            gridColumnGap: "1.2rem",
        },
    },
    ".section-title": {
        fontWeight: 500,
        width: "100%",
        borderBottom: `1px solid ${appConfig.style.colors.table}`,
        paddingBottom: "1rem",
        marginBottom: "2rem",
    },
});

export default GrantDetailsStyle;
