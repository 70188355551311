
import { useState } from 'react'
import { IC_WARNING_CIRCLE } from '../../../../../../Assets'
import Button from '../../../../../../Shared/Components/Button/Button'
import { ModalBodyProps } from '../../../../../../Shared/Components/Modal/types'
import useRootStore from '../../../../../../Shared/Hooks/useRootStore'
import { formatDate } from '../../../../../../Shared/Utilities'
import { OpenTerminationRequest } from '../../../../Types/trusteeTypes'
import TerminationTerminatioStyle from './TerminationRequestTermination.module.css'
import useTrusteeModals from '../../../../useTrusteeModals'

interface TerminationTerminationProps extends ModalBodyProps {
    data: OpenTerminationRequest
}

const TerminationRequestTermination = (props: TerminationTerminationProps) => {
    const { trusteeStore } = useRootStore()
    const { showSuccessModal } = useTrusteeModals()
    const [working, setWorking] = useState(false)


    const terminationTerminationHandler = async () => {
        setWorking(true)
        const res = await trusteeStore.PostTerminationTermiantion(props.data.requestId)
        setWorking(false)
        if (res.data?.status) {
            trusteeStore.GetTrusteeBeneficiariesByParams({ PageNumber: 1, ItemInPage: 5 })
            showSuccessModal('Termination request has been canceled.')
        }
        props.removeModal && props.removeModal()
    }

    const cancelHandler = () => {
        props.removeModal && props.removeModal()
    }

    return (
        <div style={TerminationTerminatioStyle} className={TerminationTerminatioStyle.container}>

            <div className={TerminationTerminatioStyle.header}>
                <img src={IC_WARNING_CIRCLE} alt="" />
                <h1>Are you sure?</h1>
                <p>Would you like to cancel the termination request that was submitted to trustee?</p>
            </div>

            <div className={TerminationTerminatioStyle.detailsContainer}>
                <div className={TerminationTerminatioStyle.detail}>
                    <h3>Date:</h3>
                    <p>{formatDate(props.data.requestDate)}</p>
                </div>
                <div className={TerminationTerminatioStyle.detail}>
                    <h3>Comments:</h3>
                    <p>{props.data.remarks}</p>
                </div>
            </div>

            <div className={TerminationTerminatioStyle.actions}>
                <Button
                    label='No'
                    qaid='terminationRequestTermination.noBtn'
                    onClick={cancelHandler}
                    cancel
                />
                <Button
                    label='Yes'
                    qaid='terminationRequestTermination.yesBtn'
                    onClick={terminationTerminationHandler}
                    isLoading={working}
                />
            </div>
        </div>
    )
}

export default TerminationRequestTermination