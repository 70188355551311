import styled from "@emotion/styled";

// const TOGGLE_BTN_HEIGHT = "1.8rem";
// const TOGGLE_BTN_WIDTH = "4rem";

export const Wrapper = styled.div`
  label: WithToggle;
  position: relative;
`;

export const ToggleBtn = styled.button`
  label: ToggleBtn;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
`;
