import { useEffect, useState } from "react";
import useRootStore from "./useRootStore";

function useScreenSize(targetWidth: number = 960) {
	const {
		appState: { fontSize },
	} = useRootStore();
	const [width, setWidth] = useState<number>(window.innerWidth);

	const handleScreenChange = () => {
		setWidth(window.innerWidth);
	};

	const clearListener = () => {
		window.removeEventListener("resize", handleScreenChange);
	};

	useEffect(() => {
		window.addEventListener("resize", handleScreenChange);
		return clearListener;
	}, []);

	return {
		isMobile: width < 601,
		isTablet: width >= 601 && width < 900,
		width,
		isAboveTarget: width >= targetWidth,
		clearListener,
		fontSize,
	};
}

export default useScreenSize;
