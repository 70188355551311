import { css } from '@emotion/css';
import { useRef, useState } from 'react';
import Button from '../../../Shared/Components/Button/Button';
import { Comment } from '../../../Shared/Components/Input/Input.Style';
import Separator from '../../../Shared/Components/Separator';
import { ForwardedRef, MultiStepElementProps } from '../../../Shared/Hooks/useMultiStepForm';
import useRootStore from '../../../Shared/Hooks/useRootStore';
import ComparablePeers from '../Components/Settings/ComparablePeers';
import { OnboardingStatus } from '../../../Models/API/Expensing/onboarding-status-enum';

const Style = css({
	label: 'ExpensingStartPage',
	display: 'flex',
	flexDirection: 'column',
	width: '100rem',
	padding: '6rem 8rem',
	'&__title': {
		fontWeight: 500,
		fontSize: '2.5rem',
		marginBottom: '5rem',
	},
	'&__next-btn': {
		marginTop: '0.8rem',
	},
});

const Peers = (props: MultiStepElementProps) => {
	const { expensingStore } = useRootStore();
	const [isError, setIsError] = useState<boolean>(false);
	const ref = useRef<ForwardedRef>(null);

	const onSubmitHandler = async () => {
		// to add validation
		setIsError(false);
		const isValidated = ref.current?.onValidate();
		if (!isValidated) return setIsError(true);

		const isUpdated = await expensingStore.setCompanyOnboardingStatus(OnboardingStatus.UnderlyingAssetPrice);
		isUpdated && props.onNextStep?.();
	};

	return (
		<div className={Style}>
			<div className={`${Style}__title`}> Expensing general settings</div>
			<ComparablePeers ref={ref} isInitial />

			{isError && (
				<Comment error absolute={false}>
					Total weight should sum to 100%
				</Comment>
			)}
			<Separator />
			<div className="flex justify-between">
				<Button className={`${Style}__next-btn`} qaid="ExpensingInitialSetup.Button.Back" label="Back" inverse cancel onClick={props.onPrevStep} />
				<Button className={`${Style}__next-btn`} qaid="ExpensingInitialSetup.Button.Next" label="Next" position="end" onClick={onSubmitHandler} />
			</div>
		</div>
	);
};

export default Peers;
