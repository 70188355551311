import classNames from "classnames";
import { IMG_LOGO_NAME } from "../../Assets";
import useScreenSize from "../../Shared/Hooks/useScreenSize";
import AccountResetPassword from "./Components/AccountForms/AccountResetPassword";
import { Link } from "react-router-dom";
import { Routes } from "../../Routes";

export const AccountResetPasswordScreen = () => {
	const { isMobile, isTablet } = useScreenSize();
	return (
		<section className="account-bg-login-page account-pages">
			<Link
				to={Routes.account.login}
				className={classNames("alt-logo", {
					tablet: isTablet,
					mobile: isMobile,
				})}
			>
				<img src={IMG_LOGO_NAME} alt="brand logo" />
			</Link>
			<div
				className={classNames("form-login-container", {
					mobile: isMobile,
					tablet: isTablet,
				})}
			>
				<AccountResetPassword />
			</div>
		</section>
	);
};

export default AccountResetPasswordScreen;
