interface LoaderProps {
	standalone?: boolean;
	className?: string;
}
// const Spinner = (props: LoaderProps) => {
//   return (
//     <div className={`loader ${props.className || ""}`}>
//       <div className="lds-dual-ring"></div>
//     </div>
//   );
// };
const Loader = (props: LoaderProps) => {
	const Spinner = () => {
		return (
			<div className={`loader ${props.className || ""}`}>
				<div className="lds-dual-ring"></div>
			</div>
		);
	};
	return props.standalone ? (
		<div className="loader-container">
			<Spinner />
		</div>
	) : (
		<Spinner />
	);
};

export default Loader;
