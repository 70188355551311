import styled from "@emotion/styled";
import { COLOR_BLUE_HAZE } from "../../../../config/style/lib/colors";
import { theme } from "../../../../config/style/theme";

export const StyledInputGroup = styled.div`
	label: InputGroup;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 1.4rem 2.5rem;
	min-height: 16rem;
	border: 1px solid ${COLOR_BLUE_HAZE};
	border-radius: ${theme.borderRadiuses.low};
	margin-top: ${theme.spacings.sm};

	.waterfall_step--preferences-terms & {
		min-height: 10rem;
	}

	.question-mark {
		margin-inline: 2.2rem;
	}
`;
