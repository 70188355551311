import { useEffect, useRef } from "react";
import { useState, useCallback } from "react";

// Adds optional attribute to type properties
// export type UnConcrete<Type> = {
//   [Property in keyof Type]+?: Type[Property];
// };

export type DirtyStatus<Type> = {
	[Property in keyof Type]+?: boolean;
};

export default function useComplexState<T extends object>(
	initialValue: T,
): [T, (newState: Partial<T>, replace?: boolean) => void, DirtyStatus<T>, boolean] {
	const [value, setValue] = useState<T>(initialValue);
	const [dirtyState, setDirtyState] = useState<DirtyStatus<T>>({});
	const [isModified, setIsModified] = useState<boolean>(false);

	//const initialValueRef = useRef(initialValue);
	// useEffect(() => {
	//     setValue(initialValue);

	// }, [initialValue])

	// function handleChange<K extends keyof T>(key: K, newValue: any) {
	//   let _value = { ...value } as T;
	//   _value[key] = newValue;
	//   setValue(_value);
	// }

	const setState = useCallback(
		(newState: Partial<T>, replace?: boolean) => {
			if (replace) {
				setValue(newState as any);
				setIsModified(false);
			} else {
				setValue((prevState) => ({
					...prevState,
					...newState,
				}));
				const _dirtyState = { ...dirtyState };
				for (var key in newState) {
					_dirtyState[key] = true;
				}

				setDirtyState(_dirtyState);
				setIsModified(true);
			}
		},
		[dirtyState],
	);

	return [value, setState, dirtyState, isModified];
}
