import { css } from "@emotion/css";
import appConfig from "../../../../../../config/config";

const NewVestStyles = css({
	display: "flex",
	flexDirection: "column",

	".subTitle": {
		color: appConfig.style.colors.background2,
		marginBottom: "1rem",
	},

	".stepsContainer": {
		height: "21rem",
		overflowY: "auto",
		paddingRight: "1rem",
	},

	".innerStepsContainer": {
		display: "flex",
		flexDirection: "column",
	},

	".input": {
		width: "18rem",
	},

	".wide-input": {
		width: "32rem",
	},

});
export default NewVestStyles;
