import { Fade } from "@mui/material";
import { ReactElement, useState } from "react";
import ProgressBarStyle from "./index.style";
import Button from "../Button/Button";
import Step from "./Step";
import classNames from "classnames";

interface IProgressBarProps {
    pageCaptions: string[];
    onStepClickHandler: (idx: number) => void;
    currentStepIdx: number;
    title?: ReactElement;
    lastCompletedIdx?: number;
    actions?: ReactElement;
    size?: "lg" | "md";
    isLastStepHighlighed?: boolean;
}

const ProgressBar = ({
    pageCaptions,
    onStepClickHandler,
    currentStepIdx,
    title,
    lastCompletedIdx = 0,
    actions,
    size = "lg",
    isLastStepHighlighed = false,
}: IProgressBarProps) => {
    const [showSteps, setShowSteps] = useState<boolean>(true);

    return (
        <div className={ProgressBarStyle}>
            {!!(title || actions) && (
                <div className={`${ProgressBarStyle}__header`}>
                    {!!title && <div className="title">{title}</div>}
                    {!!actions && <div className={`${ProgressBarStyle}__header-actions`}>{actions}</div>}
                </div>
            )}
            <Fade
                in={showSteps}
                timeout={300}
            >
                <div className={classNames(`${ProgressBarStyle}__steps-container`, size)}>
                    {pageCaptions.map((caption: string, idx: number) => (
                        <Step
                            isActive={currentStepIdx === idx}
                            isCompleted={idx <= lastCompletedIdx}
                            isLastStep={idx === pageCaptions.length - 1}
                            key={idx}
                            number={idx + 1}
                            caption={caption}
                            onClick={() => onStepClickHandler(idx)}
                            isLastStepHighlighed={isLastStepHighlighed}
                        />
                    ))}
                </div>
            </Fade>
            {/* <div
                className="toggle-header"
                onClick={() => setShowSteps((show) => !show)}
            >
                <i className={`arrow ${showSteps ? "up" : "down"}`}></i>
            </div> */}
        </div>
    );
};

export default ProgressBar;
