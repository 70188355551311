import { css } from "@emotion/css";
import { useEffect, useState } from "react";
import Clickable from "../../../Shared/Components/Clickable/Clickable";
import appConfig from "../../../config/config";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
// import MoreHorIcon from "@mui/icons-material/MoreHoriz";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Fade from "@mui/material/Fade";
import classNames from "classnames";
import { IC_TABLE_MENU_EYE } from "../../../Assets";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../Routes";
import useRootStore from "../../../Shared/Hooks/useRootStore";

const Styles = css({
	label: "Menu",
	position: "relative",
	marginLeft: "auto",
	"&__menu": {
		padding: "1.2rem",
		zIndex: 9999999,
		position: "absolute",
		top: "calc(100% + 1rem)",
		boxShadow: appConfig.style.boxShadow.card1,
		border: `1px solid ${appConfig.style.colors.background1}`,
		background: "#ffffff",
		display: "flex",
		gap: "2rem",
		right: "2rem",
		borderRadius: 8,
		width: "max-content",
		"&:not(.horizontal)": {
			flexDirection: "column",
			minWidth: "15rem",
		},
		"&.right": {
			right: "unset",
			left: "4rem",
			top: "-1.2rem",
		},
	},
	"&__menu-item": {
		background: "#ffffff",
		color: appConfig.style.colors.text4,
		img: {
			width: "2rem",
		},
	},
});

interface Props {
	items?: MenuItem[];
	isHorizontal?: boolean;
	autoClose?: () => boolean;
	right?: boolean;
}

export interface MenuItem {
	label: string;
	qaid: string;
	icon?: string;
	onClick?: () => void;
	hide?: boolean;
	toolTip?: string;
}


const TableAdditionalViews = ({ isHorizontal, autoClose, right }: Props) => {
	const history = useHistory();
	const { trusteeStore } = useRootStore()

	const [showMenu, setShowMenu] = useState<boolean>(false);


	const items: MenuItem[] = [
		{
			label: "Grant orders",
			qaid: "",
			onClick() {
				history.push(Routes.trusteeData.orders);
				trusteeStore.GetTrusteeOrders()
			},
		},
		{
			label: "Grant cancelations",
			qaid: "",
			onClick() {
				history.push(Routes.trusteeData.cancelations);
				trusteeStore.GetTrusteeCancelations()
			},
		},
	]
	useEffect(() => {
		const shouldClose = autoClose?.();
		if (!shouldClose) return;

		setShowMenu(false);
	}, [autoClose]);

	return (
		<div className={Styles}>
			<Clickable
				qaid="Button.Menu"
				// onClick={onDeleteShareholder}
				onClick={(e) => {
					e.stopPropagation();
					setShowMenu((val) => !val);
				}}
				height="100%"
			>
				{/* <Image sizes="" src={IC_ADDITIONAL_VIEWS} alt="additional views" tooltip="Additional Views" /> */}
				<img src={IC_TABLE_MENU_EYE} alt="additional views" style={{ width: "4.7rem" }} />
			</Clickable>
			<ClickAwayListener
				onClickAway={() => {
					// setTimeout(() => {
					setShowMenu(false);
					// }, 3000);
				}}
			>
				<Fade in={showMenu} timeout={100} unmountOnExit>
					<div className={classNames(`${Styles}__menu`, { horizontal: isHorizontal, right })}>
						{items
							.filter((itm) => !itm.hide)
							.map((item) => (
								<Clickable
									qaid={item.qaid}
									onClick={() => {
										item.onClick?.();
										setShowMenu(false);
									}}
									flex="0 0 2rem"
									height="100%"
									margin="0 0 0 auto"
									className={`${Styles}__menu-item`}
									gap="1.2rem"
									justify="start"
									key={item.label}
								>
									<>
										{!!item.icon && <img src={item.icon} alt="" width="100%" height="auto" />}
										{!isHorizontal && <span>{item.label}</span>}
									</>
								</Clickable>
							))}
					</div>
				</Fade>
			</ClickAwayListener>
		</div>
	);
};
export default TableAdditionalViews;
