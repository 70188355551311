import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { IC_WATERFALL_ANALYSIS } from '../../Assets';
import { Routes } from '../../Routes';

import Button from '../../Shared/Components/Button/Button';
import useRootStore from '../../Shared/Hooks/useRootStore';
import { WaterfallFormSteps } from '../../Store/WaterfallStore';
import { WaterfallIndexStyle } from './index.Style';
import { SummaryStatusCode } from './Components/AddEditWaterfall/Steps/Summary/deps/types';
import MainMenu from '../../Shared/Components/Waterfall/MainMenu';
import { WaterfallProject } from '../../Models/API/Waterfall/IForm';

const WaterfallScreen = () => {
	const history = useHistory();

	const {
		waterfallStore,
		companyStore: { companyId },
		appState,
	} = useRootStore();

	useEffect(() => {
		let interval: NodeJS.Timer;
		(async () => {
			const isProjectRunning = await getProjectsAndCheckIfRunning();
			if (!isProjectRunning) return;

			interval = setInterval(async () => {
				const isProjectRunning = await getProjectsAndCheckIfRunning();
				if (!isProjectRunning) clearInterval(interval);
			}, 6000);
		})();
		return () => {
			clearInterval(interval);
		};
	}, []);

	const getProjectsAndCheckIfRunning = async () => {
		const res = await waterfallStore.getProjects(companyId);
		return !!res.data?.some((proj) => proj.summaryStatus.status === SummaryStatusCode.RUNNING);
	};

	const onOpen = async (waterfall: WaterfallProject, step?: number) => {
		if (appState.isLoading) return;
		appState.isLoading = true;
		try {
			waterfallStore.setLastAllowedStep(waterfall.maxStep);
			const path = `${Routes.waterfall.index}/${waterfall.waterfallId}${
				step ? `/${step}` : waterfallStore.lastAllowedStep ? `/${waterfallStore.lastAllowedStep}` : ''
			}`;
			waterfallStore.setProjectName(waterfall.projectName);
			waterfallStore.isNewProject = false;
			// to check if owner
			waterfallStore.setProjectViewMode(!waterfall.isOwner);
			// setShowVersionsDialog(false);
			history.push(path);
		} catch (error: any) {
			console.log(error);
		}
		appState.isLoading = false;
	};

	return (
		<section>
			<MainMenu onOpen={onOpen} onDelete={waterfallStore.deleteProject} projects={waterfallStore.projects} />
			<div className={WaterfallIndexStyle}>
				<div className={`${WaterfallIndexStyle}__container`}>
					<img className="analysis-img" src={IC_WATERFALL_ANALYSIS} alt="" />
					<div className="analysis-text">Discover the optimal exit strategy for your company using Waterfall Modelling</div>
					<Button
						position="center"
						qaid="WaterfallScreen.Button.CreateNewAnalysis"
						label="waterfall.createNewAnalysis"
						onClick={() => {
							waterfallStore.setProjectViewMode(false);
							waterfallStore.isNewProject = true;
							history.push(`${Routes.waterfall.index}/0/${WaterfallFormSteps.scenarios}`);
						}}
						className="btn-create-analysis"
					/>
				</div>
			</div>
			{/* <Table
				className="Table--Waterfall"
				border={{ top: true, bottom: true, right: true }}
				headerSize={2.5}
				columns={tableColumns}
				rows={waterfallStore.waterfallProjects}
				fallbackText={error}
			/>

			<div className="flex justify-center">
			</div> */}
		</section>
	);
};

export default observer(WaterfallScreen);
