import { observer } from "mobx-react-lite";
import useRootStore from "../../../../../../Shared/Hooks/useRootStore";
import Input from "../../../../../../Shared/Components/Input/TextInput";
import NumInput from "../../../../../../Shared/Components/Input/NumberInput";
import DateInput from "../../../../../../Shared/Components/Input/DatePickerInput";
import { FormControl, FormControlLabel, Switch } from "@mui/material";
import Select from "../../../../../../Shared/Components/Select/Select";
import { useEffect, useState } from "react";
import Table, { TableColumn } from "../../../../../../Shared/Components/Table/Table";
import Button from "../../../../../../Shared/Components/Button/Button";
import NewPlanStyle from "./EditEquityPlan.style";
import { EquityPlan, IEquityPlan, IPlanVest, IPool, IVestingSchedule } from "../../../../../../Models/App/EquityPlans/Company";
import { toJS } from "mobx";
import { isNullOrUndefined } from "../../../../../../Shared/Utilities";
import NewVestingSchedule from "../../VestingSchedule/NewVestingSchedule/NewVestingSchedule";
import useModal from "../../../../../../Shared/Hooks/useModal";
import PoolStepManager from "../PoolStepManger/PoolManager";
import Flex from "../../../../../../Shared/Components/Layout/Flex";
import NewVestStyles from "../../VestingSchedule/NewVestingSchedule/NewVestingSchedule.style";

interface EditPlanProps {
	planId: number;
	plan?: IEquityPlan;
}
const EditEquityPlan = (props: EditPlanProps) => {
	const { equityPlansStore } = useRootStore();
	const { showModal } = useModal();

	const [poolAmount, setPoolAmount] = useState(0);
	const [aprvDate, setAprvDate] = useState<Date | string>();
	// const [vestsArr, setVestsArr] = useState<IPlanVest[]>();


	const plan = equityPlansStore.equityPlan?.plan;
	const pool = equityPlansStore.equityPlan?.poolSteps ?? [];



	const updatePlanHandler = async () => {
		await equityPlansStore.UpdateEquityPlan(props.planId);
		// if (!planId) throw new Error('Crate Plan Faild')
		// const mPool = createNewPool(planId)
		// await equityPlansStore.createPoolForPlan(mPool)
	};


	useEffect(() => {
		if (!pool[0]?.amount) {
			setPoolAmount(0)
			return;
		}
		const totalPool = pool?.map(pls => pls?.amount)?.reduce((acc, crr) => acc + crr)
		// const totalPool = pool?.[0].amount
		if (!totalPool) return
		setPoolAmount(totalPool)
	}, [pool])



	return (
		<div className={NewPlanStyle}>
			{/* <h3 className="mb-4">Plan Details</h3> */}
			<div className="details">

				<Flex gap={40}>
					<div className={`${NewVestStyles}__form`}>

						{/* Name */}
						<Input
							label="Plan Name"
							qaid="EditPlan.Input.PlanName"
							value={plan?.name}
							onChange={e => {
								if (!plan || !e) return
								plan.name = e
							}}
							isViewMode
						/>

						{/* Pool Amount */}
						<NumInput
							label="Pool Amount"
							qaid="EditPlan.Input.PoolAmount"
							value={poolAmount}
							onChange={(e) => setPoolAmount(e ?? 0)}
							isViewMode
						/>

						{/* Plan Board Date */}
						<DateInput
							label="Plan Board Approval Date"
							value={plan?.planApprovalDateByBoard}
							onChange={e => {
								if (!plan || !e) return
								plan.planApprovalDateByBoard = e
							}}
							qaid="EditPlan.Input.ApprovalDate"
							isViewMode
						/>

						{/* Pool Approval Date */}
						<DateInput
							label="Pool Board Approval Date"
							value={pool[0]?.stepDate}
							onChange={e => setAprvDate(e)}
							qaid="EditPlan.Input.PoolApprovalDate"
							isViewMode
						/>

						{/* Return To Pool */}
						<FormControlLabel
							sx={{ "& .MuiFormControlLabel-label": { fontSize: "1.2rem" } }}
							label={"Canceled aloocations return to pool"}
							control={
								<Switch
									data-qaid="EditPlan.CheckBox.CanceledReturnToPool"
									size="small"
									checked={plan?.isCancellationsReturnToPool}
									onChange={e => {
										if (!plan || !e) return
										plan.isCancellationsReturnToPool = e.target.checked
									}}
									color="secondary"
									disabled
								/>
							}
						/>

					</div>
					<Flex className="sswitches" direction="column" flex="1 0 200px" height="100%" justify="start">
						<Flex justify="between" flex={0}>
							<p>RSU</p>
							<Switch
								checked={plan?.allowRsu}
								onChange={(e) => {
									if (!plan || !e) return
									plan.allowRsu = e.target.checked
								}}
								color="secondary"
								data-qaid="EditPlan.CheckBox.AllowRSU"
								disabled
							/>
						</Flex>
						<Flex justify="between" flex={0}>
							<p>Stock Options</p>
							<Switch
								checked={plan?.allowSop}
								onChange={e => {
									if (!plan || !e) return
									plan.allowSop = e.target.checked
								}}
								color="secondary"
								data-qaid="EditPlan.CheckBox.AllowOptions"
								disabled
							/>
						</Flex>
					</Flex>
				</Flex>
			</div>



			{/* <Flex gap={8} align="center" className="mt-5" justify="center">
				<Button label="Cancel" cancel qaid="EditPlan.Button.Cancel" />
				<Button label="Update Plan Details" qaid="EditPlan.Button.Update" onClick={updatePlanHandler} />
			</Flex> */}
		</div>
	);
};
export default observer(EditEquityPlan);



// const mapVests = (vest: IVestingSchedule): IPlanVest => {
	// 	return {
	// 		companyId: 135,
	// 		isFixed: !vest.isManual,
	// 		planId: vest.planId,
	// 		vestingCompanyId: 0,
	// 		vestingId: vest.vestingId,
	// 	};
	// };

	// const addVestScg = (vestRow: IVestingSchedule) => {
	// 	if (isNullOrUndefined(vestRow)) return;
	// 	const mappedRow = mapVests(vestRow);
	// 	if (isNullOrUndefined(plan.vestings) || plan.vestings.length < 1) {
	// 		plan.vestings = [mappedRow];
	// 	}
	// 	const arr = plan.vestings;
	// 	arr.push(mappedRow);
	// 	plan.vestings = arr;
	// };

	// const removeVestScg = async (vestRow: IVestingSchedule) => {
	// 	if (isNullOrUndefined(vestRow)) return;
	// 	if (isNullOrUndefined(plan.vestings) || plan.vestings.length < 1) {
	// 		plan.vestings = [];
	// 	}

	// 	console.log("removing");
	// 	const arr = plan.vestings.filter((vst) => +vst.vestingId !== +vestRow.vestingId);
	// 	plan.vestings = arr;
	// };

	// const findVestSched = (id: number) => {
	// 	const planId = plan?.vestings?.filter((vst) => vst.planId === id);
	// };

	// const vestsColumns: TableColumn<IVestingSchedule>[] = [
	// 	{
	// 		label: "Use In This Plan",
	// 		defaultCellSize: 80,
	// 		name: "actions",
	// 		align: "center",
	// 		render: (row) => (
	// 			<>
	// 				<Switch
	// 					value={plan?.allowRsu}
	// 					onChange={(e) => {
	// 						e.target.checked ? addVestScg(row) : removeVestScg(row);
	// 					}}
	// 					size="small"
	// 				/>
	// 			</>
	// 		),
	// 	},
	// 	{ label: "Name", defaultCellSize: 200, name: "vestingName" },
	// 	{ label: "Description", defaultCellSize: 400, name: "description" },
	// 	// { label: '', name: "description" },

	// 	// { label: '', defaultCellSize: 100, name: 'menu', menuItems: [{ onClick: (row) => handleVestingModal(row.vestingId), label: 'Edit' }, { onClick: () => console.log('first'), label: 'Delete' }] }
	// ];