import styled from "@emotion/styled";
import { COLOR_TEXT_ACTION } from "../../lib/colors";
import { theme } from "../../theme";

const StyledInputLabel = styled.label`
	label: InputLabel;
	display: block;
	color: ${COLOR_TEXT_ACTION};
	font-size: 1.3rem; // TODO: pick a value from theme options
	margin-bottom: 0.4rem; // TODO: pick a value from theme options
	font-family: ${theme.fonts.main};
`;

export default StyledInputLabel;
