import { observer } from "mobx-react-lite";
import useRootStore from "../../../Shared/Hooks/useRootStore";

function TransactionDetails() {
    const {
        shareRepStore,
    } = useRootStore();

    return (
        <section>
            <div>tranie: { JSON.stringify(shareRepStore.info||{}) }</div>
            <div>subs: { JSON.stringify(shareRepStore.subs||{}) }</div>
            <div>considerations: { JSON.stringify(shareRepStore.considerations||{}) }</div>
            <div>docs: { JSON.stringify(shareRepStore.docs||{}) }</div>
            <div>notifications: { JSON.stringify(shareRepStore.notifications||{}) }</div>
        </section>)
}

export default observer(TransactionDetails)