import { css } from "@emotion/css";
import { useEffect, useState } from "react";
import { IC_TABLE } from "../../../Assets";
import { ExpectedTermMethodEnum } from "../../../Models/API/Expensing/expected-life-method-enum";
import { GrantCalculation } from "../../../Models/API/Expensing/grant-calculation";
import { OptionsPricing } from "../../../Models/API/Expensing/options-pricing";
import Button from "../../../Shared/Components/Button/Button";
import Image from "../../../Shared/Components/Image";
import NumberInput from "../../../Shared/Components/Input/NumberInput";
import Title from "../../../Shared/Components/Layout/Title";
import Table, { TableColumn } from "../../../Shared/Components/Table/Table";
import { currencies } from "../../../Shared/Config";
import useGeneralModal from "../../../Shared/Hooks/useGeneralModal";
import useRootStore from "../../../Shared/Hooks/useRootStore";
import { formatDate, formatDecimal, isNullOrUndefined } from "../../../Shared/Utilities";
import appConfig from "../../../config/config";
import BlackAndScholesStyle from "./index.style";
import { observer } from "mobx-react-lite";

const TableStyle = css({
    label: "TableStyle",
    "&__actions": {
        paddingBottom: "1.2rem",
        borderBottom: `1px solid ${appConfig.style.colors.table}`,
        marginBottom: "3rem",
        marginTop: 0,
    },
    ".selected-row": {
        color: appConfig.style.colors.color1,
        fontWeight: 700,
    },
});

const BlackAndScholes = () => {
    const { expensingStore } = useRootStore();
    const [grants, setGrants] = useState<GrantCalculation[]>();
    const [selectedIds, setSelectedIds] = useState<number[]>();
    const [editGrants, setEditGrants] = useState<OptionsPricing[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { showErrorModal } = useGeneralModal();

    useEffect(() => {
        loadGrants();
    }, []);

    const loadGrants = async () => {
        const res = await expensingStore.getPricingGrants();
        setGrants(res.data?.data);
    };

    const getCurrency = (id?: number) => currencies.find((c) => c.currencyId === id) || { symbol: "" };

    const onEditGrantHandler = (id: number, key: string | undefined, value: any) => {
        const idx = editGrants.findIndex((g) => g.entityAppId === id);
        if (idx === -1 || !key) return;

        setEditGrants((prevState) => [
            ...prevState.slice(0, idx),
            {
                ...prevState[idx],
                [key]: value,
            },
            ...prevState.slice(idx + 1),
        ]);
    };

    const tableColumn: TableColumn<GrantCalculation>[] = [
        {
            name: "grantNumber",
            label: "Grant number",
            sortable: true,
            isSearch: true,
        },
        {
            name: "beneficiaryName",
            label: "Beneficiary name",
            sortable: true,
            isSearch: true,
        },
        {
            name: "grantDate",
            label: "Grant date",
            format: (val) => formatDate(val),
            sortable: true,
            isSearch: true,
        },
        {
            name: "granted",
            label: "Amount granted",
            format: (val) => formatDecimal(val),
            sortable: true,
            isSearch: true,
        },
        {
            name: "exercisePrice",
            label: "Exercise price",
            format: (val, obj) => `${getCurrency(obj?.currency)?.symbol}${formatDecimal(val, { decimalLength: 3 })}`,
            sortable: true,
            isSearch: true,
        },
        {
            name: "marketPrice",
            label: "Fair market value",
            sortable: true,
            isSearch: true,
            render(obj, value) {
                const currency = getCurrency(obj?.currency);
                const data = editGrants.find((g) => g.entityAppId === obj.entityAppId);
                if (!selectedIds?.includes(obj.entityAppId) || !data)
                    return `${currency?.symbol}${formatDecimal(value, { decimalLength: 3 })}`;
                return (
                    <NumberInput
                        disabled={isLoading}
                        width="100%"
                        qaid=""
                        value={data.marketPrice ?? undefined}
                        name="marketPrice"
                        onChange={(value, key) => onEditGrantHandler(obj.entityAppId, key, value)}
                        number="float"
                        prependText={currency?.symbol}
                    />
                );
            },
        },
        {
            name: "expectedTerm",
            label: "Expected life",
            sortable: true,
            isSearch: true,
            render(obj, value) {
                const data = editGrants.find((g) => g.entityAppId === obj.entityAppId);
                if (!selectedIds?.includes(obj.entityAppId) || !data) return formatDecimal(value);
                return (
                    <NumberInput
                        disabled={isLoading}
                        width="100%"
                        qaid=""
                        value={data.expectedTerm ?? undefined}
                        name="expectedTerm"
                        onChange={(value, key) => onEditGrantHandler(obj.entityAppId, key, value)}
                        number="float"
                    />
                );
            },
        },
        {
            name: "volatility",
            label: "Volatility",
            sortable: true,
            isSearch: true,
            render(obj, value) {
                const data = editGrants.find((g) => g.entityAppId === obj.entityAppId);
                if (!data || !selectedIds?.includes(obj.entityAppId)) return formatDecimal(value * 100) + "%";
                return (
                    <NumberInput
                        disabled={isLoading}
                        width="100%"
                        qaid=""
                        value={data.volatility ? data.volatility * 100 : undefined}
                        name="volatility"
                        percentage
                        onChange={(value, key) => onEditGrantHandler(obj.entityAppId, key, value ? value / 100 : undefined)}
                        number="float"
                    />
                );
            },
        },
        {
            name: "riskFreeRate",
            label: "Risk free intrest",
            sortable: true,
            isSearch: true,
            render(obj, value) {
                const data = editGrants.find((g) => g.entityAppId === obj.entityAppId);
                if (!data || !selectedIds?.includes(obj.entityAppId)) return formatDecimal(value * 100) + "%";
                return (
                    <NumberInput
                        disabled={isLoading}
                        width="100%"
                        qaid=""
                        value={data.riskFreeRate ? data.riskFreeRate * 100 : undefined}
                        name="riskFreeRate"
                        percentage
                        onChange={(value, key) => onEditGrantHandler(obj.entityAppId, key, value ? value / 100 : undefined)}
                        number="float"
                    />
                );
            },
        },
        {
            name: "fairValue",
            label: "B&S value",
            sortable: true,
            isSearch: true,
            render(obj, value) {
                const currency = getCurrency(obj?.currency);
                const data = editGrants.find((g) => g.entityAppId === obj.entityAppId);
                if (!selectedIds?.includes(obj.entityAppId) || !data) return `${currency?.symbol}${formatDecimal(value)}`;
                return (
                    <NumberInput
                        disabled={isLoading}
                        width="100%"
                        qaid=""
                        value={data.fairValue ?? undefined}
                        name="fairValue"
                        onChange={(value, key) => onEditGrantHandler(obj.entityAppId, key, value)}
                        number="float"
                        prependText={currency?.symbol}
                    />
                );
            },
            className: "bold",
        },
    ];

    const onSaveHandler = async () => {
		const isNotValid = editGrants.some((g) => isNullOrUndefined(g.marketPrice));
		if (isNotValid) return showErrorModal('Data is missing in at least one grant, please try again.');

		setIsLoading(true);

		const res = await expensingStore.runBlackAndScholes(
			editGrants.map((g) => {
				const existedGrant = grants?.find((gr) => gr.entityAppId === g.entityAppId);
				return {
					...g,
					expectedTerm: g.expectedTerm || null,
					fairValue: g.fairValue === existedGrant?.fairValue ? null : g.fairValue || null,
					riskFreeRate: g.riskFreeRate || null,
					volatility: g.volatility || null,
				};
			})
		);

		setIsLoading(false);
		if (!res.isSuccess) return showErrorModal(res.errorMessage);
		// setGrants((prevGrants) => {
		//     return prevGrants?.map((g) => {
		//         const newGrant = res.data.data.find((gr) => gr.entityAppId === g.entityAppId);
		//         return newGrant
		//             ? ({
		//                   beneficiaryName: g.beneficiaryName,
		//                   currency: g.currency,
		//                   entityAppId: newGrant.entityAppId,
		//                   exercisePrice: newGrant.exercisePrice,
		//                   expectedTerm: newGrant.expectedTerm,
		//                   fairValue: newGrant.fairValue,
		//                   grantDate: g.grantDate,
		//                   granted: g.granted,
		//                   grantNumber: newGrant.grantNumber,
		//                   marketPrice: newGrant.marketPrice,
		//                   riskFreeRate: newGrant.riskFreeRate,
		//                   volatility: newGrant.volatility,
		//               } as GrantCalculation)
		//             : g;
		//     });
		// });
		setEditGrants([]);
		setSelectedIds([]);
	};

    return (
        <div className={BlackAndScholesStyle}>
            <Table
                rows={grants}
                columns={tableColumn}
                scrollAfterRows={15}
                rowSize={1.1}
                onRowSelect={(ids, selectedId, isAdd) => {
                    setSelectedIds(ids as number[]);

                    setEditGrants((state) => {
                        const grant = grants?.find((g) => g.entityAppId === selectedId);
                        if (!grant) return state;
                        return isAdd
                            ? [
                                  ...state,
                                  {
                                      entityAppId: grant.entityAppId,
                                      expectedTerm: grant.expectedTerm,
                                      expectedTermMethod: ExpectedTermMethodEnum.Manual,
                                      fairValue: grant.fairValue,
                                      forfeitureRatePricing: 0,
                                      marketPrice: grant.marketPrice,
                                      optionsPricingMethod: "BS",
                                      riskFreeRate: grant.riskFreeRate,
                                      volatility: grant.volatility,
                                  },
                              ]
                            : state.filter((g) => g.entityAppId !== selectedId);
                    });
                }}
                exportToExcel={{ fileName: "Altshare - B&S Calculations", sheetName: "B&S Parameters" }}
                className={TableStyle}
                selectBy="entityAppId"
                filterBy={["exercisePrice"]}
                rowClassName={(row) => (selectedIds?.includes(row.entityAppId) ? "selected-row" : "")}
                customHeaderRender={
                    <>
                        <Image
                            src={IC_TABLE}
                            width="5rem"
                        />
                        <Title>B&S calculation</Title>
                    </>
                }
            />

            <Button
                qaid="BS.Button.Save"
                label="Save"
                onClick={onSaveHandler}
                position="end"
                className="mt-4"
                disabled={!editGrants.length}
                isLoading={isLoading}
            />
        </div>
    );
};

export default observer(BlackAndScholes);
