import { useEffect, useState } from "react";
import useRootStore from "../../../../../../Shared/Hooks/useRootStore";
import Flex from "../../../../../../Shared/Components/Layout/Flex";
import OtpInput from "react-otp-input";
import { IMG_LOGIN_LOCK } from "../../../../../../Assets";
import classNames from "classnames";
import { inputClassName, Comment } from "../../../../../../Shared/Components/Input/Input.Style";
import { css } from "@emotion/css";
import { ModalBodyProps } from "../../../../../../Shared/Components/Modal/types";
import useModal from "../../../../../../Shared/Hooks/useModal";
import CompaniesList from "./CompaniesList";
import { IPivotalCompany } from "../../../../../../Models/App/AppUserModel";
import { isString } from "../../../../../../Shared/Utilities";

const Style = css({
	label: "PivotalOtp",
	"&__otp-container": {
		position: "relative",
		img: {
			width: "6rem",
		},
	},
	"&__otp-input": {
		height: "4rem",
		width: "4rem",
		flex: "0 0 4rem",
		borderWidth: 2,
		fontSize: "1.6rem",
	},
});

interface Props extends ModalBodyProps {
	onCompanySelect: (reCaptchaToken: string, company: IPivotalCompany) => Promise<boolean>;
	initialRegistrartion?: boolean
}

const PivotalOtp = ({ removeModal, onConfirmHandler, onCompanySelect, initialRegistrartion }: Props) => {
	const [otp, setOtp] = useState<string>("");
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>("");
	const { auth } = useRootStore();
	const { showModal } = useModal();

	useEffect(() => {
		setError("");
		if (otp.length < 6) return;
		setIsLoading(true);
		handleSubmit();
		return () => setOtp("");
	}, [otp]);

	const handleSubmit = async () => {
		const res = await auth.pivotalValidateLoginOtp(otp);

		if (isString(res)) {
			setError("Something went wrong, please try again");
			setIsLoading(false);
			return;
		}

		onConfirmHandler?.();
		return showModal({
			body: <CompaniesList companies={res} onCompanySelect={onCompanySelect} initialRegistrartion={initialRegistrartion} />,
			isMust: true,
			width: "60rem",
		});
	};

	return (
		<Flex direction="column" className={`${Style}__otp-container`}>
			<Flex align="center" gap="2rem" direction="column" margin="0 0 3rem 0" justify="center">
				<img src={IMG_LOGIN_LOCK} alt="one time passcode" />
				<span className="bold text-center">A one-time password has been sent to your business email</span>
			</Flex>
			<OtpInput
				value={otp}
				onChange={setOtp}
				numInputs={6}
				shouldAutoFocus
				containerStyle={{ justifyContent: "center", gap: "1.6rem" }}
				renderInput={(props) => (
					<input
						{...props}
						className={classNames(inputClassName, `${Style}__otp-input`)}
						data-qaid="PivotalLogin.Input.Otp"
						disabled={isLoading}
						type="number"
					/>
				)}
			/>
			<div style={{ position: "relative", width: "100%", marginTop: "2rem" }}>
				<Comment error center>
					{error}
				</Comment>
			</div>
		</Flex>
	);
};

export default PivotalOtp;
