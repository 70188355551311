import { observer } from "mobx-react-lite"
import useRootStore from "../../../Shared/Hooks/useRootStore";

function TransactionDocs() {
    const {
        shareRepStore,
    } = useRootStore();

    return (
        <div>

        </div>)
}

export default observer(TransactionDocs)