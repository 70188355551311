import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../Routes";
import Styled from "./Footer.style";

const Footer = () => {
    const history = useHistory();
    const { t } = useTranslation();

    const links = [
        {
            label: "footer.termsOfUse",
            link: Routes.tos,
        },
        {
            label: "footer.privacyPolicy",
            link: Routes.privacy,
        },
        {
            label: "footer.contactUs",
            link: Routes.contactUs.external,
        },
    ];

    return (
        <footer className={Styled}>
            <div className={`${Styled}__LinksContainer`}>
                {links.map((link) => (
                    <a
                        key={link.label}
                        className={`${Styled}__LinksContainer-Link`}
                        href={link.link}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {t(link.label)}
                    </a>
                ))}
            </div>
        </footer>
    );
};

export default Footer;
