import classNames from "classnames";
import { useEffect, useMemo, useRef, useState } from "react";
import { NumberOption } from "../../../../../Models/API/All/NumberOption";
import { Column as ColumnType } from "../../../../../Models/App/CapTable/ImportCapTable";
import CheckBox from "../../../../../Shared/Components/CheckBox/CheckBox";
import Select from "../../../../../Shared/Components/Select/Select";
import { formatNumber, isArray, isNullOrUndefined, isNumber } from "../../../../../Shared/Utilities";
import { CapTableImportColumnType } from "../../../types";
import { ImportColumn } from "./index.style";

export type ImportCapTableProps = {
    columns: ColumnType[];
    focus?: "all" | "header" | "border";
    onSelect?: (selected: number[]) => void;
    highlight?: CapTableImportColumnType | CapTableImportColumnType[];
    disabled?: CapTableImportColumnType | CapTableImportColumnType[];
    onChangeColumn?: (uniqueId: string, col: ColumnType) => void;
    showTotal?: boolean;
};

type Columns = { class: ColumnType[]; other: ColumnType[] };

const ImportCapTable = ({ columns, onSelect, focus, highlight, disabled, onChangeColumn, showTotal = false }: ImportCapTableProps) => {
    const [selected, setSelected] = useState<number[]>(columns[0]?.cells.map((cell, idx) => cell.referenceId));
    const [isClassScrollable, setIsClassScrollable] = useState<boolean>(false);
    const classRef = useRef<HTMLDivElement>(null);
    const classActionsRef = useRef<HTMLDivElement>(null);
    const otherRef = useRef<HTMLDivElement>(null);

    const getColsWithTotal = (cols: ColumnType[]) => {
        return cols.map((col: ColumnType) => {
            return {
                ...col,
                cells: [
                    ...col.cells,
                    {
                        value:
                            col.type !== CapTableImportColumnType.shareholders
                                ? col.cells.reduce((acc, cell) => acc + +cell.value, 0)
                                : "Total",
                        referenceId: col.cells.length + 3,
                    },
                ],
            } as ColumnType;
        });
    };

    const onScrollHandler = (e: any, isOther: boolean = false) => {
        if (!otherRef?.current || !classRef?.current) return;
        if (isOther) {
            classRef.current.scrollTop = e.target.scrollTop;
            return;
        }
        otherRef.current.scrollTop = e.target.scrollTop;
        if (classActionsRef.current) {
            classActionsRef.current.scrollLeft = e.target.scrollLeft;
        }
    };

    useEffect(() => {
        classRef.current?.addEventListener("scroll", onScrollHandler);
        otherRef.current?.addEventListener("scroll", (e) => onScrollHandler(e, true));
        return () => {
            classRef.current?.removeEventListener("scroll", onScrollHandler);
            otherRef.current?.removeEventListener("scroll", (e) => onScrollHandler(e, true));
        };
    }, []);

    const cols: Columns = (showTotal ? getColsWithTotal(columns) : columns).reduce(
        (acc, col) => {
            col.type === CapTableImportColumnType.class ||
            col.type === CapTableImportColumnType.warrants ||
            col.type === CapTableImportColumnType.none
                ? acc.class.push(col)
                : acc.other.push(col);
            return acc;
        },
        { class: [], other: [] } as Columns
    );

    useEffect(() => {
        if (isNullOrUndefined(classRef.current) || !cols.class.length) return;

        // setTimeout(() => {
        //     setColumns((prevData) => {
        //         if (!prevData) return prevData;

        //         return [
        //             ...prevData.slice(0, 5),
        //             {
        //                 ...prevData[5],
        //                 id: 2,
        //             },
        //             ...prevData.slice(6),
        //         ];
        //     });
        // }, 2000);

        setIsClassScrollable(classRef.current.offsetWidth < classRef.current?.scrollWidth);
    }, [classRef.current]);

    const isFull = !!cols.class.length;
    const isWarrantsExists = isFull && cols.class.some((cl) => cl.type === CapTableImportColumnType.warrants);

    const getClassType = (type: CapTableImportColumnType) => {
        switch (type) {
            case CapTableImportColumnType.class:
                return "Shares";
            case CapTableImportColumnType.bsa:
                return "BSA";
            case CapTableImportColumnType.warrants:
                return "Warrants";
            case CapTableImportColumnType.converted:
                return "Converted";
            default:
                return "";
        }
    };

    const classesList: NumberOption[] = useMemo(() => {
        return columns
            .filter(
                (col) =>
                    col.type === CapTableImportColumnType.class ||
                    col.type === CapTableImportColumnType.none ||
                    col.type === CapTableImportColumnType.warrants ||
                    col.type === CapTableImportColumnType.commonShares
            )
            .map((col) => ({ value: col.id, label: col.title }));
    }, [columns]);

    const typesList: NumberOption[] = [
        {
            value: CapTableImportColumnType.none,
            label: "Not a share class",
        },
        {
            value: CapTableImportColumnType.class,
            label: "Share class",
        },
        {
            value: CapTableImportColumnType.warrants,
            label: "Warrants",
        },
    ];

    return (
        <ImportColumn.FullContainer
            isWarrants={isWarrantsExists}
            isClass={isFull}
        >
            <ImportColumn.Table border={!cols.class.length}>
                <ImportColumn.Container
                    ref={otherRef}
                    style={{ borderTopLeftRadius: 16, borderBottomLeftRadius: 16, flexBasis: "content" }}
                    className={classNames({ "hidden-scroll": cols.class.length, warrants: isWarrantsExists })}
                    border={!!cols.class.length}
                >
                    {cols.other.map((column, colIdx) => (
                        <ImportColumn.Column
                            isLast={colIdx === cols.other.length - 1 && !cols.class.length}
                            isFirst={colIdx === 0}
                            key={column.uniqueId}
                            highlight={column.type === highlight}
                            focus={column.type === highlight ? focus : undefined}
                            style={{
                                minWidth: column.type === CapTableImportColumnType.shareholders && cols.class.length ? "32rem" : "",
                                borderRightWidth: colIdx === cols.other.length - 1 ? 0 : 2,
                            }}
                        >
                            <ImportColumn.Cell
                                headerSize={cols.class.length ? "lg" : "md"}
                                className={classNames("header", { disable: !!cols.class.length })}
                            >
                                {column.title}
                                {!!onSelect && (
                                    <span className="selection">
                                        {selected.length}/{column.cells.length}
                                    </span>
                                )}
                            </ImportColumn.Cell>
                            {column.cells?.map((cell, cellIdx) => (
                                <ImportColumn.Cell
                                    key={cellIdx}
                                    isLast={cellIdx === column.cells.length - 1}
                                    isTotal={showTotal && cellIdx === column.cells.length - 1}
                                    className={classNames({
                                        disable: isArray(disabled) ? disabled.some((t) => column.type === t) : disabled === column.type,
                                    })}
                                >
                                    {!!onSelect && colIdx === 0 ? (
                                        <CheckBox
                                            type="secondary"
                                            isChecked={selected.includes(cell.referenceId)}
                                            qaid="Table.CheckBox.SelectAll"
                                            label={
                                                cell.value.substring(0, 50 / cols.other.length) +
                                                (cell.value.length > 50 / cols.other.length ? "..." : "")
                                            }
                                            onClick={() => {
                                                setSelected((prevState) => {
                                                    const isChecked = prevState.some((id) => id === cell.referenceId);
                                                    const state = isChecked
                                                        ? prevState.filter((id) => id !== cell.referenceId)
                                                        : [...prevState, cell.referenceId];
                                                    onSelect?.(state);
                                                    return state;
                                                });
                                            }}
                                        />
                                    ) : column.type === CapTableImportColumnType.shareholders ? (
                                        cell.value.substring(0, 50 / cols.other.length) +
                                        (cell.value.length > 50 / cols.other.length ? "..." : "")
                                    ) : (
                                        formatNumber(cell.value, false)
                                    )}
                                </ImportColumn.Cell>
                            ))}
                        </ImportColumn.Column>
                    ))}
                </ImportColumn.Container>

                {!!cols.class.length && (
                    <ImportColumn.Container
                        ref={classRef}
                        style={{ borderTopLeftRadius: 16 }}
                        border
                    >
                        {cols.class.map((column, colIdx) => (
                            <ImportColumn.Column
                                isLast={colIdx === cols.class.length - 1}
                                isFirst={colIdx === 0}
                                key={column.uniqueId}
                                highlight={isArray(highlight) ? highlight.some((t) => column.type === t) : highlight === column.type}
                                focus={
                                    (isArray(highlight) ? highlight.some((t) => column.type === t) : highlight === column.type)
                                        ? focus
                                        : undefined
                                }
                                className={classNames({ shrink: colIdx === cols.class.length - 1 && isClassScrollable })}
                            >
                                {!!onChangeColumn && (
                                    <ImportColumn.Cell
                                        className={classNames("header", {
                                            multiline: column.type === CapTableImportColumnType.warrants || isWarrantsExists,
                                        })}
                                        headerSize={column.type === CapTableImportColumnType.warrants || isWarrantsExists ? "lg" : "sm"}
                                        style={{ zIndex: 4, justifyContent: "space-evenly" }}
                                    >
                                        <Select
                                            qaid=""
                                            options={typesList}
                                            value={column.type}
                                            onChange={(type) =>
                                                isNumber(type) &&
                                                column.uniqueId &&
                                                onChangeColumn(column.uniqueId, {
                                                    ...column,
                                                    cells: showTotal ? column.cells.slice(0, column.cells.length - 1) : column.cells,
                                                    type,
                                                })
                                            }
                                        />
                                        {column.type === CapTableImportColumnType.warrants && onChangeColumn ? (
                                            <Select
                                                qaid=""
                                                options={classesList}
                                                value={column.id}
                                                onChange={(id) =>
                                                    isNumber(id) &&
                                                    column.uniqueId &&
                                                    onChangeColumn(column.uniqueId, {
                                                        ...column,
                                                        cells: showTotal ? column.cells.slice(0, column.cells.length - 1) : column.cells,
                                                        id,
                                                    })
                                                }
                                            />
                                        ) : isWarrantsExists ? (
                                            <div style={{ height: "3.6rem" }}></div>
                                        ) : (
                                            <></>
                                        )}
                                    </ImportColumn.Cell>
                                )}
                                <ImportColumn.Cell
                                    headerSize="lg"
                                    className={classNames("header sub", {
                                        multiline:
                                            column.type === CapTableImportColumnType.class ||
                                            column.type === CapTableImportColumnType.warrants,
                                    })}
                                    style={isWarrantsExists ? { top: "10rem" } : {}}
                                >
                                    <span>{column.title}</span>
                                    <span>{getClassType(column.type)}</span>
                                </ImportColumn.Cell>
                                {column.cells?.map((cell, cellIdx) => (
                                    <ImportColumn.Cell
                                        key={cellIdx}
                                        isLast={cellIdx === column.cells.length - 1}
                                        isTotal={showTotal && cellIdx === column.cells.length - 1}
                                        className={classNames({
                                            disable: isArray(disabled) ? disabled.some((t) => column.type === t) : disabled === column.type,
                                            shrink: cellIdx === column.cells.length - 1 && isClassScrollable,
                                        })}
                                    >
                                        {!!onSelect && colIdx === 0 ? (
                                            <CheckBox
                                                type="secondary"
                                                isChecked={selected.includes(cell.referenceId)}
                                                qaid="Table.CheckBox.SelectAll"
                                                label={cell.value.substring(0, 50 / 1.5) + (cell.value.length > 50 / 1.5 ? "..." : "")}
                                                onClick={() => {
                                                    setSelected((prevState) => {
                                                        const isChecked = prevState.some((id) => id === cell.referenceId);
                                                        const state = isChecked
                                                            ? prevState.filter((id) => id !== cell.referenceId)
                                                            : [...prevState, cell.referenceId];
                                                        onSelect?.(state);
                                                        return state;
                                                    });
                                                }}
                                            />
                                        ) : column.type === CapTableImportColumnType.shareholders ? (
                                            cell.value.substring(0, 50 / 1.5) + (cell.value.length > 50 / 1.5 ? "..." : "")
                                        ) : (
                                            formatNumber(cell.value, false)
                                        )}
                                    </ImportColumn.Cell>
                                ))}
                            </ImportColumn.Column>
                        ))}
                    </ImportColumn.Container>
                )}
            </ImportColumn.Table>
        </ImportColumn.FullContainer>
    );
};

export default ImportCapTable;
