import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { ClickAwayListener } from "@mui/material";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Fade } from "react-bootstrap";
import Flex from "../../../Shared/Components/Layout/Flex";
import MainMenuStyle, { ActionsMenuStyle } from "./ActionsMenu.style";
import { MenuActionType } from "../../../Models/App/action-menu-type";

type Props = {
    actions: MenuActionType[];
};

const ActionsMenu = ({ actions }: Props) => {
    const [isActionsMenuOpen, setIsActionsMenuOpen] = useState<boolean>(false);

    return (
        <Flex
            flex={0}
            position="relative"
        >
            <ClickAwayListener onClickAway={() => setIsActionsMenuOpen(false)}>
                <div
                    className={classNames(`${MainMenuStyle}__btn-actions`, {
                        clicked: isActionsMenuOpen,
                    })}
                    onClick={() => setIsActionsMenuOpen((state) => !state)}
                    data-qaid="CapTable.Button.Actions"
                >
                    Actions <MoreHorizIcon />
                </div>
            </ClickAwayListener>
            <Fade
                in={isActionsMenuOpen}
                unmountOnExit
            >
                <div className={ActionsMenuStyle}>
                    {actions
                        .filter((action) => !action.disabled)
                        .map(({ label, icon, separator, background, qaid, onClick }) => (
                            <div
                                key={label}
                                className={classNames(`${ActionsMenuStyle}__item`, { separator })}
                                onClick={onClick}
                                data-qaid={qaid}
                            >
                                <img
                                    src={icon}
                                    alt={label}
                                />
                                {label}
                            </div>
                        ))}
                </div>
            </Fade>
        </Flex>
    );
};

export default observer(ActionsMenu);
