import { useState } from "react";
import { useHistory } from "react-router-dom";
import AddEditNewCompany from ".";
import { CompanyRegistration } from "../../../Models/API/Auth";
import Title from "../../../Shared/Components/Layout/Title";
import useRootStore from "../../../Shared/Hooks/useRootStore";
import AddEditNewCompanyStyle from "./index.style";
import { mapToAddressUpdate, mapToCompanyContactUpdate, mapToCompanyUpdate } from "../../../utils/mapper";
import { ModalBodyProps } from "../../../Shared/Components/Modal/types";

interface Props extends ModalBodyProps {
    // onSubmit: (data: CompanyRegistration) => Promise<void> | void;
    company: CompanyRegistration;
}

const EditCompany = ({ company, removeModal }: Props) => {
    const { companyStore } = useRootStore();

    const onSubmitHandler = async (companyInfo: CompanyRegistration) => {
        const companyId = companyStore.companyId;
        const addressId = companyStore.addresses[0].addressId;
        const companyContactId = companyStore.companyContactProfile.contactId;

        const companyUpdate = mapToCompanyUpdate(companyInfo);
        const addressUpdate = mapToAddressUpdate(companyInfo);
        const companyContactUpdate = mapToCompanyContactUpdate(companyInfo.contact);

        await Promise.all([
            companyStore.updateCompany(companyId, companyUpdate),
            companyStore.updateAddress(addressId, addressUpdate),
            companyStore.updateCompanyContact(companyId, companyContactId, companyContactUpdate),
        ]);
        removeModal?.();
    };

    return (
        <div className={AddEditNewCompanyStyle}>
            <Title className={`${AddEditNewCompanyStyle}__title`}>Edit Company</Title>
            <AddEditNewCompany
                onSubmit={onSubmitHandler}
                company={company}
            />
        </div>
    );
};

export default EditCompany;
