export const nationalities = [
	{ value: 1, label: "Afghan" },
	{ value: 2, label: "Albanian" },
	{ value: 3, label: "Algerian" },
	{ value: 4, label: "American" },
	{ value: 5, label: "Andorran" },
	{ value: 6, label: "Angolan" },
	{ value: 7, label: "Antiguans" },
	{ value: 8, label: "Argentinean" },
	{ value: 9, label: "Armenian" },
	{ value: 10, label: "Australian" },
	{ value: 11, label: "Austrian" },
	{ value: 12, label: "Azerbaijani" },
	{ value: 13, label: "Bahamian" },
	{ value: 14, label: "Bahrain" },
	{ value: 15, label: "Bangladeshi" },
	{ value: 16, label: "Barbadian" },
	{ value: 17, label: "Barbudans" },
	{ value: 18, label: "Batswana" },
	{ value: 19, label: "Belarusian" },
	{ value: 20, label: "Belgian" },
	{ value: 21, label: "Belizean" },
	{ value: 22, label: "Beninese" },
	{ value: 23, label: "Bhutanese" },
	{ value: 24, label: "Bolivian" },
	{ value: 25, label: "Bosnian" },
	{ value: 26, label: "Brazilian" },
	{ value: 27, label: "British" },
	{ value: 28, label: "Brunei" },
	{ value: 29, label: "Bulgarian" },
	{ value: 30, label: "Burkinabe" },
	{ value: 31, label: "Burmese" },
	{ value: 32, label: "Burundian" },
	{ value: 33, label: "Cambodian" },
	{ value: 34, label: "Cameroonian" },
	{ value: 35, label: "Canadian" },
	{ value: 36, label: "Cape Verdean" },
	{ value: 37, label: "Cayman Islands" },
	{ value: 38, label: "Central African" },
	{ value: 39, label: "Chadian" },
	{ value: 40, label: "Chilean" },
	{ value: 41, label: "Chinese" },
	{ value: 42, label: "Colombian" },
	{ value: 43, label: "Comoran" },
	{ value: 44, label: "Congolese" },
	{ value: 45, label: "Costa Rican" },
	{ value: 46, label: "Croatian" },
	{ value: 47, label: "Cuban" },
	{ value: 48, label: "Cypriot" },
	{ value: 49, label: "Czech" },
	{ value: 50, label: "Danish" },
	{ value: 51, label: "Djibouti" },
	{ value: 52, label: "Dominican" },
	{ value: 53, label: "Dutch" },
	{ value: 54, label: "East Timorese" },
	{ value: 55, label: "Ecuadorean" },
	{ value: 56, label: "Egyptian" },
	{ value: 57, label: "Emirian" },
	{ value: 58, label: "Equatorial Guinean" },
	{ value: 59, label: "Eritrean" },
	{ value: 60, label: "Estonian" },
	{ value: 61, label: "Ethiopian" },
	{ value: 62, label: "Fijian" },
	{ value: 63, label: "Filipino" },
	{ value: 64, label: "Finnish" },
	{ value: 65, label: "French" },
	{ value: 66, label: "Gabonese" },
	{ value: 67, label: "Gambian" },
	{ value: 68, label: "Georgian" },
	{ value: 69, label: "German" },
	{ value: 70, label: "Ghanaian" },
	{ value: 71, label: "Greek" },
	{ value: 72, label: "Grenadian" },
	{ value: 73, label: "Guatemalan" },
	{ value: 74, label: "Guinea-Bissauan" },
	{ value: 75, label: "Guinean" },
	{ value: 76, label: "Guyanese" },
	{ value: 77, label: "Haitian" },
	{ value: 78, label: "Herzegovinian" },
	{ value: 79, label: "Honduran" },
	{ value: 80, label: "Hungarian" },
	{ value: 81, label: "I-Kiribati" },
	{ value: 82, label: "Icelander" },
	{ value: 83, label: "Indian" },
	{ value: 84, label: "Indonesian" },
	{ value: 85, label: "Iranian" },
	{ value: 86, label: "Iraqi" },
	{ value: 87, label: "Irish" },
	{ value: 88, label: "Israeli" },
	{ value: 89, label: "Italian" },
	{ value: 90, label: "Ivorian" },
	{ value: 91, label: "Jamaican" },
	{ value: 92, label: "Japanese" },
	{ value: 93, label: "Jordanian" },
	{ value: 94, label: "Kazakhstani" },
	{ value: 95, label: "Kenyan" },
	{ value: 96, label: "Kittian and Nevisian" },
	{ value: 97, label: "Kuwait" },
	{ value: 98, label: "Kyrgyz" },
	{ value: 99, label: "Laotian" },
	{ value: 100, label: "Latvian" },
	{ value: 101, label: "Lebanese" },
	{ value: 102, label: "Liberian" },
	{ value: 103, label: "Libyan" },
	{ value: 104, label: "Liechtensteiner" },
	{ value: 105, label: "Lithuanian" },
	{ value: 106, label: "Luxembourger" },
	{ value: 107, label: "Macedonian" },
	{ value: 108, label: "Malagasy" },
	{ value: 109, label: "Malawian" },
	{ value: 110, label: "Malaysian" },
	{ value: 111, label: "Maldives" },
	{ value: 112, label: "Malian" },
	{ value: 113, label: "Maltese" },
	{ value: 114, label: "Marshallese" },
	{ value: 115, label: "Mauritanian" },
	{ value: 116, label: "Mauritian" },
	{ value: 117, label: "Mexican" },
	{ value: 118, label: "Micronesian" },
	{ value: 119, label: "Moldovan" },
	{ value: 120, label: "Monaco" },
	{ value: 121, label: "Mongolian" },
	{ value: 122, label: "Moroccan" },
	{ value: 123, label: "Mosotho" },
	{ value: 124, label: "Motswana" },
	{ value: 125, label: "Mozambican" },
	{ value: 126, label: "Namibian" },
	{ value: 127, label: "Nauru" },
	{ value: 128, label: "Nepalese" },
	{ value: 129, label: "New Zealander" },
	{ value: 130, label: "Nicaraguan" },
	{ value: 131, label: "Nigerian" },
	{ value: 132, label: "Nigerien" },
	{ value: 133, label: "North Korean" },
	{ value: 134, label: "Northern Irish" },
	{ value: 135, label: "Norwegian" },
	{ value: 136, label: "Oman" },
	{ value: 137, label: "Pakistan" },
	{ value: 138, label: "Palauan" },
	{ value: 139, label: "Panamanian" },
	{ value: 140, label: "Papua New Guinean" },
	{ value: 141, label: "Paraguayan" },
	{ value: 142, label: "Peruvian" },
	{ value: 143, label: "Polish" },
	{ value: 144, label: "Portuguese" },
	{ value: 145, label: "Qatar" },
	{ value: 146, label: "Romanian" },
	{ value: 147, label: "Russian" },
	{ value: 148, label: "Rwandan" },
	{ value: 149, label: "Saint Lucian" },
	{ value: 150, label: "Salvadoran" },
	{ value: 151, label: "Samoan" },
	{ value: 152, label: "San Marinese" },
	{ value: 153, label: "Saint Kitts And Nevis" },
	{ value: 154, label: "Sao Tomean" },
	{ value: 155, label: "Saudi" },
	{ value: 156, label: "Scottish" },
	{ value: 157, label: "Senegalese" },
	{ value: 158, label: "Serbian" },
	{ value: 159, label: "Seychellois" },
	{ value: 160, label: "Sierra Leonean" },
	{ value: 161, label: "Singaporean" },
	{ value: 162, label: "Slovakian" },
	{ value: 163, label: "Slovenian" },
	{ value: 164, label: "Solomon Islander" },
	{ value: 165, label: "Somalia" },
	{ value: 166, label: "South African" },
	{ value: 167, label: "South Korean" },
	{ value: 168, label: "Spanish" },
	{ value: 169, label: "Sri Lankan" },
	{ value: 170, label: "Sudanese" },
	{ value: 171, label: "Surinamer" },
	{ value: 172, label: "Swazi" },
	{ value: 173, label: "Swedish" },
	{ value: 174, label: "Swiss" },
	{ value: 175, label: "Syrian" },
	{ value: 176, label: "Taiwanese" },
	{ value: 177, label: "Tajik" },
	{ value: 178, label: "Tanzanian" },
	{ value: 179, label: "Thai" },
	{ value: 180, label: "Togolese" },
	{ value: 181, label: "Tongan" },
	{ value: 182, label: "Trinidadian or Tobagonian" },
	{ value: 183, label: "Tunisian" },
	{ value: 184, label: "Turkish" },
	{ value: 185, label: "Tuvaluan" },
	{ value: 186, label: "Ugandan" },
	{ value: 187, label: "Ukrainian" },
	{ value: 188, label: "United Arab Emirates" },
	{ value: 189, label: "Uruguayan" },
	{ value: 190, label: "Uzbekistani" },
	{ value: 191, label: "Vanuatu" },
	{ value: 192, label: "Venezuelan" },
	{ value: 193, label: "Vietnamese" },
	{ value: 194, label: "Welsh" },
	{ value: 195, label: "Western Sahara" },
	{ value: 196, label: "Yemenite" },
	{ value: 197, label: "Zambian" },
	{ value: 198, label: "Zimbabwean" },
];
