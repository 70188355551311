/* eslint-disable jsx-a11y/iframe-has-title */
import { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { IC_LIST_ITEM, IMG_G2, IMG_INVERTED, IMG_LOGO_NAME } from "../../Assets";
import useRootStore from "../../Shared/Hooks/useRootStore";
import AccountCreateFirstPhase from "./Components/AccountForms/AccountCreateFirstPhase";
import { BrowserView } from "react-device-detect";
import classNames from "classnames";
import useScreenSize from "../../Shared/Hooks/useScreenSize";
import { Routes } from "../../Routes";
import { AnimatePresence, motion } from "framer-motion";

export const AccountCreateFirstPhaseScreen = () => {
	const contentRef = useRef<HTMLDivElement>(null);
	const history = useHistory();
	const { isMobile, isTablet } = useScreenSize();
	const [isPageVisible, setIsPageVisible] = useState<boolean>(true);
	const [isPageMounted, setIsPageMounted] = useState<boolean>(true);

	const navToAccountLogin = () => {
		setIsPageVisible(false);

		setTimeout(() => {
			history.push(Routes.account.login);
			setIsPageMounted(false);
		}, 500);
	};

	const target = {
		newTab: "_blank",
		currentWindow: "_self",
	};
	const whereToOpen = target.newTab;
	const link = "https://www.g2.com/products/altshare/reviews";

	const interval = setInterval(() => {
		const reviewsBage = document.querySelector("[class^='BadgeContainer__Inner']");

		if (!!reviewsBage) {
			clearInterval(interval);
			reviewsBage.addEventListener("click", (e) => {
				e.preventDefault();
				e.stopPropagation();
				window.open(link, whereToOpen);
			});
		}
	}, 250);

	return (
		<AnimatePresence>
			{isPageVisible && (
				<motion.section
					initial={{ opacity: 0 }}
					animate={{ opacity: 1, transition: { duration: 0.5 } }}
					exit={{ opacity: 0 }}
					className={classNames("account-pages", {
						tablet: isTablet,
						mobile: isMobile,
					})}
				>
					<div
						className={classNames({
							"account-reg-container": true,
							mobile: isMobile,
						})}
					>
						<div
							className={classNames({
								"account-page-content": true,
								mobile: isMobile,
								hidden: isTablet,
							})}
							ref={contentRef}
						>
							<Link
								to={Routes.account.login}
								className={classNames("alt-logo", {
									tablet: isTablet,
									mobile: isMobile,
								})}
							>
								<img src={IMG_LOGO_NAME} alt="brand logo" />
							</Link>
							<div className="title-join">Join The Global Revolution With altshare</div>
							<div className="title-promo">Making equity management easy for everyone</div>
							<ul className="list-promo">
								<li>
									<img src={IC_LIST_ITEM} alt="Note" />
									Automated <span className="promo--bold"> cap table</span> - free up to 25 stakeholders
								</li>
								<li>
									{" "}
									<img src={IC_LIST_ITEM} alt="Note" />
									Advanced <span className="promo--bold">funding scenario modeling</span>
								</li>
								<li>
									{" "}
									<img src={IC_LIST_ITEM} alt="Note" />
									Accurate <span className="promo--bold">waterfall</span> calculations
								</li>
								<li>
									{" "}
									<img src={IC_LIST_ITEM} alt="Note" />A comprehensive <span className="promo--bold">equity plans</span> management
								</li>
							</ul>
						</div>

						<img
							className={classNames({
								"img-inverted": true,
								tablet: isTablet,
								mobile: isMobile,
							})}
							src={IMG_INVERTED}
							alt=""
						/>

						<AccountCreateFirstPhase navToAccountLogin={navToAccountLogin} />
						{!isTablet && !isMobile && <div style={{ bottom: 0 }} className="elfsight-app-da56f90d-8151-4f40-bc92-ebe39768545c g2-widget"></div>}
					</div>
				</motion.section>
			)}
		</AnimatePresence>
	);
};

export default AccountCreateFirstPhaseScreen;
