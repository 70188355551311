import { css } from "@emotion/css";
import appConfig from "../../../config/config";
import { breakpoints } from "../../../config/style/theme";

export const equityDashboardStyle = css({
	label: 'EquityDashboard',
	display: 'flex',
	flexDirection: 'column',
	height: '100%',

	'&__vestTable': {
		width: '100%',

		'> h3': {
			fontSize: '1.4rem',
			margin: '1.8rem 4rem',
		},

		'&__table': {
			borderRadius: '0px',

			"div[data-row-type='table-container']": {
				borderRadius: '0px',
				'> div': {
					borderRadius: '0px',
				},
			},
		},
	},

	'&__dashboard': {
		display: 'grid',
		padding: '1.6rem 3.8rem',
		background: appConfig.style.colors.background1,
		gridTemplateColumns: 'repeat(9, 1fr)',
		gridTemplateRows: 'repeat(2, 1fr)',
		gridGap: '2.4rem',

		'.ChartWidget': {
			boxShadow: 'none',
			padding: '0px',
		},

		'.ChartWidgetBody': {
			justifyContent: 'space-between',
			width: '100%',
		},

		'.ChartLegend': {
			margin: '0px',
			width: '27rem',
		},

		'.CircularSwitchToggle': {
			display: 'flex',
			alignItems: 'center',
			width: '100%',
			justifyContent: 'space-between',

			'> span': {
				fontSize: '1.8rem',
				fontWeight: 400,
				marginLeft: '10px',
				width: '27rem',
			},
		},

		'.divider': {
			width: '100%',
			height: '1px',
			backgroundColor: '#ACB2CC',
			margin: '1.5rem',
		},

		'.card1': {
			gridArea: '1/1/3/4',
			minWidth: '45rem',
		},

		'.card2': {
			gridArea: '1/6/3/4',
			display: 'flex',
		},

		'.card3': {
			gridArea: '1/6/3/10',
			padding: '0px',
		},
	},
	'&__table-container': {
		padding: '4rem',
	},
});
