import classNames from "classnames";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { checkQaidConvention } from "../../Utilities";
import Select from "../Select/Select";
// import Tooltip from "../Tooltip/Tooltip";
import Styles, { Comment } from "./Input.Style";
import { BasicInputProps } from "../../../Models/App/Inputs/types";
import { Fade, Tooltip } from "@mui/material";
import Image from "../Image";
import { IC_QUESTION_MARK_ORANGE, IC_QUESTION_MARK_ORANGE_FILL } from "../../../Assets";

// export type InputOnChangeEvent = {
//   name?: string;
//   value?: string | number | Date;
// };

// type NumberType = "float" | "int";

// interface DecimalObjectProps extends DecimalFormatProps {
//   type: NumberType;
// }

const InputWrapper = forwardRef<HTMLDivElement, BasicInputProps>(
    (
        {
            label,
            error,
            info,
            comment,
            forceValidation,
            isLoading,
            inverse,
            containerStyle,
            containerClassName,
            qaid,
            inputWidth,
            multilineLabel,
            onOptionSelect,
            options,
            selectedValue,
            endIcon,
            flat,
            margin = true,
            isViewMode,
            relative,
            prefix,
            ...props
        },
        ref
    ) => {
        checkQaidConvention(qaid);
        const { t } = useTranslation();

        return (
            <div
                className={classNames(
                    Styles,
                    containerClassName,
                    { flat },
                    {
                        "mt-0 mb-0": !margin,
                    }
                )}
                ref={ref}
                style={{
                    ...containerStyle,
                    width: isViewMode && info ? "fit-content" : isViewMode ? "100%" : props.width,
                    position: comment || error ? "relative" : "unset",
                    minWidth: props.width ? "auto" : "10rem",
                }}
            >
                <label
                    className="label-container"
                    htmlFor=""
                >
                    {(label || info) && (
                        <div className="label">
                            {!!label && (
                                <span className={classNames({ "text-ellipsis": !multilineLabel })}>
                                    {t(label)} {props.required ? "*" : ""}
                                </span>
                            )}
                            {!!info && (
                                <Image
                                    className={classNames("label__info", { "ml-2": isViewMode })}
                                    src={IC_QUESTION_MARK_ORANGE}
                                    srcHover={IC_QUESTION_MARK_ORANGE_FILL}
                                    tooltip={t(info)}
                                    pointer
                                />
                            )}
                        </div>
                    )}
                    <div
                        className={classNames(`${Styles}__inputContainer`, {
                            relative: endIcon || relative || options || prefix,
                            "with-padding": endIcon,
                            "with-padding-left": prefix,
                            error: !!(error && flat),
                        })}
                        style={{
                            width: inputWidth || "inherit",
                            // position: endIcon ? "relative" : "unset",
                        }}
                    >
                        {isViewMode ? (
                            <>{selectedValue}</>
                        ) : (
                            !!onOptionSelect &&
                            !!options && (
                                <Select
                                    className="merged-select"
                                    value={selectedValue}
                                    qaid={qaid.replace("Input", "Select")}
                                    options={options}
                                    onChange={onOptionSelect}
                                    isStandalone
                                    disabled={props.disabled}
                                />
                            )
                        )}
                        {props.children}
                        {endIcon && (
                            <img
                                src={endIcon}
                                alt="lookup"
                                className={`${Styles}__end-icon`}
                            />
                        )}
                        {!!prefix && <span className={`${Styles}__prefix`}>{prefix}</span>}
                    </div>
                    <Fade in={!isViewMode && !props.disabled && !!error && !flat}>
                        <Comment error>{t(error || "")}</Comment>
                    </Fade>
                    {!isViewMode && !props.disabled && <>{!error && comment && <Comment>{t(comment)}</Comment>}</>}
                </label>
            </div>
        );
    }
);

export default InputWrapper;
