import { IMG_LOGO_NAME } from "../../Assets";
import useScreenSize from "../../Shared/Hooks/useScreenSize";
import AccountCreateSecondPhase from "./Components/AccountForms/AccountCreateSecondPhase";
import classNames from "classnames";
import { BrowserView } from "react-device-detect";

export const AccountCreateSecondPhaseScreen = () => {
	const { isMobile, isTablet } = useScreenSize();

	return (
		<>
			<section
				className={classNames({
					"account-bg-setup-page": true,
					"account-pages": true,
					mobile: isMobile,
				})}
			>
				<div
					className={classNames({
						"account-reg-container": true,
						mobile: isMobile,
					})}
				>
					<BrowserView>
						<div
							className={classNames({
								"account-page-content": true,
								mobile: isMobile,
								fadeIn: true && !isMobile,
							})}
						>
							<div
								className={classNames("alt-logo", {
									hidden: isTablet,
								})}
							>
								<img src={IMG_LOGO_NAME} alt="brand logo" />
							</div>
							<div
								className={classNames({
									"title-join": true,
									hidden: isTablet,
								})}
							>
								<div>The Way Founders </div>
								<div>Do Equity</div>
							</div>
						</div>
					</BrowserView>
					<AccountCreateSecondPhase />
				</div>
			</section>
		</>
	);
};

export default AccountCreateSecondPhaseScreen;
