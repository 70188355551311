import { css } from "@emotion/css";

const EntityFactoryStyle = css({
	label: "EntityFactory",
	display: "flex",
	flexDirection: "column",
	height: "25rem",
	width: "100%",

	".registrationContainer": {
		display: "grid",
		gridTemplateColumns: "1fr 1fr 1fr",
		gridRowGap: "5rem",
		gridColumnGap: "2.5rem",
	},
});

export default EntityFactoryStyle;
