import { useEffect } from "react";
import { IMG_LOGO_NAME } from "../../Assets";
import classNames from "classnames";
import useRootStore from "../../Shared/Hooks/useRootStore";
import useScreenSize from "../../Shared/Hooks/useScreenSize";
import { UserStatus } from "../../Models/API/enums";

export const AccountCompleteRegistration = () => {
	const { auth } = useRootStore();
	const { isMobile } = useScreenSize();

	useEffect(() => {
		const updateStatus = async () => {
			const res = await auth.updateUserStatus(UserStatus.PendingForPaymentPlan);

			if (res.isSuccess) {
				setTimeout(async () => {
					auth.handleUserData(UserStatus.PendingForPaymentPlan);
				}, 3000);
			}
		};

		updateStatus();
	}, []);

	return (
		<section
			className={classNames({
				"account-bg-reg-success-page": true,
				"account-pages": true,
				mobile: isMobile,
			})}
		>
			<div className="account-reg-container">
				<div className="account-page-content">
					<div className={classNames("alt-logo", { hidden: isMobile })}>
						<img src={IMG_LOGO_NAME} alt="brand logo" />
					</div>
				</div>
				<div
					className={classNames("success-msg-box", {
						mobile: isMobile,
					})}
				>
					<div
						className={classNames("hello-user", {
							mobile: isMobile,
						})}
					>
						Hello {auth.userInfo.firstName ?? ""}!
					</div>
					<div className={classNames("welcome-altshare", { mobile: isMobile })}>Welcome to altshare</div>
				</div>
			</div>
		</section>
	);
};

export default AccountCompleteRegistration;
