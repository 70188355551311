import { css } from "@emotion/css";
import appConfig from "../../../../../config/config";

export const GrantDetailsStyle = css({

    label: "GrantCard",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-between",

    "&.standalone": {
        padding: "7rem 8rem 4rem",
        height: "100%",
        overflow: "auto",
    },
    "&__main-section": {
        width: "100%",
        // boxShadow: appConfig.style.boxShadow.card1,
    },
    "&__inputs-container": {
        display: "grid",
        gridTemplateColumns: "repeat(6, 1fr)",
        gridColumnGap: "2.4rem",
        gridRowGap: "3.4rem",
        border: `1px solid ${appConfig.style.colors.table}`,
        borderRadius: 16,
        padding: "3.6rem 4.4rem",
    },
    "&__add-ben-btn": {
        alignSelf: "flex-end",
        marginBottom: "0.4rem",
    },
    "&__title": {
        fontSize: "2.5rem",
        marginBottom: "4.8rem",
        fontWeight: 500,
    },
    "&__title-container": {
        display: "flex",
        alignItems: "center",
        gap: "2rem",
        textTransform: "capitalize",
        ".img-sh-type": {
            width: "5.4rem",
        },
        marginBottom: "2.4rem",
    },

    // label: "GrantDetails",
    // display: "flex",
    // flexDirection: "column",
    // flex: 1,
    // overflow: 'visible',
    // // "boxShadow": appConfig.style.boxShadow.card1,

    // "&__title-container": {
    //     display: "flex",
    //     alignItems: "center",
    //     gap: "2rem",
    //     textTransform: "capitalize",
    //     ".img-sh-type": {
    //         width: "5.4rem",
    //     },
    //     marginBottom: "2.4rem",
    // },

    // "&__details-section": {
    //     display: "flex",
    //     flexDirection: "row",
    //     border: `1px solid ${appConfig.style.colors.table}`,
    //     borderRadius: 16,
    //     // margin: "14px",
    // },

    // "&__ben-container": {
    //     display: "flex",
    //     flexDirection: "column",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     alignSelf: "flex-start",
    //     padding: "3.2rem",
    //     flex: "0 0 22rem",
    //     img: {
    //         marginBottom: "2.8rem",
    //         width: "6.4rem",
    //         height: "6.4rem",
    //     },
    //     // "& img": {
    //     // 	margin: "4rem",
    //     // 	width: "8rem",
    //     // },
    //     ".info": {
    //         display: "flex",
    //         flexDirection: "column",
    //         alignSelf: "flex-start",
    //         ".label": {
    //             color: appConfig.style.colors.text2,
    //             marginBottom: "1.6rem",
    //         },
    //         "&.mb": {
    //             marginBottom: "4.8rem",
    //         },
    //     },
    // },

    // "&__grant-details": {
    //     display: "flex",
    //     flexDirection: "column",
    //     padding: "3.6rem 4.4rem",
    //     overflow: "hidden",
    //     // borderLeft: `1px solid ${appConfig.style.colors.table}`,
    //     width: "100%",
    //     ".grant-title": {
    //         marginBottom: "4rem",
    //     },
    // },

    // ".grant-inputs-container": {
    //     display: "grid",
    //     gridTemplateColumns: "repeat(6, 1fr)",
    //     width: "100%",
    //     gridColumnGap: "2.4rem",
    //     // marginTop: "2.4rem",
    //     gridRowGap: "4.8rem",
    //     overflow: "auto",
    //     // paddingBottom: "2rem",
    // },

    // ".column": {
    //     display: "flex",
    //     flexDirection: "row",
    //     margin: "14px",
    // },

    // ".input-row": {
    //     display: "flex",
    //     flexDirection: "row",
    // },
});

// export const MinGrantDetailsStyle = css({
// 	"label": "GrantCardExpand",
// 	"display": "flex",
// 	"flexDirection": "column",
// 	"border": "1px solid black",
// 	"borderRadius": "2px",

// 	".grant-data-container": {
// 		width: "100%",
// 	},

// 	".column": {
// 		display: "flex",
// 		flexDirection: "row",
// 		margin: "14px",
// 	},

// 	".input-row": {
// 		display: "flex",
// 		flexDirection: "row",
// 	},

// 	".input": {
// 		margin: "14px",
// 		width: "18rem",
// 	},
// });

// export default GrantDetailsStyles;
