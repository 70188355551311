import { css } from '@emotion/css';
import { useEffect, useRef, useState } from 'react';
import { UnderlyingAssetPrice } from '../../../../../Models/API/Expensing/underlying-asset-price';
import { Currencies } from '../../../../../Models/API/enums';
import { EditableUnderlyingAssetPrice } from '../../../../../Models/App/Expensing/editable-underlying-asset-price';
import AddButton from '../../../../../Shared/Components/Button/AddButton';
import Button from '../../../../../Shared/Components/Button/Button';
import DatePickerInput from '../../../../../Shared/Components/Input/DatePickerInput';
import NumberInput from '../../../../../Shared/Components/Input/NumberInput';
import TextInput from '../../../../../Shared/Components/Input/TextInput';
import { Cell, Row, Table, rowHeight } from '../../../../../Shared/Components/Table/Table.Style';
import useRootStore from '../../../../../Shared/Hooks/useRootStore';
import { formatDate, formatDecimal, isNullOrUndefined } from '../../../../../Shared/Utilities';
import appConfig from '../../../../../config/config';
import Menu, { MenuElement } from '../../../../../Shared/Components/Menu';
import { IC_EDIT2, IC_TRASH2 } from '../../../../../Assets';
import Pagination from '../../../../../Shared/Components/Pagination';

const Style = css({
	label: 'MarketTable',
	border: `1px solid ${appConfig.style.colors.table}`,
	overflow: 'unset !important',
	maxHeight: 'unset !important',
	'&__actions': {
		display: 'flex',
		width: '100%',
		gap: '1.2rem',
		justifyContent: 'flex-end',
		'> button': {
			alignSelf: 'center',
		},
	},
	'&__editable-row': {
		'.row-edit-menu': {
			opacity: 0,
			visibility: 'hidden',
			transition: 'all .3s',
		},
		'&:hover': {
			'.row-edit-menu': {
				opacity: 1,
				visibility: 'visible',
			},
		},
	},
});

const ROWS_PER_PAGE = 5;

type Props = {
	isInitial?: boolean;
};

const MarketTable = ({ isInitial }: Props) => {
	const { expensingStore } = useRootStore();
	const [assets, setAssets] = useState<UnderlyingAssetPrice[]>();
	const [editIds, setEditIds] = useState<Record<number, boolean>>({});
	// const [isEdit, setIsEdit] = useState<boolean>(false);
	const [asset, setAsset] = useState<EditableUnderlyingAssetPrice>();
	const editRef = useRef<HTMLDivElement>(null);
	const menuRef = useRef<MenuElement>(null);
	const [pageIdx, setPageIdx] = useState<number>(0);

	useEffect(() => {
		(async () => {
			const res = await expensingStore.getAssetPrices();
			if (!res.data || isNullOrUndefined(res.data?.data)) return;
			setAssets(res.data.data);
		})();
	}, [expensingStore.company?.operationalCurrency]);

	const onClickAddHandler = () => {
		const newAsset: EditableUnderlyingAssetPrice = {
			underlyingAssetPriceId: undefined,
			fairValue: undefined,
			fairValueCurrency: Currencies.USD,
			valuationDate: undefined,
			comment: undefined,
		};
		setAsset(newAsset);
		setTimeout(() => editRef.current?.scrollIntoView({ behavior: 'smooth' }), 50);
		// setIsEdit(true);
	};

	const onAddHandler = async () => {
		if (isNullOrUndefined(asset) || isNullOrUndefined(asset.valuationDate) || !asset.fairValue) return;

		const res = await expensingStore.addAssetPrice(asset);
		if (isNullOrUndefined(res?.data?.data)) return;

		const addedAsset = res.data.data;
		setAssets(prevState => (
			prevState ?
				[...prevState, addedAsset].sort((a, b) => new Date(b.valuationDate).getTime() - new Date(a.valuationDate).getTime())
				: [addedAsset]
		));
		setAsset(undefined);
	};

	const onUpdateHandler = async () => {
		if (isNullOrUndefined(asset) || isNullOrUndefined(asset.underlyingAssetPriceId) || isNullOrUndefined(asset.valuationDate) || !asset.fairValue) return;

		const res = await expensingStore.updateAssetPrice(asset);
		if (isNullOrUndefined(res?.data?.data)) return;

		const addedAsset = res.data.data[0];
		setAssets((prevState) => {
			if (!prevState) return prevState;

			const assetIdx = prevState.findIndex((as) => as.assetPriceId === asset.underlyingAssetPriceId);
			if (assetIdx === -1) return prevState;

			return [...prevState.slice(0, assetIdx), addedAsset, ...prevState.slice(assetIdx + 1)];
		});

		setAsset(undefined);
	};

	const onDeleteHandler = async (assetId: number) => {
		const res = await expensingStore.deleteAssetPrice(assetId);
		if (res.error) return;

		setAssets((prevAssets) => prevAssets?.filter((as) => as.assetPriceId !== res.data?.data?.assetPriceId));
	};

	const onEditHandler = ({ assetPriceId, ...asset }: UnderlyingAssetPrice) => {
		setAsset({ ...asset, underlyingAssetPriceId: assetPriceId });
	};

	const onToggleEdit = (assetId: number) => {
		setEditIds((prevState) => ({ ...prevState, [assetId]: !prevState[assetId] }));
	};

	const onAssetInputHandler = (value?: string | number | Date | boolean | null, name?: string | undefined) => {
		if (isNullOrUndefined(name)) return;

		setAsset((prevState) => (prevState ? { ...prevState, [name]: value } : ({ [name]: value } as EditableUnderlyingAssetPrice)));
	};

	return (
		<>
			<Table className={Style}>
				<Row header rowSize={rowHeight} headerSize={1.25}>
					<Cell border={{ right: true, bottom: true }} style={{ borderTopLeftRadius: 16 }}>
						Date
					</Cell>
					<Cell border={{ right: true, bottom: true }}>Fair market value</Cell>
					<Cell border={{ right: true, bottom: true }} style={{ borderTopRightRadius: 16 }}>
						Comments
					</Cell>
				</Row>
				{!!asset && !asset.underlyingAssetPriceId && (
					<Row rowSize={1.5} ref={editRef}>
						<Cell border={{ right: true, bottom: true }} isOverflow>
							<DatePickerInput qaid="" value={asset.valuationDate} name="valuationDate" onChange={onAssetInputHandler} />
						</Cell>
						<Cell border={{ right: true, bottom: true }}>
							<NumberInput
								qaid=""
								value={asset.fairValue}
								name="fairValue"
								onChange={onAssetInputHandler}
								number="float"
								placeholder={expensingStore.companyCurrency?.symbol}
								onEnter={onAddHandler}
								prependText={expensingStore.companyCurrency?.symbol}
							/>
						</Cell>
						<Cell border={{ right: true, bottom: true }}>
							<TextInput qaid="" value={asset.comment} name="comment" onChange={onAssetInputHandler} />
						</Cell>
					</Row>
				)}
				{assets?.slice(pageIdx * ROWS_PER_PAGE, pageIdx * ROWS_PER_PAGE + ROWS_PER_PAGE).map((as) =>
					asset?.underlyingAssetPriceId === as.assetPriceId ? (
						<Row key={as.assetPriceId} rowSize={1.5} ref={editRef}>
							<Cell border={{ right: true, bottom: true }} isOverflow>
								<DatePickerInput qaid="" value={asset.valuationDate} name="valuationDate" onChange={onAssetInputHandler} />
							</Cell>
							<Cell border={{ right: true, bottom: true }}>
								<NumberInput
									qaid=""
									value={asset.fairValue}
									name="fairValue"
									onChange={onAssetInputHandler}
									number="float"
									placeholder={expensingStore.companyCurrency?.symbol}
									onEnter={onAddHandler}
									prependText={expensingStore.companyCurrency?.symbol}
								/>
							</Cell>
							<Cell border={{ right: true, bottom: true }}>
								<TextInput qaid="" value={asset.comment} name="comment" onChange={onAssetInputHandler} />
							</Cell>
						</Row>
					) : (
						<Row key={as.assetPriceId} rowSize={1.1} className={`${Style}__editable-row`} onMouseLeave={() => menuRef.current?.hide()}>
							<Cell border={{ right: true, bottom: true }} isOverflow>
								{formatDate(as.valuationDate)}

								<Menu
									ref={menuRef}
									items={[
										{
											qaid: '',
											label: 'Edit',
											icon: IC_EDIT2,
											onClick: () => onEditHandler(as),
										},
										{
											qaid: '',
											label: 'Delete',
											icon: IC_TRASH2,
											onClick: () => onDeleteHandler(as.assetPriceId),
										},
									]}
									position={{ left: '4rem', top: '-0.5rem' }}
									isHorizontal
									className="row-edit-menu"
								/>
							</Cell>
							<Cell border={{ right: true, bottom: true }}>
								{expensingStore.companyCurrency?.symbol}
								{formatDecimal(as.fairValue)}
							</Cell>
							<Cell border={{ right: true, bottom: true }}>{as.comment}</Cell>
						</Row>
					)
				)}

				<Row className="custom-render" data-row-type="custom">
					{asset ? (
						<div className={`${Style}__actions`}>
							<Button qaid="AssetPrice.Button.Cancel" onClick={() => setAsset(undefined)} label="Cancel" cancel />
							<Button
								qaid="AssetPrice.Button.Add"
								onClick={asset.underlyingAssetPriceId ? onUpdateHandler : onAddHandler}
								label="Confirm"
								disabled={isNullOrUndefined(asset.valuationDate) || !asset.fairValue}
							/>
						</div>
					) : (
						<AddButton onClick={onClickAddHandler} qaid="409A.Button.AddNew" label="Add new" />
					)}
				</Row>
			</Table>
			{!isInitial && (
				<Pagination
					style={{ marginTop: '4rem' }}
					onNavHandler={(idx) => setPageIdx(idx)}
					currentPage={pageIdx}
					pageCount={Math.max(Math.ceil((assets || []).length / ROWS_PER_PAGE), 1)}
				/>
			)}
		</>
	);
};

export default MarketTable;
