import { css } from "@emotion/css";
import { useEffect, useState } from "react";
import { ILoginPivotal, IPivotalCompany } from "../../../../../../Models/App";
import Button from "../../../../../../Shared/Components/Button/Button";
import TextInput from "../../../../../../Shared/Components/Input/TextInput";
import Title from "../../../../../../Shared/Components/Layout/Title";
import { ModalBodyProps } from "../../../../../../Shared/Components/Modal/types";
import RadioButton from "../../../../../../Shared/Components/RadioButton/RadioButton";
import useModal from "../../../../../../Shared/Hooks/useModal";
import useRootStore from "../../../../../../Shared/Hooks/useRootStore";
import { isNullOrUndefined } from "../../../../../../Shared/Utilities";
import appConfig from "../../../../../../config/config";
import PivotalOtp from "./Otp";
import useRecaptcha from "../../../../../../Shared/Hooks/useRecaptcha";

const Style = css({
    label: "PivotalLogin",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "2rem 4rem",
    maxWidth: "40rem",
    margin: "auto",
    "&__methods": {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        margin: "2rem 0",
    },
    "&__form": {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        button: {
            alignSelf: "center",
            marginTop: "2rem",
        },
    },
    "&__comment": {
        marginTop: "2rem",
        textAlign: "center",
        fontSize: "1.2rem",
        color: appConfig.style.colors.text2,
    },
    "&__input": {
        marginBottom: "2rem",
    },
});

interface Props extends ModalBodyProps {
    onCompanySelect: (reCaptchaToken: string, company: IPivotalCompany) => Promise<boolean>;
    initialRegistrartion?: boolean
}

const PivotalLogin = ({ removeModal, onCompanySelect, initialRegistrartion }: Props) => {
    const { showAsyncModal } = useModal();
    const { auth } = useRootStore();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<ILoginPivotal>({ userName: "", emailPhone: "", otpSendBy: "mobile", });
    const { execute } = useRecaptcha();

    useEffect(() => {
        return () => setData({} as ILoginPivotal);
    }, []);

    const onInputHandler = (value: string, name?: string) =>
        !isNullOrUndefined(name) && setData((prevData) => ({ ...prevData, [name]: value }));

    const handleSubmit = async () => {
        execute(async (reCaptchaToken) => {
            setIsLoading(true);

            // const isSuccess = await new Promise((res) => setTimeout(() => res(true), 1000));
            const isSuccess = await auth.pivotalLogin({
                ...data,
                reCaptchaToken,
            });

            if (isSuccess) {
                const isSuccess = await showAsyncModal({
                    body: <PivotalOtp onCompanySelect={onCompanySelect} initialRegistrartion={initialRegistrartion} />,
                    isMust: true,
                    maxWidth: "100%",
                    width: "40rem",
                });
                isSuccess && removeModal?.();
            } else {
                console.log("asd");
            }
            setIsLoading(false);
        });
    };

    return (
        <div className={Style}>
            <Title>Access to altshare</Title>

            <div className={`${Style}__methods`}>
                <RadioButton
                    qaid="PivotalLogin.Radio.Email"
                    value="email"
                    label="Email"
                    name="otpSendBy"
                    onChange={onInputHandler}
                    checked={data.otpSendBy === "email"}
                    disabled={isLoading}
                />
                <RadioButton
                    qaid="PivotalLogin.Radio.Mobile"
                    value="mobile"
                    label="Mobile number"
                    name="otpSendBy"
                    onChange={onInputHandler}
                    checked={data.otpSendBy === "mobile"}
                    disabled={isLoading}
                />
            </div>

            <div className={`${Style}__form`}>
                <TextInput
                    qaid="PivotalLogin.Input.Username"
                    className={`${Style}__input`}
                    value={data.emailPhone}
                    name="emailPhone"
                    onChange={onInputHandler}
                    disabled={isLoading}
                />
                <TextInput
                    qaid="PivotalLogin.Input.Password"
                    value={data.userName}
                    name="userName"
                    onChange={onInputHandler}
                    label="Password or ID"
                    required
                    type="password"
                    disabled={isLoading}
                />

                <Button
                    label="Send me an access code"
                    qaid="PivotalLogin.Button.SendAccessCode"
                    onClick={handleSubmit}
                    isLoading={isLoading}
                />
            </div>

            <div className={`${Style}__comment`}>
                By clicking on "Send me an access code", a one time access code will be sent to your mobile phone or to your email,
                according to your selection.
            </div>
        </div>
    );
};

export default PivotalLogin;
