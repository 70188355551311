import styled from "@emotion/styled";

const WIDGET_TITLE_FONT_SIZE = "1.4rem";
const WIDGET_TITLE_COLOR = "#4D4D4D";
// const WIDGET_TITLE_SPACING_BOTTOM = "1.6rem";

export const StyledWidgetTitle = styled.h3`
  label: WidgetTitle;
  color: ${WIDGET_TITLE_COLOR};
  font-size: ${WIDGET_TITLE_FONT_SIZE};
  font-weight: 700;
`;
