import { useEffect, useRef, useState } from "react";
import { IContact, IContactCreate, IContactDetails } from "../../Models/API/Contact/contact";
import MergeContact from "../../Screens/CapTable/Components/General/MergeContact/MergeContact";
import useContactValidation from "../../Screens/CapTable/Hooks/Validations/useContactValidation";
import { shareholderRoleOptions } from "../Config";
import { getDummyName } from "../DummyData/dummyHelper";
import { commonValidators } from "../ObjectValidator";
import { getRandomNumberBetween, getUniqueId, isNumber } from "../Utilities";
import { useAppendState } from "./useAppendState";
import { InputRefs, useFormValidation } from "./useFormValidation";
import useModal from "./useModal";
import useRootStore from "./useRootStore";

export const INITIAL_CONTACT = {
    isLegalEntity: false,
    firstName: undefined,
    lastName: undefined,
    mobile: undefined,
    mobilePrefix: undefined,
    contactDetails: {} as IContactDetails,
} as IContactCreate;

interface Props {
    onSuccess?: (contact: IContact) => Promise<void> | void;
    onMerge?: (contact: IContact) => Promise<void> | void;
    onUnmount?: () => void;
    isEmailRequired?: boolean;
    isRoleRequired?: boolean;
    isOrganizationNameRequired?: boolean;
    isUnique?: boolean;
    isSearchByBoth?: boolean;
}

const useContact = ({
    onSuccess,
    onMerge,
    onUnmount,
    isEmailRequired,
    isRoleRequired = true,
    isOrganizationNameRequired = false,
    isUnique = true,
    isSearchByBoth = false,
}: Props) => {
    const { contactStore, companyStore } = useRootStore();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [contact, setContact, onInputHandler] = useAppendState<IContactCreate>({ ...INITIAL_CONTACT, companyId: companyStore.companyId });
    const inputRefs = useRef<InputRefs<IContactCreate>>({} as InputRefs<IContactCreate>);
    const contactInputRefs = useRef<InputRefs<IContactDetails>>({} as InputRefs<IContactDetails>);
    const {
        validateForm,
        formValidationState,
        clearErrors: clearDetailsErrors,
        setFormValidationState,
    } = useContactValidation(contact, inputRefs.current, isEmailRequired, isOrganizationNameRequired);

    const {
        validateForm: validateContact,
        formValidationState: detailsValidationState,
        clearErrors: clearContactDetailsErrors,
    } = useFormValidation({
        form: contact.contactDetails,
        schema: {
            role: [commonValidators.requiredIf(() => isRoleRequired)],
        },
        refs: contactInputRefs.current,
    });
    const { showModal, showAsyncModal } = useModal();

    useEffect(() => {
        return () => resetForm();
    }, []);

    const resetForm = () => setContact({ ...INITIAL_CONTACT, companyId: companyStore.companyId });

    const setDummyData = () => {
        const { firstName, lastName, fullName } = getDummyName();
        setContact({
            firstName,
            lastName,
            isLegalEntity: contact.isLegalEntity,
            companyName: contact.isLegalEntity ? `Company ${getRandomNumberBetween(10000, 100000)}` : undefined,
            email: `${firstName.toLocaleLowerCase()}_${getUniqueId()}@autofilltest.com`,
            contactDetails: {
                role: getRandomNumberBetween(1, shareholderRoleOptions.length),
            } as IContactDetails,
        } as IContactCreate);
    };

    const validateForms = () => {
        let isValidated = validateContact();
        return validateForm() && isValidated;
    };

    const onCreateContactHandler = async (
        c: IContactCreate = contact,
        {
            allowMerge = true,
            isEmailUnique = false,
            isSearchByBoth = false,
            isUpdate = true,
        }: { isUpdate?: boolean; allowMerge?: boolean; isSearchByBoth?: boolean; isEmailUnique?: boolean } = {
            allowMerge: true,
            isEmailUnique: false,
            isSearchByBoth: false,
            isUpdate: true,
        }
    ) => {
        if (!validateForms()) return false;

        const newContact = Object.assign({}, c);

        setIsLoading(true);

        if (contact.contactId) {
            const updatedContact = await contactStore.updateContact(contact);
            if (updatedContact?.error) {
                return { error: updatedContact?.error };
            }
            setIsLoading(false);
            if (!updatedContact?.data) return false;
            await onSuccess?.(updatedContact.data);
            return true;
        }

        const res = await contactStore.createContact(newContact, isUpdate, isUnique, isSearchByBoth);

        if (res.similarContacts?.length) {
            setIsLoading(false);
            if (
                !allowMerge ||
                (isEmailUnique && isSearchByBoth && res.similarContacts.some((similarContact) => similarContact.email === newContact.email))
            ) {
                return { error: "Email already exists" };
            }
            const isCreated = await showAsyncModal({
                body: (
                    <MergeContact
                        contacts={res.similarContacts}
                        contact={newContact}
                        onCreate={onCreateContactHandler}
                        onMerge={async (contactId, mergedContact) => {
                            setIsLoading(true);
                            await contactStore.updateContact({
                                ...newContact,
                                ...mergedContact,
                                contactId,
                                email: mergedContact?.email || newContact.email,
                            });
                            await onMerge?.({ ...newContact, ...mergedContact, contactId });
                            // onProceed({ ...contact, contactId });
                            setIsLoading(false);
                        }}
                    />
                ),
                isFrameless: true,
                width: "150rem",
                height: "100%",
            });
            return isCreated;
        } else if (isNumber(res.createdContactId)) {
            const registeredContact: IContact = {
                ...newContact,
                contactId: res.createdContactId,
            };
            await onSuccess?.(registeredContact);
            setIsLoading(false);
            return true;
        }
        return false;
    };

    return {
        contact,
        setContact,
        onInputHandler,
        validateForm: validateForms,
        inputRefs: inputRefs.current,
        contactInputRefs: contactInputRefs.current,
        isLoading,
        formValidationState: {
            ...formValidationState,
            contactDetails: detailsValidationState,
        },
        onCreateContactHandler,
        setDummyData,
        clearErrors: () => {
            clearContactDetailsErrors();
            clearDetailsErrors();
        },
        resetForm,
        setFormValidationState,
    };
};

export default useContact;
