export interface IGrantCancelation {
	companyId: number;
	grantId: number;
	contactId: number;
	cancelationDate: Date | string;
	cancelationReason: number;
	cancelType: number;
	gracePeriodNum: number;
	gracePeriodType: number;
	amountToCancel: number;
	isMany: boolean;
	grantCancelationId: number;
	planManagerId: number;
	fullName: {firstName: string, lastName: string};
	grantNumber: string;
	employeeNumber: string;
}

export interface IGrantCancelationTable {
	companyId: number;
	grantId: number;
	cancelationDate: string;
	cancelationReason: string;
	cancelType: string;
	gracePeriod: string;
	amountToCancel: number;
	grantCancelationId: number;
}

export enum CancelType {
	Vested,
	Unvested,
	VestedAndUnvested,
	VestedByAmount,
}

export enum CancelDateType {
	Days,
	Months,
	Years,
}

export enum CancelationReason {
	Default,
	Termination,
	Resignation,
	Death,
	Retirment,
	EndOfGPT,
	GrantExpiration,
	DueToExercise,
	Waiver,
}
