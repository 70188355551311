import { ExpenseReport } from "../../../Models/API/Expensing/expense-report";
import { GrantReportPayload } from "../../../Models/API/Expensing/grant-report";
import { EditableGrantReport } from "../../../Models/App/Expensing/editable-grant-report";

export const convertExpenseReport = (report: ExpenseReport): GrantReportPayload => {
    return {
		expenseCompanyReportName: report.expenseCompanyReportName,
		previousExpenseCompanyReportId: report.reportLastId,
		period: report.period,
		reportEndDate: report.reportDateTo,
		reportStartDate: report.reportDateFrom,
		assetPrice: 0,
	};
};
