import Button from "../../../../Shared/Components/Button/Button";

interface DataManagerProps {
    rtd: () => void;
}

const DataManager = (props: DataManagerProps) => {
    return (
        <div className="container-fluid">
            <div className="main-container box-shadow-1">
                <Button qaid="" onClick={props.rtd}>
                    Go back
                </Button>
                <h1>Data Manager</h1>
                <Button qaid="">Import Some Data</Button>
                <Button qaid="">Update Some Data</Button>
                <Button qaid="">Export Some Data</Button>
            </div>
        </div>
    );
};
export default DataManager;
