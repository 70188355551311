import React from 'react'
import TableDrawer from '../../../../ESOP/General/TableDrawer/TableDrawer'
import Table, { TableColumn } from '../../../../../Shared/Components/Table/Table'
import NumberInput from '../../../../../Shared/Components/Input/NumberInput'
import { TextInput } from '../../../../../Shared/Components'

import cardStyle from './TrusteeGrantCard.module.css'
import { IC_USER_PROFILE_PURPLE } from '../../../../../Assets'
import useRootStore from '../../../../../Shared/Hooks/useRootStore'
import { observer } from 'mobx-react-lite'
import { TrusteeCancelation, TrusteeOrder, TrusteeVest } from '../../../Types/trusteeTypes'
import { toJS } from 'mobx'
import { formatDate, formatNumber } from '../../../../../Shared/Utilities'
import PagedTableDrawer from '../../../General/PagedTableDrawer/PagedTableDrawer'
import { cancelationOrderKeys, trusteeGrantKeys, trusteeOrderKeys } from '../../../StaticData/tableTypes'
import ViewOnlyBox from '../../../../../Shared/Components/Input/ViewOnlyBox'

export const ordersColumns: TableColumn<TrusteeOrder>[] = [
    { label: 'Order number', name: 'orderNumber' },
    { label: 'Grant number', name: 'grantNumber' },
    { label: 'Order type', name: 'orderType' },
    { label: 'Amount', name: 'amount', format: (val) => formatNumber(val) },
    { label: 'Sale price', name: 'salePrice', format: (val) => formatNumber(val) },
    { label: 'Exercise price', name: 'exercisePrice', format: (val) => formatNumber(val) }
]

export const cancelationsColumns: TableColumn<TrusteeCancelation>[] = [
    { label: 'Cancelation number', name: 'cancelationNumber' },
    { label: 'Cancel type', name: 'cancelType' },
    { label: 'Cancel date', name: 'cancelDate' },
    { label: 'Cancel in', name: 'cancelDate' },
    { label: 'Cancel reason', name: 'cancellReason' },
    { label: 'Amount', name: 'amount', format: (val) => formatNumber(val) }
]

const TrusteeGrantCard = () => {

    const { trusteeStore } = useRootStore()

    const grant = trusteeStore.singleGrant
    console.log('grant', toJS(grant))

    // interface TrusteeVest {
    //     date: Date
    //     eventType: string
    //     vested: number
    //     exercised: number
    //     sold: number
    //     canceled: number
    //     exercisable: number
    //     saleable: number
    //     outstanding: number
    // }
    const vestColumns: TableColumn<TrusteeVest>[] = [
        { label: 'Date', name: 'eventDate', format: val => formatDate(val) },
        { label: 'Event type', name: 'eventType' },
        { label: 'Vested', name: 'vested', format: val => formatNumber(val) },
        { label: 'Exercised', name: 'exercised', format: val => formatNumber(val) },
        { label: 'Sold', name: 'sold', format: val => formatNumber(val) },
        { label: 'Canceled', name: 'canceled', format: val => formatNumber(val) },
        { label: 'Exersiable', name: 'exercisable', format: val => formatNumber(val) },
        { label: 'Saleable', name: 'saleable', format: (val) => formatNumber(val) },
        { label: 'Outstanding', name: 'outstanding', format: (val) => formatNumber(val) }
    ]



    return (
        <div style={cardStyle}>
            <div className={cardStyle.cardContainer}>
                <div className={cardStyle.beneficiary}>
                    <img className={cardStyle.icon} src={IC_USER_PROFILE_PURPLE} alt="" />
                    <h1>{grant.beneficiaryName}</h1>
                </div>

                <div className={cardStyle.detailsContainer}>

                    <div className={cardStyle.row}>
                        <div className={cardStyle.details}>
                            <ViewOnlyBox value={grant.planName} label={"Equity plan"} />
                        </div>
                        <div className={cardStyle.details}>
                            <ViewOnlyBox value={grant.awardType} label={"Award type"} />
                        </div>
                        <div className={cardStyle.details}>
                            <ViewOnlyBox value={formatDate(grant.grantDate)} label={"Grant date"} />
                        </div>
                        <div className={cardStyle.details}>
                            <ViewOnlyBox value={grant.grantNumber} label={"Grant number"} />
                        </div>
                        <div className={cardStyle.details}>
                            <ViewOnlyBox value={grant.granted} label={"Amount granted"} />
                        </div>
                        <div className={cardStyle.details}>
                            <ViewOnlyBox value={formatDate(grant.expirationDate)} label={"Expiration date"} />
                        </div>
                    </div>

                    <div className={cardStyle.row}>
                        <div className={cardStyle.details}>
                            <ViewOnlyBox value={grant.erercisePrice} label={"Exercise price"} />
                        </div>
                        <div className={cardStyle.details}>
                            <ViewOnlyBox value={grant.taxTrack} label={"Tax track"} />
                        </div>
                        <div className={cardStyle.details}>
                            <ViewOnlyBox value={grant.department} label={"Department"} />
                        </div>
                        <div className={cardStyle.details}>
                            <ViewOnlyBox value={grant.site} label={"Site"} />
                        </div>
                        <div className={cardStyle.details}>
                            <ViewOnlyBox value={grant.vestingSchedule} label={"Vesting schedule"} />
                        </div>
                        <div className={cardStyle.details}>
                            <ViewOnlyBox value={formatDate(grant.vestingStartDate)} label={"Vesting start date"} />
                        </div>
                    </div>
                </div>

                <p className={cardStyle.tableHeader}>Vesting schedule</p>

                <Table
                    columns={vestColumns}
                    rows={grant.vestings ?? []}
                />
                <PagedTableDrawer
                    headers={trusteeOrderKeys}
                    tableData={trusteeStore.grantOrders?.orders}
                    totalRows={trusteeStore.grantOrders?.totalNumberOfOrders}
                    newDataReq={trusteeStore.GetTrusteeGrantOrdersByParams}
                    header='Orders'
                />
                {/* <TableDrawer
                    tableColumns={ordersColumns}
                    tableData={trusteeStore.grantOrders}
                    header={'Orders'}
                /> */}
                <PagedTableDrawer
                    headers={cancelationOrderKeys}
                    tableData={trusteeStore.grantCancelations.orders}
                    totalRows={trusteeStore.grantCancelations.totalNumberOfOrders}
                    newDataReq={trusteeStore.GetTrusteeGrantCancelationsbyParams}
                    header='Cancelations'
                />
                {/* <TableDrawer
                    tableColumns={cancelationsColumns}
                    tableData={trusteeStore.grantCancelations}
                    header={'Cancelations'}
                /> */}
            </div>
        </div>
    )
}

export default observer(TrusteeGrantCard)