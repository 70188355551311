import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { BenTable } from "../../../../Models/App/EquityPlans/Beneficiaries";
import { MultiType } from "../../../../Models/App/EquityPlans/General";
import Button from "../../../../Shared/Components/Button/Button";
import Table, { TableColumn } from "../../../../Shared/Components/Table/Table";
import useModal from "../../../../Shared/Hooks/useModal";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import SectionStyles from "../../General/styles/SectionStyles";
import useEsopModals from "../../useEsopModals";
import { BeneficiaryData } from "../../../../Models/App/ESOP/BeneficiaryData";




interface BeneficiariesProps {
    rtd: () => void;
}

const Beneficiaries = (props: BeneficiariesProps) => {
    const { equityPlansStore } = useRootStore();
    // const { show} = useModalShow()
    const { showAsyncModal } = useModal();

    const [benDataTable, setBenDataTable] = useState<BeneficiaryData[] | undefined>();
    const [InvitationArr, setInvitationArr] = useState();

    const [multiArr, setMultiArr] = useState<number[]>([]);

    const { newBeneficiaryModalHandler, openBeneficiaryModal, showCreateGrantModal } = useEsopModals();

    const getDataTable = async () => {
        equityPlansStore.GetBeneficiariesDashboardData();
    };

    useEffect(() => {
        getDataTable();
    }, []);

    // fullname: concat(fullName.firstName, fullName.lastName),

    const tableColumns: TableColumn<BenTable>[] = [
        {
            label: "Employee Number",
            name: "employeeNumber",
            sortable: true,
        },
        {
            label: "Emp. Status",
            name: "employementStatus",
            sortable: true,
        },
        {
            label: "full name",
            name: "fullName",
            format: (val: { firstName: string; lastName: string }) => `${val?.firstName}  ${val?.lastName}`,
            sortable: false,
            style: { flex: 1.5 },
            onCellClick: (value) => console.log(value),
        },
        {
            label: "Department",
            name: "department",
            format: (value) => equityPlansStore.companyData?.departments?.filter((dep) => dep.key === value)[0]?.value,
            sortable: true,
        },
        {
            label: "Site",
            name: "site",
            format: (value) => equityPlansStore.companyData?.sites?.filter((site) => site.key === value)[0]?.value,
            sortable: true,
        },
        {
            label: "Shares",
            name: "shares",
            sortable: true,
        },
        {
            label: "Outstanding",
            name: "outstanding",
            sortable: true,
            onCellClick: (value) => console.log(value),
        },
        {
            label: "Web Access",
            name: "webAccess",
            sortable: true,
            onCellClick: (value) => console.log(value),
        },
        {
            label: "Email",
            name: "email",
            sortable: true,
            onCellClick: (value) => console.log(value),
        },
    ];

    const multiHandler = async (action: "delete" | "invite") => {
        console.log("action:", action, "data:", multiArr);
        if (action === "delete") {
            const proceed = await showAsyncModal({
                type: "confirm",
                body: <p>Are You Sure You Want To Delete This Beneficiaries?</p>,
                confirmButton: {
                    label: "Yes",
                },
                cancelButton: {
                    label: "No",
                },
            });

            if (!proceed) return;
            equityPlansStore.UpdateMultipleBeneficiaries(MultiType.Delete, multiArr);
        }
    };

    return (
        <div className={SectionStyles}>
            <div className="section-header">
                <h1>Beneficiaries</h1>
                <Button qaid="Beneficiaries.Button.BackToDashboard" onClick={props.rtd}>
                    Go back
                </Button>
            </div>
            <div className="section-table">
                <div className="table-actions">
                    <Button qaid="Beneficiaries.Button.AddNewBeneficiary" inverse label="Add New Beneficiary" onClick={() => newBeneficiaryModalHandler()} />
                </div>

                <Table
                    columns={tableColumns}
                    rows={equityPlansStore.companyBeneficiaries}
                    // color="secondary"
                    scrollAfterRows={13}
                    onRowClick={(row) => {
                        console.log("Beneficiary row Clicked", toJS(row));
                        openBeneficiaryModal(row.beneficiaryId);
                        // openGrntCard(row)
                    }}
                    showTotal={false}
                    onRowSelect={(arr) => {
                        const newArr = arr as any;
                        if (Array.isArray(newArr)) {
                            const nArr = newArr.filter((m) => m !== undefined).map((m: any) => +m) as number[];
                            setMultiArr(nArr);
                        }
                    }}
                    selectBy="beneficiaryId"
                    // actions={[
                    // 	{
                    // 		qaid: "Beneficiary.Button.SendInvitation",
                    // 		label: "Send Invitation Email",
                    // 		onClick: () => showCreateGrantModal,
                    // 	},
                    // 	{
                    // 		qaid: "Beneficiary.Button.Delete",
                    // 		label: "Delete",
                    // 		onClick: () => multiHandler("delete")
                    // 	},
                    // ]}
                    // oneditrender
                    filterBy={tableColumns.filter((col) => col.name !== "actions").map((col) => col.name as keyof BenTable)}
                    searchBy={["employeeNumber", "department", "email", "employementStatus", "fullName", "site"]}
                    exportToExcel={{ fileName: "Beneficiaries", sheetName: "Beneficiaries" }}
                    fullscreen
                />
            </div>
        </div>
    );
};

export default observer(Beneficiaries);
