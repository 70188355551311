import { WidgetBody } from "../../../shared/components/Widget/Widget.style";
import WidgetTitle from "../../../shared/components/Widget/WidgetTitle/WidgetTitle";

import { FundingWidgetProps } from "../../FundingDashboard";
import NoData from "../NoData/NoData";
import { StyledPreRoundWidget } from "./PreRoundWidget.style";

const PreRoundWidget = (props: FundingWidgetProps) => {
	const { title, chartNode, legendNode, isNoData } = props;

	return (
		<StyledPreRoundWidget>
			<WidgetTitle text={title} />

			{isNoData ? (
				<NoData />
			) : (
				<WidgetBody>
					{chartNode}
					{legendNode}
				</WidgetBody>
			)}
		</StyledPreRoundWidget>
	);
};

export default PreRoundWidget;
