import styled from "@emotion/styled";
import appConfig from "../../../config/config";

const Separator = styled.div(
    () => ({
        borderBottom: `1px solid ${appConfig.style.colors.table}`,
        width: "100%",
    }),
    ({ margin = true }: { margin?: boolean }) => ({
        ...(margin
            ? {
                  margin: "2.4rem 0",
              }
            : {}),
    })
);

export default Separator;
