import { ReactNode } from "react";
import { WidgetBody } from "../../../shared/components/Widget/Widget.style";
import WidgetTitle from "../../../shared/components/Widget/WidgetTitle/WidgetTitle";
import { FundingWidgetProps } from "../../FundingDashboard";

import NoData from "../NoData/NoData";
import {
	ChartWrapper,
	LegendWrapper,
	PPSWidgetWrapper,
	PostRoundWidgetBody,
	StyledPostRoundWidget,
	ValuationWidgetWrapper,
} from "./PostRoundWidget.style";

interface PostRoundWidgetProps extends FundingWidgetProps {
	postRoundValuationWidget: ReactNode;
	pricePerShareWidget: ReactNode;
}

const PostRoundWidget = (props: PostRoundWidgetProps) => {
	const { title, chartNode, legendNode, postRoundValuationWidget, pricePerShareWidget, isNoData } =
		props;
	return (
		<>
			<StyledPostRoundWidget className="PostRoundWidget">
				<WidgetTitle text={title} />

				{isNoData ? (
					<NoData />
				) : (
					<PostRoundWidgetBody>
						<ChartWrapper>{chartNode}</ChartWrapper>
						<LegendWrapper>{legendNode}</LegendWrapper>
						<ValuationWidgetWrapper>{postRoundValuationWidget}</ValuationWidgetWrapper>
						<PPSWidgetWrapper>{pricePerShareWidget}</PPSWidgetWrapper>
					</PostRoundWidgetBody>
				)}
			</StyledPostRoundWidget>
		</>
	);
};

export default PostRoundWidget;
