import { NumberOption } from "../../Models/API/All/NumberOption";
import { EmploymentStatus } from "../../Models/App/EquityPlans/Beneficiaries";
import { CancelDateType, CancelType, CancelationReason } from "../../Models/App/EquityPlans/GrantCancelations";
import { GrantTypes, TaxTracks } from "../../Models/App/EquityPlans/Grants";
import { OrderType } from "../../Models/App/EquityPlans/Order";



//  Beneficiary

export const employmentStatus = [
    { label: 'Employee', value: EmploymentStatus.Employee },
    { label: 'Ex. Employee', value: EmploymentStatus.ExEmployee },
    { label: 'Other', value: EmploymentStatus.Other },
]


// Grant

export const grantTypes = [
    { label: 'Options', value: GrantTypes.Options },
    { label: 'RSU', value: GrantTypes.RSU },
]


// Cancelations

export const cancelationType = [
    { label: "Unvested", value: CancelType.Unvested },
    { label: "vested", value: CancelType.Vested },
    { label: "All", value: CancelType.VestedAndUnvested },
    { label: "Amount", value: CancelType.VestedByAmount }, // only on DueToExercise
];

export const cancelationUnit = [
    { label: "Days", value: CancelDateType.Days },
    { label: "Months", value: CancelDateType.Months },
    { label: "Years", value: CancelDateType.Years },
];

export const cancelationReason = [
    { label: "Default", value: CancelationReason.Default },
    { label: "Termination", value: CancelationReason.Termination },
    { label: "Resignation", value: CancelationReason.Resignation },
    { label: "Death", value: CancelationReason.Death },
    { label: "Retirement", value: CancelationReason.Retirment },
    { label: "End Of GPT", value: CancelationReason.EndOfGPT },
    { label: "Grant Expiration", value: CancelationReason.GrantExpiration },
    { label: "Due To Exercise", value: CancelationReason.DueToExercise },
    { label: "Waiver", value: CancelationReason.Waiver },
];



//  orders

export const orderTypes = [
    { label: "Sale", value: OrderType.Sell },
    { label: "Exercise", value: OrderType.Exercise },
    { label: "Same day sale", value: OrderType.SDS },
]

//  Tax tracks

export const taxTracks = [
    { label: 'ISO (US)', value: TaxTracks.ISO },
    { label: 'NSO (US)', value: TaxTracks.NSO },
    { label: '102 (IL)', value: TaxTracks.CapitalGainTranch_102 },
    { label: '3(i) (IL)', value: TaxTracks.T3 },
    { label: '102N (IL)', value: TaxTracks.OrdinaryIncomeTranch_102 },
    { label: 'EMI (UK)', value: TaxTracks.EMI },
    { label: 'CSOP (UK)', value: TaxTracks.CSOP },
    { label: 'Other', value: TaxTracks.None },
]

export const taxTracks2 = [
    { label: 'ISO (US)', value: 'ISO (US)' },
    { label: 'NSO (US)', value: 'NSO (US)' },
    { label: '102 (IL)', value: '102 (IL)' },
    { label: '3(i) (IL)', value: '3(i) (IL)' },
    { label: '102N (IL)', value: '102N (IL)' },
    { label: 'EMI (UK)', value: 'EMI (UK)' },
    { label: 'CSOP (UK)', value: 'CSOP (UK)' },
    { label: 'Other', value: 'Other' },
]