import { DateFormatsEnum, formatDate } from "../../Utilities";
import Clickable from "../Clickable/Clickable";
import Flex from "../Layout/Flex";
import Title from "../Layout/Title";
import { ModalBodyProps } from "../Modal/types";
import { browserName, fullBrowserVersion } from "react-device-detect";
// import { useScreenshot } from "use-react-screenshot";

const AppInfo = ({ displayModal, hideModal }: ModalBodyProps) => {
	// const [image, takeScreenShot] = useScreenshot();

	const build = localStorage.getItem("buildVersion");
	let lastBuildTime: string | undefined = undefined;

	if (build) {
		lastBuildTime = formatDate(+build, DateFormatsEnum.FULL);
	}

	const renderInfo = (title: string, body?: string) => (
		<Flex gap="0.4rem" justify="start" align="center">
			<span className="bold">{title}:</span>
			<span>{body}</span>
		</Flex>
	);

	return (
		<Flex direction="column" gap="1rem" justify="start" align="start" padding="2rem">
			<Title className="mb-5">App Info</Title>
			{renderInfo("Environment", process.env.REACT_APP_ENV?.toUpperCase())}
			{renderInfo("Last build time", lastBuildTime)}
			{renderInfo("Google Analytics ID", process.env.REACT_APP_MEASUREMENT_ID)}
			{renderInfo("Google Tag Manager ID", process.env.REACT_APP_GTM_ID)}
			{renderInfo("Hotjar ID", process.env.REACT_APP_HOTJAR_ID)}
			<div>---</div>
			{renderInfo("Screen size", `${window.screen.width}x${window.screen.height} pixels`)}
			{renderInfo("Scale", `${window.devicePixelRatio * 100}%`)}
			{renderInfo("Frame size", `${window.innerWidth}x${window.innerHeight} pixels`)}
			{renderInfo("Browser", `${browserName} (ver. ${fullBrowserVersion})`)}

			{/* {document.body && (
				<>
					<Clickable
						qaid="App.Button.Screenshot"
						onClick={async () => {
							hideModal?.();
							await new Promise((res) => setTimeout(res, 500));
							await takeScreenShot(document.body);
							displayModal?.();
						}}
						applyStyle
					>
						Take screenshot
					</Clickable>
					{image && <img src={image} alt="screenshot" className="w-100" style={{ border: "1px solid black" }} />}
				</>
			)} */}
		</Flex>
	);
};

export default AppInfo;
