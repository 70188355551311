import { css } from "@emotion/css";
import styled from "@emotion/styled";
import appConfig from "../../../config/config";
import { isNumber, isObject, isString } from "../../Utilities";

export interface BorderStyle {
    bottom?: boolean;
    top?: boolean;
    left?: boolean;
    right?: boolean;
}

type Color = "primary" | "secondary";

interface Props {
    color?: Color;
    isClickable?: boolean;
    header?: boolean;
    sortable?: boolean;
    isCustom?: boolean;
    isTotal?: boolean;
    align?: "start" | "end" | "center";
    justify?: "start" | "end" | "center";
    textAlign?: "left" | "center" | "right";
    scrollAfterRows?: number;
    defaultCellSize?: number | string;
    isStickyHeader?: boolean;
    isStickyTotal?: boolean;
    isFullscreen?: boolean;
    headerSize?: number;
    border?: BorderStyle | false;
    isHighlight?: boolean;
    isRelative?: boolean;
    dir?: "row" | "column";
    isMenu?: boolean;
    isOverflow?: boolean;
    rowSize?: number;
}

export const rowHeight = 40;

const getBorderDesign = (color: Color, size: number = 1) => {
    return `${size}px solid ${color === "primary" ? appConfig.style.colors.table : appConfig.style.colors.gray2}`;
};

export const Table = styled.div(
    {
        label: "Table",
        // fontSize: 12,
        // maxWidth: 1080,
        width: "100%",
        // margin: "0 auto",
        background: "#ffffff",
        // boxShadow: appConfig.style.boxShadow.card1,
        borderRadius: 16,
        // position: "relative",
        overflow: "auto",
    },
    ({
        scrollAfterRows = 25,
        isFullscreen,
        isRelative,
        dir = "row",
        isCustom = false,
    }: Pick<Props, "scrollAfterRows" | "isFullscreen" | "isRelative" | "dir" | "isCustom">) => ({
        maxHeight: isFullscreen
            ? "calc(100vh - 14rem)"
            : `${(scrollAfterRows * rowHeight + (scrollAfterRows === 25 ? rowHeight / 8 : 0)) / 10}rem`, // + 1/8 row is to display there's a next row
        borderBottomLeftRadius: isCustom ? 0 : "1.6rem",
        borderBottomRightRadius: isCustom ? 0 : "1.6rem",
        position: isRelative ? "relative" : "inherit",
        display: dir === "column" ? "flex" : "grid",
        flexDirection: dir === "column" ? "row" : "column",
        ...(isCustom
            ? {
                  "::-webkit-scrollbar-track": {
                      background: "#ffffff",
                  },
                  // "::-webkit-scrollbar": {
                  // 	width: "0.4rem",
                  // 	height: "0.4rem",
                  // },
              }
            : {}),
        ".table-search": {
            // position: "absolute",
            // bottom: "calc(100% + 10px)",
        },
    })
);

export const Row = styled.div(
    {
        label: "Row",
        display: "inline-flex",
        width: "100%",
        alignItems: "center",
        transition: "all .1s",
        // position: "relative",
        // "&:hover": {
        //     boxShadow: "4px 0px 15px #4D4D4D40"
        // },
        "> div": {
            height: "100%",
        },
        "&:last-child": {
            borderBottomLeftRadius: "1.6rem",
            borderBottomRightRadius: "1.6rem",
        },
        "&.custom-render": {
            padding: "0 1.6rem",
            minHeight: "5.3rem",
            background: "#ffffff",
            borderTop: getBorderDesign("primary", 2),
            borderBottomLeftRadius: "1.6rem",
            borderBottomRightRadius: "1.6rem",
            // boxShadow: appConfig.style.boxShadow.card1,
        },
    },
    ({
        isClickable = false,
        color = "primary",
        header = false,
        isTotal = false,
        isStickyHeader = false,
        isStickyTotal = false,
        headerSize = 1,
        rowSize = 1,
        isHighlight = false,
        isRelative = true,
    }: Pick<
        Props,
        | "isClickable"
        | "color"
        | "header"
        | "isTotal"
        | "isStickyHeader"
        | "headerSize"
        | "rowSize"
        | "isHighlight"
        | "isStickyTotal"
        | "isRelative"
    >) => ({
        position: isRelative ? "relative" : "inherit",
        ...(header
            ? {
                  borderTopLeftRadius: "1.6rem",
                  borderTopRightRadius: "1.6rem",
                  userSelect: "none",
                  lineHeight: 1.25,
                  // "color": "#ffffff",
                  fontFamily: appConfig.style.fonts.textPro,
                  // fontWeight: 600,
                  "> *": {
                      whiteSpace: "unset",
                      background: color === "primary" ? appConfig.style.colors.purple : appConfig.style.colors.text1,
                  },
                  "> div": {
                      fontWeight: 500,
                      whiteSpace: "break-spaces",
                  },
              }
            : {
                  "> *": {
                      background: isTotal ? appConfig.style.colors.total : "transparent",
                  },
              }),
        ...(isStickyHeader
            ? {
                  top: 0,
                  position: "sticky",
                  zIndex: 5,
              }
            : {}),
        ...(isStickyTotal
            ? {
                  bottom: 0,
                  position: "sticky",
                  zIndex: 4,
              }
            : {}),
        cursor: isClickable && !isTotal ? "pointer" : "default",
        height: `${(rowHeight * (header ? headerSize : rowSize)) / 10}rem`,

        "&:not(&.custom-render):not(div[data-row-type='total'])": {
            background: isHighlight ? appConfig.style.colors.background1 : "transparent",
            "&:hover": {
                boxShadow: isClickable ? `4px 0px 15px #4D4D4D40` : "unset",
                transition: "box-shadow .3s",
            },
        },
    })
);

export const Col = styled.div(
    {
        label: "Col",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        transition: "all .1s",
        // position: "relative",
        "> div": {
            height: "100%",
        },
        "&:last-child": {
            borderBottomLeftRadius: "1.6rem",
            borderBottomRightRadius: "1.6rem",
        },
    },
    ({
        isClickable = false,
        color = "primary",
        header = false,
        isTotal = false,
        isStickyHeader = false,
        isStickyTotal = false,
        headerSize = 1,
        isHighlight = false,
        isRelative = true,
    }: Pick<
        Props,
        "isClickable" | "color" | "header" | "isTotal" | "isStickyHeader" | "headerSize" | "isHighlight" | "isStickyTotal" | "isRelative"
    >) => ({
        position: isRelative ? "relative" : "inherit",
        cursor: isClickable && !isTotal ? "pointer" : "default",
        height: rowHeight * headerSize,
        background: isHighlight ? appConfig.style.colors.background1 : "transparent",
        "&:hover": {
            background: isClickable ? appConfig.style.colors.background1 : "transparent",
        },
    })
);

export const Cell = styled.div(
    {
        label: "Cell",
        height: "100%",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        minWidth: "5rem",
        display: "flex",
        alignItems: "center",
        gap: "0.8rem",
        width: "100%",
    },
    ({
        color = "primary",
        sortable,
        isCustom,
        border,
        align = "center",
        justify = "start",
        isClickable,
        defaultCellSize = "10rem",
        isMenu,
        textAlign = "left",
        isOverflow = false,
    }: Pick<
        Props,
        | "color"
        | "sortable"
        | "isCustom"
        | "justify"
        | "align"
        | "isClickable"
        | "defaultCellSize"
        | "border"
        | "isMenu"
        | "textAlign"
        | "isOverflow"
    >) => ({
        minWidth: isMenu ? "4.7rem" : defaultCellSize,
        flex: `${isMenu ? 0 : 1} 0 ${isMenu ? "4.7rem" : isString(defaultCellSize) ? defaultCellSize : `${defaultCellSize}rem`}`,
        position: isMenu ? "relative" : "unset",
        overflow: isMenu || isOverflow ? "unset" : "hidden",
        // "minWidth": defaultCellSize,
        // "flex": `1 0 ${isString(defaultCellSize) ? defaultCellSize : `${defaultCellSize}rem`}`,
        borderBottom: !border || (isObject(border) && !border.bottom) ? "none" : getBorderDesign(color),
        borderTop: !border || (isObject(border) && !border.top) ? "none" : getBorderDesign(color),
        "&:not(:last-child)": {
            borderRight: !border || (isObject(border) && !border.right) ? "none" : getBorderDesign(color),
        },
        "&:not(:first-of-type)": {
            borderLeft: !border || (isObject(border) && !border.left) ? "none" : getBorderDesign(color),
        },
        cursor: sortable || isClickable ? "pointer" : "inherit",
        padding: isMenu ? 0 : "0 1.6rem",
        justifyContent: justify,
        alignItems: align,
        textAlign,
        "> div": {
            // for cells that are returns in render fn
            padding: 0,
        },
    })
);

export const TableContainer = css({
	label: 'TableContainer',
	display: 'grid',
	// marginTop: 10,
	marginBottom: '1rem',
	borderRadius: 16,
	overflow: 'auto',
	boxShadow: appConfig.style.boxShadow.card1,
	border: `1px solid ${appConfig.style.colors.table}`,
	'&__actions': {
		// background: "red",
		position: 'relative',
		marginBottom: '1.4rem',
		marginTop: '1rem',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		gap: '1rem',
		'&__left, &__right': {
			flex: 1,
			position: 'relative',
		},
		'&__right': {
			display: 'flex',
			justifyContent: 'end',
			alignItems: 'center',
			gap: '1.2rem',
			minWidth: '25rem',
			'> .clickable': {
				display: 'flex',
				alignItems: 'center',
				position: 'relative',

				'&:not(:last-child)': {
					'&::after': {
						content: "''",
						position: 'absolute',
						width: 1,
						height: '2rem',
						right: '-1.5rem',
						background: appConfig.style.colors.color1,
						pointerEvents: 'none',
					},
				},
			},
		},
		'&__clickable': {
			// color: appConfig.style.colors.color1,
			// textDecoration: "underline",
		},
		'&__filter-conditions': {
			display: 'flex',
			flexDirection: 'column',
			marginBottom: '1.5rem',
			borderBottom: `1px solid ${appConfig.style.colors.gray}`,
		},
	},
	'&__actions-wrapper': {
		'> div:not(:last-child)': {
			flex: '0 0 3.6rem',
			height: '2.8rem',
			'> img': {
				width: '2.4rem',
				height: '100%',
			},
		},
		'> *:not(:last-child)': {
			paddingRight: '1.2rem',
			borderRight: `1px solid ${appConfig.style.colors.table}`,
		},
	},
	'&__menu': {
		position: 'absolute',
		zIndex: 4,
		background: '#ffffff',
		// top: 0,
		boxShadow: appConfig.style.boxShadow.card1,
		// minWidth: "10rem",
		// right: 30,
		display: 'flex',
		border: `1px solid ${appConfig.style.colors.background1}`,
		borderRadius: 8,
		// transform: "translate(-3.7rem, 3.4rem)",
		top: 'calc(100% - 0.5rem)',
		right: '0.8rem',
		padding: '0.8rem 1.2rem',
		gap: '1.2rem',
		'*': {
			userSelect: 'none',
		},
		'&.top': {
			top: 'calc(100% - 8rem)',
		},
		'&.left': {
			// left: "-7.5rem",
			right: '100%',
			top: -1,
		},
		img: {
			flex: '0 0 2rem',
			width: '2rem',
			height: 'auto',
		},
		// right: 0,
		// width: 'fit-content'
	},
});

export const FloatingCard = css({
    label: "FloadingCard",
    background: "#ffffff",
    boxShadow: appConfig.style.boxShadow.card1,
    border: `1px solid ${appConfig.style.colors.gray}`,
    padding: "1.6rem",
    minWidth: "25rem",
    width: "58.7rem",
    position: "absolute",
    // transform: `translate(calc(-100% - 40px), calc(100% - 35px))`,
    top: `calc(100% + 1.2rem)`,
    right: 0,
    zIndex: 10,
    cursor: "default",
});

export const SelectedActions = styled.div({
    label: "SelectedActions",
    position: "absolute",
    bottom: "calc(100% + 2rem)",
    left: 0,
    zIndex: 10,
    border: `1px solid ${appConfig.style.colors.field}`,
    borderRadius: 8,
    padding: "1.6rem",
    background: "#ffffff",
    display: "flex",
    gap: "1.6rem",
    boxShadow: "3px 3px 16px 0px rgba(171, 175, 198, 0.38)",
    img: {
        width: "2.4rem",
        height: "auto",
    },
    "&::before": {
        content: "''",
        bottom: "100%",
        left: 16,
        border: "solid transparent",
        height: 0,
        width: 0,
        pointerEvents: "none",
        borderBottomColor: appConfig.style.colors.field,
        borderWidth: 10,
        position: "absolute",
        top: "100%",
        transform: "rotate(180deg)",
    },
    "&::after": {
        content: "''",
        bottom: "100%",
        left: 17,
        border: "solid transparent",
        height: 0,
        width: 0,
        pointerEvents: "none",
        borderBottomColor: "#ffffff",
        borderWidth: 9,
        position: "absolute",
        top: "100%",
        transform: "rotate(180deg)",
    },
});

const StyledTable = { Table, Row, Cell, Col, SelectedActions };

export default StyledTable;
