import { IC_PLANS } from "../../../../Assets";
import { PaymentPlanEnum } from "../../../../Models/API/enums";
import { IPlanFeature, customFeatures, enterpriseFeatures, growthFeatures, scaleFeatures, starterFeatures } from "./plans-features";

export interface IPlanData {
	title: string;
	desc: string;
	features: IPlanFeature[];
	img: string;
	name: string;
	footerText?: string;
}

export const getPlanData = (planCode?: PaymentPlanEnum): IPlanData => {
	switch (planCode) {
		case PaymentPlanEnum.Growth:
			return {
				title: 'Includes Explorer plan, plus:',
				desc: 'Ideal for companies on the rise',
				features: growthFeatures,
				img: IC_PLANS.GROWTH,
				name: PaymentPlanEnum[planCode],
				footerText: 'Additional 10 stakeholders - $40/month *This plan is limited to 60 stakeholders',
			};
		case PaymentPlanEnum.Scale:
			return {
				title: 'Includes Growth plan, plus:',
				desc: 'Scaling equity management to perfection',
				features: scaleFeatures,
				img: IC_PLANS.SCALE,
				name: PaymentPlanEnum[planCode],
				footerText: 'Additional 10 stakeholders- $80/month *This plan is limited to 90 stakeholders',
			};
		case PaymentPlanEnum.Enterprise:
			return {
				title: 'Includes Scale plan plus:',
				desc: 'Unlock unlimited power at any extent',
				features: enterpriseFeatures,
				img: IC_PLANS.SCALE, //TODO ADD Enterprise
				name: PaymentPlanEnum[planCode],
				footerText: 'Additional 10 stakeholders- $60/month',
			};
		case PaymentPlanEnum.Custom: //TODO: DELETE?
			return {
				title: "Includes Enterprise plan, plus:",
				desc: "Get unlimited access to our products & services",
				features: customFeatures,
				img: IC_PLANS.CUSTOM,
				name: "Custom"
			};
		case PaymentPlanEnum.Starter:
		default:
			return {
				title: 'Includes:',
				desc: 'Discover the foundations for early - stage',
				features: starterFeatures,
				img: IC_PLANS.STARTER,
				name: 'Explorer',
			};
	}
};
