import { css } from '@emotion/css';
import { useRef, useState } from 'react';
import { GrantReportData } from '../../../Models/App/Expensing/report-data';
import Button from '../../../Shared/Components/Button/Button';
import Separator from '../../../Shared/Components/Separator';
import { ForwardedRefType, MultiStepElementProps } from '../../../Shared/Hooks/useMultiStepForm';
import useRootStore from '../../../Shared/Hooks/useRootStore';
import Report from '../Components/ReportSettings/Report';
import { getUniqueId, isNullOrUndefined } from '../../../Shared/Utilities';
import { GrantReportPayload } from '../../../Models/API/Expensing/grant-report';

const Style = css({
	label: 'ExpensingStartPage',
	display: 'flex',
	flexDirection: 'column',
	width: '100rem',
	padding: '6rem 8rem',
	'&__title': {
		fontWeight: 500,
		fontSize: '2.5rem',
		marginBottom: '5rem',
	},
	'&__next-btn': {
		marginTop: '0.8rem',
	},
});

type Props = {
	onSubmitHandler?: () => void;
} & MultiStepElementProps;

const Settings = (props: Props) => {
	const { expensingStore } = useRootStore();
	const settingsRef = useRef<ForwardedRefType<GrantReportData>>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const onSubmitHandler = async () => {
		if (isNullOrUndefined(settingsRef.current)) return;

		const isValidated = await settingsRef.current.onValidate();

		if (!isValidated) return;
		const data = settingsRef.current.getData();
		expensingStore.newReportData = data;

		if (!data.report.reportEndDate || !data.report.expenseCompanyReportName || !data.report.reportStartDate) return;

		setIsLoading(true);
		const res = await expensingStore.getCompanyGrants(data.report.reportEndDate);
		if (res.data?.data.length) {
			setIsLoading(false);
			return props.onNextStep?.();
		}

		await expensingStore.runBlackAndScholes([]);
		await expensingStore.updateGrantsForfeitureRate([]);
		await expensingStore.createReport(data.report as GrantReportPayload);
		setIsLoading(false);
		props.onSubmitHandler?.();
	};

	return (
		<div className={Style}>
			<div className={`${Style}__title`}>New expensing report</div>
			<Report ref={settingsRef} data={expensingStore.newReportData} />
			<Separator />
			<Button
				className={`${Style}__next-btn`}
				isLoading={isLoading}
				qaid="ExpensingNewReport.Button.Next"
				label="Next"
				position="end"
				onClick={onSubmitHandler}
			/>
		</div>
	);
};

export default Settings;
