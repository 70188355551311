import { createTheme } from "@mui/material";

import appConfig from "../config/config";
import { theme as AltshareTheme } from "../config/style/theme";

const theme = createTheme({
    shape: {
        borderRadius: 10,
    },
    typography: {
        // htmlFontSize: 10,

        button: {
            textTransform: "none",
        },
        h1: {},
        h2: {},
        h3: {},
        h4: {},
        h5: {},
        h6: {},
    },
    palette: {
        // text: {},
        primary: {
            main: "#9999ff",
        },
        secondary: {
            main: "#fc9f30",
        },
        // success: {},
        // warning: {},
        error: {
            main: "#FB6446",
        },
        divider: "#dee0e7",

        // background: {},
    },
    components: {
        MuiSwitch: {
            styleOverrides: {
                root: {
                    fontSize: 10,
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: "6px",
                    boxShadow: "0px 1px 5px rgb(0 0 0 / 41%)",
                    border: "1px solid #707070",
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    ":after": {
                        border: "unset",
                    },
                },
                underline: {
                    ":before": {
                        border: "unset",
                    },
                    ":focus": {
                        border: "unset",
                    },
                    ":hover": {
                        border: "unset",
                        "::before": {
                            border: "unset !important",
                        },
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                standard: {
                    padding: "0.9rem 1.4rem",
                    border: `1px solid ${AltshareTheme.colors.dark}`,
                    borderRadius: AltshareTheme.borderRadiuses.low,
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    "& .MuiAutocomplete-inputRoot": {
                        outline: 0,
                        display: "flex",
                        width: "100%",
                        boxSizing: "border-box",
                        fontSize: "1.4rem",
                        alignItems: "center",
                        flexWrap: "wrap",
                        position: "relative",
                        padding: "0 1.4rem",
                        letterSpacing: 1,
                        fontFamily: appConfig.style.fonts.assistant,
                        border: `1px solid ${appConfig.style.colors.gray2}`,
                        borderRadius: 3,
                        minHeight: "30px",
                    },

                    "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
                        padding: 0,
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    // border: "inherit",
                    display: "none",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 20,
                    fontWeight: "bold",
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontFamily: AltshareTheme.fonts.main,
                    fontWeight: "bold",
                    minWidth: "150px",
                    fontSize: "1.6rem",
                    borderRadius: `${AltshareTheme.borderRadiuses.medium} ${AltshareTheme.borderRadiuses.medium} 0 0`,
                    background: AltshareTheme.gradients.main,
                    color: "white",
                    marginRight: "1.6rem",
                    "&.Mui-selected": {
                        background: "white",
                        color: AltshareTheme.colors.primary,
                        borderTop: AltshareTheme.borders.alt,
                        borderLeft: AltshareTheme.borders.alt,
                        borderRight: AltshareTheme.borders.alt,
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    paddingLeft: "2.5rem",
                },

                indicator: {
                    ".MuiTabs-root & ": {
                        display: "none",
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: "1.4rem",
                    background: appConfig.style.colors.background2,
                    letterSpacing: 1.15,
                    width: "fit-content",
                    maxWidth: "unset",
                },
            },
        },
    },
});

export default theme;
