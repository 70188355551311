import classNames from 'classnames';
import { SyntheticEvent, useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { rootStore } from '../../../..';
import { IC_REG_PROG_2 } from '../../../../Assets';
import { ICompanyRegistration } from '../../../../Models/API/Company';
import { ICompanyContactRegistration } from '../../../../Models/API/Company/company-contact';
import { UserStatus } from '../../../../Models/API/enums';
import { IUserRegistration } from '../../../../Models/App/Auth/RegUser';
import { Routes } from '../../../../Routes';
import Button from '../../../../Shared/Components/Button/Button';
import CheckBox from '../../../../Shared/Components/CheckBox/CheckBox';
import ErrorMessage from '../../../../Shared/Components/Layout/ErrorMessage';
import Flex from '../../../../Shared/Components/Layout/Flex';
import Text from '../../../../Shared/Components/Layout/Text';
import Select from '../../../../Shared/Components/Select/Select';
import TextInput from '../../../../Shared/Components/TextInput';
import { roleOptions } from '../../../../Shared/Config';
import useComplexState from '../../../../Shared/Hooks/useComplexState';
import { useObjectValidation } from '../../../../Shared/Hooks/useObjectValidation';
import useRootStore from '../../../../Shared/Hooks/useRootStore';
import useScreenSize from '../../../../Shared/Hooks/useScreenSize';
import { commonValidators, successResult } from '../../../../Shared/ObjectValidator';
import { REGEX_PASSWORD, getUniqueId, isNumber } from '../../../../Shared/Utilities';
import VerifyMailMsg from './VerifyMailMsg';
import useModal from '../../../../Shared/Hooks/useModal';
import { Comment } from '../../../../Shared/Components/Input/Input.Style';
import useQueryParams from '../../../../Shared/Hooks/useQueryParams';

export const AccountCreateSecondPhase = () => {
	const queryParams = useQueryParams();
	const { auth, companyStore, appState } = useRootStore();
	const { isMobile } = useScreenSize();
	const { showModal } = useModal();
	const location = useLocation();
	const history = useHistory();
	const params = useParams<{ uniquekey: string }>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [userData, setUserData] = useComplexState<IUserRegistration>({
		firstName: auth.userInfo.firstName,
		lastName: auth.userInfo.lastName,
		email: auth.userInfo.email,
		isMarketingSubscription: true,
		isTermsOfUse: false,
	});
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [isPasswordWeak, setIsPasswordWeak] = useState<boolean>(false);
	const [urlKey, setUrlKey] = useState<string>();
	const isInvitationRegistration = location.pathname.includes(Routes.account.invite);
	const isInvitationEmpRegistration = location.pathname.includes(Routes.account.inviteEmp);
	const [isValidUser, userValState] = useObjectValidation(userData, {
		privateEmail: isInvitationEmpRegistration
			? [
					commonValidators.required(),
					commonValidators.emailValidator,
					(v) => {
						if (v === userData.email) {
							return {
								isValid: false,
								message: 'Private email must be different from company email',
							};
						}
						return successResult;
					},
			  ]
			: [],
		password: [
			(v) => {
				if (urlKey === 'gsign') {
					return {
						isValid: true,
						message: '',
					};
				}
				if (v && v.length >= 8) {
					return successResult;
				}
				return {
					isValid: false,
					message: 'Password must be at least 8 characters long',
				};
			},
			commonValidators.regex(REGEX_PASSWORD, 'The password does not meet the requirements'),
		],
		confirmPassword: [
			(v) => {
				if (urlKey === 'gsign') {
					return {
						isValid: true,
						message: '',
					};
				}
				if (v === userData.password) {
					return successResult;
				}
				return {
					isValid: false,
					message: 'Password & Confirm Password do not match',
				};
			},
		],
		firstName: [
			commonValidators.required(),
			commonValidators.minLength(2),
			commonValidators.regex(/^[a-zA-Z !"#$%&'()*+,/\-.:;<=>?@[\\\]^_`{|}~ ]+$/, 'Only letters, space or special characters allowed'),
		],
		lastName: [
			commonValidators.required(),
			commonValidators.minLength(2),
			commonValidators.regex(/^[a-zA-Z !"#$%&'()*+,/\-.:;<=>?@[\\\]^_`{|}~ ]+$/, 'Only letters, space or special characters allowed'),
		],
		companyName: isMobile || isInvitationRegistration ? [] : [commonValidators.required('Mandatory field'), commonValidators.minLength(2)],
		role: isMobile || isInvitationRegistration ? [] : [commonValidators.required()],
		isTermsOfUse:
			isInvitationEmpRegistration || isInvitationRegistration
				? [commonValidators.required('Approval of Privacy Policy and Terms & Conditions is mandatory')]
				: [],
	});

	useEffect(() => {
		if (isInvitationRegistration && params.uniquekey && !auth.userInfo?.firstName) {
			(async () => {
				if (await auth.getCurrentUserInfoByUniqueKey(params.uniquekey)) {
					setUserData({ ...auth.userInfo });
				} else {
					history.push('/');
				}
			})();
		}
		if (Object.keys(queryParams).length) {
			appState.redirectUrlAfterLogin = queryParams.redirect;
		}
	}, []);

	useEffect(() => {
		if (params.uniquekey) {
			setUrlKey(params.uniquekey);
		}
	}, [params.uniquekey]);

	const showError = (body?: string) => {
		if (!body) return;
		showModal({
			type: 'error',
			title: 'Registration Error',
			body,
		});
	};

	const submitForm = async (e: any) => {
		e.preventDefault();
		setIsLoading(true);
		setIsSubmitted(true);

		if (isValidUser) {
			userData.uniqueKey = params.uniquekey;

			if (isInvitationRegistration) {
				const res = await auth.completeInvitation(userData);
				if (res.isSuccess && res.data) {
					setIsLoading(false);

					if (isInvitationEmpRegistration) {
						showModal({
							body: <VerifyMailMsg email={userData.privateEmail!} />,
							isMust: true,
							maxWidth: '100%',
							width: '80rem',
						});
					} else if (res.isSuccess) {
						auth.handleUserData(UserStatus.Active);
					} else {
						showError(res.error);
					}
				}

				setIsLoading(false);
				return;
			}

			const res = await auth.userCreateAccountSecondPhase(userData, urlKey);

			let errServer = res.error;

			if (res.isSuccess && res.data) {
				if (isMobile) {
					rootStore.auth.updateUser(res.data);

					history.push(Routes.account.companySetup);
				} else {
					const resCompanyCreate = await companyStore.createCompany({
						companyLegalName: userData.companyName,
						companyContact: {
							firstName: userData.firstName,
							lastName: userData.lastName,
							role: userData.role,
						} as ICompanyContactRegistration,
					} as ICompanyRegistration);

					if (resCompanyCreate.isSuccess) {
						setIsLoading(false);
						rootStore.auth.updateUser(res.data);
						history.push(Routes.account.companySetup);

						return;
					}

					errServer = resCompanyCreate.error;
				}

				setIsLoading(false);
				// setErrorMsg(errServer);
				showError(errServer);
			}

			// setErrorMsg(errServer);
			showError(errServer);
		}

		setIsLoading(false);
	};

	return (
		<section
			className={classNames({
				'form-account-box': true,
				mobile: isMobile,
			})}
		>
			<div>
				<div
					className={classNames('form-header', {
						mobile: isMobile,
					})}
				>
					{!isInvitationRegistration && !isInvitationEmpRegistration && (
						<div className="form-progress">
							<img className="ic" src={IC_REG_PROG_2} alt="progress logo" />
						</div>
					)}
					<div className="form-title">Account set up</div>
				</div>
				<div
					className={classNames({
						'setup-form': true,
						mobile: isMobile,
					})}
				>
					<form onSubmit={submitForm}>
						<Flex direction={isMobile ? 'column' : 'row'}>
							<Flex
								className={classNames({
									'form-input-group required': true,
									'form-input-group-mobile': isMobile,
								})}
								position="relative"
								margin={isMobile ? '0' : '0 15px 0 0'}
							>
								<TextInput
									className={classNames({
										'account-input': true,
										mobile: isMobile,
									})}
									disabled={isInvitationRegistration}
									placeholder="First name *"
									qaid="AccountCreateSecondPhase.Input.FirstName"
									value={userData.firstName}
									hasError={isSubmitted && !userValState?.firstName?.isValid}
									onChange={(e) => {
										setUserData({ firstName: e });
									}}
								/>
								<div
									className={classNames('form-error', {
										mobile: isMobile,
									})}
								>
									{isSubmitted && userValState?.firstName?.message}
								</div>
							</Flex>
							<Flex className="form-input-group required" position="relative" margin={isMobile ? '30px 0 0 0 ' : '0 0 0 15px'}>
								<TextInput
									className={classNames({
										'account-input': true,
										mobile: isMobile,
									})}
									placeholder="Last name *"
									disabled={isInvitationRegistration}
									qaid="AccountCreateSecondPhase.Input.LastName"
									value={userData.lastName}
									hasError={isSubmitted && !userValState?.lastName?.isValid}
									onChange={(e) => {
										setUserData({ lastName: e });
									}}
								/>
								<div
									className={classNames('form-error', {
										mobile: isMobile,
									})}
								>
									{isSubmitted && userValState?.lastName?.message}
								</div>
							</Flex>
						</Flex>
						{isInvitationRegistration ? (
							<>
								<Flex className="form-input-group required" position="relative" width={'100%'} margin={'30px 0 0 0'}>
									<TextInput
										className={classNames({
											'account-input': true,
											mobile: isMobile,
										})}
										placeholder="Email Address *"
										disabled={isInvitationRegistration}
										qaid="AccountCreateSecondPhase.Input.Email"
										value={userData.email}
										onChange={(e) => {
											setUserData({ email: e });
										}}
									/>

									<div
										className={classNames('form-error', {
											mobile: isMobile,
										})}
									>
										{isSubmitted && userValState?.email?.message}
									</div>
								</Flex>
								{isInvitationEmpRegistration && (
									<Flex className="form-input-group required" position="relative" width={'100%'} margin={'30px 0 0 0'}>
										<TextInput
											className={classNames({
												'account-input': true,
												mobile: isMobile,
											})}
											placeholder="Private Email Address *"
											qaid="AccountCreateSecondPhase.Input.PrivateEmail"
											value={userData.privateEmail}
											onChange={(e) => {
												setUserData({ privateEmail: e });
											}}
										/>

										<div
											className={classNames('form-error', {
												mobile: isMobile,
											})}
										>
											{isSubmitted && userValState?.privateEmail?.message}
										</div>
									</Flex>
								)}
							</>
						) : (
							!isMobile && (
								<>
									<div className="account-input-row-1">
										<Flex className="form-input-group required" position="relative" width={'100%'}>
											<TextInput
												className={classNames({
													'account-input': true,
													mobile: isMobile,
												})}
												placeholder="Company name *"
												qaid="AccountCreateSecondPhase.Input.CompanyName"
												value={userData.companyName}
												hasError={isSubmitted && !userValState?.companyName?.isValid}
												name="companyName"
												onChange={(e) => {
													setUserData({ companyName: e });
												}}
											/>

											<div
												className={classNames('form-error', {
													mobile: isMobile,
												})}
											>
												{isSubmitted && userValState?.companyName?.message}
											</div>
										</Flex>
									</div>
									<div className="account-input-row-1">
										<div className="form-input-group required" style={{ position: 'relative' }}>
											<Select
												qaid="AccountCreateSecondPhase.Select.UserRole"
												defaultValue="Your role *"
												className={classNames('mb-0', 'login-select')}
												onChange={(value) => {
													isNumber(value) && setUserData({ role: value });
												}}
												options={roleOptions}
												value={userData.role}
											/>
											<div
												className={classNames('form-error', {
													mobile: isMobile,
												})}
											>
												{isSubmitted && userValState?.role?.message}
											</div>
										</div>
									</div>
								</>
							)
						)}

						{urlKey !== 'gsign' && (
							<>
								<Flex position={'relative'}>
									<Flex align="center" margin={'25px 0 35px 0'} direction={isMobile ? 'column' : 'row'}>
										<Flex direction="column" position="relative" margin={isMobile ? '0 ' : '0 10px 0 0'}>
											<Flex align="center" position="relative" width={isMobile ? '100%' : 'auto'}>
												<TextInput
													className={classNames({
														'account-input account-input-password': true,
														mobile: isMobile,
													})}
													placeholder="Password * "
													qaid="AccountCreateSecondPhase.Input.Password"
													value={userData.password}
													hasError={isSubmitted && !userValState?.password?.isValid}
													isPassword
													capsLockPosition={isMobile ? -144.5 : userValState?.password?.isValid ? 'bottom' : -70}
													onChange={(e) => {
														setIsPasswordWeak(false);
														setUserData({ password: e });
													}}
												/>
											</Flex>
											{!!userValState?.password?.message && isSubmitted && (
												<Text
													className={classNames({
														'input-label': true,
														'text-danger': !userValState?.password?.isValid,
													})}
												>
													{userValState?.password?.message}
												</Text>
											)}
										</Flex>
										<Flex direction="column" margin={isMobile ? '15px 0 0 0 ' : '0 0 0 10px'}>
											<Flex align="center" position="relative" width={isMobile ? '100%' : 'auto'}>
												<TextInput
													className={classNames({
														'account-input account-input-password': true,
														mobile: isMobile,
													})}
													placeholder="Confirm password *"
													qaid="AccountResetPassword.Input.ConfirmPassword"
													value={userData.confirmPassword}
													hasError={isSubmitted && userValState?.password?.isValid && !userValState?.confirmPassword?.isValid}
													isPassword
													capsLockPosition={isMobile ? -60 : isSubmitted && userValState?.confirmPassword?.isValid ? 'bottom' : -90}
													onChange={(e) => {
														setIsPasswordWeak(false);
														setUserData({ confirmPassword: e });
													}}
												/>
												{isSubmitted && userValState?.password?.isValid && !userValState?.confirmPassword?.isValid && (
													<div
														className={classNames('form-error', {
															mobile: isMobile,
														})}
													>
														{userValState?.confirmPassword?.message}
													</div>
												)}
											</Flex>
										</Flex>
									</Flex>
								</Flex>
								<Flex position="relative" justify="center" align="center">
									<Comment center style={{ top: '1rem' }}>
										Password requirements: 8+ characters, 1 uppercase, 1 lowercase, 1 numeric, 1 special character
									</Comment>
								</Flex>
							</>
						)}
						{(isInvitationRegistration || isInvitationEmpRegistration) && (
							<Flex margin="4rem 0 0 0" gap="1.6rem" direction="column" align="start">
								<CheckBox
									isChecked={userData.isTermsOfUse}
									onClick={(e) => {
										setUserData({ isTermsOfUse: !userData.isTermsOfUse });
									}}
									size="2.7rem"
									label={() => (
										<span>
											I accept altshare’s{' '}
											<a
												key="privacy"
												className="terms-link"
												onClick={(e: SyntheticEvent) => e.stopPropagation()}
												href={Routes.privacy}
												target="_blank"
												rel="noreferrer"
											>
												Privacy Policy
											</a>{' '}
											and{' '}
											<a
												key="tos"
												className="terms-link"
												onClick={(e: SyntheticEvent) => e.stopPropagation()}
												href={Routes.tos}
												target="_blank"
												rel="noreferrer"
											>
												Terms & Conditions
											</a>
										</span>
									)}
									qaid="AccountCreateFirstPhase.CheckBox.PrivacyPolicy"
									inputClassName="terms-cb-input"
									className="terms-cb"
								/>
								<CheckBox
									isChecked={userData.isMarketingSubscription}
									size="2.7rem"
									onClick={(e) => {
										setUserData({ isMarketingSubscription: !userData.isMarketingSubscription });
									}}
									label={'I agree to receive marketing communications from altshare'}
									qaid="AccountCreateFirstPhase.CheckBox.MarketingSubscriptions"
									inputClassName="terms-cb-input"
									className={classNames('terms-cb', 'hide')}
								/>
							</Flex>
						)}

						<Button
							qaid="AccountCreateSecondPhase.Button.SignUp"
							onClick={submitForm}
							position="center"
							isLoading={isLoading}
							className={classNames({
								'account-orange-butt': true,
								mobile: isMobile,
							})}
						>
							Continue
						</Button>
						{appState.isDev && (
							<Button
								className={classNames({
									'account-orange-butt': true,
									mobile: isMobile,
									devOnly: true,
								})}
								style={{ marginTop: '2rem' }}
								onClick={() => {
									setUserData({
										firstName: 'Lorem',
										lastName: 'ipsum',
										companyName: 'Company name+' + getUniqueId(),
										role: 1,
										password: 'Aa123123!1',
										confirmPassword: 'Aa123123!1',
									});
								}}
								qaid={''}
							>
								Fill data (dev only)
							</Button>
						)}
					</form>
				</div>
			</div>
		</section>
	);
};

export default AccountCreateSecondPhase;
