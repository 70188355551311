export const numOfEmployees = [
	{ label: "1-10", value: 1 },
	{ label: "11-50", value: 11 },
	{ label: "51-200", value: 51 },
	{ label: "201-500", value: 201 },
	{ label: "501-1000", value: 501 },
	{ label: "1001-5000", value: 1001 },
	{ label: "5001-10,000", value: 5001 },
	{ label: "10,001+", value: 10001 },
];
