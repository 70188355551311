import styled from '@emotion/styled';
import appConfig from '../../../config/config';

const Label = styled.label({
	label: 'RadioButtonLabel',
	display: 'flex',
	alignItems: 'center',
	// paddingLeft: 20,
	cursor: 'pointer',
	span: {
		minWidth: 'fit-content',
		'&.disabled': {
			opacity: 0.6,
		},
	},
});

const Input = styled.input({
	display: 'none',
	label: 'RadioButton',
});

const Box = styled.div(
	{
		label: 'RadioButtonCheckBox',
		background: '#ffffff',
		height: '2.8rem',
		minWidth: '2.8rem',
		maxWidth: '2.8rem',
		border: `2px solid ${appConfig.style.colors.color1}`,
		boxShadow: appConfig.style.boxShadow.card4,
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		transition: 'background 0.15s, border-color 0.15s',
		padding: '0.2rem',

		'.Table--LiquidationPreferences &, .Table--PreferencesTerms &': {
			height: '2.4rem',
			width: '2.4rem',
			minWidth: 'unset',
		},
	},
	({ isChecked, isDisabled, isLabeled }: { isChecked?: boolean; isDisabled?: boolean; isLabeled?: boolean }) => ({
		...(isChecked
			? {
					'&::after': {
						content: "''",
						width: '1.4rem',
						height: '1.4rem',
						display: 'block',
						background: appConfig.style.colors.gradientPurpleHorizontal,
						borderRadius: '50%',
						cursor: 'pointer',

						'.Table--LiquidationPreferences &, .Table--PreferencesTerms &': {
							height: '1.2rem',
							width: '1.2rem',
						},
					},
			  }
			: {}),
		...(isDisabled
			? {
					opacity: 0.6,
					cursor: 'default',
			  }
			: {
					cursor: 'pointer',
			  }),
		...(isLabeled
			? {
					marginRight: '1.2rem',
			  }
			: {}),
	})
);

const StyledRadioButton = {
	Label,
	Input,
	Box,
};

export default StyledRadioButton;
