import { AltProps } from "../../../shared/dependencies/types/types";
import {
  Content,
  Figure,
  Icon,
  Label,
  StyledAltSingleFigureWidget,
} from "./SingleFigureWidget.style";

interface Props {
  iconPath?: string;
  label: string;
  figure: string;
}
export interface SingleFigureWidgetProps extends AltProps<Props> {}

const SingleFigureWidget = ({ ap }: SingleFigureWidgetProps) => {
  const { iconPath, label, figure } = ap;

  return (
    <StyledAltSingleFigureWidget>
      <Icon src={iconPath} />
      <Content>
        <Label>{label}</Label>
        <Figure>{figure}</Figure>
      </Content>
    </StyledAltSingleFigureWidget>
  );
};

export default SingleFigureWidget;
