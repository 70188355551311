export type DataCollectionComment = {
	dataCollectionId: string;
	fieldType: DataCollectionCommentFieldType;
	commentDescription: string;
	createDate: Date;
	id: string;
};

export enum DataCollectionCommentFieldType {
	ValuationDate = 1,
	InvestmentExist = 2,
	InvestmentType = 3,
	CompanyProfitable = 4,
	AboutUs = 5,
	NextRoundValuation = 6,
	CapTable = 7,
}
