import styled from '@emotion/styled';
import appConfig from '../../../config/config';
import { IButtonProps } from './Button';

const StyledButton = styled.button(
	{
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '1rem 1.6rem',
		transition: 'all .1s',
		userSelect: 'none',
		textAlign: 'inherit',
		lineHeight: 'normal',
		flexShrink: 0,
		// position: "relative",
		cursor: 'pointer',
		'.icon-prepend': {
			width: 'auto',
			height: '1rem',
			paddingRight: '1rem',
		},
		'.icon-append': {
			width: 'auto',
			height: '1rem',
			paddingLeft: '1rem',
		},
	},
	({ inverse, width, height, cancel, position, small, fullWidth, isLoading, autoWidth }: Partial<IButtonProps>) => ({
		background: inverse ? '#ffffff' : appConfig.style.colors.gradientPurpleHorizontal,
		border: `1px solid ${inverse ? (cancel ? appConfig.style.colors.cancel : appConfig.style.colors.color1) : appConfig.style.colors.color1}`,
		fontSize: small ? '1.2rem' : '1.4rem',
		height: small ? '2.8rem' : height || '3.6rem',
		color: inverse ? (cancel ? appConfig.style.colors.text4 : appConfig.style.colors.color1) : '#ffffff',
		// maxHeight: small ? "2.8rem" : height || "3.6rem",
		// minHeight: small ? "2.8rem" : height || "3.6rem",
		width: fullWidth ? '100%' : width || 'max-content',
		minWidth: autoWidth ? 0 : small ? '8.5rem' : width || '10.5rem',
		fontFamily: appConfig.style.fonts.assistant,
		fontWeight: 600,
		borderRadius: appConfig.style.borderRadius.medium,

		alignSelf: position !== 'center' ? (position === 'topRight' ? 'flex-start' : `flex-${position}`) : position,
		justifySelf: position !== 'center' ? (position === 'topRight' ? 'flex-end' : `flex-${position}`) : position,
		...(isLoading
			? {}
			: {
					'&:hover:not(&:disabled)': {
						background: cancel ? appConfig.style.colors.cancel : appConfig.style.colors.purple,
						color: !inverse
							? cancel
								? appConfig.style.colors.cancel
								: appConfig.style.colors.color1
							: cancel
							? '#ffffff'
							: appConfig.style.colors.color1,
					},
			  }),
		...(position === 'center'
			? {
					margin: '0 auto',
			  }
			: {}),
		'&:disabled': {
			opacity: 0.6,
		},
		...(small ? { span: { marginBottom: 1 } } : {}),
		...(cancel
			? {}
			: {
					boxShadow: appConfig.style.boxShadow.light,
			  }),
	})
);

export default StyledButton;
