import { useState } from "react";
import { useHistory } from "react-router-dom";
import { IC_REG_PROG_3, IMG_LOGO_NAME } from "../../../../Assets";
import { Routes } from "../../../../Routes";
import useComplexState from "../../../../Shared/Hooks/useComplexState";
import { useObjectValidation } from "../../../../Shared/Hooks/useObjectValidation";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import { commonValidators } from "../../../../Shared/ObjectValidator";
import { useTrans } from "../../../../Shared/Components/Trans";
import Button from "../../../../Shared/Components/Button/Button";
import { SelectListItem } from "../../../../Models/App/SelectListItem";
import { useEffectOnce } from "react-use";
import { companyCurrncies } from "../../../../Shared/StaticData/company-currencies";
import { countries } from "../../../../Shared/StaticData/countries";
import { numOfEmployees } from "../../../../Shared/StaticData/numOfEmployees";
import i18n from "../../../../Shared/Middleware/i18n";
import { rootStore } from "../../../..";
import { IAddressRegistration, ICompanyRegistration, ICompanyUpdate } from "../../../../Models/API/Company";
import { UserStatus } from "../../../../Models/API/enums";
import classNames from "classnames";
import { MobileView } from "react-device-detect";
// import TextInput from "../../../../Shared/Components/TextInput";
import { customSelectStyles, roleOptions } from "../../../../Shared/Config";
import { ICompanyProfileRegistration } from "../../../../Models/App/Auth/RegUser";
import useModal from "../../../../Shared/Hooks/useModal";
import MobileRegCompleteMsg from "./MobileRegCompleteMsg";
import useScreenSize from "../../../../Shared/Hooks/useScreenSize";
import Select from "../../../../Shared/Components/Select/Select";
import { isNullOrUndefined, isNumber } from "../../../../Shared/Utilities";
import Flex from "../../../../Shared/Components/Layout/Flex";
import ErrorMessage from "../../../../Shared/Components/Layout/ErrorMessage";
import { ICompanyContactRegistration } from "../../../../Models/API/Company/company-contact";
import AutoCompleteInput from "../../../../Shared/Components/Input/AutoCompleteInput";
import { useFormValidation } from "../../../../Shared/Hooks/useFormValidation";
import { useAppendState } from "../../../../Shared/Hooks/useAppendState";
import TextInput from "../../../../Shared/Components/Input/TextInput";

interface IProps {}

export const AccountCompanyProfile = (props: IProps) => {
	const { companyStore, auth, appState } = useRootStore();
	const history = useHistory();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [errorMsg, setErrorMsg] = useState<JSX.Element | string>();
	const { isMobile } = useScreenSize();

	const [companyInfo, setCompanyInfo, onInputHandler] = useAppendState<ICompanyProfileRegistration>();
	const { isFormValid: isValidcompany, formValidationState: companyValState } = useFormValidation({
		form: companyInfo,
		schema: {
			companyName: isMobile ? [commonValidators.required(), commonValidators.minLength(2)] : [],
			role: isMobile ? [commonValidators.required()] : [],
			nationality: [
				(v) => {
					console.log("​AccountCompanyProfile -> v", {
						isValid: !isNullOrUndefined(v),
						message: v === null ? "Please select a country name from the list" : v === undefined ? "Required field" : undefined,
					});
					return {
						isValid: !isNullOrUndefined(v),
						message: v === null ? "Please select a country name from the list" : v === undefined ? "Required field" : undefined,
					};
				},
			],
			currency: [commonValidators.required()],
			noOfEmployees: [commonValidators.required()],
		},
	});

	const numOfEmployeesWithText = numOfEmployees.map((e) => ({
		label: e.label + " Employees",
		value: e.value,
	}));

	const submitform = async (e: any) => {
		e.preventDefault();
		setIsLoading(true);
		setIsSubmitted(true);

		if (isValidcompany) {
			if (isMobile) {
				await submitMobile();
			} else {
				const resUpdateCompany = await companyStore.updateCompany(rootStore.companyStore.companyId, {
					currency: companyInfo.currency,
					noOfEmployees: companyInfo.noOfEmployees,
				} as ICompanyUpdate);

				let errRequest = resUpdateCompany.error;

				if (resUpdateCompany.isSuccess) {
					const country = countries.find((e) => e.value === companyInfo.nationality);

					if (!country) {
						return;
					}

					const resAddressCreate = await companyStore.addAddress(rootStore.companyStore.companyId, {
						country: country.label,
					} as IAddressRegistration);

					if (resAddressCreate.isSuccess) {
						setIsLoading(false);
						history.push(Routes.account.completeRegisration);

						return;
					}
				}

				setErrorMsg(errRequest);
			}
		}

		setIsLoading(false);
	};

	const submitMobile = async () => {
		const resCreateCompany = await companyStore.createCompany({
			companyLegalName: companyInfo.companyName,
			currency: companyInfo.currency,
			noOfEmployees: companyInfo.noOfEmployees,
			companyContact: {
				firstName: auth.userInfo?.firstName,
				lastName: auth.userInfo?.lastName,
				role: companyInfo.role,
			} as ICompanyContactRegistration,
		} as ICompanyRegistration);

		let errRequest = resCreateCompany.error;

		errRequest = resCreateCompany.error;

		if (resCreateCompany.isSuccess && companyInfo.nationality) {
			const country = countries.find((e) => e.value === companyInfo.nationality);
			if (!country) {
				return;
			}

			const resAddressCreate = await companyStore.addAddress(rootStore.companyStore.companyId, {
				country: country?.label,
			} as IAddressRegistration);

			errRequest = resAddressCreate.error;

			if (resAddressCreate.isSuccess) {
				const resRole = await auth.addUserRole(companyInfo.role);
				errRequest = resRole.error;

				if (resRole.isSuccess) {
					setIsLoading(false);
					history.push(Routes.account.completeRegisration);

					return;
				}
			}

			setErrorMsg(errRequest);
		}

		setIsLoading(false);
	};

	return (
		<section
			className={classNames({
				"form-account-box": true,
				mobile: isMobile,
			})}
		>
			<div>
				<div
					className={classNames({
						"form-header": true,
						mobile: isMobile,
						visible: true,
					})}
				>
					<div className="form-progress">
						<img className="ic" src={IC_REG_PROG_3} alt="progresss logo" />
					</div>
					<div className="form-title">Company profile</div>
				</div>
				<div
					className={classNames({
						"setup-form": true,
						mobile: isMobile,
					})}
				>
					<form onSubmit={submitform}>
						<div className="loginFields">
							{isMobile && (
								<>
									<div className="account-input-row-1">
										<Flex className="form-input-group required" position="relative" width={"100%"}>
											<TextInput
												containerClassName={classNames("mb-0 w-100", {
													"account-input": true,
													mobile: isMobile,
												})}
												placeholder="Company name *"
												qaid="AccountCreateSecondPhase.Input.CompanyName"
												value={companyInfo.companyName}
												// error={companyValState?.companyName?.message}
												onChange={onInputHandler}
												name="companyName"
											/>
											<ErrorMessage top={"5.3rem"} left={"1.2rem"}>
												{isSubmitted && companyValState?.companyName?.message}
											</ErrorMessage>
										</Flex>
									</div>
									<div className="account-input-row-1">
										<Flex className="form-input-group required" position="relative" width={"100%"}>
											<Select
												qaid="AccountCreateSecondPhase.Select.UserRole"
												defaultValue="Your role *"
												className={classNames("mb-0", "login-select")}
												options={roleOptions}
												value={companyInfo.role}
												onChange={onInputHandler}
												name="role"
											/>

											<ErrorMessage top={"5rem"} left={"1.2rem"}>
												{isSubmitted && companyValState?.role?.message}
											</ErrorMessage>
										</Flex>
									</div>
								</>
							)}

							<div className="account-input-row-1">
								<Flex className="form-input-group required" position="relative" width={"100%"}>
									<AutoCompleteInput
										qaid="AccountCreateSecondPhase.Select.Country"
										placeholder="Country of incorporation *"
										className="login-select"
										containerClassName="mb-0"
										width="100%"
										autoComplete="nope"
										onChange={onInputHandler}
										name="nationality"
										options={countries}
										value={companyInfo.nationality ?? undefined}
									/>

									<ErrorMessage top={"5rem"} left={"1.2rem"}>
										{isSubmitted && companyValState?.nationality?.message}
									</ErrorMessage>
								</Flex>
							</div>
							<div className="account-input-row-1">
								<Flex className="form-input-group required" position="relative" width={"100%"}>
									<Select
										qaid="AccountCreateSecondPhase.Select.currency"
										defaultValue="Currency *"
										className={classNames("mb-0", "login-select")}
										options={companyCurrncies}
										value={companyInfo.currency}
										onChange={onInputHandler}
										name="currency"
									/>

									<ErrorMessage top={"5rem"} left={"1.2rem"}>
										{isSubmitted && companyValState?.currency?.message}
									</ErrorMessage>
								</Flex>
							</div>
							<div className="account-input-row-1">
								<Flex className="form-input-group required" position="relative" width={"100%"}>
									<Select
										qaid="AccountCreateSecondPhase.Select.companysize"
										defaultValue="Company size *"
										className={classNames("mb-0", "login-select")}
										options={numOfEmployeesWithText}
										value={companyInfo.noOfEmployees}
										onChange={onInputHandler}
										name="noOfEmployees"
									/>

									<ErrorMessage top={"5rem"} left={"1.2rem"}>
										{isSubmitted && companyValState?.noOfEmployees?.message}
									</ErrorMessage>
								</Flex>
							</div>

							<Flex position="relative">
								<ErrorMessage top={"4rem"} width="100%">
									{errorMsg}
								</ErrorMessage>
							</Flex>
							<div className="buttons">
								<Button
									qaid="AccountCompanyProfile.Button.SignUp"
									onClick={submitform}
									position="center"
									isLoading={isLoading}
									className={classNames({
										"account-orange-butt": true,
										mobile: isMobile,
									})}
								>
									Create account
								</Button>
								{appState.isDev && (
									<Button
										className={classNames({
											"account-orange-butt": true,
											mobile: isMobile,
											devOnly: true,
										})}
										style={{ marginTop: "2rem" }}
										onClick={() => {
											setCompanyInfo({
												nationality: 1,
												currency: 1,
												noOfEmployees: 1,
												companyName: "test",
												role: 1,
											});
										}}
										qaid={""}
									>
										Fill data (dev only)
									</Button>
								)}
							</div>
						</div>
					</form>
				</div>
			</div>
		</section>
	);
};

export default AccountCompanyProfile;
