import { CSSProperties, ReactElement, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { FilterDto, PagedTableReqParams } from "../../../../Screens/TrusteeEquity/Types/ReqRes";
import {
    IC_BRAND_LOGO_BLACK,
    IC_CHEVRON_DOWN,
    IC_CHEVRON_LEFT,
    IC_CHEVRON_RIGHT,
    IC_CHEVRON_UP,
    IC_FUNEL,
    IC_LOOKUP,
} from "../../../../Assets";
import { formatDate, formatNumber, isNullOrUndefined } from "../../../Utilities";
import Select from "../../Select/Select";
import { QueryOperator, shitOptions } from "../../../../Screens/TrusteeEquity/StaticData/dropDownValues";
import TextInput from "../../Input/TextInput";
import Button from "../../Button/Button";
import { AndOrConnector, SqlOperator } from "../../../../Screens/TrusteeEquity/Types/trusteeTypes";
import { NumberOption } from "../../../../Models/API/All/NumberOption";

import tableStyle from "./PagedTable.module.css";
import HeaderRow from "./PagedTableHeader";
import BodyRows from "./PagedTableBody";
import FilterData, { FilterLevel } from "./PagedTableFilter";

export interface PagedTableKeys<T> {
    key: keyof T
    displayValue: string
    // modifiedValue?: (cellData: string, rowData: T) => string | number
    entityEnumValue?: number
    searchable?: boolean
    customCell?: (data: string, row: T) => React.ReactChild
}

interface TableDataMock {
    fullName: string;
    dateOfBirth: Date;
    proceeds: number;
}

// interface PagedTableProps<T> {
//     tableData: T[]
//     tableKeys: PagedTableKeys<T>[]
//     onPageChange: (page: number) => any
//     onSort?: (columnIndex: number, isAscending: boolean) => any
//     onSearch?: (searchStr: string) => any
// }

interface PagedTableProps<T> {
    tableData: T[];
    tableKeys: PagedTableKeys<T>[];
    totalRows: number;
    itemsInPage?: number;
    headerChild?: React.ReactChild;
    onSort?: (params: PagedTableReqParams) => any;
    newDataReq: (params: PagedTableReqParams) => Promise<number | undefined>;
    onSearch?: (searchStr: string) => any;
    onTableRowClick?: (obj: T) => any;
    search?: boolean;
    filter?: boolean;
    headerStyle?: CSSProperties;
    rowStyle?: CSSProperties;

}

const PagedTable = <T extends Object>(props: PagedTableProps<T>) => {
    const page = useRef(1);


    const [params, setParams] = useState<PagedTableReqParams>({ PageNumber: 1, ItemInPage: props.itemsInPage })
    const [currentPage, setCurrentPage] = useState(1)
    const [showAllPages, setShowAllPages] = useState(false)
    const [dataLoading, setDataLoading] = useState(false)
    const [tableData, setTableData] = useState<T[]>([])
    const [totalPages, setTotalPages] = useState(1)
    const [showFiltration, setShowFiltration] = useState(false)


    // console.log('Table props', props)
    // console.log('Table params', params)

    const getData = async (params: PagedTableReqParams) => {
        const rows = await props.newDataReq(params);

        if (!rows) return
    }

    const filterTableData = (data: T[], tableKeys: PagedTableKeys<T>[]) => {
        const headerItems = tableKeys.map((key) => key.key as string);
        const itemsToRender = tableKeys.filter((k) => k.customCell);

        const itemsInPage = params.ItemInPage;
        const items = data.length;

        const pages = Math.ceil(props.totalRows / itemsInPage!);
        // console.log('Pages -- inpage, total', props.tableKeys, itemsInPage, props.totalRows, pages)
        setTotalPages(pages);

        let filteredData: T[] = [];

        if (!Array.isArray(data)) return;

        data?.forEach((row) => {
            const tableMap = new Map();
            headerItems.forEach((hItem) => {
                const renderItem = itemsToRender.find((item) => item.key === hItem);
                if (renderItem) {
                    // console.log('rendererer item', hItem)

                    const rendered = renderItem.customCell && renderItem.customCell(row[hItem as keyof T] as string, row);
                    tableMap.set(hItem, rendered);
                } else {
                    tableMap.set(hItem, row[hItem as keyof T]);
                }
            });

            const objected = Object.fromEntries(tableMap);
            filteredData.push(objected);
        });

        setTableData(filteredData);
    };

    const nexPage = () => {
        if (page.current + 1 > totalPages) return;

        page.current++;
        setCurrentPage(page.current);
        setParams((prm) => {
            prm.PageNumber = page.current;

            props.newDataReq && props.newDataReq(prm);
            return prm;
        });
    };

    const prevPage = () => {
        if (page.current - 1 === 0) return;

        page.current--;
        setCurrentPage(page.current);
        setParams((prm) => {
            prm.PageNumber = page.current;

            props.newDataReq(prm);
            return prm;
        });
    };

    const pageSelectHandler = (pageNum: number) => {
        page.current = pageNum;
        setCurrentPage(pageNum);
        setParams((prm) => {
            prm.PageNumber = pageNum;

            props.newDataReq(prm);
            return prm;
        });
    };

    const onTableSort = (columnIndex: number, isAscending: boolean) => {
        setParams((prm) => {
            prm.Descending = isAscending;
            prm.OrderBy = columnIndex;
            // prm.PageNumber = currentPage

            props.newDataReq && props.newDataReq(prm);
            return prm;
        });

        setCurrentPage(1);
    };

    const onTableFiltration = async (filters: FilterLevel[]) => {
        setParams((prm) => {
            const filtersDto: FilterDto[] = filters.map((filter) => {
                const dto = {} as FilterDto;
                dto.Filed = filter.entityEnum;
                dto.AndOrConnector = filter.connector;
                dto.SqlOperator = filter.operator;
                dto.Value = filter.serachValue;

                return dto;
            });

            prm.filterDtos = JSON.stringify(filtersDto);
            // prm.PageNumber = 1

            getData(prm);
            return prm;
        });

        setCurrentPage(1);
    };

    const onTableSearch = (str: string) => {
        const filters: FilterDto[] = props.tableKeys
            ?.filter((key) => key.searchable && !isNullOrUndefined(key.entityEnumValue))
            .map((key) => {
                const filter: FilterDto = {
                    AndOrConnector: AndOrConnector.Or,
                    Filed: key.entityEnumValue,
                    SqlOperator: SqlOperator.Contains,
                    Value: str,
                };

                return filter;
            });

        applyFilters(filters);
        setCurrentPage(1);
    };

    const applyFilters = (filters: FilterDto[]) => {
        setParams((prm) => {
            prm.filterDtos = JSON.stringify(filters);

            getData(prm);
            return prm;
        });
    };

    // const setTableRows = (rows: number) => {
    //     setParams(prm => {
    //         prm.ItemInPage = rows
    //         props.newDataReq(prm)
    //         return prm
    //     })
    // }

    const pagesX = () => {
        let pagesIndex: ReactElement[] = [];

        if (showAllPages) {
            for (let index = 0; index < totalPages; index++) {
                pagesIndex.push(
                    <div
                        onClick={() => pageSelectHandler(index + 1)}
                        key={`p${index}`}
                        style={
                            currentPage === index + 1
                                ? {
                                    border: "3px solid #8B96E9",
                                    color: "#8B96E9",
                                }
                                : {
                                    // cursor: 'pointer'
                                }
                        }
                        className={tableStyle.pageNumber}
                    >
                        {index + 1}
                    </div>
                );
            }
        } else if (totalPages > 3) {
            for (let index = 0; index < 3; index++) {
                pagesIndex.push(
                    <div
                        onClick={() => pageSelectHandler(index + 1)}
                        key={`p${index}`}
                        style={
                            currentPage === index + 1
                                ? {
                                    border: "3px solid #8B96E9",
                                    color: "#8B96E9",
                                }
                                : {}
                        }
                        className={tableStyle.pageNumber}
                    >
                        {index + 1}
                    </div>
                );
            }

            pagesIndex.push(
                <div
                    onClick={() => setShowAllPages(true)}
                    className={tableStyle.pageNumber}
                >
                    ...
                </div>
            );

            pagesIndex.push(
                <div
                    onClick={() => pageSelectHandler(totalPages)}
                    key={`p${totalPages}`}
                    className={tableStyle.pageNumber}
                >
                    {totalPages}
                </div>
            );
        } else {
            for (let index = 0; index < 3; index++) {
                pagesIndex.push(
                    <div
                        onClick={() => pageSelectHandler(index + 1)}
                        key={`p${index}`}
                        style={
                            currentPage === index + 1
                                ? {
                                    border: "3px solid #8B96E9",
                                    color: "#8B96E9",
                                }
                                : {}
                        }
                        className={tableStyle.pageNumber}
                    >
                        {index + 1}
                    </div>
                );
            }
        }

        return pagesIndex;
    };

    useEffect(() => {
        if (!props.tableData) {
            // console.log('No table data')
            return
        }
        filterTableData(props.tableData, props.tableKeys);
    }, [props.tableData]);

    useEffect(() => {
        // console.log('New items', props.itemsInPage)
        setParams(prm => {
            prm.ItemInPage = props.itemsInPage
            props.newDataReq(prm)

            return prm;
        });
    }, [props.itemsInPage]);

    return (
        <div
            style={tableStyle}
            className={tableStyle.tableContainer}
        >
            {showFiltration && (
                <FilterData
                    dataHeaders={props.tableKeys}
                    onFiltration={onTableFiltration}
                    close={() => setShowFiltration(false)}
                />
            )}

            {(props.headerChild || props.search || props.filter) && (
                <div className={tableStyle.headerDivider}>
                    {props.headerChild && props.headerChild}
                    <div className={tableStyle.actions}>
                        {/* <Select label='Number of rows' value={params.ItemInPage} onChange={val => setTableRows(val)} options={[{ label: '5', value: 5 }, { label: '15', value: 15 }]} qaid='' /> */}
                        {/* {props.search && <img src={IC_FUNEL} onClick={() => setShowFiltration(val => !val)} />} */}
                        {props.filter && <SearchData onSearch={onTableSearch} />}
                    </div>
                </div>
            )}

            <table className={tableStyle.table}>
                <thead>
                    {/* <HeaderRow obj={tableDataMock[0]} headers={tableDataMockTypes} /> */}
                    <HeaderRow
                        headers={props.tableKeys}
                        onSort={onTableSort}
                        headerStyle={props.headerStyle}
                    />
                </thead>

                <tbody>
                    {
                        // dataLoading
                        //     ? <tr><td className={tableStyle.emptyRow}>Loading....</td></tr>
                        //     :

                        props?.tableData?.length > 0 ? (
                            <BodyRows
                                data={tableData}
                                headers={props.tableKeys}
                                onRowClick={props.onTableRowClick}
                                rowStyle={props.rowStyle}
                            />
                        ) : (
                                <tr>
                                    <td style={props.rowStyle} colSpan={props.tableKeys?.length}>
                                    <p className={tableStyle.emptyRow}>No data to display</p>
                                </td>
                            </tr>
                        )
                    }
                </tbody>

                <caption>
                    {
                        totalPages > 1 &&
                        (
                            <div className={tableStyle.nav}>
                                <p onClick={prevPage} className={tableStyle.pageNumber}>
                                    <img src={IC_CHEVRON_LEFT} color={'#D0D4E4'} />
                                </p>
                                {
                                    pagesX()
                                }
                                <p onClick={nexPage} className={tableStyle.pageNumber}>
                                    <img src={IC_CHEVRON_RIGHT} color={'#D0D4E4'} />
                                </p>
                            </div>
                        )
                    }
                </caption>
            </table>
        </div>
    );
};

export default observer(PagedTable);

const HeaderCell = (props: { obj: Object; num: number }) => {
    const headers = Object.keys(props.obj);
    return <div className="headerCell">{headers[props.num]}</div>;
};

interface SearchProps {
    onSearch: (str: string) => any;
}

const SearchData = (props: SearchProps) => {
    const [searchStr, setSearchStr] = useState("");

    const searchInTable = () => {
        props.onSearch(searchStr);
    };

    const onAccept = (e: any) => {
        if (e.charCode === 13) {
            searchInTable();
        }
    };

    return (
        <div className={tableStyle.searchBar}>
            <TextInput
                qaid=""
                value={searchStr}
                onChange={(val) => setSearchStr(val)}
                onKeyPress={onAccept}
                endIcon={IC_LOOKUP}
                onEnter={(val) => console.log(val, "Searched")}
            />
            <div className={tableStyle.searchBtn}>
                <Button
                    qaid=""
                    label="Search"
                    onClick={searchInTable}
                    style={{ padding: 0, margin: 0 }}
                />
                {/* <img src={IC_LOOKUP} /> */}
            </div>
        </div>
    );
};

// const tableDataMock: TableDataMock[] = [
//     { fullName: 'John Smith', dateOfBirth: new Date('01/01/2020'), proceeds: 123456, },
//     { fullName: 'John Smith 2', dateOfBirth: new Date('01/01/2021'), proceeds: 23456, },
//     { fullName: 'John Smith 3', dateOfBirth: new Date('01/01/2022'), proceeds: 3456, },
//     { fullName: 'John Smith 4', dateOfBirth: new Date('01/01/2023'), proceeds: 456, },
//     { fullName: 'John Smith 5', dateOfBirth: new Date('01/01/2024'), proceeds: 56, }
// ]

// const tableDataMockTypes: PagedTableKeys<TableDataMock>[] = [
//     { key: 'dateOfBirth', displayValue: 'Date Of Birth', entityEnumValue: 0 },
//     { key: 'fullName', displayValue: 'Full name', entityEnumValue: 1 },
//     { key: 'proceeds', displayValue: 'Pro ceeds', entityEnumValue: 2 },
// ]
