import SquareIcon from "@mui/icons-material/Square";
import { FC } from "react";
import { ChartLegendItem, ChartLegendItemFigure, ChartLegendItemLabel, StyledChartLegend } from "./ChartLegend.style";
import { defaultColors } from "../DoughnutChart/DoughnutChart.style";
import OverflowText from "../../../../OverflowText";

export interface ChartLegendProps {
    colors?: string[];
    labels: string[];
    figures: number[] | string[];
    className?: string;
}

function sortLegendItems(legendItems: any[]) {
    const lastItem = legendItems.find((item) => item.label === "Others");
    const items = legendItems.filter((item) => item.label !== "Others");

    if (lastItem) {
        items.push(lastItem);
    }

    return items;
}

const ChartLegend: FC<ChartLegendProps> = ({ colors = defaultColors, labels, figures, className }: ChartLegendProps) => {
    if (!labels || labels.length <= 0) {
        return null;
    }

    const formatFigure = (figure: number | string): string => figure.toString() + "%";

    const legendItems = labels.map((label, index) => ({
        color: colors[index],
        label: label,
        figure: figures[index],
    }));

    const sortedLegendItems = sortLegendItems(legendItems);

    return (
        <StyledChartLegend className={className}>
            {sortedLegendItems.map((item, index) => (
                <ChartLegendItem key={index}>
                    <SquareIcon style={{ color: item.color }} />
                    <ChartLegendItemLabel>
                        <OverflowText>{item.label}</OverflowText>
                    </ChartLegendItemLabel>
                    <ChartLegendItemFigure>{formatFigure(item.figure)}</ChartLegendItemFigure>
                </ChartLegendItem>
            ))}
        </StyledChartLegend>
    );
};

export default ChartLegend;
