import { css } from '@emotion/css';

const DataCollectionGeneralInfoStyle = css({
	label: 'DataCollectionGeneralInfoStyle',
	display: 'flex',
	flexDirection: 'column',
	'&__title': {
		fontWeight: 700,
		marginBottom: '2rem',
	},
	'&__radio-container': {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		'> label': {
			flexBasis: '18rem',
		},
	},
	'&__input-container': {
		maxWidth: '21rem',
	},
});

export default DataCollectionGeneralInfoStyle;
