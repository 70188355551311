import { css } from "@emotion/css";
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import Button from "../../../Shared/Components/Button/Button";
import Separator from "../../../Shared/Components/Separator";
import { ForwardedRef, MultiStepElementProps } from "../../../Shared/Hooks/useMultiStepForm";
import useRootStore from '../../../Shared/Hooks/useRootStore';
import { isNullOrUndefined, isNumber } from "../../../Shared/Utilities";
import GrantsTable from '../Components/ReportSettings/GrantsTable';

const Style = css({
	label: 'NewReportGrants',
	display: 'flex',
	flexDirection: 'column',
	width: '135rem',
	'&.sm': {
		width: '120rem',
	},
	padding: '6rem 8rem',
	'&__title': {
		fontWeight: 500,
		fontSize: '2.5rem',
		marginBottom: '5rem',
	},
	'&__next-btn': {
		// marginTop: "0.8rem",
	},
});

type Props = {
    onSubmitHandler?: () => void;
} & MultiStepElementProps;

const Grants = (props: Props) => {
    const { expensingStore } = useRootStore();
    const grantsTableRef = useRef<ForwardedRef>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onSubmitHandler = async (onValidate?: () => boolean | Promise<boolean>) => {
        setIsLoading(true);
        const isValidated = isNullOrUndefined(onValidate) ? await grantsTableRef.current?.onValidate() : await onValidate?.();
        setIsLoading(false);

        if (!isValidated) return;

        props.onSubmitHandler?.();
    };

    return (
        <div className={classNames(Style, { sm: isNumber(expensingStore.newReportData?.settings.forfeitureRate) })}>
            <div className={`${Style}__title`}>New expensing report</div>
            <GrantsTable
                onSubmit={onSubmitHandler}
                ref={grantsTableRef}
            />
            <Separator />
            <div className="flex justify-between">
                <Button
                    className={`${Style}__next-btn`}
                    qaid="ExpensingNewReport.Button.Back"
                    label="Back"
                    inverse
                    cancel
                    onClick={props.onPrevStep}
                />
                <Button
                    className={`${Style}__next-btn`}
                    qaid="ExpensingNewReport.Button.Next"
                    label="Next"
                    position="end"
                    onClick={() => onSubmitHandler()}
                    isLoading={isLoading}
                />
            </div>
        </div>
    );
};

export default observer(Grants);
