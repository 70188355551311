import { css } from '@emotion/css';
import CloseIcon from '@mui/icons-material/Close';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IC_EXPENSING_LOGO } from '../../Assets';
import { OnboardingStatus } from '../../Models/API/Expensing/onboarding-status-enum';
import { Routes } from '../../Routes';
import Button from '../../Shared/Components/Button/Button';
import Image from '../../Shared/Components/Image';
import Title from '../../Shared/Components/Layout/Title';
import { ModalBodyProps } from '../../Shared/Components/Modal/types';
import useModal from '../../Shared/Hooks/useModal';
import useRootStore from '../../Shared/Hooks/useRootStore';
import ExpensingDashboard from './Components/Dashboard';
import ExpensingTable from './Components/ExpensingTable';
import useExpensingModal from './helpers/useExpensingModal';
import Container from '../../Shared/Components/Modal/Modal.Style';

const Expensing = () => {
	const { expensingStore } = useRootStore();
	const { showModal } = useModal();
	const [isDashboardClose, setIsDashboardCloase] = useState<boolean>(true);
	const history = useHistory();
	const { openNewReport, openInitialSetup } = useExpensingModal();

	useEffect(() => {
		const fetchData = async () => {
			expensingStore.newReportData = undefined;
			const res = await expensingStore.getCompany();
			if (!res.isSuccess) {
				return showModal({
					body: <EquityCompanyNotice />,
					isMust: true,
					isFrameless: true,
					showClose: false,
					width: 'auto',
					height: 'auto',
				});
			}

			const onCloseHandler = () => history.push(Routes.dashboard.index);

			const { onboardingStatus } = res.data.data;
			if (onboardingStatus !== OnboardingStatus.Completed) {
				return openInitialSetup(onboardingStatus, onCloseHandler);
			}

			const reportRes = await expensingStore.getExpenseReports();
			if (!reportRes.data?.data.length) openNewReport();
		};
		fetchData();
	}, []);

	useEffect(() => {
		expensingStore.getDashboardData();
		expensingStore.getGrantsReport();
	}, [expensingStore.selectedReportId]);

	return (
		<>
			<ExpensingDashboard data={expensingStore.dashboardData} onToggle={(val) => setIsDashboardCloase(val)} />
			<ExpensingTable grants={expensingStore.expenseGrantsReport} expand={isDashboardClose} />
		</>
	);
};

const EquityCompanyNotice = () => {
	const history = useHistory();

	const Style = css({
		label: 'EquityCompanyNotice',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		width: '60rem',
		height: '45rem',
		position: 'relative',

		'> img': {
			marginBottom: '1.6rem',
		},

		'&__text': {
			marginTop: '0.4rem',
			marginBottom: '4rem',
		},
		'&__close': {
			width: '2rem',
			height: '2rem',
		},
	});

	const onRedirect = () => history.push(Routes.options7.index);

	return (
		<div className={Style}>
			<Container.Close onClick={history.goBack}>
				<CloseIcon className={`${Style}__close`} />
			</Container.Close>
			<Image src={IC_EXPENSING_LOGO} width="13.5rem" />
			<Title type="secondary">Please notice</Title>
			<div className={`${Style}__text`}>
				In order to procceed, please create an <span className="bold">Equity Plan</span> first
			</div>
			<Button qaid="EquityCompanyNotice.Button.Continue" label="Continue" onClick={onRedirect} />
		</div>
	);
};

export default observer(Expensing);
