import { useEffect, useState } from "react";
import TextInput from "../../../../../Shared/Components/Input/TextInput";
import Table, { TableColumn } from "../../../../../Shared/Components/Table/Table";
import { isNullOrUndefined } from "../../../../../Shared/Utilities";
import DataCompStyles from "./EquityDataComp.style";
import Button from "../../../../../Shared/Components/Button/Button";
import Drawer from "../../../General/Drawer/Drawer";
import { IC_CANCEL_SQUARE, IC_CHEVRON_DOWN, IC_CHEVRON_UP } from "../../../../../Assets";
import AddButton from "../../../../../Shared/Components/Button/AddButton";
import useRootStore from "../../../../../Shared/Hooks/useRootStore";
import Clickable from "../../../../../Shared/Components/Clickable/Clickable";
import classNames from "classnames";
import Select from "../../../../../Shared/Components/Select/Select";
import { NumberOption } from "../../../../../Models/API/All/NumberOption";

interface CompProps<T> {
	title: string;
	btnLabel: string;
	tbColumns: TableColumn<T>[];
	tbRows: T[] | undefined;
	isEdit?: number;
	addFunc?: (arg0: string) => any;
	editFunc?: (arg0: string) => any;
	deleteFunc?: () => void;
	width?: string;
	required?: boolean;
	dropValues?: NumberOption[]
}

const EquityDataComp = <T extends Object>(props: CompProps<T>) => {
	const { equityPlansStore } = useRootStore();

	const [isEditing, setIsEditing] = useState(false);
	const [showDetails, setShowDetails] = useState(false);
	const [newValue, setNewValue] = useState("");

	const type = "sites";

	useEffect(() => {
		if (!isNullOrUndefined(props.isEdit)) {
			setIsEditing(!!props.isEdit);
			setShowDetails(true);
		}
		console.log("Prop.isEdit", !!props.isEdit, props.isEdit);
	}, [props.isEdit]);

	const cancelAction = () => {
		console.log("canceling");
		setIsEditing(false);
		setShowDetails(false);
		setNewValue("");
	};

	const mainBtnHandler = () => {
		// !!props.isEdit && setIsEditing(true)
		setShowDetails(false);
		console.log("mainBtnHandler");
	};

	const addHandler = async () => {
		if (!props.addFunc) return;
		const res = await props.addFunc(newValue);
		if (res) {
			setNewValue("");
		}
	};

	const editHandler = () => {
		props.editFunc && props.editFunc(newValue);
	};

	const toggleDetails = () => {
		setShowDetails((prev) => !prev);
	};



	return (
		<div className={DataCompStyles}>
			<div className={classNames("innerContainer", { open: showDetails })}>
				{/* <Drawer
					header={props.title}
					label={props.btnLabel}
					tbRows={props.tbRows}
					onClick={mainBtnHandler}
					companyRegistration
					isOpen={showInput}
				> */}

				<div className="drawer-header" onClick={toggleDetails}>
					<div className="">
						{props.title}
						{` (${props.tbRows?.length})`}
						{props.required ? " *" : ""}
					</div>

					{showDetails ? (
						<img src={IC_CHEVRON_UP} className="ml-4" width={15} alt="" />
					) : (
						<img src={IC_CHEVRON_DOWN} className="ml-4" width={15} alt="" />
					)}
				</div>
				{
					showDetails ? (
						<div className="hidden-content">
							<Table
								className="custom-table"
								columns={props.tbColumns}
								rows={props.tbRows}
								headerSize={1}
								headerless
								scrollAfterRows={2}
								border={{ top: true, bottom: false }}
								fallbackText=""
							/>

							{
								!props.dropValues ? (

									<TextInput className="input-container" qaid={`companyData.Input.${props.title.replace(/\s+/g, '')}`} value={newValue}
										onChange={(e) => setNewValue(e)}
									/>
								) : (
									<Select
										qaid={`${props.title}Select`}
										onChange={(v) => setNewValue(v.toString())}
										value={newValue}
										options={props.dropValues}
									/>
								)
							}

							<div className="button-container">
								<Button qaid={`companyData.Button.${props.btnLabel.replace(/\s+/g, '')}`} onClick={isEditing ? editHandler : addHandler} label={isEditing ? "Edit" : "Add"} autoWidth small />
								<Clickable qaid="" flex={0}>
									<img src={IC_CANCEL_SQUARE} alt="delete" onClick={cancelAction} />
								</Clickable>
							</div>
						</div>
					) : (
						<div className="add-btn">
							<AddButton
								className="add-button"
								onClick={() => setShowDetails(true)}
								qaid={`companyData.Button.open${props.title.replace(/\s+/g, '')}`}
								label={props.btnLabel ?? ""}
							/>
						</div>
					)
				}

				{/* </Drawer> */}
			</div>
		</div>
	);
};
export default EquityDataComp;
