import { css } from "@emotion/css";
import Heading from "../../../../Shared/Components/Heading/Heading";

type Props = {
    text: string;
    subtitle?: string;
};

const Style = css({
    "&__text": {
        fontWeight: 500,
        fontSize: "1.8rem",
    },
    "&__subtitle": {
        fontSize: "1.4rem",
        margin: "1rem 0",
    },
});

const WaterfallTableHeading = (props: Props) => {
    return (
        <div>
            <span className={`${Style}__text`}> {props.text}</span>
            {!!props.subtitle && <span className={`${Style}__subtitle`}>{props.subtitle}</span>}
        </div>
    );
};

export default WaterfallTableHeading;
