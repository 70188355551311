import Hr from "../../../../HorizontalRule/Hr";
import Image from "../../../../Image";
import AddNewClass from "../../../shared/assets/icons/plus_sign.svg";
import NoDataChartImg from "../../../shared/assets/images/no_data_chart.svg";
import { WidgetBody } from "../../../shared/components/Widget/Widget.style";
import { ChartWrapper, ContnetWrapper } from "./NoData.style";

const NoData = () => {
	return (
		<WidgetBody>
			<ChartWrapper>
				<Image width="16.5rem" src={NoDataChartImg} alt="no data graphic" />
			</ChartWrapper>

			<ContnetWrapper>
				<p>Start simulation by adding funding class below</p>
				<Hr ap={{ isGradient: true }} />
			</ContnetWrapper>
		</WidgetBody>
	);
};

export default NoData;
