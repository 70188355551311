import { ReactNode } from "react";
import { StyledInputGroup } from "./InputGroup.style";

type Props = {
	children: ReactNode;
};

const InputGroup = ({ children }: Props) => {
	return <StyledInputGroup className="InputGroup">{children}</StyledInputGroup>;
};

export default InputGroup;
