import { Tooltip as MaterialTooltip, TooltipProps } from '@mui/material';
import classNames from 'classnames';
import React, { CSSProperties, ReactNode, isValidElement, useState } from 'react';
import { IC_QUESTION_MARK_ORANGE, IC_QUESTION_MARK_ORANGE_FILL } from '../../../Assets';
import Image from '../Image';
import { isNullOrUndefined } from '../../Utilities';
import { css } from '@emotion/css';

interface Props extends Omit<TooltipProps, 'children'> {
	className?: string;
	containerClassName?: string;
	style?: CSSProperties;
	title: NonNullable<ReactNode>;
	disabled?: boolean;
	children?: React.ReactChild | React.ReactChild[] | false | Element;
	showIcon?: boolean;
}

const Style = css({
	label: 'Tooltip',
	width: '1.8rem',
});

const Tooltip: React.FC<Props> = ({ title, className = '', children, showIcon = false, containerClassName = '', ...props }) => {
	const [isHovering, setIsHovering] = useState<boolean>(false);

	if (isNullOrUndefined(title)) return isValidElement(children) ? children : <></>;

	if (children) {
		return (
			<MaterialTooltip title={title} enterTouchDelay={0} {...props}>
				{isValidElement(children) ? (
					showIcon ? (
						<div
							className={classNames('pointer', { [containerClassName]: containerClassName })}
							onMouseEnter={() => setIsHovering(true)}
							onMouseLeave={() => setIsHovering(false)}
						>
							{children}
							{showIcon && (
								<Image
									style={{ marginLeft: '0.8rem' }}
									pointer
									className={classNames('question-mark', { [className]: !!className })}
									src={isHovering ? IC_QUESTION_MARK_ORANGE_FILL : IC_QUESTION_MARK_ORANGE}
									alt=""
								/>
							)}
						</div>
					) : (
						children
					)
				) : (
					<span
						onMouseEnter={() => setIsHovering(true)}
						onMouseLeave={() => setIsHovering(false)}
						className={classNames('pointer', { [containerClassName]: containerClassName })}
					>
						{children}{' '}
						{showIcon && (
							<Image
								style={{ marginLeft: '0.8rem' }}
								pointer
								className={classNames('question-mark', { [className]: !!className })}
								src={isHovering ? IC_QUESTION_MARK_ORANGE_FILL : IC_QUESTION_MARK_ORANGE}
								alt=""
							/>
						)}
					</span>
				)}
			</MaterialTooltip>
		);
	}

	if (showIcon) {
		return (
			<div>
				{children}
				<Image
					style={{ marginLeft: '0.8rem' }}
					pointer
					className={classNames('question-mark', { [className]: !!className })}
					src={IC_QUESTION_MARK_ORANGE}
					srcHover={IC_QUESTION_MARK_ORANGE_FILL}
					alt=""
					tooltip={title}
					tooltipPlacement={props.placement}
				/>
			</div>
		);
	}

	return (
		<Image
			pointer
			className={classNames(Style, 'question-mark', { [className]: !!className })}
			src={IC_QUESTION_MARK_ORANGE}
			srcHover={IC_QUESTION_MARK_ORANGE_FILL}
			alt=""
			tooltip={title}
			tooltipPlacement={props.placement}
		/>
	);
};

export default Tooltip;
