import { css } from '@emotion/css';
import { useHistory } from 'react-router-dom';
import { IC_LIMITATION, IC_LIMITATION_ADMIN } from '../../Assets';
import { LimitCodesEnum } from '../../Models/App/PaymentModels';
import { Routes } from '../../Routes';
import useGeneralModal from '../Hooks/useGeneralModal';
import Button from './Button/Button';
import Image from './Image';
import Flex from './Layout/Flex';
import Title from './Layout/Title';
import { ModalBodyProps } from './Modal/types';

const Style = css({
	label: 'Limitation',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	textAlign: 'center',
	gap: '2rem',

	'.subTitle': {
		margin: '0.7rem 0 3rem 0',
		color: '#4D4D4D',
		textAlign: 'center',
	},
});

interface IProps extends ModalBodyProps {
	admin:
		| true
		| {
				fullName: string;
				email: string;
		  };
}

const UpgradePlan = ({ removeModal, admin }: IProps) => {
	const history = useHistory();

	return (
		<div className={Style}>
			<Image width="13.5rem" className="icon" src={IC_LIMITATION_ADMIN} />
			<Title type="secondary">Upgrade Plan</Title>
			{admin === true ? (
				<>
					<span>This feature is not included in your current plan. Would you like to upgrade?</span>
					<Flex gap="1rem">
						<Button cancel label="Not now" qaid="UpgradePlan.Button.NotNow" onClick={removeModal} />
						<Button label="Upgrade now" qaid="UpgradePlan.Button.Upgrade" onClick={() => history.push(Routes.payment.pricing)} />
					</Flex>
				</>
			) : (
				<>
					<span className="subTitle">
						This feature is not included in your current plan. Please refer to {admin.fullName} ({admin.email}) to upgrade your plan
					</span>
					<Button qaid="UpgradePlan.Button.OK" label="OK" onClick={removeModal} />
				</>
			)}
		</div>
	);
};
export default UpgradePlan;
