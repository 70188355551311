import { useState, useCallback } from "react";
import { AxiosResponse } from "../../Services/Axios";

const cache = new Map();

type Options = {
    cacheKey: string;
    refetch?: boolean;
    updateState?: boolean;
};

type Data<T> = {
    data: T | null | undefined;
    error: null | string;
    loading: boolean;
};

const defaultOptions: Options = {
    cacheKey: "",
    refetch: false,
    updateState: true,
};

export const useFetchApi = <T>(initialData?: T) => {
    const defaultData = {
        data: initialData ?? null,
        error: null,
        loading: false,
    };
    const [data, setData] = useState<Data<T>>(defaultData);

    const clearData = () => setData(defaultData);

    const run = useCallback(async (asyncFn: () => AxiosResponse<T>, options: Options = defaultOptions) => {
        const cachedOptions = { ...defaultOptions, ...options };
        const { cacheKey, refetch, updateState } = cachedOptions;

        const result: Data<T> = { data: null, error: null, loading: false };
        if (!refetch && cacheKey && cache.has(cacheKey)) {
            const res = cache.get(cacheKey);
            result.data = res.data;
        } else {
            setData({ data: null, error: null, loading: true });
            const res = await asyncFn();
            if (!res.isSuccess) {
                const result: Data<T> = { data: null, error: res.errorMessage ?? null, loading: false };
                setData(result);
                return result;
            }
            result.data = res.data;
            cacheKey && cache.set(cacheKey, res);
        }
        if (updateState) setData(result);
        else setData((prevData) => ({ ...result, data: prevData.data, loading: false }));
        return result;
    }, []);

    return {
        ...data,
        run,
        cachedData: cache,
        clearData,
    };
};
