import { ClickAwayListener, Fade } from '@mui/material';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { IC_COMPANY_MENU, IC_HEADER_MENU, IC_TABS, IMG_HEADER_LOGO } from '../../../Assets';
import { NumberOption } from '../../../Models/API/All/NumberOption';
import { EmployeesWebsitePermission, UserStatus } from '../../../Models/API/enums';
import { Routes } from '../../../Routes';
import useGeneralModal from '../../Hooks/useGeneralModal';
import usePermissions from '../../Hooks/usePermissions';
import useRootStore from '../../Hooks/useRootStore';
import { isNullOrUndefined } from '../../Utilities';
import Clickable from '../Clickable/Clickable';
import Image from '../Image';
import Text from '../Layout/Text';
import Select from '../Select/Select';
import HeaderStyle from './Header.Style';
import UserMenu from './UserMenu/UserMenu';

type NavItem = {
	title: string;
	url?: string;
	disabled?: boolean;
	logo: string;
	qaid: string;
	comingSoon?: boolean;
	hidden?: boolean;
	action?: () => void;
};

const Header = () => {
	const { appState, companyStore, auth, documentsStore, paymentStore } = useRootStore();
	const history = useHistory();
	const location = useLocation();
	const { permissions } = usePermissions(auth.permissions, paymentStore.currentPlan);

	const { t } = useTranslation();

	const [selectedCompany, setSelectedCompany] = useState<NumberOption>();
	const [showNavMenu, setShowNavMenu] = useState<boolean>(false);
	const [showCompanyMenu, setShowCompanyMenu] = useState<boolean>(false);
	const [showProfileMenu, setShowProfileMenu] = useState<boolean>(false);

	const [hoveredLink, setHoveredLink] = useState<string>();

	const { onAddNewCompany } = useGeneralModal();

	const checkIfTabActive = (url: string, isExact: boolean = false) => {
		return (
			location.pathname === url ||
			hoveredLink === url ||
			(isExact ? location.pathname.includes(url) && location.pathname === url : location.pathname.includes(url))
		);
	};

	const navItems: NavItem[] = [
		{
			title: t('nav.dashboard'),
			url: Routes.dashboard.index,
			qaid: 'NavMenu.Link.Dashboard',
			logo: checkIfTabActive(Routes.dashboard.index, true) ? IC_TABS.DASHBOARD.HOVER : IC_TABS.DASHBOARD.DEFAULT,
		},
		{
			title: t('nav.capTable'),
			url: Routes.capTable.index,
			disabled: permissions.capTable.disabled,
			qaid: 'NavMenu.Link.CapTable',
			logo: checkIfTabActive(Routes.capTable.index) ? IC_TABS.CAPTABLE.HOVER : IC_TABS.CAPTABLE.DEFAULT,
		},
		// {
		// 	title: t("nav.options"),
		// 	url: Routes.options.index,
		// 	disabled: !process.env.REACT_APP_IS_OPTIONS_ACTIVE || !auth.permissions?.optionPermission,
		// 	qaid: "NavMenu.Link.Options",
		// 	logo: checkIfTabActive(Routes.options.index) ? IC_TAB_OPTIONS_SELECTED : IC_TAB_OPTIONS,
		// },
		{
			title: t('nav.equityPlans'),
			url: companyStore.company.pivotalEquityPlans ? Routes.trusteeData.index : Routes.options7.dashboard,
			disabled: permissions.equityPlans.disabled,
			qaid: 'NavMenu.Link.Options',
			logo: checkIfTabActive(Routes.options7.dashboard) ? IC_TABS.OPTIONS.HOVER : IC_TABS.OPTIONS.DEFAULT,
		},
		{
			title: t('nav.funding'),
			url: Routes.funding.index,
			disabled: permissions.funding.disabled,
			qaid: 'NavMenu.Link.Funding',
			logo: checkIfTabActive(Routes.funding.index) ? IC_TABS.FUNDING.HOVER : IC_TABS.FUNDING.DEFAULT,
		},
		{
			title: t('nav.expensing'),
			url: Routes.expensing.index,
			disabled: permissions.expensing.disabled,
			qaid: 'NavMenu.Link.Expensing',
			logo: checkIfTabActive(Routes.expensing.index) ? IC_TABS.EXPENSING.HOVER : IC_TABS.EXPENSING.DEFAULT,
		},
		{
			title: t('nav.waterfall'),
			url: Routes.waterfall.index,
			disabled: permissions.waterfall.disabled,
			qaid: 'NavMenu.Link.Waterfall',
			logo: checkIfTabActive(Routes.waterfall.index) ? IC_TABS.WATERFALL.HOVER : IC_TABS.WATERFALL.DEFAULT,
		},
		{
			title: '409A',
			url: Routes.valuation.index,
			qaid: 'NavMenu.Link.409A',
			logo: checkIfTabActive(Routes.valuation.index) ? IC_TABS.Valuation409A.HOVER : IC_TABS.Valuation409A.DEFAULT,
			comingSoon: !appState.isDev,
		},
		// { title: 'Waterfall Sce.', area: 'faterfall-sce', url: '/', disabled : !auth.isLoggedIn },
		// { title: '409A', area: '409a', url: '/' , disabled : !auth.isLoggedIn},
		// {
		//     title: t("nav.dataRoom"),
		//     url: Routes.dataRoom.overview,
		//     disabled: process.env.REACT_APP_IS_DATAROOM_ACTIVE === "false" || !auth.permissions?.dataRoomsPermission,
		//     qaid: "NavMenu.Link.Dataroom",
		//     logo: checkIfTabActive(Routes.dataRoom.overview) ? IC_TABS.DASHBOARD.HOVER : IC_TABS.DASHBOARD.DEFAULT,
		// },
		{
			title: t('nav.documentsAndSignatues'),
			url: Routes.docs.index,
			disabled: permissions.docs.disabled,
			qaid: 'NavMenu.Link.Docs',
			logo: checkIfTabActive(Routes.docs.index) ? IC_TABS.DOCS.HOVER : IC_TABS.DOCS.DEFAULT,
		},
		{
			title: 'Personal portal',
			url: '',
			qaid: 'NavMenu.Link.PersonalPortal',
			logo:
				hoveredLink === '' && !(auth.permissions ? !(auth.permissions?.employeeManagementPermission > EmployeesWebsitePermission.no_access) : true)
					? IC_TABS.EMPLOYEE.HOVER
					: IC_TABS.EMPLOYEE.DEFAULT,
			disabled: permissions.employeeWebsite.disabled,
			comingSoon: !!!process.env.REACT_APP_EMPLOYEES_SITE,
			action: auth.navToEmployeesWebsite,
		},
	];

	const companyMenuItems: NavItem[] = [
		{
			title: t('nav.companyProfile'),
			url: Routes.account.companyProfile,
			disabled: !companyStore.company?.isAdmin || companyStore.odooTransactionId !== 0,
			qaid: 'CompanyMenu.Link.CompanyProfile',
			logo: IC_TABS.ACTIONS.COMPANY_PROFILE,
		},
		{
			title: t('nav.usersAndPermissions'),
			url: Routes.usersAndPermission.index,
			disabled: process.env.REACT_APP_IS_USERS_AND_PERMISSIONS_ACTIVE === 'false' || !companyStore.company?.isAdmin,
			qaid: 'CompanyMenu.Link.UserAndPermissions',
			logo: IC_TABS.ACTIONS.USERS_AND_PERMISSIONS,
		},
		{
			title: t('account.addNewCompany'),
			url: Routes.account.createCompanyProfile,
			// disabled: !companyStore.company?.isAdmin,
			qaid: 'CompanyMenu.Link.AddNewCompany',
			logo: IC_TABS.ACTIONS.ADD_COMPANY,
			action: onAddNewCompany,
		},
	];

	const companies: NumberOption[] = useMemo(
		() =>
			companyStore.companies?.map((x) => {
				return {
					value: x.companyId,
					label: x.companyLegalName,
				};
			}) || [],
		[companyStore.companies]
	);

	useEffect(() => {
		setSelectedCompany(companies?.find((x) => x.value === companyStore.companyId));
		return () => {
			setSelectedCompany(undefined);
		};
	}, [companyStore.company, companyStore.companies]);

	useEffect(() => {
		auth.userInfo.status === UserStatus.Active && companyStore.getCompanies();
	}, []);

	const onNavClickHandler = (nav: NavItem) => {
		nav.action ? nav.action() : nav.url && history.push(nav.url);
		setShowCompanyMenu(false);
		setShowNavMenu(false);
	};

	return (
		<header className={HeaderStyle}>
			<div className={`${HeaderStyle}__side-a`}>
				<ClickAwayListener onClickAway={() => setShowNavMenu(false)}>
					<div className={`${HeaderStyle}__nav-menu-container`}>
						<div
							className={classNames({
								[`${HeaderStyle}__menu-clicked`]: showNavMenu,
							})}
						></div>
						{companyStore.odooTransactionId ? (
							<Image
								tooltip="The menu is disabled for transactions"
								src={IC_HEADER_MENU}
								alt="Header Menu"
								className={`${HeaderStyle}__disabled`}
							/>
						) : (
							<Clickable qaid="Header.Button.Menu" flex={0} onClick={() => setShowNavMenu((state) => !state)}>
								<img className="menu" src={IC_HEADER_MENU} alt="Header Menu" />
							</Clickable>
						)}
						<Fade in={showNavMenu} unmountOnExit>
							<div className={`${HeaderStyle}__nav-menu`}>
								<Text fontWeight={700}>Switch to</Text>
								{navItems
									.filter((nav) => !nav.hidden)
									.map((nav) => {
										return (
											<Clickable
												justify="start"
												qaid={nav.qaid}
												className={classNames(`${HeaderStyle}__nav-menu-item`, {
													comingSoon: nav.comingSoon,
												})}
												gap="2rem"
												flex={0}
												onClick={() => !nav.comingSoon && !nav.disabled && onNavClickHandler(nav)}
												key={nav.url}
												onMouseEnter={() => !nav.comingSoon && setHoveredLink(nav.url)}
												onMouseLeave={() => !nav.comingSoon && setHoveredLink(undefined)}
												tooltip={
													nav.disabled ? 'No access permission, please contact admin' : nav.comingSoon ? 'Coming soon!' : undefined
												}
												disabled={nav.disabled}
											>
												<Image src={nav.logo} alt={nav.title} />
												<div className={classNames(`${HeaderStyle}__nav-menu-item-text`)}>
													{nav.title}
													<Fade in={location.pathname === nav.url} unmountOnExit timeout={500}>
														<div className={`${HeaderStyle}__active`}></div>
													</Fade>
												</div>
											</Clickable>
										);
									})}
							</div>
						</Fade>
					</div>
				</ClickAwayListener>
				<div className="flex align-center logoContainer" style={{ gap: '1.6rem' }}>
					<button
						className={`${HeaderStyle}__alt-logo`}
						data-qaid="Header.Button.Logo"
						onClick={() => history.push(Routes.dashboard.index)}
						disabled={companyStore.isInCompanyCreationProcess}
					>
						<img src={IMG_HEADER_LOGO} alt="" className={`${HeaderStyle}__alt-logo`} />
					</button>
					<div className={`${HeaderStyle}__separator`}></div>
					<div className={`${HeaderStyle}__page-title`}>{appState.currentPageTitle}</div>
				</div>
			</div>
			<div className={`${HeaderStyle}__side-b`}>
				<ClickAwayListener onClickAway={() => setShowCompanyMenu(false)}>
					<div className={`${HeaderStyle}__nav-menu-container`}>
						<Clickable
							qaid="Header.Button.CompanyMenu"
							onClick={() => setShowCompanyMenu((state) => !state)}
							disabled={companyStore.isInCompanyCreationProcess}
						>
							<img className="company-menu" src={IC_COMPANY_MENU} alt="company-logo" />
						</Clickable>
						<Fade in={showCompanyMenu}>
							<div className={`${HeaderStyle}__nav-menu company`}>
								<>
									{companyMenuItems
										.filter((x) => !x.disabled)
										.map((nav) => (
											<Clickable
												justify="start"
												qaid={nav.qaid}
												className={`${HeaderStyle}__nav-menu-company-item`}
												flex={0}
												onClick={() => onNavClickHandler(nav)}
												key={nav.url}
												gap="0.8rem"
											>
												<img src={nav.logo} alt={nav.title} />
												<div
													className={classNames(`${HeaderStyle}__nav-menu-item-text company`, {
														active: false && location.pathname === nav.url,
													})}
												>
													{nav.title}
												</div>
											</Clickable>
										))}
								</>
							</div>
						</Fade>
					</div>
				</ClickAwayListener>
				{!isNullOrUndefined(selectedCompany) && (
					<Select
						className={`${HeaderStyle}__select mb-0`}
						qaid="Header.Select.SelectedCompany"
						onChange={(value) => {
							const company = companyStore.companies.find((c) => c.companyId === value);
							company && companyStore.setCompany(company);

							// TODO: add url check for equity
							// history.push(Routes.dashboard.index);
						}}
						value={selectedCompany.value}
						options={companies}
						disabled={location.pathname.includes(Routes.account.createCompanyProfile) || companyStore.isInCompanyCreationProcess}
					/>
				)}
				<ClickAwayListener onClickAway={() => setShowProfileMenu(false)}>
					<div className={`${HeaderStyle}__nav-menu-container`}>
						<Clickable
							qaid="Header.Button.Profile"
							justify="center"
							onClick={() => setShowProfileMenu((state) => !state)}
							disabled={companyStore.isInCompanyCreationProcess}
						>
							<div className={`${HeaderStyle}__user-circle`}>
								{auth.userInfo.firstName?.[0]}
								{auth.userInfo.lastName?.[0]}
							</div>
						</Clickable>
						<UserMenu onHide={() => setShowProfileMenu(false)} show={showProfileMenu} />
					</div>
				</ClickAwayListener>
			</div>
		</header>
	);
};

export default observer(Header);
