import { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState } from "react";
import { eliminateTimeZone, isDate, isNullOrUndefined, isObject } from "../Utilities";
import * as dot from "dot-object";

export type AppendStateInputHandlerType = (value?: string | number | Date | boolean | null, name?: string | undefined) => void;

type ReturnType<T> = [T, Dispatch<SetStateAction<T>>, AppendStateInputHandlerType, (payload: Partial<T>) => void, T];

export const useAppendState = <T extends object>(initialState: Partial<T> = {}): ReturnType<T> => {
    const [state, setState] = useState<T>(initialState as T);
    const refState = useRef<T>(initialState as T);

    const onInputHandler = (value?: string | number | Date | boolean | null, name?: string | undefined) => {
        if (isNullOrUndefined(name)) return;

        const val = isDate(value) ? new Date(value).toISOString() : value;
        setState((prevState) => {
            if (name.includes(".")) {
                return dot.object({ ...JSON.parse(JSON.stringify(prevState)), [name]: val }) as T;
            }
            return { ...prevState, [name]: val === "" ? undefined : val };
        });
    };

    const appendState = (payload: Partial<T>) => {
        if (!isObject(payload)) return;
        setState((prevState) => ({ ...prevState, ...payload }));
    };

    return [state, setState, onInputHandler, appendState, refState.current];
};
