/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { InputRef } from '../../Models/App/Inputs/types';
import { isNullOrUndefined } from '../Utilities';
import ObjectValidator, { ObjectValidationDescriptor, ObjectValidationStatus, ValidationPair } from './../ObjectValidator';

export interface InputValidationRef extends Omit<HTMLDivElement, 'focus'>, InputRef {}

export type InputRefs<T> = Record<keyof T, InputValidationRef>;

interface ValidationProps<T> {
    form: T | undefined;
    schema: ObjectValidationDescriptor<T>;
    deps?: any[];
    refs?: InputRefs<T>;
    isEdit?: boolean;
    isRealtime?: boolean;
}

interface ValidationFormProps<T> {
    showErrors: boolean;
    focusOnError: boolean;
    schemaValidation: ObjectValidationDescriptor<T>;
}

export const useFormValidation = <T extends object>({
    form,
    schema,
    deps = [],
    refs,
    isEdit = false,
    isRealtime = true,
}: ValidationProps<T>) => {
    const [isFormValid, setIsFormValid] = useState(false);
    const [formValidationState, setFormValidationState] = useState<ObjectValidationStatus<T> | undefined>();
    const [formErrors, setFormErrors] = useState<Array<ValidationPair>>([]);
    const inputRefs = useRef<InputRefs<T>>({} as InputRefs<T>);

    const validateForm = (params?: Partial<ValidationFormProps<T>>): boolean => {
        if (isNullOrUndefined(form)) return false;
        if (isNullOrUndefined(params)) {
            params = {
                showErrors: true,
                focusOnError: true,
                schemaValidation: schema,
            };
        } else {
            if (isNullOrUndefined(params.focusOnError)) {
                params.focusOnError = true;
            }
            if (isNullOrUndefined(params.showErrors)) {
                params.showErrors = true;
            }
            if (isNullOrUndefined(params.schemaValidation)) {
                params.schemaValidation = schema;
            }
        }
        const validator = new ObjectValidator<T>(params.schemaValidation as ObjectValidationDescriptor<T>);
        validator.validate(form);
        setFormValidationState(validator.validationState);
        setIsFormValid(validator.isValid);
        setFormErrors(validator.getErrorsList());

        if (params?.showErrors) {
            let isFocus = false;
            Object.keys(validator.validationState).forEach((key) => {
                if (validator.validationState[key as keyof T]?.isValid) return;
                (refs || inputRefs.current)?.[key as keyof T]?.showError();
                if (params?.focusOnError && !isFocus) {
                    refs?.[key as keyof T]?.focus();
                    isFocus = true;
                }
            });
        }
        // params.showErrors && refs?.map((ref) => ref?.showError());
        // if (params.focusOnError) {
        // 	const firstErrorInput = Object.keys(validator.validationState).findIndex(
        // 		(inputName: string) => !validator.validationState[inputName as keyof typeof validator.validationState]?.isValid
        // 	);
        // 	refs?.[firstErrorInput]?.focus();
        // }
        return validator.isValid;
    };

    useEffect(() => {
        isRealtime &&
            validateForm({
                showErrors: isEdit || false,
                focusOnError: false,
                schemaValidation: schema,
            });
    }, [form, ...deps]);

    const clearErrors = () => Object.keys(refs || {})?.forEach((ref) => refs?.[ref as keyof T]?.resetError());

    return {
        isFormValid,
        formValidationState,
        formErrors,
        setFormValidationState,
        validateForm,
        clearErrors,
        inputRefs: inputRefs.current,
    };
};
