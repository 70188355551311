import { RefObject, useEffect, useRef, useState } from 'react';
import { css } from "@emotion/css";
import { observer } from "mobx-react-lite";
import appConfig from '../../../config/config';
import Button from "../../../Shared/Components/Button/Button";
import { useHistory } from "react-router-dom";
import Image from "../../../Shared/Components/Image";
import { IC_DOC_WORD, IC_DOWNLOAD_ACTION, IC_EYE_ACTION, HO_TO_3_IMG, HO_TO_FIRST_IMG, HO_TO_SEC_IMG } from "../../../Assets";
import UITable from "../../../Shared/Components/Table/UITable";
import { Routes } from "../../../Routes";
import Table, { TableColumn } from '../../../Shared/Components/Table/Table';
import Clickable from "../../../Shared/Components/Clickable/Clickable";
import { getMimeType } from '../../../Shared/Utilities';
import { DocumentTemplateInfo } from '../../../Models/API/Document/template-info';
import useTemplate from '../helpers/hooks/useTemplate';
import { SyntheticEvent } from 'react';
import useRootStore from "../../../Shared/Hooks/useRootStore";
import Spinner from '../../../Shared/Components/Spinner/Spinner';
import { FileExtensionEnum, TemplateTypeEnum } from '../../../Models/API/Document/template-type-enum';

const Style = css({
    padding: '4rem 5rem 4rem 13rem',
    display: 'flex',

    "&__wrapper": {
        padding: '0 0 0 20px',
        marginRight: '25rem',

        '> h1': {
            marginTop: '0px'
        },

        "> p": {
            margin: '2rem 0'
        },

        "&__boldAndColor": {
            fontWeight: 'bold',
            color: appConfig.style.colors.color1,
            cursor: 'pointer'
        },
    },

    '.divider': {
        backgroundColor: appConfig.style.colors.field,
        margin: '2.5rem auto 1.6rem auto',
    },

    '&__scroller': {
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        padding: '1rem',
        transform: 'translateX(-20rem)'
    }
});

const TableStye = css({
    '&__actionsDiv': {
        display: 'flex',
        justifyContent: 'space-between',
        width: '50%'
    },
    "div[data-cell-type='header']:not(:last-child)": {
        borderRight: `1px solid ${appConfig.style.colors.table}`,
    },
});

type Section = {
    id: number;
    label: string;
};

const sections: Section[] = [
    { id: 1, label: 'How to create a new template' },
    { id: 2, label: 'Template variables list' },
    { id: 3, label: 'Default templates' },
];

const HowTo = () => {
    const history = useHistory()
    const { previewDefaultTemplate, getDefaultTemplate, loading } = useTemplate();
    const { documentsStore } = useRootStore();
    const [activeSection, setActiveSection] = useState<number>(1);
    const sectionRefs = useRef<{ [key: number]: HTMLAnchorElement | null }>({});

    const onDownloadHandler = async (e: SyntheticEvent, templateType: TemplateTypeEnum, fileName: string) => {
        e.stopPropagation();
        const res = await getDefaultTemplate(templateType, fileName, FileExtensionEnum.docx);
        const link = document.createElement('a');
        if (!res.data?.data.fileBase64) return;
        link.href = `data:${getMimeType('docx')};base64,${res.data?.data.fileBase64}`;
        link.download = `${fileName}.docx`;
        link.click();
    };

    const tableColumns: TableColumn<Partial<DocumentTemplateInfo>>[] = [
        {
            name: 'templateName',
            label: 'Template name',
            sortable: true,
            style: { flex: 3.14 },
            render(obj, value) {
                return (
                    <>
                        <Image width="4rem" src={IC_DOC_WORD} style={{ marginRight: '0.8rem' }} />
                        {value}
                    </>
                );
            },
        },
        {
            name: 'actions',
            label: 'Actions',
            style: { flex: '0 0 20rem', justifyContent: 'space-between' },
            render: (obj, value) => {
                return (
                    <div className={`${TableStye}__actionsDiv`}>
                        <Clickable
                            qaid=""
                            onClick={(e) => {
                                obj.documentType !== undefined && obj.documentType >= 0 && obj.templateName &&
                                    onDownloadHandler(e, obj.documentType, obj.templateName)
                            }}
                            flex={0}>
                            <Image width="2.3rem" src={IC_DOWNLOAD_ACTION} tooltip="Download" />
                        </Clickable>
                        <Clickable
                            qaid=""
                            onClick={() => {
                                obj.documentType !== undefined && obj.documentType >= 0 && obj.templateName &&
                                    previewDefaultTemplate(obj.documentType, obj.templateName);
                            }}
                            flex={0}
                        >
                            <Image width="2.5rem" src={IC_EYE_ACTION} tooltip="Preview" />
                        </Clickable>
                    </div>
                );
            },
        },
    ];

    const scrollToSection = (id: number) => {
        const element = sectionRefs.current[id];

        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
            setActiveSection(id)
        }
    };

    return (
        <div className={Style}>
            <a style={{ marginTop: '-9rem' }} ref={el => (sectionRefs.current[1] = el)} />
            {loading && <Spinner />}
            <Button
                qaid='trustee.orders.back'
                label='Back'
                inverse
                cancel
                onClick={() => history.push(Routes.docs.index)}
            />
            <div className={`${Style}__wrapper`}>
                <h1>How to create a new template</h1>
                <p>Document templates for electronic securities workflow (grant letter, share certificate etc.) can be seamlessly created by customers   as Word documents and uploaded to altshare in DOCX format.
                    <br />
                    Multiple templates can be created and used for different purposes, securities types, regions etc.
                </p>
                <p>
                    <span><b>Step 1 - </b>First create your own template including insertion of relevant <span onClick={() => scrollToSection(2)} className={`${Style}__wrapper__boldAndColor`}>{'dynamic variables'}</span> from the predefined list. As part of the automatic workflow, altshare will automatically populate these variables with the real data from the corresponding transactions, when generating the final PDF output for e-signature by defined signatories. For example if you want to include the stakeholder name, {'place {{stakeholder}}'} variable.</span>
                </p>
                <p>
                    Alternatively, as a reference you can <span onClick={() => scrollToSection(3)} className={`${Style}__wrapper__boldAndColor`}>{' Download '}</span>althsare default templates and customize with your legal terms and additional data required.
                </p>
                <p>
                    <b>Step 2 - </b>Go back to <b>Documents & Signatures </b>{'>'}<b> Templates</b> and click <b>“Add new template”.</b>
                </p>
                <Image
                    src={HO_TO_FIRST_IMG}
                    width="50rem"
                />
                <p>
                    <b>Upload your template:</b><br />
                    • Simply drag & drop or click "Select a file” button to upload the created template file in DOCX format.<br />
                    • Set all required parameters within the form, including signatories required to e-sign the document by clicking “Add signatory”.<br />
                    • Please make sure that number of signatories in the list fits the signature placeholders in the defined template.
                </p>
                <p>
                    <b>Reorder Signatories (Optional):</b><br />
                    • If necessary, adjust the signing order by drag & drop signatories within the list.
                </p>
                <p>
                    <b>Select Signing Rule:</b><br />
                    • Parallel: Sends the document simultaneously to all signatories to e-sign.<br />
                    • Sequential: Sends the document to signatories one at a time, following the order specified in the list. That means, the document will be sent to the next signatory in the list only after it was signed by the previous one.
                </p>
                <Image
                    src={HO_TO_SEC_IMG}
                    width="50rem"
                />
                <p>
                    <b>Click “Add” - </b>a new template will be now created in your “Templates” list.
                </p>
                <Image
                    src={HO_TO_3_IMG}
                    width="90rem"
                /><br /><a ref={el => (sectionRefs.current[2] = el)} /><br />

                <hr className="divider" /><br />
                <h1>Template variables list</h1>
                <p>
                    altshare's automatic workflow instantly generates PDF documents for stakeholders by population of data from the corresponding transactions into the available variables in list below.
                    Please accurately copy/paste the relevant variables into your template exactly as specified, with no spaces or typos to avoid errors.
                </p>
                <UITable
                    title={'Grant letter variables'}
                    data={[
                        ['Variable', 'Description'],
                        ['{{stakeholder}}', 'Stakeholder’s full name as defined'],
                        ['{{first_name}}', 'Stakeholder’s first name'],
                        ['{{last_name}}', 'Stakeholder’s last name'],
                        ['{{id}}', 'Stakeholder’s ID number '],
                        ['{{equity_plan}}', 'Name of the equity plan'],
                        ['{{award_type}}', 'Options or RSU'],
                        ['{{grant_date}}', 'The date in which the award was approved by the board'],
                        ['{{grant_number}}', 'The number that was given to grant under Equity Plans'],
                        ['{{amount_granted}}', 'Number of options or RSUs that were granted'],
                        ['{{tax_track}}', 'Tax track that grant is subject to'],
                        ['{{vesting_schedule}}', 'Vesting schedule template name'],
                        ['{{vesting_start_date}}', 'The date in which the grant vesting starts'],
                        ['{{expiration_date}}', 'The date that the award is expired'],
                        ['{{currency}}', 'Exercise price currency'],
                        ['{{exercise_price}}', 'Exercise price of the grant'],
                        ['{{address}}', 'Stakeholder’s address'],
                    ]}
                />
                <UITable
                    title={'Share certificate variables'}
                    data={[
                        ['Variable', 'Description'],
                        ['{{certificate_id}}', 'Share certificate id '],
                        ['{{country_of_incorporation}}', 'Company’s country of incorporation'],
                        ['{{stakeholder}}', 'Stakeholder’s full name as defined'],
                        ['{{shares_par_value}}', 'Share’s par value'],
                        ['{{first_name}}', 'Stakeholder’s first name'],
                        ['{{last_name}}', 'Stakeholder’s last name'],
                        ['{{contact_id}}', 'Contact’s ID number'],
                        ['{{address}}', 'Stakeholder’s address'],
                        ['{{shares}}', 'Number of shares that is issued'],
                        ['{{date}}', 'Date in which the share certificate is issued by the company'],
                        ['{{asset_class}}', 'The share class of issued shares'],
                    ]} />
                <UITable
                    title={'E-signature variables (valid for entire templates)'}
                    data={[
                        ['Variable', 'Description'],
                        ['{{signatory_name}}', 'Company’s associated signatory name (e.g. CFO)'],
                        ['{{signatory_signature}}', 'Company’s associated signatory signature (placeholder for e-signature)'],
                        ['{{signatory2_name}}', 'Any additional company’s signatory name if required (e.g. secretary/witness)'],
                        ['{{signatory2_signature}}', 'Placeholder for secretary/witness e-signature'],
                        ['{{stakeholder_name}}', 'Stakeholder name (e.g. shareholder, beneficiary name)'],
                        ['{{stakeholder_signature}}', 'Stakeholder signature (placeholder for e-signature)'],
                    ]} /><br /><br /><br />
                <a ref={el => (sectionRefs.current[3] = el)} />
                <h5>Default templates</h5>
                <Table
                    columns={tableColumns}
                    rows={documentsStore.defaultTemplates}
                    className={TableStye}
                    rowSize={2}
                />
            </div>
            <div>
                <nav className={`${Style}__scroller`}>
                    <ul>
                        {sections.map(section => (
                            <li
                                key={section.id}
                                onClick={() => scrollToSection(section.id)}
                                style={{
                                    cursor: 'pointer',
                                    padding: '5px',
                                    fontWeight: activeSection === section.id ? 'bold' : 'normal',
                                    borderLeft: activeSection === section.id ? `4px solid ${appConfig.style.colors.color1}` : `4px solid ${appConfig.style.colors.background1}`,
                                    transition: 'border-left 0.3s, font-weight 0.3s',
                                    whiteSpace: 'nowrap',
                                    backgroundColor: 'white'
                                }}
                            >
                                {section.label}
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default observer(HowTo);