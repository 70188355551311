import classNames from "classnames";
import { InfoProps } from ".";
import DocumentPreviewInfo from "./DocumentPreviewInfo";
import { renderInfo } from "../../helpers/utils";
import { css } from "@emotion/css";
import { PreviewStyle } from "./index.style";

const Style = css({
    display: "grid",
    gridTemplateColumns: "1.25fr 1fr",
    paddingBottom: "0.4rem",
    padding: "1.2rem 0",
    gridRowGap: "1.2rem",
    "&.border-bottom": {
        borderBottom: "1px solid #ffffff",
    },
    "&:not(.border-bottom)": {
        paddingTop: "1.2rem",
    },
});

const TemplatePreviewInfo = (data: InfoProps) => {
    if (!data.info) return <></>;

    return (
        <div className={PreviewStyle}>
            <div className={`${Style} border-bottom`}>
                {data.info.slice(0, data.isPreview ? 4 : data.info.length).map((i) => renderInfo(i))}
            </div>
            <div className={Style}>{data.info.slice(4).map((i) => renderInfo(i))}</div>
        </div>
    );
};

export default TemplatePreviewInfo;
