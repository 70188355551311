import { useEffect, useState } from "react";
import CircularSwitchToggle from "../../../Shared/Components/CircularSwitchToggle/CircularSwitchToggle";
import DoughnutChart from "../../../Shared/Components/WidgetDashboard/shared/components/DoughnutChart/DoughnutChart";
import GrantsTable from "./GrantsTable/GrantsTable";
import BeneficiariesTable from "./BeneficiariesTable/BeneficiariesTable";
import { equityDashboardStyle } from "./EquityDashboard.style";
import useRootStore from "../../../Shared/Hooks/useRootStore";
import { observer } from "mobx-react-lite";
import ChartLegend from "../../../Shared/Components/WidgetDashboard/shared/components/ChartLegend/ChartLegend";
import { IC_BENEFICIARIES, IC_SIGNATURES, IC_ORDERS } from "../../../Assets";
import { formatDate, formatDecimal, formatNumber } from "../../../Shared/Utilities";
import WithToggle from "../../../Shared/Components/WidgetDashboard/shared/components/WithToggle/WithToggle";
import NoData from "../../../Shared/Components/WidgetDashboard/CaptableDashboard/dependencies/components/SwitchSelectChartWidget/NoData/NoData";
import DashboardText from "../../../Shared/Components/Dashboard/DashboardText";
import DashboardCard from "../../../Shared/Components/Dashboard/DashboardCard";
import Tabs from "./Tabs";
import PromisedTable from "./PromisedTable";
import { GrantsTableTab } from "../../../Models/App/EquityPlans/GrantTableTabs";
import SwitchSelectChartWidget from "../../../Shared/Components/WidgetDashboard/CaptableDashboard/dependencies/components/SwitchSelectChartWidget/SwitchSelectChartWidget";
import { defaultColors } from "../../../Shared/Components/WidgetDashboard/shared/components/DoughnutChart/DoughnutChart.style";
import { Routes } from "../../../Routes";
import { useHistory } from "react-router-dom";
import Orders from "../Sections/Orders/Orders";
import Cancelations from "../Sections/Cancelations/Cancelations";
import Table, { TableColumn } from "../../../Shared/Components/Table/Table";
import { UpcomingVestings } from "../../TrusteeEquity/Types/trusteeTypes";
import { PendingSignaturesType } from "../../../Models/API/Document/eletronics-signature";
import { TemplateTypeEnum } from "../../../Models/API/Document/template-type-enum";

const EquityDashboard = () => {
	const { equityPlansStore, documentsStore } = useRootStore();
	const [renderPie, setRenderPie] = useState<boolean>();
	const history = useHistory();
	const [isDashboardOpen, setIsDashboardOpen] = useState<boolean>(true);
	const [isGrantType, setIsGrantType] = useState<boolean>(false);
	const data = equityPlansStore.equityDashboard;
	const [tableData, setTableData] = useState<UpcomingVestings[]>([]);
	const [docs, setDocs] = useState<PendingSignaturesType>();
	const minPercentage = 0.01;
	const labels = isGrantType ?
		["Promised", "Unvested", "Exercisable", "Saleable", "Sold", "Canceled", "Unallocated"]
		: ["Allocated", "Unallocated", "Promised"];
	const total = isGrantType ?
		data.promised + data.unVested + data.exercisable + data.saleableShares + data.sold + data.canceled + data.unAllocated
		: data.allocated + data.unAllocated + data.promised;

	const figures = () => {
		if (!data) {
			return [];
		}
		if (isGrantType) {
			let values = [data.promised, data.unVested, data.exercisable, data.saleableShares, data.sold, data.canceled, data.unAllocated];

			const percentages = values.map(value => {
				const percentage = (value / total) * 100;
				return value === 0 ? percentage : Math.max(parseFloat(percentage.toFixed(10)), minPercentage);
			});

			const sumPercentages = percentages.reduce((sum, value) => sum + +(formatDecimal(value)), 0);
			const remainder = 100 - sumPercentages;

			if (remainder > 0) {
				percentages[6] += remainder;
			}

			return percentages.map((item) => formatDecimal(item));
		}
		else {
			let values = [data.allocated, data.unAllocated, data.promised];

			const percentages = values.map(value => {
				const percentage = (value / total) * 100;
				return value === 0 ? percentage : Math.max(parseFloat(percentage.toFixed(10)), minPercentage);
			});

			const sumPercentages = percentages.reduce((sum, value) => sum + +(formatDecimal(value)), 0);
			const remainder = 100 - sumPercentages;

			if (remainder > 0) {
				percentages[1] += remainder;
			}

			return percentages.map((item) => formatDecimal(item));
		}
	}

	const tableHeader = (
		<Tabs />
	);

	const renderPieChecker = () => {
		const { outstanding, exercised, sold, canceled, unAllocated } = data;

		if (outstanding > 0 || exercised > 0 || sold > 0 || canceled > 0 || unAllocated > 0) {
			setRenderPie(true);
		} else {
			setRenderPie(false);
		}
	};

	useEffect(() => {
		renderPieChecker();
	}, [data]);

	useEffect(() => {
		equityPlansStore.GetDashboardData();
		getUpcomingVestingEventsData();
		getDocsData();
	}, []);

	useEffect(() => {
		equityPlansStore.GetDashboardData();
	}, [equityPlansStore.selectedPlan]);

	const getUpcomingVestingEventsData = async () => {
		const res = await equityPlansStore.getUpcomingVestingEvents();

		if (res.isSuccess) {
			setTableData(res.data);
		}
	}

	const getDocsData = async () => {
		const res = await documentsStore.getDocuments();

		if (res.isSuccess) {
			setDocs(res.data.data.pendingSignatures.find(item => item.electronicSignatureType === TemplateTypeEnum.GrantLetter));
		}
	}

	const noData = !total;

	const tooltipFigures = isGrantType
		? [data.promised, data.unVested, data.exercisable, data.saleableShares, data.sold, data.canceled, data.unAllocated,]
		: [data.allocated, data.unAllocated, data.promised,]

	const poolColors = ["#99A2EB", "#F5DC7F", "#F4B16D"];

	const vestingColumns: TableColumn<UpcomingVestings>[] = [
		{
			label: 'Vesting Date',
			name: 'vestingDate',
			sortable: true,
			isOverflow: true,
			format: (val) => formatDate(val),
			style: { flexGrow: 1 },
		},
		{
			label: 'Grant type',
			name: 'grantType',
			sortable: true,
			isOverflow: true,
			style: { flexGrow: 1 },
		},
		{
			label: 'Quantity per event',
			name: 'vestingAmmount',
			sortable: true,
			isOverflow: true,
			format: (val) => formatNumber(val),
			style: { flexGrow: 1 },
		},
		{
			label: 'Number of grants',
			name: 'numberOfGrants',
			sortable: true,
			isOverflow: true,
			format: (val) => formatNumber(val),
			style: { flexGrow: 1 },
		},
	];

	return (
		<>
			<div className={equityDashboardStyle}>
				{!!data && (
					<WithToggle onToggle={(val) => setIsDashboardOpen(!val)}>
						<div className={`${equityDashboardStyle}__dashboard`}>
							<DashboardCard className="card1">
								{renderPie ? (
									<>
										<SwitchSelectChartWidget
											className="ChartWidget"
											bodyClassName="ChartWidgetBody"
											legendChartNode={
												<ChartLegend
													className="ChartLegend"
													labels={labels}
													figures={figures()}
													colors={isGrantType ? defaultColors : poolColors}
												/>
											}
											doughnutChartNode={
												<DoughnutChart
													ap={{
														labels: labels,
														figures: figures(),
														tooltipFigures: tooltipFigures,
														esopTooltip: true,
														colors: isGrantType ? defaultColors : poolColors,
													}}
												/>
											}
											switchSelectNode={
												<div className="CircularSwitchToggle">
													<CircularSwitchToggle
														value={isGrantType}
														actions={[
															{ value: false, label: 'Pool status' },
															{ value: true, label: 'Grants status' },
														]}
														onChange={(value) => setIsGrantType(value)}
														type="secondary"
														size="sm"
													/>
													{!isGrantType && !noData && <span>Total pool: {formatNumber(total)}</span>}
												</div>
											}
										/>
									</>
								) : (
									<NoData content="Data will be presented once grants allocation is started" />
								)}
							</DashboardCard>
							<DashboardCard className="card2">
								<div style={{ width: '100%' }}>
									<DashboardText
										arrow={{
											withArrow: true,
											tooltip: 'Navigate to orders',
											onClick: () => (equityPlansStore.selectedTab = GrantsTableTab.Orders),
										}}
										title="Exercise Requests:  0"
										text={''}
										img={IC_ORDERS}
									/>
									<div className="divider" />
									<DashboardText
										arrow={{
											withArrow: true,
											tooltip: 'Navigate to Documents & Signatures',
											onClick: () => history.push(Routes.docs.index),
										}}
										title="Grants Pending Signatures"
										text={
											<span>
												Company: <b>{docs?.company || 0}</b> Employees: <b>{docs?.employees || 0}</b>
											</span>
										}
										img={IC_SIGNATURES}
									/>
									<div className="divider" />
									<DashboardText
										title="Beneficiaries"
										text={
											<span>
												Active: <b>{formatNumber(data.activeBeneficiaries)}</b> Inactive:{' '}
												<b>{formatNumber(data.inactiveBeneficiaries)}</b>
											</span>
										}
										img={IC_BENEFICIARIES}
									/>
								</div>
							</DashboardCard>
							<DashboardCard style={{ padding: '0px' }} className="card3">
								<div className={`${equityDashboardStyle}__vestTable`}>
									<h3>Upcoming vesting events</h3>
									<Table columns={vestingColumns} rows={tableData} rowsPerPage={3} className={`${equityDashboardStyle}__vestTable__table`} />
								</div>
							</DashboardCard>
						</div>
					</WithToggle>
				)}

				<div className={`${equityDashboardStyle}__table-container`}>
					{equityPlansStore.selectedTab === GrantsTableTab.Promised ? (
						<PromisedTable header={tableHeader} expand={!isDashboardOpen} />
					) : equityPlansStore.selectedTab === GrantsTableTab.Grants ? (
						<GrantsTable header={tableHeader} expand={!isDashboardOpen} />
					) : equityPlansStore.selectedTab === GrantsTableTab.Beneficiaries ? (
						<BeneficiariesTable header={tableHeader} expand={!isDashboardOpen} />
					) : equityPlansStore.selectedTab === GrantsTableTab.Orders ? (
						<Orders header={tableHeader} />
					) : equityPlansStore.selectedTab === GrantsTableTab.Cancelations ? (
						<Cancelations header={tableHeader} />
					) : null}
				</div>
			</div>
		</>
	);
};
export default observer(EquityDashboard);
