import { useEffect, useState, useRef } from "react";
import Flex from "../../../../Shared/Components/Layout/Flex";
import StakeholdersLoader from "../../Loaders/StakeholdersLoader";
import { IMG_STAKEHOLDERS_HISTORY } from "../../../../Assets";
import { StakeholdersStyle } from "./Stakeholders.style";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import { IDashboardData } from "../../../../Models/App/PaymentModels";
import Tooltip from "../../../../Shared/Components/Tooltip";
import { CapTablePermission, OptionsPermission } from "../../../../Models/API/UsersAndPermissions/permissions-enum";

interface IProps {
	readonly className?: string;
	readonly flex?: number;
	readonly hasPermission: boolean;
	readonly optionsPermission?: OptionsPermission;
	readonly capTablePermissions?: CapTablePermission;
}

const Stakeholders = (props: IProps) => {
	const mounted = useRef<boolean>(true);
	const { paymentStore } = useRootStore();
	const [data, setData] = useState<IDashboardData | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			const res = await paymentStore.getDashboardData();
			if (!res.isSuccess || !mounted.current) {
				setIsLoading(false);
				return;
			}

			setData(res.data);
			setIsLoading(false);
		};

		!data && fetchData();

		return () => {
			mounted.current = false;
			setData(null);
			setIsLoading(false);
		};
	}, []);

	return (
		<Flex className={`${props.className} ${StakeholdersStyle}`} flex={props.flex} align="start" justify="start" direction="column">
			{isLoading ? (
				<StakeholdersLoader />
			) : (
				<>
					<Flex justify="between" width="100%">
						<Flex className="container">
							<div className="leftSide">
								<img className="leftSide__image" src={IMG_STAKEHOLDERS_HISTORY} alt="Stakeholders" />
								<div className="totalAmount">
									<span className="totalAmount__text">Total</span>
									{props.hasPermission && <span className="totalAmount__amount">{data?.totalUnique ?? 0}</span>}
								</div>
							</div>
							<div className="rightSide">
								<Flex className="infoContainer">
									<Flex justify="start" align="start" gap={17}>
										<span className="infoContainer__title">Shareholders</span>
										<Tooltip className="infoContainer__tooltip" title="Common and Preferred holders" />
									</Flex>
									{props.hasPermission && <span className="infoContainer__info">{data?.stakeholders ?? 0}</span>}
								</Flex>
								<Flex className="infoContainer">
									<Flex justify="start" align="start" gap={17}>
										<span className="infoContainer__title">Beneficiaries</span>
										<Tooltip
											className="infoContainer__tooltip"
											title="The number of beneficiaries which were granted options and not canceled (including those who exercise their options)"
										/>
									</Flex>
									{props.hasPermission && <span className="infoContainer__info">{data?.beneficiaries ?? 0}</span>}
								</Flex>
							</div>
						</Flex>
					</Flex>
				</>
			)}
		</Flex>
	);
};

export default Stakeholders;
