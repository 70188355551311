import { useTranslation } from "react-i18next";
import { ShareClassInfo } from "../../../../Models/API/CapTable";
import { AvailableAmountsResultInfo } from "../../../../Models/API/CapTable/available-amounts-result-info";
import { InputRefs, useFormValidation } from "../../../../Shared/Hooks/useFormValidation";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import { ObjectValidationStatus, commonValidators } from "../../../../Shared/ObjectValidator";

const useShareClassValidation = (
    sc: ShareClassInfo,
    availableAmounts: AvailableAmountsResultInfo,
    isConvertion: boolean = false,
    isAntiDilution: boolean = false,
    inputRefs?: InputRefs<ShareClassInfo>,
    isEdit?: boolean
) => {
    const { capTableStore } = useRootStore();
    const { t } = useTranslation();

    const { isFormValid, formErrors, formValidationState, setFormValidationState, validateForm } = useFormValidation({
        form: sc,
        schema: {
            shareClass: [
                commonValidators.required(t("captable.classNameMustContainALeast1")),
                // commonValidators.regex(/^[a-z0-9$&+,:;=?@#|'"<>/.^*()%!\s-]+$/i, t("captable.invalidName")),
                commonValidators.maxLength(200, t("captable.invalidName")),
                (v) => {
                    if (
                        v &&
                        capTableStore.project?.shareClassesList?.find(
                            (x) =>
                                x.shareClass.shareClass?.toLowerCase() === v.toLowerCase() && x.shareClass.shareClassID !== sc.shareClassID
                        )
                    ) {
                        return {
                            isValid: false,
                            message: t("captable.classNameAlreadyInUsePleaseChoose"),
                        };
                    }
                    return { isValid: true };
                },
            ],
            roundDate: [
                commonValidators.required(t("captable.pleaseSelectAValidDate")),
                (v) => {
                    if (
                        sc?.warrantsExpirationDate &&
                        (sc?.numberOfWarrants ?? 0) > 0 &&
                        v &&
                        (!sc.warrantsExpirationDate || v > sc.warrantsExpirationDate)
                    ) {
                        return {
                            isValid: false,
                            message: t("captable.selectedDateMustBeEarlierThanThe"),
                        };
                    }
                    return { isValid: true };
                },
            ],
            numberOfRegisteredShares: [
                commonValidators.required(),
                commonValidators.range(1, Number.MAX_SAFE_INTEGER, t("validations.pleaseEnterAValidNumber")),
            ],
            numberOfSharesOutstanding: [
                commonValidators.required(),
                commonValidators.positive(t("validations.pleaseEnterAValidNumber")),
                (v) => {
                    if (v) {
                        if (v + (sc?.numberOfWarrants ?? 0) > (sc?.numberOfRegisteredShares ?? 0)) {
                            return {
                                isValid: false,
                                message: t("validations.exceededTheAvailableAmount"),
                            };
                        }
                        if (
                            sc.shareClassID &&
                            availableAmounts &&
                            availableAmounts.minAvailableRegisteredShares &&
                            availableAmounts.minAvailableRegisteredShares > v
                        ) {
                            return {
                                isValid: false,
                                message: t("validations.exceedTheMinimumAmount"),
                            };
                        }
                    }

                    return { isValid: true };
                },
            ],
            issuePricePerShare: [
                commonValidators.required(),

                commonValidators.positive(t("validations.pleaseEnterAValidNumber")),
                commonValidators.lessThan(2147483647),
            ],
            numberOfWarrants: [
                commonValidators.range(0, Number.MAX_SAFE_INTEGER, t("validations.pleaseEnterAValidNumber")),
                (v) => {
                    if (v) {
                        if (v + (sc?.numberOfSharesOutstanding ?? 0) + (sc?.numberOfBSA ?? 0) > (sc?.numberOfRegisteredShares ?? 0)) {
                            return {
                                isValid: false,
                                message: t("validations.exceededTheAvailableAmount"),
                            };
                        }
                        if (
                            sc.shareClassID &&
                            availableAmounts &&
                            availableAmounts.minAvailableRegisteredWarrants &&
                            availableAmounts.minAvailableRegisteredWarrants > v
                        ) {
                            return {
                                isValid: false,
                                message: t("validations.exceedTheMinimumAmount"),
                            };
                        }
                    }

                    return { isValid: true };
                },
            ],
            warrantsExpirationDate: (v) => {
                //Required only if numberOfWarrants is entered
                if ((sc?.numberOfWarrants ?? 0) > 0 && !v) {
                    return { isValid: false, message: t("validations.requiredField") };
                }
                if ((sc?.numberOfWarrants ?? 0) > 0 && v && (!sc.roundDate || v < sc.roundDate)) {
                    return {
                        isValid: false,
                        message: t("captable.selectedDateMustBeLaterThanThe"),
                    };
                }
                return { isValid: true };
            },
            numberOfBSA: [
                commonValidators.range(0, Number.MAX_SAFE_INTEGER, t("validations.pleaseEnterAValidNumber")),
                (v) => {
                    if (v) {
                        if (v + (sc?.numberOfSharesOutstanding ?? 0) + (sc?.numberOfWarrants ?? 0) > (sc?.numberOfRegisteredShares ?? 0)) {
                            return {
                                isValid: false,
                                message: t("validations.exceededTheAvailableAmount"),
                            };
                        }
                        if (
                            sc.shareClassID &&
                            availableAmounts &&
                            availableAmounts.minAvailableBsa &&
                            availableAmounts.minAvailableBsa > v
                        ) {
                            return {
                                isValid: false,
                                message: t("validations.exceedTheMinimumAmount"),
                            };
                        }
                    }

                    return { isValid: true };
                },
            ],
            antiDilutedModeID: [commonValidators.requiredIf(() => isAntiDilution)],
            convertionValueEx: [
                commonValidators.requiredIf(() => isConvertion),
                commonValidators.positive(t("validations.pleaseEnterAValidNumber")),
            ],
        },
        refs: inputRefs,
        isEdit,
    });

    const initialValidationState: ObjectValidationStatus<ShareClassInfo> = {
        shareClass: {
            isValid: false,
            message: "Class name must contain at least 1 letter",
            level: "error",
        },
        roundDate: {
            isValid: false,
            message: "Please select a valid date",
            level: "error",
        },
        numberOfRegisteredShares: {
            isValid: false,
            message: "Required field",
            level: "error",
        },
        numberOfSharesOutstanding: {
            isValid: false,
            message: "Required field",
            level: "error",
        },
        issuePricePerShare: {
            isValid: false,
            message: "Required field",
            level: "error",
        },
        numberOfWarrants: {
            isValid: true,
        },
        warrantsExpirationDate: {
            isValid: true,
        },
        numberOfBSA: {
            isValid: true,
        },
        antiDilutedModeID: {
            isValid: true,
        },
        convertionValueEx: {
            isValid: true,
        },
    };

    return {
        isFormValid,
        formErrors,
        formValidationState,
        setFormValidationState,
        validateForm,
        initialValidationState,
    };
};

export default useShareClassValidation;
