import { css } from "@emotion/css";
import { IContact, IContactCreate } from "../../../Models/API/Contact/contact";
import Button, { IButtonProps } from "../Button/Button";
import { ModalBodyProps } from "../Modal/types";
import Table, { TableColumn } from "../Table/Table";
import Flex from "../Layout/Flex";
import Image from "../Image";
import { IC_COMPANY_CIRCLE, IC_USER_CIRCLE, IC_USER_PROFILE_PURPLE } from "../../../Assets";
import appConfig from "../../../config/config";
import { useState } from "react";
import TextInput from "../Input/TextInput";
import useRootStore from "../../Hooks/useRootStore";
import { REGEX_EMAIL } from "../../Utilities";

type Props = {
    title: string;
    actionLabel: string;
    contacts: IContact[];
    editableContacts: number[];
} & ModalBodyProps;

const Style = css({
    label: "ContactsEmail",
    padding: "6rem",
    display: "flex",
    flexDirection: "column",
    "&__title": {
        fontWeight: 500,
        marginBottom: "0.8rem",
    },
    "&__confirm-btn": {
        marginTop: "1.2rem",
    },
    ".legal-entity-contact": {
        color: appConfig.style.colors.text4,
    },
});

type EditableContacts = Record<number, IContactCreate>;

const ContactsEmailFillTable = ({ title, contacts, actionLabel, editableContacts, onConfirmHandler, onHideHandler }: Props) => {
    const { contactStore } = useRootStore();
    const [editedContacts, setEditerContact] = useState<EditableContacts>(
        contacts.reduce((acc, contact) => {
            if (editableContacts.includes(contact.contactId)) {
                acc[contact.contactId] = contact;
            }
            return acc;
        }, {} as EditableContacts)
    );

    const onEditHandler = (contactId: number, key: string, value: any) => {
        setEditerContact((prevContacts) => {
            if (!prevContacts[contactId]) return prevContacts;
            return {
                ...prevContacts,
                [contactId]: {
                    ...prevContacts[contactId],
                    [key]: value,
                },
            };
        });
    };

    const onSubmit = async () => {
        const res = await contactStore.updateContacts(Object.values(editedContacts));
        res.isSuccess ? onConfirmHandler?.() : onHideHandler?.();
    };

    const tableColumns: TableColumn<IContact>[] = [
        {
            label: "Name",
            name: "firstName",
            render(obj, value) {
                const fullName = obj.firstName + " " + obj.lastName;
                return (
                    <Flex
                        gap="1.2rem"
                        justify="start"
                    >
                        <Image
                            width="3rem"
                            src={obj.isLegalEntity ? IC_COMPANY_CIRCLE : IC_USER_PROFILE_PURPLE}
                            alt={fullName}
                        />
                        <span>
                            {obj.isLegalEntity ? (
                                <>
                                    {obj.companyName} <span className="legal-entity-contact">({fullName})</span>
                                </>
                            ) : (
                                fullName
                            )}
                        </span>
                    </Flex>
                );
            },
        },
        {
            label: "Email",
            name: "email",
            render: (obj) => {
                if (!editableContacts.includes(obj.contactId)) return obj.email;
                return (
                    <TextInput
                        qaid=""
                        value={editedContacts[obj.contactId].email}
                        name="email"
                        onChange={(value, name) => onEditHandler(obj.contactId, name, value)}
                        // onEnter={() => onToggleEdit(obj.contactId)}
                        width="75%"
                        placeholder="Specify email address"
                    />
                );
            },
        },
    ];

    const isEmailRequired = Object.values(editedContacts).some((c) => !REGEX_EMAIL.test(c.email || ""));

    return (
        <div className={Style}>
            <span className={`${Style}__title`}>{title}</span>
            <Table
                columns={tableColumns}
                rows={contacts}
                rowSize={1.5}
            />
            <Button
                qaid="ContactsEmail.Button.Confirm"
                label={actionLabel}
                onClick={onSubmit}
                position="end"
                className={`${Style}__confirm-btn`}
                disabled={isEmailRequired}
            />
        </div>
    );
};

export default ContactsEmailFillTable;
