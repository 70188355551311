import { css } from "@emotion/css";
import { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { IC_ARROW_BACK, IC_REPORT_SETTINGS_PURPLE } from "../../../../Assets";
import { GrantReportData } from "../../../../Models/App/Expensing/report-data";
import { Routes } from "../../../../Routes";
import Button from "../../../../Shared/Components/Button/Button";
import Image from "../../../../Shared/Components/Image";
import Separator from "../../../../Shared/Components/Separator";
import Spinner from "../../../../Shared/Components/Spinner/Spinner";
import { ForwardedRefType } from "../../../../Shared/Hooks/useMultiStepForm";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import { isNullOrUndefined } from "../../../../Shared/Utilities";
import appConfig from "../../../../config/config";
import { breakpoints } from "../../../../config/style/theme";
import { isValidExpenseReport } from "../../helpers/util";
import Report from "./Report";
import { ExpenseReportStatus } from "../../../../Models/API/Expensing/expense-report";

const Style = css({
    label: "ReportSettingsSettings",
    maxWidth: "114rem",
    paddingInline: "4rem",
    width: "100%",
    marginInline: "auto",
    paddingTop: "4rem",
    paddingBottom: "4rem",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    "&__label": {
        display: "flex",
        alignItems: "center",
        gap: "2rem",
        fontSize: "2.5rem",
        fontWeight: 500,
    },
});

const ReportSettings = () => {
    const { expensingStore } = useRootStore();
    const history = useHistory();
    const settingsRef = useRef<ForwardedRefType<GrantReportData>>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const isDisabled = expensingStore.selectedReport?.status === ExpenseReportStatus.Publish;

    if (isNullOrUndefined(expensingStore.company)) return <></>;

    const onSubmit = async () => {
        if (isDisabled) return goBack();
        const report = settingsRef.current?.getData().report;
        if (!isValidExpenseReport(report)) return;

        setIsLoading(true);
        const res = await expensingStore.updateReport(report);
        setIsLoading(false);
        res.isSuccess && goBack();
    };

    const goBack = () => history.push(Routes.expensing.index);

    return (
        <div className={Style}>
            {isLoading && <Spinner />}
            <div className={`${Style}__label`}>
                <Image
                    src={IC_REPORT_SETTINGS_PURPLE}
                    width="5rem"
                />
                <span>Report settings</span>
            </div>
            <Separator />
            <Report
                data={expensingStore.reportData}
                ref={settingsRef}
                isEdit
            />
            <Separator />
            <Button
                qaid="ReportSettings.Button.Save"
                label={isDisabled ? "Done" : "Save"}
                position="end"
                onClick={onSubmit}
            />
        </div>
    );
};

export default ReportSettings;
