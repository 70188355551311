import { observer } from "mobx-react-lite";
import { IGrantCancelation } from "../../../../../Models/App/EquityPlans/GrantCancelations";
import { GrantTable } from "../../../../../Models/App/EquityPlans/Grants";
import { IOrder, OrderType } from "../../../../../Models/App/EquityPlans/Order";
import Spinner from "../../../../../Shared/Components/Spinner/Spinner";
import { TableColumn } from "../../../../../Shared/Components/Table/Table";
import useRootStore from "../../../../../Shared/Hooks/useRootStore";
import { cancelationReason, cancelationType, grantTypes, orderTypes } from "../../../../../Shared/StaticData/equityPlans";
import { formatDate, formatNumber } from "../../../../../Shared/Utilities";
import TableDrawer from "../../../General/TableDrawer/TableDrawer";
import BeneficiaryDetails from "./../BeneficiaryDetails/BeneficiaryDetails";
import BeneficiaryCardStyles from "./BeneficiaryCard.style";

interface OrdersTable {
    orderId: number;
    orderType: string;
    orderDate: Date;
    amount: number;
}

interface CancellationsTable {
    cancelId: number;
    date: Date;
    reason: string;
    cancelType: string;
    grace: string;
}

interface BenTable {
    fullName: { firstName: string; lastName: string };
    outstanding: number;
    grantDate: Date;
    grantType: number;
    grantNumber: string;
    granted: number;
    planName: string;
    vested: number;
    unvested: number;
    grantId: number;

    // empNumber: string;
}

interface BenCardProps {
    btnFunc: () => void;
    openGrantModal: (grantId: number) => void;
    openOrderModal: () => void;
    openCancelationModal: () => void;
    isEdit: boolean;
}

const BeneficiaryCard = (props: BenCardProps) => {
    const { equityPlansStore } = useRootStore();
    // const { openGrntCard, onEditOrderHandler } = useEsopModals();

    const grantsTableColumns: TableColumn<GrantTable>[] = [
        {
            label: "Grant number",
            name: "grantNumber",
            sortable: true,
        },
        {
            label: "Grant type",
            name: "grantType",
            format: (val) => grantTypes[val]?.label,
            sortable: false,
            style: { flex: 1.5 },
        },
        {
            label: "Grant date",
            name: "grantDate",
            format: (val: any) => formatDate(val),
            sortable: true,
        },
        {
            label: "Granted",
            name: "granted",
            sortable: true,
            format: (val) => formatNumber(val),
        },
        {
            label: "Unvested",
            name: "unvested",
            sortable: true,
            format: (val) => formatNumber(val),
        },
        {
            label: "Exercisable",
            name: "exercisable",
            sortable: true,
            format: (val) => formatNumber(val),
        },
        {
            label: "Outstanding",
            name: "outstanding",
            sortable: true,
            format: (val) => formatNumber(val),
        },
    ];

    const ordersTableColumns: TableColumn<IOrder>[] = [
        {
            label: "Order number",
            name: "orderNumber",
        },
        {
            label: "Grant number",
            name: "grantNumber",
        },
        {
            label: "Order type",
            name: "orderType",
            format(val) {
                return orderTypes[val]?.label;
            },
        },
        {
            label: "Order date",
            name: "date",
            format(val) {
                return formatDate(val);
            },
        },
        {
            label: "Amount",
            name: "amount",
            format: (val) => formatNumber(val),
        },
        {
            label: "Exercise price",
            name: "exercisePrice",
        },
        {
            label: "Currency",
            name: "currency",
        },
        {
            label: "Sale price",
            name: "sellPrice",
            format(val, obj) {
                return obj?.orderType === OrderType.Exercise ? "" : val;
            },
        },
    ];

    const cancelationsTableColumns: TableColumn<IGrantCancelation>[] = [
        {
            label: "Cancelation number",
            name: "grantCancelationId",
        },
        {
            label: "Cancel type",
            name: "cancelType",
            format(val, obj) {
                if (!obj) return;
                return cancelationType.filter((ctyp) => obj.cancelType === ctyp.value)[0].label;
            },
        },
        {
            label: "Cancel date",
            name: "cancelationDate",
            format(val) {
                return formatDate(val);
            },
        },
        {
            label: "Cancel in",
            name: "gracePeriodNum",
        },
        {
            label: "Cancel reason",
            name: "cancelationReason",
            format(val, obj) {
                if (!obj) return;
                return cancelationReason.filter((ctyp) => +obj.cancelationReason === ctyp.value)[0].label;
            },
        },
        // {
        // 	name: "menu",
        // 	label: "",
        // 	menuItems: [
        // 		{
        // 			onClick: (obj) => { },
        // 			element: <img src={IC_EDIT_GRAY} alt="edit" />,
        // 			qaid: "Beneficiary.Button.Edit",
        // 		},
        // 		{
        // 			onClick: (obj) => { },
        // 			element: <img src={IC_TRASH2} alt="delete" />,
        // 			qaid: "Beneficiary.Button.Delete",
        // 		},
        // 	],
        // },
    ];

    // const handleModal = (row?: GrantTable | any) => {
    // 	showModal({
    // 		title: `Grant # ${row?.grantNumber}`,
    // 		body: <GrantCard />,
    // 		height: "100%",
    // 		width: "70%",
    // 		isMust: true,
    // 		showBackBtn: true,
    // 	});
    // };

    // const openGrntCard = (row: GrantTable) => {
    // 	equityPlansStore.GetSingleGrant(row.grantId);
    // 	equityPlansStore.GetOrdersForSingleGrant(row.beneficiaryId, row.grantId);
    // 	equityPlansStore.GetCancelationOrdersForSingleGrant(row.beneficiaryId, row.grantId);
    // 	handleModal(row);
    // };

    const getDrawerHeader = (title: string, postfix: string, total: number = 0) => {
        return (
            <div className={`${BeneficiaryCardStyles}__drawer-header`}>
                <span className="bold">{title}</span>
                <span className="header-comment">
                    ({total} {postfix})
                </span>
            </div>
        );
    };

    return (
        <div className={BeneficiaryCardStyles}>
            {equityPlansStore.beneficiaryData.firstName ? (
                <>
                    <BeneficiaryDetails
                        type="detailed"
                        isEdit={props.isEdit}
                    />
                    <div>
                        {/* Grants */}

                        <TableDrawer
                            tableColumns={grantsTableColumns}
                            tableData={equityPlansStore.beneficiaryGrants}
                            rowClicked={(obj) => props.openGrantModal(obj.grantId)}
                            actionBtn={{ func: props.btnFunc, desc: "Add new grant" }}
                            header={getDrawerHeader(
                                "Beneficiary grants",
                                (equityPlansStore.beneficiaryGrants?.length ?? 0) > 1 ? "grants" : "grant",
                                equityPlansStore.beneficiaryGrants?.length
                            )}
                        />

                        {/* Orders */}

                        <TableDrawer
                            tableColumns={ordersTableColumns}
                            tableData={equityPlansStore.beneficiaryOrders}
                            actionBtn={{ func: props.openOrderModal, desc: "Add new grant order" }}
                            header={getDrawerHeader(
                                `Beneficiary orders`,
                                (equityPlansStore.beneficiaryOrders?.length ?? 0) > 1 ? "orders" : "order",
                                equityPlansStore.beneficiaryOrders?.length
                            )}
                        />

                        {/* Cancelations */}

                        <TableDrawer
                            tableColumns={cancelationsTableColumns}
                            tableData={equityPlansStore.benficiaryGrantCancelations}
                            actionBtn={{ func: props.openCancelationModal, desc: "Add new grant cancelation" }}
                            header={getDrawerHeader(
                                `Beneficiary grant cancelations`,
                                (equityPlansStore.benficiaryGrantCancelations?.length ?? 0) > 1 ? "cancelations" : "canceletion",
                                equityPlansStore.benficiaryGrantCancelations?.length
                            )}
                        />
                    </div>
                </>
            ) : (
                <Spinner
                    attachParent
                    incorporated
                    center
                />
            )}
        </div>
    );
};

export default observer(BeneficiaryCard);

/* <Drawer
                    actionBtn={{ func: props.btnFunc, desc: "Add new grant" }}
                    header={getDrawerHeader("Beneficiary Grants", "Grants", equityPlansStore.beneficiaryGrants?.length)}
                >
                    <Table
                        columns={grantsTableColumns}
                        rows={equityPlansStore.beneficiaryGrants}
                        scrollAfterRows={5}
                        onRowClick={props.openGrantModal}
                    // onExpandRender={(row) => {
                    // 	equityPlansStore.GetSingleGrant(row.grantId);
                    // 	return <GrantDetails minimized blocked grantBtn={props.openGrantModal} />;
                    // }}
                    />
                </Drawer> */

/* <Drawer
                    actionBtn={{ func: props.openOrderModal, desc: "Add new grant order" }}
                    header={getDrawerHeader(`Beneficiary Orders`, "Orders", equityPlansStore.beneficiaryOrders?.length)}
                >
                    <Table
                        columns={ordersTableColumns}
                        rows={equityPlansStore.beneficiaryOrders}
                        // onRowClick={(ordr) => onEditOrderHandler(ordr.orderId)}
                        scrollAfterRows={6}

                    // onExpandRender={(row) => {
                    // 	equityPlansStore.GetBeneficiaryOrder(row.orderId);
                    // 	return <OrderRow row={row} />;
                    // }}
                    />
                </Drawer> */

/* <Drawer
                    actionBtn={{ func: props.openCancelationModal, desc: "Add new grant cancelation" }}
                    header={getDrawerHeader(`Beneficiary Grant Cancelations`, "Orders", equityPlansStore.benficiaryGrantCancelations?.length)}
                >
                    <Table
                        columns={cancelationsTableColumns}
                        rows={equityPlansStore.benficiaryGrantCancelations}
                        scrollAfterRows={6}
                        defaultCellSize={40}
                        onExpandRender={() => <div>XXXXXXXXXXX</div>}
                    />
                </Drawer> */
