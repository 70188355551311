import styled from "@emotion/styled";
import { breakpoints } from "../../../config/style/theme";

export const LEGEND_WIDTH = "30rem";
export const LEGEND_WIDTH_XL = "100%" || "34rem";
const LEGEND_ITEM_BOTTOM_SPACING = "1.6rem";
const LEGEND_LABEL_FONT_SIZE = "1.4rem";

export const StyledChartLegend = styled.ul`
    label: ChartLegend;
    display: grid;
    row-gap: ${LEGEND_ITEM_BOTTOM_SPACING};
    padding: unset;
    margin: 0 auto;
    // overflow-y: auto;
    max-height: 20rem;
    width: 100%;
    // padding-right: 1.6rem;

    // @media (min-width: ${breakpoints.xl}) {
    //     width: ${LEGEND_WIDTH_XL};
    // }
`;

export const ChartLegendItem = styled.li`
    label: ChartLegendItem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    font-size: ${LEGEND_LABEL_FONT_SIZE};

    svg {
        // margin-right: 1rem;
        font-size: 2rem;
        border-radius: 10px;
    }
`;

export const ChartLegendItemLabel = styled.span`
    // flex-grow: 1;
    // max-width: 20rem;
    min-width: 20rem;
    flex: 2 0 20rem;
    display: flex;
    gap: 1rem;
`;

export const ChartLegendItemFigure = styled.span`
    label: ChartLegendItem__Figure;
    display: flex;
    justify-content: flex-end;
    &.center-figure {
        flex: 1 0 7rem;
        justify-content: flex-start;
    }
    flex-grow: 1;
    flex-basis: 7rem;
`;
