import { observer } from 'mobx-react-lite';
import { ReactNode, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { useTitle } from 'react-use';
import { rootStore } from '../..';
import { UserStatus } from '../../Models/API/enums';
import { Routes } from '../../Routes';
import useRootStore from '../Hooks/useRootStore';
import { isNullOrUndefined } from '../Utilities';

interface IPrivateRouteProps extends RouteProps {
	isRequireAuth?: boolean;
	requiredUserStatus?: UserStatus;
	title: string;
	children?: ReactNode;
}

const ComponentWithTitle = ({ title, ...rest }: Partial<IPrivateRouteProps>) => {
	// const { t } = useTranslation();
	useTitle(`altshare - ${title}`); // Setting page title for google analytics
	const location = useLocation();
	const { appState, companyStore } = useRootStore();

	useEffect(() => {
		!isNullOrUndefined(title) && appState.setPageTitle(title);
	}, []);

	ReactGA.send({
		hitType: 'pageview',
		page: location.pathname + location.search,
		companyName: companyStore.company.companyLegalName,
	});

	return <Route {...rest} />;
};

const isUserStatus = (status: UserStatus): boolean => {
	return status === rootStore.auth.userInfo.status;
};

const PrivateRoute: React.FC<IPrivateRouteProps> = ({ isRequireAuth = false, title, requiredUserStatus, ...rest }) => {
	const {
		auth: { isUserActive },
		appState,
	} = useRootStore();
	const location = useLocation();

	if (requiredUserStatus && !isUserStatus(requiredUserStatus)) {
		switch (rootStore.auth.userInfo.status) {
			case UserStatus.PendingForAccountSetupForGoogle:
				return <Redirect to={Routes.account.accountSetup} />;
			case UserStatus.PendingForCompanyRegist:
				return <Redirect to={Routes.account.companySetup} />;
			case UserStatus.PendingForPaymentPlan:
				return <Redirect to={Routes.payment.pricing} />;
			default:
				return <Redirect to={Routes.account.login} />;
		}
	}

	if (isUserActive && !isRequireAuth) {
		return <Redirect to={Routes.dashboard.index} />;
	}

	if (!isUserActive && isRequireAuth) {
		appState.redirectUrlAfterLogin = location.pathname + location.search;
		return <Redirect to={Routes.account.login} />;
	}

	return <ComponentWithTitle title={title} {...rest} />;
};

export default observer(PrivateRoute);
