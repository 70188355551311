import { Currencies } from "../enums";
import { GrantReportPeriodEnum } from "./grant-report-period-enum";

export type ExpenseReport = {
    expenseCompanyReportId: number;
    reportDateFrom: Date;
    reportLastId: number;
    expenseCompanyReportName: string;
    reportDateTo: Date;
    price409: number;
    companyId: number;
    expensingCalculationMethod: number;
    expenseAmortizationStartDate: number;
    status: ExpenseReportStatus;
    saaSUserId: number;
    period: GrantReportPeriodEnum;
    operationalCurrency: Currencies;
};

export enum ExpenseReportStatus {
    Draft,
    Publish,
}
