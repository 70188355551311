import styled from "@emotion/styled";

import { StyledWidgetDashboard } from "../shared/components/WidgetDashboard/WidgetDashboard.style";
import { breakpoints } from "../shared/dependencies/breakpoints";

const PREROUND_MAX_WIDTH_XL = "67.5rem";
const POSTROUND_MAX_WIDTH_XL = "114rem";

export const Wrapper = styled(StyledWidgetDashboard)`
	label: Funding;
	grid-template-areas: "PreRound" "PostRound";

	@media (min-width: ${breakpoints.sm}) {
		grid-template-areas: "PreRound PostRound";
	}

	@media (min-width: ${breakpoints.xl}) {
		grid-template-columns: 1fr 1.4fr;
	}
`;

export const PreRoundWidgetWrapper = styled.div`
	label: PreRoundWidgetWrapper;
	grid-area: PreRound;
`;

export const PostRoundWidgetWrapper = styled.div`
	label: PostRoundWidgetWrapper;
	grid-area: PostRound;
`;
