import { IC_WATERFALL_SUMMARY } from "../../../../../../../Assets";
import Image from "../../../../../../../Shared/Components/Image";
import { TableColumn } from "../../../../../../../Shared/Components/Table/Table";
import { formatDecimal, formatNumber, isNumber } from "../../../../../../../Shared/Utilities";
import appConfig from "../../../../../../../config/config";
import {
    IBreakPoint,
    IDestructuredWaterfallSummaryData,
    IEquityHolder,
    IEquityHolderParsed,
    IShareClassesSummaryCalculation,
    IWaterfallSummaryData,
    IWaterfallSummaryDataParsed,
} from "./types";

// ## parsing string number:
export function parseNumericValue(num: any) {
    if (typeof num !== "number" || num === null || isNaN(num)) return;

    return Number.isInteger(num) ? Number(num.toFixed(0)) : Number(num.toFixed(3));
}

// ## mapping destructured data for better naming:
export function mapEquityHolderAttributes(data: IEquityHolder): IEquityHolderParsed {
    const { isoption, totalShareClassOutOfClassesTotal_CommonShareEquivalentPercentage, shares, ...rest } = data;

    return {
        ...rest,
        percentageOfShares: totalShareClassOutOfClassesTotal_CommonShareEquivalentPercentage || 0,
        numberOfShares: shares || 0,
        isOption: isoption || false,
        id: rest.id,
    };
}

// # ExitSimulationForm:
// ## Interval:
export function getMinIntervalValue(min: number, max: number): number {
    return (max - min) / 10;
}

export function getMaxIntervalValue(min: number, max: number): number {
    return Math.floor(max - min);
}

// ## Select Exit Value:
export function replaceClosestNumber(arr: number[], num: number): number[] {
    if (arr.includes(num)) {
        return arr;
    } else {
        const closest = arr.reduce((prev, curr) => {
            return Math.abs(curr - num) < Math.abs(prev - num) ? curr : prev;
        });
        return arr.map((e) => (e === closest ? num : e));
    }
}

// ## payoffBarChart:
// used for shareClassesSummaryCalculations and payoffBreakdown:
export function findItemByExitValue<T extends { exitValue: number }>(items: T[], exitValue: number): T | null {
    if (!items) return null;
    const holder = items.find((holder: T) => holder.exitValue === exitValue);
    return holder || null;
}

// ValuationTable:
const getTotalData = (data: IEquityHolderParsed[]): IEquityHolderParsed => {
    let numberOfShares = 0,
        percentageOfShares = 0,
        originalInvestment = 0,
        totalShareClassValuation = 0,
        investmentMultiple = null;

    data.forEach((equityHolder: IEquityHolderParsed) => {
        numberOfShares += equityHolder.numberOfShares;
        percentageOfShares += equityHolder.percentageOfShares;
        originalInvestment += equityHolder.originalInvestment || 0;
        totalShareClassValuation += equityHolder.totalShareClassValuation || 0;
    });

    return {
        name: "Total",
        investmentMultiple,
        numberOfShares,
        percentageOfShares,
        originalInvestment,
        totalShareClassValuation,
        id: -1,
        breakPoints: [],
        isOption: false,
        issuePricePerShare: 0,
        preferenceSummary: "",
        seniorityLevel: -1,
        shareClassesSummaryCalculations: [],
        shareClassHolders: [],
        payoffBreakdown: undefined,
    };
};

export function parseWaterfallSummaryData(data: IWaterfallSummaryData): IWaterfallSummaryDataParsed {
    const shareClassesData = data?.shareClasses?.shareClassValuation;
    const shareHoldersData = data?.shareHolders?.shareHolderValuation;

    const mappedShareClassesData = shareClassesData?.map(
        (shareClassData: IEquityHolder): IEquityHolderParsed => mapEquityHolderAttributes(shareClassData)
    );
    const mappedShareHoldersData = shareHoldersData?.map(
        (shareHolderData: IEquityHolder): IEquityHolderParsed => mapEquityHolderAttributes(shareHolderData)
    );

    return {
        ...data,
        shareClasses: {
            shareClassValuation: mappedShareClassesData,
        },
        shareHolders: {
            shareHolderValuation: mappedShareHoldersData,
        },
    };
}

export function getDestrcturedValuationData(
    data: IWaterfallSummaryDataParsed | null,
    exitValue: number
): IDestructuredWaterfallSummaryData | null {
    if (data === null) return null;
    const shareClassesData = data?.shareClasses?.shareClassValuation;
    const shareHoldersData = data?.shareHolders?.shareHolderValuation;

    const shareClassesTableData: IEquityHolderParsed[] = data.shareClasses.shareClassValuation?.map(
        ({ id, preferenceSummary, shareClassHolders, breakPoints, shareClassesSummaryCalculations, ...rst }) => {
            const dataByExitValue = shareClassesSummaryCalculations.find((item) => item.exitValue === exitValue);
            if (!dataByExitValue) {
                return {
                    ...rst,
                    shareClassesSummaryCalculations,
                    breakPoints: shareClassesData.find((sc) => sc.id === id)?.breakPoints,
                    totalShareClassValuation: 0, // ensure it's a number
                    investmentMultiple: 0,
                    id,
                } as IEquityHolderParsed;
            }

            const { totalShareValue, investmentMultiple, shares } = dataByExitValue;
            return {
                ...rst,
                breakPoints: shareClassesData.find((sc) => sc.id === id)?.breakPoints,
                shareClassesSummaryCalculations,
                totalShareClassValuation: totalShareValue || 0, // ensure it's a number
                investmentMultiple: investmentMultiple || 0,
                numberOfShares: shares,
                id,
            } as IEquityHolderParsed;
        }
    );

    shareClassesTableData?.push(getTotalData(shareClassesTableData));

    const shareHoldersTableData: IEquityHolderParsed[] = data.shareHolders.shareHolderValuation?.map(
        ({ id, preferenceSummary, shareClassHolders, breakPoints, shareClassesSummaryCalculations, ...rst }) => {
            const dataByExitValue = shareClassesSummaryCalculations.find((item) => item.exitValue === exitValue);
            if (!dataByExitValue) {
                return {
                    ...rst,
                    breakPoints: shareHoldersData.find((sh) => sh.id === id)?.breakPoints,
                    shareClassesSummaryCalculations,
                    totalShareClassValuation: 0, // ensure it's a number
                    investmentMultiple: 0,
                    id,
                } as IEquityHolderParsed;
            }

            const { totalShareValue, investmentMultiple, shares } = dataByExitValue;
            return {
                ...rst,
                breakPoints: shareHoldersData.find((sh) => sh.id === id)?.breakPoints,
                shareClassesSummaryCalculations,
                totalShareClassValuation: totalShareValue || 0, // ensure it's a number
                investmentMultiple: rst.originalInvestment ? investmentMultiple : 0,
                id,
            } as IEquityHolderParsed;
        }
    );
    shareHoldersTableData?.push(getTotalData(shareHoldersTableData));

    return {
        shareClasses: [...shareClassesData, getTotalValues(shareClassesData)],
        shareHolders: [...sortEquityHolderParsedArray(shareHoldersData), getTotalValues(shareHoldersData)],
        shareClassesTableData,
        shareHoldersTableData: sortEquityHolderParsedArray(shareHoldersTableData),
    };
}

const getTotalValues = (array: IEquityHolderParsed[]) => {
    return array.reduce(
        (acc: IEquityHolderParsed, shareholder) => ({
            ...acc,
            // investmentMultiple: (acc.investmentMultiple ?? 0) + (shareholder.investmentMultiple ?? 0),
            originalInvestment: (acc.originalInvestment ?? 0) + (shareholder.originalInvestment ?? 0),
            percentageOfShares: (acc.percentageOfShares ?? 0) + (shareholder.percentageOfShares ?? 0),
            numberOfShares: (acc.numberOfShares ?? 0) + (shareholder.numberOfShares ?? 0),
            totalShareClassValuation: (acc.totalShareClassValuation ?? 0) + (shareholder.totalShareClassValuation ?? 0),
            issuePricePerShare: (acc.issuePricePerShare ?? 0) + (shareholder.issuePricePerShare ?? 0),
        }),
        {
            id: -1,
            breakPoints: [],
            investmentMultiple: null,
            isOption: false,
            issuePricePerShare: 0,
            name: "",
            numberOfShares: 0,
            originalInvestment: 0,
            percentageOfShares: 0,
            preferenceSummary: "",
            seniorityLevel: -1,
            shareClassesSummaryCalculations: [],
            shareClassHolders: [],
            totalShareClassValuation: 0,
            payoffBreakdown: [],
        }
    );
};

const sharedTableData: TableColumn<IEquityHolderParsed>[] = [
    {
        name: "numberOfShares",
        label: "Number of Shares Fully Diluted",
        format(val: number) {
            return formatDecimal(val);
        },
    },
    {
        name: "percentageOfShares",
        label: "% of Shares Fully Diluted",
        format(val: number) {
            return `${parseNumericValue(val)}%`;
        },
    },
    {
        name: "originalInvestment",
        label: "Original Investment ($M)",
        format(val: number) {
            return formatDecimal(val, { decimalLength: 4 });
        },
    },
    {
        name: "totalShareClassValuation",
        label: "Payoff ($M)",
        format(val: number) {
            return val ? parseNumericValue(val) : ("--" as any);
        },
    },
    {
        name: "investmentMultiple",
        label: "Investment Multiple",
        render(obj, value) {
            if (value === null || !isNumber(value)) return "--";
            return value >= 1 ? (
                <span>{formatDecimal(value)}x</span>
            ) : (
                <span style={{ color: appConfig.style.colors.orange }}>{formatDecimal(value)}x</span>
            );
        },
        style: { flex: 0.5 },
    },
    {
        name: "actions",
        label: "Summary",
        render(obj, value) {
            return <Image src={IC_WATERFALL_SUMMARY} />;
        },
        style: { flex: 0.5, justifyContent: "center" },
    },
];

export const shareClassValuationTable: TableColumn<IEquityHolderParsed>[] = [
    {
        name: "seniorityLevel",
        label: "Seniority Level",
    },
    {
        name: "name",
        label: "Share class",
    },
    ...sharedTableData,
];

export const shareHolderValuationTable: TableColumn<IEquityHolderParsed>[] = [
    {
        name: "name",
        label: "Shareholder",
        style: { fontWeight: "bold" },
    },
    ...sharedTableData,
];

export const createDynamicTable = (equityHolder: IEquityHolderParsed): Array<Array<string>> => {
    const tableData = equityHolder.shareClassesSummaryCalculations;
    function sortByExitValue(items: IShareClassesSummaryCalculation[]) {
        return items.sort((a: IShareClassesSummaryCalculation, b: IShareClassesSummaryCalculation) => a.exitValue - b.exitValue);
    }

    const header: string[] = ["Exit Value ($M)"];
    const payoff: string[] = ["Payoff ($M)"];
    const investment: string[] = ["Investment Multiple"];
    const irr: string[] = ["IRR"];

    const sortedTableData = sortByExitValue([...tableData]);

    sortedTableData.forEach((item: IShareClassesSummaryCalculation) => {
        item.exitValue && header.push(formatNumber(item.exitValue));
        payoff.push(item.totalShareValue ? formatNumber(item.totalShareValue) : "--");
        investment.push(item.investmentMultiple ? `${formatNumber(item.investmentMultiple)}x` : "--");
        item.iRR && irr.push(item.iRR);
    });

    let result = [header, payoff, investment, irr];
    result = result.filter((item: string[]) => !item.slice(1).every((value) => value === "--"));
    return result;
};

export const shareHoldersWaterfallTable: TableColumn<IBreakPoint>[] = [
    {
        name: "rangeFrom",
        label: "Company Exit Value ($M)",
        format: (val, obj) => {
            // console.log("​obj", obj);
            if (!obj) return "-";
            return obj.isEndOfRange
                ? `${parseNumericValue(obj.rangeFrom)} - end of range`
                : `${parseNumericValue(obj.rangeFrom)} - ${parseNumericValue(obj.rangeTo)}`;
        },
    },
    {
        name: "percent",
        label: `Distribution (%)`,
    },
];

export const shareClassesWaterfallTable: TableColumn<IBreakPoint>[] = [
    {
        name: "rangeFrom",
        label: "Company Exit Value ($M)",
        format: (val, obj) => {
            if (!obj) return "-";
            return obj.isEndOfRange
                ? `${parseNumericValue(obj.rangeFrom)} - end of range`
                : `${parseNumericValue(obj.rangeFrom)} - ${parseNumericValue(obj.rangeTo)}`;
        },
    },
    {
        name: "percent",
        label: `Distribution (%)`,
    },
    {
        name: "waterfallDescription",
        label: "Details",
        format: (val: string) => (val ? val : "--"),
    },
];

export function sortEquityHolderParsedArray<
    T extends {
        totalShareClassValuation: number | null;
        name: string;
    }
>(data: T[]): T[] {
    let result = [];
    if (!data) return [];
    result = [...data].sort((a, b) => (b.totalShareClassValuation || 0) - (a.totalShareClassValuation || 0));

    const rowTotalIndex = result.findIndex((item) => item.name === "Total");

    if (rowTotalIndex >= 0) {
        const [total] = result.splice(rowTotalIndex, 1);
        result.push(total);
    }

    return result;
}

export function extractDataForPayoffBarChart(holders: IEquityHolderParsed[] = [], selectedExitValue: number) {
    const barChartData = [...holders.slice(0, holders.length - 1)].map((holder: IEquityHolderParsed) => {
        const selectedSummary = findItemByExitValue(holder.shareClassesSummaryCalculations, selectedExitValue);

        const label = holder.name?.trim() || "--";
        let figure = selectedSummary?.totalShareValue ? selectedSummary?.totalShareValue.toFixed(3) : 0;
        figure = figure.toString().replace(/\.?0*$/, "");

        const tooltip = parseNumericValue(selectedSummary?.investmentMultiple)?.toString() || "N/A";

        return {
            label,
            tooltip,
            figure,
        };
    });

    const labels = barChartData.map((item) => item.label);
    const figures = barChartData.map((item) => +item.figure);
    const tooltips = barChartData.map((item) => item.tooltip);

    return {
        labels,
        figures,
        tooltips,
    };
}
