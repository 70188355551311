import { css } from '@emotion/css';
import appConfig from '../../../../../config/config';

const importCapTableStyle = css({
	label: 'ImportCapTableStyle',
	display: 'flex',
	flexDirection: 'column',
	'&__header': {
		borderBottom: `1px solid ${appConfig.style.colors.table}`,
		marginBottom: '3rem',
		paddingBottom: '2rem',
	},
	'&__radios': {
		display: 'flex',
		marginTop: '2rem',
		gap: '2rem',
		'> *': {
			flex: 1,
		},
	},
});

export default importCapTableStyle;
