import { css } from "@emotion/css";
import appConfig from "../../../../../../config/config";

const AddNewStepStyle = css({
	"display": "flex",
	"flexDirection": "column",
	"justifyContent": "space-between",
	"paddingBottom": 20,
	"marginTop": 20,

	"&__form": {
		width: "100%",
		display: "grid",
		gridTemplateColumns: "repeat(auto-fit, minmax(140px, 1fr))",
		gridColumnGap: 15,
		gridRowGap: 15,
	},
});

export default AddNewStepStyle;
