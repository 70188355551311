import PhoneInput2, { CountryData, PhoneInputProps } from "react-phone-input-2";
import InputWrapper from "./InputWrapper";
import { createRef, forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { inputClassName } from "./Input.Style";
import classNames from "classnames";
import { InputRef, MobileInputProps } from "../../../Models/App/Inputs/types";
import Flex from "../Layout/Flex";
import OverflowText from "../OverflowText";
// import 'react-phone-input-2/lib/style.css';

// Omit<PhoneInputProps, "value" | "onChange">,

const PhoneInput = forwardRef<InputRef, MobileInputProps>(({ value, mobilePrefix, onChange, ...props }, ref) => {
	const phoneNumberValue = mobilePrefix && value ? mobilePrefix.substring(1) + value : "";
	const [isFocusOnce, setIsFocusOnce] = useState<boolean>(false);
	const [error, setError] = useState<string>();

	useEffect(() => {
		const inputs = Array.from(document.querySelectorAll(".form-control"));
		inputs.forEach((input) => input.classList.remove("form-control"));
	}, [value]);

	useImperativeHandle(ref, () => ({
		resetError() {
			setIsFocusOnce(false);
		},
		showError() {
			setIsFocusOnce(true);
		},
		clear() {
			// setInternalValue("");
		},
		focus() { },
		setErrorMessage(err: string) {
			setError(err);
		},
		getName() {
			return props.name;
		},
	}));
	const showError = (isFocusOnce || props.forceValidation) && props.error;

	return (
		<InputWrapper {...props} error={showError ? props.error : undefined}>
			{props.isViewMode ? (
				<Flex height="3.6rem" align="end" justify="start">
					<OverflowText>
						{mobilePrefix} {value || "---------"}
					</OverflowText>
				</Flex>
			) : (
				<PhoneInput2
					// onFocus={() => SetErrors(null)}
					{...props}
					countryCodeEditable
					specialLabel=""
					inputClass={classNames(inputClassName, props.className || "", props.qaid, {
						inverse: props.inverse,
						flat: props.flat,
					})}
					inputStyle={{ paddingLeft: "4.5rem", fontSize: "1.4rem" }}
					onClick={(e) => { }}
					enableSearch={true}
					onChange={(value: string, data: CountryData) => {
						setIsFocusOnce(false);
						const phoneNumber = value.substring(value.indexOf(data.dialCode) + data.dialCode?.toString().length);
						onChange?.(phoneNumber, data.dialCode ? `+${data.dialCode}` : undefined);
					}}
					onBlur={(e) => {
						setIsFocusOnce(true);
						props.onBlur?.(e);
					}}
					value={phoneNumberValue}
				/>
			)}
		</InputWrapper>
	);
});

export default PhoneInput;
