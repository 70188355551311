import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ChartOptions } from "chart.js";
import { Line } from "react-chartjs-2";
import Flex from "../../../../Shared/Components/Layout/Flex";
import { ValuationStyle } from "./Valuation.style";
import { useEffect, useRef, useState } from "react";
import ValutionLoader from "../../Loaders/ValutionLoader";
import { formatDate } from "../../../../Shared/Utilities";
import { IFundingGraph } from "../../../../Models/API/CapTable/dashboard-cap-table";
import Image from "../../../../Shared/Components/Image";
import { IC_DASHBOARD_LOCK_CARD } from "../../../../Assets";
import { formatNumberWithCurrency } from "../../../../Shared/Hooks/useFormatter";
import useRootStore from "../../../../Shared/Hooks/useRootStore";

interface IChartData {
    readonly x: Date;
    readonly y: number;
    readonly shareClassName: string;
    readonly investmantAmount: number;
    readonly roundDate: Date;
    readonly moneyValuation: number;
    readonly price: number;
}

interface IProps {
    readonly data: IFundingGraph[] | null;
    readonly className?: string;
    readonly hasPermission: boolean;
    readonly publishedDate: Date | null;
}

const Valuation = (props: IProps) => {
    const { appState } = useRootStore();
    ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
    const mounted = useRef<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [chartData, setChartData] = useState<IChartData[]>([]);
    const [labels, setLabels] = useState<Date[]>([]);

    useEffect(() => {
        if (!mounted.current) return;

        if (!props.hasPermission) return;

        if (!props.data) {
            setIsLoading(true);
            return;
        }

        setIsLoading(false);
        const labels = props.data.map((item) => item.roundDate);

        const chartData = props.data.map((item) => ({
            x: item.roundDate,
            y: item.moneyValuation,
            shareClassName: item.shareClassName,
            investmantAmount: item.investmantAmount,
            roundDate: item.roundDate,
            moneyValuation: item.moneyValuation,
            price: item.price,
        }));

        chartData.sort((a, b) => {
            return new Date(a.x).getTime() - new Date(b.x).getTime();
        });

        labels.sort((a, b) => {
            return new Date(a).getTime() - new Date(b).getTime();
        });

        setLabels(labels);
        setChartData(chartData);

        return () => {
            mounted.current = false;
        };
    }, [props.data]);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        tension: 0.4,
        showTooltips: true,
        interaction: {
            mode: "index",
            intersect: false,
        },

        scales: {
            x: {
                ticks: {
                    display: false,
                },

                grid: {
                    drawBorder: false,
                    display: false,
                },
            },
            y: {
                ticks: {
                    display: false,
                },

                grid: {
                    drawBorder: false,
                    display: false,
                },
            },
        },

        plugins: {
            legend: {
                display: false,
            },

            tooltip: {
                mode: "nearest",
                displayColors: false,
                intersect: false,
                backgroundColor: "#4D4D4D",
                padding: 18,
                titleSpacing: 3,
                titleMarginBottom: 3,
                footerSpacing: 3,
                footerMarginTop: 17,
                titleFont: {
                    size: appState.fontSize * 1.3,
                    weight: "normal",
                },
                bodyFont: {
                    size: appState.fontSize * 1.3,
                },
                footerFont: {
                    size: appState.fontSize * 1.3,
                    weight: "normal",
                },
                legend: {
                    display: false,
                },

                callbacks: {
                    title: () => undefined,
                    label: (tooltipItem: any) => [
                        `Series ${tooltipItem.raw?.shareClassName}`,
                        formatNumberWithCurrency(tooltipItem.raw?.investmantAmount),
                        formatDate(tooltipItem.raw?.roundDate),
                        "",
                        "Issue price per share",
                        formatNumberWithCurrency(tooltipItem.raw?.price),
                        "",
                        "Post money valuation",
                        formatNumberWithCurrency(tooltipItem.raw?.moneyValuation),
                    ],
                    // title: (tooltipItem: any) => `Series ${tooltipItem[0].raw?.shareClassName}`,
                    // afterTitle: (tooltipItem: any) => formatNumberWithCurrency(tooltipItem[0].raw?.investmantAmount),
                    // label: (tooltipItem: any) => formatDate(tooltipItem.raw?.roundDate),
                    // footer: () => "Post money valuation",
                    // afterFooter: (tooltipItem: any) => formatNumberWithCurrency(tooltipItem[0].raw?.moneyValuation),
                },
            },
        },
    };

    const data = {
        labels,
        datasets: [
            {
                data: chartData,
                pointStyle: "circle",
                fill: true,
                borderWidth: 4,
                pointBorderColor: "#ffffff",
                pointBackgroundColor: "#8B96E9",
                pointBorderWidth: 2,
                pointRadius: 6,
                pointHoverBorderColor: "#8B96E9",
                pointHoverBackgroundColor: "#ffffff",
                pointHoverBorderWidth: 2,
                borderColor: function (context: any) {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;

                    if (!chartArea) return;

                    return getGradient(ctx, chartArea);
                },

                backgroundColor: (context: any) => {
                    const bgColor = [
                        "rgba(139, 150, 233, 0.7)",
                        "rgba(139, 150, 233, 0.7)",
                        "rgba(139, 150, 233, 0.6)",
                        "rgba(139, 150, 233, 0.3)",
                        "rgba(139, 150, 233, 0.1)",
                        "rgba(139, 150, 233, 0.00)",
                    ];

                    if (!context?.chart?.chartArea) {
                        return;
                    }

                    const {
                        ctx,
                        chartArea: { top, bottom },
                    } = context.chart;
                    const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
                    gradientBg.addColorStop(0, bgColor[0]);
                    gradientBg.addColorStop(0.2, bgColor[1]);
                    gradientBg.addColorStop(0.4, bgColor[2]);
                    gradientBg.addColorStop(0.6, bgColor[3]);
                    gradientBg.addColorStop(0.8, bgColor[4]);
                    gradientBg.addColorStop(1, bgColor[5]);

                    return gradientBg;
                },
            },
        ],
    };

    const getGradient = (ctx: any, chartArea: any) => {
        let width, height, gradient;

        const chartWidth = chartArea.right - chartArea.left;
        const chartHeight = chartArea.bottom - chartArea.top;
        if (!gradient || width !== chartWidth || height !== chartHeight) {
            width = chartWidth;
            height = chartHeight;
            gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
            gradient.addColorStop(0, "#8B96E9");
            gradient.addColorStop(1, "#8B96E9");
        }

        return gradient;
    };

    return (
        <Flex
            className={`${props.className} ${ValuationStyle}`}
            align="start"
            justify="start"
            direction="column"
            position="relative"
            height="100%"
        >
            {isLoading ? (
                <ValutionLoader />
            ) : (
                <>
                    <Flex
                        justify="between"
                        flex={0}
                    >
                        {!props.hasPermission && (
                            <Image
                                src={IC_DASHBOARD_LOCK_CARD}
                                alt="lock"
                            />
                        )}
                    </Flex>

                    {chartData.length === 0 || !props.hasPermission ? (
                        <Flex
                            className="noData"
                            direction="column"
                            align="start"
                        >
                            {props.hasPermission && <span className="noData__title">No data yet</span>}
                            <div className="noData__background" />
                        </Flex>
                    ) : (
                        <Flex
                            justify="between"
                            align="start"
                        >
                            <div className="chartContainer">
                                <Line
                                    options={options as ChartOptions<any>}
                                    data={data}
                                />
                            </div>
                            <Flex
                                align="start"
                                direction="column"
                                className="infoContainer"
                                gap={20}
                            >
                                <Flex
                                    align="start"
                                    direction="column"
                                    justify="start"
                                    flex={0}
                                >
                                    <span className="infoContainer__title">Published cap table</span>
                                    <span className="infoContainer__info infoContainer__info--first">
                                        {formatDate(props.publishedDate)}
                                    </span>
                                </Flex>

                                <Flex
                                    align="start"
                                    direction="column"
                                    justify="start"
                                    flex={0}
                                >
                                    <span className="infoContainer__title">Post money valuation</span>
                                    <span className="infoContainer__info">{`${formatNumberWithCurrency(
                                        chartData[chartData.length - 1].moneyValuation
                                    )}`}</span>
                                </Flex>
                            </Flex>
                        </Flex>
                    )}
                </>
            )}
        </Flex>
    );
};

export default Valuation;
