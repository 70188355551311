import { optionSectionAwardTypes } from "../../../../../Shared/Config";
import { isDateValid } from "../../../../../Shared/Utilities";

const basicFields = [
    {
        name: "firstName",
        label: "First Name",
    },
    {
        name: "lastName",
        label: "Last Name",
    },
    {
        name: "employeeNumber",
        label: "Employee Number",
    },
];

const importFields: Array<{ name: string; label: string; formatValue?: (value: any) => string | Date | number | undefined }[]> = [
    basicFields,
    [
        ...basicFields,
        {
            name: "awardType",
            label: "Award Type",
            formatValue: (value: string) => {
                return optionSectionAwardTypes.find((type) => type.label.split(".")[1].toLocaleLowerCase().includes(value.toLocaleLowerCase()))?.value ?? value;
            },
        },
        {
            name: "grantDate",
            label: "Grand Date",
            formatValue: (value: string) => (isDateValid(new Date(value)) ? new Date(value) : value),
        },
        {
            name: "numberOfOptions",
            label: "Number of Options",
            formatValue: (value: string) => (isNaN(+value) ? value : +value),
        },
        {
            name: "exercisePrice",
            label: "Exercise price",
            formatValue: (value: string) => (isNaN(+value) ? value : +value),
        },
        {
            name: "vestingStartDate",
            label: "Vesting Start Date",
            formatValue: (value: string) => (isDateValid(new Date(value)) ? new Date(value) : value),
        },
        {
            name: "vestingSchedule",
            label: "Vesting Schedule",
            formatValue: (value: string) => (isDateValid(new Date(value)) ? new Date(value) : value),
        },
        {
            name: "expirationDate",
            label: "Expiration Date",
            formatValue: (value: string) => (isDateValid(new Date(value)) ? new Date(value) : value),
        },
        {
            name: "taxTrack",
            label: "Tax Track",
        },
    ],
];

export default importFields;
