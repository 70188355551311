import { Currencies } from "../enums";
import { OnboardingStatus } from './onboarding-status-enum';
import { UnderlyingAssetModelEnum } from "./underlying-enum";

export type ExpensingBasicCompany = {
	companyName: string;
	expensingMethod: ExpensingMethod;
	expensingAmortizationStartDate: AmortizationDate;
	operationalCurrency: Currencies;
	underlyingAssetModel: UnderlyingAssetModelEnum;
	onboardingStatus: OnboardingStatus;
};

export enum ExpensingMethod {
    StraightLine,
    Accelerated,
    Tranches,
}

export enum AmortizationDate {
    GrantDate,
    VestingStartDate,
}
