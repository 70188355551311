import { Typography } from "@mui/material";
import Heading from "../../../../../../../../Shared/Components/Heading/Heading";
import { findItemByExitValue, parseNumericValue } from "../../deps/helpers";
import { translations } from "../../deps/translations";
import { IBreakPoint, IEquityHolderParsed, IEquityHolderShort } from "../../deps/types";
import ExitSimulationDynamicTable from "./ExitSimulationDynamicTable";
import ValuationCardScatterChart from "./ValuationCardScatterChart";
import ValuationCardTogglableTable from "./ValuationCardTogglableTable";
import { css } from "@emotion/css";
import CircularSwitchToggle from "../../../../../../../../Shared/Components/CircularSwitchToggle/CircularSwitchToggle";
import { ReactElement, useState } from "react";
import SummaryTable from "./SummaryTable";
import { isNullOrUndefined, isNumber } from "../../../../../../../../Shared/Utilities";
import appConfig from "../../../../../../../../config/config";
import OverflowText from "../../../../../../../../Shared/Components/OverflowText";
import Tooltip from "../../../../../../../../Shared/Components/Tooltip";
import classNames from "classnames";

type ValuationCardProps = {
    exitValue?: number;
    equityholder: IEquityHolderParsed;
    isShareClass: boolean;
};

export function validateNumber(number: number | null): number | null {
    return typeof number === "number" && !isNaN(number) && number > 0 ? number : null;
}

const Style = css({
    label: "ValuationCard",
    padding: "6.8rem 14rem 4.8rem 10rem",
    display: "flex",
    flexDirection: "column",
    // maxHeight: '100%',
    "&__header": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "2rem",
        ".text-container": {
            display: "flex",
            flexDirection: "column",
            gap: "0.75rem",

            ".light-text": {
                color: " #828282",
            },
            ".exit-value": {
                fontWeight: 500,
            },
            ".company-exit-value": {
                height: "2rem",
            },
        },
    },
    ".margin-fix": {
        marginLeft: 55,
    },
    ".data-table": {
        marginBottom: "3.6rem",
        width: "calc(100% - 55px)",
    },
    ".cell-container": {
        display: "flex",
        gap: "1.2rem",
        maxWidth: "26rem",
        width: "100%",
        overflowX: "auto",
        overflowY: "hidden",
        "::-webkit-scrollbar": {
            width: 4,
            height: 4,
        },
        ".class-info": {
            position: "relative",
            color: "#828282",
            "&:not(:last-child)::after": {
                content: "''",
                position: "absolute",
                top: 2,
                right: "-0.6rem",
                height: 15,
                width: 2,
                background: appConfig.style.colors.color1,
            },
            ".class-name": {
                color: appConfig.style.colors.color1,
                fontWeight: 500,
            },
            "text-warning": {
                color: appConfig.style.colors.orange,
            },
        },
    },
});

const getSortedArray = (arr: any[] | undefined, key: string) => {
    return arr?.sort((a, b) => (a[key] > b[key] ? 1 : -1)) || [];
};

const ValuationCard = ({ equityholder, isShareClass, exitValue }: ValuationCardProps) => {
    const [isByExitValue, setIsByExitValue] = useState<boolean>(false);
    if (!exitValue) return null;
    const totalShareClassValuation = validateNumber(equityholder.totalShareClassValuation);
    const originalInvestment = validateNumber(equityholder.originalInvestment);
    const investmentMultiple = validateNumber(equityholder.investmentMultiple);

    const breakPointsData = equityholder.breakPoints
        .filter((breakpoint) => parseNumericValue(breakpoint.rangeTo))
        .map((breakpoint: IBreakPoint) => ({
            x: parseNumericValue(breakpoint.rangeTo),
            y: parseNumericValue(breakpoint.currentPayoff),
            hide: breakpoint.isEndOfRange,
        }));

    const selectedSummaryCalculation = findItemByExitValue(equityholder.shareClassesSummaryCalculations, exitValue);

    const selectedPayoffBreakdown = equityholder.payoffBreakdown && findItemByExitValue(equityholder.payoffBreakdown, exitValue);

    const exitValuePointYValue = selectedSummaryCalculation?.totalShareValue ? selectedSummaryCalculation?.totalShareValue : 0;

    const subrenderForCompanyOfExitValueX = (
        <Heading
            ap={{
                tag: "h5",
                size: "sm",
                spacingBottom: "sm",
            }}
        >
            {`For Company Exit Value of ${parseNumericValue(exitValue)} $M`}
        </Heading>
    );

    const subrenderTotalShareClassValue = (
        <div
            className="flex align-center mb--xs"
            style={{ gap: "1rem" }}
        >
            <Heading
                ap={{
                    tag: "h5",
                    size: "sm",
                    isBold: true,
                }}
            >
                {`${translations.totalShareClassValue}: `}
            </Heading>

            <Typography variant="body1">
                {totalShareClassValuation ? `${parseNumericValue(totalShareClassValuation)} $M` : null}
                {originalInvestment ? ` | Invested ${parseNumericValue(originalInvestment)} $M` : null}
                {investmentMultiple ? ` | Multiple ${parseNumericValue(investmentMultiple)}x` : null}
            </Typography>
        </div>
    );

    const subrenderShareHolders = (
        <div className="flex align-center mb--xs">
            <Heading
                style={{ paddingRight: "1rem" }}
                ap={{
                    tag: "h5",
                    size: "sm",
                    isBold: true,
                }}
            >
                {translations.shareholders}:
            </Heading>
            {equityholder.shareClassHolders?.map((shareholder: IEquityHolderShort, index: number) => (
                <div
                    className="flex"
                    style={{ marginRight: "1ch" }}
                    key={index}
                >
                    <Typography
                        component={"a"}
                        color="primary"
                        sx={{
                            paddingRight: "0.5rem",
                            cursor: "pointer",
                            fontSize: "1.2rem",
                        }}
                    >
                        {shareholder.name}
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{ fontSize: "1.2rem" }}
                    >
                        ({shareholder.value})
                    </Typography>
                </div>
            ))}
        </div>
    );

    const subrenderPreferencesSummary = (
        <div className="flex align-center mb--xs">
            <Heading
                ap={{
                    tag: "h5",
                    size: "sm",
                    isBold: true,
                }}
            >
                {translations.preferenceSummary}:{" "}
            </Heading>
            <Typography variant="body1">{equityholder.preferenceSummary ? equityholder.preferenceSummary : "--"}</Typography>
        </div>
    );

    const subrenderTotalShareHolderValue = (
        <div className="flex align-center mb--xs">
            <Heading
                style={{ paddingRight: "1rem" }}
                ap={{
                    tag: "h5",
                    size: "sm",
                    isBold: true,
                }}
            >
                {`${translations.totalShareHolderValue}: `}
            </Heading>

            <div>
                {totalShareClassValuation ? `${parseNumericValue(totalShareClassValuation)} $M` : null}
                {originalInvestment ? ` | Invested ${parseNumericValue(originalInvestment)} $M` : null}
                {investmentMultiple ? ` | Multiple ${parseNumericValue(investmentMultiple)}x` : null}
            </div>
        </div>
    );

    const subrenderPayoffBreakdown = (
        <div className="flex align-center mb--xs">
            <Heading
                style={{ paddingRight: "1rem" }}
                ap={{
                    tag: "h5",
                    size: "sm",
                    isBold: true,
                }}
            >
                {translations.payoffBreakdown}
            </Heading>

            {equityholder.payoffBreakdown
                ? selectedPayoffBreakdown?.payoffByClass.map((item) => (
                      <div
                          className="flex align-center"
                          key={item.name}
                      >
                          <Typography
                              component={"a"}
                              color="primary"
                              sx={{
                                  paddingRight: "0.5rem",
                                  cursor: "pointer",
                                  fontSize: "1.2rem",
                              }}
                          >
                              {item.name}
                          </Typography>
                          <Typography
                              variant="body1"
                              sx={{ fontSize: "1.2rem" }}
                          >
                              {`$(${parseNumericValue(item.totalShareValue)}M,
								${item.investmentMultiple ? `${parseNumericValue(item.investmentMultiple)}x` : "--"})`}
                          </Typography>
                      </div>
                  ))
                : null}
        </div>
    );

    const sortedClasses = getSortedArray(selectedPayoffBreakdown?.payoffByClass, "name");
    const sortedShareClassHolders = getSortedArray(equityholder.shareClassHolders, "name");

    const summaryTableData: (string | ReactElement)[][] = [
        ["", "Payoff ($M)", "Original Investment ($M)", "Investment multiple"],
        [
            "Total",
            parseNumericValue(equityholder.totalShareClassValuation)?.toString() ?? "0",
            parseNumericValue(equityholder.originalInvestment)?.toString() ?? "0",
            isNumber(equityholder.investmentMultiple) ? parseNumericValue(equityholder.investmentMultiple)?.toString() + "x" : "--",
        ],
        [
            "Breakdown",
            // getSortedArray(selectedPayoffBreakdown?.payoffByClass, "name").map(i =>
            //     `${i.name} (${parseNumericValue(i.totalShareValue)?.toString()}`
            // ).join(' | '),
            // getSortedArray(equityholder.shareClassHolders, "name").map(i => `${i.name} (${parseNumericValue(i.value)?.toString() || 0})`).join(" | "),
            // getSortedArray(selectedPayoffBreakdown?.payoffByClass, "name").map(i => {
            //     return `${i.name} (${isNullOrUndefined(i.investmentMultiple) ? "--" : parseNumericValue(i.investmentMultiple)})`
            // }).join(' | ')
            <Tooltip title={sortedClasses.map((i) => `${i.name} (${parseNumericValue(i.totalShareValue)?.toString()})`).join(" | ")}>
                <div className="cell-container">
                    {sortedClasses.map((i) => (
                        <div
                            className="class-info"
                            key={i.name}
                        >
                            <span className="class-name">{i.name}</span> ({parseNumericValue(i.totalShareValue)?.toString()})
                        </div>
                    ))}
                </div>
            </Tooltip>,
            <Tooltip title={sortedShareClassHolders.map((i) => `${i.name} (${parseNumericValue(i.value)?.toString()}%)`).join(" | ")}>
                <div className="cell-container">
                    {sortedShareClassHolders.map((i) => (
                        <div
                            key={i.name}
                            className="class-info"
                        >
                            <span className="class-name">{i.name}</span> ({parseNumericValue(i.value)?.toString()}%)
                        </div>
                    ))}
                </div>
            </Tooltip>,
            "",
            // <Tooltip title={sortedClasses.map(i => `${i.name} (${isNullOrUndefined(i.investmentMultiple) ? "--" : parseNumericValue(i.investmentMultiple)})`).join(" | ")}>
            //     <div className="cell-container">
            //         {
            //             sortedClasses.map(i =>
            //                 <div key={i.name} className="class-info">
            //                     <span className="class-name">{i.name}</span> ({isNullOrUndefined(i.investmentMultiple) ? "--" : <span className={classNames({ "text-warning": (i.investmentMultiple ?? 99) < 1 })}>{parseNumericValue(i.investmentMultiple)}</span>})
            //                 </div>
            //             )
            //         }
            //     </div>
            // </Tooltip>
        ],
    ];

    return (
        <div className={Style}>
            <div className={`${Style}__header margin-fix`}>
                <div className="text-container">
                    <div className="bold">Valuation Summary - {equityholder.name}</div>
                    <div className="company-exit-value">
                        {!isByExitValue && (
                            <>
                                <span className="light-text">For company exit value: </span>
                                <span className="exit-value">{exitValue}M</span>
                            </>
                        )}
                    </div>
                </div>
                <CircularSwitchToggle
                    value={isByExitValue}
                    actions={[
                        { value: false, label: "Summary" },
                        { value: true, label: "By exit range" },
                    ]}
                    onChange={(val) => setIsByExitValue(val)}
                />
            </div>
            <div className="data-table margin-fix">
                {isByExitValue ? <ExitSimulationDynamicTable data={equityholder} /> : <SummaryTable data={summaryTableData} />}
            </div>
            {/* <Heading
                ap={{
                    tag: "h3",
                    size: "lg",
                    spacingBottom: "lg",
                    isBold: true,
                }}
            >
                {`${isShareClass ? translations.valuationOfShareClass : translations.valuationOfShareHolder}: ${equityholder.name}`}
            </Heading>

            {isShareClass ? (
                <div className='mb--sm'>
                    {subrenderForCompanyOfExitValueX}

                    {subrenderTotalShareClassValue}

                    {subrenderPreferencesSummary}
                </div>
            ) : (
                <div className='mb--sm'>
                    {subrenderForCompanyOfExitValueX}

                    {subrenderTotalShareHolderValue}

                    {subrenderShareHolders}

                    {subrenderPayoffBreakdown}
                </div>
            )} */}
            {exitValue && (
                <div className="scatter-chart">
                    <ValuationCardScatterChart
                        yAxisLabel={translations.companyPayoffInMillions}
                        xAxisLabel={translations.companyExitValueInMillions}
                        data={breakPointsData as any}
                        exitValuePoint={{ x: exitValue, y: exitValuePointYValue }}
                    />
                </div>
            )}

            {equityholder && (
                <div className="margin-fix">
                    <ValuationCardTogglableTable
                        isShareClass={isShareClass}
                        data={equityholder.breakPoints}
                    />
                </div>
            )}
        </div>
    );
};

export default ValuationCard;
