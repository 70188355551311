import { css } from "@emotion/css";
import classNames from "classnames";
import appConfig from "../../../config/config";

const Style = css({
	label: 'IndicationStatus',
	'&.pending': {
		border: 'solid 2px transparent',
		backgroundImage: 'linear-gradient(white, white), linear-gradient(90deg, #833ab4, #fd1d1d, #fcb045)',
		backgroundOrigin: 'border-box',
		backgroundClip: 'content-box, border-box',
		borderRadius: 16,
	},
	'&__label': {
		background: '#F5F5F5',
		padding: '0.5rem 1.5rem',
		borderRadius: 16,
		color: appConfig.style.colors.color4,
		fontWeight: 500,
		display: 'flex',
		alignItems: 'center',
		gap: '1rem',
		height: '3.3rem',
		'.circle': {
			height: '1rem',
			width: '1rem',
			background: appConfig.style.colors.color4,
			borderRadius: '50%',
		},
		'.text': {
			paddingTop: 2,
			fontSize: '1.3rem',
		},
		'&.completed': {
			color: appConfig.style.colors.color1,
			'.circle': {
				background: appConfig.style.colors.color1,
			},
		},
		'&.pending': {
			height: '2.879rem',
			background: '#ffffff',
			color: appConfig.style.colors.text4,
			'.circle': {
				background: appConfig.style.colors.text4,
			},
		},
		'&.cancelled': {
			color: appConfig.style.colors.cancel,
			'.circle': {
				background: appConfig.style.colors.cancel,
			},
		},
	},
});

type Props = {
	isPending?: boolean;
	isCancelled?: boolean;
	completeText?: string;
	pendingText?: string;
	total: number;
	completed: number;
};

const IndicationStatus = ({ completed, total, completeText = 'Completed', isPending, pendingText = 'Pending', isCancelled }: Props) => {
	const isCompleted = completed === total;
	const isSurelyPending = !isCompleted && isPending && !isCancelled;

	return (
		<div className={classNames(Style, { pending: isSurelyPending })}>
			<span className={classNames(`${Style}__label`, { completed: isCompleted, pending: isSurelyPending, cancelled: isCancelled })}>
				<div className="circle"></div>
				<div className="text">
					{isCancelled ? 'Cancelled' : isSurelyPending ? pendingText : isCompleted ? completeText : `${completed} of ${total}`}
				</div>
			</span>
		</div>
	);
};

export default IndicationStatus;
