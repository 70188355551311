import { useState } from "react";
import Title from "../../../../../Shared/Components/Layout/Title";
import TextInput from "../../../../../Shared/Components/Input/TextInput";
import Flex from "../../../../../Shared/Components/Layout/Flex";
import Button from "../../../../../Shared/Components/Button/Button";
import { css } from "@emotion/css";
import { ModalBodyProps } from "../../../../../Shared/Components/Modal/types";
import CircularSwitchToggle from "../../../../../Shared/Components/CircularSwitchToggle/CircularSwitchToggle";
import Label from "../../../../../Shared/Components/Layout/Label";

const NewDraftStyle = css({
    label: "NewDraft",
    padding: "5rem 7rem",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    "&__main-title": {
        marginBottom: "3rem",
    },
    "&__actions": {
        "> button": {
            alignSelf: "flex-end",
        },
    },
    "&__container": {
        marginTop: "2rem",
    },
});

interface Props extends ModalBodyProps {
    onSave: (name: string, isFunding?: boolean) => Promise<void>;
    isDuplicate?: boolean;
    isFunding?: boolean;
}

const PublishCapTable = ({ removeModal, onSave, isDuplicate, isFunding }: Props) => {
    const [name, setName] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSaveToFunding, setIsSaveToFunding] = useState<boolean>(true);

    const onClickHandler = async () => {
        if (!name) return;
        setIsLoading(true);
        await onSave(name, isSaveToFunding);
        setIsLoading(false);
        removeModal?.();
    };

    return (
        <div className={NewDraftStyle}>
            <Title className={`${NewDraftStyle}__main-title`}>{isDuplicate ? "Save as new draft" : isFunding ? "Save new copy" : "Create new draft"}</Title>
            <TextInput
                qaid="PublishCapTable.Input.Name"
                value={name}
                onChange={(name) => setName(name)}
                label="Name"
                required
                onEnter={(name) => name && onClickHandler()}
            />
            {isFunding && (
                <div className={`${NewDraftStyle}__container`}>
                    <Label>Save to:</Label>
                    <CircularSwitchToggle
                        value={isSaveToFunding}
                        actions={[
                            { value: false, label: "Cap Table" },
                            { value: true, label: "Funding" },
                        ]}
                        onChange={(value) => setIsSaveToFunding(value)}
                        className="type-selection"
                        disabled={true || isLoading} // According to 2969, disable selection
                    />
                </div>
            )}
            <Flex align="center" justify="center" gap="2rem" className={`${NewDraftStyle}__actions`}>
                <Button
                    disabled={!name}
                    qaid="CreateNewDraft.Button.Save"
                    onClick={onClickHandler}
                    label={isDuplicate ? "Save" : "Create"}
                    isLoading={isLoading}
                />
            </Flex>
        </div>
    );
};

export default PublishCapTable;
