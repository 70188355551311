import { computed, makeAutoObservable, observable, reaction, toJS } from 'mobx';
import { clearPersistedStore, isHydrated, makePersistable } from 'mobx-persist-store';
import { RootStore } from './RootStore';
import { ExpensingService } from '../Services/ExpensingService';
import { ExpenseReport, ExpenseReportStatus } from '../Models/API/Expensing/expense-report';
import { getCurrency, isNullOrUndefined } from '../Shared/Utilities';
import { ExpensingBasicCompany } from '../Models/API/Expensing/options-migration';
import { Peer } from '../Models/API/Expensing/peer';
import { AddPeerRequest } from '../Models/API/Expensing/add-peer-request';
import { UpdatePeerRequest } from '../Models/API/Expensing/update-peer-request';
import { ExpensingCompany } from '../Models/API/Expensing/company';
import { ExpensingDashboardData } from '../Models/API/Expensing/dashboard-data';
import { HighLevelErrorResponse, HigherLevelResponse } from '../Services/Axios';
import { EditableUnderlyingAssetPrice } from '../Models/App/Expensing/editable-underlying-asset-price';
import { Currency, currencies } from '../Shared/Config';
import { Currencies } from '../Models/API/enums';
import { ReportSettings } from '../Models/App/Expensing/settings';
import { EditableGrantReport } from '../Models/App/Expensing/editable-grant-report';
import { OptionsPricing } from '../Models/API/Expensing/options-pricing';
import { GrantReportPayload } from '../Models/API/Expensing/grant-report';
import { NumberOption } from '../Models/API/All/NumberOption';
import { GrantForfeitureRatePayload } from '../Models/API/Expensing/grant-forfeiture-rate-payload';
import { GrantInfo } from '../Models/API/Expensing/grant-info';
import { convertExpenseReport } from '../Screens/Expensing/helpers/mappers';
import { ServerResponseApi } from '../Models/API/All/server-response';
import { OnboardingStatus } from '../Models/API/Expensing/onboarding-status-enum';
import { Grant } from '../Models/API/Expensing/grant';
import EventHandler from '../Shared/Middleware/EventHandler';

type CompanyPromiseCallback = Promise<HigherLevelResponse<ServerResponseApi<ExpensingCompany>> | HighLevelErrorResponse>;

export class ExpensingStore {
	expensingService: ExpensingService = new ExpensingService(0);
	expenseReports: ExpenseReport[] = [];
	// expenseReportsOptions: NumberOption[] = [];

	@observable
	selectedReportId: number | undefined = undefined;

	companyData: ExpensingCompany | undefined = undefined;
	dashboardData: ExpensingDashboardData | undefined = undefined;
	newReportData: { settings: ReportSettings; report: EditableGrantReport } | undefined = undefined;
	reportData: { settings: ReportSettings; report: EditableGrantReport } | undefined = undefined;
	expenseGrantsReport: GrantInfo[] | undefined;
	companyGrants: Grant[] | undefined = undefined;
	reportCurrency: Currency | undefined = undefined;

	onReportChange = new EventHandler<ExpensingStore, ExpenseReport>();

	constructor(private rootStore: RootStore) {
		makeAutoObservable(this);
		makePersistable(this, {
			name: 'ExpensingStore',
			properties: [
				// "expenseReportsOptions",
				'companyData',
				'selectedReportId',
				'expenseReports',
				'dashboardData',
				'newReportData',
				'reportData',
			],
			storage: window.sessionStorage,
			expireIn: 10800000, // 3 hours
		});
		rootStore.companyStore.onCompanyChange.subscribe(() => {
			if (!this.rootStore.companyStore.companyId) return;
			this.expensingService = new ExpensingService(rootStore.companyStore.companyId);
			this.resetStoreToDefaultValues();
		});
		reaction(
			() => this.selectedReport as ExpenseReport,
			(report: ExpenseReport) => {
				// Daniel: to fix later
				// if (report.expenseCompanyReportId === this.selectedReport?.expenseCompanyReportId) return;
				// this.expenseGrantsReport = undefined;
				// this.reportCurrency = undefined;
				// this.newReportData = undefined;
				// this.reportData = undefined;
			}
		);
	}

	resetStoreToDefaultValues() {
		this.companyData = undefined;
		this.expenseReports = [];
		this.selectedReportId = undefined;
		this.dashboardData = undefined;
		// this.expenseReportsOptions = [];
		this.newReportData = undefined;
		this.expenseGrantsReport = undefined;
		this.reportData = undefined;
		clearPersistedStore(this);
	}

	// get selectedReportId() {
	//     return this.selectedId;
	// }

	// set selectedReportId(value) {
	//     this.selectedId = value;
	// }

	get company(): ExpensingCompany | undefined {
		return this.companyData;
	}

	set company(data: ExpensingCompany | undefined) {
		this.companyData = data;
	}

	get selectedReport() {
		return this.expenseReports.find((rep) => rep.expenseCompanyReportId === this.selectedReportId);
	}

	get companyCurrency() {
		return currencies.find((currency) => currency.currencyId === (this.company?.operationalCurrency ?? Currencies.USD));
	}

	setSelectReportId(id: number) {
		this.selectedReportId = id;
	}

	// Expensing

	getExpenseReports = async () => {
		const res = await this.expensingService.getExpenseReports();
		if (res.isSuccess) {
			this.expenseReports = res.data.data;
			// this.expenseReportsOptions = res.data.data.map((report) => ({
			//     value: report.expenseCompanyReportId,
			//     label:
			//         report.status === ExpenseReportStatus.Publish
			//             ? `${report.expenseCompanyReportName} <span className="published">(Published)</span>`
			//             : report.expenseCompanyReportName,
			// }));

			if (isNullOrUndefined(this.selectedReportId) && res.data.data.length) {
				this.selectedReportId = res.data.data[0].expenseCompanyReportId;
			}
		}

		return res;
	};

	createReport = async (data: GrantReportPayload) => {
		const res = await this.expensingService.createReport(data);
		if (res.isSuccess) {
			await this.getExpenseReports();
			// this.expenseReportsOptions.push({
			//     value: res.data.data.expenseCompanyReportId,
			//     label: data.expenseCompanyReportName,
			// });
			// this.expenseReports.push(res.data.data.expensingGrantResultDtos)
			this.selectedReportId = res.data.data.expenseCompanyReportId;
		}
		return res;
	};

	updateReport = async (data: GrantReportPayload | null = null, reportId: number = this.selectedReportId ?? 0) => {
		const res = await this.expensingService.updateReport(data, reportId);
		if (res.isSuccess) {
			await this.getExpenseReports();
			this.selectedReportId = res.data.data.expenseCompanyReportId;
		}
		return res;
	};

	getExportToExcel = async () => {
		if (isNullOrUndefined(this.selectedReportId)) return;

		return this.expensingService.getExportToExcel(this.selectedReportId);
	};

	getDashboardData = async () => {
		if (isNullOrUndefined(this.selectedReportId)) return;

		const res = await this.expensingService.getDashboardData(this.selectedReportId);
		this.dashboardData = res.data?.data;
		return res;
	};

	getGrantsReport = async () => {
		if (isNullOrUndefined(this.selectedReportId)) return (this.expenseGrantsReport = []);

		const res = await this.expensingService.getGrantsReport(this.selectedReportId);
		if (!res.isSuccess) return;

		this.reportCurrency = getCurrency(res.data.data.reportCurrency);
		this.expenseGrantsReport = res.data.data.expensingGrantResultDtos;

		if (this.selectedReport) {
			this.reportData = {
				report: { ...convertExpenseReport(this.selectedReport), assetPrice: res.data.data.assetPrice },
				settings: {
					forfeitureRate: res.data.data.expensingGrantResultDtos[0]?.forfeitureRate * 100,
				},
			};
		}
	};

	getGrantDetails = (grantId: number) => {
		if (isNullOrUndefined(this.selectedReportId)) return;

		return this.expensingService.getGrantDetails(this.selectedReportId, grantId);
	};

	deleteReport = async (reportId = this.selectedReportId) => {
		if (isNullOrUndefined(reportId)) return;

		const res = await this.expensingService.deleteReport(reportId);
		if (res.data?.data.expenseCompanyReportId) {
			this.expenseReports = this.expenseReports.filter((report) => report.expenseCompanyReportId !== res.data.data.expenseCompanyReportId);
			// this.expenseReportsOptions = this.expenseReportsOptions.filter(
			//     (option) => option.value !== res.data.data.expenseCompanyReportId
			// );
			this.selectedReportId = this.expenseReports[0].expenseCompanyReportId;
		}
		return res;
	};

	updateReportStatus = async (status: ExpenseReportStatus, reportId = this.selectedReportId) => {
		if (isNullOrUndefined(reportId)) return;

		const res = await this.expensingService.updateReportStatus(status, reportId);
		const updatedReportId = res.data?.data.expenseCompanyReportId;
		if (updatedReportId) {
			const idx = this.expenseReports.findIndex((report) => report.expenseCompanyReportId === updatedReportId);
			this.expenseReports = [...this.expenseReports.slice(0, idx), res.data!.data, ...this.expenseReports.slice(idx + 1)];
			await this.getDashboardData();
		}
	};

	getDisclosureReport = (reportId = this.selectedReportId) => {
		if (isNullOrUndefined(reportId)) return;

		return this.expensingService.getDisclosureReport(reportId);
	};

	// Options Migration

	createCompany = async (company: ExpensingBasicCompany) => {
		return this.companyHandler(() => this.expensingService.createCompany(company));
	};

	updateCompany = async (company: Partial<ExpensingBasicCompany>) => {
		return this.companyHandler(() => this.expensingService.updateCompany({ ...this.company, ...company }));
	};

	getCompany = async () => {
		return this.companyHandler(this.expensingService.getCompany);
	};

	setCompanyOnboardingStatus = async (onboardingStatus: OnboardingStatus) => {
		// if (this.company && onboardingStatus < this.company.onboardingStatus) return true;
		const res = await this.updateCompany({ onboardingStatus });
		return res.data?.data.onboardingStatus === onboardingStatus;
	};

	private companyHandler = async (fn: () => CompanyPromiseCallback): CompanyPromiseCallback => {
		const res = await fn();
		if (res.data) {
			this.company = res.data.data;
		}
		return res;
	};

	getCompanyGrants = async (reportEndDate: Date) => {
		const res = await this.expensingService.getCompanyGrants(reportEndDate);
		if (res.data?.data) {
			this.companyGrants = res.data.data;
		}
		return res;
	};

	updateGrantsForfeitureRate = (payload: GrantForfeitureRatePayload[]) => {
		return this.expensingService.updateGrantsForfeitureRate(payload);
	};

	// Peers

	getAvailablePeers = () => {
		return this.expensingService.getAvailablePeers();
	};

	getCompanyPeers = () => {
		return this.expensingService.getCompanyPeers();
	};

	addCompanyPeer = (peer: AddPeerRequest) => {
		return this.expensingService.addCompanyPeer(peer);
	};

	updateCompanyPeer = (data: UpdatePeerRequest) => {
		return this.expensingService.updateCompanyPeer(data);
	};

	// Underlying asset

	addAssetPrice = (data: EditableUnderlyingAssetPrice) => {
		return this.expensingService.addAssetPrice(data);
	};

	updateAssetPrice = (data: EditableUnderlyingAssetPrice) => {
		if (isNullOrUndefined(data.underlyingAssetPriceId)) return;
		return this.expensingService.updateAssetPrice(data);
	};

	getAssetPrices = () => {
		return this.expensingService.getAssetPrices();
	};

	deleteAssetPrice = (assetId: number) => {
		return this.expensingService.deleteAssetPrice(assetId);
	};

	getAssetPriceByGrant = () => {};

	// OptionsPricing

	runBlackAndScholes = (data: OptionsPricing[]) => {
		return this.expensingService.runBlackAndScholes(data);
	};

	getPricingGrants = () => {
		return this.expensingService.getPricingGrants();
	};
}
