import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { GrantTable } from "../../../../Models/App/EquityPlans/Grants";
// import { IGrantEmployees } from "../../../../Models/App/Options/GrantModel";
import Button from "../../../../Shared/Components/Button/Button";
import Table, { TableColumn } from "../../../../Shared/Components/Table/Table";
import useModal from "../../../../Shared/Hooks/useModal";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import { formatDate } from "../../../../Shared/Utilities";
import SectionStyles from "../../General/styles/SectionStyles";
import useEsopModals from "../../useEsopModals";

interface GrantsProps {
    rtd: () => void;
}

interface BenTable {
    fullName: { firstName: string; lastName: string };
    outstanding: number;
    grantDate: Date;
    grantType: number;
    grantNumber: string;
    granted: number;
    planName: string;
    vested: number;
    unvested: number;
    grantId: number;
    beneficiaryId: number;
    // empNumber: string;
}

const Grants = (props: GrantsProps) => {
    // const [modalGrant, setModalGrant] = useState<BenTable>();

    const { equityPlansStore } = useRootStore();
    // const { openGrntCard, addGrantHandler } = useEsopModals();
    const { openGrntCard } = useEsopModals();
    const { showModal } = useModal();

    const getDataTable = async () => {
        const res = await equityPlansStore.GetGrantsForCompany();
        // console.log("Grants Comp - DataTable", res);
    };

    useEffect(() => {
        getDataTable();
    }, []);

    useEffect(() => {
        getDataTable();
    }, []);

    // fullname: concat(fullName.firstName, fullName.lastName),

    const tableColumns: TableColumn<GrantTable>[] = [
        // {
        // 	name: "grantId",
        // 	label: "ID",
        // },
        {
            name: "grantNumber",
            label: "Grant #",
            sortable: true,
            // onCellClick: (value) => console.log(value),
        },
        {
            name: "fullName",
            label: "full name",
            format: (val: { firstName: string; lastName: string }) => `${val?.firstName}  ${val?.lastName}`,
            sortable: false,
            style: { flex: 1.5 },
            // onCellClick: (value) => console.log(value),
        },
        {
            name: "grantDate",
            label: "Grant Date",
            format: (val: any) => formatDate(val),
            sortable: true,
            // onCellClick: (value) => console.log(value),
        },
        {
            name: "granted",
            label: "Granted",
            sortable: true,
            // onCellClick: (value) => console.log(value),
        },
        {
            name: "vested",
            label: "Vested",
            sortable: true,
            // onCellClick: (value) => console.log(value),
        },
        {
            name: "unvested",
            label: "Unvested",
            sortable: true,
            // onCellClick: (value) => console.log(value),
        },
        {
            name: "outstanding",
            label: "Outstanding",
            sortable: true,
            // onCellClick: (value) => console.log(value),
        },
    ];

    const getModalData = () => {
        // Get grant Data
        // Get orders Data
        // Get cancelations Data
    };

    // TODO: clear on close or reOpen ?

    const clearModalGrantData = () => {
        // clear relevant data from store
    };

    return (
        <div className={SectionStyles}>
            <div className="container-fluid">
                <div className="section-header">
                    <h1>Grants</h1>
                    <Button qaid="" onClick={props.rtd}>
                        Go back
                    </Button>
                </div>
                <div className="section-table">
                    {/* <Button qaid={""} onClick={handleModal}>Open Modal</Button> */}
                    <div className="table-actions">
                        {/* <Button label="Add New Grant" qaid="Grants.Button.AddNewGrant" inverse onClick={() => addGrantHandler(true)} /> */}
                    </div>
                    <Table
                        columns={tableColumns}
                        rows={equityPlansStore.companyGrants}
                        color="secondary"
                        scrollAfterRows={12}
                        fullscreen
                        onRowClick={(row) => {
                            // console.log("rowClicked", toJS(row));
                            openGrntCard(row.grantId, row.beneficiaryId);
                        }}
                        onRowSelect={(row) => {
                            // setInvitationArr(invi)
                            // console.log(toJs(row))
                        }}
                        // actions={[
                        // 	{
                        // 		label: "Repricing",
                        // 		qaid: "Grants.Button.Repricing",
                        // 	},
                        // 	{
                        // 		label: "Delete",
                        // 		qaid: "Grants.Button.Delete",
                        // 	},
                        // ]}
                        searchBy={["fullName", "grantDate", "grantNumber", "grantType", "outstanding"]}
                        exportToExcel={{ fileName: "Grants", sheetName: "Grants" }}
                        filterBy={tableColumns.filter((col) => col.name !== "actions").map((col) => col.name as keyof GrantTable)}
                    />
                </div>
            </div>
        </div>
    );
};
export default observer(Grants);
