import { PaymentCurrencyEnum, PaymentPlanEnum } from '../API/enums';

export interface PaymentIntentModel {
	subscriptionId: string;
	uniq: string;
}

export interface IPaymentPlans {
	usd: IPaymenPlan[];
	eur: IPaymenPlan[];
}

export interface IPaymenPlan {
	name: string;
	planId: number;
	price: number;
	code: PaymentPlanEnum;
	currency: PaymentCurrencyEnum;
	maxNumOfStakeholders: number;
	capTableManagement: boolean;
	optionsManagement: boolean;
	fundingManagement: boolean;
	expensingManagement: boolean;
	numberOfRooms: number;
	maxSizeOfFiles: number;
	employeeInterface: boolean;
	capTableDrafts: number;
	equityReports: number;
	waterfall: boolean;
	valuationReport: boolean;
	documents: boolean;

	isDefault?: boolean;
	buttonStatus?: string;
}

export interface IPlanUsage {
	stakeholders: {
		current: number;
		max: number;
	};
	rooms: {
		current: number;
		max: number;
	};
	files: {
		current: number;
		max: number;
	};
}

export interface ICustomerDetails {
	customerDetails: IBillingInfo;
	paymentMhetod?: PaymentMethod;
	invoice?: {
		invoicePdf: string;
		nextBillingDate: Date;
	};
}

export interface PaymentMethod {
	cardNumber: string;
	expMonth: number;
	expYear: number;
	cardHolderName: string;
}

export interface ICustomerSubscribe {
	uniq: string;
	subscriptionId: string;
}

export interface IMessageModel {
	show: boolean;
	closeButton?: boolean;
	title?: string;
	messageType?: string;
	message?: string;
	subMessage?: string;
}

// export interface IBillingContact {
// 	companyName?: string;
// 	companyId?: number;
// 	firstName?: string;
// 	lastName?: string;
// 	email?: string;
// 	phone?: string;
// }

export interface ICardHolderInfo {
	name?: string;
}

export interface IInvoice {
	invoicePdf: string;
	hostedInvoiceUrl: string;
	nextBillingDate: Date | null;
	dueDate: Date | null;
}

export interface IDashboardData {
	readonly stakeholders: number;
	readonly beneficiaries: number;
	readonly totalUnique: number;
}

export interface IBillingInfo {
	companyName: string;
	firstName?: string;
	lastName?: string;
	email?: string;
	phone?: string;
	firstAddressLine?: string;
	secondAddressLine?: string;
	city?: string;
	zipCode?: string;
	country?: string | number;
	state?: string;
	recaptchaToken?: string;
}

export interface ICardInfo {
	readonly cardHolderName?: string;
	readonly cardNumber: string;
	readonly country?: string;
	readonly postalCode?: string;
}

export interface ICheckout {
	readonly email?: string;
	readonly companyName: string;
	readonly isPaymentPrivacyPolicy?: boolean;
	readonly firstAddressLine?: string;
	readonly secondAddressLine?: string;
	readonly city?: string;
	readonly zipCode?: string;
	readonly country?: string;
	readonly state?: string;
}

export type PlanLimitationState = {
	show: boolean;
	limitCode: LimitCodesEnum | null;
};

export enum LimitCodesEnum {
	General,
	Stakeholders,
}

export enum AddOnsEnum {
	GrowthStakeholders,
	ScaleStakeholders,
	EnterpriseStakeholders,
	ValuationReport,
	Custom,
}

export type AddOnPlan = {
	addonId: number;
	addonType: AddOnsEnum;
	price: number;
};

export type AddOnPlanReponse = AddOnPlan & {
	quantity: number;
	base: number;
};

export type PreviewInvoice = {
	invoicePdf: string;
	hostedInvoiceUrl: string;
	nextBillingDate: Date;
	dueDate: Date;
	summary: {
		description: string;
		amount: number;
	}[];
};
