import { css } from "@emotion/css";
import Image from "../../../Shared/Components/Image";
import { IC_CONTACT_US } from "../../../Assets";

const Style = css({
	label: "ContactUs",
	padding: "7rem",
	paddingTop: "5rem",
});

const ContactUs = () => {
	return (
		<div className={Style}>
			<div className={`${Style}__header`}>
				<Image src={IC_CONTACT_US} alt="contact us" />
				<span>Contact us</span>
			</div>
		</div>
	);
};

export default ContactUs;
