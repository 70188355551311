export const industries = [
	{ value: 1, label: "Accounting" },
	{ value: 2, label: "Airlines/Aviation" },
	{ value: 3, label: "Alternative Dispute Resolution" },
	{ value: 4, label: "Alternative Medicine" },
	{ value: 5, label: "Animation" },
	{ value: 6, label: "Apparel & Fashion" },
	{ value: 7, label: "Architecture & Planning" },
	{ value: 8, label: "Arts & Crafts" },
	{ value: 9, label: "Automotive" },
	{ value: 10, label: "Aviation & Aerospace" },
	{ value: 11, label: "Banking" },
	{ value: 12, label: "Biotechnology" },
	{ value: 13, label: "Broadcast Media" },
	{ value: 14, label: "Building Materials" },
	{ value: 15, label: "Business Supplies & Equipment" },
	{ value: 16, label: "Capital Markets" },
	{ value: 17, label: "Chemicals" },
	{ value: 18, label: "Civic & Social Organization" },
	{ value: 19, label: "Civil Engineering" },
	{ value: 20, label: "Commercial Real Estate" },
	{ value: 21, label: "Computer & Network Security" },
	{ value: 22, label: "Computer Games" },
	{ value: 23, label: "Computer Hardware" },
	{ value: 24, label: "Computer Networking" },
	{ value: 25, label: "Computer Software" },
	{ value: 26, label: "Construction" },
	{ value: 27, label: "Consumer Electronics" },
	{ value: 28, label: "Consumer Goods" },
	{ value: 29, label: "Consumer Services" },
	{ value: 30, label: "Cosmetics" },
	{ value: 31, label: "Dairy" },
	{ value: 32, label: "Defense & Space" },
	{ value: 33, label: "Design" },
	{ value: 34, label: "Education Management" },
	{ value: 35, label: "E-learning" },
	{ value: 36, label: "Electrical & Electronic Manufacturing" },
	{ value: 37, label: "Entertainment" },
	{ value: 38, label: "Environmental Services" },
	{ value: 39, label: "Events Services" },
	{ value: 40, label: "Executive Office" },
	{ value: 41, label: "Facilities Services" },
	{ value: 42, label: "Farming" },
	{ value: 43, label: "Financial Services" },
	{ value: 44, label: "Fine Art" },
	{ value: 45, label: "Fishery" },
	{ value: 46, label: "Food & Beverages" },
	{ value: 47, label: "Food Production" },
	{ value: 48, label: "Fundraising" },
	{ value: 49, label: "Furniture" },
	{ value: 50, label: "Gambling & Casinos" },
	{ value: 51, label: "Glass, Ceramics & Concrete" },
	{ value: 52, label: "Government Administration" },
	{ value: 53, label: "Government Relations" },
	{ value: 54, label: "Graphic Design" },
	{ value: 55, label: "Health, Wellness & Fitness" },
	{ value: 56, label: "Higher Education" },
	{ value: 57, label: "Hospital & Health Care" },
	{ value: 58, label: "Hospitality" },
	{ value: 59, label: "Human Resources" },
	{ value: 60, label: "Import & Export" },
	{ value: 61, label: "Individual & Family Services" },
	{ value: 62, label: "Industrial Automation" },
	{ value: 63, label: "Information Services" },
	{ value: 64, label: "Information Technology & Services" },
	{ value: 65, label: "Insurance" },
	{ value: 66, label: "International Affairs" },
	{ value: 67, label: "International Trade & Development" },
	{ value: 68, label: "Internet" },
	{ value: 69, label: "Investment Banking/Venture" },
	{ value: 70, label: "Investment Management" },
	{ value: 71, label: "Judiciary" },
	{ value: 72, label: "Law Enforcement" },
	{ value: 73, label: "Law Practice" },
	{ value: 74, label: "Legal Services" },
	{ value: 75, label: "Legislative Office" },
	{ value: 76, label: "Leisure & Travel" },
	{ value: 77, label: "Libraries" },
	{ value: 78, label: "Logistics & Supply Chain" },
	{ value: 79, label: "Luxury Goods & Jewelry" },
	{ value: 80, label: "Machinery" },
	{ value: 81, label: "Management Consulting" },
	{ value: 82, label: "Maritime" },
	{ value: 83, label: "Marketing & Advertising" },
	{ value: 84, label: "Market Research" },
	{ value: 85, label: "Mechanical or Industrial Engineering" },
	{ value: 86, label: "Media Production" },
	{ value: 87, label: "Medical Device" },
	{ value: 88, label: "Medical Practice" },
	{ value: 89, label: "Mental Health Care" },
	{ value: 90, label: "Military" },
	{ value: 91, label: "Mining & Metals" },
	{ value: 92, label: "Motion Pictures & Film" },
	{ value: 93, label: "Museums & Institutions" },
	{ value: 94, label: "Music" },
	{ value: 95, label: "Nanotechnology" },
	{ value: 96, label: "Newspapers" },
	{ value: 97, label: "Nonprofit Organization Management" },
	{ value: 98, label: "Oil & Energy" },
	{ value: 99, label: "Online Publishing" },
	{ value: 100, label: "Outsourcing/Offshoring" },
	{ value: 101, label: "Package/Freight Delivery" },
	{ value: 102, label: "Packaging & Containers" },
	{ value: 103, label: "Paper & Forest Products" },
	{ value: 104, label: "Performing Arts" },
	{ value: 105, label: "Pharmaceuticals" },
	{ value: 106, label: "Philanthropy" },
	{ value: 107, label: "Photography" },
	{ value: 108, label: "Plastics" },
	{ value: 109, label: "Political Organization" },
	{ value: 110, label: "Primary/Secondary" },
	{ value: 111, label: "Printing" },
	{ value: 112, label: "Professional Training" },
	{ value: 113, label: "Program Development" },
	{ value: 114, label: "Public Policy" },
	{ value: 115, label: "Public Relations" },
	{ value: 116, label: "Public Safety" },
	{ value: 117, label: "Publishing" },
	{ value: 118, label: "Railroad Manufacture" },
	{ value: 119, label: "Ranching" },
	{ value: 120, label: "Real Estate" },
	{ value: 121, label: "Recreational" },
	{ value: 122, label: "Facilities & Services" },
	{ value: 123, label: "Religious Institutions" },
	{ value: 124, label: "Renewables & Environment" },
	{ value: 125, label: "Research" },
	{ value: 126, label: "Restaurants" },
	{ value: 127, label: "Retail" },
	{ value: 128, label: "Security & Investigations" },
	{ value: 129, label: "Semiconductors" },
	{ value: 130, label: "Shipbuilding" },
	{ value: 131, label: "Sporting Goods" },
	{ value: 132, label: "Sports" },
	{ value: 133, label: "Staffing & Recruiting" },
	{ value: 134, label: "Supermarkets" },
	{ value: 135, label: "Telecommunications" },
	{ value: 136, label: "Textiles" },
	{ value: 137, label: "Think Tanks" },
	{ value: 138, label: "Tobacco" },
	{ value: 139, label: "Translation & Localization" },
	{ value: 140, label: "Transportation/Trucking/Railroad" },
	{ value: 141, label: "Utilities" },
	{ value: 142, label: "Venture Capital" },
	{ value: 143, label: "Veterinary" },
	{ value: 144, label: "Warehousing" },
	{ value: 145, label: "Wholesale" },
	{ value: 146, label: "Wine & Spirits" },
	{ value: 147, label: "Wireless" },
	{ value: 148, label: "Writing & Editing" },
];
