import { observer } from "mobx-react-lite";
import Select from "../../../../Shared/Components/Select/Select";
import DatePckr from "../../../../Shared/Components/Input/DatePickerInput";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import { cancelationReason, cancelationType, cancelationUnit } from "../../../../Shared/StaticData/equityPlans";
import { CancelationReason } from "../../../../Models/App/EquityPlans/GrantCancelations";
import Input from "../../../../Shared/Components/Input/NumberInput";

const CancelationCard = () => {
	const { equityPlansStore } = useRootStore();

	const gCancel = equityPlansStore.benGrantCancelation;

	return (
		<>
			<div>CancelationCard</div>
			<div className="input">
				<Select label="Cancelation reason" options={cancelationReason} value={gCancel?.cancelationReason} onChange={() => {}} disabled qaid="" />
			</div>
			<div className="input">
				<DatePckr label="Cancelation date" value={gCancel?.cancelationDate as string} onChange={() => {}} qaid="" disabled />
			</div>
			<div className="input">
				<Select label="Canceling" options={cancelationType} value={gCancel?.cancelType} onChange={() => {}} qaid="" disabled />
			</div>

			{gCancel?.cancelationReason === CancelationReason.DueToExercise ? (
				<Input value={gCancel.amountToCancel} onChange={() => {}} qaid="AddNewStep.Input.TimePeriod" label="Amount" />
			) : (
				<Input
					label="Cancel in:"
					value={gCancel?.gracePeriodNum}
					onChange={() => {}}
					qaid="AddNewStep.Input.TimePeriod"
					options={cancelationUnit ?? []}
					selectedValue={gCancel?.gracePeriodType}
					onOptionSelect={() => {}}
					ratio={0.4}
					// error={formValidationState?.amount?.message || formValidationState?.dateType?.message}
					// ref={(el: InputValidationRef) => (inputRefs.current[2] = el)}
				/>
			)}
		</>
	);
};
export default observer(CancelationCard);
