import React, { ReactElement } from "react";
import Table, { TableColumn } from "../../../../Shared/Components/Table/Table";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import { OrderByBeneficiaryTable, TrusteeBeneficiaries } from "../../Types/trusteeTypes";
import TableAdditionalViews from "../TableAdditionalViews";
import useTrusteeModals from "../../useTrusteeModals";
import TrusteeBeneficiaryCard from "./BeneficiaryCard/TrusteeBeneficiaryCard";
import PagedTable, { PagedTableKeys } from "../../../../Shared/Components/Table/PagedTable/PagedTable";
import { PagedTableReqParams } from "../../Types/ReqRes";
import { useEffectOnce } from "react-use";
import { observer } from "mobx-react-lite";

import { toJS } from 'mobx';
import { BeneficiaryCell } from '../BeneficiaryCell/BeneficiaryCell';
import { IC_CANCEL_ROUND } from '../../../../Assets';
import TerminationRequest from './TerminationRequest/TerminationRequest';
import TerminationRequestTermination from './TerminationRequest/CancelRequest/TerminationRequestTermination';


export interface TrusteeTableProps {
    header: ReactElement;
    expand: boolean;
}

const TrusteeBeneficiariesMain = (props: TrusteeTableProps) => {

    const { trusteeStore } = useRootStore()
    const { showBeneficiaryCard, showTerminationModal, showTerminationCancelationModal } = useTrusteeModals()

    const bensColumns: TableColumn<TrusteeBeneficiaries>[] = [
        { label: "Beneficiary", name: "fullName" },
        { label: "Employee number", name: "employeeNumber" },
        { label: "Site", name: "site" },
        { label: "Shares", name: "shares" },
        { label: "Web access", name: "webAccess" },
        { label: "Email", name: "businessEmail" },
        { label: "Outstanding", name: "outstanding" },
    ];

    const benficiaryKeys: PagedTableKeys<TrusteeBeneficiaries>[] = [
        {
            key: 'fullName', displayValue: 'Beneficiary', entityEnumValue: OrderByBeneficiaryTable.FullName, searchable: true,
            customCell: (column, row) => (
                <>
                    <BeneficiaryCell
                        data={column}
                        fullRow={row}
                        onTerminate={() => termintaionRequestHandler(row)}
                        showQa
                        pendingTermination={!!row.requestNumber}
                    />
                </>
            )
        },
        { key: 'employeeNumber', displayValue: 'Employee number', entityEnumValue: OrderByBeneficiaryTable.EmployeeNumber, searchable: true },
        { key: 'employeeStatus', displayValue: 'Employee status', entityEnumValue: OrderByBeneficiaryTable.EmployeeStatus },
        { key: 'site', displayValue: 'Site', entityEnumValue: OrderByBeneficiaryTable.Site, searchable: true },
        { key: 'shares', displayValue: 'Shares', entityEnumValue: OrderByBeneficiaryTable.Shares },
        { key: 'webAccess', displayValue: 'Web access', entityEnumValue: OrderByBeneficiaryTable.WebAccess },
        { key: 'businessEmail', displayValue: 'Email', entityEnumValue: OrderByBeneficiaryTable.BusinessEmail, searchable: true },
        { key: 'outstanding', displayValue: 'Outstanding', entityEnumValue: OrderByBeneficiaryTable.Outstanding }
    ]

    const getStuffByParams = async (params: PagedTableReqParams) => {
        const data = await trusteeStore.GetTrusteeBeneficiariesByParams(params);
        return 3;
    };

    const termintaionRequestHandler = (row: TrusteeBeneficiaries) => {
        if (row.requestNumber) {
            showTerminationCancelationModal(row.openTerminationRequest)

            // TODO: add cancel termination request modal
        } else if (row.employeeStatus === 'Employee') {

            console.log('Terminating ...', toJS(row))
            showTerminationModal(row)

        }

        // else if (
        //     // row.employeeStatus === 'Pending' && 
        //     row.requestNumber
        // ) {
        //     window.alert('Already pending requset')
        // }
    }

    const openBeneficiaryCard = (obj: TrusteeBeneficiaries) => {
        const id = trusteeStore.trusteeBeneficiariesTable.beneficiarys.filter((ben) => ben.employeeNumber === obj.employeeNumber)[0]
            ?.beneficiaryId;
        if (!id) return;
        console.log("Opening card for", obj.fullName);
        showBeneficiaryCard(id);
    };

    const Header = () => {
        return (
            <>
                {props.header}
                <TableAdditionalViews />
            </>
        );
    };

    useEffectOnce(() => {
        const params: PagedTableReqParams = {};
        params.PageNumber = 1;
        params.ItemInPage = 5;
        getStuffByParams(params);
    });

    return (
        <>
            <PagedTable
                tableKeys={benficiaryKeys}
                newDataReq={(params) => getStuffByParams(params)}
                onSort={(params) => getStuffByParams(params)}
                tableData={trusteeStore.trusteeBeneficiariesTable.beneficiarys}
                totalRows={trusteeStore.trusteeBeneficiariesTable.numberOfTotalBeneficiarys}
                headerChild={<Header />}
                onTableRowClick={(row) => openBeneficiaryCard(row)}
                itemsInPage={props.expand ? 10 : 5}
                search
                filter
            />
        </>
    );
};

export default observer(TrusteeBeneficiariesMain);
