import { css } from "@emotion/css";
import appConfig from "../../../../../../config/config";

const NewBeneficiaryStyles = css({
    label: "AddBeneficiary",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    "&:not(.no-pd)": {
        padding: "8rem 8rem",
    },
    "&.no-pd": {
        borderBottom: `1px solid ${appConfig.style.colors.gray}`,
        marginBottom: "2.4rem",
        paddingBottom: "2.4rem",
    },

    "&__form": {
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        gridTemplateRows: "repeat(1, 1fr)",
        gridColumnGap: "2.4rem",
        gridRowGap: "3.4rem",
    },
    "&__title": {
        marginBottom: "4.4rem",
    },
    "&__more-info-btn": {
        fontWeight: 500,
        position: "relative",
        overflow: "hidden",
        "&::after": {
            content: "''",
            position: "absolute",
            width: 9999,
            height: 1,
            left: "23.5rem",
            background: appConfig.style.colors.field,
        },
    },
    "&__grant-title": {
        fontWeight: 500,
        position: "relative",
        overflow: "hidden",
        marginBottom: "3rem",
        "&::after": {
            content: "''",
            position: "absolute",
            width: 9999,
            height: 1,
            left: "14rem",
            top: 10,
            background: appConfig.style.colors.field,
        },
    },
});

export default NewBeneficiaryStyles;
