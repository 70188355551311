import { SplitTableData } from "../Models/API/CapTable/split-table-data";

import { action, makeAutoObservable, runInAction, toJS } from "mobx";

import { OperationSaveMode } from "../Models/API/All/operation-save-mode";
import { UserPersonalInformation } from "../Models/API/All/user-personal-information";
import { OptionTransactionInfo, PersonCapTablePreviewInfo, ShareClassInfo } from "../Models/API/CapTable";
import { AvailableAmountsResultInfo } from "../Models/API/CapTable/available-amounts-result-info";
import { LoadOptionTransactionResultInfo } from "../Models/API/CapTable/load-option-transaction-result-info";
import { OptionTransactionResultInfo } from "../Models/API/CapTable/OptionTransactionResultInfo";

import { AvailableAwardsResultInfo } from "../Models/API/CapTable/available-awards-result-info";
import { ErrorMessage, ProjectPreviewInfo } from "../Models/API/CapTable/project-preview-info";
import { ProjectUserResultInfo } from "../Models/API/CapTable/project-user-result-info";
import {
    CapTableFullscreenMode,
    OptionTransactionTypesEnum,
    OptionUpdateModeTypeEnum,
    PersonTypesEnum,
    ShareClassTypeOptions,
    WarrantsType,
} from "../Models/API/enums";
import IAutoCompleteUser, { IAutoCompleteRequest } from "../Models/App/AutoCompleteUser";
import { ExecuteResponse } from "../Services/BaseService";
import CapTableService from "../Services/CapTableService";
import UserService, { escapeRegexCharacters } from "../Services/UserService";
import { appDate } from "../Shared/appDate";
import { formatDate, formatNumber } from "../Shared/Utilities";
import { RootStore } from "./RootStore";
import StoreUpdater from "./StoreUpdater";

import { t } from "i18next";
import { clearPersistedStore, makePersistable } from "mobx-persist-store";
import { IPivotalCompany } from "../Models/App";
import { GetInternalDashBoardCapTableReq } from "../Models/App/CapTable/dashboard";
import { ImportCapTable } from "../Models/App/CapTable/ImportCapTable";
import { CapTablePermission } from "../Models/API/UsersAndPermissions/permissions-enum";

export default class CapTableStore {
    updateStoreValue = new StoreUpdater(this).dispatcher();
    initiated = false;
    project?: ProjectPreviewInfo;
    userProjects?: ProjectUserResultInfo;
    capTableService: CapTableService = {} as any;
    userService: UserService = {} as any;
    currentProjectId: number = 0;
    optionTransactionsList: Array<OptionTransactionInfo> = [];
    optionTransactionsData: LoadOptionTransactionResultInfo | null = null;
    isLoading = false;
    userPermissions: CapTablePermission = CapTablePermission.no_access;
    minNumberOfCommonShares?: number;
    showExercisedDetails: boolean;
    internalDashBoardCapTableData: any | null = null;
    projectErrors: ErrorMessage[] = [];

    private fullscreen: CapTableFullscreenMode = CapTableFullscreenMode.none;
    private _filteredDate: Date = new Date();
    public get filteredDate(): Date {
        return this._filteredDate;
    }
    public set filteredDate(value: Date) {
        this._filteredDate = value;
        this.reloadCurrentProject();
    }
    isEditing: boolean = false;

    get filteredSharedClasses() {
        return this.project?.shareClassesList?.filter((x) => x.shareClass.shareClassTypeID !== 200);
    }

    get numberOfCommonShares() {
        return this.getCommonShareClass(this.project)?.numberOfRegisteredShares ?? 0;
    }

    get fullscreenMode() {
        return this.fullscreen;
    }

    setFullscreen = (val: CapTableFullscreenMode) => {
        this.fullscreen = val;
    };

    getCommonShareClass(project?: ProjectPreviewInfo) {
        if (project) {
            const shareClass = project.shareClassesList?.find(
                (x) => x.shareClass.shareClassTypeID === ShareClassTypeOptions.Common
            )?.shareClass;
            return shareClass;
        }
        return undefined;
    }

    get isValidProjectForPublish(): boolean {
        if (this.project) {
            if (this.project.optionTransactionsBalance && !this.project.optionTransactionsBalance?.isValid) {
                return false;
            }
            if (this.project.shareClassTransactionsBalanceList?.find((x) => !x.isValid)) {
                return false;
            }
            if (this.project.shareClassWithoutBalanceList?.find((x) => !x.isValid)) {
                return false;
            }
            if (this.project.shareHolderTransactionBalanceList?.find((x) => !x.isValid)) {
                return false;
            }
            if (this.project.shareClassHolderList?.find((x) => !x.isValid)) {
                return false;
            }
            return true;
        }

        return false;
    }

    constructor(private rootStore: RootStore) {
        makeAutoObservable(this, {
            loadProject: action,
        });
        makePersistable(this, {
            name: "CapTableStore",
            properties: ["project", "userProjects", "optionTransactionsData", "currentProjectId"],
            storage: window.sessionStorage,
            expireIn: 10800000, // 3 hours
        });
        // Track company change because services use companyID in the common values and they need to be reinstanciated after company change
        rootStore.companyStore.onCompanyChange.subscribe(this.initServices);
        this.showExercisedDetails = false;
    }

    // This will be called autmatically when company changes
    private initServices = () => {
        if (this.rootStore.companyStore.companyId) {
            this.capTableService = new CapTableService(this.rootStore.companyStore.companyId);
            this.userService = new UserService(this.rootStore.companyStore.companyId);

            if (this.initiated) {
                this.resetStoreToDefaultValues();
                this.init();
            }
        }
    };

    init = async () => {
        RootStore.subscribeToLoading([this.capTableService], this.rootStore);
        if (this.rootStore.companyStore.isCreatingCompanyFromCompany === true) return;
        const res = await this.loadUserProjects();
        await this.loadUserPermissions();
        this.showExercisedDetails = false;

        if (!res.isSuccess) return;

        // If user has projects then load first one.
        // Otherwise automatically create new porject with today date and load it
        if (!this.userProjects?.projectsUserList?.length) {
            this.currentProjectId = 0;
            // if (this.userPermissions === CapTablePermission.admin || this.userPermissions === CapTablePermission.editor) {
            // 	await this.createNewProject(`Autosave ${formatDate(new Date())}`, new Date());
            // 	this.isLoading = false;
            // 	this.isEditing = true;
            // 	this.initiated = true;
            // } else {
            // 	this.isLoading = false;
            // 	this.initiated = true;
            // }
            return false;
        }
        const publishedProject = this.userProjects.projectsUserList.find((project) =>
            this.currentProjectId ? project.projectID === this.currentProjectId : project.isPublished
        );
        this.currentProjectId = publishedProject ? publishedProject.projectID : this.userProjects.projectsUserList[0].projectID;

        await this.loadProject(this.currentProjectId);
        this.isLoading = false;
        this.initiated = true;
        return true;
    };

    setCapTableFilterDate = (date: Date) => {
        this.filteredDate = date;
    };

    setPivotalcompany = async (reCaptchaToken: string, company: IPivotalCompany) => {
        const res = await this.capTableService.setPivotalcompany(
            this.rootStore.companyStore.companyId,
            company,
            this.rootStore.auth.pivotalToken,
            reCaptchaToken
        );

        return res.isSuccess;
    };

    disconnectPivotalcompany = async () => {
        const res = await this.capTableService.disconnectPivotalcompany(this.rootStore.companyStore.company.companyId);

        return res.isSuccess;
    };

    loadProject = async (projectID: number) => {
        this.currentProjectId = projectID;

        const res = await this.capTableService.loadCapTableProject(projectID, this.filteredDate);

        if (!res.data) return;

        const _project = res.data;
        let _minCommonShares = 0;

        if (
            this.userPermissions === CapTablePermission.admin ||
            this.userPermissions === CapTablePermission.editor ||
            this.userPermissions === CapTablePermission.full_access
        ) {
            _minCommonShares =
                (await this.getAvailableAmountByClass(this.getCommonShareClass(_project)?.shareClassID ?? 0, this.filteredDate))
                    .minAvailableRegisteredShares ?? 0;
        }

        runInAction(() => {
            if (_project?.isPublished) {
                this._filteredDate = _project.filteredDate ?? new Date();
            }
            this.minNumberOfCommonShares = _minCommonShares;
            const showWOshares = _project?.shareClassHolderList?.some(
                (x) => x.personID === -1 && (x.numberOfSharesOutstanding || x.numberOfWarrants || x.numberOfBSA || x.converted || x.options)
            );
            const personsList = showWOshares
                ? [
                      ...(_project.personsList || []),
                      {
                          firstName: t("captable.withOrWithoutOthers"),
                          personID: -1,
                          sourceUserID: -1,
                      },
                  ]
                : _project.personsList;

            this.project = {
                ..._project,
                personsList,
                shareClassHolderList: showWOshares
                    ? _project.shareClassHolderList
                    : _project.shareClassHolderList?.filter((p) => p.personID !== -1),
            };

            const errors = (_project.shareHolderTransactionBalanceList || [])?.reduce<ErrorMessage[]>((acc, t) => {
                return t.isValid ? acc : [...acc, ...t.errorMessageList.map((error) => ({ ...error, type: "error" } as ErrorMessage))];
            }, [] as ErrorMessage[]);

            if (_project.options && !_project.options.isImportedSuccesfully) {
                errors.push({
                    message: "Temporal communication problem for options data sync. Please try again later",
                    type: "alert",
                });
            }

            this.projectErrors = errors;

            // if (showWOshares) {
            // 	this.project?.personsList?.push();
            // } else {
            // 	this.project = { ..._project, personsList: [...(_project.personsList || [])] }
            // }
        });
    };

    getUsersAutoComplete(query: IAutoCompleteRequest): Array<IAutoCompleteUser> {
        const escapedValue = escapeRegexCharacters((query.email ?? query.firstName ?? query.lastName ?? "").trim());

        if (escapedValue === "") {
            return [];
        }
        const regex = new RegExp("\\b" + escapedValue, "i");
        const mapper = (x: PersonCapTablePreviewInfo) => {
            return {
                email: x.email ?? "",
                firstName: x.firstName ?? "",
                lastName: x.lastName ?? "",
                userId: x.personID ?? 0,
                dialingCode: "",
                phoneNumber: "",
            };
        };
        if (query.email) {
            return this.project?.personsList?.filter((x) => regex.test(x.email ?? "")).map((x) => mapper(x)) ?? [];
        } else if (query.firstName) {
            return this.project?.personsList?.filter((x) => regex.test(x.firstName ?? "")).map((x) => mapper(x)) ?? [];
        } else if (query.lastName) {
            return this.project?.personsList?.filter((x) => regex.test(x.lastName ?? "")).map((x) => mapper(x)) ?? [];
        }

        return [];
    }

    loadUserProjects = async () => {
        const res = await this.capTableService.GetAllProjectsByUser(this.rootStore.auth.user.userId);
        if (res.isSuccess) {
            this.userProjects = {
                ...res.data,
                projectsUserList: res.data.projectsUserList?.sort((a, b) => (b.isPublished ? 1 : 0) - (a.isPublished ? 1 : 0)),
            };
        }
        return res;
    };

    loadUserPermissions = async () => {
        const res = await this.capTableService.getUserPermissions();
        if (res.isSuccess) {
            this.userPermissions = res.data.userDetails.role ?? CapTablePermission.no_access;
        }
        return res;
    };

    createNewProject = async (projectName: string | null, filteredDate: Date) => {
        this.isLoading = true;
        const projectRes = await this.capTableService.CreateNewProject({
            projectName: projectName,
            filteredDate: filteredDate,
        });
        if (projectRes.isSuccess && projectRes.data?.project) {
            await Promise.all([this.loadProject(projectRes.data.project?.projectID), this.loadUserProjects()]);
        }
        this.isLoading = false;
    };

    reloadCurrentProject = async () => {
        if (this.currentProjectId) {
            await this.loadProject(this.currentProjectId);
        }
    };

    addUpdateShareClass = async (shareClass: ShareClassInfo) => {
        shareClass.projectID = this.currentProjectId ?? 0;
        if (shareClass.shareClassID > 0) {
            await this.capTableService.updateShareClass(shareClass);
        } else {
            await this.capTableService.addShareClass(shareClass);
        }
        await this.reloadCurrentProject();
    };

    addShareClassFromImport(shareClass: ShareClassInfo) {
        return this.capTableService.addShareClassFromImport(shareClass);
    }

    deleteShareClass = async (id: number) => {
        await this.capTableService.removeShareClass(id, this.currentProjectId);
        await this.reloadCurrentProject();
    };

    addOrUpdateOptionTransaction = async (optionTransactionInfo: OptionTransactionInfo, saveMode: OperationSaveMode) => {
        let res: OptionTransactionResultInfo = {} as any;
        // Update existing
        if (optionTransactionInfo.optionTransactionID) {
            res = (await this.capTableService.updateOptionTransaction(optionTransactionInfo, this.currentProjectId, saveMode)).data;
        } else {
            //Create new
            res = (await this.capTableService.addOptionTransaction(optionTransactionInfo, this.currentProjectId, saveMode)).data;
        }
        await this.loadOptiontransactions();
        await this.reloadCurrentProject();

        return res;
    };

    deleteOptionTransaction = async (optionTransactionInfo: OptionTransactionInfo, saveMode: OperationSaveMode) => {
        // // Mark existing as delted
        const res = (await this.capTableService.removeOptionTransaction(optionTransactionInfo, this.currentProjectId, saveMode)).data;
        await this.loadOptiontransactions();
        await this.reloadCurrentProject();

        return res;
    };

    loadOptiontransactions = async () => {
        var data = (await this.capTableService.loadOptionTransactions(this.currentProjectId ?? 0, this.filteredDate)).data;

        if (data) {
            this.optionTransactionsData = data;
            this.optionTransactionsList = data.optionTransactionList ?? [];
        }
    };

    getGrantNumberList = (date: Date) => {
        var data = this.optionTransactionsList
            .filter(
                (x) => x.optionTransactionTypeID === OptionTransactionTypesEnum.Grant && x.date && appDate(x.date).getDatePart() <= date
            )
            .map((x) => x.grantNumber ?? "");

        return data;
    };
    // saveOptiontransactions = async () => {
    //   await this.capTableService.overrideOptionManualTransaction(
    //     this.curentProjectId ?? 0,
    //     this.optionTransactionsList
    //   );
    //   await this.loadOptiontransactions();
    //   await this.reloadCurrentProject();
    // };

    publishProject = async (projectId: number, name: string, date: Date, description?: string) => {
        const res = await this.capTableService.DuplicateCapTableAsCapTable({
            projectName: name,
            projectID: projectId,
            isPublished: true,
            filterDate: date,
            description,
        });

        if (res.data) {
            await this.loadUserProjects();
            await this.loadProject(res.data.projectID ?? 0);
            this.isEditing = false;
        }

        return res;
    };

    saveAsDraftProject = async (name?: string) => {
        const res = await this.capTableService.DuplicateCapTableAsCapTable({
            projectName: name,
            projectID: this.currentProjectId ?? 0,
            isPublished: false,
        });
        if (res.isSuccess) {
            await this.loadUserProjects();
            if (res.data.projectID) {
                await this.loadProject(res.data.projectID ?? 0);
            }
        }
    };

    duplicateProject = async (projectID: number) => {
        const res = await this.capTableService.DuplicateCapTableAsCapTable({
            projectID: projectID,
            isPublished: false,
        });
        if (res.isSuccess) {
            await this.loadUserProjects();
            if (res.data.projectID) {
                await this.loadProject(res.data.projectID ?? 0);
                this.isEditing = true;
            }
        }
    };

    deleteProject = async (id: number, isCapTableProject: boolean = true) => {
        const res = await this.capTableService.deleteProject(id);
        if (res.data && isCapTableProject) {
            await this.loadUserProjects();

            // If deleteing current project then either load the first one of the remaining or create new draft
            if (id === this.currentProjectId) {
                if (this.userProjects?.projectsUserList?.length) {
                    await this.loadProject(this.userProjects?.projectsUserList[0].projectID);
                } else {
                    window.location.assign("/");
                }
            }
        }
    };

    setShowExercisedDetails = (val: boolean = false) => {
        this.showExercisedDetails = val;
    };

    // addUpdateShareHolder = async (person: UserPersonalInformation, permission: number) => {
    // 	let res;

    // 	if (person.userID > 0) {
    // 		// await this.capTableService.UpdatePerson(person, this.curentProjectId);
    // 		res = await this.userService.updateUserCompany(person);
    // 	} else {
    // 		res = await this.capTableService.AddPerson(person, this.curentProjectId, permission);
    // 	}
    // 	if (res.isSuccess) {
    // 		this.rootStore.userStore.showDialog = false;
    // 		await this.reloadCurrentProject();
    // 	}

    // 	return res;
    // };

    getAvailableAmountByClass = async (classId: number, date: Date) => {
        const res = await this.capTableService.availableAmountsByShareClass(classId, date, this.currentProjectId);

        if (res.isSuccess) {
            return res.data;
        }
        return {
            availableShares: 0,
            availableWarrants: 0,
            availableBsa: 0,
        } as AvailableAmountsResultInfo;
    };

    getAvailableAmountByTypeAndExPrice = async (
        classId: number,
        personId: number = 0,
        warrantsType: WarrantsType,
        exPrice: number,
        date: Date
    ) => {
        const res = await this.capTableService.shareHolderAvailableAwardsByTypeAndExrPrice(
            classId,
            personId,
            date,
            this.currentProjectId,
            warrantsType,
            exPrice
        );
        if (res.isSuccess) {
            return res.data;
        }
        return {
            availableAmount: 0,
        } as AvailableAwardsResultInfo;
    };

    getAvailableAmountForSecondaryTx = async (seller_ShareClassId: number, seller_ProjectPersonId: number, date: Date = new Date()) => {
        const res = await this.capTableService.availableAmounts(seller_ShareClassId, seller_ProjectPersonId, date, this.currentProjectId);
        return res.data;
    };

    // availableOptionTransactionByType = async (grantNum: string, transactionType: OptionTransactionTypesEnum) => {
    //   const res = await this.capTableService.availableOptionTransactionByType(grantNum, this.curentProjectId, transactionType);
    //   return res.data;
    // }

    updateNumberOfCommonShares = async (shares: number) => {
        const shareClass = this.project?.shareClassesList?.find((x) => x.shareClass.shareClassTypeID === ShareClassTypeOptions.Common)
            ?.shareClass as ShareClassInfo;

        shareClass.numberOfRegisteredShares = shares;

        const res = await this.capTableService.updateShareClass(shareClass);

        if (res.isSuccess) {
            await this.reloadCurrentProject();
        }

        return res;
    };
    addUpdateSplit = async (data: SplitTableData) => {
        data.projectID = this.currentProjectId;
        let res: ExecuteResponse<any> = {} as any;
        if (data.splitTableID && data.splitTableID > 0) {
            res = await this.capTableService.updateSplit(data, this.currentProjectId);
        } else {
            res = await this.capTableService.addSplit(data, this.currentProjectId);
        }

        if (res.isSuccess) {
            await this.reloadCurrentProject();
        }

        return res;
    };
    removeSplit = async (id: number) => {
        let res = await this.capTableService.removeSplit(id, this.currentProjectId);
        if (res.isSuccess) {
            await this.reloadCurrentProject();
        }
        return res;
    };

    checkCompanySync = async () => {
        const res = await this.capTableService.checkCompanySync(this.rootStore.companyStore.company.companyId);

        return res;
    };

    updateProjectOptionMode = async (mode: OptionUpdateModeTypeEnum) => {
        const res = await this.capTableService.UpdateProjectOptionMode(mode, this.currentProjectId);
        await this.reloadCurrentProject();
        await this.loadOptiontransactions();

        return res;
    };

    resetStoreToDefaultValues() {
        this.initiated = false;
        this.project = undefined;
        this.userProjects = undefined;
        this.currentProjectId = 0;
        this.optionTransactionsList = [];
        this.optionTransactionsData = null;
        this.isLoading = true; // TODO: why isLoading was set to true by default
        this.userPermissions = CapTablePermission.no_access;
        this.minNumberOfCommonShares = undefined;
        this.showExercisedDetails = false;
        clearPersistedStore(this);
    }

    // get totalTableData() {
    // 	const { showExercisedDetails } = this;
    // 	const _shareClassHolderList = this.project?.shareClassHolderList ?? [];
    // 	let totalShares = _shareClassHolderList.length ? _shareClassHolderList.map((x) => x.numberOfSharesOutstanding).reduce((x, y) => x + y) : 0;
    // 	const isBsa = Boolean(_shareClassHolderList?.length);

    // 	const emptyCells = ["", "", "", "", "", ""];

    // 	let totalOptionsAndWarrants = 0;
    // 	totalShares += this.project?.options?.exercise ?? 0;

    // 	if (_shareClassHolderList.length) {
    // 		totalOptionsAndWarrants =
    // 			this.project?.shareClassHolderList?.map((x) => x.numberOfWarrants + x.converted + x.options + x.numberOfBSA).reduce((x, y) => x + y) ?? 0;
    // 	}
    // 	totalOptionsAndWarrants += this.project?.options?.unAllocated ?? 0;
    // 	totalOptionsAndWarrants += this.project?.options?.outstanding ?? 0;
    // 	const totalFullyDeluted = totalShares + totalOptionsAndWarrants;

    // 	const personsList =
    // 		this.project?.personsList?.map((sh, i) => {
    // 			const transactions: any = _shareClassHolderList.filter((x) => x.personID === sh.personID);
    // 			const shares: any = transactions?.map((t: any) => t.numberOfSharesOutstanding).reduce((x: any, y: any) => x + y) ?? 0;
    // 			const optionsAndWarrants =
    // 				transactions?.map((t: any) => t.numberOfWarrants + t.converted + t.options + t.numberOfBSA).reduce((x: any, y: any) => x + y) ?? 0;

    // 			const fullyDeluted = optionsAndWarrants + shares;
    // 			return [
    // 				formatNumber(shares, false),
    // 				totalShares > 0 && shares > 0 ? `${((shares / totalShares) * 100).toFixed(2)}%` : "",
    // 				formatNumber(optionsAndWarrants, false),
    // 				totalFullyDeluted > 0 && optionsAndWarrants > 0 ? `${((optionsAndWarrants / totalFullyDeluted) * 100).toFixed(2)}%` : "",
    // 				formatNumber(fullyDeluted, false),
    // 				totalFullyDeluted > 0 && fullyDeluted > 0 ? `${((fullyDeluted / totalFullyDeluted) * 100).toFixed(2)}%` : "",
    // 			];
    // 		}) || [];

    // 	const exercised = showExercisedDetails
    // 		? emptyCells
    // 		: [
    // 				formatNumber(this.project?.options?.exercise, false),
    // 				totalShares ? `${(((this.project?.options?.exercise ?? 0) / totalShares) * 100).toFixed(2)}%` : "",
    // 				"",
    // 				"",
    // 				formatNumber(this.project?.options?.exercise, false),
    // 				totalFullyDeluted ? `${(((this.project?.options?.exercise ?? 0) / totalFullyDeluted) * 100).toFixed(2)}%` : "",
    // 		  ];

    // 	const exercisesList = showExercisedDetails
    // 		? this.project?.options?.exerciseDetails?.exercisesList?.map((x) => {
    // 				const totalHolding = (x.outstanding ?? 0) + (x.exercise ?? 0);
    // 				const isOther = x.personType === PersonTypesEnum.None;
    // 				return [
    // 					formatNumber(x.exercise, false),
    // 					`${(((x.exercise ?? 0) / totalShares) * 100).toFixed(2)}%`,
    // 					isOther || !x.outstanding ? " " : formatNumber(x.outstanding, false),
    // 					isOther || !x.outstanding ? " " : formatNumber(((x.outstanding ?? 0) / totalHolding / totalFullyDeluted) * 100, false) + "%",
    // 					formatNumber(totalHolding, false),
    // 					formatNumber((totalHolding / totalFullyDeluted) * 100, false) + "%",
    // 				];
    // 		  }) || []
    // 		: [];

    // 	const outstanding = [
    // 		"",
    // 		"",
    // 		formatNumber(this.project?.options?.outstanding, false),
    // 		totalFullyDeluted ? `${(((this.project?.options?.outstanding ?? 0) / totalFullyDeluted) * 100).toFixed(2)}%` : "",
    // 		formatNumber(this.project?.options?.outstanding, false),
    // 		totalFullyDeluted ? `${(((this.project?.options?.outstanding ?? 0) / totalFullyDeluted) * 100).toFixed(2)}%` : "",
    // 	];

    // 	const unallocated = [
    // 		"",
    // 		"",
    // 		formatNumber(this.project?.options?.unAllocated, false),
    // 		totalFullyDeluted ? `${(((this.project?.options?.unAllocated ?? 0) / totalFullyDeluted) * 100).toFixed(2)}%` : "",
    // 		formatNumber(this.project?.options?.unAllocated, false),
    // 		totalFullyDeluted ? `${(((this.project?.options?.unAllocated ?? 0) / totalFullyDeluted) * 100).toFixed(2)}%` : "",
    // 	];

    // 	const total = [
    // 		formatNumber(totalShares),
    // 		totalShares ? "100%" : "0",
    // 		formatNumber(totalOptionsAndWarrants),
    // 		totalFullyDeluted ? `${((totalOptionsAndWarrants / totalFullyDeluted) * 100).toFixed(2)} %` : "0",
    // 		formatNumber(totalFullyDeluted),
    // 		totalFullyDeluted ? "100%" : "0",
    // 	];

    // 	return {
    // 		personsList,
    // 		exercised,
    // 		exercisesList,
    // 		outstanding,
    // 		unallocated,
    // 		total,
    // 		totalFullyDeluted,
    // 		isBsa,
    // 	};
    // }
    // get commonShareData() {
    // 	const { project, showExercisedDetails } = this;
    // 	if (!project) return null;
    // 	const classInfo = project.shareClassesList?.find((x) => x.shareClass.shareClassTypeID === 200);
    // 	if (!classInfo) return null;

    // 	let totalCommonshares = 0;
    // 	let totalWarrants = 0;
    // 	let totalOptions = 0;
    // 	let totalBsa = 0;
    // 	const totalExercisedList = project.options?.exerciseDetails?.exercisesList?.reduce((acc, ex: any) => acc + ex.outstanding, 0) ?? 0;

    // 	if (project.shareClassHolderList?.length) {
    // 		totalCommonshares = project?.shareClassHolderList
    // 			?.filter((x) => x.shareClassID === classInfo.shareClass.shareClassID)
    // 			.map((x) => x.numberOfSharesOutstanding)
    // 			.reduce((x, y) => x + y);

    // 		totalBsa = project?.shareClassHolderList
    // 			?.filter((x) => x.shareClassID === classInfo.shareClass.shareClassID)
    // 			.map((x) => x.numberOfBSA)
    // 			.reduce((x, y) => x + y);

    // 		totalWarrants = project?.shareClassHolderList
    // 			?.filter((x) => x.shareClassID === classInfo.shareClass.shareClassID)
    // 			.map((x) => x.numberOfWarrants)
    // 			.reduce((x, y) => x + y);

    // 		totalOptions = project?.shareClassHolderList?.map((x) => x.options).reduce((x, y) => x + y) ?? 0;
    // 	}

    // 	totalCommonshares += project.options?.exercise ?? 0;
    // 	totalOptions += project.options?.unAllocated ?? 0;
    // 	totalOptions += project.options?.outstanding ?? 0;

    // 	const hasCommonBSAs = Boolean(totalBsa);

    // 	const hasCommonWarrants = totalWarrants && totalWarrants > 0 ? true : false;

    // 	const personsList = project.personsList?.map((sh, i) => {
    // 		const transaction = project.shareClassHolderList?.find((x) => x.personID === sh.personID && x.shareClassID === classInfo.shareClass.shareClassID);
    // 		return [
    // 			formatNumber(transaction?.numberOfSharesOutstanding, false),
    // 			hasCommonWarrants && formatNumber(transaction?.numberOfWarrants, false),
    // 			hasCommonBSAs && formatNumber(transaction?.numberOfBSA, false),
    // 			formatNumber(transaction?.options, false),
    // 		].filter((data) => data !== false);
    // 	});

    // 	const exercised = [showExercisedDetails ? "" : formatNumber(project.options?.exercise, false), hasCommonWarrants && "", hasCommonBSAs && "", ""].filter(
    // 		(ex) => ex !== false
    // 	);

    // 	const exercisesList = showExercisedDetails
    // 		? project.options?.exerciseDetails?.exercisesList?.map((x) =>
    // 				[formatNumber(x.exercise, false), hasCommonWarrants && "", hasCommonBSAs && "", formatNumber(x.outstanding, false)].filter(
    // 					(x) => x !== false
    // 				)
    // 		  )
    // 		: [];

    // 	const outstanding = [
    // 		"",
    // 		hasCommonWarrants && "",
    // 		hasCommonBSAs && "",
    // 		formatNumber((project.options?.outstanding ?? 0) - (showExercisedDetails ? totalExercisedList : 0), false),
    // 	].filter((x) => x !== false);

    // 	const unallocated = ["", hasCommonWarrants && "", hasCommonBSAs && "", formatNumber(project.options?.unAllocated, false)].filter((x) => x !== false);

    // 	const total = [
    // 		formatNumber(totalCommonshares),
    // 		hasCommonWarrants && formatNumber(totalWarrants),
    // 		hasCommonBSAs && formatNumber(totalBsa),
    // 		formatNumber(totalOptions),
    // 	].filter((x) => x !== false);

    // 	return {
    // 		personsList,
    // 		exercised,
    // 		exercisesList,
    // 		outstanding,
    // 		unallocated,
    // 		total,
    // 		hasCommonWarrants,
    // 		totalCommonshares,
    // 		totalWarrants,
    // 		totalOptions,
    // 		classInfo,
    // 		hasCommonBSAs,
    // 	};
    // }

    // getShareClassData = (classInfo: ShareClassInfo) => {
    // 	if (!this.project) return null;
    // 	let grandTotalConverted = 0;

    // 	let classTransactions = this.project.shareClassHolderList?.filter((x: any) => x.shareClassID === classInfo.shareClassID);

    // 	const personIDMap: any = {};
    // 	this.project.personsList?.forEach((person, index) => {
    // 		if (person.personID) {
    // 			personIDMap[person.personID] = index;
    // 		}
    // 	});

    // 	//Sort transactions based on personIDMap
    // 	classTransactions?.sort((a, b) => {
    // 		const indexA = personIDMap[a.personID];
    // 		const indexB = personIDMap[b.personID];
    // 		if (indexA === undefined) {
    // 			return 1; // if personID not found in first array, move to end of second array
    // 		} else if (indexB === undefined) {
    // 			return -1; // if personID not found in first array, move to beginning of second array
    // 		} else {
    // 			return indexA - indexB; // sort by index position in first array
    // 		}
    // 	});

    // 	const showWarrants = Boolean(classInfo.numberOfWarrants);
    // 	const showConverted = Boolean((classInfo.convertionValueEx ?? 0) > 0);
    // 	const showBsa = Boolean(classInfo.numberOfBSA);
    // 	const personsList = classTransactions?.map((shareholder) => {
    // 		const transaction = classTransactions?.find((x) => x.personID === shareholder.personID);
    // 		grandTotalConverted += transaction?.converted ?? 0;

    // 		return [
    // 			formatNumber(transaction?.numberOfSharesOutstanding, false),
    // 			showWarrants && formatNumber(transaction?.numberOfWarrants, false),
    // 			showBsa && formatNumber(transaction?.numberOfBSA),
    // 			showConverted && formatNumber(transaction?.converted, false),
    // 		].filter((p) => p !== false);
    // 	});

    // 	const exercised = ["", showWarrants && "", showBsa && "", showConverted && ""].filter((i) => i !== false);

    // 	const emptyCells = ["", showWarrants && "", showBsa && "", showConverted && ""].filter((i) => i !== false);
    // 	const exercisesList = this.showExercisedDetails ? this.project?.options?.exerciseDetails?.exercisesList?.map(() => emptyCells) : [];

    // 	const total = [
    // 		formatNumber(classTransactions?.map((x: any) => x.numberOfSharesOutstanding).reduce((x: any, y: any) => x + y)),
    // 		showWarrants &&
    // 			formatNumber(
    // 				classTransactions
    // 					?.filter((x: any) => x.shareClassID === classInfo.shareClassID)
    // 					.map((x: any) => x.numberOfWarrants)
    // 					.reduce((x: any, y: any) => x + y)
    // 			),
    // 		showBsa &&
    // 			formatNumber(
    // 				classTransactions
    // 					?.filter((x: any) => x.shareClassID === classInfo.shareClassID)
    // 					.map((x: any) => x.numberOfBSA)
    // 					.reduce((x: any, y: any) => x + y)
    // 			),
    // 		showConverted && formatNumber(grandTotalConverted),
    // 	].filter((i) => i !== false);

    // 	const titles = [
    // 		{
    // 			string: "captable.shares",
    // 			fallback: "Shares",
    // 		},
    // 		showWarrants && {
    // 			string: "captable.warrants",
    // 			fallback: "Warrants",
    // 		},
    // 		showBsa && {
    // 			string: "captable.bsa",
    // 			fallback: "BSA",
    // 		},
    // 		showConverted && {
    // 			string: "captable.converted",
    // 			fallback: "Converted",
    // 		},
    // 	].filter((title) => title !== false);

    // 	return {
    // 		personsList,
    // 		exercised,
    // 		exercisesList,
    // 		outstanding: emptyCells,
    // 		unallocated: emptyCells,
    // 		total,
    // 		grandTotalConverted,
    // 		classTransactions,
    // 		shareClass: classInfo.shareClass,
    // 		showWarrants,
    // 		showConverted,
    // 		showBsa,
    // 		titles,
    // 	};
    // };

    updateProjectDetails = async (projectId: number, projectName: string, isCapTableProject: boolean = true) => {
        const res = await this.capTableService.updateProjectDetails(projectId, projectName);
        if (!res.isSuccess || !this.userProjects?.projectsUserList || !isCapTableProject) return;

        const projectIdx = this.userProjects.projectsUserList.findIndex((project) => project.projectID === projectId);

        this.userProjects.projectsUserList = [
            ...this.userProjects.projectsUserList.slice(0, projectIdx),
            {
                ...this.userProjects.projectsUserList[projectIdx],
                projectName,
            },
            ...this.userProjects.projectsUserList.slice(projectIdx + 1),
        ];

        if (this.project?.projectID === projectId) {
            this.project.projectName = projectName;
        }
    };

    getInternalDashBoardCapTableData = async (props?: GetInternalDashBoardCapTableReq) => {
        const res = await this.capTableService.GetInternalDashBoardCapTable(props);

        if (res.isSuccess) {
            const data = res.data.data;
            this.internalDashBoardCapTableData = data;
            return this.internalDashBoardCapTableData;
        }
    };

    getExternalDashBoardCapTableData = async () => {
        const res = await this.capTableService.GetExternalDashBoardCapTable();

        if (res.isSuccess) {
            return res.data;
        }
    };

    getCapTableUpdates = (page: number, size: number = 10) => {
        return this.capTableService.GetCapTableUpdates(page, size);
    };

    uploadCapTableImport = async (data: FormData) => {
        return this.capTableService.uploadCapTableImport(data);
    };

    saveImportedCapTable = async (data: ImportCapTable) => {
        return this.capTableService.saveImportedCapTable(data);
    };
}
