const COLOR_ACTION = "hsl(30, 85%, 67%)";
const COLOR_DARK = "hsl(240, 1%, 30%)";
const COLOR_ERROR = "hsl(353, 90%, 67%)";
const COLOR_LIGHT = "hsl(0, 0%, 90%)";
const COLOR_PRIMARY = "hsl(240, 100%, 80%)";
const COLOR_SECONDARY = "hsl(8, 85%, 67%)";
const COLOR_SUCCESS = "hsl(122, 39%, 49%)";
const COLOR_WARNING = "hsl(204, 86%, 53%)";

export const COLOR_SANTAS_GRAY = "hsl(228, 17%, 65%)"; // #979DB5
export const COLOR_BLUE_HAZE = "#D0D4E4"; // #D0D4E4
export const COLOR_TEXT_ACTION = COLOR_SANTAS_GRAY;
export const COLOR_TUNDORA = "#4D4D4D"; // #4D4D4D
export const COLOR_TITAN_WHITE = "#F0F1FF"; // #F0F1FF

export const colors = {
	action: COLOR_ACTION,
	dark: COLOR_DARK,
	error: COLOR_ERROR,
	light: COLOR_LIGHT,
	primary: COLOR_PRIMARY,
	secondary: COLOR_SECONDARY,
	success: COLOR_SUCCESS,
	warning: COLOR_WARNING,
};
