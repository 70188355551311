import { css } from "@emotion/css";
import { useEffect, useState } from "react";
import CircularProgress from "../../../../../../../../Shared/Components/CircularProgress";
import useRootStore from "../../../../../../../../Shared/Hooks/useRootStore";
import { getRandomNumberBetween } from "../../../../../../../../Shared/Utilities";
import appConfig from "../../../../../../../../config/config";
import { SummaryEstimatedTime } from "../../deps/types";
import Container from "../../../../../../../../Shared/Components/Modal/Modal.Style";
import { IC_WARNING_CIRCLE } from "../../../../../../../../Assets";
import Title from "../../../../../../../../Shared/Components/Layout/Title";
import Button from "../../../../../../../../Shared/Components/Button/Button";

const Style = css({
    label: "ProgressCard",
    boxShadow: `3px 3px 3px 0px ${appConfig.style.colors.boxShadowColor1}`,
    padding: "4rem 10rem",
    border: `1px solid ${appConfig.style.colors.table}`,
    borderRadius: 16,
    width: "fit-content",
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    alignItems: "center",
    "&__label": {
        fontSize: "2rem",
        fontWeight: 700,
        color: appConfig.style.colors.color1,
        margin: "2rem auto",
    },
    ".text-center": {
        color: appConfig.style.colors.text1,
    },
});

type Props = {
    startTime?: number;
    estimated?: SummaryEstimatedTime;
    isDataReceived?: boolean;
    setIsTimeout?: (val: boolean) => void;
    onRetry?: () => void;
    isSummaryStep?: boolean;
};

type Percentage = {
    estimated: number;
    extra: number;
    ratio: number;
};

const ProgressCard = ({ startTime, estimated, isDataReceived = false, setIsTimeout, onRetry, isSummaryStep = true }: Props) => {
	const { waterfallStore } = useRootStore();
	const [isError, setIsError] = useState<boolean>(false);
	const [percentage, setPercentage] = useState<Percentage>({ estimated: 0, extra: 0, ratio: 0.8 });

	useEffect(() => {
		if (!startTime || !estimated || percentage.estimated) return;
		calculatePercentage(percentage);
	}, [startTime, estimated]);

	useEffect(() => {
		return () => {
			setPercentage({} as Percentage);
		};
	}, []);

	useEffect(() => {
		if (!isDataReceived) return;
		(async () => {
			for (let i = 1; i < 100; i++) {
				setPercentage((prevState) => ({
					...prevState,
					estimated: prevState.estimated + i > 100 ? 100 : prevState.estimated + i,
					extra: prevState.extra + i > 100 ? 100 : prevState.extra + i,
				}));
				await new Promise((res) => setTimeout(res, 25));
			}
		})();
	}, [isDataReceived]);

	const calculatePercentage = async (per: Percentage) => {
		if (!startTime || !estimated || isDataReceived) return;

		const total = getSum(per);
		if (total >= 100) {
			isSummaryStep && waterfallStore.cancelSummary();
			setIsTimeout?.(true);
			setIsError(true);
			return console.log('Error');
		}

		const isFirstPhase = per.estimated < 100;
		let result: number = 0;
		const currentTime = +new Date();
		const timeout = getRandomNumberBetween(300, 3000);

		if (isFirstPhase) {
			const endTime = estimated.estimatedTime;
			result = ((currentTime - startTime) / endTime) * 100;
			const estimatedTime = result > 100 ? 100 : result;
			setPercentage((prevState) => ({ ...prevState, estimated: estimatedTime }));

			await new Promise((res) => setTimeout(res, timeout));
			calculatePercentage({ ...per, estimated: estimatedTime });
		} else {
			const endTime = estimated.extraTime;
			result = ((currentTime - startTime) / endTime) * 100;
			const estimatedTime = result > 100 ? 100 : result;
			setPercentage((prevState) => ({ ...prevState, extra: estimatedTime }));

			await new Promise((res) => setTimeout(res, timeout));
			calculatePercentage({ ...per, extra: estimatedTime });
		}
	};

	const getSum = (per: Percentage) => per.estimated * per.ratio + per.extra * (1 - per.ratio);

	// if (isNullOrUndefined(startTime)) {
	//     return (
	//         <Spinner
	//             incorporated
	//             center
	//         />
	//     );
	// }

	const totalPercentage = getSum(percentage);

	if (!isSummaryStep) {
		return <CircularProgress percentage={totalPercentage === 100 && !isDataReceived ? 99 : totalPercentage} label size="sm" />;
	}

	return (
		<div className={Style}>
			{isError ? (
				<>
					<Container.Image src={IC_WARNING_CIRCLE} alt="warning" />
					<Title style={{ marginBottom: '2rem' }} warning>
						Sorry
					</Title>
					<span className="text-center" style={{ marginBottom: '2rem' }}>
						Something went wrong, please try again.
						<br />
						If the problem persists, feel free to contact us.
					</span>
					<Button
						qaid="Summary.Button.Retry"
						label="Retry"
						position="center"
						onClick={() => {
							onRetry?.();
							setIsError(false);
							setPercentage({ estimated: 0, extra: 0, ratio: 0.8 });
						}}
					/>
				</>
			) : (
				<>
					<CircularProgress percentage={totalPercentage === 100 && !isDataReceived ? 99 : totalPercentage} label size="lg" />

					<span className={`${Style}__label`}>Loading...</span>
					<span className="text-center">
						This process might take some time,
						<br />
						please wait
					</span>
				</>
			)}
		</div>
	);
};

export default ProgressCard;
