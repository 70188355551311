import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { checkQaidConvention } from "../Utilities";
import { IC_CAPS_LOCK, IC_PASSWORD_INPUT_CLOSED_EYE, IC_PASSWORD_INPUT_OPENED_EYE } from "../../Assets";
import Image from "../../Shared/Components/Image";
import useScreenSize from "../Hooks/useScreenSize";
const reNumbers = /^[0-9\b]+$/;
interface TextInputProps {
	value?: string | null;
	onChange?: (newValue: string) => void;
	onBlur?: (e: React.FocusEventHandler) => void;
	onEnter?: () => void;
	hasError?: boolean;
	className?: string;
	multiline?: boolean;
	inputProps?: any;
	placeholder?: string;
	disabled?: boolean;
	type?: string;
	error?: string;
	onlyNumbers?: boolean;
	regEx?: RegExp;
	inputRef?: React.LegacyRef<any>;
	name?: string;
	forceVisited?: boolean;
	maxLength?: number;
	qaid: string;
	autocomplete?: boolean;
	autoFocus?: boolean;
	isPassword?: boolean;
	capsLockPosition?: "top" | "bottom" | number;
}

const TextInput = React.forwardRef<any, TextInputProps>((props: TextInputProps, ref) => {
	checkQaidConvention(props.qaid);
	const [isVisisted, setIsVisited] = useState(false);
	const [isCapsLockOn, setIsCapsLockOn] = useState(false);
	const [passwordInputVisible, setPasswordInputVisible] = useState(false);
	const [isFocused, setIsFocused] = useState(false);
	const { isMobile } = useScreenSize();

	useEffect(() => {
		props.forceVisited ? setIsVisited(true) : setIsVisited(false);
	}, [props.forceVisited]);

	const checkCapsLock = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.getModifierState("CapsLock")) {
			setIsCapsLockOn(true);
		} else {
			setIsCapsLockOn(false);
		}
	};

	const onKeyDownHandler = (e: any) => {
		props.isPassword && checkCapsLock(e);

		if (e.key === "Enter") {
			props.onEnter?.();
		}
	};

	const showError = props.hasError || (props.error != null && isVisisted);
	if (props.multiline) {
		return (
			<div
				className={classNames(
					{
						"text-input": true,
						"has-error": showError,
					},
					props.className
				)}
			>
				<textarea
					ref={ref}
					{...props.inputProps}
					placeholder={props.placeholder}
					value={props.value ?? ""}
					disabled={props.disabled}
					onBlur={(e) => {
						setIsVisited(true);
						props.onBlur?.(e as any);
					}}
					onChange={(e) => {
						props.onChange?.(e.target.value);
					}}
				></textarea>
				{showError && isVisisted && <div className="form-error">{props.error}</div>}
			</div>
		);
	} else {
		return (
			<div
				style={{ width: "100%", position: "relative" }}
				className={classNames(
					{
						"text-input": true,
						"has-error": showError,
					},
					props.className
				)}
			>
				<input
					type={props.isPassword ? (passwordInputVisible ? "text" : "password") : props.type ?? "text"}
					{...(props.name != null ? { name: props.name } : {})}
					ref={ref}
					placeholder={props.placeholder}
					{...props.inputProps}
					value={props.value ?? ""}
					maxLength={props.maxLength || 524288}
					disabled={props.disabled}
					autoComplete={props.autocomplete ? props.autocomplete.toString() : "new-password"}
					autoFocus={props.autoFocus}
					onFocus={() => {
						setIsFocused(true);
					}}
					data-qaid={props.qaid}
					onKeyDown={(e) => {
						onKeyDownHandler(e);
					}}
					onKeyUp={(e) => {
						props.isPassword && checkCapsLock(e);
					}}
					onChange={(e) => {
						if (props.regEx) {
							if (e.target.value === "" || props.regEx.test(e.target.value)) {
								props.onChange?.(e.target.value);
							}
						} else if (props.onlyNumbers) {
							if (e.target.value === "" || reNumbers.test(e.target.value)) {
								props.onChange?.(e.target.value);
							}
						} else {
							props.onChange?.(e.target.value);
						}
					}}
					onBlur={(e) => {
						setIsVisited(true);
						setIsFocused(false);
						props.onBlur?.(e as any);
					}}
				/>
				{props.isPassword && (
					<img
						src={passwordInputVisible ? IC_PASSWORD_INPUT_OPENED_EYE : IC_PASSWORD_INPUT_CLOSED_EYE}
						alt=""
						style={{}}
						className={classNames({
							"password-input-icon": true,
							"password-input-icon-opened": passwordInputVisible,
							mobile: isMobile,
						})}
						onMouseDown={(e) => {
							e.preventDefault();
							setPasswordInputVisible(true);
						}}
						onMouseUp={(e) => {
							e.preventDefault();
							setPasswordInputVisible(false);
						}}
						onTouchStart={(e) => {
							e.preventDefault();
							setPasswordInputVisible(true);
						}}
						onTouchEnd={(e) => {
							e.preventDefault();
							setPasswordInputVisible(false);
						}}
					/>
				)}
				{isCapsLockOn && isFocused && props.isPassword && (
					<div
						style={{
							display: "flex",
							alignItems: "center",
							gap: "1rem",
							backgroundColor: "#ffffff",
							border: "1px solid #D9D9D9",
							position: "absolute",
							whiteSpace: "nowrap",
							left: 10,
							bottom: typeof props.capsLockPosition === "number" ? props.capsLockPosition : props.capsLockPosition === "top" ? 60 : -47,
							padding: "0.8rem 3.5rem 0.8rem 1.1rem",
							borderRadius: "5px",
							color: "#929292",
							filter: "drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.15))",
						}}
					>
						<Image src={IC_CAPS_LOCK} />

						<span>Caps Lock is on</span>
					</div>
				)}
				{showError && isVisisted && <div className="form-error">{props.error}</div>}
			</div>
		);
	}
});

export default TextInput;
