import { useEffect } from 'react';
import { OnboardingStatus } from '../../../Models/API/Expensing/onboarding-status-enum';
import { ModalBodyProps } from '../../../Shared/Components/Modal/types';
import useMultiStepForm from '../../../Shared/Hooks/useMultiStepForm';
import { isNumber } from '../../../Shared/Utilities';
import NewReportWizard from '../NewReportWizard';
import FairMarket from './FairMarket';
import GeneralSettings from './GeneralSettings';
import Peers from './Peers';
import StartPage from './StartPage';
import StepIndicator from './StepIndicator';
import useRootStore from '../../../Shared/Hooks/useRootStore';

type Props = ModalBodyProps & {
	startPage?: OnboardingStatus;
};

const InitialSetup = ({ startPage, removeModal }: Props) => {
	const { expensingStore } = useRootStore();

	useEffect(() => {
		if (!isNumber(startPage)) return;
		goTo(startPage);
	}, []);

	const { goTo, step, steps, currentStepIdx } = useMultiStepForm([
		{
			element: <StartPage />,
		},
		{
			element: <GeneralSettings />,
		},
		{
			element: <Peers />,
		},
		{
			element: <FairMarket removeModal={removeModal} />,
		},
	]);

	return (
		<div style={{ position: 'relative' }}>
			<StepIndicator steps={steps.length} currentStepIdx={currentStepIdx} lastStepDoneIdx={expensingStore.company?.onboardingStatus} />
			{step}
		</div>
	);
};

export default InitialSetup;
