import { css } from "@emotion/css";
import { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { IC_ADD_FILE, IC_SUCCESS_MODAL } from "../../../../../../Assets";
import Image from "../../../../../../Shared/Components/Image";
import Title from "../../../../../../Shared/Components/Layout/Title";
import { ForwardedRef } from "../../../../../../Shared/Hooks/useMultiStepForm";
import useRootStore from "../../../../../../Shared/Hooks/useRootStore";
import { ImportContext } from "../../ImportContext";
import { CapTableImportStep } from "../../index.style";
import { importMockData } from "../../mock";
import Select from "../../../../../../Shared/Components/Select/Select";
import * as XLSX from "xlsx";
import { getUniqueId, isNullOrUndefined, isNumber } from "../../../../../../Shared/Utilities";
import UploadFile from "../../../../../../Shared/Components/UploadFile";

const Style = css({
    label: "ImportCapTable",
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    "&__img": {
        marginBottom: "3rem",
    },
    "&__text": {
        margin: "1rem 0 2rem 0",
        display: "flex",
        gap: "0.6rem",
        "&.success-msg": {
            marginBottom: "5rem",
        },
    },
    "&__sheet-selection": {
        marginTop: "5rem",
        display: "flex",
        flexDirection: "column",
        ".select-sheet": {
            marginTop: "2.4rem",
        },
    },
});

const ImportCapTable = forwardRef<ForwardedRef, unknown>((_, forwardedRef) => {
    const { file, setFile, setColumns, onFileUpload } = useContext(ImportContext);
    const [error, setError] = useState<string>();
    const [selectedSheet, setSelectedSheet] = useState<number>();
    const [sheets, setSheets] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useImperativeHandle(forwardedRef, () => ({
        onValidate: onUploadHandler,
    }));

    const onUploadHandler = async (): Promise<boolean> => {
        if (!file) return false;

        if (isNullOrUndefined(selectedSheet) && sheets.length > 1) {
            setError("Please select the relevant sheet from imported cap table");
            return false;
        }

        setError(undefined);
        setIsLoading(true);
        const res = await onFileUpload(file, sheets[selectedSheet ?? 0]);
        setIsLoading(false);

        if (res.error) {
            setError(res.errorMessage || "An error has occurred");
            return false;
        }
        setColumns(res.data?.columns.filter((col) => col?.cells).map((col) => ({ ...col, uniqueId: getUniqueId() })));
        return true;
    };

    useEffect(() => {
        if (!file) return;

        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = (e: any) => {
            const bufferArray = e?.target.result;
            const wb = XLSX.read(bufferArray, { type: "buffer" });
            setSheets(wb.SheetNames);
        };
    }, [file]);

    return (
        <CapTableImportStep
            size="sm"
            className={Style}
        >
            {file ? (
                <>
                    <Image
                        src={IC_SUCCESS_MODAL}
                        width="9rem"
                        className={`${Style}__img`}
                    />
                    <Title type="secondary">Success!</Title>
                    <div className={`${Style}__text success-msg`}>The cap table was imported successfully</div>
                </>
            ) : (
                <>
                    <Image
                        src={IC_ADD_FILE}
                        width="9rem"
                        className={`${Style}__img`}
                    />
                    <Title type="secondary">Import a cap table excel file</Title>
                    <div className={`${Style}__text`}>
                        <span>Note:</span>
                        <span>
                            Please make sure you import the most updated cap table. We recommend arranging in advance the company’s AoA with
                            the share classes details.
                        </span>
                    </div>
                </>
            )}
            <UploadFile
                accept="excel"
                onFileUpload={setFile}
                isLoading={isLoading}
                error={error}
                extensionError="Only supported files can be used for the import cap table process"
                file={file}
            />
            {!!(sheets.length > 1) && (
                <div className={`${Style}__sheet-selection`}>
                    <span className="bold">Select the relevant sheet</span>
                    <span>Looks like your file contains multiple sheets, please select the relevant one</span>
                    <Select
                        className="select-sheet"
                        qaid="ImportCapTable.Select.Sheet"
                        options={sheets.map((sheet, idx) => ({ label: sheet, value: idx }))}
                        defaultValue="Select sheet..."
                        onChange={(value) => isNumber(value) && setSelectedSheet(value)}
                        value={selectedSheet}
                    />
                </div>
            )}
        </CapTableImportStep>
    );
});

export default ImportCapTable;
