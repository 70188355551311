import { observer } from 'mobx-react-lite';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { IC_EDIT } from '../../../../../Assets';
import { NumberOption } from '../../../../../Models/API/All/NumberOption';
import { ExpensingAwardType } from '../../../../../Models/API/Expensing/award-type';
import { ExpectedTermMethodEnum } from '../../../../../Models/API/Expensing/expected-life-method-enum';
import { Grant } from '../../../../../Models/API/Expensing/grant';
import { OptionsPricing } from '../../../../../Models/API/Expensing/options-pricing';
import { Currencies } from '../../../../../Models/API/enums';
import Image from '../../../../../Shared/Components/Image';
import NumberInput from '../../../../../Shared/Components/Input/NumberInput';
import Select from '../../../../../Shared/Components/Select/Select';
import Separator from '../../../../../Shared/Components/Separator';
import Spinner from '../../../../../Shared/Components/Spinner/Spinner';
import { Row, Table, rowHeight } from '../../../../../Shared/Components/Table/Table.Style';
import Tooltip from '../../../../../Shared/Components/Tooltip';
import { currencies } from '../../../../../Shared/Config';
import { useAppendState } from '../../../../../Shared/Hooks/useAppendState';
import { InputRefs, InputValidationRef } from '../../../../../Shared/Hooks/useFormValidation';
import useModal from '../../../../../Shared/Hooks/useModal';
import { ForwardedRef } from '../../../../../Shared/Hooks/useMultiStepForm';
import useRootStore from '../../../../../Shared/Hooks/useRootStore';
import { formatDate, formatDecimal, getCurrency, isNullOrUndefined, isNumber } from '../../../../../Shared/Utilities';
import { isValidExpenseReport } from '../../../helpers/util';
import GrantsTableStyle, { TableCell as Cell } from './index.style';
import useGeneralModal from '../../../../../Shared/Hooks/useGeneralModal';

type Group = Record<
	string,
	Pick<OptionsPricing, 'expectedTerm' | 'expectedTermMethod' | 'volatility' | 'marketPrice'> & {
		grants: OptionsPricing[];
		grantDate: Date;
		exercisePrice: string;
		volatilityMethod: VolatilityMethodEnum;
		marketPriceCurrency: Currencies;
		exercisePriceCurrency: Currencies;
		forfeitureRate: number | undefined | null;
		grantType: ExpensingAwardType;
	}
>;

enum VolatilityMethodEnum {
	Comparable,
	Manual,
}

const expectedTermsMethodOptions: NumberOption[] = [
	{
		value: ExpectedTermMethodEnum.WeightedAverage,
		label: 'Weighted Average',
	},
	{
		value: ExpectedTermMethodEnum.ContractualTerm,
		label: 'Contractual Term',
	},
	{
		value: ExpectedTermMethodEnum.Manual,
		label: 'Manual',
	},
];

const volatilityOptions: NumberOption[] = [
	{
		value: VolatilityMethodEnum.Comparable,
		label: 'Comparable companies',
	},
	{
		value: VolatilityMethodEnum.Manual,
		label: 'Manual',
	},
];

type Props = {
	onSubmit: (onValidate: () => boolean | Promise<boolean>) => Promise<void>;
};

const GrantsTable = forwardRef<ForwardedRef, Props>(({ onSubmit }, forwardedRef) => {
	const { expensingStore } = useRootStore();
	const [groups, setGroups, onInputHandler] = useAppendState<Group>();
	const [isEdit, setIsEdit] = useState<Record<string, boolean>>({});
	const inputRefs = useRef<InputRefs<any>>({} as InputRefs<any>);
	const { showModal } = useModal();
	const originalGrants = useRef<Grant[]>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { showErrorModal } = useGeneralModal();

	const isForfeitureRateEnabled = isNullOrUndefined(expensingStore.newReportData?.settings.forfeitureRate);
	const onValidate = async () => {
		const keys = Object.keys(groups);
		const errors: string[] = keys.reduce((acc, key) => {
			if (!isNumber(groups[key].marketPrice)) {
				acc.push('Market price is missing');
			}

			if (groups[key].expectedTermMethod === ExpectedTermMethodEnum.Manual && !isNumber(groups[key].expectedTerm)) {
				acc.push('Expected term must be a valid number');
			}

			if (groups[key].volatilityMethod === VolatilityMethodEnum.Manual && !isNumber(groups[key].volatility)) {
				acc.push('Volatility must be a valid number');
			}
			// !isNumber(groups[key].marketPrice) ||
			//     (groups[key].expectedTermMethod === ExpectedTermMethodEnum.Manual && !isNumber(groups[key].expectedTerm)) ||
			//     (groups[key].volatilityMethod === VolatilityMethodEnum.Manual && !isNumber(groups[key].volatility));
			return acc;
		}, [] as string[]);

		if (errors.length) {
			showModal({
				type: 'error',
				title: 'Invalid Data',
				body: (
					<>
						<span className="bold">The following errors must be fixed to continue:</span>
						{Array.from(new Set(errors)).map((err, idx) => (
							<div key={err}>
								{idx + 1}. {err}
							</div>
						))}
					</>
				),
			});
			return false;
		}

		return onSubmitHandler();
	};

	useImperativeHandle(forwardedRef, () => ({
		onValidate,
	}));

	useEffect(() => {
		(async () => {
			// if (!expensingStore.newReportData?.report.reportEndDate) return;

			// setIsLoading(true);
			// const res = await expensingStore.getCompanyGrants(expensingStore.newReportData?.report.reportEndDate);
			// setIsLoading(false);

			if (!expensingStore.companyGrants) return;

			originalGrants.current = expensingStore.companyGrants;
			const obj = expensingStore.companyGrants.reduce((acc, grant) => {
				if (grant.isDeleted) return acc;
				const key = `${grant.grantDate}_${grant.exercisePrice}`;
				const grantOption: OptionsPricing = {
					entityAppId: grant.grantAppId,
					optionsPricingMethod: 'BS',
					marketPrice: grant.marketPrice,
					expectedTerm: null,
					expectedTermMethod: ExpectedTermMethodEnum.WeightedAverage,
					forfeitureRatePricing: 0,
					riskFreeRate: null,
					volatility: null,
					fairValue: null,
				};
				if (acc[key]) {
					acc[key].grants.push(grantOption);
				} else {
					acc[key] = {
						grants: [grantOption],
						grantDate: grant.grantDate,
						exercisePrice: `${getCurrency(grant.exercisePriceCurrency)?.symbol}${grant.exercisePrice}`,
						marketPrice: grant.marketPrice,
						expectedTermMethod: ExpectedTermMethodEnum.WeightedAverage,
						expectedTerm: null,
						volatility: null,
						volatilityMethod: VolatilityMethodEnum.Comparable,
						exercisePriceCurrency: grant.exercisePriceCurrency,
						forfeitureRate: expensingStore.newReportData?.settings.forfeitureRate,
						marketPriceCurrency: grant.marketPriceCurrency,
						grantType: grant.awardType,
					};
				}
				return acc;
			}, {} as Group);
			setGroups(obj);
		})();
	}, []);

	const updateGroup = (groupKey: string | undefined, key: keyof Group | undefined, value: any) => {
		if (isNullOrUndefined(groupKey) || isNullOrUndefined(key)) return;
		setGroups((prevGroups) => {
			return {
				...prevGroups,
				[groupKey]: {
					...prevGroups[groupKey],
					[key]: value,
				},
			};
		});
	};

	const onSubmitHandler = async (): Promise<boolean> => {
		if (isNullOrUndefined(expensingStore.newReportData?.report)) return false;

		const grantOptions: OptionsPricing[] = Object.keys(groups).reduce((acc, key) => {
			groups[key].grants.forEach((g) => {
				acc.push({
					entityAppId: g.entityAppId,
					optionsPricingMethod: 'BS',
					expectedTerm: groups[key].expectedTerm,
					expectedTermMethod: groups[key].expectedTermMethod,
					marketPrice: groups[key].marketPrice,
					volatility: isNumber(groups[key].volatility) ? (groups[key].volatility ?? 0) / 100 : groups[key].volatility,
					riskFreeRate: null,
					forfeitureRatePricing: 0,
					fairValue: null,
				});
			});
			return acc;
		}, [] as OptionsPricing[]);

		const res = await expensingStore.runBlackAndScholes(grantOptions);

		if (!res.isSuccess) {
			showErrorModal(res.errorMessage);
			return false;
		};

		if (originalGrants.current) {
			const grantsWithForfeitureRate = originalGrants.current
				.map((grant) => ({
					grantAppId: grant.grantAppId,
					contactAppId: grant.contactAppId,
					forfeitureRate: isNumber(expensingStore.newReportData?.settings.forfeitureRate)
						? expensingStore.newReportData.settings.forfeitureRate / 100
						: groups[`${grant.grantDate}_${grant.exercisePrice}`].forfeitureRate ?? 0,
				}))
				.filter((grant) => grant.forfeitureRate > 0);
			grantsWithForfeitureRate.length && (await expensingStore.updateGrantsForfeitureRate(grantsWithForfeitureRate));
		}
		if (!isValidExpenseReport(expensingStore.newReportData?.report)) return false;

		const createRes = await expensingStore.createReport(expensingStore.newReportData?.report);
		return createRes.isSuccess;
	};

	return (
		<div className={GrantsTableStyle}>
			<span className={`${GrantsTableStyle}__label`}>
				<Tooltip
					showIcon
					title={
						<span style={{ whiteSpace: 'nowrap' }}>
							Assuming no dividends are paid out during the life of the option.
							<br />
							Risk free interest values are pulled from US federal nominal interest
						</span>
					}
				>
					B&S parameters
				</Tooltip>
			</span>
			<Separator />
			<Table className={`${GrantsTableStyle}__table`}>
				<Row header rowSize={rowHeight} headerSize={1.5}>
					<Cell border={{ right: true, bottom: true }} style={{ borderTopLeftRadius: 16, flex: 1.1 }}>
						Grant date
					</Cell>
					<Cell border={{ right: true, bottom: true }} style={{ flex: 1 }}>
						Grant type
					</Cell>
					<Cell border={{ right: true, bottom: true }} style={{ flex: 1.2 }}>
						Exercise price
					</Cell>
					<Cell
						border={{ right: true, bottom: true }}
						style={{
							flex: Object.keys(isEdit).some((key) => key) ? 1.3 : 1.3,
						}}
					>
						Market price
					</Cell>
					<Cell border={{ right: true, bottom: true }} style={{ flex: 2.25 }}>
						Expected life
					</Cell>
					<Cell
						border={{ right: true, bottom: true }}
						style={{
							flex: 2.25,
							borderTopRightRadius: isNumber(expensingStore.newReportData?.settings.forfeitureRate) ? 16 : 0,
						}}
					>
						Volatility
					</Cell>
					{isForfeitureRateEnabled && (
						<Cell border={{ right: true, bottom: true }} style={{ borderTopRightRadius: 16, flex: 1.25 }}>
							Forfeiture rate
						</Cell>
					)}
				</Row>
				{isLoading ? (
					<Row rowSize={1.2}>
						<Spinner incorporated center size={20} />
					</Row>
				) : Object.keys(groups).length ? (
					Object.entries(groups)
						.reverse()
						.map(([key, group], idx) => {
							const isBorderBottom = idx < Object.entries(groups).length - 1;
							return (
								<Row key={idx} rowSize={1.5}>
									<Cell border={{ right: true, bottom: isBorderBottom }} style={{ flex: 1.1 }}>
										{formatDate(group.grantDate)}
									</Cell>
									<Cell border={{ right: true, bottom: isBorderBottom }} style={{ flex: 1 }}>
										{ExpensingAwardType[group.grantType]}
									</Cell>
									<Cell border={{ right: true, bottom: isBorderBottom }} style={{ flex: 1.2 }}>
										{group.grantType === ExpensingAwardType.RSU ? '----' : group.exercisePrice}
									</Cell>
									<Cell
										border={{ right: true, bottom: isBorderBottom }}
										style={{
											flex: Object.keys(isEdit).some((key) => key) ? 1.3 : 1.3,
										}}
										isOverflow
									>
										{isEdit[key] ? (
											<NumberInput
												qaid=""
												name="marketPrice"
												value={group.marketPrice ?? undefined}
												onChange={(value, name) => updateGroup(key, name, value)}
												// options={currenciesOptions}
												placeholder="0.00"
												// selectedValue={group.marketPriceCurrency}
												// onOptionSelect={(value) => {
												//     updateGroup(key, "marketPriceCurrency", value);
												//     inputRefs.current[key]?.focus();
												// }}
												number="float"
												onBlur={() => setIsEdit((prev) => ({ ...prev, [key]: false }))}
												ref={(el: InputValidationRef) => (inputRefs.current[key] = el)}
												prefix={getCurrency(group.marketPriceCurrency)?.symbol}
											/>
										) : (
											<div
												style={{
													width: '100%',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													gap: '2rem',
												}}
											>
												{group.marketPrice ? (
													<>
														{getCurrency(group.marketPriceCurrency)?.symbol}
														{formatDecimal(group.marketPrice, { decimalLength: 3 })}
													</>
												) : (
													'----'
												)}
												<Image
													src={IC_EDIT}
													tooltip="Edit"
													className="clickable"
													onClick={() => setIsEdit((prev) => ({ ...prev, [key]: true }))}
												/>
											</div>
										)}
									</Cell>
									<Cell border={{ right: true, bottom: isBorderBottom }} style={{ flex: 2.25 }} isOverflow>
										<Select
											qaid=""
											options={expectedTermsMethodOptions}
											value={group.expectedTermMethod}
											name="expectedTermMethod"
											onChange={(value, name) => updateGroup(key, name, value)}
										/>
										{group.expectedTermMethod === ExpectedTermMethodEnum.Manual && (
											<NumberInput
												qaid=""
												value={group.expectedTerm || undefined}
												name="expectedTerm"
												number="float"
												onChange={(value, name) => updateGroup(key, name, value)}
											/>
										)}
									</Cell>
									<Cell border={{ right: true, bottom: isBorderBottom }} style={{ flex: 2.25 }} isOverflow>
										<Select
											qaid=""
											options={volatilityOptions}
											value={group.volatilityMethod}
											name="volatilityMethod"
											onChange={(value, name) => {
												if (value === VolatilityMethodEnum.Comparable) {
													setGroups((prevGroups) => {
														return {
															...prevGroups,
															[key]: {
																...prevGroups[key],
																volatility: null,
																volatilityMethod: value,
															},
														};
													});
												} else updateGroup(key, name, value);
											}}
										/>
										{group.volatilityMethod === VolatilityMethodEnum.Manual && (
											<NumberInput
												qaid=""
												percentage
												value={group.volatility || undefined}
												name="volatility"
												placeholder="%"
												onChange={(value, name) => updateGroup(key, name, value)}
											/>
										)}
									</Cell>
									{isForfeitureRateEnabled && (
										<Cell border={{ right: true, bottom: isBorderBottom }} style={{ flex: 1.25 }}>
											<NumberInput
												qaid=""
												percentage
												value={group.forfeitureRate || undefined}
												name="forfeitureRate"
												placeholder="%"
												onChange={(value, name) => updateGroup(key, name, value)}
											/>
										</Cell>
									)}
								</Row>
							);
						})
				) : (
					<Row rowSize={1.2} className="w-100">
						<span className="w-100 text-center">No grants found</span>
					</Row>
				)}
			</Table>
		</div>
	);
});

export default observer(GrantsTable);
