import styled from "@emotion/styled";
import appConfig from "../../../config/config";

type NavButtonProps = {
    type: "back" | "next";
    disabled?: boolean;
};

type PageButtonProps = {
    active?: boolean;
};

const Container = styled.div({
    label: "PaginationContainer",
    display: "flex",
    gap: "1rem",
    justifyContent: "center",
});

const PaginationButton = {
    width: "4rem",
    height: "4rem",
    borderRadius: 6,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    border: `1px solid ${appConfig.style.colors.table}`,
};

const NavButton = styled.div(
    {
        ...PaginationButton,
        userSelect: "none",
    },
    ({ type, disabled }: NavButtonProps) => ({
        label: type === "back" ? "NavBackButton" : "NavNextButton",
        background: disabled ? appConfig.style.colors.table : "#ffffff",
        color: !disabled ? appConfig.style.colors.table : "#ffffff",
        svg: {
            stoke: disabled ? "red" : "blue",
        },
        transform: type === "back" ? "rotate(180deg)" : "none",
        paddingLeft: 2,
    })
);

const PageButton = styled.div(
    {
        label: "PageButton",
        ...PaginationButton,
        fontSize: "1.6rem",
        userSelect: "none",
    },
    ({ active }: PageButtonProps) => ({
        color: active ? appConfig.style.colors.color1 : appConfig.style.colors.text4,
        borderWidth: active ? 2 : 1,
        borderColor: active ? appConfig.style.colors.color1 : appConfig.style.colors.table,
    })
);

const PaginationComp = {
    Container,
    PageButton,
    NavButton,
};

export default PaginationComp;
