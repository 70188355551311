import { FC, ReactNode } from "react";
import { StyledWidget } from "./Widget.style";

export interface WidgetProps {
  children: ReactNode;
}

const Widget: FC<WidgetProps> = (props: WidgetProps) => {
  const { children } = props;
  return <StyledWidget>{children}</StyledWidget>;
};

export default Widget;
