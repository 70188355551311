import { css } from "@emotion/css";
import { useEffect, useState } from "react";
import { IC_EXPENSING_SETTINGS } from "../../../../Assets";
import { ExpensingBasicCompany } from "../../../../Models/API/Expensing/options-migration";
import Image from "../../../../Shared/Components/Image";
import Separator from "../../../../Shared/Components/Separator";
import { useAppendState } from "../../../../Shared/Hooks/useAppendState";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import { isNullOrUndefined } from "../../../../Shared/Utilities";
import ComparablePeers from "./ComparablePeers";
import FairMarketValue from "./FairMarketValue";
import General from "./General";

const Style = css({
    label: "GeneralExpensingSettings",
    maxWidth: "114rem",
    paddingInline: "4rem",
    width: "100%",
    marginInline: "auto",
    paddingTop: "4rem",
    paddingBottom: "4rem",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    "&__label": {
        display: "flex",
        alignItems: "center",
        gap: "2rem",
        fontSize: "2.5rem",
        fontWeight: 500,
    },
});

const Settings = () => {
    const { expensingStore } = useRootStore();
    const [company, _, onInputHandler] = useAppendState<ExpensingBasicCompany>(expensingStore.company);
    const [isMounted, setIsMounted] = useState<boolean>(false);

    useEffect(() => {
        if (!isMounted) return;
        expensingStore.updateCompany(company);
    }, [company]);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    if (isNullOrUndefined(expensingStore.company)) return <></>;

    return (
        <div className={Style}>
            <div className={`${Style}__label`}>
                <Image
                    src={IC_EXPENSING_SETTINGS}
                    width="5rem"
                />
                <span>General expensing settings</span>
            </div>
            <Separator />
            <General
                migration={company}
                onInputHandler={onInputHandler}
            />
            <ComparablePeers />
            <Separator />
            <FairMarketValue isInitial={false} />
        </div>
    );
};

export default Settings;
