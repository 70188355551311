import { observer } from "mobx-react-lite";
import useRootStore from "../../Shared/Hooks/useRootStore";
import TrusteeDashboard from "./Dashboard/TrusteeDashboard";
import TrusteeSectionSwitch from "./TrusteeSectionSwitch";
import MainMenu from "../../Shared/Components/MainMenu/MainMenu";
import { MenuActionType } from "../../Shared/Components/MainMenu/ActionsMenu/ActionsMenu";
import { IC_EDIT2, IC_SUBMIT, IC_REPORT, IC_FORM, IC_DOCUMENT } from "../../Assets";
import { Product } from "../../Models/API/enums";
import { useEffect } from "react";
import useTrusteeModals from "./useTrusteeModals";


const Trustee = () => {

    const { trusteeStore } = useRootStore();
    const { showPivotalRegistration } = useTrusteeModals()

    useEffect(() => {
        if (!trusteeStore.pivotalRegistration) return

        // TODO: Add multiple times pop-up
        if (trusteeStore.pivotalRegistrationOpen) {
            return
        }

        showPivotalRegistration(true)
        trusteeStore.setRegistration = false

    }, [trusteeStore.pivotalRegistration])


    return (
        <>
            <MainMenu product={Product.TrusteeEquity} />
            <TrusteeSectionSwitch />
            {/* <div>Trustee </div>
            <div>Company Id: </div>
            <div>{trusteeStore.companyId}</div>
            <p onClick={() => trusteeStore.number = trusteeStore.number + 5}>ADD</p>
            <p>Number {trusteeStore.number}</p> */}
        </>
    )
}

export default observer(Trustee)