import { css } from '@emotion/css';
import { ClickAwayListener, Fade } from '@mui/material';
import { IC_EXPORT_TO_EXCEL_BUTTON, IC_FILTER_BUTTON, IC_FILTER_BUTTON_CLICKED, IC_TRASH2 } from '../../../../../../../Assets';
import { VolatilityData, VolatilityTableData, WaterfallPeer } from '../../../../../../../Models/API/Waterfall/IForm';
import Image from '../../../../../../../Shared/Components/Image';
import Spinner from '../../../../../../../Shared/Components/Spinner/Spinner';
import { Cell, Row, Table } from '../../../../../../../Shared/Components/Table/Table.Style';
import { getPercentrage, isNumber } from '../../../../../../../Shared/Utilities';
import appConfig from '../../../../../../../config/config';
import { useEffect, useState } from 'react';
import Select from '../../../../../../../Shared/Components/Select/Select';
import { NumberOption } from '../../../../../../../Models/API/All/NumberOption';
import OverflowText from '../../../../../../../Shared/Components/OverflowText';
import useVolatilityTooltip from '../../../../../utils/useVolatilityTooltip';
import Tooltip from '../../../../../../../Shared/Components/Tooltip';

type Props = {
	data?: VolatilityTableData;
	setData?: (data: VolatilityTableData, volatilities: VolatilityData[]) => Promise<void>;
	volatilities: VolatilityData[];
	timeToLiquiity?: number;
	peers?: WaterfallPeer[];
	onDelete: (identifier: string) => Promise<void>;
};

const Style = css({
	label: 'VolatilityTable',
	marginTop: '4rem',
	'&__table': {
		border: `1px solid ${appConfig.style.colors.table}`,
		overflow: 'unset !important',
		maxHeight: 'unset !important',
		borderRadius: 16,
	},
	'&__header': {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: '0.4rem',
		'.title': {
			fontWeight: 500,
		},
		'.actions': {
			display: 'flex',
			alignItems: 'center',
			position: 'relative',
			'.import-img': {
				marginTop: 3,
				marginRight: '0.6rem',
			},
			'.filter-container': {
				position: 'absolute',
				right: 0,
				top: 'calc(100% + 0.6rem)',
				zIndex: 10,
				background: '#ffffff',
				border: `1px solid ${appConfig.style.colors.background1}`,
				padding: '3rem',
				boxShadow: `3px 3px 3px 0px #C9CAD066`,
				borderRadius: 8,
				width: '36rem',
				'.form': {
					marginTop: '1.6rem',
					display: 'grid',
					gridTemplateColumns: 'repeat(2, 1fr)',
					gridColumnGap: '0.8rem',
					gridRowGap: '1.6rem',
				},
			},
		},
	},
	'.arrow-down': {
		width: 0,
		height: 0,
		borderLeft: '6px solid transparent',
		borderRight: '6px solid transparent',
		borderTop: '8px solid #E98B8B',
	},
	'.arrow-up': {
		width: 0,
		height: 0,
		borderLeft: '6px solid transparent',
		borderRight: '6px solid transparent',
		borderBottom: `8px solid ${appConfig.style.colors.success}`,
	},
});

type AdjustKeys = keyof Pick<VolatilityTableData, 'adjustedTableChosenTo' | 'adjustedTableFrom' | 'adjustedTableInterspace'>;

const VolatilityTable = ({ data, volatilities, setData, timeToLiquiity = 0, peers, onDelete }: Props) => {
	const [showFilter, setShowFilter] = useState<boolean>(false);
	const [filter, setFilter] = useState<{ from: number; to: number }>();
	const { getTooltip, onTooltipEnter, onTooltipLeave, tooltipData } = useVolatilityTooltip();
	const [isLoading, setIsLoading] = useState<Record<AdjustKeys, boolean>>({
		adjustedTableChosenTo: false,
		adjustedTableFrom: false,
		adjustedTableInterspace: false,
	});
	const [isDeleting, setIsDeleting] = useState<Record<string, boolean>>({});

	useEffect(() => {
		if (!data || filter) return;
		setFilter({ from: data.adjustedTableFrom, to: data.adjustedTableChosenTo });
	}, [data]);

	if (!data || !filter) return <Spinner incorporated center />;

	const options: NumberOption[] = Array.from({ length: (filter.to - filter.from) / data.adjustedTableInterspace + 1 }).map((_, i) => ({
		label: (filter.from + i * data.adjustedTableInterspace).toString(),
		value: filter.from + i * data.adjustedTableInterspace,
	}));

	const toOptions: NumberOption[] = options.filter((opt) => {
		if (!isNumber(opt.value)) return true;
		if (opt.value === filter.from) return false;
		return opt.value >= data.adjustedTableFrom;
	});

	const onExportHandler = () => {};

	const updateData = async (value: any, key?: AdjustKeys) => {
		if (!isNumber(value) || key === undefined) return;
		setIsLoading((prev) => ({ ...prev, [key]: true }));
		await setData?.({ ...data, [key]: value }, volatilities);
		setIsLoading((prev) => ({ ...prev, [key]: false }));
	};

	const isOneLoading = isLoading.adjustedTableChosenTo || isLoading.adjustedTableFrom || isLoading.adjustedTableInterspace;
	const average = volatilities.find((m) => m.companyName === 'average');

	return (
		<Fade in={volatilities.length > 2} unmountOnExit>
			<div className={Style}>
				<div className={`${Style}__header`}>
					<span className="title">Time to liquidity event: {timeToLiquiity}</span>
					<div className="actions">
						<Image
							data-qaid="VolatilityTable.Button.Export"
							className="import-img pointer"
							src={IC_EXPORT_TO_EXCEL_BUTTON}
							height="4.6rem"
							onClick={onExportHandler}
						/>
						<Image
							data-qaid="VolatilityTable.Button.Filter"
							className="pointer"
							src={showFilter ? IC_FILTER_BUTTON_CLICKED : IC_FILTER_BUTTON}
							onClick={() => setShowFilter((prev) => !prev)}
							height="4rem"
							width="4rem"
						/>
						<Fade in={showFilter} unmountOnExit>
							{/* <ClickAwayListener onClickAway={() => setShowFilter(false)}> */}
							<div className="filter-container">
								<div className="bold">Volatility range in years</div>
								<div className="form">
									<Select
										qaid="VolatilityTable.Select.From"
										value={data.adjustedTableFrom}
										defaultValue="From"
										options={options}
										onChange={(value) => {
											updateData(value, 'adjustedTableFrom');
										}}
										disabled={isOneLoading}
										isLoading={isLoading.adjustedTableFrom}
									/>
									<Select
										qaid="VolatilityTable.Select.To"
										value={data.adjustedTableChosenTo}
										defaultValue="to"
										options={toOptions}
										onChange={(value) => {
											updateData(value, 'adjustedTableChosenTo');
										}}
										disabled={isOneLoading}
										isLoading={isLoading.adjustedTableChosenTo}
									/>
									<Select
										qaid="VolatilityTable.Select.Interspace"
										label="Interspace"
										value={data.adjustedTableInterspace}
										defaultValue="to"
										options={[
											{ value: 0.5, label: '0.5' },
											{ value: 1, label: '1' },
										]}
										onChange={(value) => {
											updateData(value, 'adjustedTableInterspace');
										}}
										disabled={isOneLoading}
										isLoading={isLoading.adjustedTableInterspace}
									/>
								</div>
							</div>
							{/* </ClickAwayListener> */}
						</Fade>
					</div>
				</div>
				<Table className={`${Style}__table`}>
					<Row header headerSize={1.25}>
						<Cell border={{ bottom: true, right: true }} style={{ borderTopLeftRadius: 16 }}>
							Peer Company
						</Cell>
						{volatilities[0]?.data.map((obj) => (
							<Cell key={obj.expectedTermsInYears} border={{ bottom: true, right: true }}>
								{obj.expectedTermsInYears} {obj.expectedTermsInYears === 1 ? 'Year' : 'Years'}
							</Cell>
						))}
						<Cell border={{ bottom: true }} style={{ borderTopRightRadius: 16 }}>
							Actions
						</Cell>
					</Row>
					{volatilities.map((peer, idx) => {
						const isLast = idx === volatilities.length - 1;
						const isTotal = peer.companyName === 'median' || peer.companyName === 'average';
						const waterfallPeer = peers?.find((p) => p.companyName === peer.companyName);

						return (
							<Row key={peer.identitifier} rowSize={1.25} isTotal={isTotal}>
								<Cell
									border={{ bottom: !isLast, right: true }}
									style={{ borderBottomLeftRadius: isLast ? 16 : 0, fontWeight: isTotal ? 500 : 400 }}
								>
									{isTotal ? (
										<OverflowText>{`${peer.companyName[0].toUpperCase()}${peer.companyName.slice(1)}`}</OverflowText>
									) : waterfallPeer ? (
										<Tooltip title={getTooltip(waterfallPeer, tooltipData)} placement="right">
											<span
												onMouseEnter={() => {
													onTooltipLeave(waterfallPeer.stockId);
													onTooltipEnter(waterfallPeer.stockId);
												}}
											>
												{peer.companyName}
											</span>
										</Tooltip>
									) : (
										<OverflowText>{peer.companyName}</OverflowText>
									)}
								</Cell>
								{peer?.data.map((obj, pIdx) => (
									<Cell key={obj.expectedTermsInYears} border={{ bottom: !isLast, right: true }} style={isTotal ? { fontWeight: 500 } : {}}>
										{!isTotal && obj.expectedTermsInYears === timeToLiquiity && (
											<span className={obj.volatility < (average?.data[pIdx].volatility ?? 0) ? 'arrow-down' : 'arrow-up'}></span>
										)}
										{getPercentrage(obj.volatility)}
									</Cell>
								))}
								<Cell
									border={{ bottom: !isLast }}
									style={{ borderBottomRightRadius: isLast ? 16 : 0, fontWeight: isTotal ? 500 : 400, position: 'relative' }}
								>
									{!isTotal && !isDeleting[peer.identitifier] && (
										<Image
											src={IC_TRASH2}
											data-qaid="VolatilityTable.Button.Delete"
											className="pointer"
											width="2rem"
											tooltip="Delete"
											onClick={async () => {
												setIsDeleting((prev) => ({ ...prev, [peer.identitifier]: true }));
												await onDelete(peer.identitifier);
												setIsDeleting((prev) => ({ ...prev, [peer.identitifier]: false }));
											}}
										/>
									)}
									{isDeleting[peer.identitifier] && <Spinner size={14} incorporated attachParent />}
								</Cell>
							</Row>
						);
					})}
				</Table>
			</div>
		</Fade>
	);
};

export default VolatilityTable;
