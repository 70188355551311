import { NumberOption } from '../../../Models/API/All/NumberOption';
import { IndustryEnum } from '../../../Models/App/Valuation/industry-enum';
import { SectorEnum } from '../../../Models/App/Valuation/sector-enum';

export const industriesLabels: Record<keyof typeof IndustryEnum, string> = {
	TECNO: 'Technology',
	TELCM: 'Telecommunications',
	HLTHC: 'Health Care',
	FINAN: 'Financials',
	RLEST: 'Real Estate',
	CODIS: 'Consumer Discretionary',
	COSTP: 'Consumer Staples',
	INDUS: 'Industrials',
	BMATR: 'Basic Materials',
	ENEGY: 'Energy',
	UTILS: 'Utilities',
};

export const sectorLabels: Record<keyof typeof SectorEnum, string> = {
	SFTCS: 'Software and Computer Services',
	TECHD: 'Technology Hardware and Equipment',
	TELEQ: 'Telecommunications Equipment',
	TELSP: 'Telecommunications Service Providers ',
	HLTPR: 'Health Care Providers',
	MEDES: 'Medical Equipment and Services',
	PHARM: 'Pharmaceuticals and Biotechnology',
	BANKS: 'Banks',
	FINCS: 'Finance and Credit Services',
	INVBB: 'Investment Banking and Brokerage Services ',
	RITMG: 'Mortgage Real Estate Investment Trusts',
	CEINV: 'Closed End Investments',
	OMINV: 'Open End and Miscellaneous Investment Vehicles',
	LFINS: 'Life Insurance',
	NLINS: 'Non-life Insurance',
	RLISV: 'Real Estate Investment and Services Development',
	REITS: 'Real Estate Investment Trusts',
	AUTMB: 'Automobiles and Parts',
	CNSVS: 'Consumer Services ',
	HHOLD: 'Household Goods and Home Construction',
	LEISG: 'Leisure Goods',
	PERSG: 'Personal Goods',
	MEDIA: 'Media',
	RTAIL: 'Retailers ',
	TRLES: 'Travel and Leisure',
	BEVES: 'Beverages',
	FOODS: 'Food Producers',
	TOBAC: 'Tobacco',
	PCDGS: 'Personal Care, Drug and Grocery Stores ',
	CNSTM: 'Construction and Materials ',
	AERSP: 'Aerospace and Defense',
	ELTNC: 'Electronic and Electrical Equipment',
	GNIND: 'General Industrials',
	INDEN: 'Industrial Engineering',
	SUPSV: 'Industrial Support Services',
	INDTR: 'Industrial Transportation',
	INMAT: 'Industrial Materials',
	INDMT: 'Industrial Metals and Mining',
	PRCMM: 'Precious Metals and Mining',
	CHMCL: 'Chemicals',
	OILGC: 'Oil, Gas and Coal',
	ALTEN: 'Alternative Energy',
	ELECT: 'Electricity',
	GWMUT: 'Gas, Water and Multi-utilities ',
	WASTE: 'Waste and Disposal Services',
};
