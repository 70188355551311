import Flex from "../Layout/Flex"
import OverflowText from "../OverflowText"
import InputWrapper from "./InputWrapper"

interface ViewOnlyProps {
    value: any
    label: string
}

const ViewOnlyBox = (props: ViewOnlyProps) => {
    return (
        <InputWrapper qaid="" label={props.label}>
            <Flex height="3.6rem" align="end" justify="start">
                <OverflowText>{props.value || "---------"}</OverflowText>
            </Flex>
        </InputWrapper>
    )
}

export default ViewOnlyBox