import classNames from "classnames";
import { IMG_LOGO_NAME } from "../../Assets";
import AccountLoginOTP from "./Components/AccountForms/AccountLoginOTP";
import useScreenSize from "../../Shared/Hooks/useScreenSize";
import { Link } from "react-router-dom";
import { Routes } from "../../Routes";
import { isTablet } from "react-device-detect";

export const AccountLoginOtpScreen = () => {
	const { isMobile } = useScreenSize();

	return (
		<section className={classNames("account-bg-login-page account-pages login", { mobile: isMobile })}>
			<Link
				to={Routes.account.login}
				className={classNames("alt-logo", {
					tablet: isTablet,
					mobile: isMobile,
				})}
			>
				<img src={IMG_LOGO_NAME} alt="brand logo" />
			</Link>
			<div
				className={classNames({
					"form-login-container": true,
					mobile: isMobile,
					tablet: isTablet,
				})}
			>
				<AccountLoginOTP />
			</div>
		</section>
	);
};

export default AccountLoginOtpScreen;
