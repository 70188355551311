import styled from "@emotion/styled";
import appConfig from "../../../config/config";

export interface CardProps extends Omit<React.HTMLAttributes<HTMLElement>, "align" | "children"> {
	children: React.ReactChild | React.ReactChild[] | false | Element;
}

const Card = styled.div(() => ({
	label: "Card",
	background: "#ffffff",
	boxShadow: appConfig.style.boxShadow.card1,
	borderRadius: 4,
	position: "relative",
}));

export default Card;
