import { css } from "@emotion/css";
import appConfig from "../../../config/config";
import { breakpoints } from "../../../config/style/theme";

const BlackAndScholesStyle = css({
    label: "B&S Parameters",
    maxWidth: "135rem",
    paddingInline: "4rem",
    width: "100%",
    marginInline: "auto",
    paddingTop: "4rem",
    paddingBottom: "4rem",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    "&__label": {
        display: "flex",
        alignItems: "center",
        gap: "2rem",
        fontSize: "2.5rem",
        fontWeight: 500,
    },
});

export default BlackAndScholesStyle;
