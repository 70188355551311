import { SubTransaction, TransactionConsideration, TransactionDocument, TransactionInfo, TransactionNotification } from '../Models/API/ShareRep';
import { BaseService } from './BaseService';

export class ShareRepService extends BaseService {
	private endPoints = {
		getTransactionInfo: (tranie_id : number) => `PersonalPage/transactions/${tranie_id}`,
        getTransactionDocs: (tranie_id : number) => `PersonalPage/transactions/${tranie_id}/documents`,
        getTransactionDoc: (tranie_id : number, doc_id : number) => `PersonalPage/transactions/${tranie_id}/documents/${doc_id}`,
        getTransactionNotifications: (tranie_id : number, after? : number) => `PersonalPage/transactions/${tranie_id}/notifications${after ? `?after=${after}` : ''}`,
        getTransactionSubs: (tranie_id : number) => `PersonalPage/transactions/${tranie_id}/subtransactions`,
        getTransactionConsiderations: (tranie_id : number) => `PersonalPage/transactions/${tranie_id}/considerations`
    }

    async getTransactionInfo(tranie_id : number) {
        return this.safeExecuteAsync(async () => {
            const response = await this.httpShareRep.get<TransactionInfo>(this.endPoints.getTransactionInfo(tranie_id));
            return response.data;
        })
    }

    async getTransactionDocs(tranie_id : number) {
        return this.safeExecuteAsync(async () => {
            const response = await this.httpShareRep.get<TransactionDocument[]>(this.endPoints.getTransactionDocs(tranie_id));
            return response.data;
        })
    }

    async getTransactionDoc(tranie_id : number, doc_id : number) {
        return this.safeExecuteAsync(async () => {
            const response = await this.httpShareRep.get<string>(this.endPoints.getTransactionDoc(tranie_id, doc_id));
            return response.data;
        })
    }

    async getTransactionNotifications(tranie_id : number, after? : number) {
        return this.safeExecuteAsync(async () => {
            const response = await this.httpShareRep.get<TransactionNotification[]>(this.endPoints.getTransactionNotifications(tranie_id, after));
            return response.data;
        })
    }

    async getSubTransactions(tranie_id : number) {
        return this.safeExecuteAsync(async () => {
            const response = await this.httpShareRep.get<SubTransaction[]>(this.endPoints.getTransactionSubs(tranie_id));
            return response.data;
        })
    }

    async getTransactionConsiderations(tranie_id : number) {
        return this.safeExecuteAsync(async () => {
            const response = await this.httpShareRep.get<TransactionConsideration[]>(this.endPoints.getTransactionConsiderations(tranie_id));
            return response.data;
        })
    }
}
