import { ClickAwayListener, Fade, Tooltip } from "@mui/material";
import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { IC_ARROW, IC_CONTACT_US, IC_EXIT, IC_EXIT_DARK, IC_GLOBE, IC_INVITE, IC_USER_PROFILE_PURPLE } from "../../../../Assets";
import useRootStore from "../../../Hooks/useRootStore";
import { copyToClipboard } from "../../../Utilities";
import { useTranslation } from "react-i18next";
import i18n from "../../../Middleware/i18n";
import UserMenuStyle from "./UserMenu.Style";
import { Routes } from "../../../../Routes";

type Lang = {
    value: string;
    label: string;
};

const AppLanguages: Lang[] = [
    { value: "en-us", label: "English" },
    { value: "fr-fr", label: "French" },
];

interface Props {
    onHide: (e?: MouseEvent | TouchEvent) => void;
    show: boolean;
}

const UserMenu = ({ onHide, show }: Props) => {
    const { auth } = useRootStore();
    const { t } = useTranslation();
    const [isCopied, setIsCopied] = useState<boolean>(false);
    const [showLangSelection, setShowLangSelection] = useState<boolean>(false);

    const selectedLang = useMemo(() => {
        return AppLanguages.find((x) => x.value?.toLowerCase() === i18n?.resolvedLanguage?.toLowerCase());
    }, [i18n?.resolvedLanguage]);

    useEffect(() => {
        isCopied && setTimeout(() => setIsCopied(false), 1000);
    }, [isCopied]);

    return (
		// <ClickAwayListener onClickAway={onHide}>
		<Fade unmountOnExit in={show}>
			<div className={UserMenuStyle} onClick={(e) => e.stopPropagation()}>
				<div className={`${UserMenuStyle}__item profile view-only`}>
					<div className={`${UserMenuStyle}__clickable`}>
						<div className={`${UserMenuStyle}__img-wrapper bigger`}>
							<img width="50px" height="50px" className="ic" src={IC_USER_PROFILE_PURPLE} alt="" />
						</div>
						<div className={`${UserMenuStyle}__user-details`}>
							<div className="name">{auth.isUserActive ? `${auth.user.firstName} ${auth.user.lastName}` : t('account.guest')}</div>
							{auth.isUserActive && (
								<Tooltip id="tooltip-user_email" placement="bottom" title={isCopied ? t('general.copiedToClipboard') : auth.userEmail ?? ''}>
									<div
										className="email"
										onClick={() => {
											copyToClipboard(auth.userEmail);
											setIsCopied(true);
										}}
									>
										{auth.userEmail}
									</div>
								</Tooltip>
							)}
						</div>
					</div>
				</div>
				{/* <div className={`${UserMenuStyle}__item`}>
					<div className={`${UserMenuStyle}__clickable`} onClick={() => setShowLangSelection(!showLangSelection)}>
						<div className={`${UserMenuStyle}__img-wrapper`}>
							<img className="ic" src={IC_GLOBE} alt="" />
						</div>
						<div className="content">
							<span>Language - {AppLanguages.find((x) => x.value?.toLowerCase() === i18n?.resolvedLanguage?.toLowerCase())?.label}</span>
							<img className={`ic arrow ${showLangSelection ? "rotate-180" : ""}`} src={IC_ARROW} alt="" />
						</div>
					</div>
					{showLangSelection && (
						<div className={`${UserMenuStyle}__sub-menu`}>
							{AppLanguages.map((lang) => (
								<div
									className={`${UserMenuStyle}__sub-menu-item ${selectedLang?.value?.toLowerCase() === lang.value?.toLowerCase() ? "selected" : ""
										}`}
									onClick={(e) => {
										// e.stopPropagation();
										onHide();
										i18n.changeLanguage(lang?.value);
										// emptyCache();
									}}
									key={lang.value}
								>
									{lang.label}
								</div>
							))}
						</div>
					)}
				</div> */}
				<a
					href={Routes.contactUs.external}
					target="_blank"
					rel="noreferrer"
					className={`${UserMenuStyle}__clickable ${UserMenuStyle}__contactUs`}
					onClick={() => onHide()}
				>
					<div className={`${UserMenuStyle}__img-wrapper`}>
						<img className="ic" src={IC_INVITE} alt="contact us" />
					</div>
					<div className="content">Contact Us</div>
				</a>
				{auth.isUserActive && (
					<div
						className={`${UserMenuStyle}__item`}
						onClick={() => {
							auth.logOut();
							console.log(`%cLogging out by click`, 'background: red; padding: 4px; color: white; font-weight: bold; font-size: 20px');
						}}
					>
						<div className={`${UserMenuStyle}__clickable`}>
							<div className={`${UserMenuStyle}__img-wrapper`}>
								<img className="ic" src={IC_EXIT_DARK} alt="" />
							</div>
							<div className="content">{t('loginAndRegister.signOut')}</div>
						</div>
					</div>
				)}
				<div className={`${UserMenuStyle}__item disabled`}>
					<div className={`${UserMenuStyle}__clickable`}>
						<div className="content">V{process.env.REACT_APP_VERSION}</div>
					</div>
				</div>
			</div>
		</Fade>
		// </ClickAwayListener>
	);
};

export default UserMenu;
