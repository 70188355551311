import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { useState } from "react";

interface StripeElementsProps {
	currency?: number;
	children: any;
}

export const StripeElements = (props: StripeElementsProps) => {
	const [stripePromise] = useState(() => loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_US || ""));

	return <Elements stripe={stripePromise}>{props.children}</Elements>;
};
