import { css } from "@emotion/css";
import { breakpoints } from "../../../../config/style/theme";
import appConfig from "../../../../config/config";

export const BillingInfoStyle = css({
	label: 'BillingInfo',
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	borderRadius: '16px',
	border: `1px solid ${appConfig.style.colors.table}`,
	backgroundColor: '#FFF',
	padding: '4rem',
	flex: 1,
	filter: 'drop-shadow(3px 3px 3px rgba(201, 202, 208, 0.40))',

	'&__title': {
		fontWeight: 'bold',
		color: '#4D4D4D',
		marginBottom: '3.7rem',
	},

	'&__editButton': {
		position: 'absolute',
		top: '-2rem',
	},

	'.formContainer': {
		flexDirection: 'column',

		'&__title': {
			display: 'none',
		},

		'.firstInputRow': {
			justifyContent: 'space-between',
			width: '100%',
			gap: '1.9rem',
			flex: 0,
		},

		'.mobileAddressLabel': {
			display: 'none',
		},

		'.secondInputRow': {
			justifyContent: 'space-between',
			width: '100%',
			gap: '1.9rem',
			flex: 0,
			marginTop: '4rem',
		},
	},

	'.buttonsContainer': {
		justifyContent: 'flex-end',
		gap: '1.8rem',
		flex: 1,
		marginTop: '4rem',
	},

	[`@media screen and (max-width: ${breakpoints.xs})`]: {
		flexDirection: 'column',
		padding: '2rem',
		filter: 'none',

		'&__title': {
			marginBottom: '0.9rem',
		},

		'.formContainer': {
			position: 'absolute',
			backgroundColor: '#F5F5FD',
			alignItems: 'start',
			left: '0',
			top: '6rem',
			height: 'calc(100% - 6rem)',
			paddingInline: '1.9rem',
			zIndex: 10,

			'&__title': {
				display: 'block',
				fontWeight: 'bold',
				color: '#4D4D4D',
				margin: '6.2rem 0 4.5rem 0',
			},

			'.firstInputRow': {
				flexDirection: 'column',
				justifyContent: 'space-between',
				width: '100%',
				gap: '1.9rem',
				flex: 0,
			},

			'.mobileAddressLabel': {
				display: 'block',
				color: '#979DB5',
				fontSize: '1.3rem',
				margin: '1.7rem 0 0.5rem 0.3rem',
			},

			'.secondInputRow': {
				flexDirection: 'column',
				gap: '0rem',
				backgroundColor: '#FFF',
				borderRadius: '8px',
				border: '1px solid #BAC0D9',
				margin: '0 0 3.7rem 0',

				'.borderNone': {
					border: 'none',
				},

				'.borderTopBottom': {
					borderTop: '1px solid #BAC0D9',
					borderBottom: 'px solid #BAC0D9',
					borderRight: 'none',
					borderLeft: 'none',
					borderRadius: '0px',
				},

				'.borderRightBottom': {
					borderRight: '1px solid #BAC0D9',
					borderTop: 'none',
					borderLeft: 'none',
					borderBottom: '1px solid #BAC0D9',
					borderRadius: '0px',
				},

				'.borderRight': {
					borderRight: '1px solid #BAC0D9',
					borderTop: 'none',
					borderLeft: 'none',
					borderBottom: 'none',
					borderRadius: '0 0 0 18px',
				},
				'.borderTop': {
					borderTop: '1px solid #BAC0D9',
					borderRight: 'none',
					borderLeft: 'none',
					borderBottom: 'none',
					borderRadius: '0 0 18px 0',
				},
			},
		},

		'.buttonsContainer': {
			justifyContent: 'space-between',

			'&__button': {
				width: '45%',
				height: '4rem',
			},
		},
	},
});
