import { useState } from "react"
import Button from "../../../../../Shared/Components/Button/Button"
import { ModalBodyProps } from "../../../../../Shared/Components/Modal/types"
import { TrusteeBeneficiaries } from "../../../Types/trusteeTypes"
import useRootStore from "../../../../../Shared/Hooks/useRootStore"
import DatePickerInput from "../../../../../Shared/Components/Input/DatePickerInput"
import TextInput from "../../../../../Shared/Components/Input/TextInput"
import { BeneficiaryCell } from "../../BeneficiaryCell/BeneficiaryCell"
import { TextAreaInput } from "../../../../../Shared/Components/Input/Input.Style"

import TerminationStyle from './TerminationRequest.module.css'

interface TerminationProps extends ModalBodyProps {
    benData: TrusteeBeneficiaries
}

const TerminationRequest = (props: TerminationProps) => {

    const { trusteeStore } = useRootStore()

    const [remarks, setRemarks] = useState('')
    const [selectedDate, setSelectedDate] = useState<Date>(new Date)
    const [transferring, setTransferring] = useState(false)
    const [proccessFailed, setProccessFailed] = useState(false)

    const beneficiaryTerminationHandler = async () => {
        setTransferring(true)
        console.log(`Terminating employee ${props.benData.beneficiaryId} x`)

        const res = await trusteeStore.BeneficiaryTerminationRequest(remarks, props.benData.beneficiaryId, selectedDate)

        console.log(`Termination RES ${res}`, res)

        if (!res.data?.status) {
            setProccessFailed(true)
            setTransferring(false)
            return
        }

        trusteeStore.GetTrusteeBeneficiariesByParams({ PageNumber: 1, ItemInPage: 5 })
        props.removeModal && props.removeModal()
    }

    return (
        <div style={TerminationStyle} className={TerminationStyle.terminationContainer}>


            <div className={TerminationStyle.header}>
                <h3>Termination request </h3>
                <BeneficiaryCell data={props.benData.fullName} fullRow={props.benData} />
            </div>

            <div className={TerminationStyle.inputs}>
                <div style={{ padding: '1rem' }}>
                    <DatePickerInput
                        label="Termination date"
                        qaid="trustee.terminationRequest.date"
                        value={selectedDate}
                        onChange={e => e && setSelectedDate(e)}
                    />
                </div>
                <div style={{ padding: '1rem' }}>
                    <h3 className={TerminationStyle.label}>
                        Remarks
                    </h3>
                    <TextAreaInput
                        style={{ resize: "none", width: "200%", borderColor: '#8b96e9' }}
                        value={remarks}
                        onChange={e => setRemarks(e.target.value)}
                        data-qaid="trustee.terminationRequest.remarks"
                        maxLength={200}
                        rows={4}
                        placeholder="Type your comment here..."
                    />
                </div>
            </div>

            <div className={TerminationStyle.buttons}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {proccessFailed && <p style={{ color: 'red' }}>Process failed</p>}
                    <Button
                        label="Submit"
                        qaid="trustee.terminationRequest.yes"
                        onClick={beneficiaryTerminationHandler}
                        isLoading={transferring}
                    />
                </div>
            </div>

        </div>
    )
}

export default TerminationRequest