import { IContactCreate } from "../../../../Models/API/Contact/contact";
import { InputRefs, useFormValidation } from "../../../../Shared/Hooks/useFormValidation";
import { commonValidators } from "../../../../Shared/ObjectValidator";
import { isNullOrUndefined } from "../../../../Shared/Utilities";

const useShareholderValidation = (
    contact: IContactCreate,
    inputRefs: InputRefs<IContactCreate> = {} as InputRefs<IContactCreate>,
    emailRequired: boolean = false,
    isOrganizationNameRequired: boolean = false,
    deps: any[] = []
) => {
    const { isFormValid, formErrors, formValidationState, setFormValidationState, validateForm, clearErrors } = useFormValidation({
        form: contact,
        schema: {
            companyName: [commonValidators.requiredIf(() => !!contact.isLegalEntity || isOrganizationNameRequired), commonValidators.minLength(2)],
            firstName: [
                commonValidators.requiredIf(() => emailRequired || !!(emailRequired && contact.isLegalEntity)),
                commonValidators.regex(/^[a-z .-]*$/i),
                commonValidators.minLength(2),
            ],
            lastName: [
                commonValidators.requiredIf(() => emailRequired || !!(emailRequired && contact.isLegalEntity)),
                commonValidators.regex(/^[a-z .-]*$/i),
                commonValidators.minLength(2),
            ],
            // employeeNumber: [
            // 	commonValidators.requiredIf(() => employeeNumberRequired),
            // 	commonValidators.lengthRange(employeeNumberRequired ? 1 : 0, 100, "Insert at least one character"),
            // 	commonValidators.regex(/^[a-zA-Z0-9]*$/),
            // ],
            email: [commonValidators.requiredIf(() => emailRequired || !!(emailRequired && contact.isLegalEntity)), commonValidators.emailValidator],
            mobile: [(v) => {
                const isValid = (!v && !contact.mobilePrefix) || !!(v && contact.mobilePrefix);
                return {
                    isValid,
                    message: isValid ? "" : "Mobile number is incorrect"
                }
            }]
            // id: [commonValidators.lengthRange(2, 15, "Insert at least two characters")],
            // contactDetailsDto: [commonValidators.customValidation((contact.contactDetailsDto?.idNumber?.length ?? 0) > 2, "Error!")],
        },
        refs: inputRefs,
        deps,
    });

    return {
        isFormValid,
        formErrors,
        formValidationState,
        setFormValidationState,
        validateForm,
        clearErrors,
    };
};

export default useShareholderValidation;
