import classNames from "classnames";
import { checkQaidConvention } from "../../Utilities";
import Flex, { IFlexProps } from "../Layout/Flex";
import { css } from "@emotion/css";
import appConfig from "../../../config/config";
import { forwardRef } from "react";
import Tooltip from "../Tooltip";

interface Props extends IFlexProps {
	qaid: string;
	children: React.ReactChild | React.ReactChild[] | false | Element;
	applyStyle?: boolean;
	tooltip?: string;
	disabled?: boolean;
}

const Styles = css({
	label: "Clickable",
	cursor: "pointer",
	"&.link": {
		color: appConfig.style.colors.color1,
		textDecoration: "underline",
	},


});



const Clickable = forwardRef<HTMLDivElement, Props>(({ qaid, children, applyStyle = false, className = "", tooltip, disabled, ...props }, forwardedRef) => {
	if (!children) return <></>;
	checkQaidConvention(qaid);

	if (tooltip)
		return (
			<Tooltip title={tooltip}>
				<Flex
					data-qaid={qaid}
					{...props}
					margin={props.margin ?? 0}
					basis={props.basis ?? "content"}
					className={classNames(Styles, {
						[className]: !!className,
						link: applyStyle,
					disabled
					
					})}
					ref={forwardedRef}
					
					
				>
					{children}
				</Flex>
			</Tooltip>
		);

	return (
		<Flex
			data-qaid={qaid}
			{...props}
			margin={props.margin ?? 0}
			basis={props.basis ?? "content"}
			className={classNames(Styles, {
				[className]: !!className,
				link: applyStyle,
				disabled: disabled,
			})}
			ref={forwardedRef}
		>
			{children}
		</Flex>
	);
});

export default Clickable;
