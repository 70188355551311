import { Switch } from "@mui/material";
import { observer } from "mobx-react-lite";
import Button from "../../../../../Shared/Components/Button/Button";
import DatePckr from "../../../../../Shared/Components/Input/DatePickerInput";
import Input from "../../../../../Shared/Components/Input/NumberInput";
import Select from "../../../../../Shared/Components/Select/Select";
import useRootStore from "../../../../../Shared/Hooks/useRootStore";
import NewCancelationStyles from "./NewGrantCancelation.style";
// import { NumberOption } from "../../../../../Models/API/All/NumberOption";
import { NumberOption } from "../../../../../Models/API/All/NumberOption";
import { CancelDateType, CancelType, CancelationReason, IGrantCancelation } from "../../../../../Models/App/EquityPlans/GrantCancelations";
import { isNullOrUndefined, isNumber } from "../../../../../Shared/Utilities";
import { cancelationReason, cancelationType, cancelationUnit } from "../../../../../Shared/StaticData/equityPlans";
import { useEffect, useState } from "react";
import { ModalBodyProps } from "../../../../../Shared/Components/Modal/types";
import useEsopModals from "../../../useEsopModals";
import { toJS } from "mobx";
import Title from "../../../../../Shared/Components/Layout/Title";
import Flex from "../../../../../Shared/Components/Layout/Flex";

interface CnclProps extends ModalBodyProps {
	showGrant?: boolean;
	showBen?: boolean;
	grantId?: number;
	beneficiaryId?: number;
	qaTermination?: boolean;
	onSave?: () => unknown;
}

const NewGrantCancelation = (props: CnclProps) => {
	const { equityPlansStore } = useRootStore();
	const { showSuccessModal, showErrorModal } = useEsopModals();

	const [orderFrom, setOrderFrom] = useState<"grntCard" | "benCard" | "grntQA" | "benQA" | "cnclTable">();

	const [grntId, setGrntId] = useState<number>();
	const [benId, setBenId] = useState<number>();
	const [minDate, setMinDate] = useState<Date>()

	console.log("cancewlation props", props);

	const cancelationReasons = cancelationReason.filter((rsn) => {
		if (props.qaTermination) {
			return (
				rsn.value === CancelationReason.Death ||
				rsn.value === CancelationReason.Resignation ||
				rsn.value === CancelationReason.Retirment ||
				rsn.value === CancelationReason.Termination
			);
		} else {
			return rsn.value !== CancelationReason.Default;
		}
	});

	const gCancel = equityPlansStore.newGrantCancelation;

	// if (props.grantId) gCancel.grantId = props.grantId;
	// if (props.beneficiaryId) gCancel.contactId = props.beneficiaryId;

	const grants = equityPlansStore.beneficiaryGrants?.map((grt) => {
		// console.log("Cancelations equityPlansStore.beneficiaryGrants", toJS(equityPlansStore.beneficiaryGrants));
		return { label: grt.grantNumber, value: grt.grantId };
	});

	// const bnfris = equityPlansStore.beneficiariesData?.map((ben) => {
	// 	console.log("Cancelations equityPlansStore.beneficiariesData", toJS(equityPlansStore.beneficiariesData));
	// 	return { label: `${ben.firstName} ${ben.lastName}`, value: ben.beneficiaryId };
	// });

	const beneficiarySelectHandler = (benId: number) => {
		equityPlansStore.GetGrantsForBeneficiary(benId);
		gCancel.contactId = benId;
		if (orderFrom === "cnclTable") {
			setBenId(benId);
		}
	};

	const finishCancelationOrder = (beneficiaryId?: number) => {
		showSuccessModal();

		switch (orderFrom) {
			case "grntQA":
				equityPlansStore.GetGrantsForCompany();
				break;
			case "benQA":
				equityPlansStore.GetBeneficiariesDashboardData();
				break;
			case "benCard":
				if (!beneficiaryId) return;
				equityPlansStore.GetBeneficiariesDashboardData();
				equityPlansStore.GetCancelationsForBeneficiary(beneficiaryId);
				break;
			case "cnclTable":
				equityPlansStore.GetGrantCancelationsForCompany();
				break;
			case "grntCard":
				if (!benId || !grntId) return;
				equityPlansStore.GetCancelationOrdersForSingleGrant(benId, grntId);
				equityPlansStore.GetGrantsForCompany();
				break;
		}
	};

	console.log("props.beneficiaryId, props.grantId, props.showBen, props.showGrant", props.beneficiaryId, props.grantId, props.showBen, props.showGrant);

	const cancelGrantHandler = async () => {
		console.log("canceling grant", toJS(equityPlansStore.newGrantCancelation), "ben, grant", benId, grntId);

		if (orderFrom === "benQA") {
			console.log("terminating employee comp", benId);
			if (!benId) return;
			const res = await equityPlansStore.TerminateEmployeeQa(benId);
			console.log("terminate response ", res);
			if (res?.data && res.data[0]?.grantId) {
				showSuccessModal("The termination request was added successfully");
				finishCancelationOrder();
			} else {
				showErrorModal(res)
			}
		} else if (orderFrom === "benCard") {
			if (!grntId) return;

			const res = await equityPlansStore.CancelSingleGrant(grntId);
			if (res?.data && res.data[0]?.contactId) {
				finishCancelationOrder(res.data[0].contactId);
				props.removeModal && props.removeModal();
				showSuccessModal("The grant cancellation was added successfully");
			} else {
				showErrorModal(res);
			}
		} else {
			if (!benId || !grntId) return;

			const res = await equityPlansStore.CancelSingleGrant(grntId, benId);
			if (res?.data && res.data[0]?.grantId) {
				finishCancelationOrder();
				props.removeModal && props.removeModal();
				showSuccessModal("The grant cancellation was added successfully");
			} else {
				showErrorModal(res);
			}
		}
	};

	const defineOrderFrom = () => {
		if (!isNullOrUndefined(props.qaTermination)) {
			setOrderFrom("benQA");
		} else if (props.beneficiaryId && props.grantId) {
			setOrderFrom("grntQA");
		} else if (props.showGrant && !props.showBen) {
			setOrderFrom("benCard");
		} else if (!props.qaTermination && !props.showGrant && !props.showBen) {
			setOrderFrom("grntCard");
		} else if (props.showGrant && props.showBen) {
			setOrderFrom("cnclTable");
		}
	};

	console.log("Cancelation order FROM: ", orderFrom);

	const getGrantDate = () => {
		const grantId = props.grantId ?? grntId
		if (!grantId) return
		const date = equityPlansStore.companyGrants?.filter(grnt => grnt.grantId === grantId)[0].grantDate
		if (typeof date == 'undefined') return
		setMinDate(date)
	}

	const mountCard = () => {
		if (isNullOrUndefined(orderFrom)) defineOrderFrom();
		// console.log('orderFrom', orderFrom)
		// console.log('benId, grant', benId, grant)

		switch (orderFrom) {
			case "benCard":
				setBenId(equityPlansStore.beneficiaryData.beneficiaryId);
				break;
			case "benQA":
				setBenId(props.beneficiaryId);
				break;
			case "grntCard":
				setGrntId(equityPlansStore.beneficiaryGrantData.grantId);
				setBenId(equityPlansStore.beneficiaryGrantData.beneficiaryId);
				break;
			case "grntQA":
				setGrntId(props.grantId);
				setBenId(props.beneficiaryId);
				break;
			case "cnclTable":
				// if (!equityPlansStore.companyBeneficiaries) { equityPlansStore.GetBeneficiariesDashboardData(); };
				break;
		}
		getGrantDate()
	};

	const grantChangeHandler = (grntId: number) => {
		console.log("setting grant Id", grntId);
		gCancel.grantId = grntId;
		setGrntId(grntId);
		if (orderFrom === "cnclTable") {
			setGrntId(grntId);
		}
	};

	useEffect(() => {
		getGrantDate()
	}, [grntId])

	useEffect(() => {
		mountCard();
	}, [orderFrom]);

	useEffect(() => {
		mountCard();

		return () => {
			// resetCard();
			equityPlansStore.newGrantCancelation = {} as IGrantCancelation;
		};
	}, []);

	return (
		<div className={NewCancelationStyles}>
			{/* <h3>Grant Cancelation Wizard</h3> */}
			<h1 className="mb-5">Add Grant Cancelation</h1>

			<div className={`${NewCancelationStyles}__form`}>
				{!props.qaTermination ||
					(props.showGrant && props.grantId && (
						<div className="cancell-all-switch">
							<p>cancell a single grant</p>
							<Switch checked={gCancel.isMany} onClick={() => (gCancel.isMany = !gCancel.isMany)} />
							<p>cancell all grants for beneficiary</p>
						</div>
					))}

				{!gCancel.isMany &&
					(props.showBen && props.showGrant ? (
						<>
							<Select
								label="Beneficiary"
								options={equityPlansStore.beneficiariesList ?? []}
								value={gCancel.contactId}
								onChange={(e) => beneficiarySelectHandler(+e)}
								qaid="NewCancelation.Select.Beneficiary"
							/>
							<Select
								label="Grant"
								options={equityPlansStore.beneficiaryGrantList ?? []}
								value={gCancel.grantId}
								onChange={(e) => isNumber(e) && grantChangeHandler(e)}
								qaid="NewCancelation.Select.Grant"
							/>
						</>
					) : (
						props.showGrant && (
							<Select
								label="Grant"
								options={grants ?? []}
								value={gCancel.grantId}
								onChange={(e) => isNumber(e) && grantChangeHandler(e)}
								qaid="NewCancelation.Select.Grant"
								disabled={!!props.grantId}
							/>
						)
					))}
				{gCancel.isMany && props.showBen && (
					<Select
						label="Beneficiary"
						options={equityPlansStore.beneficiariesList ?? []}
						value={gCancel.contactId}
						onChange={(e) => (gCancel.contactId = e as number)}
						qaid="NewCancelation.Select.Beneficiary"
					/>
				)}

				<Select
					label="Cancelation reason"
					options={cancelationReasons}
					value={gCancel.cancelationReason}
					onChange={(e) => (gCancel.cancelationReason = e as number)}
					qaid="NewCancelation.Select.Reason"
				/>
				<DatePckr
					label="Cancelation date"
					minDate={minDate}
					value={gCancel.cancelationDate as string}
					onChange={(e) => (gCancel.cancelationDate = e)}
					qaid="NewCancelation.Select.Date"
				/>

				<Select label="Canceling" options={cancelationType} value={gCancel.cancelType} onChange={(e) => (gCancel.cancelType = e as number)} qaid="" />

				{gCancel.cancelationReason === CancelationReason.DueToExercise || gCancel.cancelType === CancelType.VestedByAmount ? (
					<Input
						value={gCancel.amountToCancel}
						onChange={(e) => {
							gCancel.amountToCancel = e;
						}}
						qaid="NewCancelation.Input.Amount"
						label="Amount"
					/>
				) : (
					<Input
						label="Cancel in:"
						value={gCancel.gracePeriodNum}
						onChange={(e) => {
							gCancel.gracePeriodNum = e;
						}}
						qaid="NewCancelation.Input.GracePeriod"
						options={cancelationUnit}
						selectedValue={gCancel.gracePeriodType}
						onOptionSelect={(e) => {
							!isNullOrUndefined(e) && (gCancel.gracePeriodType = +e);
						}}
						ratio={0.4}
					// error={formValidationState?.amount?.message || formValidationState?.dateType?.message}
					// ref={(el: InputValidationRef) => (inputRefs.current[2] = el)}
					/>
				)}
			</div>

			<Flex className="buttons" justify="end" margin="2rem 0 0 0">
				<Button qaid="NewCancelation.Button.Add" label="Add" onClick={cancelGrantHandler} />
			</Flex>
		</div>
	);
};
export default observer(NewGrantCancelation);
