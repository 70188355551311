import styled from "@emotion/styled";

export const StyledDashboardWidget = styled.div`
    label: DashboardWidget;
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const StyledDashboardWidgetHeader = styled.header`
    label: DashboardWidgetHeader;
    display: flex;
    align-items: baseline;
    margin-bottom: 2rem;
`;

export const StyledDashboardWidgetTitle = styled.h3`
    label: DashboardWidgetTitle;
    font-size: 2.5rem;
    margin-right: 1rem;
    font-weight: 500;
`;

export const StyledDashboardWidgetSubtitle = styled.span`
    label: DashboardWidgetSubtitle;
    font-size: 1.2rem;
    color: #828282;
`;
