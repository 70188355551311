import { css } from "@emotion/css";
import { ReactElement } from "react";
import Image from "../Image";
import { IC_ARROW_ESOP } from "../../../Assets";
import Clickable from "../Clickable/Clickable";

export const Style = css({
    label: "DashboardText",
    display: "flex",
    alignItems: 'center',
    width: '100%',

    ".wrapText": {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10px',
        width: '100%',
    },

    ".title": {
        fontWeight: 700,
        fontSize: "1.4rem"
    },

    ".text": {
        whiteSpace: "nowrap",
        fontSize: "1.4rem"
    },
});

const DashboardText = ({ title, text, img, arrow }: { title: string; text?: string | ReactElement, img?: string, arrow?: { withArrow: boolean, tooltip: string, onClick?: () => void } }) => {
    return (
        <div className={Style}>
            <Image
                width="6rem"
                src={img}
                alt=""
            />
            <div className="wrapText">
                <span className="title text-ellipsis">{title}</span>
                <span className="text">{text ?? "---"}</span>
            </div>

            {
                arrow?.withArrow && <Clickable tooltip={arrow.tooltip} qaid="" onClick={arrow.onClick}>
                    <Image
                        src={IC_ARROW_ESOP}
                        alt=""
                        style={{ marginRight: '0px', marginLeft: 'auto' }}
                    />
                </Clickable>}
        </div>
    );
};

export default DashboardText;
