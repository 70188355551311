import styled from "@emotion/styled";
import { COLOR_BLUE_HAZE } from "../../../config/style/lib/colors";
import { Color } from "../../../config/types/style.types";
import { Spacing } from "../../../config/types/theme.types";
import { AltProps } from "../WidgetDashboard/shared/dependencies/types/types";
import { theme } from "../../../config/style/theme";

interface Props {
	isGradient?: boolean;
	color?: Color;
	spacing?: Spacing | null;
}

type HrProps = AltProps<Props> & React.HTMLAttributes<HTMLHRElement>;

const FancyHr = styled.div<HrProps>`
	label: FancyHorizontalRule;
	background: ${(props) => (props.ap.isGradient ? "linear-gradient(117deg, #8B96E9 26.54%, #F2AB64 49.21%, #F6818F 73.76%, #F5DC82 100%)" : "unset")};
	height: 3px;
	max-width: 45rem;
`;

const BasicHr = styled.hr<HrProps>`
	label: HorizontalRule;
	color: ${(props) => props.color || COLOR_BLUE_HAZE};
	margin-block: ${(props) => (props.ap.spacing ? theme.spacings[props.ap.spacing] : "unset")};
`;

const Hr: React.FC<HrProps> = (props) => {
	return props.ap.isGradient ? <FancyHr {...props} /> : <BasicHr {...props} />;
};

export default Hr;
