import { css } from '@emotion/css';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import { IC_EXCEL_EXPORT, IC_EYE_DARK, IC_REFRESH_PURPLE, IC_REFRESH_WHITE, IC_USER_CIRCLE } from '../../../../Assets';
import { ExpensingAwardType } from '../../../../Models/API/Expensing/award-type';
import { ExpenseReportStatus } from '../../../../Models/API/Expensing/expense-report';
import { GrantInfo } from '../../../../Models/API/Expensing/grant-info';
import Clickable from '../../../../Shared/Components/Clickable/Clickable';
import Image from '../../../../Shared/Components/Image';
import Menu from '../../../../Shared/Components/Menu';
import OverflowText from '../../../../Shared/Components/OverflowText';
import Spinner from '../../../../Shared/Components/Spinner/Spinner';
import Table, { TableColumn } from '../../../../Shared/Components/Table/Table';
import { Cell } from '../../../../Shared/Components/Table/Table.Style';
import useModal from '../../../../Shared/Hooks/useModal';
import useRootStore from '../../../../Shared/Hooks/useRootStore';
import { formatDate, formatDecimal, isNullOrUndefined } from '../../../../Shared/Utilities';
import appConfig from '../../../../config/config';
import { convertExpenseReport } from '../../helpers/mappers';
import { exportDisclosureReportToExcel, exportExpensingToExcel } from '../../helpers/util';
import GrantDetails from '../GrantDetails';
import useGeneralModal from '../../../../Shared/Hooks/useGeneralModal';
import { toJS } from 'mobx';
import { EmployeeStatus } from '../../../../Models/API/Expensing/employee-status-enum';

type Props = {
	grants: GrantInfo[] | undefined;
	expand?: boolean;
};

export const Style = css({
	label: 'ExpensingTable',
	// display: "flex",
	// flexDirection: "column",
	// position: "relative",
	padding: '4rem',

	'&__bg-total': {
		background: `${appConfig.style.colors.purple} !important`,
	},
	'.row-hover': {
		color: appConfig.style.colors.color1,
	},
	'&__custom-header': {
		width: 'calc(100% - 29.5rem)',
		display: 'flex',
		justifyContent: 'flex-end',
	},
	'&__recalculate-btn': {
		cursor: 'pointer',
		display: 'flex',
		color: appConfig.style.colors.color1,
		paddingInline: '1rem',
		height: '3.6rem',
		fontWeight: 500,
		border: `1px solid ${appConfig.style.colors.color1}`,
		borderRadius: 8,
		background: '#F9F9FF',
		alignItems: 'center',
		gap: '1rem',
		boxShadow: `1px 1px 1px rgba(217, 217, 217, 0.5)`,
		'.img-hover': {
			display: 'none',
		},
		'&.clicked': {
			opacity: 0.7,
			cursor: 'default',
		},
		'&:hover:not(.clicked):not(.disabled)': {
			transition: 'all .15s',
			background: appConfig.style.colors.color1,
			color: '#ffffff',
			'.img-hover': {
				display: 'unset',
			},
			'.img-normal': {
				display: 'none',
			},
		},
	},
});

const ExpensingTable = ({ grants, expand }: Props) => {
	const {
		expensingStore: { reportCurrency, updateReport, selectedReport, getExportToExcel, getDisclosureReport, reportData },
	} = useRootStore();
	const { showModal } = useModal();
	const { showErrorModal } = useGeneralModal();
	const [hoverId, setHoverId] = useState<number>(-1);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const tableColumn: TableColumn<GrantInfo>[] = [
		{
			name: 'grantAppId',
			label: 'Grant Number',
			sortable: true,
			isSearch: true,
		},
		{
			name: 'beneficaryName',
			label: 'Beneficiary',
			render(obj, value) {
				if (isNullOrUndefined(value)) return <></>;
				return (
					<Cell>
						<Image src={IC_USER_CIRCLE} width="3rem" />
						<OverflowText>{value}</OverflowText>
					</Cell>
				);
			},
			sortable: true,
			isSearch: true,
			defaultCellSize: '13rem',
		},
		{
			name: 'employeeStatus',
			label: 'Employee Status',
			format: (val) => EmployeeStatus[val],
			sortable: true,
			isSearch: true,
		},
		{
			name: 'grantType',
			label: 'Grant Type',
			sortable: true,
			isSearch: true,
			style: { flexGrow: 0.3 },
			format: (val) => ExpensingAwardType[val],
		},
		{
			name: 'grantDate',
			label: 'Grant date',
			format: (val) => formatDate(val),
			sortable: true,
			isSearch: true,
			style: { flexGrow: 0.8 },
		},
		{
			name: 'vestingStartDate',
			label: 'Vesting start date',
			format: (val) => formatDate(val),
			sortable: true,
			isSearch: true,
			style: { flexGrow: 0.8 },
		},
		{
			name: 'granted',
			label: 'Granted',
			format: (val) => formatDecimal(val),
			sortable: true,
			isSearch: true,
			style: { flexGrow: 0.3 },
		},
		{
			name: 'fairValue',
			label: 'B&S value',
			sortable: true,
			sortType: 'number',
			isSearch: true,
			isOverflow: true,
			style: { flexBasis: '11rem', width: '11rem', flexGrow: 0 },
			render(obj, value) {
				if (!obj.grantAppId) return;
				return (
					<>
						{reportCurrency?.symbol}
						{formatDecimal(value)}
						{hoverId === obj.grantAppId && (
							<Menu
								isHorizontal
								position={{ top: '0.4rem', right: '-200%' }}
								items={[
									{
										label: 'View',
										qaid: '',
										onClick: () => onViewGrantHandler(obj.grantAppId),
										icon: IC_EYE_DARK,
									},
								]}
							/>
						)}
					</>
				);
			},
		},
		{
			name: 'totalExpensePostForfeitures',
			label: 'Total expenses',
			sortable: true,
			sortType: 'number',
			isSearch: true,
			format: (val) => `${reportCurrency?.symbol}${formatDecimal(val)}`,
		},
		{
			name: 'amortizedExpenseAfter',
			label: 'Total Armotized Expenses',
			format: (val) => `${reportCurrency?.symbol}${formatDecimal(val)}`,
			sortable: true,
			isSearch: true,
			defaultCellSize: '13rem',
		},
		{
			name: 'amortizedExpenseBefore',
			label: 'Previous Armotized Expenses',
			format: (val) => `${reportCurrency?.symbol}${formatDecimal(val)}`,
			sortable: true,
			isSearch: true,
			defaultCellSize: '17rem',
		},
		{
			name: 'unamortizedExpenseAfter',
			label: 'Unarmotized Expense',
			format: (val) => `${reportCurrency?.symbol}${formatDecimal(val)}`,
			sortable: true,
			isSearch: true,
			defaultCellSize: '12rem',
		},
		{
			name: 'expenseForPeriod',
			label: 'Expense to Armotize for Period',
			format: (val) => `${reportCurrency?.symbol}${formatDecimal(val)}`,
			sortable: true,
			isSearch: true,
			defaultCellSize: '14rem',
			className: `${Style}__bg-total`,
		},
	];

	const onViewGrantHandler = (grantId: number) => {
		showModal({
			body: <GrantDetails grantId={grantId} />,
			width: '90%',
			maxWidth: '140rem',
			isFrameless: true,
			isMust: true,
		});
	};

	const onRecalculateHandler = async () => {
		if (isLoading || isNullOrUndefined(selectedReport) || selectedReport?.status === ExpenseReportStatus.Publish) return;

		setIsLoading(true);
		const res = await updateReport();
		if (res.errorMessage) {
			showErrorModal(res.errorMessage);
		}
		setIsLoading(false);
	};

	const grantsWithTotal = useMemo(() => {
		if (isNullOrUndefined(grants)) return grants;

		const total: GrantInfo = grants?.reduce(
			(acc, grant) => {
				acc.amortizedExpenseAfter += grant.amortizedExpenseAfter;
				acc.amortizedExpenseBefore += grant.amortizedExpenseBefore;
				acc.expenseForPeriod += grant.expenseForPeriod;
				acc.totalExpensePostForfeitures += grant.totalExpensePostForfeitures;
				// acc.fairValue += grant.fairValue;
				acc.unamortizedExpenseAfter += grant.unamortizedExpenseAfter;
				acc.granted += grant.granted;
				return acc;
			},
			{
				amortizedExpenseAfter: 0,
				amortizedExpenseBefore: 0,
				expenseForPeriod: 0,
				totalExpensePostForfeitures: 0,
				// fairValue: 0,
				unamortizedExpenseAfter: 0,
				granted: 0,
			} as GrantInfo
		);

		return grants.concat(total);
	}, [grants]);

	return (
		<div className={Style}>
			<Table
				rows={grantsWithTotal}
				columns={tableColumn}
				fullscreen
				customActionsRender={
					<Clickable
						flex={0}
						onClick={async () => {
							const res = await Promise.all([getExportToExcel(), getDisclosureReport()]);
							if (res[0]?.data?.data) exportExpensingToExcel(res[0]?.data?.data);
							if (res[1]?.data?.data) exportDisclosureReportToExcel(res[1]?.data?.data);
						}}
						disabled={isNullOrUndefined(selectedReport)}
						qaid="ExpensingTable.Button.Export"
					>
						<img src={IC_EXCEL_EXPORT} alt={`Grants (Expensing).xlsx`} />
					</Clickable>
				}
				showTotal
				isStickyHeader
				isStickyTotal
				rowSize={1.1}
				scrollAfterRows={expand ? 16 : 7}
				rowClassName={(grant) => (grant.grantAppId === hoverId ? 'row-hover' : '')}
				onRowEnter={(grant) => grant.grantAppId && setHoverId(grant.grantAppId)}
				onRowLeave={() => setHoverId(-1)}
				customHeaderRender={
					<div className={`${Style}__custom-header`}>
						<div
							className={classNames(`${Style}__recalculate-btn`, {
								clicked: isLoading || selectedReport?.status === ExpenseReportStatus.Publish,
								disabled: isNullOrUndefined(selectedReport),
							})}
							onClick={onRecalculateHandler}
						>
							<span>{isLoading ? 'Recalculating' : 'Recalculate'}</span>
							{isLoading ? (
								<Spinner size={15} incorporated className="flex" />
							) : (
								<>
									<Image width="1.6rem" src={IC_REFRESH_PURPLE} className="img-normal" />
									<Image width="1.6rem" src={IC_REFRESH_WHITE} className="img-hover" />
								</>
							)}
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default observer(ExpensingTable);
