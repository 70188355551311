import { css } from "@emotion/css";
import classNames from "classnames";
import { isNullOrUndefined } from "../../../../Shared/Utilities";
import { InfoProps } from ".";
import { renderInfo } from "../../helpers/utils";
import { PreviewStyle } from "./index.style";

const Style = css({
    label: "DocumentTooltip",
    display: "grid",
    gridTemplateColumns: "1.4fr 1fr",
    gridColumnGap: "2rem",
    padding: "1.2rem 0",
    gridRowGap: "1.2rem",
    "&.border-bottom": {
        borderBottom: `1px solid #ffffff`,
        // paddingBottom: "1.4rem",
        display: "grid",
        gridTemplateColumns: "1.4fr 1fr",
        gridColumnGap: "2rem",
    },
    "&:not(.border-bottom)": {
        // paddingTop: "1.2rem",
    },
});

const DocumentPreviewInfo = (data: InfoProps) => {
    if (isNullOrUndefined(data.info)) return <></>;

    const InfoEl = (
        <>
            <div className={classNames(Style, { "border-bottom": data.info.length > 2 })}>
                {data.info.slice(0, 2).map((info) => renderInfo(info))}
            </div>
            {data.info.length > 2 && <div className={Style}>{data.info.slice(2).map((info) => renderInfo(info))}</div>}
        </>
    );

    if (data.isTooltip)
        return (
            <div
                style={{
                    padding: "2rem",
                }}
            >
                {InfoEl}
            </div>
        );

    return <div className={PreviewStyle}>{InfoEl}</div>;
};

export default DocumentPreviewInfo;
