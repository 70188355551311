import { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { ForwardedRef } from "../../../../../../Shared/Hooks/useMultiStepForm";
import { CapTableImportStep } from "../../index.style";
import { css } from "@emotion/css";
import ImportCapTable from "../../ImportCapTable";
import { ImportContext } from "../../ImportContext";
import { CapTableImportColumnType } from "../../../../types";
import { isNullOrUndefined } from "../../../../../../Shared/Utilities";
import { Column } from "../../../../../../Models/App/CapTable/ImportCapTable";
import useRootStore from "../../../../../../Shared/Hooks/useRootStore";
import { ShareClassInfo } from "../../../../../../Models/API/CapTable/share-class-info";
import { Column as ColumnType } from "../../../../../../Models/App/CapTable/ImportCapTable";

const Style = css({
    label: "ShareClasses",
    display: "flex",
    flexDirection: "column",
    // alignSelf: "center",
    // justifyContent: "center",
    position: "relative",
    "&__text": {
        marginTop: "0.8rem",
        marginBottom: "2.8rem",
    },
    // "&__header": {
    //     paddingTop: "7.5rem",
    //     paddingLeft: "2rem",
    //     display: "flex",
    //     flexDirection: "column",
    //     // marginBottom: "2rem",
    //     position: "absolute",
    //     left: "10rem",
    //     top: "4rem",
    // },
});

const ShareClasses = forwardRef<ForwardedRef, unknown>((_, forwardedRef) => {
    const { capTableStore } = useRootStore();
    const { columns, onFileUpload, file, setColumns, setShareClasses, shareClasses } = useContext(ImportContext);

    useEffect(() => {
        if (!shareClasses?.[0]?.projectID) return;
        capTableStore.deleteProject(shareClasses[0].projectID);
    }, []);

    useEffect(() => {
        shareClasses?.[0].projectID && capTableStore.loadProject(shareClasses[0].projectID);
    }, [shareClasses?.[0].projectID]);

    useImperativeHandle(forwardedRef, () => ({
        async onValidate() {
            if (isNullOrUndefined(columns)) return false;

            const selectedCols = columns.filter((col) => col.type !== CapTableImportColumnType.none).map(({ uniqueId, ...rst }) => rst);
            const res = await capTableStore.saveImportedCapTable({ columns: selectedCols });
            setShareClasses(res.data?.shareClasses.map((sc) => ({ ...sc, roundDate: undefined, issuePricePerShare: undefined })));
            return !res.error;
        },
    }));

    const onChangeColumn = (uniqueId: string, col: Column) => {
        if (isNullOrUndefined(columns)) return;
        const colIdx = columns.findIndex((col) => col.uniqueId === uniqueId);
        if (colIdx === -1) return;
        setColumns([...columns.slice(0, colIdx), col, ...columns.slice(colIdx + 1)]);
    };

    return (
        <CapTableImportStep
            className={Style}
            scroll
        >
            <div className="bold">Imported cap table</div>
            <div className={`${Style}__text`}>
                Please review the below marked columns. For those that do not represent Share Class, please select another appropriate
                option from the drop-down list.
            </div>
            <ImportCapTable
                columns={columns || []}
                highlight={[CapTableImportColumnType.class, CapTableImportColumnType.none, CapTableImportColumnType.warrants]}
                disabled={[CapTableImportColumnType.shareholders, CapTableImportColumnType.commonShares]}
                onChangeColumn={onChangeColumn}
                showTotal
            />
        </CapTableImportStep>
    );
});

const mockData: ShareClassInfo[] = [
    {
        convertionValue: 0,
        shareClassID: 2563,
        projectID: 2776,
        shareClass: "Common",
        roundDate: new Date("1970-01-01T00:00:00Z"),
        seniorityLevel: 1,
        numberOfRegisteredShares: 2352316,
        numberOfSharesOutstanding: 2352316,
        numberOfWarrants: 0,
        issuePricePerShare: 0,
        convertionTypeExID: null,
        convertionValueEx: null,
        commonShareEquivalent: 0,
        warrantsPriceEx: null,
        pricePerShareAfterDiscountEx: null,
        shareClassTypeID: 200,
        antiDilutedModeID: null,
        warrantsExpirationDate: null,
        numberOfBSA: 0,
    },
    {
        convertionValue: 0,
        shareClassID: 2565,
        projectID: 2776,
        shareClass: "New ",
        roundDate: new Date("1970-01-01T00:00:00Z"),
        seniorityLevel: 2,
        numberOfRegisteredShares: 5912104,
        numberOfSharesOutstanding: 5912104,
        numberOfWarrants: 0,
        issuePricePerShare: 0,
        convertionTypeExID: null,
        convertionValueEx: null,
        commonShareEquivalent: 0,
        warrantsPriceEx: null,
        pricePerShareAfterDiscountEx: null,
        shareClassTypeID: 100,
        antiDilutedModeID: null,
        warrantsExpirationDate: null,
        numberOfBSA: 0,
    },
    {
        convertionValue: 0,
        shareClassID: 2564,
        projectID: 2776,
        shareClass: "Ordinary",
        roundDate: new Date("1970-01-01T00:00:00Z"),
        seniorityLevel: 3,
        numberOfRegisteredShares: 166654,
        numberOfSharesOutstanding: 166654,
        numberOfWarrants: 0,
        issuePricePerShare: 0,
        convertionTypeExID: null,
        convertionValueEx: null,
        commonShareEquivalent: 0,
        warrantsPriceEx: null,
        pricePerShareAfterDiscountEx: null,
        shareClassTypeID: 100,
        antiDilutedModeID: null,
        warrantsExpirationDate: null,
        numberOfBSA: 0,
    },
    {
        convertionValue: 0,
        shareClassID: 2566,
        projectID: 2776,
        shareClass: "Preferred",
        roundDate: new Date("1970-01-01T00:00:00Z"),
        seniorityLevel: 4,
        numberOfRegisteredShares: 217412,
        numberOfSharesOutstanding: 217412,
        numberOfWarrants: 0,
        issuePricePerShare: 0,
        convertionTypeExID: null,
        convertionValueEx: null,
        commonShareEquivalent: 0,
        warrantsPriceEx: null,
        pricePerShareAfterDiscountEx: null,
        shareClassTypeID: 100,
        antiDilutedModeID: null,
        warrantsExpirationDate: null,
        numberOfBSA: 0,
    },
    {
        convertionValue: 0,
        shareClassID: 2567,
        projectID: 2776,
        shareClass: "Preferred.1",

        roundDate: new Date("1970-01-01T00:00:00Z"),
        seniorityLevel: 5,
        numberOfRegisteredShares: 45945,
        numberOfSharesOutstanding: 45945,
        numberOfWarrants: 0,
        issuePricePerShare: 0,
        convertionTypeExID: null,
        convertionValueEx: null,
        commonShareEquivalent: 0,
        warrantsPriceEx: null,
        pricePerShareAfterDiscountEx: null,
        shareClassTypeID: 100,
        antiDilutedModeID: null,
        warrantsExpirationDate: null,
        numberOfBSA: 0,
    },
    {
        convertionValue: 0,
        shareClassID: 2568,
        projectID: 2776,
        shareClass: "Preferred.2",

        roundDate: new Date("1970-01-01T00:00:00Z"),
        seniorityLevel: 6,
        numberOfRegisteredShares: 1347132,
        numberOfSharesOutstanding: 1347132,
        numberOfWarrants: 0,
        issuePricePerShare: 0,
        convertionTypeExID: null,
        convertionValueEx: null,
        commonShareEquivalent: 0,
        warrantsPriceEx: null,
        pricePerShareAfterDiscountEx: null,
        shareClassTypeID: 100,
        antiDilutedModeID: null,
        warrantsExpirationDate: null,
        numberOfBSA: 0,
    },
    {
        convertionValue: 0,
        shareClassID: 2569,
        projectID: 2776,
        shareClass: "Preferred.3",

        roundDate: new Date("1970-01-01T00:00:00Z"),
        seniorityLevel: 7,
        numberOfRegisteredShares: 1096970,
        numberOfSharesOutstanding: 1096970,
        numberOfWarrants: 0,
        issuePricePerShare: 0,
        convertionTypeExID: null,
        convertionValueEx: null,
        commonShareEquivalent: 0,
        warrantsPriceEx: null,
        pricePerShareAfterDiscountEx: null,
        shareClassTypeID: 100,
        antiDilutedModeID: null,
        warrantsExpirationDate: null,
        numberOfBSA: 0,
    },
    {
        convertionValue: 0,
        shareClassID: 2570,
        projectID: 2776,
        shareClass: "Preferred.4",

        roundDate: new Date("1970-01-01T00:00:00Z"),
        seniorityLevel: 8,
        numberOfRegisteredShares: 8536986,
        numberOfSharesOutstanding: 8536986,
        numberOfWarrants: 0,
        issuePricePerShare: 0,
        convertionTypeExID: null,
        convertionValueEx: null,
        commonShareEquivalent: 0,
        warrantsPriceEx: null,
        pricePerShareAfterDiscountEx: null,
        shareClassTypeID: 100,
        antiDilutedModeID: null,
        warrantsExpirationDate: null,
        numberOfBSA: 0,
    },
    {
        convertionValue: 0,
        shareClassID: 2571,
        projectID: 2776,
        shareClass: "Preferred.5",

        roundDate: new Date("1970-01-01T00:00:00Z"),
        seniorityLevel: 9,
        numberOfRegisteredShares: 19157,
        numberOfSharesOutstanding: 19157,
        numberOfWarrants: 0,
        issuePricePerShare: 0,
        convertionTypeExID: null,
        convertionValueEx: null,
        commonShareEquivalent: 0,
        warrantsPriceEx: null,
        pricePerShareAfterDiscountEx: null,
        shareClassTypeID: 100,
        antiDilutedModeID: null,
        warrantsExpirationDate: null,
        numberOfBSA: 0,
    },
    {
        convertionValue: 0,
        shareClassID: 2572,
        projectID: 2776,
        shareClass: "Preferred.7",

        roundDate: new Date("1970-01-01T00:00:00Z"),
        seniorityLevel: 10,
        numberOfRegisteredShares: 13010751,
        numberOfSharesOutstanding: 13010751,
        numberOfWarrants: 0,
        issuePricePerShare: 0,
        convertionTypeExID: null,
        convertionValueEx: null,
        commonShareEquivalent: 0,
        warrantsPriceEx: null,
        pricePerShareAfterDiscountEx: null,
        shareClassTypeID: 100,
        antiDilutedModeID: null,
        warrantsExpirationDate: null,
        numberOfBSA: 0,
    },
    {
        convertionValue: 0,
        shareClassID: 2573,
        projectID: 2776,
        shareClass: "Preferred.8",

        roundDate: new Date("1970-01-01T00:00:00Z"),
        seniorityLevel: 11,
        numberOfRegisteredShares: 562740,
        numberOfSharesOutstanding: 562740,
        numberOfWarrants: 0,
        issuePricePerShare: 0,
        convertionTypeExID: null,
        convertionValueEx: null,
        commonShareEquivalent: 0,
        warrantsPriceEx: null,
        pricePerShareAfterDiscountEx: null,
        shareClassTypeID: 100,
        antiDilutedModeID: null,
        warrantsExpirationDate: null,
        numberOfBSA: 0,
    },
    {
        convertionValue: 0,
        shareClassID: 2574,
        projectID: 2776,
        shareClass: "Total ",

        roundDate: new Date("1970-01-01T00:00:00Z"),
        seniorityLevel: 12,
        numberOfRegisteredShares: 26561567,
        numberOfSharesOutstanding: 26561567,
        numberOfWarrants: 0,
        issuePricePerShare: 0,
        convertionTypeExID: null,
        convertionValueEx: null,
        commonShareEquivalent: 0,
        warrantsPriceEx: null,
        pricePerShareAfterDiscountEx: null,
        shareClassTypeID: 100,
        antiDilutedModeID: null,
        warrantsExpirationDate: null,
        numberOfBSA: 0,
    },
    {
        convertionValue: 0,
        shareClassID: 2575,
        projectID: 2776,
        shareClass: "Total",

        roundDate: new Date("1970-01-01T00:00:00Z"),
        seniorityLevel: 13,
        numberOfRegisteredShares: 33268167,
        numberOfSharesOutstanding: 33268167,
        numberOfWarrants: 0,
        issuePricePerShare: 0,
        convertionTypeExID: null,
        convertionValueEx: null,
        commonShareEquivalent: 0,
        warrantsPriceEx: null,
        pricePerShareAfterDiscountEx: null,
        shareClassTypeID: 100,
        antiDilutedModeID: null,
        warrantsExpirationDate: null,
        numberOfBSA: 0,
    },
];

export default ShareClasses;
