export const grantCancelationsMock = [
	{
		grantId: 745,
		// grantNumber: -1,
		beneficiaryId: 0,
		id: 21682,
		date: "2002-09-15T00:00:00",
		// transactionType: 1,
		granted: 1234,
		vested: 822,
		exercised: 0,
		canceled: 0,
		sold: 0,
		sellable: 0,
		exercisable: 0,
		outstanding: 0,
		grantStatusType: 0,
	},
	{
		grantId: 745,
		// grantNumber: -1,
		beneficiaryId: 0,
		id: 21683,
		date: "2002-10-15T00:00:00",
		// transactionType: 2,
		granted: 1234,
		vested: 848,
		exercised: 0,
		canceled: 0,
		sold: 0,
		sellable: 0,
		exercisable: 0,
		outstanding: 0,
		grantStatusType: 0,
	},
	{
		grantId: 745,
		// grantNumber: -1,
		beneficiaryId: 0,
		id: 21684,
		date: "2002-11-15T00:00:00",
		// transactionType: 0,
		granted: 1234,
		vested: 874,
		exercised: 0,
		canceled: 0,
		sold: 0,
		sellable: 0,
		exercisable: 0,
		outstanding: 0,
		grantStatusType: 0,
	},
	{
		grantId: 745,
		// grantNumber: -1,
		beneficiaryId: 0,
		id: 21685,
		date: "2002-12-15T00:00:00",
		// transactionType: 0,
		granted: 1234,
		vested: 899,
		exercised: 0,
		canceled: 0,
		sold: 0,
		sellable: 0,
		exercisable: 0,
		outstanding: 0,
		grantStatusType: 0,
	},
	{
		grantId: 745,
		// grantNumber: -1,
		beneficiaryId: 0,
		id: 21686,
		date: "2003-01-15T00:00:00",
		// transactionType: 0,
		granted: 1234,
		vested: 925,
		exercised: 0,
		canceled: 0,
		sold: 0,
		sellable: 0,
		exercisable: 0,
		outstanding: 0,
		grantStatusType: 0,
	},
	{
		grantId: 745,
		// grantNumber: -1,
		beneficiaryId: 0,
		id: 21687,
		date: "2003-02-15T00:00:00",
		// transactionType: 0,
		granted: 1234,
		vested: 951,
		exercised: 0,
		canceled: 0,
		sold: 0,
		sellable: 0,
		exercisable: 0,
		outstanding: 0,
		grantStatusType: 0,
	},
	{
		grantId: 745,
		// grantNumber: -1,
		beneficiaryId: 0,
		id: 21688,
		date: "2003-03-15T00:00:00",
		// transactionType: 0,
		granted: 1234,
		vested: 976,
		exercised: 0,
		canceled: 0,
		sold: 0,
		sellable: 0,
		exercisable: 0,
		outstanding: 0,
		grantStatusType: 0,
	},
	{
		grantId: 745,
		// grantNumber: -1,
		beneficiaryId: 0,
		id: 21689,
		date: "2003-04-15T00:00:00",
		// transactionType: 0,
		granted: 1234,
		vested: 1002,
		exercised: 0,
		canceled: 0,
		sold: 0,
		sellable: 0,
		exercisable: 0,
		outstanding: 0,
		grantStatusType: 0,
	},
	{
		grantId: 745,
		// grantNumber: -1,
		beneficiaryId: 0,
		id: 21690,
		date: "2003-05-15T00:00:00",
		// transactionType: 0,
		granted: 1234,
		vested: 1028,
		exercised: 0,
		canceled: 0,
		sold: 0,
		sellable: 0,
		exercisable: 0,
		outstanding: 0,
		grantStatusType: 0,
	},
	{
		grantId: 745,
		// grantNumber: -1,
		beneficiaryId: 0,
		id: 21691,
		date: "2003-06-15T00:00:00",
		// transactionType: 0,
		granted: 1234,
		vested: 1054,
		exercised: 0,
		canceled: 0,
		sold: 0,
		sellable: 0,
		exercisable: 0,
		outstanding: 0,
		grantStatusType: 0,
	},
	{
		grantId: 745,
		// grantNumber: -1,
		beneficiaryId: 0,
		id: 21692,
		date: "2003-07-15T00:00:00",
		// transactionType: 0,
		granted: 1234,
		vested: 1079,
		exercised: 0,
		canceled: 0,
		sold: 0,
		sellable: 0,
		exercisable: 0,
		outstanding: 0,
		grantStatusType: 0,
	},
	{
		grantId: 745,
		// grantNumber: -1,
		beneficiaryId: 0,
		id: 21693,
		date: "2003-08-15T00:00:00",
		// transactionType: 0,
		granted: 1234,
		vested: 1105,
		exercised: 0,
		canceled: 0,
		sold: 0,
		sellable: 0,
		exercisable: 0,
		outstanding: 0,
		grantStatusType: 0,
	},
	{
		grantId: 745,
		// grantNumber: -1,
		beneficiaryId: 0,
		id: 21694,
		date: "2003-09-15T00:00:00",
		// transactionType: 0,
		granted: 1234,
		vested: 1131,
		exercised: 0,
		canceled: 0,
		sold: 0,
		sellable: 0,
		exercisable: 0,
		outstanding: 0,
		grantStatusType: 0,
	},
	{
		grantId: 745,
		// grantNumber: -1,
		beneficiaryId: 0,
		id: 21695,
		date: "2003-10-15T00:00:00",
		// transactionType: 0,
		granted: 1234,
		vested: 1156,
		exercised: 0,
		canceled: 0,
		sold: 0,
		sellable: 0,
		exercisable: 0,
		outstanding: 0,
		grantStatusType: 0,
	},
	{
		grantId: 745,
		// grantNumber: -1,
		beneficiaryId: 0,
		id: 21696,
		date: "2003-11-15T00:00:00",
		// transactionType: 0,
		granted: 1234,
		vested: 1182,
		exercised: 0,
		canceled: 0,
		sold: 0,
		sellable: 0,
		exercisable: 0,
		outstanding: 0,
		grantStatusType: 0,
	},
	{
		grantId: 745,
		// grantNumber: -1,
		beneficiaryId: 0,
		id: 21697,
		date: "2002-08-15T00:00:00",
		// transactionType: 0,
		granted: 1234,
		vested: 796,
		exercised: 0,
		canceled: 0,
		sold: 0,
		sellable: 0,
		exercisable: 0,
		outstanding: 0,
		grantStatusType: 0,
	},
	{
		grantId: 745,
		// grantNumber: -1,
		beneficiaryId: 0,
		id: 21698,
		date: "2002-07-15T00:00:00",
		// transactionType: 0,
		granted: 1234,
		vested: 771,
		exercised: 0,
		canceled: 0,
		sold: 0,
		sellable: 0,
		exercisable: 0,
		outstanding: 0,
		grantStatusType: 0,
	},
	{
		grantId: 745,
		// grantNumber: -1,
		beneficiaryId: 0,
		id: 21699,
		date: "2002-06-15T00:00:00",
		// transactionType: 0,
		granted: 1234,
		vested: 745,
		exercised: 0,
		canceled: 0,
		sold: 0,
		sellable: 0,
		exercisable: 0,
		outstanding: 0,
		grantStatusType: 0,
	},
	{
		grantId: 745,
		// grantNumber: -1,
		beneficiaryId: 0,
		id: 21700,
		date: "2002-05-15T00:00:00",
		// transactionType: 0,
		granted: 1234,
		vested: 719,
		exercised: 0,
		canceled: 0,
		sold: 0,
		sellable: 0,
		exercisable: 0,
		outstanding: 0,
		grantStatusType: 0,
	},
	{
		grantId: 745,
		// grantNumber: -1,
		beneficiaryId: 0,
		id: 21701,
		date: "2001-01-15T00:00:00",
		// transactionType: 0,
		granted: 1234,
		vested: 308,
		exercised: 0,
		canceled: 0,
		sold: 0,
		sellable: 0,
		exercisable: 0,
		outstanding: 0,
		grantStatusType: 0,
	},
	{
		grantId: 745,
		// grantNumber: -1,
		beneficiaryId: 0,
		id: 21702,
		date: "2001-02-15T00:00:00",
		// transactionType: 0,
		granted: 1234,
		vested: 334,
		exercised: 0,
		canceled: 0,
		sold: 0,
		sellable: 0,
		exercisable: 0,
		outstanding: 0,
		grantStatusType: 0,
	},
	{
		grantId: 745,
		// grantNumber: -1,
		beneficiaryId: 0,
		id: 21703,
		date: "2001-03-15T00:00:00",
		// transactionType: 0,
		granted: 1234,
		vested: 359,
		exercised: 0,
		canceled: 0,
		sold: 0,
		sellable: 0,
		exercisable: 0,
		outstanding: 0,
		grantStatusType: 0,
	},
	{
		grantId: 745,
		// grantNumber: -1,
		beneficiaryId: 0,
		id: 21704,
		date: "2001-04-15T00:00:00",
		// transactionType: 0,
		granted: 1234,
		vested: 385,
		exercised: 0,
		canceled: 0,
		sold: 0,
		sellable: 0,
		exercisable: 0,
		outstanding: 0,
		grantStatusType: 0,
	},
	{
		grantId: 745,
		// grantNumber: -1,
		beneficiaryId: 0,
		id: 21705,
		date: "2001-05-15T00:00:00",
		// transactionType: 0,
		granted: 1234,
		vested: 411,
		exercised: 0,
		canceled: 0,
		sold: 0,
		sellable: 0,
		exercisable: 0,
		outstanding: 0,
		grantStatusType: 0,
	},
	{
		grantId: 745,
		// grantNumber: -1,
		beneficiaryId: 0,
		id: 21706,
		date: "2001-06-15T00:00:00",
		// transactionType: 0,
		granted: 1234,
		vested: 437,
		exercised: 0,
		canceled: 0,
		sold: 0,
		sellable: 0,
		exercisable: 0,
		outstanding: 0,
		grantStatusType: 0,
	},
	{
		grantId: 745,
		// grantNumber: -1,
		beneficiaryId: 0,
		id: 21707,
		date: "2001-07-15T00:00:00",
		// transactionType: 0,
		granted: 1234,
		vested: 462,
		exercised: 0,
		canceled: 0,
		sold: 0,
		sellable: 0,
		exercisable: 0,
		outstanding: 0,
		grantStatusType: 0,
	},
	{
		grantId: 745,
		// grantNumber: -1,
		beneficiaryId: 0,
		id: 21708,
		date: "2003-12-15T00:00:00",
		// transactionType: 0,
		granted: 1234,
		vested: 1208,
		exercised: 0,
		canceled: 0,
		sold: 0,
		sellable: 0,
		exercisable: 0,
		outstanding: 0,
		grantStatusType: 0,
	},
	{
		grantId: 745,
		// grantNumber: -1,
		beneficiaryId: 0,
		id: 21709,
		date: "2001-08-15T00:00:00",
		// transactionType: 0,
		granted: 1234,
		vested: 488,
		exercised: 0,
		canceled: 0,
		sold: 0,
		sellable: 0,
		exercisable: 0,
		outstanding: 0,
		grantStatusType: 0,
	},
	{
		grantId: 745,
		// grantNumber: -1,
		beneficiaryId: 0,
		id: 21710,
		date: "2001-10-15T00:00:00",
		// transactionType: 0,
		granted: 1234,
		vested: 539,
		exercised: 0,
		canceled: 0,
		sold: 0,
		sellable: 0,
		exercisable: 0,
		outstanding: 0,
		grantStatusType: 0,
	},
	{
		grantId: 745,
		// grantNumber: -1,
		beneficiaryId: 0,
		id: 21711,
		date: "2001-11-15T00:00:00",
		// transactionType: 0,
		granted: 1234,
		vested: 565,
		exercised: 0,
		canceled: 0,
		sold: 0,
		sellable: 0,
		exercisable: 0,
		outstanding: 0,
		grantStatusType: 0,
	},
	{
		grantId: 745,
		// grantNumber: -1,
		beneficiaryId: 0,
		id: 21712,
		date: "2001-12-15T00:00:00",
		// transactionType: 0,
		granted: 1234,
		vested: 591,
		exercised: 0,
		canceled: 0,
		sold: 0,
		sellable: 0,
		exercisable: 0,
		outstanding: 0,
		grantStatusType: 0,
	},
	{
		grantId: 745,
		// grantNumber: -1,
		beneficiaryId: 0,
		id: 21713,
		date: "2002-01-15T00:00:00",
		// transactionType: 0,
		granted: 1234,
		vested: 616,
		exercised: 0,
		canceled: 0,
		sold: 0,
		sellable: 0,
		exercisable: 0,
		outstanding: 0,
		grantStatusType: 0,
	},
	{
		grantId: 745,
		// grantNumber: -1,
		beneficiaryId: 0,
		id: 21714,
		date: "2002-02-15T00:00:00",
		// transactionType: 0,
		granted: 1234,
		vested: 642,
		exercised: 0,
		canceled: 0,
		sold: 0,
		sellable: 0,
		exercisable: 0,
		outstanding: 0,
		grantStatusType: 0,
	},
	{
		grantId: 745,
		// grantNumber: -1,
		beneficiaryId: 0,
		id: 21715,
		date: "2002-03-15T00:00:00",
		// transactionType: 0,
		granted: 1234,
		vested: 668,
		exercised: 0,
		canceled: 0,
		sold: 0,
		sellable: 0,
		exercisable: 0,
		outstanding: 0,
		grantStatusType: 0,
	},
	{
		grantId: 745,
		// grantNumber: -1,
		beneficiaryId: 0,
		id: 21716,
		date: "2002-04-15T00:00:00",
		// transactionType: 0,
		granted: 1234,
		vested: 694,
		exercised: 0,
		canceled: 0,
		sold: 0,
		sellable: 0,
		exercisable: 0,
		outstanding: 0,
		grantStatusType: 0,
	},
	{
		grantId: 745,
		// grantNumber: -1,
		beneficiaryId: 0,
		id: 21717,
		date: "2001-09-15T00:00:00",
		// transactionType: 0,
		granted: 1234,
		vested: 514,
		exercised: 0,
		canceled: 0,
		sold: 0,
		sellable: 0,
		exercisable: 0,
		outstanding: 0,
		grantStatusType: 0,
	},
	{
		grantId: 745,
		// grantNumber: -1,
		beneficiaryId: 0,
		id: 21718,
		date: "2004-01-15T00:00:00",
		// transactionType: 0,
		granted: 1234,
		vested: 1234,
		exercised: 0,
		canceled: 0,
		sold: 0,
		sellable: 0,
		exercisable: 0,
		outstanding: 0,
		grantStatusType: 0,
	},
];
