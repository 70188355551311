import { IContactUs } from "../Models/App";
import { ICommonApiRequestValues } from "../Models/App/DataRoom/ICommonApiRequestValue";
import { BaseService } from "./BaseService";

export default class NotificationService extends BaseService {
	endPoints = {
		SendContactUsFormDetails: "/Emails/email-templates/contactus",
	};

	commonValues: ICommonApiRequestValues;
	constructor(companyID: number) {
		super();
		this.commonValues = {
			companyID: companyID,
		};
	}

	async sendContactUsFormDetails(formValues: IContactUs) {
		return await this.httpNotification.post(this.endPoints.SendContactUsFormDetails, {
			...formValues,
		});
	}
}
