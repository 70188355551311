import { css } from "@emotion/css";
import classNames from "classnames";
import { useState } from "react";
import useRootStore from "../../../../../Shared/Hooks/useRootStore";
import appConfig from "../../../../../config/config";

const TableStyle = css({
	"label": "TableConfig",
	"overflow": "auto",
	"display": "flex",
	"flexDirection": "column",
	"width": "100%",
	"borderTop": `1px solid ${appConfig.style.colors.border1}`,
	"&__row": {
		"display": "flex",
		"transition": "all .2s",
		"&.hover": {
			"&:hover": {
				background: appConfig.style.colors.background2,
			},
		},
	},
	"&__cell": {
		"padding": 12,
		"flex": "0 0 150px",
		"borderRight": `1px solid ${appConfig.style.colors.border1}`,
		"&:first-child": {
			borderLeft: `1px solid ${appConfig.style.colors.border1}`,
		},
		"borderBottom": `1px solid ${appConfig.style.colors.border1}`,
		"cursor": "pointer",
		"transition": "all .2s",
		"&.hover": {
			background: appConfig.style.colors.background2,
		},
	},
});

interface Props {
	type?: "row" | "column";
	onSelect: (rowIdx: number, colIdx: number) => void;
	data: string[][];
}

const TableConfig = ({ data, type = "row", onSelect }: Props) => {
	const [col, setCol] = useState<number>();

	if (!data?.length) return <div>No data to display</div>;

	return (
		<div className={`${TableStyle} row-selection`}>
			{data.map((row, rIdx) => (
				<div key={rIdx} className={classNames(`${TableStyle}__row`, { hover: type === "row" })}>
					{row.map((cell, cIdx) => (
						<div
							key={cIdx}
							className={classNames(`${TableStyle}__cell`, "text-ellipsis", {
								hover: type === "column" && col === cIdx,
							})}
							data-col={cIdx}
							data-row={rIdx}
							onClick={() => onSelect(rIdx, cIdx)}
							onMouseOver={() => type === "column" && setCol(cIdx)}
						>
							{cell}
						</div>
					))}
				</div>
			))}
		</div>
	);
};

export default TableConfig;
