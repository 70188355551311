import styled from "@emotion/styled";
import { isNumber } from "../../Utilities";

export interface IGridProps extends Omit<React.HTMLAttributes<HTMLElement>, "align" | "children"> {
    cols?: "auto-fit" | number;
    rows?: number;
    min?: string;
    max?: string;
    gap?: { row: string; col: string };
    width?: string;
    height?: string;
}

const Grid = styled.div(
    {
        label: "Grid",
        display: "grid",
    },
    ({
        min = "0",
        max = "1fr",
        rows = 1,
        cols = "auto-fit",
        gap = { row: "3.2rem", col: "3.2rem" },
        width = "100%",
        height = "auto",
    }: IGridProps) => ({
        width,
        height,
        gridTemplateColumns: `repeat(${cols}, minmax(${isNumber(min) ? `${min}rem` : min}, ${max}))`,
        gridTemplateRows: `repeat(${rows}, 1fr)`,
        gridColumnGap: gap.col,
        gridRowGap: gap.row,
    })
);

export default Grid;
