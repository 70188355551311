import { css } from "@emotion/css";
import appConfig from "../../../../config/config";

// @include animation("fadeIn2 .2s");
const UserMenuStyle = css({
	label: "UserMenuProfile",
	boxShadow: appConfig.style.boxShadow.dark,
	position: "absolute",
	zIndex: 5,
	right: "-0.8rem",
	background: "#ffffff",
	top: "calc(100% + 0.8rem)",
	display: "flex",
	flexDirection: "column",
	// "border": `1px solid ${appConfig.style.colors.color1Secondary}`,
	minWidth: "30rem",
	borderRadius: "0.8rem",
	userSelect: "none",

	"&__item": {
		display: "flex",
		flexDirection: "column",
		placeContent: "center",
		width: "100%",
		flex: "0 0 4rem",
		cursor: "pointer",
		transition: "all 0.1s",
		"&.view-only": {
			color: "#cacaca",
		},
		"&:not(.view-only):hover": {
			".clickable": {
				textDecoration: "underline",
				letterSpacing: 1,
			},
		},

		"&.profile": {
			borderBottom: `1px solid ${appConfig.style.colors.color1Secondary}`,
			marginBottom: "7px",
			color: "#000000",
			img: {
				width: "5rem",
			},
		},

		// "&::after": {
		// 	"content": "''",
		// 	"background": "#ffffff",
		// 	"width": "1.5rem",
		// 	"position": "absolute",
		// 	"height": "1.5rem",
		// 	"top": "-0.75rem",
		// 	// 14px of the person menu - half of the sqaure to center
		// 	"right": "calc(1.4rem - 0.75rem)",
		// 	"transform": "rotate(45deg)",
		// 	"borderTopLeftRadius": "0.8rem",
		// 	"@include gt-lg": {
		// 		// 18px of the person menu - half of the sqaure to center
		// 		right: "1.6rem",
		// 	},
		// },
	},
	"&__clickable": {
		padding: "0 1.6rem",
		flex: "0 0 4rem",
		display: "flex",
		alignItems: "center",
		cursor: "pointer",
		textDecoration: "none !important",

		".content": {
			display: "flex",
			justifyContent: "space-between",
			flex: 1,
			".arrow": {
				width: "1rem",
				"&.rotate-180": {
					transform: "rotate(180deg)",
				},
			},
		},
	},
	"&__img-wrapper": {
		marginRight: "1.2rem",
		img: {
			width: "1.6rem",
			height: "auto",
		},
		"&.bigger": {
			padding: "2rem 0",
		},
	},
	"&__user-details": {
		display: "flex",
		flexDirection: "column",
		".name": {
			fontWeight: "bold",
		},
		".email": {
			color: "#777",
			textOverflow: "ellipsis",
			overflow: "hidden",
			width: "21rem",
			whiteSpace: "nowrap",
		},
	},
	"&__sub-menu": {
		//    "@include animation("fadeIn2 .2s")"
		padding: "0 1.4rem",
		display: "flex",
		flexDirection: "column",
		borderBottom: `1px solid ${appConfig.style.colors.color1Secondary}`,
	},
	"&__sub-menu-item": {
		padding: "1.6rem",
		paddingLeft: "3.6rem",
		"&:hover, &.selected": {
			color: appConfig.style.colors.color1,
			textDecoration: "underline",
		},
	},
});

export default UserMenuStyle;
