import styled from "@emotion/styled";
import { breakpoints } from "../../dependencies/breakpoints";

export const WIDGET_BORDER_RADIUS = "1.6rem";
const WIDGET_PADDING_X = "1.8rem";
const WIDGET_PADDING_Y = "1.9rem";
export const WIDGET_MIN_HEIGHT = "11.6rem"; // CaptableDashboard's single figure widget height
// export const WIDGET_MIN_HEIGHT = "8.8rem"; // FundingDashboard's single figure widget height
export const WIDGET_MIN_WIDTH = "32rem";
// export const WIDGET_MIN_WIDTH_XL = "48rem";
export const WIDGET_MIN_WIDTH_XL = "38.4rem";
const WIDGET_BG_COLOR = "white";
const WIDGHT_BOX_SHADOW = "3px 3px 3px 0px rgba(201, 202, 208, 0.4)";

export const StyledWidget = styled.div`
	label: Widget;
	background-color: ${WIDGET_BG_COLOR};
	border-radius: ${WIDGET_BORDER_RADIUS};
	box-shadow: ${WIDGHT_BOX_SHADOW};
	min-height: ${WIDGET_MIN_HEIGHT};
	min-width: ${WIDGET_MIN_WIDTH};
	padding: ${WIDGET_PADDING_Y} ${WIDGET_PADDING_X};
	height: 100%;
	width: 100%;
	overflow: hidden;
	position: relative;

	.page--dashboard &:first-of-type {
		padding: 3rem;
	}

	@media (min-width: ${breakpoints.xl}) {
		min-width: ${WIDGET_MIN_WIDTH_XL};
	}

	.isStandalone {
		box-shadow: none;
		padding: unset;
	}
`;

export const WidgetBody = styled.div`
	label: WidgetBody;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	height: 100%;
	overflow-y: auto;
`;
