import React from "react";
import useToggle from "../../hooks/useToggle";
import { ToggleBtn, Wrapper } from "./WithToggle.style";
import Image from "../../../../Image";
import { IC_ARROW_DOWN_CIRCLE, IC_ARROW_UP_CIRCLE } from "../../../../../../Assets";
import { Collapse, Fade } from "@mui/material";

type Props = {
    isOpen?: boolean;
    children: React.ReactNode;
    onToggle?: (val: boolean) => void;
};

const WithToggle: React.FC<Props> = (props: Props) => {
    const [shouldShowComponent, toggleShouldShowComponent] = useToggle(props.isOpen || true);

    return (
        <Wrapper>
            <Collapse in={shouldShowComponent}>{props.children}</Collapse>
            <ToggleBtn
                type="button"
                onClick={() => {
                    toggleShouldShowComponent();
                    props.onToggle?.(shouldShowComponent);
                }}
            >
                {shouldShowComponent ? (
                    <Image
                        width="4.4rem"
                        src={IC_ARROW_UP_CIRCLE}
                        alt="close dashboard"
                    />
                ) : (
                    <Image
                        width="4.4rem"
                        src={IC_ARROW_DOWN_CIRCLE}
                        alt="open dashboard"
                    />
                )}
            </ToggleBtn>
        </Wrapper>
    );
};

export default WithToggle;
