import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Routes } from "../../Routes";
import Button from "../../Shared/Components/Button/Button";
import EquityDashboard from "./Sections/Dashboard/EquityDashboard";
import EquityEntitySelector from "./Sections/Dashboard/EquityEntitySelector";
import useRootStore from "../../Shared/Hooks/useRootStore";
import useEsopModals from "./useEsopModals";
// import MainMenu from "./MainMenu/MainMenu";
import Spinner from "../../Shared/Components/Spinner/Spinner";
import { CompanyRegistrationEnum } from "./Sections/Registration/Registration";
import { toJS } from "mobx";
import { isNullOrUndefined } from "../../Shared/Utilities";
import MainMenu from "../../Shared/Components/MainMenu/MainMenu";
import { MenuActionType } from "../../Shared/Components/MainMenu/ActionsMenu/ActionsMenu";
import { IC_EDIT2 } from "../../Assets";
import { Product } from "../../Models/API/enums";
import useTrusteeModals from "../TrusteeEquity/useTrusteeModals";

export enum EquityEntities {
	Beneficiaries,
	Grants,
	Orders,
	Cancellations,
}

const Index = () => {

	const { equityPlansStore, auth, companyStore, trusteeStore } = useRootStore()
	const { onRegistrationHandler } = useEsopModals()
	const { showPivotalRegistration } = useTrusteeModals()

	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [isRegisting, setIsRegistering] = useState<boolean>(false)
	const params = useParams<{ entity: string | undefined }>()
	const history = useHistory()



	useEffect(() => {
		if (isLoading || equityPlansStore.isRegistrationCompleted || isRegisting || companyStore.company.pivotalEquityPlans) return;

		let step = -1;

		const { companyData } = equityPlansStore;

		if (!companyData) {
			step = CompanyRegistrationEnum.intro;
		} else if (!companyData.plans?.length) {
			step = CompanyRegistrationEnum.newEquityPlan;
		} else if (!companyData.vestings.some((vesting) => vesting.active)) {
			step = CompanyRegistrationEnum.vestingScheduleTemplates;
		} else if (!companyData.taxTracks?.length) {
			step = CompanyRegistrationEnum.companyData;
		}

		setTimeout(() => {
			if (!auth.authToken) return;

			equityPlansStore.isRegistrationCompleted = false;
			onRegistrationHandler(step, () => {
				if (equityPlansStore.isRegistrationCompleted) {
					setIsRegistering(false);
					fetchData();
					return;
				}
				history.push(Routes.dashboard.index);
			});
		}, 300);
		setIsRegistering(true);
		return () => setIsLoading(false);
	}, [isLoading, isRegisting, equityPlansStore.isRegistrationCompleted]);

	useEffect(() => {
		if (isRegisting) return;

		fetchData();
	}, [isRegisting, equityPlansStore.isRegistrationCompleted]);

	const fetchData = async () => {
		setIsLoading(true);
		await Promise.all([
			equityPlansStore.GetEquitySettings(),
			equityPlansStore.GetGrantsForCompany(),
			equityPlansStore.GetBeneficiariesDashboardData(),
			// equityPlansStore.GetDashboardOrders(),
		]);
		setIsLoading(false);
	};

	useEffect(() => {
		if (!trusteeStore.pivotalRegistration) return

		// TODO: Add multiple times pop-up
		if (trusteeStore.pivotalRegistrationOpen) {
			// console.log('from ESOP, already open')

			return
		}
		// console.log('from ESOP')
		showPivotalRegistration(true)
		//trusteeStore.setRegistration = false

	}, [trusteeStore.pivotalRegistration])

	return (
		<section className="page--equity dashboard default">
			<MainMenu product={companyStore.company.pivotalEquityPlans ? Product.TrusteeEquity : Product.EquityPlans} />

			{
				isLoading ?
					<Spinner center incorporated />
					: (equityPlansStore.isRegistrationCompleted || companyStore.company.pivotalEquityPlans)
						? <EquityEntitySelector />
						: <></>
			}
		</section>
	);
};
export default Index;





// const createNewCompanyHandler = async () => {
// const company = await equityPlansStore.CreateNewCompany();
// if (company) {
// 	setIsCompanyRegistered(true);
// } else {
// onRegistrationHandler();
// }
// };

// useEffect(() => {
// 	params.entity && setEquityEntity(+params.entity);
// }, [params]);

// const checkEquityCompany = async () => {
// 	await equityPlansStore.GetEquitySettings();
// 	const isFullyRegistered = !!(
// 		equityPlansStore.companyData?.plans?.length &&
// 		equityPlansStore.companyData.vestings?.length &&
// 		equityPlansStore.companyData.taxTracks?.length
// 	);
// 	setIsCompanyRegistered(isFullyRegistered);
// 	// if (equityPlansStore.companyData) {
// 	// 	setIsCompanyRegistered(() => true);
// 	// } else {
// 	// 	setIsCompanyRegistered(() => false);
// 	// }
// };

// useEffect(() => {
// 	isCompanyRegistered && equityPlansStore.GetEquitySettings();
// }, [isCompanyRegistered]);