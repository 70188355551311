import { css } from "@emotion/css";

const NewOrderStyles = css({
	padding: "7rem 8rem 4rem",
	"&__form": {
		display: "grid",
		gridTemplateColumns: "repeat(4, 1fr)",
		marginBottom: "2rem",
		gridGap: "2.4rem",
	},
	"&__title": {
		marginBottom: "4rem",
	},
});

export default NewOrderStyles;
