import { observer } from 'mobx-react-lite';
import useRootStore from '../../../../Shared/Hooks/useRootStore';
import { css } from '@emotion/css';
import Select from '../../../../Shared/Components/Select/Select';
import { ConvertPromisedModalType, IPromisedTable, PromisedType } from '../../../../Models/App/EquityPlans/Promised';
import NumberInput from '../../../../Shared/Components/Input/NumberInput';
import DatePickerInput from '../../../../Shared/Components/Input/DatePickerInput';
import { addDaysToDate, isNumber } from '../../../../Shared/Utilities';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { ForwardedRef, ForwardedRefType } from '../../../../Shared/Hooks/useMultiStepForm';
import { useAppendState } from '../../../../Shared/Hooks/useAppendState';
import { InputValidationRef, useFormValidation } from '../../../../Shared/Hooks/useFormValidation';
import { commonValidators } from '../../../../Shared/ObjectValidator';

interface PromisedBoardApprovalProps {
    promised?: IPromisedTable;
    name?: string;
    grantMinDate: Date;
    oneEditData?: ConvertPromisedModalType;
    total?: number;
}

const PromisedStyle = css({
    label: "PromisedBoardApproval",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    "&__main-section": {
        width: "100%",
        padding: "2rem 0",

        "> b": {
            fontWeight: 500,
        },
    },

    "&__inputs-container": {
        display: "grid",
        gridTemplateColumns: "repeat(5, 1fr)",
        gridColumnGap: "2.4rem",
        gridRowGap: "3.4rem",
    },
});

const BoardApprovalFields = forwardRef<ForwardedRefType<ConvertPromisedModalType>, PromisedBoardApprovalProps>((props, forwardedRef) => {
    const { equityPlansStore, currenciesOptions } = useRootStore();
    const { name, grantMinDate, promised, oneEditData, total } = props;
    const [expMinDate, setExpMinDate] = useState<Date>();
    const [data, _, onInputChange] = useAppendState<ConvertPromisedModalType>({
        promisedId: promised?.promisedId,
        amountPromised: promised?.amountPromised,
        grantDate: oneEditData?.grantDate,
        exercisePrice: oneEditData?.exercisePrice,
        vestingId: oneEditData?.vestingId,
        expirationDate: oneEditData?.expirationDate,
        vestingStartDate: oneEditData?.vestingStartDate,
        currency: oneEditData?.currency,
    });

    useEffect(() => {
        validateExpirationDate();
    }, [data.amountPromised, data.grantDate, data.vestingStartDate, data.vestingId]);

    useImperativeHandle(forwardedRef, () => ({
        onValidate: () => {
            return validateForm({ showErrors: true, focusOnError: true });
        },
        getData() {
            return data;
        }
    }));

    const { formValidationState, inputRefs, validateForm } = useFormValidation({
        form: data,
        schema: {
            grantDate: [commonValidators.required()],
            exercisePrice: [commonValidators.required(), commonValidators.graterThan(0)],
            currency: [commonValidators.isInt()],
            vestingId: [commonValidators.required()],
            vestingStartDate: [commonValidators.required()],
            expirationDate: [commonValidators.required()]
        },
    });

    const validateExpirationDate = async () => {
        if (total) {
            if (!total || !data.grantDate || !data.vestingStartDate || !data.vestingId) return;
            const expDate = await equityPlansStore.ValidateExpDate(total, new Date(data.grantDate), new Date(data.vestingStartDate), data.vestingId);
            setExpMinDate(expDate.data?.date);
            return;
        }

        if (!data.amountPromised || !data.grantDate || !data.vestingStartDate || !data.vestingId) return;
        const expDate = await equityPlansStore.ValidateExpDate(data.amountPromised, new Date(data.grantDate), new Date(data.vestingStartDate), data.vestingId);
        setExpMinDate(expDate.data?.date);
    };

    return (
        <>
            <div className={PromisedStyle}>
                <div className={`${PromisedStyle}__main-section`}>
                    <b>{name}</b>
                    <div className={`${PromisedStyle}__inputs-container`}>
                        {/* Grant Date */}
                        <DatePickerInput
                            label="Grant date"
                            qaid="AddGrant.Input.GrantDate"
                            name="grantDate"
                            minDate={grantMinDate}
                            maxDate={new Date()}
                            value={data.grantDate?.toString()}
                            onChange={onInputChange}
                            error={formValidationState?.grantDate?.message}
                            ref={(el: InputValidationRef) => (inputRefs.grantDate = el)}
                            required
                            containerClassName="mb-0"
                        />

                        {/* Ex. Price */}
                        <NumberInput
                            qaid="AddGrant.Input.ExPrice"
                            label="Exercise price"
                            value={data.exercisePrice}
                            name="exercisePrice"
                            onChange={onInputChange}
                            number="float"
                            options={currenciesOptions}
                            selectedValue={data.currency}
                            onOptionSelect={(value) => isNumber(value) && onInputChange(value, 'currency')}
                            error={formValidationState?.exercisePrice?.message || formValidationState?.currency?.message}
                            ref={(el: InputValidationRef) => {
                                inputRefs.exercisePrice = el;
                                inputRefs.currency = el;
                            }}
                            required
                            containerClassName="mb-0"
                            ratio={0.6}
                        />

                        {/* Vest Schedj */}
                        <Select
                            label="Vesting schedule"
                            qaid="AddGrant.Input.VestingSchedule"
                            options={equityPlansStore.companyActiveVests ?? []}
                            name="vestingId"
                            onChange={(value, name) => isNumber(value) && onInputChange(value, name)}
                            value={data.vestingId}
                            error={formValidationState?.vestingId?.message}
                            ref={(el: InputValidationRef) => (inputRefs.vestingId = el)}
                            required
                        />

                        {/* Vest Start date */}
                        <DatePickerInput
                            label="Vesting start date"
                            qaid="AddGrant.Input.VestingStartDate"
                            name="vestingStartDate"
                            value={data.vestingStartDate}
                            minDate={grantMinDate}
                            maxDate={data.grantDate}
                            onChange={(value, name) => onInputChange(value, name)}
                            error={formValidationState?.vestingStartDate?.message}
                            ref={(el: InputValidationRef) => (inputRefs.vestingStartDate = el)}
                            required
                            containerClassName="mb-0"
                        />

                        {/* Exp, Date */}
                        <DatePickerInput
                            label="Expiration date"
                            qaid="AddGrant.Input.ExpirationDate"
                            name="expirationDate"
                            minDate={expMinDate ? addDaysToDate(expMinDate, 1) : data.grantDate ? addDaysToDate(data.grantDate, 1) : undefined}
                            value={data.expirationDate}
                            onChange={(value, name) => onInputChange(value, name)}
                            error={formValidationState?.expirationDate?.message}
                            ref={(el: InputValidationRef) => (inputRefs.expirationDate = el)}
                            required
                            containerClassName="mb-0"
                        />
                    </div>
                </div>
            </div>
        </>
    );

});

export default observer(BoardApprovalFields);