import { css } from "@emotion/css";
import appConfig from "../../../../../config/config";

const DataCompStyle = css({
	position: "relative",
	width: "100%",
	// border: '1px solid red',
	// borderRadius: '4px',

	".innerContainer": {
		width: "100%",
		position: "absolute",
		border: "1px solid var(--border-3-field, #BAC0D9)",
		borderRadius: 10,
		padding: "0 1.4rem",
		display: "flex",
		flexDirection: "column",
		zIndex: 1,
		background: "#ffffff",
		"&.open": {
			zIndex: 2,
		},
	},

	".hidden-content": {
		// padding: "0 10px 10px 10px",
		padding: "0 0 1rem 0",
		".custom-table": {
			".Table__Container": {
				boxShadow: "none",
				border: "none",
				borderRadius: 0,
			},
			".Table__Cell": {
				paddingLeft: 0,
			},
		},
	},

	".input-container": {
		width: "fit-content",
		paddingInlineStart: "11px",
		height: "3rem",
		borderRadius: "8px",
		border: "1px solid  #8B96E9",
		margin: "0",
	},

	".button-container": {
		marginTop: "1.2rem",
		display: "flex",
		flexDirection: "row-reverse",
		justifyContent: "space-between",
	},

	".drawer-header": {
		flex: 1,
		display: "flex",
		alignItems: "center",
		flexDirection: "row",
		justifyContent: "space-between",
		// fontSize: "1.6rem",
		fontWeight: 500,
		// margin: "1.4rem 0",
		padding: "1.4rem 0",
		borderBottom: `1px solid ${appConfig.style.colors.field}`,
		// paddingBottom: "1rem",
		cursor: "pointer",
		color: appConfig.style.colors.text2,
	},

	".add-btn": {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		cursor: "pointer",
		// marginTop: "1rem",
		flex: 1,
		padding: "1rem 0",
	},
});

export default DataCompStyle;
