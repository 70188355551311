import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { IMG_REG_COMLETE_MOBILE } from "../../../../Assets";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import { EmployeeManagementPermission } from "../../../../Models/API/UsersAndPermissions/permissions-enum";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../../Routes";
import Spinner from "../../../../Shared/Components/Spinner/Spinner";
import { isNullOrUndefined } from "../../../../Shared/Utilities";
import CloseIcon from "@mui/icons-material/Close";

export const MobileRegCompleteMsg = () => {
	const { auth, companyStore } = useRootStore();
	const history = useHistory();
	const queryParams = new URLSearchParams(history.location.search);
	const [hasPermissionToEmployeesWebsite, setHasPermissionToEmployeesWebsite] = useState<boolean>(false);
	const [shouldNavToEmployee, setShouldNavToEmployee] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const fromLogin = history.location.pathname.includes(Routes.account.otpLogin);
	const isFirstReg = queryParams.has("mobile-first-reg");

	useEffect(() => {
		const fetchPermissions = async () => {
			setIsLoading(true);

			if (isNullOrUndefined(auth.permissions)) {
				await auth.setCompanyPermissions(companyStore.companyId);
			}
			if (
				auth.permissions?.employeeManagementPermission === EmployeeManagementPermission.admin ||
				auth.permissions?.employeeManagementPermission === EmployeeManagementPermission.Viewer
			) {
				setHasPermissionToEmployeesWebsite(true);
				setShouldNavToEmployee(fromLogin);
			}

			setIsLoading(false);
		};

		!isFirstReg && fetchPermissions();
	}, []);

	useEffect(() => {
		setTimeout(() => {
			if (shouldNavToEmployee) {
				auth.navToEmployeesWebsite();
			}
		}, 3000);
	}, [shouldNavToEmployee]);

	const navToMarketing = () => {
		window.location.replace(Routes.marketing.index);
	};

	const getModalContent = () => {
		if (isFirstReg) {
			return (
				<>
					<CloseIcon className="contentContainer__closeIcon" onClick={navToMarketing} />
					<div className="contentContainer__text">
						After completing the setup, you'll be able to access the personal portal via your mobile devices
					</div>
				</>
			);
		} else if (shouldNavToEmployee) {
			return <span className="contentContainer__title">Welcome to your Personal Portal</span>;
		} else {
			return <div className="contentContainer__text">We have designed the altshare platform to only be viewable on desktop.</div>;
		}
	};

	return (
		<section className="designedForMobileContainer">
			<div className="innerContainer">
				{isLoading ? (
					<Spinner center incorporated />
				) : (
					<div className="contentContainer">
						{getModalContent()}
						<div className="contentContainer__img">
							<img className="ic" src={IMG_REG_COMLETE_MOBILE} alt="" />
						</div>
						{hasPermissionToEmployeesWebsite && !shouldNavToEmployee && (
							<div className="bottomContainer">
								You can stil access to your&nbsp;
								<span className="account-link" onClick={auth.navToEmployeesWebsite}>
									Personal portal
								</span>
								<br />
							</div>
						)}
					</div>
				)}
			</div>
		</section>
	);
};

export default observer(MobileRegCompleteMsg);
