import styled from "@emotion/styled";
import { breakpoints } from "../../../../../shared/dependencies/breakpoints";
import { DOUGHNUT_CHART_MAX_SIZE_XL, DOUGHNUT_CHART_SPACING } from "../../../../../shared/components/DoughnutChart/DoughnutChart.style";
import { LEGEND_WIDTH_XL } from "../../../../../shared/components/ChartLegend/ChartLegend.style";

const MAX_CONTENT_WIDTH_XL = "28rem";

export const Wrapper = styled.div`
	label: NoDataWrapper;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;

	@media (min-width: ${breakpoints.xl}) {
	}
`;

export const ChartWrapper = styled.div`
	min-height: ${DOUGHNUT_CHART_MAX_SIZE_XL};
	min-width: ${DOUGHNUT_CHART_MAX_SIZE_XL};
	label: ChartWrapper;
	> img {
		width: 16.5rem;
		height: auto;
	}
	// margin: 0 ${DOUGHNUT_CHART_SPACING};
`;

export const ContnetWrapper = styled.div`
	label: ContnetWrapper;
	flex-grow: 1;
	padding-inline: 1.6rem;
	max-width: 50%;
	line-height: 1.5;
	font-size: 1.4rem;
`;

export const DashboardContnetWrapper = styled.div`
	label: ContnetWrapper;
	flex-grow: 1;
	max-width: clamp(20%, 80%, 100%);
	font-size: 1.8rem;
	font-weight: 400;
	line-height: 30px;
	letter-spacing: 0.36px;
`;

export const Divider = styled.span`
	height: 3px;
	width: 100%;
	margin-top: 1.6rem;
	max-width: clamp(20%, 80%, 100%);
	background: linear-gradient(117deg, #8b96e9 26.54%, #f2ab64 49.21%, #f6818f 73.76%, #f5dc82 100%);
`;
