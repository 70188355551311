import { ReactNode } from "react";
import NoData from "./NoData/NoData";
import { BtnWrapper, ChartWrapper, Wrapper } from "./SwitchSelectChartWidget.style";
import { WidgetBody } from "../../../../shared/components/Widget/Widget.style";

export interface SwitchSelectChartWidgetProps {
	switchSelectNode: ReactNode;
	doughnutChartNode: ReactNode;
	legendChartNode: ReactNode;
	isNoData?: boolean;
	className?: string;
	bodyClassName?: string;
	noDataContent?: string;
}

const SwitchSelectChartWidget = (props: SwitchSelectChartWidgetProps) => {
	return (
		<Wrapper className={props.className}>
			<BtnWrapper>{props.switchSelectNode}</BtnWrapper>
			<WidgetBody className={props.bodyClassName}>
				{props.isNoData ? (
					<NoData content={props.noDataContent} />
				) : (
					<>
						{props.doughnutChartNode}
						{props.legendChartNode}
					</>
				)}
			</WidgetBody>
		</Wrapper>
	);
};

export default SwitchSelectChartWidget;
