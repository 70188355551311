import { useHistory, useParams } from "react-router-dom";
import SubmitFiles from "./Sections/SubmitFiles/SubmitFiles";
import TrusteeReports from "./Sections/Reports/TrusteeReports";
import TrusteeForms from "./Sections/Forms/TrusteeForms";
import TrusteeGrantDocuments from "./Sections/GrantDocuments/TrusteeGrantDocuments";
import TrusteeDashboard from "./Dashboard/TrusteeDashboard";
import TrusteeOrders from "./Dashboard/AdditionalViews/TrusteeOrders";
import TrusteeCancelations from "./Dashboard/AdditionalViews/TrusteeCancelations";


const TrusteeSectionSwitch = () => {
    const params = useParams<{ section: string | undefined }>();
    const history = useHistory();

    // console.log('PARAM DATA', params.section)

    switch (params.section) {
        case undefined:
            return <TrusteeDashboard />
        case "submit_file":
            return (
                <SubmitFiles />
            );
        case "reports":
            return (
                <TrusteeReports />
            );
        case "forms":
            return (
                <TrusteeForms />
            );
        case "grantDocuments":
            return (
                <TrusteeGrantDocuments />
            );
        case "orders":
            return (
                <TrusteeOrders />
            );
        case "cancelations":
            return (
                <TrusteeCancelations />
            );
        default:
            return <></>
    }
}

export default TrusteeSectionSwitch