import { IC_EXPENSING_LOGO, IC_PREVIEW_BACKGROUND_EXPENSING, IC_PREVIEW_BACKGROUND_WATERFALL, IC_WATERFALL_LOGO } from '../../../Assets';
import { WelcomePageProps } from '../../../Models/App/welcome-page';

export const WaterfallWelcomeProps: WelcomePageProps = {
	logo: IC_WATERFALL_LOGO,
	title: `Model the best exit strategy 
of the company`,
	benefits: [
		<>
			<span className="bold">Generate customizable waterfall scenarios</span> to reflect various liquidation preferences & exit strategies, giving you a
			clear view of potential outcomes
		</>,
		<>
			<span className="bold">Make informed decisions faster</span> - instantly calculate distributions and visualize financial impacts with just a few
			clicks
		</>,
		<>
			<span className="bold">Easy navigation</span> with intuitive wizard designed for both finance experts and founders, simplifying complex analyses
		</>,
		<>
			<span className="bold">Secure & Reliable</span> - trust in our top-notch security measures to protect your sensitive financial data, with reliable
			performance for all your scenario modeling needs.
		</>,
		<>
			<span className="bold">Comprehensive IRR & ROI Analysis</span> - evaluate your investments with detailed Internal Rate of Return (IRR) and Return on
			Investment (investment multiple) calculations, providing you with a complete picture of your financial performance and potential returns
		</>,
	],
	previewImage: IC_PREVIEW_BACKGROUND_WATERFALL,
};

export const ExpensingWelcomeProps: WelcomePageProps = {
	logo: IC_EXPENSING_LOGO,
	title: `Your trusted solution for
stock-based compensation expensing`,
	benefits: [
		<>
			Stay compliant with <span className="bold">US GAAP & IFRS</span>
		</>,
		<>Create accurate reports tailored to your business, in just few clicks</>,
		<>
			Real time integration with external financial data to determine option fair value using <span className="bold">Black-Scholes</span> method
		</>,
		<>Synchronized with your equity plans data to create always up to date reports</>,
		<>Seamless integration with 409A valuation</>,
		<>Support multiple currencies and exchange rate conversions</>,
		<>
			Always <span className="bold">auditor-ready</span> in minutes
		</>,
	],
	previewImage: IC_PREVIEW_BACKGROUND_EXPENSING,
};
