import { useHistory } from 'react-router-dom'
import Button from '../../../../Shared/Components/Button/Button'
import { IC_DOCUMENT_PDF, IC_DOCUMENT_PEN, IC_PDF } from '../../../../Assets'
import useRootStore from '../../../../Shared/Hooks/useRootStore'
import { useEffect, useState } from 'react'
import { NumberOption } from '../../../../Models/API/All/NumberOption'
import Tabs from '../../../../Shared/Components/Tabs/Tabs'
import PagedTable from '../../../../Shared/Components/Table/PagedTable/PagedTable'
import { trusteeGrantDocuments } from '../../StaticData/tableTypes'
import { useEffectOnce } from 'react-use'
import { GrantDocumentStatus, TrusteeGrantDocument } from '../../Types/trusteeTypes'
import Table, { TableColumn } from '../../../../Shared/Components/Table/Table'
import { observer } from 'mobx-react-lite'
import { formatDate } from '../../../../Shared/Utilities'
import useTrusteeModals from '../../useTrusteeModals'

import PageStyles from '../../Dashboard/AdditionalViews/AdditionalViews.module.css'


const TrusteeGrantDocuments = () => {

    const history = useHistory()
    const { trusteeStore } = useRootStore()
    const { showSuccessModal } = useTrusteeModals()

    const [selectedTab, setSelectedTab] = useState<NumberOption>({ label: 'Pending', value: 0 })
    const [documentsId, setDocumentsId] = useState<string[]>([])
    const [signing, setSigning] = useState(false)

    const setTab = (tab: NumberOption) => {
        setSelectedTab(tab)
    }

    const reportTabs: NumberOption[] = [
        { label: 'Pending', value: 0 },
        { label: 'Pending employee signature', value: 1 },
        { label: 'Signed', value: 2 }
    ]

    const First = (props: { click: TrusteeGrantDocument }) => {

        const iconClickHandler = async () => {
            console.log('first CLICKED !', props.click)
            const res: any = await trusteeStore.GetSingleTrusteeGrantDocument(props.click.documentId)

            if (!res.data) return

            const file = {
                base64: 'data:application/pdf;base64,' + res.data.documentBytes,
                fileName: props.click.beneficiaryName + '_' + props.click.grantNumber + '_' + props.click.grantDate + '.pdf'
            }

            const downloadLink = document.createElement("a");
            downloadLink.href = file.base64;
            downloadLink.download = file.fileName;

            downloadLink.click();
        }

        return (
            <div style={{ padding: '1rem', cursor: 'pointer' }}>
                <img src={IC_DOCUMENT_PDF} width={24} onClick={iconClickHandler} />
            </div>
        )
    }

    const documentsTable: TableColumn<TrusteeGrantDocument>[] = [
        { name: 'beneficiaryName', label: 'Full name' },
        { name: 'idSSN', label: 'ID/SSN' },
        { name: 'grantDate', label: 'Grant date', format: (v) => formatDate(v) },
        { name: 'grantNumber', label: 'Grant number' },
        { name: 'grantType', label: 'Grant type' },
        { name: 'exercisePrice', label: 'Exercise price' },
        { name: 'quantity', label: 'Quantity' },
        { name: 'menu', label: '', render: (e, id) => <First click={e} /> },
    ]

    const documentsTableSmall: TableColumn<TrusteeGrantDocument>[] = [
        { name: 'beneficiaryName', label: 'Full name' },
        { name: 'idSSN', label: 'ID/SSN' },
        { name: 'grantDate', label: 'Grant date', format: (v) => formatDate(v) },
        { name: 'grantNumber', label: 'Grant number' },
        { name: 'grantType', label: 'Grant type' },
        { name: 'exercisePrice', label: 'Exercise price' },
        { name: 'quantity', label: 'Quantity' },
        { name: 'menu', label: '', render: (e, id) => <First click={e} /> },
    ]

    const documentSignHandler = async () => {
        setSigning(true)

        console.log('documentSignHandler', documentsId)
        const res = await trusteeStore.PostTrusteeGrantDocumentsSignage(documentsId)

        if (res.data) {
            showSuccessModal('Grant letters signed sucssesfully')
            setDocumentsId([])
            trusteeStore.GetTrusteeGrantDocuments(selectedTab.value)
        }

        setSigning(false)
    }

    const documentSelectHandler = (e: any) => {
        console.log('document Select Handler', JSON.stringify(e))
        const d = e as TrusteeGrantDocument[]
        const f = d.map(doc => doc.documentId)
        setDocumentsId(f)
    }

    const getSingleDocument = (documentId: string) => {

    }




    useEffectOnce(() => {
        trusteeStore.GetTrusteeGrantDocuments(GrantDocumentStatus.Pending)
    })

    useEffect(() => {
        trusteeStore.GetTrusteeGrantDocuments(selectedTab.value)
    }, [selectedTab])

    return (
        <div style={PageStyles} className={PageStyles.mainContainer}>

            <div className={PageStyles.btnHeader}>
                <Button
                    qaid='trustee.orders.back'
                    label='Back'
                    inverse
                    cancel
                    onClick={() => history.push('/trustee')}
                />
            </div>
            <div className={PageStyles.pageBody}>
                <div className={PageStyles.pageHeader}>

                    <div className={PageStyles.headerMain}>
                        <img src={IC_DOCUMENT_PEN} />
                        <h3>Grant documents</h3>
                    </div>
                </div>


                <Tabs
                    selectedTab={selectedTab?.value}
                    setTab={setTab}
                    headlines={reportTabs}
                />

                <div style={{ marginTop: '3rem' }}>
                    {
                        selectedTab.value === GrantDocumentStatus.Pending ? (
                            <Table
                                columns={documentsTable}
                                rows={trusteeStore.trusteeGrantDocuments}
                                onRowSelect={documentSelectHandler}
                            />
                        ) : (
                            <Table
                                columns={documentsTable}
                                rows={trusteeStore.trusteeGrantDocuments}
                            // onRowSelect={documentSelectHandler}
                            />
                        )
                    }
                    {/* <PagedTable
                    newDataReq={trusteeStore.GetTrusteeGrantDocumentsByParams}
                    tableData={trusteeStore.trusteeGrantDocuments.documents}
                    totalRows={trusteeStore.trusteeGrantDocuments.numberOfTotalItems}
                    tableKeys={trusteeGrantDocuments}
                    onTableRowClick={(a) => getReport(a)}
                /> */}

                    {
                        {
                            '0': <>1</>,
                            '1': <>2</>,
                            '2': <>3</>,
                        }[selectedTab.value]

                    }
                </div>

                {
                    (selectedTab.value === GrantDocumentStatus.Pending) &&
                    <div className={PageStyles.button}>
                        <Button
                            qaid='trustee.orders.back'
                            label='Sign'
                            // inverse
                            // cancel
                            onClick={documentSignHandler}
                            disabled={documentsId.length == 0}
                            isLoading={signing}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default observer(TrusteeGrantDocuments)