import { useEffect, useState } from "react";
import { CompanyRegistration } from "../../../Models/API/Auth";
import Button from "../../../Shared/Components/Button/Button";
import Flex from "../../../Shared/Components/Layout/Flex";
import { currencies } from "../../../Shared/Config";
import { useAppendState } from "../../../Shared/Hooks/useAppendState";
import useContact from "../../../Shared/Hooks/useContact";
import { useFormValidation } from "../../../Shared/Hooks/useFormValidation";
import useRootStore from "../../../Shared/Hooks/useRootStore";
import { commonValidators } from "../../../Shared/ObjectValidator";
import { nationalities } from "../../../Shared/StaticData/nationalities";
import { getRandomNumberBetween, isNullOrUndefined } from "../../../Shared/Utilities";
import CompanyInfo from "./CompanyInfo";
import ContactInfo from "./ContactInfo";

interface Props {
    onSubmit: (data: CompanyRegistration) => Promise<void> | void;
    company?: CompanyRegistration;
}

const AddEditNewCompany = ({ onSubmit, company }: Props) => {
    const { appState, auth } = useRootStore();
    const [companyInfo, setCompanyInfo, onCompanyInputHandler] = useAppendState<CompanyRegistration>(
        (company ?? {}) as CompanyRegistration
    );
    const { contact, setContact, validateForm, formValidationState, onInputHandler, inputRefs, contactInputRefs, setDummyData } =
        useContact({
            isRoleRequired: true,
            isEmailRequired: true,
        });
    const {
        formValidationState: companyFormValidationState,
        validateForm: validateCompanyForm,
        inputRefs: companyInputRefs,
    } = useFormValidation({
        form: companyInfo,
        schema: {
            companyLegalName: [
                commonValidators.required(),
                commonValidators.minLength(2, "Full name must contain at least 2 letters"),
                commonValidators.maxLength(30, "Invalid Name"),
            ],
            nationality: [commonValidators.required()],
            currency: [commonValidators.required()],
            noOfEmployees: [commonValidators.required()],
            businessDescription: [commonValidators.maxLength(2000, "Please use up to 2,000 characters")],
            website: [
                commonValidators.regex(
                    /^(?:(?:https?):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i,
                    "Invalid website address"
                ),
            ],
            // noOfEmployees: commonValidators.range(0, 99999, "Please enter a valid number"),
        },
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (isNullOrUndefined(company)) return;
        setContact(company.contact);
    }, []);

    const onSubmitHandler = async () => {
        let isValid = validateForm();
        isValid = validateCompanyForm() && isValid;
        if (!isValid) return;

        setIsLoading(true);
        await onSubmit({ ...companyInfo, contact });
        setIsLoading(false);
    };

    return (
        <>
            <CompanyInfo
                // isViewMode={Boolean(company?.guid)}
                company={companyInfo}
                setCompany={setCompanyInfo}
                formValidationState={companyFormValidationState}
                onInputHandler={onCompanyInputHandler}
                disabled={isLoading}
                inputRefs={companyInputRefs}
            />
            <ContactInfo
                // isViewMode={Boolean(company?.guid)}
                contact={contact}
                setContact={setContact}
                formValidationState={formValidationState}
                onInputHandler={onInputHandler}
                disabled={isLoading}
                inputRefs={inputRefs}
                contactInputRefs={contactInputRefs}
            />
            <Flex
                margin="auto 0 0 0"
                justify="end"
                flex={0}
                height="fit-content"
                gap={appState.isDev ? "1rem" : 0}
            >
                {appState.isDev && !company && (
                    <Button
                        label="Fill data (dev only)"
                        qaid=""
                        cancel
                        onClick={() => {
                            setCompanyInfo({
                                companyLegalName: `${auth.user.firstName}'s Company ${getRandomNumberBetween(10000, 100000)}`,
                                nationality: getRandomNumberBetween(1, nationalities.length),
                                currency: getRandomNumberBetween(1, currencies.length),
                                noOfEmployees: 1,
                            } as CompanyRegistration);
                            setDummyData();
                        }}
                    />
                )}
                <Button
                    qaid="CreateCompany.Button.Create"
                    label={company ? "Update" : "Create"}
                    onClick={onSubmitHandler}
                    isLoading={isLoading}
                />
            </Flex>
        </>
    );
};

export default AddEditNewCompany;
