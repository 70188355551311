import { observer } from "mobx-react-lite";
import { useToggle } from "react-use";
import Clickable from "../../../../../../../../Shared/Components/Clickable/Clickable";
import ExpandToggle from "../../../../../../../../Shared/Components/ExpandToggle";
import Table from "../../../../../../../../Shared/Components/Table/Table";
import { shareClassesWaterfallTable, shareHoldersWaterfallTable } from "../../deps/helpers";
import { IBreakPoint } from "../../deps/types";
import Fade from "react-bootstrap/esm/Fade";
import { useEffect, useRef } from "react";

interface Props {
	data: IBreakPoint[];
	isShareClass: boolean;
}

const ValuationCardTogglableTable = ({ data, isShareClass }: Props) => {
	const [showTable, toggleShowTable] = useToggle(false);
	const ref = useRef<HTMLDivElement>(null);


	useEffect(() => {
		showTable && ref.current?.scrollIntoView({ behavior: 'smooth' })
	}, [showTable])

	if (!data) return <></>;

	return (
		<>
			<Clickable
				justify="start"
				margin="2rem 0 3rem 0"
				width="fit-content"
				qaid="ValuationCard.Button.ToggleWaterfallTable"
				onClick={toggleShowTable}
				gap="0.8rem"
				ref={ref}
			>
				<span style={{ width: "11rem", color: "#4d4d4d" }}>{showTable ? "Hide Waterfall" : "Show Waterfall"}</span>
				<ExpandToggle isOpen={showTable} />
			</Clickable>

			<Fade in={showTable} unmountOnExit timeout={100}>
				<Table
					className="Table--ValuationCardTogglableTable"
					columns={isShareClass ? shareClassesWaterfallTable : shareHoldersWaterfallTable}
					rows={data}
				/>
			</Fade>
		</>
	);
};

export default observer(ValuationCardTogglableTable);
