import { css } from "@emotion/css";
import appConfig from "../../../../../config/config";

const VersionsDropDownStyle = css({
    label: "VersionsDropDown",
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: "calc(100% + 0.6rem)",
    left: "4rem",
    background: "#ffffff",
    zIndex: 4,
    borderRadius: 16,
    padding: "0.4rem",
    boxShadow: appConfig.style.boxShadow.dark,
    maxWidth: "104rem",
    animation: "fadeIn .3s ease-out",
    width: "calc(100% - 8rem)",
    "&__projects": {
        maxHeight: "33rem",
        overflowY: "auto",
        padding: "0 1.6rem",
        position: "relative",
        "&.scroll": {
            padding: "0 1rem 0 1.6rem",
        },
    },
});

export default VersionsDropDownStyle;
