import { css } from "@emotion/css";
import { breakpoints } from "../../../../config/style/theme";

export const UpdatesStyle = css({
	label: "Updates",
	overflow: "hidden",
	position: "relative",

	".noData": {
		marginTop: "2rem",

		"&__title": {
			fontSize: "1.8rem",
		},

		"&__background": {
			height: "100%",
			width: "100%",
			borderRadius: "8px",
			opacity: 0.8,
			background: "linear-gradient(0deg, #BDC3F1 0%, #F0F1FF 45.83%, rgba(255, 255, 255, 0.00) 100%)",
		},
	},

	".updatesContainer": {
		marginTop: "3rem",
		overflowY: "hidden",

		"&__updates": {
			overflowY: "auto",
			paddingRight: "1.2rem",
		},
	},

	".rowContainer": {
		margin: "0 0 3.3rem 0",

		"&__icon": {
			height: "auto",
			width: "3rem",
			objectFit: "contain",
			marginInlineEnd: "2rem",
			marginTop: "0.7rem",
		},

		"&__action": {
			".name": {
				fontWeight: 700,
				marginRight: "0.6rem",
			},
		},

		"&__date": {
			color: "#828282",
		},
	},

	[`@media screen and (max-width: ${breakpoints.lg})`]: {
		".rowContainer": {
			fontSize: "1.6rem",
			// "&__name": {
			// 	fontSize: "1.6rem",
			// },

			// "&__action": {
			// 	fontSize: "1.6rem",
			// },

			// "&__date": {
			// 	fontSize: "1.6rem",
			// },
		},
	},
});
