import { css } from "@emotion/css";
import appConfig from "../../../../config/config";

const MainMenuStyle = css({
    label: "ExpensingMenu",
    "&__menu": {
        height: "7.1rem",
        width: "100%",
        display: "flex",
        alignItems: "center",
        padding: "0 4rem",
        boxShadow: `3px 3px 3px 0px ${appConfig.style.colors.boxShadowColor1}`,
        position: "relative",
    },
    "&__menu-side-a": {
        display: "flex",
        flexDirection: "row",
        flex: 1,
        gap: "2.6rem",
    },
    "&__menu-left-side": {
        display: "flex",
        flexDirection: "row",
        gap: "2.6rem",
    },
    "&__btn-actions": {
        background: appConfig.style.colors.gradientPurpleVertical,
        padding: "0.8rem 1.2rem",
        borderRadius: 8,
        color: "#ffffff",
        display: "flex",
        alignItems: "center",
        gap: "0.8rem",
        cursor: "pointer",
        userSelect: "none",
        border: "1px solid #ffffff",
        transition: "all .1s",
        "&.clicked": {
            border: `1px solid ${appConfig.style.colors.color1}`,
            background: appConfig.style.colors.purple,
            color: appConfig.style.colors.color1,
        },
    },
    "&__reset-icon": {
        width: "2.4rem",
    },
    "&__published": {
        color: appConfig.style.colors.orange,
    },
    "&__back-btn": {
        cursor: "pointer",
        display: "flex",
        gap: "2rem",
        height: "3.6rem",
        alignItems: "center",
        color: appConfig.style.colors.text4,
        boxShadow: "1px 1px 1px rgba(217, 217, 217, 0.5)",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: appConfig.style.colors.text4,
        padding: "0.8rem 2rem",
        borderRadius: 8,
        fontWeight: 500,
        userSelect: "none",
        "> span": {
            marginTop: 1,
        },
    },
});

export default MainMenuStyle;
