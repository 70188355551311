import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { ForwardedRef, ForwardedRefType } from '../../../../../Shared/Hooks/useMultiStepForm';
import Question from './Question';
import RadioButton from '../../../../../Shared/Components/RadioButton/RadioButton';
import DatePickerInput from '../../../../../Shared/Components/Input/DatePickerInput';
import NumberInput from '../../../../../Shared/Components/Input/NumberInput';
import { TextAreaInput } from '../../../../../Shared/Components/Input/Input.Style';
import { WaterfallStep } from '../../../../Waterfall/Components/AddEditWaterfall/index.style';
import useRootStore from '../../../../../Shared/Hooks/useRootStore';
import { DataCollectionPayload } from '../../../../../Models/API/DataCollection/data-collection-payload';
import { useAppendState } from '../../../../../Shared/Hooks/useAppendState';
import Spinner from '../../../../../Shared/Components/Spinner/Spinner';
import { DataCollectionInvestmentTypeEnum } from '../../../../../Models/API/DataCollection/data-collection-investment-type-enum';
import DataCollectionGeneralInfoStyle from './index.style';
import { DataCollectionComment, DataCollectionCommentFieldType } from '../../../../../Models/API/DataCollection/comment-response';
import useDataCollection from '../../../hooks/useDataCollection';

const GeneralInfo = forwardRef<ForwardedRef, any>((_, forwardedRef) => {
	const { dataCollectionStore } = useRootStore();
	const { data, setData, commentsByQuestion, onInputHandler, questionsRef, getFieldTypeByKey } = useDataCollection();

	useImperativeHandle(forwardedRef, () => ({
		onValidate: async () => {
			const promises = Object.keys(questionsRef.current || []).map((key) => {
				const comment = questionsRef.current?.[key as keyof DataCollectionPayload].getData();
				if (!comment || !dataCollectionStore.currentProjectId) return;
				const fieldType = getFieldTypeByKey(key as keyof DataCollectionPayload);
				if (!fieldType) return;
				return dataCollectionStore.addComment({
					fieldType,
					commentDescription: comment,
					valuationProjectId: dataCollectionStore.currentProjectId,
				});
			});
			await Promise.all([...promises, dataCollectionStore.updateDataCollection(data)]);

			return true;
		},
	}));

	useEffect(() => {
		if (data.investmentType !== DataCollectionInvestmentTypeEnum.SAFE) {
			setData((prev) => (prev ? { ...prev, nextRoundValuation: null } : prev));
		}
	}, [data.investmentType]);

	if (!data) return <Spinner center incorporated />;

	return (
		<WaterfallStep small className={DataCollectionGeneralInfoStyle}>
			<span className={`${DataCollectionGeneralInfoStyle}__title`}>General Info</span>
			<Question
				title="For which date does your company need the 409A valuation?"
				info="the fill later"
				renderActions={
					<DatePickerInput
						containerClassName={`${DataCollectionGeneralInfoStyle}__input-container`}
						qaid=""
						placeholder="Select..."
						value={data?.valuationDate ?? undefined}
						name="valuationDate"
						onChange={onInputHandler}
					/>
				}
				ref={(el) => {
					if (questionsRef.current && el) {
						questionsRef.current.valuationDate = el;
					}
				}}
				comments={commentsByQuestion[DataCollectionCommentFieldType.ValuationDate]}
			/>

			<Question
				title="Has your company raised money six months prior the valuation date?"
				info="the fill later"
				renderActions={
					<div className={`${DataCollectionGeneralInfoStyle}__radio-container`}>
						<RadioButton
							qaid=""
							name="investmentExist"
							value={true}
							checked={data.investmentExist === true}
							label="Yes"
							onChange={onInputHandler}
						/>
						<RadioButton
							qaid=""
							name="investmentExist"
							value={false}
							checked={data.investmentExist === false}
							label="No"
							onChange={onInputHandler}
						/>
					</div>
				}
				ref={(el) => {
					if (questionsRef.current && el) {
						questionsRef.current.investmentExist = el;
					}
				}}
				comments={commentsByQuestion[DataCollectionCommentFieldType.InvestmentExist]}
			/>
			{data.investmentExist ? (
				<>
					<Question
						title="Was the money raised through?"
						info="the fill later"
						renderActions={
							<div className={`${DataCollectionGeneralInfoStyle}__radio-container`}>
								<RadioButton
									qaid=""
									name="investmentType"
									value={DataCollectionInvestmentTypeEnum.Equity}
									checked={data.investmentType === DataCollectionInvestmentTypeEnum.Equity}
									label="Issue shares"
									onChange={onInputHandler}
								/>
								<RadioButton
									qaid=""
									name="investmentType"
									value={DataCollectionInvestmentTypeEnum.SAFE}
									checked={data.investmentType === DataCollectionInvestmentTypeEnum.SAFE}
									label="SAFE"
									onChange={onInputHandler}
								/>
								<RadioButton
									qaid=""
									name="investmentType"
									value={DataCollectionInvestmentTypeEnum.ConvertibleLoan}
									checked={data.investmentType === DataCollectionInvestmentTypeEnum.ConvertibleLoan}
									label="Convertible loan"
									onChange={onInputHandler}
								/>
							</div>
						}
						ref={(el) => {
							if (questionsRef.current && el) {
								questionsRef.current.investmentType = el;
							}
						}}
						comments={commentsByQuestion[DataCollectionCommentFieldType.InvestmentType]}
					/>
					{data.investmentType === DataCollectionInvestmentTypeEnum.SAFE && (
						<>
							<Question
								title="What is your estimation regarding the next round company valuation?"
								info="the fill later"
								renderActions={
									<NumberInput
										qaid=""
										name="nextRoundValuation"
										value={data.nextRoundValuation ?? undefined}
										onChange={onInputHandler}
										placeholder="Type in years..."
										number="float"
									/>
								}
								ref={(el) => {
									if (questionsRef.current && el) {
										questionsRef.current.nextRoundValuation = el;
									}
								}}
								comments={commentsByQuestion[DataCollectionCommentFieldType.NextRoundValuation]}
							/>
						</>
					)}
				</>
			) : data.investmentExist === false ? (
				<>
					<Question
						title="Has your company generated revenues in the last 12 months?"
						info="the fill later"
						renderActions={
							<div className={`${DataCollectionGeneralInfoStyle}__radio-container`}>
								<RadioButton
									qaid=""
									name="companyProfitable"
									value={true}
									checked={data.companyProfitable === true}
									label="Yes"
									onChange={onInputHandler}
								/>
								<RadioButton
									qaid=""
									name="companyProfitable"
									value={false}
									checked={data.companyProfitable === false}
									label="No"
									onChange={(value, name) => {
										if (!name) return;
										setData((prevData) => ({ ...prevData, [name]: value, companyProfitableInFuture: null }));
									}}
								/>
							</div>
						}
						ref={(el) => {
							if (questionsRef.current && el) {
								questionsRef.current.companyProfitable = el;
							}
						}}
						comments={commentsByQuestion[DataCollectionCommentFieldType.CompanyProfitable]}
					/>
					{data.companyProfitable === true && (
						<Question
							title="Has your company generated operational profit in the last 12 months or projected to generate a profit in the next 1-2 years?"
							info="the fill later"
							renderActions={
								<div className={`${DataCollectionGeneralInfoStyle}__radio-container`}>
									<RadioButton
										qaid=""
										name="companyProfitableInFuture"
										checked={data.companyProfitableInFuture === true}
										value={true}
										label="Yes"
										onChange={onInputHandler}
									/>
									<RadioButton
										qaid=""
										name="companyProfitableInFuture"
										checked={data.companyProfitableInFuture === false}
										value={false}
										label="No"
										onChange={onInputHandler}
									/>
								</div>
							}
							ref={(el) => {
								if (questionsRef.current && el) {
									questionsRef.current.companyProfitableInFuture = el;
								}
							}}
							comments={[]}
						/>
					)}
				</>
			) : (
				<></>
			)}
			<Question
				title="What’s the company estimation regarding the time in years for an exit (M&A, IPO) or other sell of the company assets?"
				info="the fill later"
				renderActions={
					<NumberInput
						qaid=""
						value={data.timeToLiquidity ?? undefined}
						name="timeToLiquidity"
						onChange={onInputHandler}
						containerClassName={`${DataCollectionGeneralInfoStyle}__input-container`}
						number="float"
						placeholder="Type in years..."
					/>
				}
				ref={(el) => {
					if (questionsRef.current && el) {
						questionsRef.current.timeToLiquidity = el;
					}
				}}
				comments={[]}
			/>
			<Question
				title="Add a link to your company About Us page or write a short description (Maximum 500 characters)"
				info="the fill later"
				renderActions={
					<TextAreaInput
						data-qaid=""
						value={data.aboutUs ?? undefined}
						onChange={(e) => setData((prev) => ({ ...prev, aboutUs: e.target.value }))}
						style={{ resize: 'none', height: '100%', width: '100%' }}
						rows={4}
						maxLength={500}
						placeholder="Type text here..."
						name=""
					/>
				}
			/>
		</WaterfallStep>
	);
});

export default GeneralInfo;
