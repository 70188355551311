import { css } from "@emotion/css";
import appConfig from "../../../../config/config";

const TableDrawerStyles = css({
    display: "flex",
    flexDirection: "column",
    // boxShadow: appConfig.style.boxShadow.card1,
    marginTop: '4rem',
    borderRadius: "4px",
    borderBottom: `1px solid ${appConfig.style.colors.table}`,

    ".drawer-header": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        // fontSize: "1.6rem",
        alignItems: "center",
        paddingBottom: "2.4rem",
        // borderBottom: `1px solid ${appConfig.style.colors.table}`,
        padding: "1.4rem 0",
        height: "6rem",
    },

    ".header-details": {
        cursor: "pointer",
        display: "flex",
        flexDirection: "row",
        // width: "44rem",
        justifyContent: "space-between",
        borderRadius: "5px",
    },

    ".drawer-table": {
        position: "relative",
        overflow: "hidden",
        background: "#FFF",
        padding: "2rem 0 4rem 0",
    },
});

export default TableDrawerStyles;
