import { css } from "@emotion/css";
import { IContactCreate } from "../../../../../../Models/API/Contact/contact";
import { useState } from "react";
import TextInput from "../../../../../../Shared/Components/Input/TextInput";
import { isNullOrUndefined } from "../../../../../../Shared/Utilities";
import Button from "../../../../../../Shared/Components/Button/Button";
import appConfig from "../../../../../../config/config";
import { useAppendState } from "../../../../../../Shared/Hooks/useAppendState";

const Style = css({
	label: "NewContactCard",
	display: "grid",
	gridTemplateColumns: "repeat(5, 1fr)",
	gap: "2.5rem",
	padding: "3rem 6rem 3rem 8rem",
	borderRadius: 16,
	border: `1px solid ${appConfig.style.colors.table}`,
	marginTop: "1.6rem",
	button: {
		placeSelf: "flex-end",
		justifySelf: "flex-end",
	},
});

interface Props {
	contact: IContactCreate;
	onCreate: (contact: IContactCreate) => void;
	disabled?: boolean;
}

const NewContactCard = ({ contact, onCreate, disabled }: Props) => {
	const [newContact, setNewContact, onInputHandler] = useAppendState<IContactCreate>(contact);

	const onContactDetailsInputHandler = (value?: string | number, name?: string) => {
		if (isNullOrUndefined(name)) return;
		setNewContact((prevContact) => ({ ...prevContact, contactDetails: { ...prevContact.contactDetails, [name]: value } }));
	};

	return (
		<div className={Style}>
			<TextInput
				label="First name"
				qaid="NewContactCard.Input.FirstName"
				value={newContact.firstName}
				name="firstName"
				onChange={onInputHandler}
				required
				margin={false}
				disabled={disabled}
			/>
			<TextInput
				label="Last name"
				qaid="NewContactCard.Input.LastName"
				value={newContact.lastName}
				name="lastName"
				onChange={onInputHandler}
				required
				margin={false}
				disabled={disabled}
			/>
			<TextInput
				label="Company email"
				qaid="NewContactCard.Input.Email"
				value={newContact.email}
				name="email"
				onChange={onInputHandler}
				required
				margin={false}
				disabled={disabled}
			/>
			<TextInput
				label="Employee number"
				qaid="NewContactCard.Input.EmployeeNumber"
				value={newContact.contactDetails?.employeeNumber}
				name="employeeNumber"
				onChange={onContactDetailsInputHandler}
				margin={false}
				disabled={disabled}
			/>
			<Button qaid="NewContactCard.Button.Create" label="Create" onClick={() => onCreate(newContact)} disabled={disabled} autoWidth />
		</div>
	);
};

export default NewContactCard;
