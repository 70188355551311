import { useEffect, useRef, useState } from "react";
import Flex from "../../../../Shared/Components/Layout/Flex";
import EquityLoader from "../../Loaders/EquityLoader";
import { EquityStyle } from "./Equity.style";
import { IC_DASHBOARD_ARROW, IC_DASHBOARD_LOCK_CARD, IC_ACTIVE_BENEFICIARIES } from "../../../../Assets";
import Chart from "./Chart";
import Clickable from "../../../../Shared/Components/Clickable/Clickable";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../../Routes";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import Image from "../../../../Shared/Components/Image";
import { SaaSMainDashboardData } from "../../../../Models/App/ESOP/SaaSMainDashboardData";
import { TrusteeMainSaaSDashboardRes } from "../../../TrusteeEquity/Types/ReqRes";

export interface IEquityChartElement {
	readonly label: string;
	readonly amount: number;
	readonly color: string;
	readonly shouldConnectToNextElement?: boolean;
	tooltip?: string
}
export interface IEquityChart {
	readonly name: string;
	readonly totalAmount: number;
	readonly elements: IEquityChartElement[];
}

interface IProps {
	readonly className?: string;
	readonly flex?: number;
	readonly hasPermission: boolean;
}

interface ChartData {
	totalPool: {
		allocated: number
		unallocated: number
	},
	shares: {
		exercised: number
		rsUs: number
	},
	outstanding: {
		unvested: number
		vested: number
	},
	unvested: {
		rsu: number
		options: number
	}
}

const startChart: ChartData = {
	totalPool: {
		allocated: 0,
		unallocated: 0
	},
	shares: {
		exercised: 0,
		rsUs: 0
	},
	outstanding: {
		unvested: 0,
		vested: 0
	},
	unvested: {
		rsu: 0,
		options: 0
	}
}

const Equity = (props: IProps) => {
	const mounted = useRef<boolean>(false);
	const history = useHistory();
	const { equityPlansStore, trusteeStore, companyStore } = useRootStore();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [data, setData] = useState<IEquityChart[]>();
	const [activeBeneficiaries, setActiveBeneficiaries] = useState<number>(0);
	// const [chartData, setChartData] = useState<ChartData>({} as ChartData)

	const pivotalData = !!companyStore.company.pivotalEquityPlans

	let chartData: ChartData | undefined = undefined

	useEffect(() => {
		return () => {
			setActiveBeneficiaries(0);
		};
	}, []);

	const getSaasData = async () => {
		const res = await equityPlansStore.GetSaaSMainDashboardData()
		if (!res.data) return false

		const chart: ChartData = {
			totalPool: res.data.totalPoolDto,
			shares: res.data.shares,
			outstanding: res.data.outstanding,
			unvested: res.data.unvested
		}

		setActiveBeneficiaries(res.data.beneficiariesWithGrants);
		// setChartData(chart)
		chartData = chart

		// mounted.current = true
		return true
	}

	const getPivotalData = async () => {
		trusteeStore.GetBeneficiariesSummary();
		const res = await trusteeStore.GetSaasMainDahsboard();

		if (!res.data) return false;

		const chart: ChartData = {
			totalPool: res.data.poolDto,
			shares: res.data.sharesDto,
			outstanding: {
				unvested: res.data.outstandingDto.unvested.options + res.data.outstandingDto.unvested.rsUs,
				vested: res.data.outstandingDto.exercisable,
			},
			unvested: {
				options: res.data.outstandingDto.unvested.options,
				rsu: res.data.outstandingDto.unvested.rsUs,
			},
		};

		// setChartData(chart)
		const beneficiaries = trusteeStore.benficiariesSummary.active + trusteeStore.benficiariesSummary.inactive;
		setActiveBeneficiaries(beneficiaries);

		chartData = chart;
		// mounted.current = true
		return true;
	}

	const fetchData = async () => {
		setIsLoading(true);
		const dataSet = pivotalData ? await getPivotalData() : await getSaasData();
		// await equityPlansStore.GetSaaSMainDashboardData();

		// console.log('fff fetch', 'dataSet', dataSet, 'mounted', mounted.current, 'chartData', chartData)
		// if (!dataSet || !mounted.current || !chartData) return;
		if (!dataSet || !chartData) return;

		setIsLoading(false);

		const equityData = [
			{
				name: 'Total pool',
				totalAmount: chartData.totalPool.allocated + chartData.totalPool.unallocated,
				elements: [
					{
						label: 'Allocated awards',
						amount: chartData.totalPool.allocated,
						color: 'linear-gradient(213deg, #8B96E9 0%, #BCC2F1 100%)',
						shouldConnectToNextElement: true,
						// tooltip: `Canceled awards that are not back to pool: ${res.data.voided}`
					},
					{
						label: 'Unallocated',
						amount: chartData.totalPool.unallocated,
						color: 'linear-gradient(180deg, #BABFD5 0%, #CDD2E2 100%)',
					},
				],
			},
			{
				name: 'Shares',
				totalAmount: chartData.shares.exercised + chartData.shares.rsUs,
				elements: [
					{
						label: 'Exercised',
						amount: chartData.shares.exercised,
						color: 'linear-gradient(180deg, #8CC1F6 0%, #B6D6F6 100%)',
					},
					{
						label: "RSU's",
						amount: chartData.shares.rsUs,
						color: 'linear-gradient(180deg, #BDC3F1 1.04%, #D7DDFB 100%)',
					},
				],
			},
			{
				name: 'Outstanding awards',
				totalAmount: chartData.outstanding.vested + chartData.outstanding.unvested,
				elements: [
					{
						label: 'Unvested',
						amount: chartData.outstanding.unvested,
						color: 'linear-gradient(180deg, #BABFD5 0%, #CDD2E2 100%)',
						shouldConnectToNextElement: true,
					},

					{
						label: 'Exercisable',
						amount: chartData.outstanding.vested,
						color: 'linear-gradient(180deg, #F6818F 0%, #FBB7BF 100%)',
					},
				],
			},
			{
				name: 'Unvested',
				totalAmount: chartData.unvested.rsu + chartData.unvested.options,
				elements: [
					{
						label: "RSU's",
						amount: chartData.unvested.rsu,
						color: 'linear-gradient(180deg, #F2AB64 0%, #FCD0A5 100%)',
					},
					{
						label: 'Options',
						amount: chartData.unvested.options,
						color: 'linear-gradient(180deg, #F3D569 0%, #F5DC82 100%)',
					},
				],
			},
		];

		setData(equityData);
		setIsLoading(false);
	};

	const arrowNavigationHandler = () => {
		pivotalData
			? history.push(Routes.trusteeData.index)
			: history.push(Routes.options7.dashboard)
	}


	useEffect(() => {
		!data && fetchData();

		return () => {
			mounted.current = false;
		};
	}, []);

	return (
		<Flex className={`page--equity ${props.className} ${EquityStyle}`} flex={props.flex} align="start" justify="start" direction="column">
			{isLoading ? (
				<EquityLoader />
			) : (
				<>
					{props.hasPermission ? (
						<Clickable qaid="Equity.Button.NavToEquity" className="navArrow" onClick={arrowNavigationHandler}>
							<Image width="2rem" src={IC_DASHBOARD_ARROW} tooltip="Navigate to Equity plans" alt="Navigate to Equity plans" />
						</Clickable>
					) : (
						<Flex justify="between" className="navArrow" flex={0}>
							<Image width="2rem" src={IC_DASHBOARD_LOCK_CARD} alt="lock" />
						</Flex>
					)}
					<div className="innerContainer">
						<>
							<Flex className="activeBeneficiaries" justify="end" align="end" gap={10} flex={0}>
								<img className="activeBeneficiaries__img" src={IC_ACTIVE_BENEFICIARIES} alt="Active beneficiaries" />
								<Flex justify="end" align="end" gap={7} flex={0}>
									<span className="activeBeneficiaries__title">Beneficiaries</span>
									<span className="activeBeneficiaries__info">{activeBeneficiaries}</span>
								</Flex>
							</Flex>
							<span className="complexConnector complexConnector__left" />
							<span className="complexConnector complexConnector__right" />
							<Flex justify="evenly" width="100%" height="80%">
								{data &&
									data.map((data) =>
										<Chart key={data.name} name={data.name} totalAmount={data.totalAmount} elements={data.elements} />
									)
								}

							</Flex>
						</>
					</div>
				</>
			)}
		</Flex>
	);
};

export default Equity;
