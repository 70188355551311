import SquareIcon from "@mui/icons-material/Square";
import { FC, ReactElement } from "react";
import { ChartLegendItem, ChartLegendItemFigure, ChartLegendItemLabel, StyledChartLegend } from "./ChartLabels.style";
import OverflowText from "../OverflowText";
import { defaultColors } from "../WidgetDashboard/shared/components/DoughnutChart/DoughnutChart.style";

export interface ChartLegendProps {
    colors?: string[];
    labels: string[];
    figures: number[] | string[];
    centerFigures?: number[] | string[] | ReactElement[];
}

function sortLegendItems(legendItems: any[]) {
    const lastItem = legendItems.find((item) => item.label === "Others");
    const items = legendItems.filter((item) => item.label !== "Others");

    if (lastItem) {
        items.push(lastItem);
    }

    return items;
}

const ChartLabels: FC<ChartLegendProps> = ({ colors = defaultColors, labels, figures, centerFigures }: ChartLegendProps) => {
    if (!labels || labels.length <= 0) {
        return null;
    }

    const formatFigure = (figure: number | string): string => figure.toString() + "%";

    const legendItems = labels.map((label, index) => ({
        color: colors[index],
        label: label,
        figure: figures[index],
        centerFigure: centerFigures?.[index],
    }));

    const sortedLegendItems = sortLegendItems(legendItems);

    return (
        <StyledChartLegend>
            {sortedLegendItems.map((item, index) => (
                <ChartLegendItem key={index}>
                    <ChartLegendItemLabel>
                        <SquareIcon style={{ color: item.color }} />
                        <OverflowText>{item.label}</OverflowText>
                    </ChartLegendItemLabel>
                    {!!item.centerFigure && (
                        <ChartLegendItemFigure className="center-figure">
                            <OverflowText>{item.centerFigure}</OverflowText>
                        </ChartLegendItemFigure>
                    )}
                    <ChartLegendItemFigure>{formatFigure(item.figure)}</ChartLegendItemFigure>
                </ChartLegendItem>
            ))}
        </StyledChartLegend>
    );
};

export default ChartLabels;
