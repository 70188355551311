import React from 'react'
import useRootStore from '../../../../Shared/Hooks/useRootStore'
import Table, { TableColumn } from '../../../../Shared/Components/Table/Table'
import { TrusteeCancelation } from '../../Types/trusteeTypes'
import { formatDate } from '../../../../Shared/Utilities'
import { observer } from 'mobx-react-lite'
import Button from '../../../../Shared/Components/Button/Button'
import { useHistory } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import { toJS } from 'mobx'

import AdditionalStyles from './AdditionalViews.module.css'
import PagedTable from '../../../../Shared/Components/Table/PagedTable/PagedTable'
import { trusteeCancelationKeysMain } from '../../StaticData/tableTypes'

const TrusteeCancelations = () => {

    const { trusteeStore } = useRootStore()
    const history = useHistory()

    const cancelationsColumns: TableColumn<TrusteeCancelation>[] = [
        { label: "Cancelation number", name: "cancelationNumber" },
        { label: "Employee number", name: "beneficiaryId" },
        { label: "Full name", name: "beneficiaryName" },
        { label: "Grant number", name: "grantNumber" },
        { label: "Cancel type", name: "cancelType" },
        { label: "Reason", name: "cancellReason" },
        {
            label: "Date",
            name: "cancelDate",
            format(val) {
                return formatDate(val);
            },
        },
        { label: "Amount", name: "amount" }
    ]

    useEffectOnce(() => {
        if (!Array.isArray(trusteeStore.trusteeCancelations)) {
            trusteeStore.GetTrusteeCancelations()
        }
    })

    return (
        <div style={AdditionalStyles} className={AdditionalStyles.mainContainer}>
            <div className={AdditionalStyles.pageHeader}>
                <Button
                    qaid='trustee.orders.back'
                    label='Back'
                    inverse
                    cancel
                    onClick={() => history.push('/trustee')}
                />
            </div>

            <div className={AdditionalStyles.pageBody}>
                {/* <Table
                    columns={cancelationsColumns}
                    rows={trusteeStore.trusteeCancelations}
                /> */}

                <PagedTable
                    newDataReq={trusteeStore.GetTrusteeCancelationsByParams}
                    tableData={trusteeStore.trusteeCancelations.orders}
                    totalRows={trusteeStore.trusteeCancelations.totalNumberOfOrders}
                    tableKeys={trusteeCancelationKeysMain}
                />
            </div>
        </div>
    )
}

export default observer(TrusteeCancelations)