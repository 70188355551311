import classNames from "classnames";
import Styles from "./index.style";
import { IC_CHECK_WHITE2 } from "../../../../Assets";
import Image from "../../Image";

type StepProps = {
    onClick: () => void;
    number: number;
    caption: string;
    isActive: boolean;
    isCompleted?: boolean;
    isLastStep: boolean;
    isLastStepHighlighed: boolean;
};

const Step = ({ onClick, number, caption, isActive, isCompleted, isLastStep, isLastStepHighlighed }: StepProps) => {
    return (
        <div
            className={classNames(Styles, {
                active: isActive,
                completed: isCompleted,
                last: isLastStep,
                highlight: isLastStepHighlighed,
            })}
            onClick={onClick}
            data-qaid={`ProgressBar.Link.Step-${number}`}
        >
            <div className="number-circle">
                <div className="number">
                    {isCompleted ? (
                        <Image
                            width={"1.8rem"}
                            src={IC_CHECK_WHITE2}
                            alt="Completed"
                        />
                    ) : (
                        number
                    )}
                </div>
            </div>
            <div className="caption">{caption}</div>
        </div>
    );
};

export default Step;
