import { makeAutoObservable } from "mobx";
import { clearPersistedStore, makePersistable } from "mobx-persist-store";
import { RootStore } from "./RootStore";
import { DocumentsService } from "../Services/DocumentsService";
import { DocumentTemplateInfo } from "../Models/API/Document/template-info";
import { DocumentTemplatePayload } from "../Models/App/Document/template-payload";
import { NumberOption } from "../Models/API/All/NumberOption";
import { ElectronicSignature } from "../Models/API/Document/eletronics-signature";
import { SendForSingaturePayload } from "../Models/API/Document/send-for-signature-payload";
import { FileExtensionEnum, TemplateTypeEnum } from "../Models/API/Document/template-type-enum";

type Tab = "signatures" | "templates";

export class DocumentsStore {
	documentsService: DocumentsService = new DocumentsService(0);
	templates: DocumentTemplateInfo[] | undefined = undefined;
	selectedTab: Tab = 'signatures';
	docs: ElectronicSignature[] | undefined = undefined;

	constructor(private rootStore: RootStore) {
		makeAutoObservable(this);
		makePersistable(this, {
			name: 'DocumentStore',
			properties: ['templates', 'selectedTab'],
			storage: window.sessionStorage,
			expireIn: 10800000, // 3 hours
		});
		rootStore.companyStore.onCompanyChange.subscribe(() => {
			if (!this.rootStore.companyStore.companyId) return;
			this.documentsService = new DocumentsService(rootStore.companyStore.companyId);
			this.resetStoreToDefaultValues();
		});
	}

	get selectableTemplate(): NumberOption[] {
		return this.availableTemplates?.map((template) => ({ value: template.templateId, label: template.templateName })) || [];
	}

	get getTemplatesByType() {
		return (type: TemplateTypeEnum) => this.availableTemplates?.filter((t) => t.documentType === type) || [];
	}

	get getTemplatesOptionsByType() {
		return (type: TemplateTypeEnum) =>
			this.templates?.reduce((acc, t) => {
				if ((t.isDeleted === false && t.documentType) === type) acc.push({ value: t.templateId, label: t.templateName });
				return acc;
			}, [] as NumberOption[]);
	}

	get availableTemplates() {
		return this.templates?.filter((t) => t.isDeleted === false);
	}

	get defaultTemplates(): Partial<DocumentTemplateInfo>[] {
		return [
			{
				templateName: 'Grant Letter',
				documentType: TemplateTypeEnum.GrantLetter,
			},
			{
				templateName: 'Share Certificate',
				documentType: TemplateTypeEnum.ShareCertificate,
			},
		];
	}

	get getTemplateById() {
		return (templateId: string) => this.templates?.find((u) => u.templateId === templateId);
	}

	resetStoreToDefaultValues() {
		this.templates = undefined;
		this.docs = undefined;
		this.selectedTab = 'signatures';
		clearPersistedStore(this);
	}

	async getTemplates() {
		const res = await this.documentsService.getTemplates();
		if (res.isSuccess) {
			this.templates = res.data.data;
		}
		return res;
	}

	setSelectedTab = (tab: Tab) => {
		this.selectedTab = tab;
	};

	getTemplate(templateId: string, isPreview: boolean = false) {
		return this.documentsService.getTemplate(templateId, isPreview);
	}

	getDefaultTemplate(documentType: TemplateTypeEnum, fileExtension: FileExtensionEnum) {
		return this.documentsService.getDefaultTemplate(documentType, fileExtension);
	}

	async createTemplate(template: DocumentTemplatePayload) {
		const res = await this.documentsService.createTemplate(template);
		if (res.isSuccess) {
			this.templates = this.templates ? [...this.templates, res.data.data] : [res.data.data];
		}
		return res;
	}

	async updateTemplate(template: DocumentTemplatePayload) {
		const res = await this.documentsService.updateTemplate(template);
		if (res.isSuccess && this.templates) {
			const templateIdx = this.templates?.findIndex((temp) => temp.templateId === res.data.data.templateId);
			if (templateIdx !== -1) {
				this.templates = [...this.templates.slice(0, templateIdx), res.data.data, ...this.templates.slice(templateIdx + 1)];
			}
		}
		return res;
	}

	async deleteTemplate(templateId: string) {
		const res = await this.documentsService.deleteTemplate(templateId);
		if (res.isSuccess) {
			this.templates = this.templates?.filter((temp) => temp.templateId !== templateId.toString()); // to fix
		}

		return res.isSuccess;
	}

	getDocument(docId: string) {
		return this.documentsService.getDocument(docId);
	}

	async getDocuments() {
		const res = await this.documentsService.getDocuments();
		if (res.data) {
			this.docs = res.data.data.electronicSignatures;
		}

		return res;
	}

	signDocuments(documentId: string[], signerName: string) {
		return this.documentsService.signDocuments(documentId, signerName);
	}

	deleteDocument(documentId: string) {
		return this.documentsService.deleteDocument(documentId);
	}

	cancelDocument(documentId: string) {
		return this.documentsService.cancelDocument(documentId);
	}

	// Signature

	sendForSignature(payload: SendForSingaturePayload) {
		return this.documentsService.sendForSignature(payload);
	}

	getDocumentSignatures(docId: string) {
		return this.documentsService.getDocumentSignatures(docId);
	}
}
