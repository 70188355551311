import styled from "@emotion/styled";
import { css } from "@emotion/css";
import appConfig from "../../../config/config";

export const inputClassName = css({
    label: "Input",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "1.2rem",
    paddingRight: "1.2rem",
    color: appConfig.style.colors.text1,
    transition: "all .1s",
    height: "3.6rem",
    width: "100% !important",
    letterSpacing: 1,
    // fontFamily: appConfig.style.fonts.assistant.regular, // Daniel - temp comment until all inputs will be replaced
    border: `1px solid ${appConfig.style.colors.border1}`,
    borderRadius: appConfig.style.borderRadius.medium,
    outline: "none",
    "&.inverse": {
        boxShadow: "unset",
        border: "unset",
        borderBottom: `1px solid ${appConfig.style.colors.field}`,
        borderRadius: 0,
    },
    background: "#ffffff",
    // "&:after": {
    // 	content: 'attr(data-value) "%"',
    // },
    "&:disabled": {
        background: "#EBEBE455",
        ".Table--PreferencesTerms &": {
            background: "unset",
        },
    },
    "&.flat": {
        outline: "none",
        padding: 0,
        margin: 0,
        border: "none",
        width: "100%",
    },
    "&:focus": {
        borderColor: appConfig.style.colors.color1,
        "+ .options-container": {
            // display: 'flex',
            opacity: 1,
            visibility: "visible",
        },
    },
});

export const StyledInput = styled.input(
    {},
    ({
        percentage,
        isMerged,
        flex = 0,
        isTotal,
    }: {
        percentage?: boolean;
        disabled?: boolean;
        isMerged?: boolean;
        flex?: number | string;
        isTotal?: boolean;
    }) => ({
        flex,
        // ...(percentage
        // 	? {
        // 			paddingRight: "2rem",
        // 	  }
        // 	: {}),
        ...(isMerged
            ? {
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
            }
            : {}),
        ...(isTotal
            ? {
                background: appConfig.style.colors.purple,
                borderWidth: 2,
                "&:disabled": {
                    background: appConfig.style.colors.purple,
                },
            }
            : {}),
    })
);

export const TextAreaInput = styled.textarea({}, ({ rows }: { rows?: number; autoExpand?: boolean }) => ({
	borderRadius: appConfig.style.borderRadius.medium,
	border: `1px solid ${appConfig.style.colors.border1}`,
	padding: '1.2rem',
	color: appConfig.style.colors.text1,
	'&:focus': {
		outline: 'none',
		borderColor: appConfig.style.colors.color1,
	},
}));

const Styles = css({
    label: "Input-Wrapper",
    display: "flex",
    flexDirection: "column",
    // marginBottom: "2rem",
    // position: "relative",
    // ".waterfall &, .Captable__menu &": {
    // 	marginBottom: "0",
    // },
    ".label-container": {
        marginBottom: 0,
    },

    ".label": {
        marginBottom: "0.4rem",
        display: "flex",
        justifyContent: "space-between",
        color: appConfig.style.colors.text2,
        fontSize: "1.3rem",
        alignItems: "flex-end",

        "@media (-webkit-device-pixel-ratio: 1.5)": {
            fontSize: "1.4rem",
        },

        "&__info": {
            width: "1.8rem",
        },

        "> span": {
            flex: 1,
            textAlign: "initial",
        },
    },
    "&__inputContainer": {
        display: "flex",
        // position: "relative",
        "&.relative": {
            position: "relative",
            "&.with-padding": {
                input: {
                    paddingRight: "3rem",
                },
            },
            "&.with-padding-left": {
                input: {
                    paddingLeft: "2.4rem",
                },
            },
        },
        ".react-datepicker-wrapper": {
            width: "100%",
            input: {
                paddingLeft: "4.8rem",
            },
        },
        ".select": {
            // borderRight: "none",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
        },
        ".icon": {
            position: "absolute",
            cursor: "pointer",
            top: 1,
            left: 1,
            width: "calc(4rem - 2px)",
            maxWidth: "4rem",
            zIndex: 1,
            // background: appConfig.style.colors.color1Secondary,
            borderTopLeftRadius: ".8rem",
            borderBottomLeftRadius: ".8rem",
            height: "calc(100% - 2px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            userSelect: "none",
            padding: "0.6rem",
            // boxShadow: `1px 1px 1px 0px ${appConfig.style.colors.lightGray}`,
            // border: `1px solid ${appConfig.style.colors.color1}`,
            img: {
                marginTop: "0.1rem",
                width: "85%",
                height: "85%",
            },
            "&.inverse": {
                background: "#ffffff",
                height: "calc(100% - 0.5rem)",
            },
            "&:not(.disabled).ic-calendar": {
                "&:hover": {
                    transition: "all 0.3s",
                    background: appConfig.style.colors.background1,
                },
            },
            "&.disabled": {
                cursor: "default !important",
            },
        },
    },
    ".options-container": {
        // overflow: "auto",
        // maxHeight: 163,
        // boxShadow: appConfig.style.boxShadow.card1,
        // background: "white",
        // top: "calc(100% + 1rem)",
        // left: 0,
        // width: "100%",
        // position: "absolute",
        // display: "flex",
        // flexDirection: "column",
        // border: `1px solid ${appConfig.style.colors.background1}`,
        // borderRadius: 3,
        // zIndex: -1,
        // minHeight: 33,
        opacity: 0,
        visibility: "hidden",
    },
    ".loading-spinner": {
        position: "absolute",
        right: "1rem",
        top: 0,
        bottom: 0,
        margin: "auto",
    },
    "&__suffix": {
        position: "absolute",
        right: 0,
        transform: "translate(-100%, calc(50% - 0.2rem))",
        color: appConfig.style.colors.text2,
        // "@media screen and (min-width: 1600px)": {
        //   transform: "translate(-100%, calc(50% - 3px))",
        // },
    },
    "&__end-icon": {
        position: "absolute",
        // cursor: "pointer",
        top: "calc(50% - 0.9rem)",
        right: "1rem",
        width: "1.8rem",
        zIndex: 1,
    },
    "&.flat": {
        outline: "none",
        padding: 0,
        margin: 0,
        border: "none",
        width: "100%",
    },
    "&__prefix": {
        position: "absolute",
        left: 0,
        transform: "translate(100%, calc(50% - 0.2rem))",
        color: appConfig.style.colors.text2,
    },
});

export const Comment = styled.div(
    {
        label: "Comment",
        marginTop: "0.1rem",
        fontSize: "1.1rem",
        // marginTop: "0.4rem",
        color: appConfig.style.colors.text3,
        whiteSpace: "break-spaces",
        textAlign: "end",
        width: "100%",
        maxWidth: "fit-content",
        right: 0,
        "@media (-webkit-device-pixel-ratio: 1.5)": {
            fontSize: "1.2rem",
        },
    },
    ({ error, center, absolute = true }: { error?: boolean; center?: boolean; absolute?: boolean }) => ({
        ...(error
            ? {
                textAlign: "start",
                right: "unset",
                color: appConfig.style.colors.danger,
                // position: "absolute",
                width: "100%",
            }
            : {}),
        ...(center
            ? {
                textAlign: "center",
                position: "unset",
                width: "100%",
                right: "unset",
                margin: "auto",
            }
            : {}),
        position: absolute ? "absolute" : "unset",
    })
);

export default Styles;
