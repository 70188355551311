import { css } from "@emotion/css";
import { breakpoints } from "../../../../config/style/theme";
import appConfig from "../../../../config/config";

export const StakeholdersStyle = css({
    label: "Stakeholders",
    position: "relative",

    ".container": {
        alignItems: "center",
        flex: 1,
        flexDirection: "row",
        margin: 0,
        marginTop: "1.5rem",

        ".leftSide": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRight: `1px solid ${appConfig.style.colors.table}`,
            paddingRight: "9.2rem",
            marginRight: "4rem",
            width: "fit-content",

            "&__image": {
                width: "8rem",
                height: "8rem",
            },

            ".totalAmount": {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "cetner",
                marginTop: "1.5rem",
                width: "fit-content",

                "&__text": {
                    color: "#4D4D4D",
                    // fontSize: "1.6rem",
                },

                "&__amount": {
                    color: "#4D4D4D",
                    fontSize: "3rem",
                    fontWeight: 700,
                    textAlign: "center",
                },
            },
        },

        ".rightSide": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            height: "100%",

            ".infoContainer": {
                alignItems: "start",
                flexDirection: "column",
                flex: 0,

                "&__title": {
                    flex: 1,
                },

                "&__tooltip": {
                    marginTop: "0.4rem",
                },

                "&__info": {
                    fontWeight: 700,
                },
            },
        },
    },

    [`@media screen and (max-width: ${breakpoints.lg})`]: {
        ".infoContainer": {
            fontSize: "1.6rem",

            "&__title": {
                marginBottom: "0.8rem",
            },
        },

        ".totalAmount": {
            fontSize: "1.6rem",
        },
    },
});
