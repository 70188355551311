import { EffectCallback, useEffect, useState } from "react";

function useEffectOnce(callback: EffectCallback, deps: any[] = [], condition?: boolean) {
	const [isOccured, setIsOccurred] = useState<boolean>(false);

	useEffect(() => {
		if (isOccured || !condition) return;
		callback();
		setIsOccurred(true);
	}, deps);
}

export default useEffectOnce;
