import classNames from 'classnames';
import { IC_PAYMENT_PLAN_CHECK } from '../../../../Assets';
import Image from '../../../../Shared/Components/Image';
import '../Plan/Plan.Style';

export interface IPlanFeature {
	readonly label: string;
	readonly tooltip?: string | JSX.Element;
	custom?: () => JSX.Element;
}

export const starterFeatures: IPlanFeature[] = [
	{
		label: 'Cap table management',
	},
	{
		label: 'Equity plans management',
	},
	{
		label: 'Funding scenarios (limited to 5 simulations)',
	},
	{
		label: 'SAFE & Convertible notes',
	},
	{
		label: 'Employee portal',
	},
	{
		label: 'AI based automatic onboarding',
	},
	{
		label: 'Email support',
	},
];

export const growthFeatures: IPlanFeature[] = [
	{
		label: 'Electronic securities issuance - share certificate, grant letters & more',
	},
	{
		label: 'Advanced Funding scenarios',
		tooltip: 'Unlimited scenarios smart comparison',
	},
	{
		label: 'Electronic options exercises',
	},
	{
		label: '409A valuations (extra fee)',
		tooltip: (
			<>
				Price: $1,600 per report
				<br />
				For small companies up to 10 shareholders - $1,100 per report
			</>
		),
	},
	{
		label: 'Onboarding consultant',
	},
	{
		label: 'Priority support',
	},
];

export const scaleFeatures: IPlanFeature[] = [
	{
		label: 'Waterfall modeling',
	},
	{
		label: 'SBC expensing reports (ASC 718, IFRS2)',
	},
	{
		label: 'HRIS integration',
	},
	{
		label: '409A valuations (extra fee)',
		tooltip: 'Price: $1,600 per report',
	},
	{
		label: 'Full service onboarding',
	},
	{
		label: 'Dedicated customer success manager',
	},
];

export const enterpriseFeatures: IPlanFeature[] = [
	{
		label: '',
		custom: () => (
			<div className="customContainer">
				<Image src={IC_PAYMENT_PLAN_CHECK} alt="check" className="featureContainer__icon" />
				<div>
					<span className="title">All inclusive</span>
					<span>Full access to our entire products at any capacity, including up to two 409A valuations per year</span>
				</div>
			</div>
		),
	},
];

export const customFeatures: IPlanFeature[] = [
	{
		label: 'Unlimited stakeholders',
	},
	{
		label: '409A valuation reports',
	},
	{
		label: 'Dedicated customer success manager',
	},
	{
		label: 'Full-service onboarding by us',
	},
	{
		label: 'And more tailored your needs',
	},
];
