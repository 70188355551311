import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Routes } from "../../../../Routes";
import Button from "../../../../Shared/Components/Button/Button";
import Beneficiaries from "../Beneficiaries/Beneficiaries";
import DataLog from "../DataLog/DataLog";
import DataManager from "../DataManager/DataManager";
import Grants from "../Grants/Grants";
import CompanySettings from "../Settings/CompanySettings";
import EquityDashboard from "./EquityDashboard";
import EquityNewDashboard from "../../Dashboard/EquityDashboard";
import Orders from "../Orders/Orders";
import Cancelations from "../Cancelations/Cancelations";

const EquityEntitySelector = () => {
    // const [equityEntity, setEquityEntity] = useState<number | undefined>()
    const params = useParams<{ entity: string | undefined }>();
    const history = useHistory();

    // useEffect(() => {
    //     params.entity && setEquityEntity(+params.entity)
    // }, [params])
    const backToDashboard = () => {
        history.push(Routes.options7.index);
    };

    switch (params.entity) {
        case undefined:
            return <EquityNewDashboard />
        // return <EquityDashboard />;
        case "beneficiaries":
            return (
                <>
                    <Beneficiaries rtd={backToDashboard} />
                </>
            );
        case "grants":
            return (
                <>
                    <Grants rtd={backToDashboard} />
                </>
            );
        case "settings":
            return (
                <>
                    <CompanySettings rtd={backToDashboard} />
                </>
            );
        case "data-manager":
            return (
                <>
                    <DataManager rtd={backToDashboard} />
                </>
            );
        case "data-log":
            return (
                <>
                    <DataLog rtd={backToDashboard} />
                </>
            );
        case "orders":
            console.log('ORdering')
            return (
                <>
                    <Orders rtd={backToDashboard} />
                </>
            );
        case "cancelations":
            return (
                <>
                    <Cancelations rtd={backToDashboard} />
                </>
            );
        case "dashboard":
            return (
                <>
                    <EquityNewDashboard />
                </>
            );
        default:
            return (
                <>
                    <Button qaid="" onClick={backToDashboard}>
                        Back To Dashboard
                    </Button>
                </>
            );
    }
};
export default EquityEntitySelector;
