import { css } from "@emotion/css";
import appConfig from "../../../../../config/config";
import styled from "@emotion/styled";
import { Cell } from "../../../../../Shared/Components/Table/Table.Style";

const GrantsTableStyle = css({
    label: "GrantsTable",
    "&__label": {
        fontWeight: 500,
        color: appConfig.style.colors.text1,
    },
    "&__section": {
        display: "flex",
        flexDirection: "column",
    },
    "&__table": {
        label: "GrantsTable",
        border: `1px solid ${appConfig.style.colors.table}`,
        overflow: "unset !important",
        maxHeight: "unset !important",
        // "*": {
        //     justifyContent: "center !important",
        //     alignItems: "center !important",
        // },
    },
    "&__actions": {
        display: "flex",
        width: "100%",
        gap: "1.2rem",
        justifyContent: "flex-end",
        "> button": {
            alignSelf: "center",
        },
    },
    "&__editable-row": {
        ".row-edit-menu": {
            opacity: 0,
            visibility: "hidden",
            transition: "all .3s",
        },
        "&:hover": {
            ".row-edit-menu": {
                opacity: 1,
                visibility: "visible",
            },
        },
    },
});

export const TableCell = styled(Cell)(() => ({
    justifyContent: "center",
    alignItems: "center",
}));

export default GrantsTableStyle;
