import Flex from "../../../Shared/Components/Layout/Flex";
import { Skeleton } from "@mui/material";
import { RectangularSkeleton } from "../index.style";

const StakeholdersLoader = () => {
	return (
		<Flex direction="column" align="start" gap={40}>
			<Flex gap={60}>
				<Flex direction="column" align="center" justify="center" gap={10}>
					<Skeleton variant="circular" width={80} height={80} animation="wave" />
					<Skeleton variant="rectangular" width={"30%"} height={"10%"} animation="wave" className={RectangularSkeleton} />
				</Flex>
				<Flex direction="column" align="start" gap={20} flex={1}>
					<Skeleton variant="rectangular" width={"70%"} height={"10%"} animation="wave" className={RectangularSkeleton} />
					<Skeleton variant="rectangular" width={"30%"} height={"10%"} animation="wave" className={RectangularSkeleton} />
					<Skeleton variant="rectangular" width={"70%"} height={"10%"} animation="wave" className={RectangularSkeleton} />
					<Skeleton variant="rectangular" width={"30%"} height={"10%"} animation="wave" className={RectangularSkeleton} />
				</Flex>
			</Flex>
		</Flex>
	);
};

export default StakeholdersLoader;
