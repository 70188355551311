import { FC } from "react";
import WithToggle from "../WithToggle/WithToggle";
import { Wrapper } from "./WidgetDashboard.style";
import useRootStore from "../../../../../Hooks/useRootStore";

interface WidgetDashboardProps {
    isOpen?: boolean;
    children: React.ReactNode;
    onToggle?: (val: boolean) => void;
}

const WidgetDashboard: FC<WidgetDashboardProps> = (props: WidgetDashboardProps) => {
    const { appState } = useRootStore();

    return (
        <WithToggle isOpen={props.isOpen} onToggle={props.onToggle}>
            <Wrapper>{props.children}</Wrapper>
        </WithToggle>
    );
};

export default WidgetDashboard;
