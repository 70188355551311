import { FC } from "react";
import Widget, { WidgetProps } from "../../../../Shared/Components/WidgetDashboard/shared/components/Widget/Widget";
import { StyledDashboardWidget, StyledDashboardWidgetHeader, StyledDashboardWidgetSubtitle, StyledDashboardWidgetTitle } from "./DashboardWidget.style";

export interface DashboardWidgetProps extends WidgetProps {
	readonly title: string;
	readonly subtitle?: string;
}

const DashboardWidget: FC<DashboardWidgetProps> = (props: DashboardWidgetProps) => {
	const { title, subtitle, children } = props;

	return (
		<Widget>
			<StyledDashboardWidget>
				<StyledDashboardWidgetHeader>
					<StyledDashboardWidgetTitle>{title}</StyledDashboardWidgetTitle>
					{subtitle && <StyledDashboardWidgetSubtitle>{subtitle}</StyledDashboardWidgetSubtitle>}
				</StyledDashboardWidgetHeader>
				{children}
			</StyledDashboardWidget>
		</Widget>
	);
};

export default DashboardWidget;
