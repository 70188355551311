import { css } from "@emotion/css";
import appConfig from "../../../../../config/config";
import { AmortizationDate, ExpensingBasicCompany, ExpensingMethod } from "../../../../../Models/API/Expensing/options-migration";
import { AppendStateInputHandlerType } from "../../../../../Shared/Hooks/useAppendState";
import useRootStore from "../../../../../Shared/Hooks/useRootStore";
import Separator from "../../../../../Shared/Components/Separator";
import Select from "../../../../../Shared/Components/Select/Select";
import RadioButton from "../../../../../Shared/Components/RadioButton/RadioButton";
import { toJS } from "mobx";

const Style = css({
    label: "ExpensingGeneralSettings",
    display: "flex",
    flexDirection: "column",
    gap: "2.4rem",
    width: "100%",
    "&__label": {
        fontWeight: 500,
        color: appConfig.style.colors.text1,
    },
    "&__select": {
        maxWidth: "16rem",
    },
    "&__section": {
        display: "flex",
        flexDirection: "column",
    },
    "&__radio-container": {
        display: "flex",
        padding: "1.6rem 0",
        "> *": {
            minWidth: "30rem",
        },
    },
});

type Props = {
    migration: ExpensingBasicCompany;
    onInputHandler: AppendStateInputHandlerType;
};

const General = ({ migration, onInputHandler }: Props) => {
    const { currenciesOptions, expensingStore, companyStore } = useRootStore();
    // const [migration, setMigration, onInputHandler] = useAppendState<OptionsMigration>({
    //     companyName: companyStore.company.companyLegalName,
    //     operationalCurrency: currenciesOptions.find((c) => c.value === Currencies.USD)?.value,
    //     expensingMethod: ExpensingMethod.accelerated,
    //     expensingAmortizationStartDate: AmortizationDate.vestingStartDate,
    // } as OptionsMigration);

    return (
        <div className={Style}>
            <div className={`${Style}__section`}>
                <span className={`${Style}__label`}>Functional Currency</span>
                <Separator />
                <Select
                    qaid="Expensing.Select.Currency"
                    options={currenciesOptions}
                    label="Select currency"
                    onChange={onInputHandler}
                    name="operationalCurrency"
                    required
                    value={migration.operationalCurrency}
                    className={`${Style}__select`}
                />
            </div>
            <div className={`${Style}__section`}>
                <span className={`${Style}__label`}>Expenses amortization date</span>
                <Separator />
                <div className={`${Style}__radio-container`}>
                    <RadioButton
                        qaid="ExpensingGeneral.Radio.VestingStartDate"
                        name="expensingAmortizationStartDate"
                        value={AmortizationDate.VestingStartDate}
                        checked={migration.expensingAmortizationStartDate === AmortizationDate.VestingStartDate}
                        onChange={onInputHandler}
                        label="Vesting start date"
                    />
                    <RadioButton
                        qaid="ExpensingGeneral.Radio.GrantDate"
                        name="expensingAmortizationStartDate"
                        value={AmortizationDate.GrantDate}
                        checked={migration.expensingAmortizationStartDate === AmortizationDate.GrantDate}
                        onChange={onInputHandler}
                        label="Grant date"
                    />
                </div>
                <Separator />
            </div>
            <div className={`${Style}__section`}>
                <span className={`${Style}__label`}>Expenses calculation method</span>
                <Separator />
                <div className={`${Style}__radio-container`}>
                    <RadioButton
                        qaid="ExpensingGeneral.Radio.Accelerated"
                        name="expensingMethod"
                        value={ExpensingMethod.Accelerated}
                        checked={migration.expensingMethod === ExpensingMethod.Accelerated}
                        onChange={onInputHandler}
                        label="Accelerated"
                    />
                    <RadioButton
                        qaid="ExpensingGeneral.Radio.NonRatable"
                        name="expensingMethod"
                        value={ExpensingMethod.StraightLine}
                        checked={migration.expensingMethod === ExpensingMethod.StraightLine}
                        onChange={onInputHandler}
                        label="Non ratable straight line"
                    />
                </div>
                <Separator />
            </div>
        </div>
    );
};

export default General;
