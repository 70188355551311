import RadioButton from '../../../../../Shared/Components/RadioButton/RadioButton';
import useRootStore from '../../../../../Shared/Hooks/useRootStore';
import useEsopModals from '../../../useEsopModals';

import Title from '../../../../../Shared/Components/Layout/Title';
import { css } from '@emotion/css';
import appConfig from '../../../../../config/config';

const Style = css({
	label: 'SelectDataSource',
	'&__header': {
		paddingBottom: '1.6rem',
		borderBottom: `1px solid ${appConfig.style.colors.table}`,
		marginBottom: '2.5rem',
	},
	'&__selection-container': {
		display: 'flex',
		flexDirection: 'column',
		gap: '4rem',
		'> span': {
			color: appConfig.style.colors.text2,
		},
		borderBottom: `1px solid ${appConfig.style.colors.table}`,
		paddingBottom: '2.5rem',
		marginBottom: '4rem',
	},
	'&__radios': {
		display: 'flex',
		gap: '6rem',
	},
});

const SelectDataSource = () => {
	const { companyStore, trusteeStore, auth } = useRootStore();
	const { showChangeDataSource } = useEsopModals();

	const equityTxt = 'Create & manage your data actively using Equity Plans product';
	const trusteeTxt = 'All the equity plans data is synchronized from altshare trustee service';

	const poolSummaryAndAutorization = async () => {
		trusteeStore.GetPoolSummary();
	};

	const equitySourceSelectorHandler = (fromPivotal: boolean) => {
		if (fromPivotal === companyStore.company.pivotalEquityPlans) return;

		showChangeDataSource(fromPivotal);

		if (fromPivotal) {
			poolSummaryAndAutorization();
		}
	};

	return (
		<div className={Style}>
			<div className={`${Style}__header`}>
				<Title>Equity plans data source</Title>
			</div>

			<div className={`${Style}__selection-container`}>
				<span>Choose import from:</span>
				<div className={`${Style}__radios`}>
					<RadioButton
						name="dataFrom"
						qaid=""
						value={!companyStore.company.pivotalEquityPlans}
						checked={!companyStore.company.pivotalEquityPlans}
						label="Equity plans product"
						onChange={() => equitySourceSelectorHandler(false)}
					/>
					<RadioButton
						name="dataFrom"
						qaid=""
						value={companyStore.company.pivotalEquityPlans}
						checked={companyStore.company.pivotalEquityPlans}
						label="altshare trustee data"
						onChange={() => equitySourceSelectorHandler(true)}
					/>
				</div>
				<span>{companyStore.company.pivotalEquityPlans ? trusteeTxt : equityTxt}</span>
			</div>
		</div>
	);
};

export default SelectDataSource;
