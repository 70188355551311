import { css } from "@emotion/css";
import { observer } from "mobx-react-lite";
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { IC_CHECK_GRAY, IC_CHECK_SECONDARY, IC_EDIT2, IC_INACTIVE, IC_TRASH2 } from "../../../../../Assets";
import { Peer } from "../../../../../Models/API/Expensing/peer";
import { EditableGlobalPeer } from "../../../../../Models/App/Expensing/editable-global-peer";
import { EditablePeer } from "../../../../../Models/App/Expensing/editable-peer";
import { InputRef } from "../../../../../Models/App/Inputs/types";
import Button from "../../../../../Shared/Components/Button/Button";
import AutoCompleteInput from "../../../../../Shared/Components/Input/AutoCompleteInput";
import NumberInput from "../../../../../Shared/Components/Input/NumberInput";
import Menu from "../../../../../Shared/Components/Menu";
import Separator from "../../../../../Shared/Components/Separator";
import Spinner from "../../../../../Shared/Components/Spinner/Spinner";
import Table, { TableColumn } from "../../../../../Shared/Components/Table/Table";
import { Cell } from "../../../../../Shared/Components/Table/Table.Style";
import { useAppendState } from "../../../../../Shared/Hooks/useAppendState";
import { ForwardedRef } from "../../../../../Shared/Hooks/useMultiStepForm";
import useRootStore from "../../../../../Shared/Hooks/useRootStore";
import { isNullOrUndefined, isNumber } from "../../../../../Shared/Utilities";
import appConfig from "../../../../../config/config";
import { InputValidationRef, useFormValidation } from "../../../../../Shared/Hooks/useFormValidation";
import { commonValidators } from "../../../../../Shared/ObjectValidator";
import useGeneralModal from "../../../../../Shared/Hooks/useGeneralModal";
import { Comment } from '../../../../../Shared/Components/Input/Input.Style';

const Style = css({
	label: 'ExpensingPeers',
	display: 'flex',
	flexDirection: 'column',
	gap: '2.4rem',
	width: '100%',
	'&__label': {
		fontWeight: 500,
		color: appConfig.style.colors.text1,
	},
	'&__section': {
		display: 'flex',
		flexDirection: 'column',
	},
	'&__form': {
		display: 'flex',
		gap: '2.4rem',
		'> .input-wrapper, > .button-wrapper': {
			flex: 1,
		},
		'> .button-wrapper': {
			alignSelf: 'flex-end',
			display: 'flex',
			justifyContent: 'flex-end',
		},
	},
	'&__actions': {
		position: 'absolute',
		background: '#ffffff',
		transition: 'opacity .3s',
		right: 0,
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		paddingLeft: '1rem',
	},
	'.row-hover': {
		color: appConfig.style.colors.color1,
	},
	'.row-inactive': {
		color: appConfig.style.colors.text2,
	},
});

type Props = {
	isInitial?: boolean;
};

const ComparablePeers = forwardRef<ForwardedRef, Props>(({ isInitial = false }, forwardedRef) => {
	const { expensingStore } = useRootStore();
	const [availablePeers, setAvailablePeers] = useState<EditableGlobalPeer[]>();
	const [peers, setPeers] = useState<Peer[]>();
	const [peer, setPeer, onPeerInputHandler] = useAppendState<EditablePeer>();
	const [isAdding, setIsAdding] = useState<boolean>(false);
	const [rowHoverId, setRowHoverId] = useState<number>(-1);
	const [editId, setEditId] = useState<number>(-1);
	const editPeer = useRef<Peer>();
	const editRef = useRef<InputRef>(null);
	const { showErrorModal } = useGeneralModal();

	const rows = isInitial ? peers?.filter((p) => p.isActive) : peers;
	const total = useMemo(() => {
		return rows?.reduce(
			(acc, p) => {
				if (p.isActive) {
					acc.peerWeight += p.peerWeight;
				}
				return acc;
			},
			{ peerWeight: 0 } as Peer
		);
	}, [rows]);

	const { formValidationState, validateForm, inputRefs, isFormValid } = useFormValidation({
		form: peer,
		schema: {
			id: [commonValidators.required()],
			peerWeight: [
				commonValidators.requiredIf(() => !!peer.id),
				(value) => {
					if (value === 0) {
						return {
							isValid: false,
							message: 'Please enter a valid number',
						};
					}
					const total = peers?.reduce((acc, peer) => acc + (peer.isActive ? peer.peerWeight : 0), 0) ?? 0;

					if (isNullOrUndefined(value) || value + total <= 100) {
						return {
							isValid: true,
						};
					}

					return {
						isValid: false,
						message: 'Total weight cannot exceed 100%',
					};
				},
			],
		},
		deps: isInitial ? [peers] : [peers, total],
	});

	useImperativeHandle(forwardedRef, () => ({
		onValidate() {
			if (!peers?.length) return true;
			return peers.reduce((acc, p) => acc + p.peerWeight, 0) === 100;
		},
	}));

	useEffect(() => {
		(async () => {
			const res = await Promise.all([expensingStore.getAvailablePeers(), expensingStore.getCompanyPeers()]);
			const unfilteredPeers = res[1].data?.data || [];

			if (res[0].data?.data) {
				setAvailablePeers(
					res[0].data?.data.map((p) => {
						const peerObj = unfilteredPeers.find((unfilteredPeer) => unfilteredPeer.refinitivIdentifier === p.refinitivIdentifier);
						// return !peerObj?.isActive;
						return { ...p, isSelected: !!peerObj?.isActive };
					})
				);
			}
			setPeers(unfilteredPeers);
		})();
	}, []);

	const peersOptions = useMemo(
		() => availablePeers?.filter((p) => !p.isSelected).map((p) => ({ value: p.id, label: p.companyName, description: p.ticker })),
		[availablePeers]
	);

	if (isNullOrUndefined(availablePeers)) {
		return <Spinner attachParent incorporated center />;
	}

	const updatePeer = async (obj?: EditablePeer) => {
		if (isNullOrUndefined(obj) || isNullOrUndefined(obj.peerWeight)) return;

		const res = await expensingStore.updateCompanyPeer({
			isActive: obj.isActive,
			peerWeight: obj.peerWeight,
			companyPeerId: obj.companyPeerId,
		});

		setPeers((prevPeers) => {
			if (isNullOrUndefined(res.data) || isNullOrUndefined(prevPeers)) return;
			const peerIdx = prevPeers?.findIndex((p) => p.refinitivIdentifier === obj.refinitivIdentifier);

			if (isNullOrUndefined(peerIdx) || peerIdx === -1) return;
			setEditId(-1);

			setAvailablePeers((prevState) => {
				if (isNullOrUndefined(prevState)) return prevState;

				const pIdx = prevState.findIndex((p) => p.refinitivIdentifier === obj.refinitivIdentifier);
				if (pIdx === -1) return prevState;

				return [
					...prevState.slice(0, pIdx),
					{
						...prevState[pIdx],
						isSelected: obj.isActive,
					},
					...prevState.slice(pIdx + 1),
				];
			});

			return [...prevPeers.slice(0, peerIdx), { ...res.data.data, companyPeerId: obj.companyPeerId ?? 0 }, ...prevPeers.slice(peerIdx + 1)];
		});
	};

	const tableColumns: TableColumn<Peer>[] = [
		{
			name: 'companyName',
			label: 'Company name',
			style: { flex: 2 },
			isOverflow: true,
			render(obj, value) {
				return (
					<Cell style={{ display: 'flex', justifyContent: 'space-between', width: '100%', position: 'relative' }} isOverflow>
						<span>{value}</span>
						<div className={`${Style}__section`}>
							<Menu
								autoClose={() => rowHoverId !== obj.id}
								position={{ left: '4rem', top: '-1.1rem' }}
								items={[
									{
										label: 'Edit',
										icon: IC_EDIT2,
										qaid: 'Peers.Button.Edit',
										onClick: () => {
											setEditId(obj.companyPeerId);
											setTimeout(() => {
												editRef.current?.focus(false);
											}, 100);
										},
									},
									isInitial
										? {
												label: 'Delete',
												icon: IC_TRASH2,
												qaid: 'Peers.Button.Delete',
												onClick: () => {
													updatePeer({
														...obj,
														isActive: false,
													});
												},
										  }
										: obj.isActive
										? {
												label: 'Inactive',
												icon: IC_INACTIVE,
												qaid: 'Peers.Button.Inactive',
												onClick: () => {
													updatePeer({
														...obj,
														isActive: false,
													});
												},
										  }
										: {
												label: 'Active',
												icon: IC_CHECK_GRAY,
												qaid: 'Peers.Button.Active',
												onClick: () => {
													const total = peers?.reduce((acc, p) => acc + (p.isActive ? p.peerWeight : 0), 0) ?? 0;
													if (total + obj.peerWeight > 100) return showErrorModal('Total weight cannot exceed 100%');

													updatePeer({
														...obj,
														isActive: true,
													});
												},
										  },
								]}
								isHorizontal
							/>
						</div>
					</Cell>
				);
			},
		},
		{
			name: 'ticker',
			label: 'Symbol',
			style: { flex: 1 },
		},
		{
			name: 'peerWeight',
			label: 'Weight',
			style: { flex: 1 },
			render(obj, value) {
				if (!obj.isActive && obj.companyPeerId) return '0%';
				if (editId !== obj.companyPeerId) return `${value}%`;
				editPeer.current = obj;
				return (
					<NumberInput
						qaid="EditPeer.Input.Weight"
						value={editPeer.current?.peerWeight}
						percentage
						ref={editRef}
						placeholder="%"
						number="float"
						onChange={(val) => {
							if (isNullOrUndefined(editPeer.current) || isNullOrUndefined(val)) return;
							editPeer.current.peerWeight = val;
						}}
						width="6.5rem"
						onBlur={() => updatePeer(editPeer.current)}
						onEnter={() => updatePeer(editPeer.current)}
					/>
				);
			},
		},
	];

	const onAddPeerHandler = async () => {
		if (!isFormValid || !isNumber(peer.peerWeight) || isNullOrUndefined(expensingStore.company)) return;
		const existingPeer = peers?.find((p) => p.refinitivIdentifier === peer.refinitivIdentifier && p.isActive === false);

		if (existingPeer) {
			await updatePeer(existingPeer);
			setPeer({ peerWeight: undefined } as EditablePeer);
			return;
		}

		setIsAdding(true);
		const res = await expensingStore.addCompanyPeer({
			peerId: peer.id,
			peerWeight: peer.peerWeight,
		});
		setIsAdding(false);

		setPeers((prevState) => {
			if (isNullOrUndefined(res.data)) return prevState;
			setAvailablePeers((prevState) => {
				if (isNullOrUndefined(prevState)) return prevState;

				const pIdx = prevState.findIndex((p) => p.refinitivIdentifier === res.data?.data.refinitivIdentifier);
				if (pIdx === -1) return prevState;

				return [
					...prevState.slice(0, pIdx),
					{
						...prevState[pIdx],
						isSelected: !!res.data?.data.isActive,
					},
					...prevState.slice(pIdx + 1),
				];
			});
			setPeer({ peerWeight: undefined } as EditablePeer);

			return prevState ? [...prevState, res.data.data] : [res.data.data];
		});
	};

	return (
		<div className={Style}>
			<div className={`${Style}__section`}>
				<span className={`${Style}__label`}>Comparable companies</span>
				<Separator />
				<div className={`${Style}__form`}>
					<AutoCompleteInput
						qaid="Peers.Input.CompanyName"
						label="Comparable company"
						onChange={(value) => {
							const p = availablePeers.find((p) => p.id === value);
							p &&
								setPeer((prevState) => ({
									...p,
									isActive: true,
									peerWeight: prevState.peerWeight ?? undefined,
									companyPeerId: undefined,
								}));
						}}
						onInput={(e: any) => {
							if (e.target.value) return;
							setPeer(
								(prevState) =>
									({
										peerWeight: prevState.peerWeight ?? undefined,
									} as EditablePeer)
							);
						}}
						value={peer?.id}
						required
						options={peersOptions}
						containerClassName="input-wrapper"
						placeholder="Type company name or symbol..."
					/>
					<NumberInput
						qaid="Peers.Input.Weight"
						label="Weight"
						name="peerWeight"
						value={peer?.peerWeight}
						percentage
						placeholder="%"
						onChange={onPeerInputHandler}
						required
						containerClassName="input-wrapper"
						error={formValidationState?.peerWeight?.message}
						ref={(el: InputValidationRef) => (inputRefs.peerWeight = el)}
					/>
					<div className="button-wrapper">
						<Button
							className="button"
							qaid="Peers.Button.Add"
							inverse
							disabled={!isFormValid}
							label="Add"
							onClick={onAddPeerHandler}
							isLoading={isAdding}
						/>
					</div>
				</div>
			</div>
			<div className={`${Style}__section`}>
				<Table
					rows={rows && total ? [...rows, total] : rows}
					columns={tableColumns}
					rowClassName={(peer) => {
						let className = '';
						if (peer.companyPeerId === rowHoverId) className += 'row-hover';
						if (peer.isActive === false) className += ' row-inactive';

						return className;
					}}
					rowSize={1.15}
					scrollAfterRows={isInitial ? 4 : 6}
					onRowEnter={(row) => setRowHoverId(row.companyPeerId)}
					onRowLeave={() => setRowHoverId(-1)}
					fallbackText="No companies added yet"
					showTotal
				/>
			</div>
			{!isInitial && !!rows?.length && total?.peerWeight !== 100 && (
				<Comment error absolute={false}>
					Total weight must sum to 100% to calculate volatility
				</Comment>
			)}
		</div>
	);
});

export default observer(ComparablePeers);
