import { getRandomNumberBetween } from '../Utilities'
import firstNameList from './firstNameList.json'
import lastNameList from './lastNameList.json'


export const getDummyName = (): { firstName: string, lastName: string, fullName: string } => {
    const firstName = firstNameList[getRandomNumberBetween(0, firstNameList.length - 1)];
    const lastName = lastNameList[getRandomNumberBetween(0, lastNameList.length - 1)];
    return {
        firstName,
        lastName,
        fullName: `${firstName} ${lastName}`
    }
}