import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { useRef } from "react";
import appConfig from "../../../config/config";
import classNames from "classnames";
import Box from "@mui/material/Box/Box";
import Typography from "@mui/material/Typography";
import MuiCircularProgress from "@mui/material/CircularProgress";

type Size = "sm" | "lg";

const CircleStyle = styled.div(
    {
        label: "Circle",
        position: "relative",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        justifyItems: "center",
        transition: "all .3s",
        boxShadow: `3px 3px 3px 0px ${appConfig.style.colors.boxShadowColor1}`,
        width: "12rem",
        height: "12rem",
        "&::before": {
            content: "''",
            position: "absolute",
            inset: 10,
            background: "#ffffff",
            borderRadius: "50%",
            boxShadow: `inset 3px 3px 3px 0px ${appConfig.style.colors.boxShadowColor1}`,
        },
    },
    ({ percentage, size = "sm" }: { percentage: number; size: Size }) => ({
        background: `conic-gradient(from 0deg, ${appConfig.style.colors.color1} 0%, ${appConfig.style.colors.color1} 0% ${percentage}%, ${appConfig.style.colors.purple} ${percentage}%, ${appConfig.style.colors.purple} 100%)`,
    })
);

const Style = css({
    label: "CircularProgress",
    "&__label": {
        position: "relative",
        textAlign: "center",
        fontSize: "2.4rem",
        height: "fit-content",
        alignSelf: "center",
        color: appConfig.style.colors.color1,
        fontWeight: 700,
    },
    "&__label-small": {
        fontWeight: 500,
        color: appConfig.style.colors.text4,
        fontSize: "1.2rem",
        // marginTop: 2,
    },
    "&__small-container": {
        width: "4rem",
        height: "4rem",
    },
});

type Props = {
    percentage: number;
    className?: string;
    label?: boolean;
    size?: Size;
};

const CircularProgress = ({ percentage, label = true, className = "", size = "sm" }: Props) => {
    const ref = useRef<HTMLDivElement>(null);

    const intPercentage = Math.floor(percentage);

    if (size === "sm") {
        return (
            <Box
                position="relative"
                display="inline-flex"
                style={{ color: appConfig.style.colors.text4 }}
                className={`${Style}__small-container`}
            >
                <MuiCircularProgress
                    value={intPercentage}
                    variant="determinate"
                    color="inherit"
                    style={{ width: "100%", height: "100%" }}
                    thickness={2}
                />
                <Box
                    top={3}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <span className={`${Style}__label-small`}>
                        {`${intPercentage}`}
                        <small>%</small>
                    </span>
                </Box>
            </Box>
        );
    }

    return (
        <CircleStyle
            className={classNames({ [className]: !!className })}
            percentage={intPercentage}
            ref={ref}
            data-progress={intPercentage}
            size={size}
        >
            {label && <span className={`${Style}__label`}>{percentage > 100 ? 100 : percentage < 0 ? 0 : intPercentage}%</span>}
        </CircleStyle>
    );
};

export default CircularProgress;
