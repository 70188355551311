export default class EventHandler<TSource, TArgs> {
	private handlers: Array<(source: TSource, args: TArgs) => void> = [];

	public subscribe(handler: (source: TSource, args: TArgs) => void) {
		this.handlers.push(handler);

		return () => {
			this.unsubscribe(handler);
		};
	}
	public unsubscribe(handler: (source: TSource, args: TArgs) => void) {
		this.handlers = this.handlers.filter((h) => h !== handler);
	}
	public trigger(source: TSource, args: TArgs): void {
		// Duplicate the array to avoid side effects during iteration.
		this.handlers.slice(0).forEach((h) => h(source, args));
	}
}
