import { Suspense } from "react";
import ReactDOM from "react-dom";
//import './index.css';
import { ThemeProvider } from "@mui/material";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter } from "react-router-dom";
import AltshareTheme from "../src/theme/theme";
import App from "./App";
import ErrorPage from "./Screens/ErrorPage";
import { ModalProvider } from "./Shared/Components/Modal/ModalProvider";
import Spinner from "./Shared/Components/Spinner/Spinner";
import { RootStore, StoreProvider } from "./Store/RootStore";

// declare global {
// 	interface Window {
// 		toJS: (param: any) => void;
// 	}
// }

// Daniel - to check later if need to remove consoles in production
if (process.env.NODE_ENV === "production") {
    console.log = () => {};
    console.table = () => {};
    console.error = () => {};
    console.info = () => {};
}

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById("root")
// );

export const rootStore = new RootStore();

ReactDOM.render(
    <Suspense fallback={<Spinner />}>
        <ErrorBoundary
            fallbackRender={ErrorPage}
            onError={(error) => console.log("ERROR!", error)}
        >
            <StoreProvider store={rootStore}>
                <GoogleOAuthProvider clientId="688029105126-1jvfgajrtia3rg423jbe62b6lek97rit.apps.googleusercontent.com">
                    <BrowserRouter>
                        <ThemeProvider theme={AltshareTheme}>
                            <ModalProvider>
                                <App />
                            </ModalProvider>
                        </ThemeProvider>
                    </BrowserRouter>
                </GoogleOAuthProvider>
            </StoreProvider>
        </ErrorBoundary>
    </Suspense>,
    document.getElementById("root")
);
