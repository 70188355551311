import { IOrder } from "../../../../Models/App/EquityPlans/Order";
import Button from "../../../../Shared/Components/Button/Button";
import Table, { TableColumn } from "../../../../Shared/Components/Table/Table";
import SectionStyles from "../../General/styles/SectionStyles";
import { ReactElement, useEffect } from "react";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import { observer } from "mobx-react-lite";
import useEsopModals from "../../useEsopModals";
import { orderTypes } from "../../../../Shared/StaticData/equityPlans";
import Flex from "../../../../Shared/Components/Layout/Flex";
import AddButton from "../../../../Shared/Components/Button/AddButton";
import { useHistory } from "react-router-dom";
import { formatDate } from "../../../../Shared/Utilities";
interface OrderProps {
    header: ReactElement;
}

const Orders = ({ header }: OrderProps) => {
    const history = useHistory();
    const { equityPlansStore } = useRootStore();
    const { newOrderHandler } = useEsopModals();

    useEffect(() => {
        equityPlansStore.GetDashboardOrders();
    }, []);

    const tableColumns: TableColumn<IOrder>[] = [
        { label: "Order number", name: "orderNumber" },
        { label: "Grant number", name: "grantNumber" },
        { label: "Employee number", name: "employeeNumber" },
        {
            label: "Full name",
            name: "fullName",
            format(val) {
                return `${val.firstName} ${val.lastName}`;
            },
        },
        {
            label: "Order type",
            name: "orderType",
            format(val) {
                return orderTypes[val]?.label;
            },
        },
        {
            label: "Order date",
            name: "date",
            format(val) {
                return formatDate(val);
            },
        },
        { label: "Amount", name: "amount" },
        { label: "Price", name: "sellPrice" },
    ];

    useEffect(() => {
        equityPlansStore.GetDashboardOrders();
    }, [equityPlansStore.dataAsOfDate, equityPlansStore.selectedPlan]);

    return (
        <div className={SectionStyles}>
            <div className="section-table">
                <Table
                    customHeaderRender={header}
                    columns={tableColumns}
                    rows={equityPlansStore.companyOrders}
                    scrollAfterRows={13}
                    selectBy="orderId"
                    showTotal={false}
                    filterBy={tableColumns.filter((col) => col.name !== "actions").map((col) => col.name as keyof IOrder)}
                    searchBy={["orderNumber"]}
                    exportToExcel={{ fileName: "Orders", sheetName: "Orders" }}
                    fullscreen
                    renderBottom={
                        <Flex align="center" justify="start">
                            <AddButton onClick={() => newOrderHandler(true, true)} qaid="" label="Add new order" />
                        </Flex>
                    }
                />
            </div>
        </div>
    );
};

export default observer(Orders);