/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from "react";
import ObjectValidator, {
	ObjectValidationDescriptor,
	ObjectValidationStatus,
	ValidationPair,
} from "./../ObjectValidator";

export interface InputValidationRef extends HTMLDivElement {
	showError: () => void;
	resetError: () => void;
	focus: () => void;
}

export function useObjectValidation<T extends object>(
	obj: T,
	valDescriptor: ObjectValidationDescriptor<T>,
	deps?: Array<any>,
	refs?: Array<InputValidationRef | null>,
	forceValidation?: boolean,
): [
	boolean,
	ObjectValidationStatus<T> | null | undefined,
	Array<ValidationPair>,
	React.Dispatch<React.SetStateAction<ObjectValidationStatus<T> | null | undefined>>,
	(forceValidation?: boolean) => boolean,
] {
	const objRef = useRef(obj);

	const [isValid, setIsValid] = useState(false);
	const [isDirty, setIsDirty] = useState(false);
	const [validationState, setValidationState] = useState<ObjectValidationStatus<T> | null>();
	const [allErrors, setAllErrors] = useState<Array<ValidationPair>>([]);

	const validate = (showErrors = true, focusOnError = true): boolean => {
		setIsDirty(objRef.current !== obj);
		const validator = new ObjectValidator<T>(valDescriptor);
		validator.validate(obj);
		setValidationState(validator.validationState);
		setIsValid(validator.isValid);
		setAllErrors(validator.getErrorsList());
		showErrors && refs?.map((ref) => ref?.showError());
		if (focusOnError) {
			const firstErrorInput = Object.keys(validator.validationState).findIndex(
				(inputName: string) =>
					!validator.validationState[inputName as keyof typeof validator.validationState]?.isValid,
			);
			refs?.[firstErrorInput]?.focus();
		}
		return isValid;
	};

	useEffect(() => {
		validate(forceValidation || false, false);
	}, [obj, ...(deps ?? [])]);

	return [isValid, validationState, allErrors, setValidationState, validate];
}
