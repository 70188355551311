import { css } from "@emotion/css";
import classNames from "classnames";
import { ReactElement } from "react";
import Image from "../../../Shared/Components/Image";
import { breakpoints } from "../../../config/style/theme";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    img?: string;
    children: ReactElement | ReactElement[] | string;
}

const Style = css({
    label: "DashboardCard",
    background: "#ffffff",
    borderRadius: 16,
    overflow: "hidden",
    "&__wrapper": {
        padding: "2.6rem 1.8rem",
        boxShadow: "3px 3px 0px rgba(201, 202, 208, 0.40)",
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        gap: "3.2rem",
        overflow: "auto",
        width: "100%",
        height: "100%",
        [`@media (min-width: ${breakpoints.xl})`]: {
            padding: "2.6rem 4rem",
        },
    },
});

const DashboardCard = ({ img, children, className = "", ...rst }: Props) => {
    return (
        <div className={classNames(Style, { [className]: !!className })}>
            <div
                className={`${Style}__wrapper`}
                {...rst}
            >
                {!!img && (
                    <Image
                        width="6rem"
                        src={img}
                        alt=""
                    />
                )}
                {children}
            </div>
        </div>
    );
};

export default DashboardCard;
