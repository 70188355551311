export const translations = {
    between: "Between",
    companyExitValueInMillions: "Exit Value ($M)",
    companyPayoffInMillions: "Payoff ($M)",
    downloadAllExcels: "Download all Excels",
    every: "Every",
    exitValueSimulationParameters: "Exit value simulation parameters (optional)",
    intervals: "Intervals",
    investedInShareClasses: "Invested in Share classes",
    maximum: "Maximum",
    minimum: "Minimum",
    payoffInMillions: "Payoff ($M)",
    payoffBreakdown: "Payoff Breakdown",
    preferenceSummary: "Preferences Summary",
    range: "Range",
    sensitivityAnalysis: "Sensitivity Analysis",
    sensitivityAnalysisSettings: "Sensitivity Analysis Settings",
    shareclasse: "Shareclasse",
    shareclasses: "Shareclasses",
    shareholder: "Shareholder",
    shareholders: "Shareholders",
    shareholdersValuationTable: "Shareholders Valuation Table",
    termSheetAnalysisSummary: "Term Sheet Analysis Summary",
    totalShareClassValue: "Total Shareclass Value",
    totalShareHolderValue: "Total Shareholder Value",
    valuationOfShareClass: "Valuation of Shareclass",
    valuationOfShareHolder: "Valuation of Shareholder",
    waterfallModelingSummary: "Waterfall Modeling Summary",
};
