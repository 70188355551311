import MuiSwitch, { SwitchProps } from "@mui/material/Switch";
import classNames from "classnames";

interface Props extends Omit<SwitchProps, "onChange"> {
	className?: string;
	qaid: string;
	onChange?: (value: boolean, name?: string) => void;
}

const Switch = ({ className = "", qaid, onChange, ...rst }: Props) => {
	return (
		<MuiSwitch
			data-qaid={qaid}
			className={classNames({ [className]: !!className })}
			onChange={(e) => {
				onChange?.(e.target.checked, e.target.name);
			}}
			{...rst}
		/>
	);
};

export default Switch;
