import { Dispatch, ReactNode, SetStateAction, createContext, useEffect, useState } from "react";
import { Column, ImportCapTable } from "../../../../Models/App/CapTable/ImportCapTable";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import { isNumber } from "../../../../Shared/Utilities";
import { AxiosResponse } from "axios";
import { isString } from "underscore";
import { HighLevelErrorResponse, HigherLevelResponse } from "../../../../Services/Axios";
import { ShareClassInfo } from "../../../../Models/API/CapTable/share-class-info";

type ContextData = {
    file: File | undefined;
    setFile: Dispatch<SetStateAction<File | undefined>>;
    columns: Column[] | undefined;
    setColumns: Dispatch<SetStateAction<Column[] | undefined>>;
    // onSetColumnsHandler: (cols: Column[]) => void;
    onFileUpload: (
        file: File,
        sheetName?: string,
        shareholdersIndex?: number
    ) => Promise<HigherLevelResponse<ImportCapTable> | HighLevelErrorResponse>;
    shareClasses: ShareClassInfo[] | undefined;
    setShareClasses: Dispatch<SetStateAction<ShareClassInfo[] | undefined>>;
};

const ImportContext = createContext<ContextData>({} as ContextData);

const ImportProvider = ({ children }: { children: ReactNode }) => {
    const { capTableStore } = useRootStore();
    const [file, setFile] = useState<File>();
    const [columns, setColumns] = useState<Column[]>();
    const [shareClasses, setShareClasses] = useState<ShareClassInfo[]>();

    // const onSetColumnsHandler = (cols: Column[]) => {
    //     const maxCells = Math.max(...cols.map((col) => col.cells.length));

    //     const res = cols.map((col) => {
    //         const missingCells = maxCells - col.cells.length;
    //         const lastIdx = col.cells[col.cells.length - 1].referenceId + 1;
    //         return {
    //             ...col,
    //             cells: [...col.cells, ...Array.from({ length: missingCells }).map((_, idx) => ({ value: "", referenceId: lastIdx + idx }))],
    //         };
    //     });
    //     setColumns(res);
    // };

    const onFileUpload = async (file: File, sheetName?: string, shareholdersIndex?: number) => {
        const formData = new FormData();

        formData.append("file", file, sheetName);
        if (isString(sheetName)) formData.append("sheetName", sheetName);
        if (isNumber(shareholdersIndex)) formData.append("shareholdersIndex", shareholdersIndex + "");
        const res = await capTableStore.uploadCapTableImport(formData);
        setShareClasses(undefined);
        return res;
    };

    return (
        <ImportContext.Provider value={{ file, setFile, columns, onFileUpload, setColumns, shareClasses, setShareClasses }}>
            {children}
        </ImportContext.Provider>
    );
};

export { ImportContext, ImportProvider };
