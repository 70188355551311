import { IC_CANCEL_ROUND, IC_TERMINATE, IC_USER_CIRCLE } from "../../../../Assets"
import { MenuItem } from "../../../../Shared/Components/Menu"
import TableActions from "../../../ESOP/Dashboard/TableActions/TableActions"

import style from './BeneficiaryCell.module.css'

interface BenProps<T> {
    data: string
    fullRow: T
    showQa?: boolean
    closeQa?: () => boolean
    onTerminate?: () => any
    pendingTermination?: boolean
}


export const BeneficiaryCell = <T extends object>(props: BenProps<T>) => {

    const terminate = {
        qaid: '',
        label: 'Termination request',
        icon: IC_TERMINATE,
        onClick: props.onTerminate && props.onTerminate
    }

    const cancelTermination = {
        qaid: '',
        label: 'Cancel termination request',
        icon: IC_CANCEL_ROUND,
        onClick: props.onTerminate && props.onTerminate
    }

    let menuItems: MenuItem[] = [props.pendingTermination ? cancelTermination : terminate]


    return (
        <div style={style} className={style.cellContainer}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                <img src={IC_USER_CIRCLE} style={{ maxHeight: '80%' }} />
                <p style={{ height: '10%', alignSelf: 'center', marginLeft: '1rem', marginTop: '1rem' }}>{props.data}</p>
            </div>
            {
                props.showQa &&
                <TableActions
                    cssClass={style.cellActions}
                    autoClose={props.closeQa}
                    items={menuItems}
                />
            }
        </div>
    )
}