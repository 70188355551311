import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { IC_ARROW_BACK, IC_NEW_ERPORT, IC_PUBLISH, IC_REPORT_SETTINGS, IC_SETTINGS, IC_TRASH2 } from "../../../../Assets";
import { ExpenseReportStatus } from "../../../../Models/API/Expensing/expense-report";
import { MenuActionType } from "../../../../Models/App/action-menu-type";
import { Routes } from "../../../../Routes";
import ActionsMenu from "../../../../Shared/Components/ActionsMenu/ActionsMenu";
import Select from "../../../../Shared/Components/Select/Select";
import useModal from "../../../../Shared/Hooks/useModal";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import NewReportWizard from "../../NewReportWizard";
import MainMenuStyle from "./index.style";
import useGeneralModal from "../../../../Shared/Hooks/useGeneralModal";
import Image from "../../../../Shared/Components/Image";
import { isNullOrUndefined, isNumber } from '../../../../Shared/Utilities';
import useExpensingModal from '../../helpers/useExpensingModal';

type Props = {
	showActions?: boolean;
	showSelect?: boolean;
};

type InnerPageProps = {
	showActions?: boolean;
	showSelect?: false;
	showBack?: boolean;
};

const checkInnerPageType = (props: unknown): props is InnerPageProps => Object.prototype.hasOwnProperty.call(props, 'showBack');

const ExpensingMenu = ({ showActions = true, showSelect = true, ...props }: Props | InnerPageProps) => {
	const isInnerPage = checkInnerPageType(props);
	const { expensingStore } = useRootStore();
	const { showModal } = useModal();
	const { showErrorModal } = useGeneralModal();
	const history = useHistory();
	const { openNewReport } = useExpensingModal();

	const menuActions: MenuActionType[] = [
		{
			label: 'Create new report',
			icon: IC_NEW_ERPORT,
			onClick: () => {
				openNewReport(() => (expensingStore.newReportData = undefined));
			},
			qaid: 'ActionsMenu.Button.NewReport',
			separator: true,
		},
		{
			label: 'General expensing settings',
			icon: IC_SETTINGS,
			onClick: () => history.push(Routes.expensing.settings),
			qaid: 'ActionsMenu.Button.Settings',
		},
		{
			label: 'Report settings',
			icon: IC_REPORT_SETTINGS,
			onClick: () => history.push(Routes.expensing.reportSettings),
			qaid: 'ActionsMenu.Button.ReportSettings',
			disabled: isNullOrUndefined(expensingStore.selectedReport),
		},
		{
			label: 'B&S Parameters',
			icon: IC_REPORT_SETTINGS,
			onClick: () => history.push(Routes.expensing.blackAndScholes),
			qaid: 'ActionsMenu.Button.ReportSettings',
			disabled: isNullOrUndefined(expensingStore.selectedReport),
		},
		{
			label: 'Publish',
			icon: IC_PUBLISH,
			disabled: isNullOrUndefined(expensingStore.selectedReport) || expensingStore.selectedReport?.status === ExpenseReportStatus.Publish,
			onClick: () =>
				showModal({
					type: 'warning',
					title: 'Publish Report',
					body: <>Are you sure that you want to publish the share class right "{expensingStore.selectedReport?.expenseCompanyReportName}"?</>,
					onConfirm: () => expensingStore.updateReportStatus(ExpenseReportStatus.Publish),
				}),
			qaid: 'ActionsMenu.Button.Publish',
		},
		{
			label: 'Delete',
			icon: IC_TRASH2,
			disabled: isNullOrUndefined(expensingStore.selectedReport),
			onClick: () =>
				showModal({
					type: 'confirm',
					title: 'Delete Report',
					body: <>Are you sure that you want to delete the report "{expensingStore.selectedReport?.expenseCompanyReportName}"?</>,
					onConfirm: async () => {
						const res = await expensingStore.deleteReport();
						if (res?.errorMessage) {
							showErrorModal(res.errorMessage, 'Deletion failed');
						}
					},
				}),
			qaid: 'ActionsMenu.Button.Delete',
			// disabled: expensingStore.selectedReport?.status === ExpenseReportStatus.Publish,
		},
	];

	return (
		<div className={`${MainMenuStyle}__menu`}>
			<div className={`${MainMenuStyle}__menu-side-a`}>
				<div className={`${MainMenuStyle}__menu-left-side`}>
					{isInnerPage && props.showBack && (
						<div className={`${MainMenuStyle}__back-btn`} onClick={() => history.push(Routes.expensing.index)}>
							<Image src={IC_ARROW_BACK} width="0.9rem" />
							<span>Back</span>
						</div>
					)}
					{showSelect && (
						<div style={{ width: '32rem' }}>
							<Select
								options={expensingStore.expenseReports.map((rep) => ({
									value: rep.expenseCompanyReportId,
									label:
										rep.status === ExpenseReportStatus.Publish ? (
											<span>
												{rep.expenseCompanyReportName}
												<span className={`${MainMenuStyle}__published`}> (Published)</span>
											</span>
										) : (
											rep.expenseCompanyReportName
										),
								}))}
								value={expensingStore.selectedReportId}
								onChange={(value) => isNumber(value) && expensingStore.setSelectReportId(value)}
								qaid="Expensing.Select.Report"
								defaultValue="Select a report..."
							/>
						</div>
					)}
				</div>
			</div>
			<div className={`${MainMenuStyle}__menu-side-b`}>{showActions && <ActionsMenu actions={menuActions} />}</div>
		</div>
	);
};

export default observer(ExpensingMenu);
