import { css } from "@emotion/css";
import CloseIcon from "@mui/icons-material/Close";
import { IC_DOC_PDF, IC_DOC_WORD } from "../../../../Assets";
import Image from "../../../../Shared/Components/Image";
import { getMimeType } from "../../../../Shared/Utilities";
import appConfig from "../../../../config/config";
import DocumentPreviewInfo from "./DocumentPreviewInfo";
import TemplatePreviewInfo from "./TemplatePreviewInfo";

const Style = css({
    label: "Template",
    position: "fixed",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    background: appConfig.style.colors.background2 + "99",
    zIndex: 1060,
    "&__top": {
        display: "flex",
        justifyContent: "space-between",
    },
    "&__filename": {
        display: "flex",
        alignItems: "center",
        gap: "1.2rem",
        color: "#ffffff",
        fontWeight: 500,
        padding: "2.4rem",
    },
    "&__close-btn": {
        padding: "2.4rem",
        color: "#ffffff",
        cursor: "pointer",
        "> svg": {
            fontSize: "2rem",
            fontWeight: 500,
        },
    },
    "&__preview-data": {
        maxWidth: "140rem",
        width: "100%",
        height: "calc(100% - 15rem)",
        margin: "auto",
        display: "flex",
        alignItems: "center",
        paddingInline: "4rem",
        gap: "11rem",
        justifyContent: "center",
        "> embed": {
            height: "100%",
            borderRadius: 16,
            flex: 2.1,
            maxWidth: "77rem",
        },
        "> div": {
            color: "#ffffff",
            flex: 1,
        },
    },
});

export type InfoProps = {
    info: InfoType[];
    isPreview: boolean;
    isTooltip?: boolean;
};

export type InfoType = {
    icon: string;
    title: string;
    text: string[];
};

type TemplateModalProps = {
    fileBase64: string;
    fileName: string;
    extension: "pdf" | "docx";
    onClose: () => void;
    info: InfoProps;
};

const Preview = ({ fileBase64, onClose, fileName, extension, info }: TemplateModalProps) => {
    return (
        <div className={Style}>
            <div className={`${Style}__top`}>
                <div className={`${Style}__filename`}>
                    <Image
                        src={extension === "pdf" ? IC_DOC_PDF : extension === "docx" ? IC_DOC_WORD : undefined}
                        alt={fileName}
                        width="2.5rem"
                    />
                    {fileName}.{extension}
                </div>

                <div
                    className={`${Style}__close-btn`}
                    onClick={onClose}
                >
                    <CloseIcon />
                </div>
            </div>
            <div className={`${Style}__preview-data`}>
                <embed src={`data:${getMimeType("pdf")};base64,${fileBase64}#toolbar=0&navpanes=0&view=FitH`} />
                {info.isPreview ? <TemplatePreviewInfo {...info} /> : <DocumentPreviewInfo {...info} />}
            </div>
        </div>
    );
};

export default Preview;
