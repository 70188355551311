import { Theme } from "../types/theme.types";
import { colors } from "./lib/colors";

const borderRadiuses = {
	low: `0.8rem`,
	medium: `1.6rem`,
	high: `2.4rem`,
} as const;

const borders = {
	main: `1px solid ${colors.dark}`,
	alt: `1px solid ${colors.primary}`,
} as const;

const boxShadows = {
	main: `0 3px 3px ${colors.dark}}`,
	alt: `0 3px 3px ${colors.dark}`,
} as const;

const fonts = {
	main: ["NeueHassTextPro", "sans-serif"].join(","),
	alt: ["allroundgothic", "sans-serif"].join(","),
} as const;

const gradients = {
	main: `linear-gradient(to right, #C4C4FD 0%, #AAAAFE 50%, ${colors.primary} 100%)`,
	alt: `linear-gradient(to right, #C4C4FD 0%, #AAAAFE 50%, ${colors.primary} 100%)`,
} as const;

const spacings = {
	xs: "0.8rem",
	sm: "1.6rem",
	md: "2.4rem",
	lg: "3.2rem",
	xl: "4rem",
} as const;

const textSizes = {
	xs: "0.8rem",
	sm: "1.2rem",
	md: "1.4rem",
	lg: "1.6rem",
	xl: "2.4rem",
} as const;

export const breakpoints = {
	xs: "600px",
	s: "768px",
	sm: "900px",
	md: "1200px",
	lg: "1536px",
	xl: "1720px",
} as const;

const layers = {
	lowest: 1,
	low: 10,
	medium: 100,
	high: 1000,
	highest: 10000,
} as const;

const textShadows = {
	low: "0 0 3px rgba(0, 0, 0, 0.25)",
	medium: "0 0 6px rgba(0, 0, 0, 0.25)",
	high: "0 0 9px rgba(0, 0, 0, 0.25)",
} as const;

export const theme: Theme = {
	borderRadiuses: borderRadiuses,
	borders: borders,
	boxShadows: boxShadows,
	breakpoints: breakpoints,
	colors: colors,
	fonts: fonts,
	gradients: gradients,
	layers: layers,
	spacings: spacings,
	textShadows: textShadows,
	textSizes: textSizes,
};
