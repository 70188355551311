import { observer } from "mobx-react-lite"
import { PagedTableKeys } from "./PagedTable"
import { CSSProperties, useState } from "react"
import { isNullOrUndefined } from "../../../Utilities"
import { IC_CHEVRON_DOWN, IC_CHEVRON_UP } from "../../../../Assets"

import tableStyle from './PagedTable.module.css'


interface HeaderProps<T> {
    obj?: T
    headers: PagedTableKeys<T>[]
    onSort?: (columnIndex: number, isAscending: boolean) => any;
    headerStyle?: CSSProperties;
}

const HeaderRow = observer(<T extends object>(props: HeaderProps<T>) => {
    // console.log('HeaderRow Props', toJS(props.obj))

    const [sortedBy, setSortedBy] = useState<number>()
    const [sortAscending, setSortAscending] = useState<boolean>()


    // const objkeys: string[] = Object.keys(props.obj)
    // const mappedHeaders = objkeys.map(key => props.headers.filter(hd => hd.key === key))

    const sortByColumnHandler = (coulmn: PagedTableKeys<T>) => {
        // console.log('sortByColumnHandler', coulmn)
        setSortedBy(coulmn.entityEnumValue)
        setSortAscending(val =>
            isNullOrUndefined(val)
                ? false
                : !val
        )

        if (isNullOrUndefined(coulmn.entityEnumValue)) {
            console.log('NO entity Enum Value you Imbacile!')
            return
        }

        props.onSort && props.onSort(coulmn.entityEnumValue, sortAscending ? sortAscending : false)
    }

    return (
        <>
            <tr>
                {
                    props.headers.map((hdr, i) => (
                        <th style={props.headerStyle} key={hdr.entityEnumValue ?? 1 + (i * 11)}>
                            <div className={tableStyle.headerCell} onClick={() => sortByColumnHandler(hdr)}>
                                {hdr.displayValue}
                                {' '}
                                {
                                    sortedBy === hdr.entityEnumValue && !isNullOrUndefined(hdr.entityEnumValue) && (
                                        sortAscending ?
                                            <img src={IC_CHEVRON_UP} />
                                            : <img src={IC_CHEVRON_DOWN} />
                                    )
                                }
                            </div>
                        </th>
                    ))
                }
            </tr>
        </>
    )
})

export default HeaderRow