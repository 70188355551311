import { SummaryStatus } from '../../../Screens/Waterfall/Components/AddEditWaterfall/Steps/Summary/deps/types';
import { IndustryEnum } from '../../App/Valuation/industry-enum';
import { SectorEnum } from '../../App/Valuation/sector-enum';
import { ProjectsUserInfo } from '../CapTable/projects-user-info';
import { Currencies, ProductTypeEnum } from '../enums';

export interface StepsResponse {
	maxStep: number;
	isOptionsAllowed: boolean;
}

export interface ScenarioRequest {
	companyId: number;
	waterfallId: number;
	description: string;
	exitValue: number;
	valuationDate: Date;
	timeToExit: number;
}

export interface ScenarioResponse extends ScenarioRequest {
	projectName: string;
	maxStep: number;
}

export interface WaterfallProject {
	dateModified: Date;
	waterfallId: number;
	exitValue: number;
	isOwner: boolean;
	isSummary: boolean;
	valuatorFullName: string;
	valuatorId: number;
	projectName: string;
	maxStep: number;
	summaryStatus: SummaryStatus;
}

export interface CreateProjectRequest {
	companyId: number;
	waterfallId: number;
	projectName: string;
}

export interface CreateProjectResponse {
	waterfallId: number;
	unfulfillmentStep: number;
}

export interface Reviewer {
	userId: number;
	name: string;
	isSelected: boolean;
	isOwner: boolean;
}

export interface ReviewersResponse {
	isOwner: boolean;
	users: Reviewer[];
}

export interface ToggleReviewerRequest extends WaterfallDefaultParams {
	participantUserId: number;
}

export interface UploadOptionsRequest extends WaterfallDefaultParams {
	isUnallocated: boolean;
}

export interface ShareClassRight {
	shareClass: string;
	shareClassId: number | undefined;
	waterfallId: number;
	isPreferred: null | boolean;
	investmentDate: Date;
	entitledToRecieveAnnualInterestRate: boolean | undefined;
	isCompoundedInterestRate: boolean | undefined;
	annualInterestRate: number;
	seniorityLevel: number;
	numberOfSharesOutstanding: number;
	numberOfWarrants: number;
	issuePricePerShare: number;
	liquidationPreferenceMultiple: number | undefined;
	isCapMultipleInculdeInterestRate: boolean;
	liquidationPreference: number;
	totalLiquidationPreference: string;
	pariPassuId: null | number;
	seniorityLevelDisplay: number;
}

export interface ShareClassRightsResponse {
	shareClasses: ShareClassRight[];
}

export interface ShareClassRightResponse {
	shareClass: ShareClassRight;
	pariPassu: EditablePariPassu[];
}

export type EditablePariPassu = {
	isEditable: boolean;
	isSelected: boolean;
	shareClassId: number;
	shareClassName: string;
	seniorityLevel: number;
	isCurrent: boolean;
	baseSeniorityLevel: number;
};

export interface CapTableRequest {}
export interface CapTableRequest {
	companyId: number;
	waterfallId: number;
	projectId: number;
}

export interface ShareClassRightRequest {
	waterfallId: number;
	companyId: number;
	shareClassId: number;
	investmentDate: Date;
	isPreferred: boolean;
}

export interface ShareClassRightIssueRequest extends ShareClassRightRequest {
	liquidationPreferenceMultiple: number;
	parriPassu: number[];
	entitledToRecieveAnnualInterestRate: boolean;
	annualInterestRate?: number;
	isCompoundedInterestRate?: boolean;
}

export interface WaterfallDefaultParams {
	waterfallId: number;
	companyId: number;
	shareClassId?: number;
}

// step 5

export interface PreferenceShareClassRight {
	shareClassId: number;
	shredClassName: string;
	seniorityLevel: number;
	shareClassPreferenceTermsTypeId: number | undefined;
	capMultiple: number | undefined;
	hasOptionToConvertIntoShares: boolean | undefined;
	isCapMultipleInculdeInterestRate: boolean | undefined;
	hasLimitationOnAmount: boolean | undefined;
	convertingCompanyValue: number | undefined;
	mustConvertIntoSharesOnSpecificCompanyValue: boolean | undefined;
	mustConvertIntoSharesOnSpecificPayoff: boolean | undefined;
	convertingPayoffValue: number | undefined;
}

export interface PreferenceShareClassRequest {
	waterfallId: number;
	companyId: number;
	shareClassId: number;
	shareClassPreferenceTermsTypeId: number;
	capMultiple: number | undefined;
	hasOptionToConvertIntoShares: boolean | undefined;
	isCapMultipleInculdeInterestRate: boolean | undefined;
	hasLimitationOnAmount: boolean | undefined;
	convertingCompanyValue: number | undefined;
	mustConvertIntoSharesOnSpecificCompanyValue: boolean | undefined;
	mustConvertIntoSharesOnSpecificPayoff: boolean | undefined;
	convertingPayoffValue: number | undefined;
}

export enum PayoffSelectionOptions {
	'None',
	'NonParticipating',
	'Participating',
	'NoCap',
	'Convert',
	'ProRata',
}

export interface PlanData {
	name?: string;
	price: number | undefined;
	amount: number;
	shareClassId?: number;
	isUnalocated: boolean;
}

export type WaterfallAwardType = {
	shareClassId: number | undefined;
	name: string | undefined;
	price: number | undefined;
	totalAmount: number | undefined;
};

export interface CapTableShareClass {
	conversionPriceShare: number;
	conversionType: string;
	investmentAmount: number;
	issuePrice: number;
	name: string;
	seniorityLevel: number;
	shareClassId: number;
	shareFullyDiluted: number;
	shareHolders: any[];
	shareOutstanding: number;
	warrants: number;
}

export interface CapTableData {
	valuationDate: string;
	captableImportDate: string;
	captableLastUpdate: string;
	captableName: string;
	captableSummary: {
		investmentAmount: number;
		shareFullyDiluted: number;
		shareOutstanding: number;
		warrants: number;
	};
	shareClass: CapTableShareClass[];
	projectId: number;
	isProjectDeleted: boolean;
}

export type CapTableProjectResponse = {
	isSucceeded?: boolean;
	infoMessage?: null | string;
	errorCode: any;
	projectsUserList?: ProjectsUserInfo[] | null;
};

export type CapTableProject = {
	filterDate: Date;
	isOwner: boolean;
	isPublished: boolean;
	optionUpdateMode: number;
	productType: ProductTypeEnum;
	projectID: number;
	projectName: string;
	updatedDate: Date;
};

export type DataPlanResponse = {
	options: PlanData[];
	isUpdatedOption: boolean;
	withUnalocated: null | boolean;
};

export type WaterfallPeer = {
	stockId: number;
	companyName: string;
	ticker: string;
	ipoDateTime: Date;
	identifier: string;
	marketCap: number;
	enterpriseValue: number;
	industry: string;
	sector: string;
	description: string;
	asOfDate: Date;
	currency: Currencies;
};

export type PeerSearchParams = {
	keywords?: string;
	sectorEnum?: SectorEnum;
	industryEnum?: IndustryEnum;
};

export type RiskFreeRate = {
	expectedTerm: number;
	valuationDate: Date;
	riskFreeRate: number;
};

export type VolatilityTableData = {
	adjustedTableFrom: number;
	adjustedTableChosenTo: number;
	adjustedTableInterspace: number;
	waterfallId: number;
	selectedSector: SectorEnum | null;
	selectedIndustry: IndustryEnum | null;
	chosenVolatility: number | null;
	peersKeywordsSearch: string | null;
};

export type VolatilityPayload = Pick<VolatilityTableData, 'selectedIndustry' | 'selectedSector' | 'peersKeywordsSearch' | 'chosenVolatility'>;

export type VolatilityData = {
	companyName: string;
	data: Array<{
		expectedTermsInYears: number;
		volatility: number;
	}>;
	identitifier: string;
	ticker: string;
};

export type MultipleData = {
	enterpriseValueToSales: number | null;
	enterpriseValueToEbitda: number | null;
	marketCaptToNetIncome: number | null;
	ebitdaMargin: number | null;
	marketCap: number | null;
	identifier: string;
	companyName: string;
	ticker: string;
};

export type VolatilityAndMultiple = {
	volatilities: VolatilityData[] | null;
	multiples: MultipleData[] | null;
	peers: string[] | null;
};

export type Industry = {
	industry: keyof typeof IndustryEnum;
	sectors: (keyof typeof SectorEnum)[];
};

export type VolatilityTablePreviewData = {
	adjustedTableFrom: number;
	adjustedTableChosenTo: number;
	adjustedTableInterspace: number;
};
