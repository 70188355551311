import ExistingUser, { ExistingUserExtended } from "../Models/API/All/existing-user";
import { UserPersonalInformation } from "../Models/API/All/user-personal-information";
import { AddUserToOptionsAndCapTableRequest } from "../Models/API/CapTable/add-user-to-options-and-cap-table-request";
import { RoomAndUserPermission } from "../Models/API/UsersAndPermissions/room-and-user-permissions";
import { UserActiveProducts } from "../Models/API/UsersAndPermissions/user-active-products";
import { CompanyLimitationsPopUpType, PersonTypesEnum } from "../Models/API/enums";
import { IAutoCompleteRequest } from "../Models/App";
import IAutoCompleteUser from "../Models/App/AutoCompleteUser";
import { ICommonApiRequestValues } from "../Models/App/DataRoom/ICommonApiRequestValue";
import { BaseService, ExecuteResponse } from "./BaseService";

import { TemporaryUser } from "../Models/API/All/temporary-user";
import { UserPermissions } from "../Models/API/UsersAndPermissions/user-permissions-info";

export default class UserService extends BaseService {
    endPoints = {
        GetAutocomplete: "UsersAndPermissions/GetAutocomplete",
        GetAutocompleteForOrganization: "UsersAndPermissions/GetAutocompleteForOrganization",
        SaveUserDetailesByUserId: "UsersAndPermissions/SaveUserDetailesByUserId",
        UpdateUserCompany: "UserCompany",
        GetUserDetailesByUserId: "UsersAndPermissions/GetUserDetailesByUserId",
        CheckIfPresenrtUserProfileTabs: "UsersAndPermissions/CheckIfPresenrtUserProfileTabs",
        DeleteUserFromAllPlatforms: "UsersAndPermissions/DeleteUSerFromAllPlatforms",
        GetRoomAndPermissionByUserIdDetails: "UsersAndPermissions/GetRoomAndPermissionByUserIdDetails",
        InviteNewUsersToAllPlatforms: "UsersAndPermissions/InviteNewUsersToAllPlatforms",
        CrossProductsCheckIfUserExist: "CrossProducts/CheckIfUserExist",
        ResendInvitation: "Invitation/resend",
        NotifySales: "CrossProducts/NotifySales",
        UsersAndPermissions: (companyID: number, userId: number) => `UsersAndPermissions/${companyID}/${userId}`,
        InviteEmployee: "Invitation/employee",
        InviteNewUsersToDataRoom: "/DataRoom/users/invite",
        InviteUser: "user/invite",
        UserPermission: (userId: number) => `UserPermission/${userId}`,
    };
    commonValues: ICommonApiRequestValues;

    constructor(companyID: number) {
        super();
        this.commonValues = {
            companyID: companyID,
        };
    }
    async getUserActiveProducts(userId: number) {
        return await this.safeExecuteAsync(async () => {
            return (
                await this.httpLogin.post<UserActiveProducts>(this.endPoints.CheckIfPresenrtUserProfileTabs, {
                    ...this.commonValues,
                    long: userId,
                })
            ).data;
        });
    }
    async deleteUser(userId: number) {
        return await this.safeExecuteAsync(async () => {
            return (
                await this.httpLogin.post<boolean>(this.endPoints.DeleteUserFromAllPlatforms, {
                    ...this.commonValues,
                    modifiedUserID: userId,
                })
            ).data;
        });
    }
    async updateUserCompany(data: UserPersonalInformation) {
        return await this.safeExecuteAsync(async () => {
            return (
                await this.httpUserManagement.post<any>(this.endPoints.UpdateUserCompany, {
                    ...data,
                    ...this.commonValues,
                })
            ).data;
        });
    }

    async sendEmployeeInvitation(empoloyeeId: number, companyId: number, reCaptchaToken: string) {
        return await this.safeExecuteAsync(async () => {
            const data = { invitedUserId: empoloyeeId, companyId, reCaptchaToken };
            return await this.httpLogin.post<any>(this.endPoints.InviteEmployee, {
                // await this.httpLogin.post<any>('http://10.249.6.217:8087/api/Invitation/employee', {
                // api/Invitation/employee
                ...data,
                // ...this.commonValues,
            });
        });
    }

    async notifySales(limitType: CompanyLimitationsPopUpType) {
        return await this.safeExecuteAsync(async () => {
            return (
                await this.httpLogin.post<any>(this.endPoints.NotifySales, {
                    ...this.commonValues,
                    productType: limitType,
                })
            ).data;
        });
    }
    async InviteNewUsersToAllPlatforms(userData: UserPersonalInformation) {
        const data: AddUserToOptionsAndCapTableRequest = {
            firstName: userData.firstName,
            lastName: userData.lastName,
            personalIdnumber: "1234567", // FIXME: This is currently required by the server to have minimum 6 chars
            workMail: userData.email,
            companyName: userData.organizationName,
            corporateNumber: userData.corporateNumber ?? "",
            creditDetails: "creditDetails",
            expiredDate: new Date("2025-01-01"),
            id: userData.id,
            isLegalEntity: userData.type === PersonTypesEnum.LegalCompany,
            mobileNumber: userData.phoneNumber,
            nationality: userData.nationality,
            organization: userData.organizationName,
            plan: 0,
            role: userData.role,
            userID: 0,
            employeeNumber: userData.employeeNumber ?? "",
            ...this.commonValues,

            idImage: userData.photo,
        };
        return await this.safeExecuteAsync(async () => {
            return (await this.httpLogin.post<any>(this.endPoints.InviteNewUsersToAllPlatforms, data)).data;
        });
    }

    async CheckIfUserExists(userData: ExistingUser) {
        return await this.safeExecuteAsync(async () => {
            const _userData = { ...userData };
            if (!_userData.email) {
                _userData.email = null;
            }
            const data = (
                await this.httpLogin.post<{
                    isExact: boolean;
                    usersList: Array<ExistingUserExtended>;
                }>(this.endPoints.CrossProductsCheckIfUserExist, {
                    ...this.commonValues,
                    ..._userData,
                })
            ).data;

            return data;
        });
    }

    async InviteNewUsersToDataRoom(userData: UserPersonalInformation, roomID: number) {
        const data: TemporaryUser = {
            firstName: userData.firstName,
            lastName: userData.lastName,
            employeeNumber: userData.employeeNumber, // FIXME: This is currently required by the server to have minimum 6 chars
            workMail: userData.email,
            companyName: userData.organizationName,
            corporateNumber: userData.corporateNumber,
            creditDetails: "creditDetails",
            expiredDate: new Date("2025-01-01"),
            id: userData.id,
            isLegalEntity: userData.type === PersonTypesEnum.LegalCompany,
            mobileNumber: userData.phoneNumber,
            nationality: userData.nationality,
            organization: userData.organizationName,
            plan: 0,
            role: userData.role,
            userId: 0,
            personalIdnumber: "1234567",
        };
        const res = await this.safeExecuteAsync(async () => {
            const response = await this.httpLogin.post<{
                isSuccess?: boolean;
                usersToInvaite: Array<{
                    userId: number;
                    isSucceed: boolean;
                    email: string;
                    employeeNumber?: string;
                }>;
            }>(this.endPoints.InviteNewUsersToDataRoom, {
                dataRoomID: roomID,
                ...this.commonValues,
                usersToInvaite: [data],
            });

            return response.data;
        });
        const failedUser = res.data.usersToInvaite.find((user) => !user.isSucceed);
        if (failedUser) {
            return Promise.reject({
                error: `Could not create the user: ${failedUser.email || failedUser.employeeNumber}`,
                exception: null,
            });
        }
        return res.isSuccess ? res.data.usersToInvaite?.[0].userId : Promise.reject({ error: res.error, exception: res.exception });
    }

    async GetUserDetailesByUserId(id: number) {
        return await this.safeExecuteAsync(async () => {
            const data = (
                await this.httpLogin.post<UserPersonalInformation>(this.endPoints.GetUserDetailesByUserId, {
                    ...this.commonValues,
                    long: id,
                })
            ).data;
            data.userID = id; // FIXME: there is bug in api - it returns logged in user id instead of the requested
            data.type = data.isLegalEntity ? PersonTypesEnum.LegalCompany : PersonTypesEnum.Person;
            //data.userID = id;
            return data;
        });
    }

    async getUserPermissions(userId: number) {
        return await this.safeExecuteAsync(async () => {
            const data = (
                await this.httpLogin.get<UserPermissions>(this.endPoints.UsersAndPermissions(this.commonValues.companyID || 0, userId))
            ).data;

            return data;
        });
    }
    async getRoomAndPermissionByUserId(userId: number) {
        return await this.safeExecuteAsync(async () => {
            const data = (
                await this.httpLogin.post<Array<RoomAndUserPermission>>(this.endPoints.GetRoomAndPermissionByUserIdDetails, {
                    ...this.commonValues,
                    currentUserId: userId,
                })
            ).data;

            return data;
        });
    }
    async getUsersAutoComplete(query: IAutoCompleteRequest, dataRoomID?: number): Promise<ExecuteResponse<IAutoCompleteUser[]>> {
        const escapedValue = escapeRegexCharacters(
            (query.email ?? query.firstName ?? query.lastName ?? query.organizationName ?? query.employeeNumber ?? "").trim()
        );
        let data = {
            ...this.commonValues,
            // dataRoomID:2,
            firstName: null,
            lastName: null,
            email: null,
            companyName: null,
        } as any;

        if (escapedValue === "") {
            const d: Promise<ExecuteResponse<IAutoCompleteUser[]>> = Promise.resolve({
                data: [],
                isSuccess: true,
            });

            return d;
        }

        if (query.email) {
            data.email = escapedValue;
        } else if (query.firstName) {
            data.firstName = escapedValue;
        } else if (query.lastName) {
            data.lastName = escapedValue;
        } else if (query.organizationName) {
            data.companyName = escapedValue;
        } else if (query.employeeNumber) {
            data.employeeNumber = escapedValue;
        }
        data.projectID = !query.projectID ? -1 : query.projectID;

        data.dataRoomID = dataRoomID;

        // If company name privided then look in legal entities else look in users
        if (query.organizationName) {
            return await this.safeExecuteAsync(async () => {
                return (await this.httpLogin.post<Array<IAutoCompleteUser>>(this.endPoints.GetAutocompleteForOrganization, data)).data;
            });
        } else {
            return await this.safeExecuteAsync(async () => {
                return (await this.httpLogin.post<Array<IAutoCompleteUser>>(this.endPoints.GetAutocomplete, data)).data;
            });
        }
    }

    async inviteContact(contactId: number, userPermissions: Partial<UserPermissions>): Promise<ExecuteResponse<UserPermissions>> {
        return await this.safeExecuteAsync(async () => {
            const data = (
                await this.httpUserManagement.post<UserPermissions>(this.endPoints.InviteUser, {
                    contactId: contactId,
                    companyId: this.commonValues.companyID,
                    permission: {
                        ...userPermissions,
                    },
                })
            ).data;

            return data;
        });
    }

    async updateUserPermissions(userId: number, userPermissions: Partial<UserPermissions>): Promise<ExecuteResponse<UserPermissions>> {
        return await this.safeExecuteAsync(async () => {
            const data = (
                await this.httpUserManagement.patch<UserPermissions>(this.endPoints.UserPermission(userId), {
                    companyId: this.commonValues.companyID,
                    ...userPermissions,
                })
            ).data;

            return data;
        });
    }
}
export function escapeRegexCharacters(str: string) {
    return str.replace(/[*+?^${}()|[\]\\]/g, "\\$&");
}
