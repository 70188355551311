import { ArcElement, Chart as ChartJS, ChartOptions, Legend, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { AltProps } from '../WidgetDashboard/shared/dependencies/types/types';
import useRootStore from '../../Hooks/useRootStore';
import { defaultColors } from '../WidgetDashboard/shared/components/DoughnutChart/DoughnutChart.style';
import { formatNumber } from '../../Utilities';
import { css } from '@emotion/css';

const Style = css({
	label: 'DoughnutChartWrapper',
	maxWidth: '17rem',
	maxHeight: '17rem',
	// transform: 'scale(0.85)',
});

interface Props {
	colors?: string[];
	labels: string[];
	figures: number[] | string[];
	tooltipFigures?: number[];
	className?: string;
	tooltipTitle?: string;
}

export interface DoughnutChartProps extends AltProps<Props> {}

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ ap }: DoughnutChartProps) => {
	const { colors, labels, figures, tooltipFigures, className, tooltipTitle } = ap;
	const { appState } = useRootStore();

	const data = {
		labels: labels,
		datasets: [
			{
				label: 'Shareholders',
				data: figures,
				backgroundColor: colors || defaultColors,
			},
		],
	};

	const options: any = {
		responsive: true,
		maintainAspectRatio: true,
		plugins: {
			legend: {
				display: false,
			},
			cutout: 40,

			tooltip: {
				mode: 'index',
				intersect: false,
				backgroundColor: '#4D4D4D',
				padding: 18,
				titleSpacing: 3,
				titleMarginBottom: 3,
				footerSpacing: 3,
				footerMarginTop: 17,
				titleFont: {
					size: appState.fontSize * 1,
					weight: 'normal',
				},

				bodyFont: {
					size: appState.fontSize * 1,
				},
				footerFont: {
					size: appState.fontSize * 1,
					weight: 'normal',
				},
				...(tooltipFigures &&
					tooltipFigures?.length > 0 && {
						callbacks: {
							beforeTitle: function (context: any) {
								return `${context[0].label}`;
							},
							title: function () {
								return `------------------------`;
							},
							afterTitle: function () {
								// return "Shares (fully diluted):";
								return tooltipTitle;
							},

							label: function (context: any) {
								const formattedNumber = formatNumber(tooltipFigures[context.dataIndex]);
								const percentage = context.dataset.data[context.dataIndex];

								return ` ${formattedNumber}    ${percentage}%`;
							},
						},
					}),
			},
		},
	};

	return (
		<div className={Style}>
			<Doughnut options={options} data={data} />
		</div>
	);
};

export default DoughnutChart;
