import React, { useState } from "react";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import Table, { TableColumn } from "../../../../Shared/Components/Table/Table";
import { OrderByGrantTable, TrusteeGrant } from "../../Types/trusteeTypes";
import { TrusteeTableProps } from "../TrusteeBeneficiaries/TrusteeBeneficiariesMain";
import TableAdditionalViews from "../TableAdditionalViews";
import TrusteeGrantCard from "./GrantCard/TrusteeGrantCard";
import useTrusteeModals from "../../useTrusteeModals";
import { formatNumber } from "../../../../Shared/Utilities";
import PagedTable, { PagedTableKeys } from "../../../../Shared/Components/Table/PagedTable/PagedTable";
import { PagedTableReqParams } from "../../Types/ReqRes";
import { useEffectOnce } from "react-use";
import { observer } from "mobx-react-lite";
import { BeneficiaryCell } from "../BeneficiaryCell/BeneficiaryCell";

interface ShitProps {
    data?: string;
}

export const Shit = (props: ShitProps) => {
    return (
        <>
            SHIT!
            {props.data}
        </>
    );
};

const TrusteeGrantsMain = (props: TrusteeTableProps) => {
    const { trusteeStore } = useRootStore();
    const { showGrantCard } = useTrusteeModals();
    const [renderCard, setRenderCard] = useState<number>();

    const grantColumns: TableColumn<TrusteeGrant>[] = [
        {
            label: "Grant Number",
            name: "grantNumber",
        },
        {
            label: "Beneficiary",
            name: "beneficiaryName",
        },
        {
            label: "Grant Type",
            name: "awardType",
        },
        {
            label: "Grant Date",
            name: "grantDate",
        },
        {
            label: "Granted",
            name: "granted",
            format: (val) => formatNumber(val),
        },
        {
            label: "Unvested",
            name: "unVested",
            format: (val) => formatNumber(val),
        },
        {
            label: "Exercisable",
            name: "exercisable",
            format: (val) => formatNumber(val),
        },
        {
            label: "Saleable",
            name: "saleable",
            format: (val) => formatNumber(val),
        },
        {
            label: "Exercised",
            name: "exercised",
            format: (val) => formatNumber(val),
        },
        {
            label: "Sold",
            name: "sold",
            format: (val) => formatNumber(val),
        },
        {
            label: "Canceled",
            name: "canceled",
            format: (val) => formatNumber(val),
        },
    ];

    const grantKeys: PagedTableKeys<TrusteeGrant>[] = [
        { key: "grantNumber", displayValue: "Grant number", entityEnumValue: OrderByGrantTable.GrantNumber, searchable: true },
        {
            key: "beneficiaryName",
            displayValue: "Beneficiary",
            entityEnumValue: OrderByGrantTable.BeneficiaryName,
            searchable: true,
            customCell: (data, row) => (
                <BeneficiaryCell
                    data={data}
                    fullRow={row}
                />
            ),
        },
        { key: "awardType", displayValue: "Grant type", entityEnumValue: OrderByGrantTable.AwardType },
        { key: "grantDate", displayValue: "Grant date", entityEnumValue: OrderByGrantTable.GrantDate },
        { key: "granted", displayValue: "Granted", entityEnumValue: OrderByGrantTable.Granted },
        { key: "unVested", displayValue: "Unvested", entityEnumValue: OrderByGrantTable.UnVested },
        { key: "exercisable", displayValue: "Exercisable", entityEnumValue: OrderByGrantTable.Exercisable },
        { key: "saleable", displayValue: "Saleable", entityEnumValue: OrderByGrantTable.Saleable },
        { key: "exercised", displayValue: "Exercised", entityEnumValue: OrderByGrantTable.Exercised },
        { key: "sold", displayValue: "Sold", entityEnumValue: OrderByGrantTable.Sold },
        { key: "canceled", displayValue: "Canceled", entityEnumValue: OrderByGrantTable.Canceled },
        { key: "outstanding", displayValue: "Outstanding", entityEnumValue: OrderByGrantTable.Outstanding },
    ];

    const getStuffByParams = async (params: PagedTableReqParams) => {
        const data = await trusteeStore.GetTrusteeGrantsByParamsAsync(params);
        return data;
    };

    const openGrantCard = (obj: TrusteeGrant) => {
        const id = trusteeStore.trusteeGrantsTable.grants.filter((grant) => grant.grantNumber === obj.grantNumber)[0]?.grantId;
        showGrantCard(id);
    };

    // useEffectOnce(() => {
    //     trusteeStore.GetTrusteeGrantsByPage(5)
    // })

    const Header = () => {
        return (
            <>
                {/* <CircularSwitchToggle
                    value={true}
                    actions={[
                        { value: true, label: "Grants" },
                        { value: false, label: "Beneficiaries" },
                    ]}
                    onChange={(value) => (!value)}
                    type="secondary"
                    size="md"
                /> */}
                {props.header}
                <TableAdditionalViews />
            </>
        );
    };

    return (
        <>
            {/* <h1>Grants</h1> */}
            {/* <Table
                columns={grantColumns}
                rows={trusteeStore.trusteeGrants}
                customHeaderRender={props.header}
                customActionsRender={() => <TableAdditionalViews />}
                onRowClick={(row) => showGrantCard(row.grantId)}
            /> */}
            <PagedTable
                tableKeys={grantKeys}
                tableData={trusteeStore.trusteeGrantsTable.grants}
                totalRows={trusteeStore.trusteeGrantsTable.numberOfRows}
                newDataReq={(params) => getStuffByParams(params)}
                onSort={(params) => getStuffByParams(params)}
                onTableRowClick={(row) => openGrantCard(row)}
                headerChild={<Header />}
                itemsInPage={props.expand ? 10 : 5}
                search
                filter
            />
            <div className="">{renderCard && <TrusteeGrantCard />}</div>
        </>
    );
};

export default observer(TrusteeGrantsMain);
