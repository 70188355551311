import { NumberOption } from "../../../Models/API/All/NumberOption";
import { SqlOperator, SubmitFilesToTrusteeType } from "../Types/trusteeTypes";

export const shitOptions: NumberOption[] = [
    { label: 'Shit 1', value: 1 },
    { label: 'Shit 2', value: 2 },
    { label: 'Shit 3', value: 3 },
    { label: 'Shit 4', value: 4 },
    { label: 'Shit 5', value: 5 },
]

export const QueryOperator: NumberOption[] = [
    { label: 'Equal', value: SqlOperator.Equal },
    { label: 'Contains', value: SqlOperator.Contains },
    { label: 'Greater than', value: SqlOperator.GreaterThan },
    { label: 'Greater or equals', value: SqlOperator.GreaterThanOrEqual },
    { label: 'Less than', value: SqlOperator.LessThan },
    { label: 'Less or equals', value: SqlOperator.LessThanOrEqual },
    { label: 'Not equal', value: SqlOperator.NotEqual },
]

export const trusteeFileTypes: NumberOption[] = [
    { label: 'Board', value: SubmitFilesToTrusteeType.Board },
    { label: 'New beneficiaries', value: SubmitFilesToTrusteeType.NewBeneficiary },
    { label: 'New grant', value: SubmitFilesToTrusteeType.NewGrant },
    { label: 'New exercise', value: SubmitFilesToTrusteeType.NewExercise },
    { label: 'Other', value: SubmitFilesToTrusteeType.Other },
]