import Flex from "../../../../../../Layout/Flex";
import NoDataChartImg from "../../../../../shared/assets/images/no_data_chart.svg";
import { WidgetBody } from "../../../../../shared/components/Widget/Widget.style";
import { ChartWrapper, ContnetWrapper, DashboardContnetWrapper, Divider, Wrapper } from "./NoData.style";

interface IProps {
	readonly content?: string;
}

const NoData = (props: IProps) => {
	const content = props.content ?? "Data will be presented once the cap table is published";

	return (
		<Wrapper>
			<WidgetBody>
				<ChartWrapper>
					<img src={NoDataChartImg} alt="no data graphic" />
				</ChartWrapper>
				<Flex direction="column">
					<DashboardContnetWrapper>{content}</DashboardContnetWrapper>
					<Divider />
				</Flex>
			</WidgetBody>
		</Wrapper>
	);
};

export default NoData;
