import React, { useEffect, useState } from "react";
import classNames from "classnames";

import Flex from "../../../../../../../Shared/Components/Layout/Flex";
import { VestingStepStyle } from "./VestingStep.Style";
import NumberInput from "../../../../../../../Shared/Components/Input/NumberInput";
import { useAppendState } from "../../../../../../../Shared/Hooks/useAppendState";
import { getUniqueId, isNullOrUndefined, isNumber } from "../../../../../../../Shared/Utilities";
import Select from "../../../../../../../Shared/Components/Select/Select";
import Clickable from "../../../../../../../Shared/Components/Clickable/Clickable";
import { DateType, IVestStep, VestingStepType } from "../../../../../../../Models/App/EquityPlans/Company";
import { NumberOption } from "../../../../../../../Models/API/All/NumberOption";
import ErrorMessage from "../../../../../../../Shared/Components/Layout/ErrorMessage";
import AddButton from "../../../../../../../Shared/Components/Button/AddButton";
import useEffectOnce from "../../../../../../../Shared/Hooks/useEffectOnce";
import Button from "../../../../../../../Shared/Components/Button/Button";

const stepTypes: NumberOption[] = [
	{ value: VestingStepType.After, label: "After" },
	{ value: VestingStepType.ProRata, label: "Pro Rata" },
	// { value: VestingStepType.Exact, label: "Exact" },
];

const dateTypes: NumberOption[] = [
	{ value: DateType.Year, label: "Year" },
	{ value: DateType.Month, label: "Month" },
	{ value: DateType.Day, label: "Day" },
	{ value: DateType.Quarter, label: "Quarter" },
	{ value: DateType.Exact, label: "Exact" },
];

interface IProps {
	readonly id?: string;
	readonly step: IVestStep;
	readonly totalPercent: number;
	readonly onAddStep: (step: IVestStep) => void;
	readonly onRemoveStep: (stepId: string) => void;
	readonly onEditStep: (step: IVestStep) => void;
	readonly onSaveStep: (step: IVestStep) => void;
	readonly onChangeTotalPrecent: (totalPrecent: number) => void;
	readonly onStepHasError: (isStepHasError: boolean) => void;
}

const VestingStep: React.FC<IProps> = (props: IProps) => {
	const [step, setStep, onInputHandler, appendState] = useAppendState<IVestStep>({} as IVestStep);
	const [isStepOnEdit, setIsStepOnEdit] = useState<boolean>(false);
	const [error, setError] = useState<string | undefined>(undefined);

	const errorMessages = "Total percent can't be more than 100%";

	useEffect(() => {
		if (!isNullOrUndefined(props.step.id)) {
			setStep(props.step);
		} else {
			setStep({ percent: 100 - props.totalPercent, vestingStepType: VestingStepType.After, dateType: DateType.Month, amount: 1 });
		}
	}, [props.step, props.totalPercent]);

	useEffect(() => {
		props.onStepHasError(!isNullOrUndefined(error));
	}, [error]);

	const onAddStep = () => {
		if (isNullOrUndefined(step.percent) || step.percent === 0) {
			return;
		}

		if (props.totalPercent + step.percent > 100) {
			setError(errorMessages);

			return;
		}

		step.id = getUniqueId();
		setError(undefined);
		props.onAddStep(step);
	};

	const onRemoveStep = () => {
		if (isNullOrUndefined(step.id)) {
			return;
		}

		props.onRemoveStep(step.id);
	};

	const onEditStep = () => {
		if (isNullOrUndefined(step.id)) {
			return;
		}

		props.onEditStep(step);
		setIsStepOnEdit(true);
	};

	const onSaveStep = () => {
		if (isNullOrUndefined(step.id)) {
			return;
		}

		if (props.totalPercent + step.percent > 100) {
			setError(errorMessages);

			return;
		}

		setError(undefined);
		props.onSaveStep(step);
		setIsStepOnEdit(false);
	};

	return (
		<Flex className={VestingStepStyle} direction="column" align="end">
			<Flex
				className={classNames("innerContainer", {
					error: !isNullOrUndefined(error),
				})}
			>
				<Flex className="inputContainer">
					<NumberInput
						containerClassName="mb-0 inputContainer__input"
						qaid="VestingStep.Input.Precentage"
						value={step.percent}
						name="percent"
						percentage
						onChange={onInputHandler}
						disabled={!isNullOrUndefined(step.id) && !isStepOnEdit}
					/>
					<ErrorMessage top={"3.5rem"} fontSize={13}>
						{error}
					</ErrorMessage>
					<Select
						className="mb-0 inputContainer__input"
						qaid="VestingStep.Input.Precentage"
						name="vestingStepType"
						value={step.vestingStepType}
						onChange={onInputHandler}
						disabled={!isNullOrUndefined(step.id) && !isStepOnEdit}
						options={stepTypes}
					/>
					<NumberInput
						containerClassName="mb-0 inputContainer__multiSelect"
						qaid="VestingStep.Input.Precentage"
						value={step.amount}
						name="amount"
						onChange={onInputHandler}
						disabled={!isNullOrUndefined(step.id) && !isStepOnEdit}
						options={dateTypes}
						onOptionSelect={(value) => isNumber(value) && setStep({ ...step, dateType: value })}
						selectedValue={step.dateType}
						ratio={0.3}
					/>
				</Flex>
				<Flex className="actionsContainer">
					{!isNullOrUndefined(step.id) &&
						(isStepOnEdit ? (
							<Clickable className="editButtonsContainer__button" qaid="VestingStep.Button.SaveChanges" onClick={onSaveStep}>
								Save
							</Clickable>
						) : (
							<Flex className="editButtonsContainer">
								<Clickable className="editButtonsContainer__button" qaid="VestingStep.Button.ClearStep" onClick={onRemoveStep}>
									Clear
								</Clickable>
								<Clickable className="editButtonsContainer__button" qaid="VestingStep.Button.EditStep" onClick={onEditStep}>
									Edit
								</Clickable>
							</Flex>
						))}
				</Flex>
			</Flex>
			{isNullOrUndefined(step.id) && (
				<Flex justify="end">
					<Button qaid="VestingStep.Button.AddStep" label="Add new step" onClick={onAddStep} />
				</Flex>
			)}
		</Flex>
	);
};

export default VestingStep;
