import styled from "@emotion/styled";
import appConfig from "../../../config/config";
import { COLOR_BLUE_HAZE } from "../../../config/style/lib/colors";
import { theme } from "../../../config/style/theme";

export const QUESTION_CARD_ACTIVE_SPACING_X = "2.5rem";
export const QUESTION_CARD_ACTIVE_SPACING_Y = "1.4rem";

export const QUESTION_CARD_MIN_HEIGHT = "4rem"; // 7.2rem with spacings
// export const QUESTION_CARD_MIN_HEIGHT = "8rem"; // 7.2rem with spacings

const QuestionCard = styled.div({
    label: "QuestionCard",
    color: appConfig.style.colors.text1,

    "&.disabled": {
        opacity: 0.7,
    },

    ".question-mark": {
        marginInline: "2.2rem",
    },
});

const CardMain = styled.div({
    label: "QuestionCard__Main",
    display: "flex",
    alignItems: "center",
    height: "100%",
    minHeight: QUESTION_CARD_MIN_HEIGHT,
});

const Question = styled.div({
    label: "QuestionCard__Question",
    fontSize: "1.4rem",
    minWidth: "35rem",
    marginRight: "4rem",
    flex: "0 1 55rem",
    ".InputGroup &": {
        marginRight: "1.5rem",
    },
});

const Options = styled.div({
    label: "QuestionCard__Options",
    display: "flex",
    minWidth: "20rem",
    flexGrow: 0,
    justifyContent: "flex-start",
    gap: "9rem",
});

const Option = styled.div({
    label: "QuestionCard__Option",
});

const Children = styled.div({
    label: "QuestionCard__Children",
    ".is-active &": {
        // paddingBlock: QUESTION_CARD_ACTIVE_SPACING_Y,
        paddingInline: QUESTION_CARD_ACTIVE_SPACING_X,
        border: `1px solid ${COLOR_BLUE_HAZE}`,
        borderRadius: theme.borderRadiuses.low,
    },
});

export const StyledQuestionCard = {
    QuestionCard,
    CardMain,
    Question,
    Options,
    Option,
    Children,
};
