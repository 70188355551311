import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import appConfig from '../../../../config/config';
import { ConvertPromisedModalType, ConvertPromisedRequestType, IPromisedTable } from '../../../../Models/App/EquityPlans/Promised';
import Button from '../../../../Shared/Components/Button/Button';
import CheckBox from '../../../../Shared/Components/CheckBox/CheckBox';
import Tooltip from '../../../../Shared/Components/Tooltip';
import { ForwardedRefType } from '../../../../Shared/Hooks/useMultiStepForm';
import useRootStore from '../../../../Shared/Hooks/useRootStore';
import { formatNumber, isNullOrUndefined } from '../../../../Shared/Utilities';
import BoardApprovalFields from './BoardApprovalFields';

interface PromisedBoardApprovalProps {
	promiseds: IPromisedTable[];
	onSave: (promised: ConvertPromisedRequestType, amount: number) => Promise<void>;
}

export const PromisedStyle = css({
	label: "PromisedBoardApproval",
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	padding: "7rem 8rem 7rem",

	"&__title": {
		fontSize: "2.5rem",
		marginBottom: "4.8rem",
		fontWeight: 500,
	},

	"&__subtitle": {
		fontSize: "1.8rem",
		marginRight: "1.5rem",
		fontWeight: 500,
	},

	"&__purple": {
		color: `${appConfig.style.colors.color1}`,
		fontWeight: 500,
	},

	"&__flex": {
		display: 'flex',
		alignItems: 'baseline',
		gap: '1rem',

		'> span': {
			fontSize: "1.8rem",
		}
	},

	"&__tooltip": {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	}
});

const PromisedBoardApproval = (props: PromisedBoardApprovalProps) => {
	const { equityPlansStore, companyStore, } = useRootStore();
	const ref = useRef<ForwardedRefType<ConvertPromisedModalType>[]>([]);
	const [isEditPerOne, setIsEditPerOne] = useState<boolean>(props.promiseds.length <= 1);
	const [grantMinDate, setGrantMinDate] = useState<Date>(new Date);
	const [totalAmount, _] = useState<number>(props.promiseds.reduce((sum, prom) => sum + (prom.amountPromised || 0), 0));
	const promisedsData = ref.current.map((ref) => ref.getData());

	useEffect(() => {
		planChangeHandler();
	}, []);

	const planChangeHandler = () => {
		const plan = equityPlansStore.companyData?.plans.filter((pln) => pln.planId === props.promiseds[0].planId)[0];
		if (isNullOrUndefined(plan?.planApprovalDateByBoard)) return;
		const minDate = plan?.planApprovalDateByBoard;
		setGrantMinDate(minDate);
	};

	const onSendToConvert = () => {
		const isValidArray = ref.current?.map((ref) => ref.onValidate());
		if (isValidArray.includes(false)) return;
		let newPromisedsData = ref.current.map((ref) => ref.getData());

		if (props.promiseds.length > 1 || !isEditPerOne) {
			newPromisedsData = props.promiseds.map((prom, index) => {
				return {
					...newPromisedsData[!isEditPerOne ? 0 : index],
					amountPromised: prom.amountPromised,
					promisedId: prom.promisedId
				}
			});
		}

		const obj: ConvertPromisedRequestType = {
			companyId: companyStore.companyId,
			perGrant: isEditPerOne,
			planId: equityPlansStore.selectedPlan || 0,
			promiseds: newPromisedsData,
		}

		props.onSave(obj, newPromisedsData.length);
	}

	return (
		<>
			<div className={PromisedStyle}>
				<span className={`${PromisedStyle}__title`}>{'Board approval'}</span>
				<div className={`${PromisedStyle}__subtitle`}>
					<span>{'Total promised awards '}</span>
					<span className={`${PromisedStyle}__purple`}>{`(${formatNumber(totalAmount)})`}</span>
				</div><br />
				<div className={`${PromisedStyle}__flex`}>
					<div className={`${PromisedStyle}__subtitle`}>
						<span>{'Selected '}</span>
						<span className={`${PromisedStyle}__purple`}>{`(${props.promiseds.length})`}</span>
					</div>
					<CheckBox
						isChecked={isEditPerOne}
						onClick={() => setIsEditPerOne(!isEditPerOne)}
						qaid='EditPerGrant.CheckBox'
						style={{ minWidth: '2rem' }}
						disabled={props.promiseds.length === 1}
					/>
					<span>{'Edit per grant'}</span>
					<Tooltip
						title={<div className={`${PromisedStyle}__tooltip`}>
							<span>{'Edit the parameters prior to checking'}</span>
							<span>{' “Edit per grant”, will apply to all selected grants'}</span>
						</div>}
						placement='top'
					/>
				</div>

				{
					(isEditPerOne ? props.promiseds.map((promised, index) => {
						return (
							<BoardApprovalFields
								ref={(r: any) => ref.current[index] = r}
								key={index}
								name={`${promised?.fullName.firstName} ${promised?.fullName.lastName} (${promised?.grantNumber})`}
								grantMinDate={grantMinDate}
								promised={promised}
								oneEditData={promisedsData[0]}
							/>
						)
					}) : (
						<BoardApprovalFields
							ref={(r: any) => ref.current = [r]}
							grantMinDate={grantMinDate}
								oneEditData={promisedsData[0]}
								total={totalAmount}
						/>
					))
				}
				<Button
					disabled={false}
					onClick={onSendToConvert}
					qaid="Promised.Button.Boardapproval"
					label="Confirm"
					style={{ margin: '50px 0px auto auto' }}
				/>
			</div>
		</>
	);
};

export default observer(PromisedBoardApproval);