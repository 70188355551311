import { observer } from "mobx-react-lite";
import useRootStore from "../../../../../Shared/Hooks/useRootStore";
import TextInput from "../../../../../Shared/Components/Input/TextInput";
import NumberInput from "../../../../../Shared/Components/Input/NumberInput";
import Select from "../../../../../Shared/Components/Select/Select";
import DatePickerInput from "../../../../../Shared/Components/Input/DatePickerInput";
import Button from "../../../../../Shared/Components/Button/Button";
import { Switch } from "@mui/material";
import AddNewStepStyle from "../../Settings/VestingSchedule/AddNewStep/AddNewStep.Style";
import Flex from "../../../../../Shared/Components/Layout/Flex";
import { isNumber } from "../../../../../Shared/Utilities";
import useEsopModals from "../../../useEsopModals";

const EditOrder = () => {
	const { equityPlansStore } = useRootStore();
	const { openGrntCard } = useEsopModals();

	const order = equityPlansStore.beneficiaryOrder;

	const onInputHandler = (value: string | number | undefined, name: string) => {
		equityPlansStore.beneficiaryOrder = {
			...equityPlansStore.beneficiaryOrder,
			[name]: value,
		};
	};

	return (
		<div className={AddNewStepStyle}>
			<Flex margin="0 0 20px 0">
				<Button
					label="Go To Grant"
					inverse
					square
					cancel
					qaid=""
					onClick={() => {
						// open grant modal
						openGrntCard(order.grantId, order.contactId, !!order.grantNumber);
					}}
				/>
			</Flex>
			<div className={`${AddNewStepStyle}__form`}>
				<TextInput
					label="Employee Number"
					value={order.employeeNumber}
					qaid="EditOrder.Input.EmployeeNumber"
					disabled
				/>
				<TextInput
					label="First Name"
					value={order.employeeFirstName}
					qaid="EditOrder.Input.FirstName"
					disabled
				/>
				<TextInput
					label="Last Name"
					value={order.employeeLastName}
					qaid="EditOrder.Input.LastName"
					disabled
				/>
				<TextInput
					label="Grant Number"
					value={order.grantNumber}
					qaid="EditOrder.Input.GrantNumber"
					disabled
				/>
				<Select
					label="Order type"
					options={[]}
					value={order.orderType}
					onChange={() => {}}
					qaid="EditOrder.Select.OrderType"
					disabled
				/>
				<NumberInput
					label="Amount"
					value={order.amount ?? ""}
					name="amount"
					onChange={(value, name) => isNumber(value) && name && onInputHandler(value, name)}
					qaid="EditOrder.Input.Amount"
				/>
				<DatePickerInput
					label="Order Date"
					value={order.date}
					name="date"
					onChange={(value, name) => name && onInputHandler(value?.toISOString(), name)}
					qaid="EditOrder.Input.OrderDate"
				/>
				<Flex className="secondary-switch">
					<Switch
						checked={order.isSecondary}
						onClick={() => (order.isSecondary = !order.isSecondary)}
						data-qaid="EditOrder.CheckBox.SecTransaction"
					/>
					<p>Secondary Transaction</p>
				</Flex>

				{order.isSecondary && (
					<>
						<TextInput
							label="Buyer First Name"
							qaid="EditOrder.Input.BuyerFirstName"
							value={order.buyerFirstName ?? ""}
							onChange={(e) => (order.buyerFirstName = e)}
						/>
						<TextInput
							label="Buyer Last Name"
							qaid="EditOrder.Input.BuyerLastName"
							value={order.buyerLastName ?? ""}
							onChange={(e) => (order.buyerLastName = e)}
						/>
						<TextInput
							label="Buyer Email"
							qaid="EditOrder.Input.BuyerEmail"
							value={order.buyerEmail ?? ""}
							onChange={(e) => (order.buyerEmail = e)}
						/>
					</>
				)}
			</div>

			<Flex align="center"></Flex>
		</div>
	);
};
export default observer(EditOrder);
