import Flex from "../../../Shared/Components/Layout/Flex";
import { Skeleton } from "@mui/material";
import { RectangularSkeleton } from "../index.style";

const CapTableLoader = () => {
	return (
		<Flex direction="column" height="100%" align="start">
			<Skeleton variant="rectangular" width={"40%"} height={"10%"} animation="wave" className={RectangularSkeleton} />

			<Flex align="center" justify="start" gap={70}>
				<Skeleton variant="circular" width={120} height={120} animation="wave" />
				<Flex direction="column" gap={20}>
					<Skeleton variant="rectangular" width={"80%"} height={"10%"} animation="wave" className={RectangularSkeleton} />
					<Skeleton variant="rectangular" width={"80%"} height={"10%"} animation="wave" className={RectangularSkeleton} />
					<Skeleton variant="rectangular" width={"80%"} height={"10%"} animation="wave" className={RectangularSkeleton} />
				</Flex>
			</Flex>
		</Flex>
	);
};

export default CapTableLoader;
