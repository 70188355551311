import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { InputValidationRef, useFormValidation } from "../../../../../../Shared/Hooks/useFormValidation";
import { commonValidators } from "../../../../../../Shared/ObjectValidator";
import { EquityPlan, IEquityPlan, IPoolParams } from "../../../../../../Models/App/EquityPlans/Company";
import Flex from "../../../../../../Shared/Components/Layout/Flex";
import CheckBox from "../../../../../../Shared/Components/CheckBox/CheckBox";
import TextInput from "../../../../../../Shared/Components/Input/TextInput";
import DatePickerInput from "../../../../../../Shared/Components/Input/DatePickerInput";
import NumberInput from "../../../../../../Shared/Components/Input/NumberInput";
import Button from "../../../../../../Shared/Components/Button/Button";
import { MultiStepElementProps, ForwardedRef } from "../../../../../../Shared/Hooks/useMultiStepForm";

import { NavButtons, StepDivider, StepTitle } from "../../Registration.Style";
import NewEquityPlanStyle from "./NewEquityPlan.Style";
import useRootStore from "../../../../../../Shared/Hooks/useRootStore";
import { observer } from "mobx-react-lite";
import { isNullOrUndefined } from "../../../../../../Shared/Utilities";
import NewEquityPlan from "../../../Settings/Plan/NewEquityPlan/NewEquityPlan";
import useEsopModals from "../../../../useEsopModals";
import { toJS } from "mobx";
import Spinner from "../../../../../../Shared/Components/Spinner/Spinner";
import SelectDataSource from "../../../Settings/SelectDataSource";




const NewEquityPlanStep = forwardRef<ForwardedRef, MultiStepElementProps>((props, forwardedRef) => {
    const { equityPlansStore, companyStore } = useRootStore();
    const { showErrorModal } = useEsopModals();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isCreated, setIsCreated] = useState<boolean>(false);
    const [loadedPlan, setLoadedPlan] = useState<EquityPlan>();

    const ref = useRef<ForwardedRef>(null);
    // const { validateForm, formValidationState } = useFormValidation({
    // 	form: plan,
    // 	schema: {
    // 		name: [commonValidators.required()],
    // 		// poolAmount: [commonValidators.required()],
    // 	},
    // 	refs: inputRefs.current,
    // });

    useImperativeHandle(forwardedRef, () => ({
        async onValidate() {
            // return validateForm({ showErrors: true });
            return true;
        },
    }));

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const res = await equityPlansStore.GetEquitySettings();
            if (res?.plans?.[0]) {
                setLoadedPlan(res.plans[0]);
                setIsCreated(true);
            }
            setIsLoading(false);
        })();
    }, []);

    // useEffect(() => {
    // 	plan.allowRsu = true;
    // 	plan.allowSop = true;
    // 	plan.allowAga = false;
    // 	plan.allowBspce = false;
    // 	plan.isCancellationsReturnToPool = false;
    // }, []);

    const createPlanHandler = async () => {
        if (isCreated) return props.onNextStep?.();
        const isFormValid = await ref.current?.onValidate();
        if (!isFormValid) return;

        const res = await equityPlansStore.AddEquityPlan();
        if (res?.error) return showErrorModal(res.errorMessage);
        props.onNextStep?.();

        // const mPool = createNewPool(planId);
        // await equityPlansStore.createPoolForPlan(mPool);
    };

    const plan = equityPlansStore.newEquityPlan;

    return (
        <div className={NewEquityPlanStyle}>
            {
                isLoading ?
                    (
                        <div className={`${NewEquityPlanStyle}__loading-spinner`}>
                            <Spinner incorporated center />
                        </div>
                    ) : (
                        // TODO: check if already registered to Pivotal from cap temble
                        //   registerd ? shot trustee dashboard : keep on registering
                        <>

                            {equityPlansStore.showTrustee && <SelectDataSource />}
                            <NewEquityPlan registration data={loadedPlan} ref={ref} />
                        </>
                    )
            }
            <div className="buttonsContainer">
                <Flex justify="end" gap="1rem" className={NavButtons}>
                    <Button qaid="Registration.Button.Next" className={"buttonsContainer__button"} onClick={createPlanHandler}>
                        Next
                    </Button>
                </Flex>
            </div>
        </div>
    );
});

export default observer(NewEquityPlanStep);
