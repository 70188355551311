import { css } from "@emotion/css";
import appConfig from "../../../../../../config/config";

const NewGrantDetailsStyle = css({
    label: "GrantCard",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-between",
    "&.standalone": {
        padding: "7rem 8rem 4rem",
        height: "100%",
        overflow: "auto",
    },
    "&__main-section": {
        width: "100%",
        // boxShadow: appConfig.style.boxShadow.card1,
    },
    "&__inputs-container": {
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        gridColumnGap: "2.4rem",
        gridRowGap: "3.4rem",
    },
    "&__add-ben-btn": {
        alignSelf: "flex-end",
        marginBottom: "0.4rem",
    },
    "&__title": {
        fontSize: "2.5rem",
        marginBottom: "4.8rem",
        fontWeight: 500,
    },

    "&__grant-letter": {
        margin: '3.6rem 0',
        paddingBottom: '1.6rem',
        "> .label": {
            marginBottom: '2rem',
            overflow: "hidden",
            "> span":{
                position: "relative",
                fontWeight: 500,
                fontSize: '1.3rem',
                "&::after": {
                    content: "''",
                    position: "absolute",
                    top: "50%",
                    width: 9999,
                    height: 1,
                    left: "10.5rem",
                    background: appConfig.style.colors.field,
                },
            },
        },
        ".flex": {
            marginTop: '2rem',
        }
    },
});

export default NewGrantDetailsStyle;
