import { css } from "@emotion/css";
import appConfig from "../../../config/config";

const AddEditNewCompanyStyle = css({
	label: "AddNewCompany",
	padding: "7rem 8rem 4rem",
	height: "100%",
	overflow: "auto",
	display: "flex",
	flexDirection: "column",
	"&__title": {
		marginBottom: "5rem",
	},
	"&__sub-title": {
		marginBottom: "2rem",
		fontWeight: 700,
		display: "flex",
		gap: "1.2rem",
		alignItems: "center",
	},
	"&__form": {
		label: "Form",
		display: "grid",
		gridColumnGap: "2.4rem",
		gridRowGap: "2.4rem",
		gridTemplateColumns: "repeat(4, 1fr)",
		"&.isOpen": {
			// gridTemplateRows: "1fr 0.33fr 1fr",
		},
	},
	"&__contact-section": {
		marginTop: "3rem",
		marginBottom: "2rem",
		borderTop: `1px solid ${appConfig.style.colors.table}`,
		borderBottom: `1px solid ${appConfig.style.colors.table}`,
		paddingBottom: "2rem",
		paddingTop: "2rem",
	},
});

export default AddEditNewCompanyStyle;
