import { css } from "@emotion/css";

const NewPlanStyle = css({
	label: 'NewEquityPlan',
	display: 'flex',
	flexDirection: 'column',
	'&__title': {
		marginBottom: '1.5rem',
	},

	'.subtitle': {
		marginBottom: '1.5rem',
	},

	'.checkboxContainer': {
		marginInlineEnd: '6rem',
	},

	'.inputContainer': {
		marginInlineEnd: '2.4rem',
		width: '21rem',
	},
	'&__loading-container': {
		minHeight: '40rem',
	},
});

export default NewPlanStyle;
