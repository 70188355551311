import Clickable from "../../../../Shared/Components/Clickable/Clickable";
import Flex from "../../../../Shared/Components/Layout/Flex";
import useMultiStepForm from "../../../../Shared/Hooks/useMultiStepForm";
import FileImport from "./FileImport/FileImport";
import ColumnSelection from "./TableConfig/ColumnSelection";
import HeadlineSelection from "./TableConfig/HeadlineSelection";
import RowSelection from "./TableConfig/RowSelection";
import TableEdit from "./TableEdit/TableEdit";
import TypeSelection from "./TypeSelection/TypeSelection";

const ImportFromExcel = () => {
	const { step, onPrevStep } = useMultiStepForm([
		{
			element: <TypeSelection />,
		},
		{
			element: <FileImport />,
		},
		{
			element: <HeadlineSelection />,
		},
		{
			element: <RowSelection />,
		},
		{
			element: <ColumnSelection />,
		},
		{
			element: <TableEdit />,
		},
	]);

	return (
		<Flex className="h-100" direction="column" align="start">
			{step}
			<Flex justify="between" className="mt-auto" flex={0}>
				<Clickable qaid="ImportFromExcel.Button.Back" applyStyle onClick={onPrevStep} flex={0}>
					Back
				</Clickable>
				{/* {!isFirstStep && (
					<Clickable qaid="ImportFromExcel.Button.Back" applyStyle onClick={onNextStep} flex={0}>
						Next
					</Clickable>
				)} */}
			</Flex>
		</Flex>
	);
};

export default ImportFromExcel;
