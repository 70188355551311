import { css } from '@emotion/css';
import { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { OnboardingStatus } from '../../../Models/API/Expensing/onboarding-status-enum';
import { Routes } from '../../../Routes';
import Button from '../../../Shared/Components/Button/Button';
import Separator from '../../../Shared/Components/Separator';
import useModal from '../../../Shared/Hooks/useModal';
import { ForwardedRef, MultiStepElementProps } from '../../../Shared/Hooks/useMultiStepForm';
import useRootStore from '../../../Shared/Hooks/useRootStore';
import FairMarketValue from '../Components/Settings/FairMarketValue';
import useExpensingModal from '../helpers/useExpensingModal';

const Style = css({
	label: 'ExpensingFirMarket',
	display: 'flex',
	flexDirection: 'column',
	width: '100rem',
	padding: '6rem 8rem',
	'&__title': {
		fontWeight: 500,
		fontSize: '2.5rem',
		marginBottom: '5rem',
	},
	'&__next-btn': {
		marginTop: '0.8rem',
	},
});

type Props = MultiStepElementProps & {
	onSubmitHandler?: () => void;
	removeModal?: () => void;
};

const FairMarket = ({ onSubmitHandler, ...props }: Props) => {
	const ref = useRef<ForwardedRef>(null);
	const { expensingStore } = useRootStore();
	const { showModal } = useModal();
	const history = useHistory();
	const { openNewReport } = useExpensingModal();

	const onNextHandler = async () => {
		const isValidated = ref.current?.onValidate();
		if (!isValidated) return;

		const isUpdated = await expensingStore.setCompanyOnboardingStatus(OnboardingStatus.Completed);
		if (!isUpdated) return;
		props.removeModal?.();

		showModal({
			type: 'success',
			body: 'Expensing general settings were successfully updated',
			qaid: 'Expensing.Modal.Create',
			width: '52rem',
			showProgressBar: false,
			timeout: 1500,
			onModalClose: openNewReport,
		});
	};

	return (
		<div className={Style}>
			<div className={`${Style}__title`}> Expensing general settings</div>
			<FairMarketValue ref={ref} />

			<Separator />
			<div className="flex justify-between">
				<Button className={`${Style}__next-btn`} qaid="ExpensingInitialSetup.Button.Back" label="Back" inverse cancel onClick={props.onPrevStep} />
				<Button className={`${Style}__next-btn`} qaid="ExpensingInitialSetup.Button.Next" label="Next" position="end" onClick={onNextHandler} />
			</div>
		</div>
	);
};

export default FairMarket;
