import { css } from "@emotion/css";
import appConfig from "../../../../config/config";
import styled from "@emotion/styled";

const AddEditWaterfallStyle = css({
	label: "AddEditWaterfall",
    display: "flex",
    flexDirection: "column",
	"&__btn-actions": {
        background: appConfig.style.colors.gradientPurpleVertical,
        padding: "0.8rem 1.2rem",
        borderRadius: 8,
        color: "#ffffff",
        display: "flex",
        alignItems: "center",
        gap: "0.8rem",
        cursor: "pointer",
        userSelect: "none",
        border: "1px solid #ffffff",
        transition: "all .1s",
        "&.clicked": {
            border: `1px solid ${appConfig.style.colors.color1}`,
            background: appConfig.style.colors.purple,
            color: appConfig.style.colors.color1,
        },
    },
});

export const WaterfallStep = styled.div({
    padding: "4rem 10rem",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flex: 1,
}, ({ small = false }: { small?: boolean }) => ({
    ...(small ? {
        maxWidth: "120rem",
        marginInline: 'auto',
        padding: "4rem 6rem"
    } : {})
}))

export const WaterfallFooter = styled.div({
    // padding: "4rem 9rem 4rem 9.4rem",
    display: "flex",
    borderTopWidth: 1,
    borderTopStyle: "solid",
    borderTopColor: appConfig.style.colors.table,
    justifyContent: "space-between",
    padding: "2rem 0rem",
    marginInline: "10rem"
})

export default AddEditWaterfallStyle;
