import { OnboardingStatus } from '../../../Models/API/Expensing/onboarding-status-enum';
import { Routes } from '../../../Routes';
import useModal from '../../../Shared/Hooks/useModal';
import useRootStore from '../../../Shared/Hooks/useRootStore';
import InitialSetup from '../InitialSetupWizard';
import NewReportWizard from '../NewReportWizard';

const useExpensingModal = () => {
	const { expensingStore } = useRootStore();
	const { showModal } = useModal();

	const openNewReport = (onClose?: () => void) => {
		showModal({
			body: <NewReportWizard />,
			isMust: true,
			isFrameless: true,
			width: 'auto',
			height: 'auto',
			onCloseClick: onClose,
			onModalClose: () => (expensingStore.newReportData = undefined),
		});
	};

	const openInitialSetup = (status?: OnboardingStatus, onClose?: () => void) => {
		showModal({
			body: <InitialSetup startPage={status} />,
			isMust: true,
			isFrameless: true,
			width: 'auto',
			height: 'auto',
			onCloseClick: onClose,
		});
	};

	return {
		openNewReport,
		openInitialSetup,
	};
};

export default useExpensingModal;
