import { makeAutoObservable, runInAction, toJS } from "mobx";
import { UserPersonalInformation } from "../Models/API/All/user-personal-information";
import { DataRoomPermissionsLVL, PersonTypesEnum, ProductType } from "../Models/API/enums";
import { UserActiveProducts } from "../Models/API/UsersAndPermissions/user-active-products";
import { RoomNameAndUserPermission } from "../Models/API/UsersAndPermissions/users-permissions-by-company";
import SubmitPermissionEventArgs from "../Models/App/DataRoom/submit-permissions-event-args";
import SubmitShareholderEventArgs from "../Models/App/DataRoom/submit-shareholder-event-args";
import { FormMode } from "../Models/App/enums";
import CapTableService from "../Services/CapTableService";
import NotificationService from "../Services/NotificationService";
import ReferenceService, { ContactUsSubject } from "../Services/ReferenceService";
import UserService from "../Services/UserService";
import EventHandler from "../Shared/Middleware/EventHandler";
import { RootStore } from "./RootStore";
import { clearPersistedStore, makePersistable } from "mobx-persist-store";
import IAutoCompleteUser, { IAutoCompleteRequest } from "../Models/App/AutoCompleteUser";
import { ConfirmationDialogProps } from "../Models/API/All/Dialogs";
import { UserPermissions } from "../Models/API/UsersAndPermissions/user-permissions-info";

export enum UserTab {
    None = 0,
    CapTable = 1,
    DataRoom = 2,
    Options = 3,
    Permissions = 4,
}
export interface InitUserStoreAdditionalParams {
    captableProjectId?: number;
    optionsFormMode?: FormMode;
    capTableFormMode?: FormMode;
}
// This store is used to control the big user modal (captable, dataroom and permissions)
export default class UserStore {
    addNewBeneficiery: boolean = false;
    userInvitationSent: boolean = false;

    sitePermissions?: UserPermissions;
    // User related
    userId?: number;
    userPersonalDetails?: UserPersonalInformation;
    userActiveProducts?: UserActiveProducts; // Tabs in the popup are shown according to this prop

    // Cap table related
    //capTableUserInfo?: LoadPersonPreviewResultInfo;
    onSubmitHoldings = new EventHandler<any, SubmitShareholderEventArgs>();
    holdingsFormMode: FormMode = FormMode.View;

    //Permissions related
    onSubmitPermissions = new EventHandler<any, SubmitPermissionEventArgs>();
    permissionsFormMode: FormMode = FormMode.View;
    userRoomsPermissions: Array<RoomNameAndUserPermission> = [];
    showAllPermissions = false;

    // Options related
    optionsFormMode: FormMode = FormMode.View;

    // Dialog related
    showDialog = false;

    contactUsSubjects: ContactUsSubject[] | null = null;

    additionalParams: InitUserStoreAdditionalParams | undefined;

    public get showTabs() {
        if (!this.userId) {
            // Switching tabs is not allowed if it is new user
            return false;
        }
        return true;
    }

    // Services
    capTableService: CapTableService = null as any;
    userService: UserService = null as any;
    notificationService: NotificationService = null as any;
    referenceService: ReferenceService = null as any;

    userProducts = [
        { name: "Holdings", typeId: ProductType.CapTable },
        { name: "Data room", typeId: ProductType.DataRoom },
    ];

    constructor(private rootStore: RootStore) {
        makeAutoObservable(this);
        makePersistable(this, {
            name: "UserStore",
            properties: ["contactUsSubjects"],
            storage: window.sessionStorage,
            expireIn: 10800000, // 3 hours
        });
        // Track company change because services use companyID in the common values and they need to be reinstanciated after company change
        rootStore.companyStore.onCompanyChange.subscribe(this.init);
        this.init();
    }

    // This will be called autmatically when company changes
    private init = () => {
        this.referenceService = new ReferenceService(this.rootStore.companyStore.companyId);
        if (this.rootStore.companyStore.companyId) {
            this.capTableService = new CapTableService(this.rootStore.companyStore.companyId);
            this.userService = new UserService(this.rootStore.companyStore.companyId);
            this.notificationService = new NotificationService(this.rootStore.companyStore.companyId);

            RootStore.subscribeToLoading([this.capTableService, this.userService], this.rootStore);
        }
    };

    //Load cap table user data (if project id provided then it loads the data by proj id, if not provided then it will load published project data [if exists])
    // async loadCapTableUserInfo(ProjectId?: number) {
    //     this.capTableUserInfo = (await this.capTableService.LoadProjectPersonPreview({ projectID: ProjectId, projectPersonID: this.userId })).data
    // }

    // async loadDataRoomUserInfo(ProjectId?: number) {
    //     //this.capTableUserInfo = (await this.capTableService.LoadProjectPersonPreview({projectID : ProjectId, projectPersonID : this.userId})).data
    // }

    getContactUsSubjects = async () => {
        try {
            const res = await this.referenceService.getContactUsSubjects();
            this.contactUsSubjects = res.data;
            return this.contactUsSubjects;
        } catch (error) {
            console.error(error);
        }
    };

    async resetStoreToDefaultValues() {
        this.addNewBeneficiery = false;
        this.userInvitationSent = false;
        this.sitePermissions = undefined;
        this.userId = undefined;
        this.userPersonalDetails = undefined;
        this.userActiveProducts = undefined;
        this.onSubmitHoldings = new EventHandler<any, SubmitShareholderEventArgs>();
        this.holdingsFormMode = FormMode.View;
        this.onSubmitPermissions = new EventHandler<any, SubmitPermissionEventArgs>();
        this.permissionsFormMode = FormMode.View;
        this.userRoomsPermissions = [];
        this.showAllPermissions = false;
        this.optionsFormMode = FormMode.View;
        this.showDialog = false;
        this.contactUsSubjects = null;
        clearPersistedStore(this);
    }

    async loadUserInfo(contactId: number) {
        const data = (await this.userService.GetUserDetailesByUserId(contactId)).data;
        // this.rootStore.contactStore.setCurrentContactId(contactId);

        runInAction(() => {
            this.userId = contactId;
            this.userPersonalDetails = data;
        });

        return data;
    }

    async getUserInfo(userId: number) {
        return (await this.userService.GetUserDetailesByUserId(userId)).data;
    }

    async getUserPermissions(userId: number) {
        return (await this.userService.getUserPermissions(userId)).data;
    }

    async sendEmployeeInvitation(empoloyeeId: number, companyId: number, ReCaptchaToken: string) {
        const response = await this.userService.sendEmployeeInvitation(empoloyeeId, companyId, ReCaptchaToken);
        // console.log('[sendEmployeeInvitation] response.data', response)
        return response;
    }

    async getAutoComplete(query: IAutoCompleteRequest, dataRoomID?: number): Promise<IAutoCompleteUser[]> {
        try {
            const data = await this.userService.getUsersAutoComplete(query, dataRoomID);
            return data.data;
        } catch (error) {
            throw error;
        }
    }

    async inviteContact(userId: number, permissions: Partial<UserPermissions>) {
        try {
            return await this.userService.inviteContact(userId, permissions);
        } catch (error) {
            throw error;
        }
    }

    async updateUserPermissions(userId: number, permissions: Partial<UserPermissions>) {
        try {
            return await this.userService.updateUserPermissions(userId, permissions);
        } catch (error) {
            throw error;
        }
    }
}
