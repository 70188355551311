import { createContext, FC } from "react";
import { BaseService } from "../Services/BaseService";
import { currencies } from "../Shared/Config";
import { AppStateStore } from "./AppStateStore";
import { AuthStore } from "./AuthStore";

import CapTableStore from "./CapTableStore";
import EquityPlansStore from "./EquityPlansStore";
import FundingSceStore from './FundingSceStore';
import PaymentStore from "./PaymentStore";
import { CompanyStore } from "./CompanyStore";

import ShareHolderStore from "./ShareHolderStore";
import UserStore from "./UserStore";
import WaterfallStore from "./WaterfallStore";
import { ContactStore } from "./ContactStore";
import { ShareRepStore } from "./ShareRepStore";
import TrusteeStore from "./TrusteeStore";
import { ExpensingStore } from "./ExpensingStore";
import { DocumentsStore } from "./DocumentsStore";
import ValuationStore from './ValuationStore';
import DataCollectionStore from './DataCollectionStore';

export class RootStore {
	appState = new AppStateStore(this);
	companyStore = new CompanyStore(this);
	auth = new AuthStore(this);
	capTableStore = new CapTableStore(this);
	userStore = new UserStore(this);
	shareHolderStore = new ShareHolderStore(this);
	fundingStore = new FundingSceStore(this);
	paymentStore = new PaymentStore(this);
	waterfallStore = new WaterfallStore(this);
	valuationStore = new ValuationStore(this);
	equityPlansStore = new EquityPlansStore(this);
	contactStore = new ContactStore(this);
	shareRepStore = new ShareRepStore(this);
	trusteeStore = new TrusteeStore(this);
	expensingStore = new ExpensingStore(this);
	documentsStore = new DocumentsStore(this);
	dataCollectionStore = new DataCollectionStore(this);

	//tochange amir
	get currency() {
		return currencies.find((x) => x.currencyId === this.companyStore.company.currency) || { label: '', currencyId: 0, symbol: '' };
	}

	get currenciesOptions() {
		return currencies.map((c) => ({ value: c.currencyId, label: c.label }));
	}

	resetStores() {
		try {
			this.paymentStore.resetStoreToDefaultValues();
			this.capTableStore.resetStoreToDefaultValues();
			this.userStore.resetStoreToDefaultValues();
			this.fundingStore.resetStoreToDefaultValues();
			this.auth.resetStoreToDefaultValues();
			this.waterfallStore.resetStoreToDefaultValues();
			this.valuationStore.resetStoreToDefaultValues();
			this.companyStore.resetStoreToDefaultValues();
			this.contactStore.resetStoreToDefaultValues();
			this.shareRepStore.resetStoreToDefaultValues();
			this.expensingStore.resetStoreToDefaultValues();
			this.documentsStore.resetStoreToDefaultValues();
			this.dataCollectionStore.resetStoreToDefaultValues();
			this.appState.resetStoreToDefaultValues();
		} catch (error) {
			console.error(error);
		}
	}

	public static subscribeToLoading(services: BaseService[], rootStore: RootStore) {
		// services.forEach((s) => {
		// 	s.onError.subscribe((s, e) => {
		// 		rootStore.appState.error = e.error;
		// 	});
		// 	s.onRequestStart.subscribe(() => {
		// 		rootStore.appState.isLoading = true;
		// 	});
		// 	s.onRequestEnd.subscribe(() => {
		// 		rootStore.appState.isLoading = false;
		// 	});
		// });
	}
}

const RootStoreContext = createContext({} as RootStore);

const StoreProvider: FC<{ store: RootStore }> = ({ store, children }) => {
    return <RootStoreContext.Provider value={store}>{children}</RootStoreContext.Provider>;
};
export { StoreProvider, RootStoreContext };
