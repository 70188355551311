import { css } from "@emotion/css";
import appConfig from "../../../config/config";

const Footer = css({
	label: "Footer",
	borderTop: `1px solid ${appConfig.style.colors.table}`,
	padding: "1rem 0",
	display: "flex",
	alignContent: "center",
	justifyContent: "center",
	position: "relative",

	"&__LinksContainer": {
		height: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},

	"&__LinksContainer-Link": {
		color: appConfig.style.colors.text1,
		padding: "0 4rem",
		fontFamily: appConfig.style.fonts.assistant,
		fontSize: "1.2rem",
		cursor: "pointer",

		"&:not(:last-child)": {
			borderRight: `1px solid ${appConfig.style.colors.table}`,
		},

		"&:hover": {
			textDecoration: "underline !important",
		},

		"&:active": {
			textDecoration: "none",
		},
	},
});

export default Footer;
