import { css } from "@emotion/css";
import appConfig from "../../../../../config/config";

const BeneficiaryCardStyles = css({
    label: "BeneficiaryCard",
    display: "flex",
    flexDirection: "column",
    padding: "7rem 8rem 4rem",

    "&__drawer-header": {
        display: "flex",
        gap: "0.8rem",
        ".header-comment": {
            color: appConfig.style.colors.text4,
        },
    },
});

export default BeneficiaryCardStyles;
