import classNames from "classnames";
import { useEffect, useRef } from "react";

interface FullScreenProps {
	isFullScreen?: boolean;
	className?: string;
}
const FullScreenContainer: React.FC<FullScreenProps> = (props) => {
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!ref.current) return;
		ref.current.style.zIndex = props.isFullScreen ? "1000" : "1";
	}, [props.isFullScreen]);

	return (
		<div
			ref={ref}
			className={classNames(
				{ [props.className || ""]: !!props.className },
				{
					"full-screen-container": true,
					"active": props.isFullScreen,
					"inactive": props.isFullScreen === false,
				},
			)}
		>
			{props.children}
		</div>
	);
};

export default FullScreenContainer;
