import { AltProps } from "../../../../Shared/Components/WidgetDashboard/shared/dependencies/types/types";
import Styled from "./InputLabel.style";

interface Props {
	htmlFor: string;
	text: string;
}

interface LabelProps extends AltProps<Props> {}

const InputLabel = ({ ap }: LabelProps) => {
	const { htmlFor, text } = ap;
	return <Styled htmlFor={htmlFor}>{text}</Styled>;
};

export default InputLabel;
