import { css } from "@emotion/css";

export const CompanyDataStyle = css({
	label: 'CompanyData',
	display: 'flex',
	flexDirection: 'column',
	height: '50rem',
	width: '100rem',
	padding: '6rem 7.7rem 6.7rem 7.7rem',

	'.innerButtons': {
		position: 'relative',
	},

	'.buttonsContainer': {
		position: 'absolute',
		bottom: '20px',
		right: '20px',
	},
});
