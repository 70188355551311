import { useState } from "react"
import { AndOrConnector, SqlOperator } from "../../../../Screens/TrusteeEquity/Types/trusteeTypes"
import { PagedTableKeys } from "./PagedTable"
import { NumberOption } from "../../../../Models/API/All/NumberOption"
import { isNullOrUndefined } from "../../../Utilities"




import tableStyle from './PagedTable.module.css'
import { QueryOperator } from "../../../../Screens/TrusteeEquity/StaticData/dropDownValues"
import Select from "../../Select/Select"
import TextInput from "../../Input/TextInput"
import Button from "../../Button/Button"


export interface FilterLevel {
    entityEnum: number
    operator: SqlOperator
    serachValue: string
    connector: AndOrConnector
}



interface FilterProps<T> {
    dataHeaders: PagedTableKeys<T>[]
    onFiltration?: (filters: FilterLevel[]) => any
    close: () => void
}


const FilterData = <T extends Object>(props: FilterProps<T>) => {
    const [entity, setEntity] = useState<number>()
    const [operator, setOperator] = useState<SqlOperator>()
    const [searchValue, setSearchValue] = useState<string>()
    const [andOr, setAndOr] = useState<AndOrConnector>(AndOrConnector.And)

    const [filters, setFilters] = useState<FilterLevel[]>([])

    const connectorOptions: NumberOption[] = [{ label: 'And', value: AndOrConnector.And }, { label: 'Or', value: AndOrConnector.Or }]

    const filterAddHandler = () => {
        console.log('Adding Filter', entity, operator, searchValue)
        if (isNullOrUndefined(andOr) || isNullOrUndefined(entity) || isNullOrUndefined(operator) || !searchValue) return
        const newFilterLevel: FilterLevel = {} as FilterLevel
        newFilterLevel.entityEnum = entity
        newFilterLevel.operator = operator
        newFilterLevel.serachValue = searchValue
        newFilterLevel.connector = andOr

        setEntity(undefined)
        setOperator(undefined)
        setSearchValue(undefined)
        setAndOr(AndOrConnector.And)

        setFilters(filters => [...filters, newFilterLevel])
    }

    const tableFieldsOptions: NumberOption[] = props.dataHeaders?.map(header => {
        const option: NumberOption = { label: header.displayValue, value: header.entityEnumValue }
        return option
    })

    const applyFilters = () => {
        // const parsedFilters = JSON.stringify(filters)
        props.onFiltration && props.onFiltration(filters)
        props.close()
    }

    const removeFilters = () => {
        props.onFiltration && props.onFiltration([])
        setFilters([])
    }

    return (
        <div className={tableStyle.filterTable}>
            <div className={tableStyle.allFilters}>
                <p onClick={() => props.close()}>X</p>
                <p>where:</p>
                {
                    filters?.map((filter, i) => (
                        <div className={tableStyle.filterLevel}>
                            {
                                i !== 0 &&
                                <p>{connectorOptions.filter(cntr => cntr.value === filter.connector)[0]?.label}</p>
                            }
                            <p>{props.dataHeaders.find(hdr => hdr.entityEnumValue === filter.entityEnum)?.displayValue}</p>
                            <p>{QueryOperator.find(val => val.value === filter.operator)?.label}:</p>
                            <p>{filter.serachValue}</p>
                        </div>
                    ))
                }
            </div>

            <div className={tableStyle.filterParams}>

                <Select
                    value={andOr}
                    onChange={(val) => setAndOr(val)}
                    options={connectorOptions}
                    qaid=''
                    className={[tableStyle.filterElm, tableStyle.andFilter].join(' ')}
                />

                <Select
                    value={entity}
                    onChange={(val) => setEntity(val)}
                    options={tableFieldsOptions}
                    qaid=''
                    className={tableStyle.filterElm}
                />

                <Select
                    value={operator}
                    onChange={(val) => setOperator(val)}
                    options={QueryOperator}
                    qaid=''
                    className={tableStyle.filterElm}
                />

                <TextInput
                    qaid=''
                    value={searchValue}
                    onChange={val => setSearchValue(val)}
                    className={tableStyle.filterElm}
                />
            </div>

            <div className={tableStyle.addBtn}>
                <Button
                    qaid=''
                    label='Add'
                    onClick={filterAddHandler}
                    small
                />
            </div>

            <div className={tableStyle.filterButtons}>
                <Button
                    qaid=''
                    label='Clear all filters'
                    onClick={removeFilters}
                    cancel
                />
                <Button
                    qaid=''
                    label='Filter data'
                    onClick={applyFilters}
                />
            </div>

        </div>
    )

}


export default FilterData