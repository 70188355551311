import { UserPersonalInformation } from "../Models/API/All/user-personal-information";
import { CompanyRegistration, ContactInfo } from "../Models/API/Auth";
import { IAddress, IAddressRegistration, IAddressUpdate, ICompany, ICompanyRegistration, ICompanyUpdate } from "../Models/API/Company";
import { ICompanyContact, ICompanyContactRegistration, ICompanyContactUpdate } from "../Models/API/Company/company-contact";
import { countries } from "../Shared/StaticData/countries";
import { IContact, IContactCreate } from "../Models/API/Contact/contact";
import { rootStore } from "..";
import { PersonTypesEnum } from "../Models/API/enums";

/**
 * The function maps the interface CompanyRegistration to the interface ICompanyRegistration
 * @param {CompanyRegistration} companyRegistration
 * @returns {ICompanyRegistration}
 */
export const mapCompanyRegistration = (companyRegistration: CompanyRegistration): ICompanyRegistration => {
    const companyRegistrationMapped: ICompanyRegistration = {
        companyLegalName: companyRegistration.companyLegalName,
        currency: companyRegistration.currency ?? 0,
        companyNumber: companyRegistration.companyNumber,
        website: companyRegistration.website ?? "",
        industry: companyRegistration.industry,
        creationDate: companyRegistration.formationDate ? new Date(companyRegistration.formationDate).toISOString() : undefined,
        noOfEmployees: companyRegistration.noOfEmployees,
        businessDescription: companyRegistration.businessDescription ?? "",
        companyContact: {
            firstName: companyRegistration.contact.firstName || "",
            lastName: companyRegistration.contact.lastName || "",
            businessEmail: companyRegistration.contact.email || "",
        },
    };

    return companyRegistrationMapped;
};

/**
 * The function maps the interface ContactInfo to the interface ICompanyContactRegistration
 * @param {ContactInfo} contactInfo
 * @returns {ICompanyContactRegistration}
 */
export const mapContactInfo = (contact: IContactCreate): ICompanyContactRegistration => {
    const contactInfoMapped: ICompanyContactRegistration = {
        firstName: contact.firstName || "",
        lastName: contact.lastName || "",
        businessEmail: contact.email || "",
        mobile: contact.mobile,
        prefix: contact.mobilePrefix,
        organizationName: contact.organizationName,
        role: contact.contactDetails?.role,
        country: contact.contactDetails?.nationality,
        idNumber: contact.contactDetails?.idNumber,
    };

    return contactInfoMapped;
};

/**
 * The function maps the interface CompanyRegistration to the interface IAddressRegistration
 * @param {CompanyRegistration} companyRegistration
 * @returns {IAddressRegistration}
 */
export const mapAddressRegistration = (companyRegistration: CompanyRegistration): IAddressRegistration => {
    const selectedCountry = countries.find((x) => x.value === companyRegistration.nationality);

    const addressRegistrationMapped: IAddressRegistration = {
        country: selectedCountry?.label ?? "",
    };

    return addressRegistrationMapped;
};

/**
 * The functions maps the interface ICompany & ICompanyContact & IAddress to the interface CompanyRegistration
 * @param {ICompany} company
 * @param {ICompanyContact} companyContact
 * @param {IAddress} address
 * @returns {CompanyRegistration}
 */
export const mapToCompanyRegistration = (company: ICompany, companyContact: ICompanyContact, address: IAddress): CompanyRegistration => {
    const selectedCountry = countries.find((x) => x.label === address?.country);

    const companyMapped: CompanyRegistration = {
        guid: "1",
        companyLegalName: company.companyLegalName,
        currency: company.currency ?? 0,
        companyNumber: company.companyNumber,
        website: company.website ?? "",
        industry: company.industry,
        formationDate: company.creationDate ? new Date(company.creationDate) : undefined,
        noOfEmployees: company.noOfEmployees,
        businessDescription: company.businessDescription ?? "",
        contact: {
            companyId: rootStore.companyStore.companyId,
            firstName: companyContact.firstName,
            lastName: companyContact.lastName,
            email: companyContact.businessEmail,
            mobile: companyContact.mobile,
            mobilePrefix: companyContact.prefix,
            organizationName: companyContact.organizationName,
            contactDetails: {
                role: companyContact.role,
                nationality: companyContact.country,
                idNumber: companyContact.idNumber,
            },
        },
        nationality: selectedCountry?.value,
    };

    return companyMapped;
};

/**
 * The function maps the interface CompanyRegistration to the interface ICompanyUpdate
 * @param {CompanyRegistration} companyRegistration
 * @returns {ICompanyUpdate}
 */
export const mapToCompanyUpdate = (companyRegistration: CompanyRegistration): ICompanyUpdate => {
    const companyMapped: ICompanyUpdate = {
        companyLegalName: companyRegistration.companyLegalName,
        currency: companyRegistration.currency,
        companyNumber: companyRegistration.companyNumber,
        website: companyRegistration.website ?? undefined,
        industry: companyRegistration.industry,
        creationDate: companyRegistration.formationDate ? new Date(companyRegistration.formationDate).toISOString() : undefined,
        noOfEmployees: companyRegistration.noOfEmployees,
        businessDescription: companyRegistration.businessDescription ?? undefined,
    };

    return companyMapped;
};

/**
 * The function maps the interface CompanyRegirtation to the interface IAddressUpdate
 * @params {CompanyRegistration} companyRegistration
 * @returns {IAddressUpdate}
 */
export const mapToAddressUpdate = (companyRegistration: CompanyRegistration): IAddressUpdate => {
    const selectedCountry = countries.find((x) => x.value === companyRegistration.nationality);

    const addressMapped: IAddressUpdate = {
        country: selectedCountry?.label,
    };

    return addressMapped;
};

/**
 * The function maps the interface CompanyRegistration to the interface ICompanyContactUpdate
 * @param {CompanyRegistration} companyRegistration
 * @returns {ICompanyContactUpdate}
 */
export const mapToCompanyContactUpdate = (contact: IContactCreate): ICompanyContactUpdate => {
    const companyContactMapped: ICompanyContactUpdate = {
        firstName: contact.firstName || "",
        lastName: contact.lastName || "",
        businessEmail: contact.email?.replace(/\s+/g, "") || "",
        mobile: contact.mobile,
        prefix: contact.mobilePrefix,
        organizationName: contact.organizationName,
        role: contact.contactDetails?.role,
        country: contact.contactDetails?.nationality,
        idNumber: contact.contactDetails?.idNumber?.toString(),
    };

    return companyContactMapped;
};

/**
 * The function maps the interface UserPersonalInformation to the interface IContact
 * @param {UserPersonalInformation} userPersonalInformation
 * @returns {IContactCreate}
 */
export const mapToContactCreate = (userPersonalInformation: UserPersonalInformation): IContactCreate => {
    const contactMapped: IContactCreate = {
        companyId: rootStore.companyStore.companyId,
        firstName: userPersonalInformation.firstName,
        lastName: userPersonalInformation.lastName,
        companyName: userPersonalInformation.type === PersonTypesEnum.LegalCompany ? userPersonalInformation.organizationName : undefined,
        email: userPersonalInformation.email,
        mobilePrefix: userPersonalInformation.phoneNationality,
        mobile: userPersonalInformation.phoneNumber,
        isLegalEntity: userPersonalInformation.isLegalEntity,
        contactDetails: {
            organizationName:
                userPersonalInformation.type === PersonTypesEnum.Person ? userPersonalInformation.organizationName : undefined,
            employeeNumber: userPersonalInformation.employeeNumber,
            idNumber: userPersonalInformation.id,
            photo: userPersonalInformation.photo?.fileData,
            nationality: userPersonalInformation.nationality,
            role: userPersonalInformation.role,
        },
    };

    return contactMapped;
};

/**
 * The function maps the interface UserPersonalInformation to the interface IContact
 * @param {IContactCreate} userPersonalInformation
 * @returns {UserPersonalInformation}
 */
export const convertContactToShareholer = (contact: IContact): UserPersonalInformation => {
    const contactMapped: UserPersonalInformation = {
        firstName: contact.firstName,
        lastName: contact.lastName,
        email: contact.email ?? "",
        phoneNationality: contact.mobilePrefix ?? "",
        phoneNumber: contact.mobile ?? "",
        organizationName: contact.contactDetails?.organizationName ?? "",
        role: contact.contactDetails?.role ?? 0,
        nationality: contact.contactDetails?.nationality ?? 0,
        id: contact.contactDetails?.idNumber ?? "",
        photo: {
            fileData: contact.contactDetails?.photo ?? "",
            fileName: "",
            fileSignature: "",
            folderId: 0,
        },
        isLegalEntity: !!contact.isLegalEntity,
        //companyName?: string,
        corporateNumber: contact.companyName ?? "",
        type: contact.isLegalEntity ? PersonTypesEnum.LegalCompany : PersonTypesEnum.Person,
        userID: 0,
        dialingCode: contact.mobilePrefix ?? "",
        isEmployeeProfile: false,
        employeeNumber: contact.contactDetails?.employeeNumber,
        contactId: contact.contactId,
    };

    return contactMapped;
};

/**
 * The function maps the interface IcontactCreate to the interface IContactUpdate
 * @param {IContactCreate} contactCreate
 * @returns {IContactCreate}
 */
export const mapToContactUpdate = (contactCreate: IContactCreate): IContactCreate => {
    const contactUpdateMapped: IContactCreate = {
        companyId: rootStore.companyStore.companyId,
        firstName: contactCreate.firstName,
        lastName: contactCreate.lastName,
        companyName: contactCreate.companyName ?? undefined,
        email: contactCreate.email ?? undefined,
        mobilePrefix: contactCreate.mobilePrefix ?? undefined,
        mobile: contactCreate.mobile ?? undefined,
        isLegalEntity: contactCreate.isLegalEntity ?? undefined,
        contactDetails: {
            organizationName: contactCreate.contactDetails?.organizationName ?? undefined,
            employeeNumber: contactCreate.contactDetails?.employeeNumber ?? undefined,
            idNumber: contactCreate.contactDetails?.idNumber ?? undefined,
            photo: contactCreate.contactDetails?.photo ?? undefined,
            nationality: contactCreate.contactDetails?.nationality ?? undefined,
            role: contactCreate.contactDetails?.role ?? undefined,
        },
    };

    return contactUpdateMapped;
};
