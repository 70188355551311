import { ICommonApiRequestValues } from "../Models/App/DataRoom/ICommonApiRequestValue";
import { BaseService } from "./BaseService";

export type ContactUsSubject = {
	value: string;
	description: string;
};

export default class ReferenceService extends BaseService {
	endPoints = {
		GetContactUsSubjects: "/General/contactus-subject",
	};
	commonValues: ICommonApiRequestValues;

	constructor(companyID: number) {
		super();
		this.commonValues = {
			companyID: companyID,
		};
	}

	// async getContactUsSubjects() {
	//   try {
	//     const res = await this.httpReference.get(this.endPoints.GetContactUsSubjects);
	//     return res.data;
	//   } catch (error) {
	//     console.error(error);
	//     throw error;
	//   }
	// }

	async getContactUsSubjects() {
		return await this.safeExecuteAsync(async () => {
			const res = await this.httpReference.get(this.endPoints.GetContactUsSubjects);
			return res.data;
		});
	}
}
