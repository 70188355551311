import Flex from "../../../../../Shared/Components/Layout/Flex";
import { MultiStepElementProps } from "../../../../../Shared/Hooks/useMultiStepForm";
import useRootStore from "../../../../../Shared/Hooks/useRootStore";
import { ImportExcelStep } from "../helpers/types";
import TableConfig from "./TableConfig";

const HeadlineSelection = ({ goTo }: MultiStepElementProps) => {
	const { equityPlansStore } = useRootStore();

	return (
		<Flex direction="column" flex={0} justify="start">
			<div className="bold">Do you have headlines?</div>
			<div>Please select row with your headlines if you have any</div>
			<TableConfig
				data={equityPlansStore.importConfig.originalData}
				onSelect={(rIdx, cIdx) => {
					equityPlansStore.importConfig.headlineIndex = rIdx;
					goTo?.(ImportExcelStep.rowSelection);
				}}
			/>
		</Flex>
	);
};

export default HeadlineSelection;
