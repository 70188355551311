import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

i18next
	.use(Backend)
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	.init(
		{
			returnEmptyString: true,
			// debug: true,// FOR DEBUG ONLY:
			fallbackLng: "en-us",
			interpolation: {
				escapeValue: false, // not needed for react as it escapes by default
			},
			backend: {
				// for all available options read the backend's repository readme file
				loadPath: "/locales/{{lng}}/{{ns}}.json",
			},
			lowerCaseLng: true
		},
		// err => {console.log(err)}
	);
export default i18next;

// export const i18n = {
//     t: (key: string, fallback?: string, values?: any) => {
//         // FOR DEBUG ONLY:
//         // const isExists = i18next.exists(key);
//         // return `${!isExists ? '**' : ''}####${i18next.t(key, values)}####`;
//         return i18next.t(key, values);
//     }
// }
