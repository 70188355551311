import { css } from "@emotion/css";
import { observer } from "mobx-react-lite";
import { forwardRef, useImperativeHandle, useState } from "react";
import { UnderlyingAssetModelEnum } from "../../../../../Models/API/Expensing/underlying-enum";
import RadioButton from "../../../../../Shared/Components/RadioButton/RadioButton";
import Separator from "../../../../../Shared/Components/Separator";
import Spinner from "../../../../../Shared/Components/Spinner/Spinner";
import useRootStore from "../../../../../Shared/Hooks/useRootStore";
import appConfig from "../../../../../config/config";
import MarketTable from "./MarketTable";

const Style = css({
    label: "FairMarketValue",
    display: "flex",
    flexDirection: "column",
    gap: "2.4rem",
    width: "100%",
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
    "&__label": {
        fontWeight: 500,
        color: appConfig.style.colors.text1,
    },
    "&__section": {
        display: "flex",
        flexDirection: "column",
        "> div:first-child": {
            boxShadow: appConfig.style.boxShadow.card1,
        },
    },
    "&__radio-container": {
        display: "flex",
        padding: "1.6rem 0",
        "> *": {
            minWidth: "30rem",
        },
    },
    "&__actions": {
        position: "absolute",
        background: "#ffffff",
        transition: "opacity .3s",
        right: 0,
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: "1rem",
    },
});

type Props = {
    isInitial?: boolean;
};

const FairMarketValue = forwardRef<any, any>(({ isInitial = true }: Props, forwardedRef) => {
    const { expensingStore } = useRootStore();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useImperativeHandle(forwardedRef, () => ({
        onValidate: () => {
            return expensingStore.company?.underlyingAssetModel !== UnderlyingAssetModelEnum.None;
        },
    }));

    const onCalculationChange = async (value: UnderlyingAssetModelEnum) => {
        if (expensingStore.company?.underlyingAssetModel === value || isLoading) return;
        setIsLoading(true);
        await expensingStore.updateCompany({ underlyingAssetModel: value });
        setIsLoading(false);
    };

    return (
		<div className={Style}>
			{isLoading && isInitial && <Spinner attachParent center />}
			<div className={`${Style}__section`}>
				<span className={`${Style}__label`}>409A</span>
				<Separator />
				<span style={{ color: appConfig.style.colors.text2 }}>Market value calculation when value is missing for a specific grant date</span>
				<div className={`${Style}__radio-container`}>
					<RadioButton
						qaid="409A.Radio.LatestValuation"
						name="underlyingAssetModel"
						value={UnderlyingAssetModelEnum.LatestValuation}
						checked={expensingStore.company?.underlyingAssetModel === UnderlyingAssetModelEnum.LatestValuation}
						onChange={onCalculationChange}
						label="Use latest valuation date"
					/>
					<RadioButton
						qaid="409A.Radio.StraightLine"
						name="underlyingAssetModel"
						value={UnderlyingAssetModelEnum.StraightLine}
						checked={expensingStore.company?.underlyingAssetModel === UnderlyingAssetModelEnum.StraightLine}
						onChange={onCalculationChange}
						label="Weighted average between valuation dates"
					/>
				</div>
			</div>
			<div className={`${Style}__section table`}>
				<MarketTable isInitial={isInitial} />
			</div>
		</div>
	);
});

export default observer(FairMarketValue);
