import { css } from "@emotion/css";
import appConfig from "../../../config/config";

const MainMenuStyle = css({
	label: "EquityMenu",
	"&__menu": {
		height: "7.1rem",
		width: "100%",
		display: "flex",
		alignItems: "center",
		padding: "0 4rem",
		boxShadow: `3px 3px 3px 0px ${appConfig.style.colors.boxShadowColor1}`,
		position: "relative",
	},
	"&__backdrop": {
		background: appConfig.style.colors.background2,
		position: "absolute",
		top: "7.1rem",
		left: 0,
		height: "calc(100% - 7.1rem)",
		width: "100%",
		transition: "opacity .2s",
		zIndex: -1,
		opacity: 0,
		"&.show": {
			zIndex: 3,
			opacity: 0.7,
		},
	},
	"&__version-select": {
		maxWidth: "37rem",
		"*": {
			color: appConfig.style.colors.text1,
		},
		".published": {
			color: appConfig.style.colors.orange,
		},
		".draft": {
			color: appConfig.style.colors.text4,
		},
	},
	"&__menu-side-a": {
		display: 'flex',
		flexDirection: 'row',
		flex: 1,
		gap: '2.6rem',
	},
	"&__menu-left-side": {
		display: 'flex',
		flexDirection: 'row',
		gap: '2.6rem',
	},
	"&__btn-actions": {
		background: appConfig.style.colors.gradientPurpleVertical,
		padding: "0.8rem 1.2rem",
		borderRadius: 8,
		color: "#ffffff",
		display: "flex",
		alignItems: "center",
		gap: "0.8rem",
		cursor: "pointer",
		userSelect: "none",
		border: "1px solid #ffffff",
		transition: "all .1s",
		"&.clicked": {
			border: `1px solid ${appConfig.style.colors.color1}`,
			background: appConfig.style.colors.purple,
			color: appConfig.style.colors.color1,
		},
	},
	"&__reset-icon": {
		width: "2.4rem",
	},
});

export default MainMenuStyle;
