import { IC_DOTS_MENU, IC_EDIT2, IC_TRASH2 } from "../../../../Assets";
import Menu, { MenuItem } from "../../../../Shared/Components/Menu/index";
import { TableActionsStyle } from "./TableActions.style";

interface TableActionsProps {
	isHover?: boolean;
	items: MenuItem[];
	clickAction?: () => unknown;
	cssClass?: string;
	autoClose?: () => boolean;
}

const TableActions = (props: TableActionsProps) => {
	return (
		<div
			className={`${TableActionsStyle} ${props.cssClass}`}
			onClick={(e) => {
				e.stopPropagation();
			}}
		>
			{/* <img src={IC_DOTS_MENU} onClick={props.clickAction} /> */}
			<Menu
				position={{ left: "4rem", top: "-0.5rem" }}
				autoClose={props.autoClose}
				items={props.items}
				// items={[
				// 	{
				// 		label: "Edit",
				// 		icon: IC_EDIT2,
				// 		qaid: "EditShareholder.Button.Edit",
				// 		// onClick: onToggleUpdate
				// 	},
				// 	{
				// 		label: "Delete",
				// 		icon: IC_TRASH2,
				// 		qaid: "EditShareholder.Button.DeleteShareholder",
				// 		// onClick: onDeleteShareholder
				// 	},
				// ]}
				isHorizontal
			/>
		</div>
	);
};
export default TableActions;
