import { useEffect, useRef, useState } from "react";
import TransactionDetails from "./Components/TransactionDetails";
import TransactionDocs from "./Components/TransactionDocs";
import useRootStore from "../../Shared/Hooks/useRootStore";

const TIMER_INTERVAL = 30000;

export default function ShareRepScreen() {
    const {
        shareRepStore,
        companyStore
    } = useRootStore();

    const timer = useRef<NodeJS.Timeout|null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
		(async () => {
            await shareRepStore.init(companyStore.odooTransactionId);
            setIsLoading(false);

            timer.current = setInterval(async () => {
                const after = shareRepStore.notifications?.length ?
                    shareRepStore.notifications[shareRepStore.notifications.length - 1].id : undefined;
                await shareRepStore.refresh(companyStore.odooTransactionId, after);
            }, TIMER_INTERVAL);
        })();

        return () => {
            if(timer.current) {
                clearInterval(timer.current);
                timer.current = null;
            }
        }
    })

    return (
        isLoading ? <div>Loading...</div> :
        <div>
            <TransactionDetails/>
            <TransactionDocs/>
        </div>)
}