import { useRef, useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import CapTable from "./components/CapTable";
import Equity from "./components/Equity";
import Stakeholders from "./components/Stakeholders";
import Updates from "./components/Updates";
import Valuation from "./components/Valuation";
import {
    DashboardStyle,
    DashboardWidgetCaptable,
    DashboardWidgetEquity,
    DashboardWidgetStakeholders,
    DashboardWidgetUpdates,
    DashboardWidgetValuation,
} from "./index.style";
import DashboardWidget from "./components/DashboardWidget/DashboardWidget";
import useRootStore from "../../Shared/Hooks/useRootStore";
import { ICapTableDashboard, IFundingGraph } from "../../Models/API/CapTable/dashboard-cap-table";
import { formatDate } from "../../Shared/Utilities";
import { CapTablePermission, OptionsPermission } from "../../Models/API/UsersAndPermissions/permissions-enum";

const DashboardScreen = () => {
    const mounted = useRef<boolean>(true);
    const { capTableStore, auth } = useRootStore();
    const [capTableData, setCapTableData] = useState<ICapTableDashboard | null>(null);
    const [valuationData, setValuationData] = useState<IFundingGraph[] | null>(null);
    const [publishedCapTableDate, setPublishedCapTableDate] = useState<Date | null>(null);
    const hasCapTablePermission = auth.permissions ? auth.permissions.capTablePermission !== CapTablePermission.no_access : false;
    const hasEquityPermission = auth.permissions
        ? auth.permissions.optionPermission !== OptionsPermission.NotAllowed &&
        auth.permissions.optionPermission !== OptionsPermission.Viewer
        : false;

    useEffect(() => {
        const fetchData = async () => {
            const res = await capTableStore.getExternalDashBoardCapTableData();
            if (mounted.current && res && res.data) {
                setCapTableData(res.data.capTableDashboard);
                setValuationData(res.data.fundingGraph);
                setPublishedCapTableDate(res.data.publishedCapTable);
            }
        };

        !capTableData && !valuationData && hasCapTablePermission && fetchData();
        return () => {
            mounted.current = false;
        };
    }, []);

    return (
        <DashboardStyle className="page--dashboard">
            <div className="grid-container">
                <DashboardWidgetCaptable>
                    <DashboardWidget
                        title="Cap Table"
                        subtitle={publishedCapTableDate ? `${formatDate(publishedCapTableDate)} (Published)` : undefined}
                    >
                        <CapTable
                            data={capTableData}
                            hasPermission={hasCapTablePermission}
                            publishedDate={publishedCapTableDate}
                        />
                    </DashboardWidget>
                </DashboardWidgetCaptable>

                <DashboardWidgetValuation>
                    <DashboardWidget title="Valuation history">
                        <Valuation
                            data={valuationData}
                            hasPermission={hasCapTablePermission}
                            publishedDate={publishedCapTableDate}
                        />
                    </DashboardWidget>
                </DashboardWidgetValuation>

                <DashboardWidgetEquity>
                    <DashboardWidget title="Equity awards allocation">
                        <Equity hasPermission={hasEquityPermission} />
                    </DashboardWidget>
                </DashboardWidgetEquity>

                <DashboardWidgetStakeholders>
                    <DashboardWidget title="Stakeholders">
                        <Stakeholders hasPermission={true} />
                    </DashboardWidget>
                </DashboardWidgetStakeholders>

                <DashboardWidgetUpdates>
                    <DashboardWidget title="Latest updates">
                        <Updates
                            capTablePermission={hasCapTablePermission}
                            hasEquityPermission={hasEquityPermission}
                        />
                    </DashboardWidget>
                </DashboardWidgetUpdates>
            </div>
        </DashboardStyle>
    );
};

export default observer(DashboardScreen);
