import styled from "@emotion/styled";
import { ISpinnerProps } from "./Spinner";
import appConfig from "../../../config/config";

const FullBackground = styled.div(
    {
        label: "Spinner",
        // position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        // height: "100vh",
        zIndex: 999999999999,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    ({ attachParent, background }: ISpinnerProps) => ({
        // width: attachParent ? '100%' : '100vh',]
        background: attachParent && !background ? "#ffffff77" : background || appConfig.style.colors.background2Opacity70,
        position: attachParent ? "absolute" : "fixed",
        // height: attachParent ? "-webkit-fill-available" : "100vh",
        height: attachParent ? "-webkit-fill-available" : "100%",
    })
);

export default FullBackground;
