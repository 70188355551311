import { css } from "@emotion/css";
import appConfig from "../../../../../config/config";

const NewCancelationStyles = css({
	padding: "7rem 8rem 4rem",
	".cancell-all-switch": {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		border: `1px solid ${appConfig.style.colors.color2}`,
		borderRadius: "5px",
		// "& .selector": {
		//     width: '50%',
		//     margin: '1rem',
		//     padding: '1rem 0'
		// }
	},

	".avilable": {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		width: "100%",
	},

	".inputs": {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		marginTop: "2rem",
	},

	"&__form": {
		display: "grid",
		gridTemplateColumns: "repeat(4, 1fr)",
		gridGap: "2.4rem",
	},
	".secondary-switch": {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
		alignItems: "center",
		marginTop: "1rem 0",
		padding: "1rem",
		// border: `1px solid ${appConfig.style.colors.border1}`,
		// borderRadius: '3px'
	},
});

export default NewCancelationStyles;
