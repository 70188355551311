import { css } from '@emotion/css';
import { AmortizationDate, ExpensingBasicCompany, ExpensingMethod } from '../../../Models/API/Expensing/options-migration';
import { Currencies } from '../../../Models/API/enums';
import Button from '../../../Shared/Components/Button/Button';
import { useAppendState } from '../../../Shared/Hooks/useAppendState';
import { MultiStepElementProps } from '../../../Shared/Hooks/useMultiStepForm';
import useRootStore from '../../../Shared/Hooks/useRootStore';
import General from '../Components/Settings/General';
import { useState } from 'react';
import { UnderlyingAssetModelEnum } from '../../../Models/API/Expensing/underlying-enum';
import { observer } from 'mobx-react-lite';
import { OnboardingStatus } from '../../../Models/API/Expensing/onboarding-status-enum';

const Style = css({
	label: 'ExpensingStartPage',
	display: 'flex',
	flexDirection: 'column',
	width: '94rem',
	padding: '6rem 8rem',
	'&__title': {
		fontWeight: 500,
		fontSize: '2.5rem',
		marginBottom: '5rem',
	},
	'&__next-btn': {
		marginTop: '0.8rem',
	},
});

const GeneralSettings = (props: MultiStepElementProps) => {
	const { currenciesOptions, expensingStore, companyStore } = useRootStore();
	const [company, _, onInputHandler] = useAppendState<ExpensingBasicCompany>({
		companyName: companyStore.company.companyLegalName,
		operationalCurrency: currenciesOptions.find((c) => c.value === expensingStore.company?.operationalCurrency ?? Currencies.USD)?.value,
		expensingMethod: expensingStore.company?.expensingMethod ?? ExpensingMethod.Accelerated,
		expensingAmortizationStartDate: expensingStore.company?.expensingAmortizationStartDate ?? AmortizationDate.VestingStartDate,
		underlyingAssetModel: expensingStore.company?.underlyingAssetModel ?? UnderlyingAssetModelEnum.None,
	} as ExpensingBasicCompany);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const onSubmitHandler = async () => {
		// to add validation
		setIsLoading(true);
		const res = expensingStore.company?.companyId ? await expensingStore.updateCompany(company) : await expensingStore.createCompany(company);

		if (res.isSuccess) {
			const isUpdated = await expensingStore.setCompanyOnboardingStatus(OnboardingStatus.ComparableCompanies);
			isUpdated && props.onNextStep?.();
		}
		setIsLoading(false);
	};

	return (
		<div className={Style}>
			<div className={`${Style}__title`}>Expensing general settings</div>
			<General migration={company} onInputHandler={onInputHandler} />
			<div className="flex justify-between">
				<Button className={`${Style}__next-btn`} qaid="ExpensingInitialSetup.Button.Back" label="Back" inverse cancel onClick={props.onPrevStep} />
				<Button
					className={`${Style}__next-btn`}
					qaid="ExpensingInitialSetup.Button.Next"
					label="Next"
					position="end"
					onClick={onSubmitHandler}
					isLoading={isLoading}
				/>
			</div>
		</div>
	);
};

export default observer(GeneralSettings);
