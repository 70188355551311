import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useQueryParams = () => {
	const [queryParams, setQueryParams] = useState<Record<string, string>>({});
	const location = useLocation();

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const paramsObject: Record<string, string> = {};
		searchParams.forEach((value, key) => {
			paramsObject[key] = value;
		});
		setQueryParams(paramsObject);
	}, []);

	return queryParams;
};

export default useQueryParams;
