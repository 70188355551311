import { css } from "@emotion/css";
import appConfig from "../../../../config/config";

const TrusteeActionsMenuStyle = css({
	label: "ActionsMenu",
	position: "absolute",
	top: "calc(100% - 0.4rem)",
	right: "4rem",
	minWidth: "24rem",
	background: "#ffffff",
	borderRadius: 8,
	boxShadow: appConfig.style.boxShadow.dark,
	padding: "1rem 1.5rem",
	display: "flex",
	flexDirection: "column",
	zIndex: 4,
	"&__item": {
		cursor: "pointer",
		display: "flex",
		margin: "0.4rem 0",
		alignItems: "center",
		padding: "0.8rem",
		position: "relative",
		gap: "1.6rem",
		transition: "all .15s",
		img: {
			width: "2rem",
			height: "2.8rem",
		},
		"&.separator": {
			// borderBottom: `1px solid ${appConfig.style.colors.text3}`,
			"&::after": {
				content: "''",
				position: "absolute",
				width: "calc(100% - 1.6rem)",
				height: 1,
				background: appConfig.style.colors.text3,
				top: "calc(100% + 3px)",
			},
		},
		"&:hover": {
			background: appConfig.style.colors.background1,
			borderRadius: 8,
		},
		// "&.bc": {
		// 	background: appConfig.style.colors.background1,
		// 	borderRadius: 8,
		// },
	},
});

export default TrusteeActionsMenuStyle;
