import { isNullOrUndefined, isString } from "../Utilities";
import { rootStore } from "../..";
import { Currencies } from "../../Models/API/enums";

export function formatNumberWithCurrency(n?: number | string, includeZero = true) {
	if (isString(n)) n = +n;
	if (n === 0 && !includeZero) return "";
	if (!isNullOrUndefined(n) && !isNaN(n)) {
		return getCompanyCurrencySymbol() + new Intl.NumberFormat().format(n);
	}
	return "";
}

function getCompanyCurrencySymbol(currency: Currencies = rootStore.companyStore.company.currency) {
	switch (currency) {
		case Currencies.USD:
			return "$";

		case Currencies.EUR:
			return "€";

		case Currencies.JPY:
			return "¥";

		case Currencies.GBP:
			return "£";

		case Currencies.ILS:
			return "₪";

		default:
			return "$";
	}
}
