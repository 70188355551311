
type Props = {
    src: string;
    autoplay?: boolean;
}

const YouTube = ({ src, autoplay = true }: Props) => {

    return (
        <iframe src={`https://www.youtube.com/embed/${src}&autoplay=${autoplay ? "1" : "0"}`} frameBorder={0} height="100%" width="100%" title="YouTube video player" allow="autoplay; allow-fullscreen" />
    )
}

export default YouTube;