import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import useRootStore from "../../Shared/Hooks/useRootStore";
import Templates from "./Templates";
import { css } from "@emotion/css";
import Tabs from "./Components/Tabs";
import Signatures from "./Signatures";

const Style = css({
    label: "Documents",
    padding: "4rem 10rem",
});

const Documents = () => {
    const { companyStore, documentsStore } = useRootStore();

    useEffect(() => {
        documentsStore.getTemplates();
    }, [companyStore.companyId]);

    return <section className={Style}>{documentsStore.selectedTab === "signatures" ? <Signatures /> : <Templates />}</section>;
};

export default observer(Documents);
