import { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { css } from '@emotion/css';
import { MultiStepElementProps, ForwardedRef } from '../../../Shared/Hooks/useMultiStepForm';
import Image from '../../../Shared/Components/Image';
import { IC_EXPENSING_LOGO } from '../../../Assets';
import Title from '../../../Shared/Components/Layout/Title';
import Button from '../../../Shared/Components/Button/Button';
import useRootStore from '../../../Shared/Hooks/useRootStore';
import { OnboardingStatus } from '../../../Models/API/Expensing/onboarding-status-enum';

const Style = css({
	label: 'ExpensingStartPage',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	width: '87rem',
	height: '50rem',
	'> img': {
		marginBottom: '1.6rem',
	},

	'&__text': {
		marginTop: '0.4rem',
		marginBottom: '6rem',
	},
});

const StartPage = (props: MultiStepElementProps) => {
	const { expensingStore } = useRootStore();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const onSubmit = async () => {
		setIsLoading(true);
		const isUpdated = await expensingStore.setCompanyOnboardingStatus(OnboardingStatus.GeneralSettings);
		setIsLoading(false);
		isUpdated && props.onNextStep?.();
	};

	return (
		<div className={Style}>
			<Image src={IC_EXPENSING_LOGO} width="13.5rem" />
			<Title type="secondary">Expensing general settings</Title>
			<span className={`${Style}__text`}>Determine the general settings of the company's Expensing reports</span>
			<Button qaid="StartPage.Button.Start" label="Let's start" onClick={onSubmit} position="center" width="15rem" isLoading={isLoading} />
		</div>
	);
};

export default StartPage;
