import React from "react";
import { Trans as TransN, useTranslation } from "react-i18next";
interface TransProps {
	from?: string;
	values?: any;
}

let inject = (str: string, obj: any) => str.replace(/\${(.*?)}/g, (x, g) => obj[g]);

const Trans: React.FC<TransProps> = (props) => {
	const { t, i18n } = useTranslation();
	//const txt = t((props.from ?? ''), props.values);
	const isExists = i18n.exists(props.from ?? "");

	return (
		// FOR DEBUG ONLY:
		// <i style={{ backgroundColor: isExists ?  'green'  : 'red' }}>
		//     <TransN values={props.values} i18nKey={props.from}>{props.children}</TransN>
		// </i>

		<TransN values={props.values} i18nKey={props.from}>
			{props.children}
		</TransN>
	);
};

export default Trans;

export function useTrans() {
	const { t, i18n } = useTranslation();

	const customT = (key: string, fallback?: string, values?: any) => {
		const isExists = i18n.exists(key);
		return t(key, values);
		// FOR DEBUG ONLY:
		// return `####${t(key, values)}####`;
	};
	return { t: customT, i18n };
}
