import { useState } from "react";
import { Link } from "react-router-dom";
import { IMG_VERIFY_MAIL } from "../../../../Assets";
import classNames from "classnames";
import useScreenSize from "../../../../Shared/Hooks/useScreenSize";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import ErrorMessage from "../../../../Shared/Components/Layout/ErrorMessage";
import Flex from "../../../../Shared/Components/Layout/Flex";
import useRecaptcha from "../../../../Shared/Hooks/useRecaptcha";

export interface IProps {
	readonly email: string;
}

export const VerifyMailMsg = (props: IProps) => {
	const { auth } = useRootStore();
	const { isMobile, isTablet } = useScreenSize();

	const [blockResendState, setBlockResendState] = useState<boolean>(false);
	const [isEmailResent, setIsEmailResent] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const { execute } = useRecaptcha();

	const onResendEmail = async () => {
		//prevent user from spamming the resend button
		setBlockResendState(() => true);

		setTimeout(() => {
			setBlockResendState(() => false);
		}, 5000);
		execute(async (token) => {
			const res = await auth.reSendVerificationMail(token);

			if (res.isSuccess) {
				setIsEmailResent(true);
				setErrorMessage(null);
			} else {
				setErrorMessage("An error occurred");
			}
		});
	};

	return (
		<section>
			<div
				className={classNames({
					"account-verifi-msg": true,
					mobile: isMobile,
				})}
			>
				<div className="reg-modal-content">
					<div className={classNames("reg-modal-title", { mobile: isMobile })}>Verify your account</div>
					<div className="verifi-msg-img">
						<img className="ic" src={IMG_VERIFY_MAIL} alt="" />
					</div>
					<div
						className={classNames("reg-modal-msg", {
							mobile: isMobile,
							tablet: isTablet,
						})}
					>
						A verification email has been sent to
					</div>
					<div
						className={classNames("reg-modal-to-mail", {
							tablet: isTablet,
							mobile: isMobile,
						})}
					>
						{props.email}
					</div>
					<Flex position="relative" width={"100%"}>
						<ErrorMessage top={0} left={"43%"}>
							{errorMessage}
						</ErrorMessage>
					</Flex>
				</div>
			</div>
			<div className="account-footer verifi-msg-footer">
				<div>Didn't get an email?&nbsp;</div>
				{isEmailResent && blockResendState ? (
					<span>Sent</span>
				) : (
					<Link
						className="account-link"
						key="registration"
						to="#"
						onClick={(e: any) => {
							e.preventDefault();
							onResendEmail();
						}}
					>
						Click to resend
					</Link>
				)}
			</div>
		</section>
	);
};

export default VerifyMailMsg;
