import { CapTableProductType, ProductTypeEnum } from "../enums";

export enum FundingSaveProductType {
	Publish = 1,
	CapTable = 2,
	NextRound = 3,
}

export interface FundingSaveRequest {
	companyID?: any;
	snapshotID: number;
	projectName?: string;
	productType: FundingSaveProductType;
}
