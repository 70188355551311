import { CSSProperties } from "react";
import PaginationComp from "./index.style";
import Image from "../Image";
import { IC_ARROW_RIGHT, IC_ARROW_RIGHT_WHITE } from "../../../Assets";

type Props = {
    className?: string;
    style?: CSSProperties;
    onNavHandler: (pageIdx: number) => void;
    currentPage: number;
    pageCount: number;
    maxPagesInterval?: number;
};

const Pagination = ({ onNavHandler, currentPage, pageCount, className, style, maxPagesInterval = 5 }: Props) => {
    const isBackDisabled = currentPage === 0;
    const isNextDisabled = currentPage === pageCount - 1 || pageCount === 0;

    const from =
        currentPage < Math.ceil(maxPagesInterval / 2)
            ? 0
            : currentPage + Math.ceil(maxPagesInterval / 2) > pageCount
            ? pageCount - maxPagesInterval
            : currentPage - Math.floor(maxPagesInterval / 2);
    const to =
        maxPagesInterval > pageCount
            ? pageCount - 1
            : currentPage < Math.ceil(maxPagesInterval / 2)
            ? maxPagesInterval - 1
            : currentPage + Math.ceil(maxPagesInterval / 2) > pageCount
            ? pageCount - 1
            : currentPage + Math.floor(maxPagesInterval / 2);

    const showLastPage = currentPage < pageCount / 2 && pageCount > maxPagesInterval;
    const showFirstPage = currentPage >= pageCount / 2 && pageCount > maxPagesInterval;

    const arrayRange = (start: number, stop: number, step: number = 1) =>
        Array.from({ length: (stop - start) / step + 1 }, (value, index) => start + index * step);

    return (
        <PaginationComp.Container
            className={className}
            style={style}
        >
            <PaginationComp.NavButton
                type="back"
                disabled={isBackDisabled}
                onClick={() => !isBackDisabled && onNavHandler(currentPage - 1)}
            >
                <Image
                    src={isBackDisabled ? IC_ARROW_RIGHT_WHITE : IC_ARROW_RIGHT}
                    width="1rem"
                />
            </PaginationComp.NavButton>

            {showFirstPage && (
                <>
                    <PaginationComp.PageButton
                        active={0 === currentPage}
                        onClick={() => onNavHandler(0)}
                    >
                        1
                    </PaginationComp.PageButton>
                    <PaginationComp.PageButton>...</PaginationComp.PageButton>
                </>
            )}

            {arrayRange(from, to).map((page) => (
                <PaginationComp.PageButton
                    key={page}
                    active={page === currentPage}
                    onClick={() => onNavHandler(page)}
                >
                    {page + 1}
                </PaginationComp.PageButton>
            ))}

            {showLastPage && (
                <>
                    <PaginationComp.PageButton>...</PaginationComp.PageButton>
                    <PaginationComp.PageButton
                        active={pageCount - 1 === currentPage}
                        onClick={() => onNavHandler(pageCount - 1)}
                    >
                        {pageCount}
                    </PaginationComp.PageButton>
                </>
            )}

            <PaginationComp.NavButton
                type="next"
                disabled={isNextDisabled}
                onClick={() => !isNextDisabled && onNavHandler(currentPage + 1)}
            >
                <Image
                    src={isNextDisabled ? IC_ARROW_RIGHT_WHITE : IC_ARROW_RIGHT}
                    width="1rem"
                />
            </PaginationComp.NavButton>
        </PaginationComp.Container>
    );
};

export default Pagination;
