import { css } from "@emotion/css";

export const PreviewStyle = css({
    label: "Preview",
    display: "flex",
    flexDirection: "column",
    background: "#4d4d4d",
    padding: " 2rem 4rem",
    border: "2px solid #ffffff",
    borderRadius: 8,
});
