import { css } from '@emotion/css';
import styled from '@emotion/styled';
import appConfig from '../../../config/config';
import { OnboardingStatus } from '../../../Models/API/Expensing/onboarding-status-enum';

type Props = {
	steps: number;
	currentStepIdx: number;
	lastStepDoneIdx?: OnboardingStatus;
};

const Style = css({
	label: 'ExpensingStepIndicator',
	position: 'absolute',
	top: '3.6rem',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	display: 'flex',
	gap: '0.4rem',
	alignItems: 'center',
});

const Indicator = styled.div(
	{
		borderRadius: '50%',
		transition: 'all .5s',
	},
	({ isCurrent, isDone }: { isCurrent: boolean; isDone: boolean }) => ({
		width: isCurrent ? 10 : 6,
		height: isCurrent ? 10 : 6,
		background: isDone ? appConfig.style.colors.color1 : appConfig.style.colors.lightGray,
	})
);

const StepIndicator = ({ steps, currentStepIdx, lastStepDoneIdx = OnboardingStatus.Completed }: Props) => {
	return (
		<div className={Style}>
			{Array.from({ length: steps }).map((_, idx) => (
				<Indicator key={idx} isCurrent={idx === currentStepIdx} isDone={idx <= lastStepDoneIdx} />
			))}
		</div>
	);
};

export default StepIndicator;
