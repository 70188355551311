import React from "react";
import Flex from "../../../../../Shared/Components/Layout/Flex";
import { ChartStyle } from "./Chart.Style";
import { IEquityChartElement } from "../Equity";
import { formatNumber } from "../../../../../Shared/Utilities";
import Tooltip from "../../../../../Shared/Components/Tooltip";

interface IProps {
	readonly name: string;
	readonly totalAmount: number;
	readonly elements: IEquityChartElement[];
}

const Chart: React.FC<IProps> = (props: IProps) => {
	return (
		<Flex className={ChartStyle} align="start" justify="between" direction="column" position="relative">
			{props.name === "Total pool" && (
				<>
					<span className="complexConnector complexConnector__left" />
					<span className="complexConnector complexConnector__right" />
				</>
			)}

			<Flex align="start" flex={0} direction="column" className="infoContainer">
				<Flex justify="start" flex={0} gap={5}>
					{props.elements.map((element) => (
						<span key={element.label} className="infoContainer__color" style={{ background: `${element.color}` }} />
					))}
					<span className="infoContainer__name">{props.name}</span>
				</Flex>
				<span className="infoContainer__totalAmount">{formatNumber(props.totalAmount)}</span>
			</Flex>

			{props.elements.map((element) => {
				const elementWidth = (element.amount / props.totalAmount) * 100 > 3 ? (element.amount / props.totalAmount) * 100 : 3;

				return (
					<Flex key={element.label} align="start" flex={0} direction="column" className="chartsContainer">
						<Flex align="start" flex={0} direction="column" gap={5} height="4.5rem">
							<Flex justify="start" flex={0} margin="18px 0 0 0">
								<span className="chartLabel">{element.label}:</span>
								&nbsp;
								<span className="chartLabel">{formatNumber(element.amount)}</span>
								&nbsp;
								&nbsp;
								{
									element.tooltip && <Tooltip title={element.tooltip} />
								}

							</Flex>
							<Flex justify="start" flex={0} position="relative">
								<span
									className="chartsContainer__amount"
									style={{
										background: `${element.color}`,
										width: `${elementWidth}%`,
									}}
								/>
								{element.shouldConnectToNextElement && <span className="chartsContainer__nextElementConnector" />}
							</Flex>
						</Flex>
					</Flex>
				);
			})}
		</Flex>
	);
};

export default Chart;
