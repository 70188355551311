import styled from "@emotion/styled";
import { breakpoints } from "../../dependencies/breakpoints";
import appConfig from "../../../../../../config/config";

export const WIDGET_DASHBOARD_HEIGHT_XL = "25rem";
const WIDGET_DASHBOARD_PADDING_X = "3.8rem";
const WIDGET_DASHBOARD_PADDING_Y = "1.6rem";
export const WIDGET_DASHBOARD_ROW_GAP = "1.8rem";
// export const WIDGET_DASHBOARD_COL_GAP = "1.6rem"; // pick 1.6 (captable) or 2.4 (funding)
export const WIDGET_DASHBOARD_COL_GAP = "2.4rem"; // pick 1.6 (captable) or 2.4 (funding)

export const Wrapper = styled.div`
    label: WidgetDashboardWrapper;
    background-color: ${appConfig.style.colors.background1};
    width: 100%;
    // overflow: hidden; // Daniel: to display tooltip
    padding: ${WIDGET_DASHBOARD_PADDING_Y} ${WIDGET_DASHBOARD_PADDING_X};

    // @media (min-width: ${breakpoints.lg}) {
    // 	padding: ${WIDGET_DASHBOARD_PADDING_Y} ${WIDGET_DASHBOARD_PADDING_X};
    // }
`;

export const StyledWidgetDashboard = styled.div`
    label: WidgetDashboard;
    display: grid;
    column-gap: ${WIDGET_DASHBOARD_COL_GAP};
    row-gap: ${WIDGET_DASHBOARD_ROW_GAP};
    min-height: ${WIDGET_DASHBOARD_HEIGHT_XL};
`;
