import { css } from "@emotion/css";
import appConfig from "../../../config/config";
import classNames from "classnames";
import { IC_EDIT2 } from "../../../Assets";
import Clickable from "../../../Shared/Components/Clickable/Clickable";
import Image from "../../../Shared/Components/Image";

const Style = css({
	label: "Card",
	background: "#ffffff",
	borderRadius: 16,
	border: `1px solid ${appConfig.style.colors.table}`,
	boxShadow: "3px 3px 3px rgba(201, 202, 208, 0.40)",
	position: "relative",
	"&__edit-btn": {
		top: "1.6rem",
		right: "1.6rem",
	},
});

interface Props extends React.HTMLAttributes<HTMLDivElement> {
	onEdit?: () => void;
	children?: React.ReactChild | React.ReactChild[] | false | Element;
}

const Card = ({ onEdit, children, ...rst }: Props) => {
	return (
		<div {...rst} className={classNames(Style, { [rst.className || ""]: !!rst.className })}>
			{onEdit && (
				<Clickable qaid="Card.Button.Edit" className={`${Style}__edit-btn`} onClick={onEdit} width="fit-content" position="absolute">
					<Image width="2rem" src={IC_EDIT2} alt="edit" />
				</Clickable>
			)}
			{children}
		</div>
	);
};

export default Card;
