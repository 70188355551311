import { createRef } from "react";
import { FileDrop } from "react-file-drop";
import * as xlsx from "xlsx";
import { IC_UPLOAD } from "../../../../../Assets";
import Clickable from "../../../../../Shared/Components/Clickable/Clickable";
import Flex from "../../../../../Shared/Components/Layout/Flex";
import Grid from "../../../../../Shared/Components/Layout/Grid";
import { validExcelExtensions } from "../../../../../Shared/Config";
import { MultiStepElementProps } from "../../../../../Shared/Hooks/useMultiStepForm";
import useRootStore from "../../../../../Shared/Hooks/useRootStore";
import { getFileExtension } from "../../../../../Shared/Utilities";
import { ImportExcelStep } from "../helpers/types";

const FileImport = ({ goTo }: MultiStepElementProps) => {
    const { equityPlansStore } = useRootStore();
    const inputRef = createRef<HTMLInputElement>();

    const onFileSelect = (e: any) => {
        if (!e.target.files?.length) return;

        const file = e.target.files[0];

        const extension = getFileExtension(file.name);
        if (validExcelExtensions.indexOf(extension) === -1) return;

        const reader = new FileReader();
        reader.onload = (e: any) => {
            try {
                const data = e.target.result;
                const workbook = xlsx.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];

                const json = xlsx.utils.sheet_to_json<any>(worksheet, {
                    header: 1,
                    raw: false,
                });

                equityPlansStore.setImportData(json);
                goTo?.(ImportExcelStep.headlineSelection);
            } catch (error) {}
        };
        reader.readAsArrayBuffer(file);
    };

    return (
        <Flex
            direction="column"
            align="center"
            justify="start"
        >
            <div className="bold">Upload your file</div>
            <div className="text-color-error">Please make sure your file contains the following columns:</div>
            <Grid cols={3}>
                {equityPlansStore.importFields.map((field) => (
                    <div key={field.name}>{field.label}</div>
                ))}
            </Grid>

            <FileDrop
                onDrop={(files, event) => {
                    console.log(files);
                }}
            >
                <Flex direction="column">
                    <img
                        src={IC_UPLOAD}
                        alt=""
                    />
                    <span>Drag & Drop folders/files here</span>
                    <span>Or</span>
                    <input
                        hidden
                        type="file"
                        onChange={onFileSelect}
                        multiple={false}
                        ref={inputRef}
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                    <Clickable
                        qaid="FileImport.Button.BrowseFiles"
                        applyStyle
                        onClick={() => inputRef?.current?.click()}
                    >
                        Browse files
                    </Clickable>
                </Flex>
            </FileDrop>
        </Flex>
    );
};

export default FileImport;
