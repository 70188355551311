import { css } from "@emotion/css";
import { FallbackProps } from "react-error-boundary";
import Image from "../../Shared/Components/Image";
import { IC_ERROR_CIRCLE, IC_REFRESH } from "../../Assets";
import Button from "../../Shared/Components/Button/Button";
import Title from "../../Shared/Components/Layout/Title";
import * as stackTraceParser from "stacktrace-parser";
import appConfig from "../../config/config";

const Style = css({
    label: "ErrorPage",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "&__title": {
        margin: "1.6rem 0",
    },
    "&__error": {
        marginBottom: "4rem",
        display: "flex",
        flexDirection: "column",
        maxWidth: "75%",
        padding: "2rem",
        background: "#f8f8f8",
    },
});

const ErrorPage = ({ error, resetErrorBoundary }: FallbackProps) => {
    const parsedErrorsStack = stackTraceParser.parse(error.stack);
    const errorToDisplay = (
        <>
            <div className="bold">{error.toString()}</div>
            <br />
            {parsedErrorsStack.map((error, idx) => (
                <span key={idx}>
                    at {error.methodName} ({error.file}:{error.lineNumber}:{error.column})
                </span>
            ))}
        </>
    );

    return (
        <div className={Style}>
            <Image
                src={IC_ERROR_CIRCLE}
                alt="Error"
            />
            <Title
                danger
                className={`${Style}__title`}
            >
                Oops, something went wrong...
            </Title>
            <pre className={`${Style}__error`}>{errorToDisplay}</pre>
            <Button
                qaid="Error.Button.Reload"
                label="Reload page"
                position="center"
                onClick={() => {
                    resetErrorBoundary();
                }}
            />
        </div>
    );
};

export default ErrorPage;
