import styled from "@emotion/styled";
import { StyledWidget } from "../../../shared/components/Widget/Widget.style";
import { breakpoints } from "../../../shared/dependencies/breakpoints";
import appConfig from "../../../../../../config/config";

const WIDGET_BORDER = `1px solid ${appConfig.style.colors.table}`;
// const WIDGET_HEIGHT_XL = "7rem";
const WIDGET_HEIGHT_XL = "8.8rem";
const WIDGET_MAX_WIDTH_XL = "38.4rem";
const WIDGET_MIN_WIDTH = "auto";
const WIDGET_MIN_WIDTH_XL = "35rem";

// const WIDGET_PADDING_X = "3.5rem";
const WIDGET_PADDING_X = "2rem";
const WIDGET_PADDING_Y = "0.5rem";

const WIDGET_TEXT_COLOR = "#4D4D4D";

const ICON_SIZE = "6rem";
// const ICON_SIZE = "4rem";
const ICON_SPACING_RIGHT = "3.6rem";
// const ICON_SPACING_RIGHT = "2.4rem";
const LABEL_FONT_SIZE = "1.4rem";
const FIGURE_FONT_SIZE = "1.8rem";

export const StyledAltSingleFigureWidget = styled(StyledWidget)`
    label: AltSingleFigureWidget;
    display: flex;
    align-items: center;
    border: ${WIDGET_BORDER};
    color: ${WIDGET_TEXT_COLOR};
    padding: ${WIDGET_PADDING_Y} 1rem;
    min-height: ${WIDGET_HEIGHT_XL};
    min-width: ${WIDGET_MIN_WIDTH};
    max-width: ${WIDGET_MAX_WIDTH_XL};

    @media (min-width: ${breakpoints.lg}) {
        min-width: ${WIDGET_MIN_WIDTH_XL};
        padding: ${WIDGET_PADDING_Y} ${WIDGET_PADDING_X};
    }
`;

export const Icon = styled.img`
    width: ${ICON_SIZE};
    justify-content: center;
    display: flex;
    align-items: center;
    margin-right: ${ICON_SPACING_RIGHT};
`;

export const Content = styled.div`
    flex-grow: 1;
`;

export const Label = styled.span`
    font-size: ${LABEL_FONT_SIZE};
    font-weight: 700;
    white-space: nowrap;
`;

export const Figure = styled.div`
    font-size: ${FIGURE_FONT_SIZE};
`;
