import { observer } from "mobx-react-lite";
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import IAutoCompleteUser, { IAutoCompleteRequest } from "../../Models/App/AutoCompleteUser";
import useRootStore from "../Hooks/useRootStore";
import AsyncAutoCompleteInput from "./Input/AsyncAutoCompleteInput";
import { IContact } from "../../Models/API/Contact/contact";
import { isNullOrUndefined } from "../Utilities";
import { AsyncAutoCompleteProps, InputRef } from "../../Models/App/Inputs/types";

interface IProps<T> extends Omit<AsyncAutoCompleteProps<T>, "qaid"> {
    readonly hasError?: boolean;
    readonly error?: string;
    readonly placeholder?: string;
    readonly disabled?: boolean;
    readonly searchBy: keyof IAutoCompleteRequest;
    readonly asRef?: React.MutableRefObject<any>;
    readonly dataRoomID?: number;
    readonly projectID?: number;
    readonly label?: string;
    readonly required?: boolean;
    readonly onSuggestionSelected?: (item: IContact) => void;
    readonly name?: string;
    treshhold?: number;
}

const CompanyUserAutoComplete = forwardRef<InputRef, IProps<any>>(({ onSuggestionSelected, ...props }, forwardedRef) => {
    const rootStore = useRootStore();
    const inputRef = useRef<InputRef>(null);

    useImperativeHandle(forwardedRef, () => ({
        resetError() {
            inputRef.current?.resetError();
        },
        showError() {
            inputRef.current?.showError();
        },
        focus() {
            inputRef.current?.focus();
        },
        clear() {
            inputRef.current?.clear();
        },
        getName() {
            return props.name;
        },
    }));

    const filterContacts = (data: IContact[] = [], inputType: keyof IAutoCompleteRequest, value: string = "") => {
        switch (inputType) {
            case "firstName":
                return data?.filter((contact) => contact.firstName?.toLowerCase().includes(value?.toLowerCase()));
            case "lastName":
                return data?.filter((contact) => contact.lastName?.toLowerCase().includes(value?.toLowerCase()));
            case "email":
                return data?.filter((contact) => contact.email?.toLowerCase().includes(value?.toLowerCase()));
            case "legalEntityCompanyName":
                const companies = Array.from(new Set(data.map((c) => c.companyName))).filter((companyName) => companyName);

                return companies.map((companyName) => data.find((contact) => contact.companyName === companyName)) as IContact[];
            case "employeeNumber":
                return data?.filter((contact) => contact?.contactDetails?.employeeNumber?.toLowerCase().includes(value?.toLowerCase()));

            default:
                return rootStore.contactStore.companyContacts;
        }
    };

    const loadContacts = async (value: string = ""): Promise<IContact[] | undefined> => {
        if (isNullOrUndefined(value)) {
            return undefined;
        }

        if (value.length >= (props.treshhold || 2) && !isNullOrUndefined(props.searchBy)) {
            const data = await rootStore.contactStore.getCompanyContacts();
            return new Promise((res) => res(filterContacts(data, props.searchBy!, value) || []));
        }

        return undefined;
    };
    useEffect(() => {
        loadContacts(props.value?.toString());
    }, [props.value]);

    return (
        <AsyncAutoCompleteInput
            {...props}
            qaid={`CompanyUserAutoComplete.Input.${props.searchBy[0].toUpperCase() + props.searchBy.slice(1)}`}
            value={props.value?.toString()}
            loadItems={loadContacts}
            itemKeyLabel={
                props.searchBy === "legalEntityCompanyName"
                    ? [["companyName"], ["firstName", "lastName"]]
                    : [["firstName", "lastName"], ["email"]]
            }
            onSelect={onSuggestionSelected}
            ref={inputRef}
        />
    );
});

export default observer(CompanyUserAutoComplete);
