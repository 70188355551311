import Card from "../../../../../Shared/Components/Layout/Card";
import Flex from "../../../../../Shared/Components/Layout/Flex";
import { MultiStepElementProps } from "../../../../../Shared/Hooks/useMultiStepForm";
import useRootStore from "../../../../../Shared/Hooks/useRootStore";
import appConfig from "../../../../../config/config";
import { ImportExcelStep, InfoTypeEnum, InfoTypes } from "../helpers/types";
import { css } from "@emotion/css";

const SelectionStyle = css({
	label: "SelectionType",
	paddingTop: 50,
	paddingBottom: 50,
	minWidth: 150,
	textAlign: "center",
	fontWeight: "bold",
	fontSize: 12,
	"&:hover": {
		cursor: "pointer",
		transform: "scale(1.025)",
		transition: "all .2s",
		boxShadow: `${appConfig.style.boxShadow.light} !important`,
	},
});

const TypeSelection = ({ goTo }: MultiStepElementProps) => {
	const { equityPlansStore } = useRootStore();

	const keys = Object.keys(InfoTypeEnum);
	const types: InfoTypes[] = keys.splice(keys.length / 2) as InfoTypes[];

	const onTypeSelection = (type: InfoTypes) => {
		equityPlansStore.setImportType(type);
		goTo?.(ImportExcelStep.fileImport);
	};

	return (
		<Flex direction="column" align="center" justify="start">
			<div className="bold">What type of information would you like to upload?</div>
			<div>The selected type will set the required values we'll put from your file. Don't worry your data is just fine.</div>
			<Flex gap={20} flex={0} className="mt-5">
				{types.map((type) => (
					<Card key={type} className={SelectionStyle} onClick={() => onTypeSelection(type)}>
						{type}
					</Card>
				))}
			</Flex>
		</Flex>
	);
};

export default TypeSelection;
