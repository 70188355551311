import styled from "@emotion/styled";
import classNames from "classnames";
import appConfig from "../../../config/config";
import { FontFamilies } from "./Text";

export interface IProps extends Omit<React.HTMLAttributes<HTMLElement>, "align" | "children"> {
	top?: number | string;
	left?: number | string;
	right?: number | string;
	bottom?: number | string;
	fontSize?: number;
	fontWeight?: number | string;
	family?: FontFamilies;
	width?: number | string;
}

// @ts-ignore
const ErrorMessage = styled.span(
	{
		position: "absolute",
		color: appConfig.style.colors.danger,
		label: "ErrorMessage",
		height: "fit-content",
	},
	({ top = 0, left = 0, right = 0, bottom = 0, family = "assistant", fontSize = 14, width = "fit-content" }: IProps) => ({
		top: top,
		left: left,
		right: right,
		bottom: bottom,
		// @ts-ignore
		fontFamily: family.split(".").reduce((o, i: string) => o[i], appConfig.style.fonts),
		fontSize: fontSize / 10 + "rem",
		width: width,
	})
);

export default ErrorMessage;
