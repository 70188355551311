import styled from "@emotion/styled";
export interface IFlexProps extends Omit<React.HTMLAttributes<HTMLElement>, "align" | "children"> {
	direction?: "row" | "column";
	align?: "start" | "center" | "end";
	justify?: "start" | "center" | "end" | "between" | "evenly" | "around";
	placeContent?: "start" | "center" | "end" | "between" | "evenly" | "around";
	margin?: number | string | "inherit";
	padding?: number | string | "inherit";
	gap?: number | string;
	wrap?: "nowrap" | "wrap" | "wrap-reverse" | "inherit";
	textWrap?: "nowrap" | "wrap" | "wrap-reverse" | "inherit";
	basis?: number | string | "inherit" | "content";
	children?: any | any[];
	width?: number | string;
	height?: number | string;
	flex?: number | string;
	position?: "absolute" | "relative" | "fixed" | "sticky" | "static";
	textAlign?: "start" | "center" | "end" | "inherit";
	maxWidth?: number | string;
}
const Flex = styled.div(
	{
		label: "FlexBox",
		display: "flex",
	},
	({
		direction = "row",
		align = "center",
		justify = "center",
		wrap = "inherit",
		padding = 0,
		margin = "none",
		basis = "inherit",
		width = "100%",
		height = "inherit",
		flex = 1,
		gap = 0,
		position = "static",
		placeContent,
		textWrap = "inherit",
		textAlign = "inherit",
		maxWidth = "100%",
	}: IFlexProps) => ({
		flexDirection: direction,
		alignItems: align === "center" ? align : `flex-${align}`,
		justifyContent: justify === "center" ? justify : justify === "end" || justify === "start" ? `flex-${justify}` : `space-${justify}`,
		placeContent:
			placeContent === "center" ? placeContent : placeContent === "end" || placeContent === "start" ? `flex-${placeContent}` : `space-${placeContent}`,
		flexWrap: wrap,
		padding,
		width,
		height,
		margin,
		gap,
		flexBasis: basis,
		flex,
		position,
		textWrap,
		textAlign,
		maxWidth,
	})
);
export default Flex;
