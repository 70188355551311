import { css } from "@emotion/css";
import appConfig from "../../../../../config/config";
import { mq } from "../../../../../config/style/lib/breakpoints";

const gridOptions = {
    display: "grid",
    gridColumnGap: "2.4rem",
    gridRowGap: "4.4rem",
    gridTemplateColumns: "auto",
    "@media screen and (min-width: 600px)": {
        gridTemplateColumns: "repeat(3, 1fr)",
    },
};

export const AddEditShareClassStyle = css({
    label: "AddEditShareClass",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    flex: 1,
    "&:not(.import)": {
        padding: "7rem 8rem 4rem",
    },
    "@media screen and (max-width: 768px)": {
        padding: "8rem 4rem 4rem",
    },

    "&__form": {
        label: "Form",
        width: "100%",
        borderBottom: `1px solid ${appConfig.style.colors.table}`,
        padding: "0 0 4.4rem 0",
        marginBottom: "2rem",
        ...gridOptions,
    },
    "&__checkbox-container": {
        margin: "2rem 0",
        ...gridOptions,
        "&:last-child": {},
    },
    "&__upload-files-container": {
        borderTop: `1px solid ${appConfig.style.colors.table}`,
        borderBottom: `1px solid ${appConfig.style.colors.table}`,
        padding: "1.6rem 0",
    },
    "&__upload-section": {
        display: "flex",
        paddingTop: "1.2rem",
    },
    "&__uploaded-files": {
        padding: "0 1.6rem",
        display: "flex",
        flexDirection: "column",
        flex: 1,
        overflow: "auto",
        maxHeight: "15rem",
    },
    "&__actions": {
        "&.import": {
            borderTop: `1px solid ${appConfig.style.colors.table}`,
            paddingTop: "2rem",
        },
        display: "flex",
        justifyContent: "flex-end",
    },
});
