import { css } from "@emotion/css";
import appConfig from "../../../config/config";

const ProgressBarStyle = css({
    label: "ProgressBar",
    background: "#ffffff",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    padding: "1.6rem 4rem",
    boxShadow: `3px 3px 3px 0px ${appConfig.style.colors.boxShadowColor1}`,
    "&__header": {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
        ".buttons": {
            display: "flex",
            gap: 20,
        },
    },
    "&__header-actions": {
        display: "flex",
        alignItems: "center",
        gap: "1.2rem",
        marginLeft: "auto",
    },
    "&__steps-container": {
        paddingTop: "1rem",
        display: "flex",
        alignItems: "start",
        maxWidth: "108rem",
        "&.lg": {
            maxWidth: "120rem",
        },
        width: "100%",
        marginInline: "auto",
        overflow: "hidden",
        gap: "1rem",
    },
    ".toggle-header": {
        background: appConfig.style.colors.gradientPurpleVertical,
        position: "absolute",
        width: 40,
        height: 20,
        top: "calc(100% + 2px)",
        margin: "auto",
        left: 0,
        right: 0,
        borderBottomLeftRadius: 50,
        borderBottomRightRadius: 50,
        boxShadow: appConfig.style.boxShadow.card1,
        display: "center",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        zIndex: 999,
        ".arrow": {
            border: "solid white",
            borderWidth: "0 2px 2px 0",
            display: "inline-block",
            padding: 3,
            fontSize: 12,
        },
        ".down": {
            transform: "rotate(45deg)",
            marginBottom: 5,
        },
        ".up": {
            transform: "rotate(-135deg)",
        },
    },
});

export default ProgressBarStyle;
