import { css } from "@emotion/css";

export const OverflowTextStyle = css({
	label: "OverflowText",
	maxWidth: "100%",
	width: "100%",

	"&__text": {
		whiteSpace: "nowrap",
		width: "fit-content",
		maxWidth: "100%",
		textOverflow: "ellipsis",
		overflow: "hidden",
	},
});
