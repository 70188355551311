export function appDate(date: Date) {
	const _date = date;
	const getDatePart = () => {
		return new Date(_date.getFullYear(), _date.getMonth(), _date.getDate(), 0, 0, 0);
	};

	return {
		getDatePart,
	};
}
