import classNames from "classnames";
import { IMG_LOGO_NAME } from "../../Assets";
import AccountLogin from "./Components/AccountForms/AccountLogin";
import useScreenSize from "../../Shared/Hooks/useScreenSize";
import { Link, useHistory } from "react-router-dom";
import { useState } from "react";
import { Routes } from "../../Routes";

export const AccountLoginScreen = () => {
	const { isMobile, isTablet } = useScreenSize();
	const history = useHistory();

	const [isPageVisible, setIsPageVisible] = useState<boolean>(true);

	const navToAccountRegistration = () => {
		setIsPageVisible(false);

		setTimeout(() => {
			history.push(Routes.account.accountRegistration);
		}, 500);
	};

	return (
		<section
			className={classNames("account-bg-login-page", "account-pages", "login", {
				mobile: isMobile,
			})}
		>
			<Link
				to={Routes.account.login}
				className={classNames("alt-logo", {
					tablet: isTablet,
					mobile: isMobile,
				})}
			>
				<img src={IMG_LOGO_NAME} alt="brand logo" />
			</Link>

			<div
				className={classNames({
					"form-login-container": true,
					mobile: isMobile,
					tablet: isTablet,
				})}
			>
				<AccountLogin isPageVisible={isPageVisible} navToAccountRegistration={navToAccountRegistration} />
			</div>
		</section>
	);
};

export default AccountLoginScreen;
