import { FC, HTMLProps, ReactNode } from "react";
import { Alignment, Color } from "../../../config/types/style.types";
import { Font, Spacing, TextSize } from "../../../config/types/theme.types";
import { AltProps } from "../WidgetDashboard/shared/dependencies/types/types";
import { StyledHeading } from "./Heading.style";

interface Props {
	tag: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
	align?: Alignment;
	color?: Color;
	font?: Font;
	isBold?: boolean;
	isUpperCase?: boolean;
	size?: TextSize;
	spacingBottom?: Spacing;
}
export interface HeadingProps extends AltProps<Props>, HTMLProps<HTMLHeadingElement> {
	children: ReactNode;
}

const Heading: FC<HeadingProps> = (props: HeadingProps) => {
	return (
		<StyledHeading {...props} as={props.ap.tag}>
			{props.children}
		</StyledHeading>
	);
};

export default Heading;
