import { IMG_LOGO_NAME } from "../../Assets";
import AccountCompanyProfile from "./Components/AccountForms/AccountCompanyProfile";
import classNames from "classnames";
import useScreenSize from "../../Shared/Hooks/useScreenSize";

export const AccountCompanyProfileScreen = () => {
	const { isMobile, isTablet } = useScreenSize();

	return (
		<section
			className={classNames({
				"account-bg-setup-page": true,
				"account-pages": true,
				mobile: isMobile,
			})}
		>
			<div
				className={classNames({
					"account-reg-container": true,
					mobile: isMobile,
				})}
			>
				<div
					className={classNames({
						"account-page-content": true,
						mobile: isMobile,
						fadeIn: true && !isMobile,
					})}
				>
					<div
						className={classNames("alt-logo", {
							hidden: isTablet,
						})}
					>
						<img src={IMG_LOGO_NAME} alt="brand logo" />
					</div>
					<div
						className={classNames({
							"title-join": true,
							hidden: isTablet,
						})}
					>
						<div>The Way Founders </div>
						<div>Do Equity</div>
					</div>
				</div>

				<AccountCompanyProfile />
			</div>
		</section>
	);
};

export default AccountCompanyProfileScreen;
