import { css } from "@emotion/css";
import appConfig from "../../../../config/config";

const GrantCardStyles = css({
    label: "GrantCard",
    display: "flex",
    flexDirection: "column",
    // "flex": 1,
    // "boxShadow": appConfig.style.boxShadow.card1,
    padding: "7rem 8rem 4rem",

    "&__drawer-header": {
        display: "flex",
        gap: "0.8rem",
        ".header-comment": {
            color: appConfig.style.colors.text4,
        },
    },

    ".column": {
        display: "flex",
        flexDirection: "row",
        // width: "26rem",
        margin: "14px",
        // background: "red",
    },

    ".tableContainer": {
        padding: "2rem",
    },

    //   borderRadius: 4,
    //   position: "relative",
    //   marginBottom: 10,
    //   ".step-card": {
    //     maxWidth: 930,
    //     margin: "auto",
    //     padding: "2.7rem 3.2rem",
    //     width: "calc(100% - 136px)",
    //     "&__title": {
    //       fontSize: 16,
    //       fontFamily: appConfig.style.fonts.assistant.bold,
    //       marginBottom: 20,
    //     },
    //   },
    "&__docs": {
        marginTop: "4rem",
        marginBottom: "4rem",
        ".info": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: `1px solid ${appConfig.style.colors.table}`,
            padding: "1.4rem 0",
            ".menu": {
                "img[data-type='menu-img']": {
                    transform: "rotate(90deg) scale(0.9)",
                },
            },
            ".title": {},
            ".menu-container": {
                display: "flex",
                alignItems: "center",
            },
        },
        ".add-grant-letter": {
            paddingTop: "1.4rem",
            display: "flex",
            gap: "2.4rem",
        },
    },
});

export default GrantCardStyles;
