import { useRef } from 'react';
import { ServerResponseApi } from '../../../../Models/API/All/server-response';
import { DocumentTemplateData } from '../../../../Models/API/Document/template-data';
import { DocumentTemplateInfo } from '../../../../Models/API/Document/template-info';
import { useFetchApi } from '../../../../Shared/Hooks/useFetchApi';
import useRootStore from '../../../../Shared/Hooks/useRootStore';
import { InfoProps } from '../../Components/Preview';
import { IC_PREVIEW_LAST_UPDATE, IC_PREVIEW_RULE, IC_PREVIEW_SIGNATURE, IC_PREVIEW_TYPE, IC_PREVIEW_USER } from '../../../../Assets';
import { formatDate, isNumber, isString } from '../../../../Shared/Utilities';
import { FileExtensionEnum, TemplateTypeEnum, templateTypeOptions } from '../../../../Models/API/Document/template-type-enum';
import { signinRuleOptions } from '../../../../Models/API/Document/signin-rule-enum';
import useGeneralModal from '../../../../Shared/Hooks/useGeneralModal';

const useTemplate = () => {
	const { documentsStore, contactStore, appState } = useRootStore();
	const { run, data, loading, clearData } = useFetchApi<ServerResponseApi<DocumentTemplateData>>();
	const templateRef = useRef<DocumentTemplateInfo>();
	const { showDocumentModal } = useGeneralModal();

	const getTemplate = (templateId: string, updateState: boolean = true, isPreview: boolean = false) => {
		templateRef.current = documentsStore.templates?.find((t) => t.templateId === templateId);
		return run(() => documentsStore.getTemplate(templateId, isPreview), {
			cacheKey: new Date().toISOString() || (isPreview ? `preview-${templateId}` : templateId),
			updateState: true,
		});
	};

	const getDefaultTemplate = (documentType: TemplateTypeEnum, fileName: string, fileExtension: FileExtensionEnum) => {
		return run(() => documentsStore.getDefaultTemplate(documentType, fileExtension), {
			cacheKey: new Date().toISOString() || fileName,
			updateState: true,
		});
	};

	const getTypeName = (value: number) => {
		const option = templateTypeOptions.find((opt) => opt.value === value);
		if (option && isString(option?.label)) return option.label;
		return '-';
	};

	const getRuleName = (value: number) => {
		const option = signinRuleOptions.find((opt) => opt.value === value);
		if (option && isString(option?.label)) return option.label;
		return '-';
	};

	const getPreviewInfo = (): InfoProps => {
		if (!templateRef.current) return {} as InfoProps;

		const getSignerName = (contactId: number | null) => {
			if (isNumber(contactId)) {
				return contactStore.getContactFullNameById(contactId);
			}
			return 'Associated stakeholder';
		};

		return {
			info: [
				{
					icon: IC_PREVIEW_USER,
					title: 'Updated By',
					text: [contactStore.getContactFullNameById(templateRef.current.lastUpdateByContactId)],
				},
				{
					icon: IC_PREVIEW_LAST_UPDATE,
					title: 'Last update',
					text: [formatDate(templateRef.current.lastTimeEdited) ?? '-'],
				},
				{
					icon: IC_PREVIEW_TYPE,
					title: 'Type',
					text: [getTypeName(templateRef.current.documentType)],
				},
				{
					icon: IC_PREVIEW_RULE,
					title: 'Signing Rule',
					text: [getRuleName(templateRef.current.signinRule)],
				},
				{
					icon: IC_PREVIEW_SIGNATURE,
					title: 'Signatories',
					text: templateRef.current.signers.map((s) => getSignerName(s.contactId)),
				},
			],
			isPreview: true,
		};
	};

	const previewTemplate = async (id: string, fileName: string) => {
		appState.isLoading = true;
		const res = await getTemplate(id, true, true);
		appState.isLoading = false;
		if (!res.data) return;
		showDocumentModal({ ...res.data.data, fileName }, getPreviewInfo(), clearData);
	};

	const previewDefaultTemplate = async (documentType: TemplateTypeEnum, fileName: string) => {
		appState.isLoading = true;
		const res = await getDefaultTemplate(documentType, fileName, FileExtensionEnum.pdf);
		appState.isLoading = false;
		const info = {
			info: [
				{
					icon: IC_PREVIEW_USER,
					title: 'Updated By',
					text: ['-'],
				},
				{
					icon: IC_PREVIEW_LAST_UPDATE,
					title: 'Last update',
					text: ['-'],
				},
				{
					icon: IC_PREVIEW_TYPE,
					title: 'Type',
					text: ['-'],
				},
				{
					icon: IC_PREVIEW_RULE,
					title: 'Signing Rule',
					text: ['-'],
				},
				{
					icon: IC_PREVIEW_SIGNATURE,
					title: 'Signatories',
					text: ['-'],
				},
			],
			isPreview: true,
		};
		if (!res.data) return;
		showDocumentModal({ ...res.data.data, fileName }, info, clearData);
	};

	return {
		getTemplate,
		data,
		loading,
		clearData,
		getPreviewInfo,
		previewTemplate,
		getDefaultTemplate,
		previewDefaultTemplate,
	};
};

export default useTemplate;
