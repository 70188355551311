import { useEffect, useMemo } from "react";
import { IC_WATERFALL_SUMMARY } from "../../../../../../../Assets";
import Image from "../../../../../../../Shared/Components/Image";
import { TableColumn } from "../../../../../../../Shared/Components/Table/Table";
import { formatDecimal, isNumber } from "../../../../../../../Shared/Utilities";
import appConfig from "../../../../../../../config/config";
import { extractDataForPayoffBarChart, getDestrcturedValuationData, parseNumericValue } from "./helpers";
import { IEquityHolderParsed, IWaterfallSummaryDataParsed } from "./types";


type Props = {
    isShareclass: boolean,
    data: IWaterfallSummaryDataParsed | null,
    exitValue: number
}

const useValuationData = ({ isShareclass, data, exitValue }: Props) => {

    const exitValues = useMemo(() => {
        return data?.shareClasses.shareClassValuation?.[0].shareClassesSummaryCalculations.map(
            (summary) => ({
                label: summary.exitValue.toString(),
                value: summary.exitValue
            })
        ) || [];
    }, [data]);

    const valuationData = useMemo(() => {
        return getDestrcturedValuationData(data, exitValue);
    }, [data, exitValue]);

    const payoffChartData = {
        data: extractDataForPayoffBarChart(isShareclass ? valuationData?.shareClasses : valuationData?.shareHolders,
            exitValue
        ),
        title: `${isShareclass ? "Share Classes" : "Shareholders"} Payoff at Exit Value`,
        xAxisLabel: isShareclass ? "Share Classes" : "Shareholders",
        yAxisLabel: `Payoff ($M)`
    };

    const sharedTableData: TableColumn<IEquityHolderParsed>[] = [
        {
            name: "numberOfShares",
            label: "Number of Shares Fully Diluted",
            format(val: number) {
                return formatDecimal(val);
            },
        },
        {
            name: "percentageOfShares",
            label: "% of Shares Fully Dilituted",
            format(val: number) {
                return `${parseNumericValue(val)}%`;
            },
        },
        {
            name: "originalInvestment",
            label: "Original Investment ($M)",
            format(val: number) {
                return formatDecimal(val, { decimalLength: 4 });
            },
        },
        {
            name: "totalShareClassValuation",
            label: "Payoff ($M)",
            format(val: number) {
                return val ? parseNumericValue(val) + "" : "--";
            },
        },
        {
            name: "investmentMultiple",
            label: "Investment Multiple",
            render(obj, value) {
                if (value === null || !isNumber(value)) return "--";
                return value >= 1 ? (
                    <span>{formatDecimal(value)}x</span>
                ) : (
                    <span style={{ color: appConfig.style.colors.orange }}>{formatDecimal(value)}x</span>
                );
            },
            style: { flex: 0.5 }
        },
        {
            name: "actions",
            label: "Summary",
            className: "valuation-actions",
            render(obj, value) {
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="35" viewBox="0 0 38 35" fill="none">
                        <g filter="url(#filter0_d_26_55)">
                            <rect x="1.22021" y="1" width="33" height="31" rx="4" fill="#F9F9FF" shapeRendering="crispEdges" />
                            <path d="M25.5176 24.442C21.403 24.4419 12.1983 24.4598 8.47266 24.442V10.0029" stroke="#979DB5" strokeWidth="1.4" strokeMiterlimit="10" strokeLinecap="round" />
                            <path d="M11.1525 21.4436L17.6817 14.6745C17.8702 14.4791 18.1802 14.4698 18.3801 14.6537L19.8444 16.0009C20.0579 16.1973 20.3931 16.1715 20.5739 15.9447L24.7826 10.6664" stroke="#979DB5" strokeWidth="1.4" strokeMiterlimit="10" strokeLinecap="round" />
                            <path d="M21.015 10.0855L24.9793 10.0692C25.2543 10.0681 25.4783 10.2888 25.481 10.5634L25.5176 14.3167" stroke="#979DB5" strokeWidth="1.4" strokeLinecap="round" />
                        </g>
                        <defs>
                            <filter id="filter0_d_26_55" x="0.220215" y="0" width="37" height="35" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dx="1" dy="1" />
                                <feGaussianBlur stdDeviation="1" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0.788235 0 0 0 0 0.792157 0 0 0 0 0.815686 0 0 0 0.4 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_55" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_55" result="shape" />
                            </filter>
                        </defs>
                    </svg>

                )
            },
            style: { flex: 0.5, justifyContent: 'center' }
        },
    ];

    const shareClassValuationTable: TableColumn<IEquityHolderParsed>[] = [
        {
            name: "seniorityLevel",
            label: "Seniority Level",
        },
        {
            name: "name",
            label: "Share class",
        },
        ...sharedTableData,
    ];

    const shareHolderValuationTable: TableColumn<IEquityHolderParsed>[] = [
        {
            name: "name",
            label: "Shareholder",
        },
        ...sharedTableData,
    ];

    return {
        tableColumns: isShareclass ? shareClassValuationTable : shareHolderValuationTable,
        tableData: isShareclass ? valuationData?.shareClassesTableData : valuationData?.shareHoldersTableData,
        payoffChartData,
        valuationData,
        exitValues
    }
}

export default useValuationData;