import { css } from "@emotion/css";

export const VestingStepStyle = css({
	label: "VestingStep",

	".innerContainer": {
		justifyContent: "space-between",
		borderRadius: 8,
		border: "1px solid #BAC0D9",
		padding: "2rem 3rem",
		width: "100%",
		height: "fit-content",
		marginBottom: "1rem",

		"&.error": {
			border: "1px solid red !important",
		},
	},

	".inputContainer": {
		position: "relative",
		flex: "0.7",
		maxWidth: "60%",
		display: "flex",
		gap: "2.4rem",
		alignItems: "center",
		justifyContent: "start",
		height: "100%",
		margin: 0,

		"&__input": {
			flex: "1",
		},

		"&__multiSelect": {
			flex: 2,
		},
	},

	".actionsContainer": {
		position: "relative",
		flex: "unset",
		width: "fit-content",
		gap: "8px",
		margin: 0,

		".editButtonsContainer": {
			position: "absolute",
			flex: "unset",
			flexDirection: "column",
			top: "-2rem",
			right: "0",

			"&__button": {
				color: "#8B96E9",
			},
		},
	},
});
