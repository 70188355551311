export interface IWaterfallSummaryData {
    businessValue: number;
    waterfallName: string;
    shareClasses: {
        shareClassValuation: IEquityHolder[];
    };
    shareHolders: {
        shareHolderValuation: IEquityHolder[];
    };
    totalShareClasses: ITotalShareClasses;
    simulation?: IWaterfallSimulation | null;
}

export interface IWaterfallSummaryDataParsed extends Omit<IWaterfallSummaryData, "shareClasses" | "shareHolders"> {
    shareClasses: {
        shareClassValuation: IEquityHolderParsed[];
    };
    shareHolders: {
        shareHolderValuation: IEquityHolderParsed[];
    };
}

export interface IPayoffBreakdown {
    exitValue: number;
    id: number;
    name: string;
    payoffByClass: {
        name: string;
        totalShareValue: number;
        investmentMultiple: number | null;
    }[];
}

export interface IEquityHolder {
    breakPoints: IBreakPoint[];
    id: number;
    investmentMultiple: number | null;
    isoption: boolean;
    issuePricePerShare: number;
    name: string;
    originalInvestment: number;
    payoffBreakdown?: IPayoffBreakdown[];
    preferenceSummary: string;
    seniorityLevel: number;
    shareClassesSummaryCalculations: IShareClassesSummaryCalculation[];
    shareClassHolders: IEquityHolderShort[];
    shares: number;
    totalShareClassOutOfClassesTotal_CommonShareEquivalentPercentage: number;
    totalShareClassValuation: number | null;
}
export interface IEquityHolderParsed
    extends Omit<IEquityHolder, "totalShareClassOutOfClassesTotal_CommonShareEquivalentPercentage" | "shares" | "isoption"> {
    numberOfShares: number;
    percentageOfShares: number;
    isOption: boolean;
    id: number;
}

export interface ITotalShareClasses {
    commonShareEqivalent: number;
    investment: number;
    numberOfSharesOutstanding: number;
    numberOfWarrants: number;
    optionPlansOutstandingOptions: number;
    shares_Warrants_Options_FullyDiluted: number;
    commonShareEquivalentNoWarrants: number;
    shareTotalSummaryCalculations: IShareClassesSummaryCalculation[];
}

export interface IShareClassesSummaryCalculation {
    isOptions: boolean;
    shareClassID: number;
    userId?: number;
    shares: number;
    iRR?: string | null;
    irrValue?: number | null;
    exitValue: number;
    totalShareValue: number | null;
    investmentMultiple: number | null;
}

export interface IBreakPoint {
    rangeFrom: number;
    rangeTo: number;
    commonShareComponent: number | null;
    currentPayoff: number;
    isBinaryOption: boolean;
    isLPPart: boolean;
    joinedLPAndCommonComponent: number | null;
    liquidationPreferenceComponent: number | null;
    proRataValue: number | null;
    shareClassID: number;
    waterfallDescription: string | null;
    percent: string;
    isEndOfRange: boolean;
}

// client side types:
export enum EquityHolderType {
    ShareHolders = "Shareholders",
    ShareClasses = "Share Classes",
}

export interface IWaterfallSummaryPayload {
    waterfallId: number;
    companyId: number;
    simulation?: IWaterfallSimulation | null;
}

export interface IWaterfallSimulation {
    exitValue?: number;
    from: number;
    to: number;
    every: number;
}

export interface IEquityHolderShort {
    id: number;
    name: string;
    shares: number;
    value: number;
}

export interface ChartProps {
    title?: string;
    xAxisLabel: string;
    yAxisLabel: string;
}

export interface BarChartProps extends ChartProps {
    data: {
        labels: string[];
        tooltips: string[];
        figures: number[];
    };
}

export type ScatterChartPoint = {
    x: number;
    y: number;
    hide?: boolean;
};

export interface ScatterChartProps extends ChartProps {
    data: ScatterChartPoint[];
    exitValuePoint: ScatterChartPoint;
}

export interface IDestructuredWaterfallSummaryData {
    shareClasses: IEquityHolderParsed[];
    shareHolders: IEquityHolderParsed[];
    shareClassesTableData: IEquityHolderParsed[];
    shareHoldersTableData: IEquityHolderParsed[];
}

export type IShareHolder = IEquityHolder;
export type IShareClass = IEquityHolder;

export type SummaryStatus = {
    timeStamp: number;
    status: SummaryStatusCode;
    waterfallId: number;
    estimatedTimeDTO: SummaryEstimatedTime;
};

export type SummaryEstimatedTime = {
    estimatedTime: number;
    extraTime: number;
};

export enum SummaryStatusCode {
    PENDING = 1,
    RUNNING = 2,
    FINISHED = 3,
    ERROR = 4,
}
