import { css } from "@emotion/css";
import appConfig from "../../../../config/config";

const Step = css({
    label: "Step",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    cursor: "pointer",
    userSelect: "none",
    position: "relative",
    flex: 1,
    ".number-circle": {
        background: "white",
        width: "4rem",
        height: "4rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "center",
        position: "relative",
        ".number": {
            zIndex: 2,
            width: "4rem",
            height: "4rem",
            // background: "#77787B70",
            borderRadius: "50%",
            color: appConfig.style.colors.text3,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: 700,
            transition: "all .1s",
            border: `0.4rem solid ${appConfig.style.colors.text3}`,
        },
    },
    ".caption": {
        marginTop: 10,
        textAlign: "center",
        color: appConfig.style.colors.text3,
        lineHeight: 1.15,
        width: "100%",
    },
    "&.active": {
        ".number": {
            border: `0.4rem solid ${appConfig.style.colors.color1}`,
            color: appConfig.style.colors.color1,
            position: "relative",
            // border: "2px solid white",
            // outline: `5px solid ${appConfig.style.colors.color1}`,
            "&:before": {
                content: '""',
                position: "absolute",
                top: -30,
                borderBottomLeftRadius: "79%",
                width: 0,
                height: 0,
                // borderLeft: "8px solid transparent",
                // borderRight: "8px solid transparent",
                // borderTop: `14px solid ${appConfig.style.colors.color1}`,
            },
        },
        ".caption": {
            fontWeight: 700,
            color: appConfig.style.colors.color1,
        },
    },
    "&.active.completed": {
        ".number": {
            color: "#ffffff",
        },
    },
    "&:hover": {
        ".number": {
            background: "#ffffff",
            transform: "scale(1.2)",
            transition: "transform .3s",
        },
    },
    "&:not(.active):hover": {
        ".number": {
            // outline: "5px solid white",
        },
    },
    "&.completed": {
        ".number": {
            background: "linear-gradient(0deg, #b6bfff, #8B96E9),linear-gradient(249.83deg, #8B96E9 1.6%, #BCC2F1 98.11%)",
            border: `0.4rem solid ${appConfig.style.colors.color1}`,
            transition: "all .3s",
        },
        "&:not(:last-child)": {
            ".number-circle": {
                "&:after": {
                    background: appConfig.style.colors.color1,
                    transition: "all .3s",
                },
            },
        },
        "&.highlight": {
            "&:nth-last-child(-n+2)": {
                ".number-circle": {
                    "&:after": {
                        background: `linear-gradient(90deg, #8B96E9 0.5%, ${appConfig.style.colors.orange} 1.5%)`,
                        transition: "all .3s",
                    },
                },
            },
        },
    },
    "&.highlight": {
        "&.active.last, &.completed.last": {
            ".caption": {
                color: appConfig.style.colors.orange,
            },
            ".number": {
                border: `0.4rem solid ${appConfig.style.colors.orange}`,
                background: `linear-gradient(0deg, #ffcd91, ${appConfig.style.colors.orange}),linear-gradient(359.28deg, #FCC48C 0.57%, ${appConfig.style.colors.orange} 99.35%)`,
                color: "#ffffff",
                transition: "all .3s",
            },
        },
    },
    "&:not(:last-child)": {
        ".number-circle": {
            "&:after": {
                content: '""',
                position: "absolute",
                height: "0.3rem",
                width: 10000,
                background: appConfig.style.colors.text3,
                left: "100%",
                pointerEvents: "none",
            },
        },
    },
    "&:last-child": {
        ".number-circle": {
            "&:after": {
                content: '""',
                position: "absolute",
                height: "0.4rem",
                width: 10000,
                background: "#ffffff !important",
                left: "100%",
            },
        },
    },
});

export default Step;
