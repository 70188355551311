import { css } from "@emotion/css";
import { breakpoints } from "../../../../config/style/theme";

export const CapTableStyle = css({
	label: "CapTable",
	flexGrow: 1,

	".navArrow": {
		position: "absolute",
		right: "2rem",
		top: "2rem",
		width: "fit-content",
		height: "fit-content",

		"&__icon": {
			width: "1.9rem",
			height: "auto",
		},
	},

	".chartContainer": {
		justifyContent: "start",
		height: "100%",
		backgroundColor: "transparent",

		"&__body": {
			justifyContent: "start",
			flexGrow: 1,
		},

		"&__doughnut": {
			maxHeight: "18rem !important",
			maxWidth: "18rem !important",
			marginInlineEnd: "4rem",
		},
	},
});
