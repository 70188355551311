import { css } from "@emotion/css";
import appConfig from "../../../../../../config/config";

const VersionStyle = css({
    label: "Version",
    "&__row-wrapper": {
        position: "relative",
        height: "8rem",
        // "margin": "0.2rem 0",
        padding: "0.4rem 0",
        "&:not(:last-child):not(.header)": {
            borderBottom: `1px solid ${appConfig.style.colors.table}`,
        },
        "&.header": {
            fontWeight: 500,
            margin: "0 1.6rem",
            padding: 0,
            borderBottom: `2px solid ${appConfig.style.colors.table}`,
            fontFamily: appConfig.style.fonts.textPro,
        },
    },
    "&__row": {
        // "margin": "0.4rem",
        padding: "0 1.2rem",
        height: "100%",
        gap: "1rem",
        // "&:not(&.header)": {},
        display: "flex",
        alignItems: "center",
        // "&.published": {
        // 	background: appConfig.style.colors.background3,
        // 	borderRadius: 8,
        // },
        svg: {
            fontSize: "2rem",
        },
    },
    "&__row-name": {
        flex: 2,
        display: "flex",
        alignItems: "center",
        gap: "0.4rem",
        transition: "all .1s",
        "&:not(.header)": {
            "&:hover": {
                color: appConfig.style.colors.color1,
            },
        },
        "&:not(:hover)": {
            ".published": {
                color: appConfig.style.colors.orange,
            },
            ".draft": {
                color: appConfig.style.colors.text4,
            },
        },
    },
    "&__row-update": {
        flex: 1,
        display: "flex",
        alignItems: "center",
    },
    "&__row-valuator": {
        flex: 1,
        display: "flex",
        alignItems: "center",
    },
    "&__row-exit-value": {
        flex: 1,
        display: "flex",
        alignItems: "center",
    },
    "&__row-actions": {
        flex: "0 0 14rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "2rem",
        img: {
            flex: "0 0 2.8rem",
            width: "2.8rem",
            height: "100%",
        },
        ".btn-trash": {
            img: {
                width: "2rem",
                flex: "0 0 2rem",
            },
        },
    },
    "&__project-link": {
        display: "flex",
        flex: 1,
        gap: "0.4rem",
        "&:hover": {
            textDecoration: "underline",
        },
    },
    "&__gray": {
        color: appConfig.style.colors.text4,
    },
});

export default VersionStyle;
