import React from 'react';
import StyledTable from './Table.Style';
import appConfig from '../../../config/config';
import { css } from '@emotion/css';

interface Props {
    data: string[][];
    title?: string;
}

const Style = css({
    margin: '5rem 0 0 0',

    "&__table": {
        boxShadow: appConfig.style.boxShadow.card1,
        border: `1px solid ${appConfig.style.colors.table}`,
        borderRadius: 16
    }
})

const UITable: React.FC<Props> = ({ data, title }) => {
    return (
        <section className={Style}>
            {title && <h5>{title}</h5>}
            <StyledTable.Table className={`${Style}__table`}>
                <StyledTable.Row header>
                    {data[0].map((cell) => (
                        <StyledTable.Cell>{cell}</StyledTable.Cell>
                    ))}
                </StyledTable.Row>
                {data.slice(1).map((object) => (
                    <StyledTable.Row>
                        {object.map((cell) => (
                            <StyledTable.Cell border={{ top: true, bottom: true, left: true, right: true }} >{cell}</StyledTable.Cell>
                        ))}
                    </StyledTable.Row>
                ))}
            </StyledTable.Table>
        </section>
    );
};

export default UITable;