import { css } from "@emotion/css";
import appConfig from "../../../../../config/config";

const MergeUserStyle = css({
	label: "MergeUser",
	padding: "7.5rem 15rem",
	"&__warning-container": {
		display: "flex",
		width: "100%",
		padding: "3rem 8rem",
		background: appConfig.style.colors.purple,
		borderRadius: 16,
	},
	"&__warning-img-wrapper": {
		flex: "0 0 6rem",
		marginRight: "2.4rem",
		img: {
			width: "100%",
		},
	},
	"&__warning-texts": {
		display: "flex",
		flexDirection: "column",
		alignSelf: "center",
		".danger": {
			color: appConfig.style.colors.danger,
			fontWeight: "bold",
		},
	},
	"&__new-user-section": {
		marginTop: "2rem",
	},
	".separator": {
		marginTop: "4.5rem",
		textAlign: "center",
		fontWeight: 500,
		fontSize: "2rem",
		position: "relative",
		span: {
			background: "#ffffff",
		},
		"&::before": {
			content: "''",
			position: "absolute",
			width: "calc(50% - 3rem)",
			height: 1,
			background: appConfig.style.colors.table,
			top: "50%",
			left: 0,
		},
		"&::after": {
			content: "''",
			position: "absolute",
			width: "calc(50% - 3rem)",
			height: 1,
			background: appConfig.style.colors.table,
			top: "50%",
			right: 0,
		},
	},
	"&__user-suggestions-section": {
		marginTop: "1rem",
	},
});

export default MergeUserStyle;
