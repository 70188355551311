import { PagedTableKeys } from "../../../Shared/Components/Table/PagedTable/PagedTable";
import { OrderByCancelationTable, OrderByGrantTable, OrderByOrderTable, TrusteeCancelation, TrusteeGrant, TrusteeGrantDocument, TrusteeOrder, UpcomingVestings } from "../Types/trusteeTypes";

export const trusteeGrantKeys: PagedTableKeys<TrusteeGrant>[] = [
    { key: 'grantNumber', displayValue: 'Grant number', entityEnumValue: OrderByGrantTable.GrantNumber, searchable: true },
    { key: 'beneficiaryName', displayValue: 'Beneficiary', entityEnumValue: OrderByGrantTable.BeneficiaryName, searchable: true },
    { key: 'awardType', displayValue: 'Grant type', entityEnumValue: OrderByGrantTable.AwardType },
    { key: 'grantDate', displayValue: 'Grant date', entityEnumValue: OrderByGrantTable.GrantDate },
    { key: 'granted', displayValue: 'Granted', entityEnumValue: OrderByGrantTable.Granted },
    { key: 'unVested', displayValue: 'Unvested', entityEnumValue: OrderByGrantTable.UnVested },
    { key: 'exercisable', displayValue: 'Exercisable', entityEnumValue: OrderByGrantTable.Exercisable },
    { key: 'saleable', displayValue: 'Saleable', entityEnumValue: OrderByGrantTable.Saleable },
    { key: 'exercised', displayValue: 'Exercised', entityEnumValue: OrderByGrantTable.Exercised },
    { key: 'sold', displayValue: 'Sold', entityEnumValue: OrderByGrantTable.Sold },
    { key: 'canceled', displayValue: 'Canceled', entityEnumValue: OrderByGrantTable.Canceled },
    { key: 'outstanding', displayValue: 'Outstanding', entityEnumValue: OrderByGrantTable.Outstanding }
]

export const trusteeOrderKeysMain: PagedTableKeys<TrusteeOrder>[] = [
    { key: 'orderNumber', displayValue: 'Order number', entityEnumValue: OrderByOrderTable.OrderNumber },
    { key: 'grantNumber', displayValue: 'Grant number', entityEnumValue: OrderByOrderTable.GrantNumber },
    { key: 'beneficiaryName', displayValue: 'Full name', entityEnumValue: OrderByOrderTable.BeneficiaryName },
    { key: 'orderType', displayValue: 'Order type', entityEnumValue: OrderByOrderTable.OrderType },
    { key: 'orderDate', displayValue: 'Order date', entityEnumValue: OrderByOrderTable.OrderDate },
    { key: 'amount', displayValue: 'Amount', entityEnumValue: OrderByOrderTable.Amount },
    { key: 'exercisePrice', displayValue: 'Exercise price', entityEnumValue: OrderByOrderTable.ExercisePrice },
    { key: 'salePrice', displayValue: 'Sale price', entityEnumValue: OrderByOrderTable.SalePrice }
]

export const trusteeCancelationKeysMain: PagedTableKeys<TrusteeCancelation>[] = [
    { key: 'cancelationNumber', displayValue: 'Cancelation number', entityEnumValue: OrderByCancelationTable.CancelationNumber, searchable: true },
    { key: 'beneficiaryName', displayValue: 'Full name', entityEnumValue: OrderByCancelationTable.BeneficiaryName },
    { key: 'grantNumber', displayValue: 'Grant number', entityEnumValue: OrderByCancelationTable.GrantNumber, searchable: true },
    { key: 'cancelType', displayValue: 'Cancel type', entityEnumValue: OrderByCancelationTable.CancelType },
    { key: 'cancellReason', displayValue: 'Cancel reason', entityEnumValue: OrderByCancelationTable.CancellReason },
    { key: 'cancelDate', displayValue: 'Cancel in', entityEnumValue: OrderByCancelationTable.CancelDate },
    { key: 'amount', displayValue: 'Amount', entityEnumValue: OrderByCancelationTable.Amount }
]

export const trusteeGrantKeysShort: PagedTableKeys<TrusteeGrant>[] = [
    { key: 'grantNumber', displayValue: 'Grant number', entityEnumValue: OrderByGrantTable.GrantNumber, searchable: true },
    { key: 'awardType', displayValue: 'Grant type', entityEnumValue: OrderByGrantTable.AwardType },
    { key: 'grantDate', displayValue: 'Grant date', entityEnumValue: OrderByGrantTable.GrantDate },
    { key: 'granted', displayValue: 'Granted', entityEnumValue: OrderByGrantTable.Granted },
    { key: 'unVested', displayValue: 'Unvested', entityEnumValue: OrderByGrantTable.UnVested },
    { key: 'exercisable', displayValue: 'Exercisable', entityEnumValue: OrderByGrantTable.Exercisable },
    // { key: 'saleable', displayValue: 'Saleable', entityEnumValue: OrderByGrantTable.Saleable },
    // { key: 'exercised', displayValue: 'Exercised', entityEnumValue: OrderByGrantTable.Exercised },
    // { key: 'sold', displayValue: 'Sold', entityEnumValue: OrderByGrantTable.Sold },
    // { key: 'canceled', displayValue: 'Canceled', entityEnumValue: OrderByGrantTable.Canceled },
    { key: 'outstanding', displayValue: 'Outstanding', entityEnumValue: OrderByGrantTable.Outstanding }
]

export const trusteeOrderKeys: PagedTableKeys<TrusteeOrder>[] = [
    { key: 'orderNumber', displayValue: 'Order number', entityEnumValue: OrderByOrderTable.OrderNumber, searchable: true },
    { key: 'grantNumber', displayValue: 'Grant number', entityEnumValue: OrderByOrderTable.GrantNumber },
    { key: 'orderType', displayValue: 'Order type', entityEnumValue: OrderByOrderTable.OrderType },
    { key: 'amount', displayValue: 'Amount', entityEnumValue: OrderByOrderTable.Amount },
    { key: 'salePrice', displayValue: 'Sale price', entityEnumValue: OrderByOrderTable.SalePrice },
    { key: 'exercisePrice', displayValue: 'Exercise price', entityEnumValue: OrderByOrderTable.ExercisePrice }
]

export const cancelationOrderKeys: PagedTableKeys<TrusteeCancelation>[] = [
    { key: 'cancelationNumber', displayValue: 'Cancelation number', entityEnumValue: OrderByCancelationTable.CancelationNumber, searchable: true },
    { key: 'cancelType', displayValue: 'Cancel type', entityEnumValue: OrderByCancelationTable.CancelType },
    { key: 'cancelDate', displayValue: 'Cancel in', entityEnumValue: OrderByCancelationTable.CancelDate },
    { key: 'cancellReason', displayValue: 'Cancel reason', entityEnumValue: OrderByCancelationTable.CancellReason },
    { key: 'amount', displayValue: 'Amount', entityEnumValue: OrderByCancelationTable.Amount }
]

export const upcomingVestingsKeys: PagedTableKeys<UpcomingVestings>[] = [
    { key: 'vestingDate', displayValue: 'Vesting date' },
    { key: 'grantType', displayValue: 'Grant type' },
    { key: 'vestingAmmount', displayValue: 'Quantity per event' },
    { key: 'numberOfGrants', displayValue: 'Number of grants' }
]

export const trusteeGrantDocuments: PagedTableKeys<TrusteeGrantDocument>[] = [
    { key: 'beneficiaryName', displayValue: 'Full name', entityEnumValue: 1 },
    { key: 'idSSN', displayValue: 'ID/SSN', entityEnumValue: 1 },
    { key: 'grantDate', displayValue: 'Grant date', entityEnumValue: 1 },
    { key: 'grantNumber', displayValue: 'Grant number', entityEnumValue: 1 },
    { key: 'grantType', displayValue: 'Grant type', entityEnumValue: 1 },
    { key: 'exercisePrice', displayValue: 'Exercise price', entityEnumValue: 1 },
    { key: 'quantity', displayValue: 'Full name', entityEnumValue: 1 },
    { key: 'documentId', displayValue: 'Full name', entityEnumValue: 1 },
]