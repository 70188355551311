import styled from "@emotion/styled";
import appConfig from "../../../../../config/config";
import { ImportCapTableProps } from ".";

const COL_MIN_WIDTH = "25rem";
const CELL_HEIGHT_SM = "calc(5rem + 1px)";
const CELL_HEIGHT_MD = "7.5rem";
const CELL_HEIGHT_LG = "10rem";

const FullContainer = styled.div(
    {
        label: "FullContainer",
        position: "relative",
    },
    ({ isWarrants, isClass }: { isWarrants?: boolean; isClass?: boolean }) => ({
        // height: `calc(100% - ${isClass ? (isWarrants ? 6 : 10.8) : 16}rem)`,
        // maxHeight: isClass ? "60rem" : "47.7rem",
        minHeight: "12.7rem",
        height: isClass ? "100%" : "calc(100% - 18rem)",
        // marginTop: !isClass || isWarrants ? 0 : "5rem",
        // ...(isFull
        //     ? {
        //           height: "calc(100% - 15rem)",
        //       }
        //     : {
        //           height: "calc(100% - 15rem)",
        //       }),
    })
);

const Table = styled.div(
    {
        label: "Table",
        height: "100%",
        // borderRadius: 16,
        boxShadow: `3px 3px 3px 0px ${appConfig.style.colors.boxShadowColor1}`,
        borderRadius: 16,
        overflow: "auto",
        display: "flex",
    },
    ({ border }: { border?: boolean }) => ({
        ...(border
            ? {
                  border: `1px solid ${appConfig.style.colors.table}`,
              }
            : {}),
    })
);

const Column = styled.div(
    {
        label: "ImportColumn",
        display: "flex",
        flexDirection: "column",
        flex: 1,
        height: "fit-content",
        minWidth: COL_MIN_WIDTH,
        position: "relative",
        "&.shrink": {
            minWidth: `calc(${COL_MIN_WIDTH} - 6px)`,
        },
    },
    ({
        isFirst,
        isLast,
        highlight,
        focus,
    }: Pick<ImportCapTableProps, "focus"> & { isFirst?: boolean; isLast?: boolean; highlight?: boolean }) => ({
        ...(highlight
            ? {
                  "> div.header": {
                      background: appConfig.style.colors.purple,
                      fontWeight: 500,
                  },
              }
            : {}),
        ...(isFirst && isLast
            ? {
                  borderRightWidth: 0,
                  //   borderTopLeftRadius: 16,
                  //   borderBottomLeftRadius: 16,
              }
            : {}),
        ...(isLast
            ? {
                  //   borderTopRightRadius: 16,
                  //   borderBottomRightRadius: 16,
                  "> div": {
                      borderRightWidth: 0,
                  },
                  borderRight: `0px solid ${appConfig.style.colors.table}`,
              }
            : {
                  borderRight: `2px solid ${appConfig.style.colors.table}`,
              }),
        ...(focus === "border"
            ? {
                  //   border: `1px solid ${appConfig.style.colors.color1}`,
                  //   borderColor: appConfig.style.colors.color1,
              }
            : {}),
        // ...(isStickyLeft
        //     ? {
        //           position: "sticky",
        //           left: "32rem",
        //           zIndex: 3,
        //           flex: 1,
        //           "&:first-of-type": {
        //               left: 0,
        //           },
        //       }
        //     : {}),
    })
);

const Cell = styled.div(
    {
        label: "ImportCell",
        display: "flex",
        alignItems: "center",
        background: "#ffffff",
        "&.disable": {
            color: appConfig.style.colors.text2,
        },
        "&.multiline": {
            flexDirection: "column",
            "> span": {
                flex: 1,
                display: "flex",
                width: "100%",
                alignItems: "center",
                paddingInline: "2rem",
                "&:first-of-type": {
                    borderBottom: `1px solid ${appConfig.style.colors.table}`,
                },
            },
        },
        "&:not(.multiline.sub)": {
            paddingInline: "2rem",
        },
        "&.sub": {
            top: CELL_HEIGHT_SM,
            borderTopWidth: 0,
        },
        "&.header:not(.sub)": {
            zIndex: 2,
            borderBottomWidth: 2,
            // flexBasis: "calc(7.5rem + 1px)",
        },
        ".selection": {
            marginLeft: "auto",
            color: appConfig.style.colors.text2,
        },
    },
    ({ headerSize, isLast = false, isTotal = false }: { headerSize?: "sm" | "md" | "lg"; isLast?: boolean; isTotal?: boolean }) => ({
        ...(headerSize
            ? {
                  borderBottomWidth: 3,
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  "&.sub:not(.multiline)": {
                      fontWeight: 500,
                  },
                  flexBasis: headerSize === "lg" ? CELL_HEIGHT_LG : headerSize === "md" ? CELL_HEIGHT_MD : CELL_HEIGHT_SM,
                  height: headerSize === "lg" ? CELL_HEIGHT_LG : headerSize === "md" ? CELL_HEIGHT_MD : CELL_HEIGHT_SM,
              }
            : {
                  "&.shrink": {
                      flexBasis: "calc(5rem - 6px)",
                      height: "calc(5rem - 6px)",
                  },
                  "&:not(.shrink)": {
                      flexBasis: "5rem",
                      height: "5rem",
                  },
              }),
        ...(isLast
            ? {}
            : {
                  borderBottom: `${headerSize ? 2 : 1}px solid ${appConfig.style.colors.table}`,
              }),
        ...(isTotal
            ? {
                  borderTop: `1px solid ${appConfig.style.colors.table}`,
                  position: "sticky",
                  //   background: appConfig.style.colors.purple,
                  bottom: 0,
                  zIndex: 3,
                  fontWeight: 500,
              }
            : {}),
    })
);

const Container = styled.div(
    {
        display: "flex",
        height: "100%",
        overflow: "auto",
        background: "#ffffff",
        position: "relative",
        "&.hidden-scroll": {
            flex: "0 0 calc(52rem + 1px)", // 1px of border
            height: "calc(calc(100% - 5rem) - 1px)",
            marginTop: "auto",
            borderTopLeftRadius: 16,
            borderBottomLeftRadius: 16,
            borderRightWidth: 0,
            "&.warrants": {
                height: "calc(100% - 10rem)",
            },
            // width: "fit-content",
            "::-webkit-scrollbar": {
                width: 0,
                height: 0,
            },
        },
        "&:not(.hidden-scroll)": {
            // width: "100%",
            flex: 1,
            // borderLeftWidth: 0,
        },
        "&.class-action-container": {
            position: "absolute",
            bottom: "100%",
            height: "fit-content",
            width: "calc(calc(100% - 52rem) + 1px)",
            left: "calc(52rem - 1px)",
            background: appConfig.style.colors.purple,
            overflow: "auto",
            border: `2px solid ${appConfig.style.colors.table}`,
            borderBottomWidth: 0,
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
        },
    },
    ({ border }: { border?: boolean }) => ({
        ...(border
            ? {
                  border: `1px solid ${appConfig.style.colors.table}`,
              }
            : {}),
    })
);

export const ImportColumn = {
    Table,
    Column,
    Cell,
    Container,
    FullContainer,
};
