import { observer } from "mobx-react-lite";
import { Cell, Row, Table } from "../../../../../../../../Shared/Components/Table/Table.Style";
import { createDynamicTable } from "../../deps/helpers";
import { IEquityHolderParsed, IShareClassesSummaryCalculation } from "../../deps/types";
import { css } from "@emotion/css";
import appConfig from "../../../../../../../../config/config";
import OverflowText from "../../../../../../../../Shared/Components/OverflowText";
import classNames from "classnames";

interface Props {
	data: IEquityHolderParsed;
}

const Style = css({
	label: "ExitSimulationDyamicTable",
	boxShadow: appConfig.style.boxShadow.card1,
	border: `1px solid ${appConfig.style.colors.table}`,
	borderRadius: 16,
	overflow: 'auto',
	"&__cell": {
		overflow: "auto !important",
		"&.fixed-col": {
			flex: "0 0 18rem",
			fontWeight: 500,
			background: appConfig.style.colors.purple
		},
		"&.border-thick": {
			borderWidth: 2,
		}
	}
})

const ExitSimulationDynamicTable = (props: Props) => {
	if (!props.data) return null;

	const result = createDynamicTable(props.data); // Use createDynamicTable to get the sorted result

	return (
		<div className={Style}>
			<Table>
				{result.map((row, index) =>
					<Row key={index} header={index === 0} data-qaid="ExitSimuation.Table.Row">
						{row.map((cell, cIndex) => (
							<Cell
								data-qaid="ExitSimuation.Table.Cell"
								border={index ? { bottom: index + 1 < result.length, top: true, right: true } : { bottom: true, top: false, right: true }}
								key={cIndex}
								defaultCellSize={cIndex ? "8.2rem" : "18rem"}
								className={classNames(`${Style}__cell`, { "fixed-col": !cIndex, "border-thick": !index })}
							>
								<OverflowText>
									{cell}
								</OverflowText>
							</Cell>
						))}
					</Row>
				)}
			</Table>
		</div>
	);
};

export default observer(ExitSimulationDynamicTable);
