import { makeAutoObservable } from "mobx";
import { RootStore } from "./RootStore";
import { SubTransaction, TransactionConsideration, TransactionDocument, TransactionInfo, TransactionNotification } from "../Models/API/ShareRep";
import { ShareRepService } from "../Services/ShareRep";

export class ShareRepStore {
    service : ShareRepService;
    info : TransactionInfo|null = null;
    docs : TransactionDocument[]|null = null;
    notifications : TransactionNotification[]|null = null;
    subs : SubTransaction[]|null = null;
    considerations : TransactionConsideration[]|null = null;

	constructor(private rootStore: RootStore) {
		makeAutoObservable(this);

        this.service = new ShareRepService();
    }

    async init(tranie_id : number) {
        this.info = (await this.service.getTransactionInfo(tranie_id)).data;
        this.docs = (await this.service.getTransactionDocs(tranie_id)).data;
        this.notifications = (await this.service.getTransactionNotifications(tranie_id)).data;
        this.subs = (await this.service.getSubTransactions(tranie_id)).data;
        this.considerations = (await this.service.getTransactionConsiderations(tranie_id)).data;
    }

    async refresh(tranie_id : number, after? : number) {
        const new_notifications = (await this.service.getTransactionNotifications(tranie_id, after)).data;
        this.notifications = (this.notifications||[]).concat(new_notifications);
    }

    resetStoreToDefaultValues() {
        this.info = null;
        this.docs = null;
        this.notifications = null;
        this.subs = null;
        this.considerations = null;
    }
}