import { css } from "@emotion/css";
import styled from "@emotion/styled";
import appConfig from "../../../../config/config";
import { breakpoints, theme } from "../../../../config/style/theme";

export const StyledWaterfallHeader = styled.div`
    label: WaterfallHeader;
    padding: ${theme.spacings.sm} ${theme.spacings.xl};
    // min-height: 14.7rem;
    box-shadow: ${appConfig.style.boxShadow.card2};
    margin-bottom: ${theme.spacings.lg};

    .button--waterfall {
        min-width: unset;
    }
`;

export const TopBar = styled.div`
    label: WaterfallHeader__TopBar;
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    // @media screen and (min-width: ${breakpoints.md}) {
    // 	margin-bottom: ${theme.spacings.sm};
    // }
    // @media screen and (min-width: ${breakpoints.lg}) {
    // 	margin-bottom: unset;
    // }
`;

export const ActionsMenuStyle = css`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    column-gap: ${theme.spacings.xs};
`;
