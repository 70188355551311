export const countries = [
	{ value: 1, label: "Afghanistan" },
	{ value: 3, label: "Albania" },
	{ value: 4, label: "Algeria" },
	{ value: 5, label: "American Samoa" },
	{ value: 6, label: "Andorra" },
	{ value: 7, label: "Angola" },
	{ value: 8, label: "Anguilla" },
	{ value: 9, label: "Antarctica" },
	{ value: 10, label: "Antigua and Barbuda" },
	{ value: 11, label: "Argentina" },
	{ value: 12, label: "Armenia" },
	{ value: 13, label: "Aruba" },
	{ value: 14, label: "Australia" },
	{ value: 15, label: "Austria" },
	{ value: 16, label: "Azerbaijan" },
	{ value: 17, label: "Bahamas" },
	{ value: 18, label: "Bahrain" },
	{ value: 19, label: "Bangladesh" },
	{ value: 20, label: "Barbados" },
	{ value: 21, label: "Belarus" },
	{ value: 22, label: "Belgium" },
	{ value: 23, label: "Belize" },
	{ value: 24, label: "Benin" },
	{ value: 25, label: "Bermuda" },
	{ value: 26, label: "Bhutan" },
	{ value: 27, label: "Bolivia" },
	{ value: 28, label: "Bosnia and Herzegovina" },
	{ value: 29, label: "Botswana" },
	{ value: 30, label: "Bouvet Island" },
	{ value: 31, label: "Brazil" },
	{ value: 32, label: "British Indian Ocean Territory" },
	{ value: 33, label: "Brunei Darussalam" },
	{ value: 34, label: "Bulgaria" },
	{ value: 35, label: "Burkina Faso" },
	{ value: 36, label: "Burundi" },
	{ value: 37, label: "Cambodia" },
	{ value: 38, label: "Cameroon" },
	{ value: 39, label: "Canada" },
	{ value: 40, label: "Cape Verde" },
	{ value: 41, label: "Cayman Islands" },
	{ value: 42, label: "Central African Republic" },
	{ value: 43, label: "Chad" },
	{ value: 44, label: "Chile" },
	{ value: 45, label: "China" },
	{ value: 46, label: "Christmas Island" },
	{ value: 47, label: "Cocos (Keeling) Islands" },
	{ value: 48, label: "Colombia" },
	{ value: 49, label: "Comoros" },
	{ value: 50, label: "Congo" },
	{ value: 51, label: "Congo, The Democratic Republic of the" },
	{ value: 52, label: "Cook Islands" },
	{ value: 53, label: "Costa Rica" },
	{ value: 54, label: 'Cote D"Ivoire' },
	{ value: 55, label: "Croatia" },
	{ value: 56, label: "Cuba" },
	{ value: 57, label: "Cyprus" },
	{ value: 58, label: "Czech Republic" },
	{ value: 59, label: "Denmark" },
	{ value: 60, label: "Djibouti" },
	{ value: 61, label: "Dominica" },
	{ value: 62, label: "Dominican Republic" },
	{ value: 63, label: "Ecuador" },
	{ value: 64, label: "Egypt" },
	{ value: 65, label: "El Salvador" },
	{ value: 66, label: "Equatorial Guinea" },
	{ value: 67, label: "Eritrea" },
	{ value: 68, label: "Estonia" },
	{ value: 69, label: "Ethiopia" },
	{ value: 70, label: "Falkland Islands (Malvinas)" },
	{ value: 71, label: "Faroe Islands" },
	{ value: 72, label: "Fiji" },
	{ value: 73, label: "Finland" },
	{ value: 74, label: "France" },
	{ value: 75, label: "French Guiana" },
	{ value: 76, label: "French Polynesia" },
	{ value: 77, label: "French Southern Territories" },
	{ value: 78, label: "Gabon" },
	{ value: 79, label: "Gambia" },
	{ value: 80, label: "Georgia" },
	{ value: 81, label: "Germany" },
	{ value: 82, label: "Ghana" },
	{ value: 83, label: "Gibraltar" },
	{ value: 84, label: "Greece" },
	{ value: 85, label: "Greenland" },
	{ value: 86, label: "Grenada" },
	{ value: 87, label: "Guadeloupe" },
	{ value: 88, label: "Guam" },
	{ value: 89, label: "Guatemala" },
	{ value: 90, label: "Guernsey" },
	{ value: 91, label: "Guinea" },
	{ value: 92, label: "Guinea-Bissau" },
	{ value: 93, label: "Guyana" },
	{ value: 94, label: "Haiti" },
	{ value: 95, label: "Heard Island and Mcdonald Islands" },
	{ value: 96, label: "Holy See (Vatican City State)" },
	{ value: 97, label: "Honduras" },
	{ value: 98, label: "Hong Kong" },
	{ value: 99, label: "Hungary" },
	{ value: 100, label: "Iceland" },
	{ value: 101, label: "India" },
	{ value: 102, label: "Indonesia" },
	{ value: 103, label: "Iran, Islamic Republic Of" },
	{ value: 104, label: "Iraq" },
	{ value: 105, label: "Ireland" },
	{ value: 106, label: "Isle of Man" },
	{ value: 107, label: "Israel" },
	{ value: 108, label: "Italy" },
	{ value: 109, label: "Jamaica" },
	{ value: 110, label: "Japan" },
	{ value: 111, label: "Jersey" },
	{ value: 112, label: "Jordan" },
	{ value: 113, label: "Kazakhstan" },
	{ value: 114, label: "Kenya" },
	{ value: 115, label: "Kiribati" },
	{ value: 116, label: 'Korea, Democratic People"S Republic of' },
	{ value: 117, label: "Korea, Republic of" },
	{ value: 118, label: "Kuwait" },
	{ value: 119, label: "Kyrgyzstan" },
	{ value: 120, label: 'Lao People"S Democratic Republic' },
	{ value: 121, label: "Latvia" },
	{ value: 122, label: "Lebanon" },
	{ value: 123, label: "Lesotho" },
	{ value: 124, label: "Liberia" },
	{ value: 125, label: "Libyan Arab Jamahiriya" },
	{ value: 126, label: "Liechtenstein" },
	{ value: 127, label: "Lithuania" },
	{ value: 128, label: "Luxembourg" },
	{ value: 129, label: "Macao" },
	{ value: 130, label: "Macedonia, The Former Yugoslav Republic of" },
	{ value: 131, label: "Madagascar" },
	{ value: 132, label: "Malawi" },
	{ value: 133, label: "Malaysia" },
	{ value: 134, label: "Maldives" },
	{ value: 135, label: "Mali" },
	{ value: 136, label: "Malta" },
	{ value: 137, label: "Marshall Islands" },
	{ value: 138, label: "Martinique" },
	{ value: 139, label: "Mauritania" },
	{ value: 140, label: "Mauritius" },
	{ value: 141, label: "Mayotte" },
	{ value: 142, label: "Mexico" },
	{ value: 143, label: "Micronesia, Federated States of" },
	{ value: 144, label: "Moldova, Republic of" },
	{ value: 145, label: "Monaco" },
	{ value: 146, label: "Mongolia" },
	{ value: 147, label: "Montenegro" },
	{ value: 148, label: "Montserrat" },
	{ value: 149, label: "Morocco" },
	{ value: 150, label: "Mozambique" },
	{ value: 151, label: "Myanmar" },
	{ value: 152, label: "Namibia" },
	{ value: 153, label: "Nauru" },
	{ value: 154, label: "Nepal" },
	{ value: 155, label: "Netherlands" },
	{ value: 156, label: "Netherlands Antilles" },
	{ value: 157, label: "New Caledonia" },
	{ value: 158, label: "New Zealand" },
	{ value: 159, label: "Nicaragua" },
	{ value: 160, label: "Niger" },
	{ value: 161, label: "Nigeria" },
	{ value: 162, label: "Niue" },
	{ value: 163, label: "Norfolk Island" },
	{ value: 164, label: "Northern Mariana Islands" },
	{ value: 165, label: "Norway" },
	{ value: 166, label: "Oman" },
	{ value: 167, label: "Pakistan" },
	{ value: 168, label: "Palau" },
	{ value: 169, label: "Palestinian Territory, Occupied" },
	{ value: 170, label: "Panama" },
	{ value: 171, label: "Papua New Guinea" },
	{ value: 172, label: "Paraguay" },
	{ value: 173, label: "Peru" },
	{ value: 174, label: "Philippines" },
	{ value: 175, label: "Pitcairn" },
	{ value: 176, label: "Poland" },
	{ value: 177, label: "Portugal" },
	{ value: 178, label: "Puerto Rico" },
	{ value: 179, label: "Qatar" },
	{ value: 180, label: "Reunion" },
	{ value: 181, label: "Romania" },
	{ value: 182, label: "Russian Federation" },
	{ value: 183, label: "RWANDA" },
	{ value: 184, label: "Saint Helena" },
	{ value: 185, label: "Saint Kitts and Nevis" },
	{ value: 186, label: "Saint Lucia" },
	{ value: 187, label: "Saint Pierre and Miquelon" },
	{ value: 188, label: "Saint Vincent and the Grenadines" },
	{ value: 189, label: "Samoa" },
	{ value: 190, label: "San Marino" },
	{ value: 191, label: "Sao Tome and Principe" },
	{ value: 192, label: "Saudi Arabia" },
	{ value: 193, label: "Senegal" },
	{ value: 194, label: "Serbia" },
	{ value: 195, label: "Seychelles" },
	{ value: 196, label: "Sierra Leone" },
	{ value: 197, label: "Singapore" },
	{ value: 198, label: "Slovakia" },
	{ value: 199, label: "Slovenia" },
	{ value: 200, label: "Solomon Islands" },
	{ value: 201, label: "Somalia" },
	{ value: 202, label: "South Africa" },
	{ value: 203, label: "South Georgia and the South Sandwich Islands" },
	{ value: 204, label: "Spain" },
	{ value: 205, label: "Sri Lanka" },
	{ value: 206, label: "Sudan" },
	{ value: 207, label: "Suriname" },
	{ value: 208, label: "Svalbard and Jan Mayen" },
	{ value: 209, label: "Swaziland" },
	{ value: 210, label: "Sweden" },
	{ value: 211, label: "Switzerland" },
	{ value: 212, label: "Syrian Arab Republic" },
	{ value: 213, label: "Taiwan, Province of China" },
	{ value: 214, label: "Tajikistan" },
	{ value: 215, label: "Tanzania, United Republic of" },
	{ value: 216, label: "Thailand" },
	{ value: 217, label: "Timor-Leste" },
	{ value: 218, label: "Togo" },
	{ value: 219, label: "Tokelau" },
	{ value: 220, label: "Tonga" },
	{ value: 221, label: "Trinidad and Tobago" },
	{ value: 222, label: "Tunisia" },
	{ value: 223, label: "Turkey" },
	{ value: 224, label: "Turkmenistan" },
	{ value: 225, label: "Turks and Caicos Islands" },
	{ value: 226, label: "Tuvalu" },
	{ value: 227, label: "Uganda" },
	{ value: 228, label: "Ukraine" },
	{ value: 229, label: "United Arab Emirates" },
	{ value: 230, label: "United Kingdom" },
	{ value: 231, label: "United States" },
	{ value: 232, label: "United States Minor Outlying Islands" },
	{ value: 233, label: "Uruguay" },
	{ value: 234, label: "Uzbekistan" },
	{ value: 235, label: "Vanuatu" },
	{ value: 236, label: "Venezuela" },
	{ value: 237, label: "Viet Nam" },
	{ value: 238, label: "Virgin Islands, British" },
	{ value: 239, label: "Virgin Islands, U.S." },
	{ value: 240, label: "Wallis and Futuna" },
	{ value: 241, label: "Western Sahara" },
	{ value: 242, label: "Yemen" },
	{ value: 243, label: "Zambia" },
	{ value: 244, label: "Zimbabwe" },
];
