import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import classNames from "classnames";
import { Dispatch, SetStateAction, useState } from "react";
import { CompanyRegistration } from "../../../../Models/API/Auth";
import Clickable from "../../../../Shared/Components/Clickable/Clickable";
import AutoCompleteInput from "../../../../Shared/Components/Input/AutoCompleteInput";
import NumberInput from "../../../../Shared/Components/Input/NumberInput";
import TextInput from "../../../../Shared/Components/Input/TextInput";
import Select from "../../../../Shared/Components/Select/Select";
import { currenciesOptions } from "../../../../Shared/Config";
import { AppendStateInputHandlerType } from "../../../../Shared/Hooks/useAppendState";
import { InputRefs, InputValidationRef } from "../../../../Shared/Hooks/useFormValidation";
import { ObjectValidationStatus } from "../../../../Shared/ObjectValidator";
import { countries } from "../../../../Shared/StaticData/countries";
import AddEditNewCompanyStyle from "../index.style";
import { industries } from "../../../../Shared/StaticData/industries";
import DatePickerInput from "../../../../Shared/Components/Input/DatePickerInput";
import { numOfEmployees } from "../../../../Shared/StaticData/numOfEmployees";
import { TextAreaInput } from "../../../../Shared/Components/Input/Input.Style";
import Label from "../../../../Shared/Components/Layout/Label";
import ExpandToggle from "../../../../Shared/Components/ExpandToggle";

interface Props {
    company: CompanyRegistration;
    onInputHandler: AppendStateInputHandlerType;
    setCompany: Dispatch<SetStateAction<CompanyRegistration>>;
    formValidationState?: ObjectValidationStatus<CompanyRegistration>;
    onSuggestionSelected?: (company: CompanyRegistration) => void;
    isViewMode?: boolean;
    disabled?: boolean;
    inputRefs: InputRefs<CompanyRegistration>;
}

const CompanyInfo = ({ company, inputRefs, onInputHandler, setCompany, formValidationState, disabled, isViewMode }: Props) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    return (
        <div className={classNames(`${AddEditNewCompanyStyle}__form`, { isOpen: isExpanded })}>
            <TextInput
                label="Company name"
                qaid="CompanyInfo.Input.FirstName"
                onChange={onInputHandler}
                value={company.companyLegalName}
                name="companyLegalName"
                error={formValidationState?.companyLegalName?.message}
                disabled={disabled}
                ref={(el: InputValidationRef) => (inputRefs.companyLegalName = el)}
                isViewMode={isViewMode}
                required
                containerStyle={{ gridArea: "1 / 1 / 2 / 2" }}
            />
            <AutoCompleteInput
                label="Country of incorporation"
                qaid="CompanyInfo.Input.Nationality"
                value={company.nationality}
                onChange={onInputHandler}
                name="nationality"
                options={countries}
                containerClassName="mb-0"
                autoComplete="nope"
                isViewMode={isViewMode}
                disabled={disabled}
                error={formValidationState?.nationality?.message}
                ref={(el: InputValidationRef) => (inputRefs.nationality = el)}
                containerStyle={{ gridArea: "1 / 2 / 2 / 3" }}
                required
            />
            <Select
                label="Currency"
                qaid="CompanyInfo.Input.Email"
                onChange={onInputHandler}
                value={company.currency}
                name="currency"
                options={currenciesOptions}
                error={formValidationState?.currency?.message}
                disabled={disabled}
                ref={(el: InputValidationRef) => (inputRefs.currency = el)}
                isViewMode={isViewMode}
                style={{ gridArea: "1 / 3 / 2 / 4" }}
                required
            />
            <Select
                label="Company size"
                qaid="CompanyInfo.Input.NoOfEmployees"
                value={company.noOfEmployees}
                onChange={onInputHandler}
                name="noOfEmployees"
                options={numOfEmployees.map((obj) => ({ ...obj, label: `${obj.label} Employees` }))}
                isViewMode={isViewMode}
                disabled={disabled}
                error={formValidationState?.noOfEmployees?.message}
                ref={(el: InputValidationRef) => (inputRefs.noOfEmployees = el)}
                style={{ gridArea: "1 / 4 / 2 / 5" }}
                required
            />

            <Clickable
                justify="start"
                width="fit-content"
                qaid="CompanyInfo.Button.MoreInfo"
                onClick={() => setIsExpanded(!isExpanded)}
                gap="1.2rem"
                style={{ gridArea: "2 / 1 / 3 / 4" }}
            >
                Add more info (optional)
                <ExpandToggle isOpen={isExpanded} />
            </Clickable>

            {isExpanded && (
                <>
                    <TextInput
                        label="Website"
                        qaid="CompanyInfo.Input.Website"
                        value={company.website ?? undefined}
                        onChange={onInputHandler}
                        name="website"
                        containerClassName="mb-0"
                        isViewMode={isViewMode}
                        disabled={disabled}
                        containerStyle={{ gridArea: "3 / 1 / 4 / 2" }}
                    />
                    <Select
                        label="Industry"
                        qaid="CompanyInfo.Input.Industry"
                        value={company.industry}
                        onChange={onInputHandler}
                        name="industry"
                        options={industries}
                        isViewMode={isViewMode}
                        disabled={disabled}
                        style={{ gridArea: "3 / 2 / 4 / 3" }}
                    />
                    <DatePickerInput
                        label="Formation date"
                        qaid="CompanyInfo.Input.FormationDate"
                        value={company.formationDate}
                        onChange={onInputHandler}
                        name="formationDate"
                        isViewMode={isViewMode}
                        disabled={disabled}
                        containerStyle={{ gridArea: "3 / 3 / 4 / 4" }}
                    />
                    <NumberInput
                        label="Corporate number"
                        qaid="CompanyInfo.Input.CompanyNumber"
                        value={company.companyNumber}
                        onChange={onInputHandler}
                        name="companyNumber"
                        isViewMode={isViewMode}
                        disabled={disabled}
                        containerStyle={{ gridArea: "3 / 4 / 4 / 5" }}
                    />
                    <div style={{ gridArea: "4 / 3 / 4 / 1", marginBottom: "2rem" }}>
                        <Label>Business description</Label>
                        <TextAreaInput
                            style={{ resize: "none", height: "100%", width: "100%" }}
                            rows={2}
                            maxLength={200}
                            name="businessDescription"
                            value={company.businessDescription ?? undefined}
                            onChange={(e: any) => onInputHandler(e.target.value, e.target.name)}
                            data-qaid="CompanyInfo.Input.Description"
                        />
                    </div>
                    {/* <Select
						label="Role"
						qaid="CompanyInfo.Select.Role"
						options={roleOptions}
						value={contact.contactDetails?.role}
						onChange={onInputHandler}
						name="contactDetails.role"
						ref={(el: InputValidationRef) => {
							if (contactInputRefs) {
								contactInputRefs.role = el;
							}
						}}
						style={{ gridArea: "3 / 2 / 4 / 3" }}
					/>
					<AutoCompleteInput
						label="Country of residence"
						qaid="CompanyInfo.Input.Nationality"
						value={contact.contactDetails?.nationality}
						onChange={onInputHandler}
						name="contactDetails.nationality"
						options={countries}
						containerClassName="mb-0"
						isViewMode={isViewMode}
						disabled={disabled}
						containerStyle={{ gridArea: "3 / 3 / 4 / 4" }}
					/>
					<TextInput
						label="ID"
						qaid="CompanyInfo.Input.ID"
						value={contact.contactDetails?.idNumber}
						onChange={onInputHandler}
						name="contactDetails.idNumber"
						containerClassName="mb-0"
						isViewMode={isViewMode}
						disabled={disabled}
						containerStyle={{ gridArea: "3 / 4 / 4 / 5" }}
					/> */}
                </>
            )}
        </div>
    );
};

export default CompanyInfo;
