import Flex from "../../../Shared/Components/Layout/Flex";
import { Skeleton } from "@mui/material";
import { RectangularSkeleton } from "../index.style";

const UpdatesLoader = () => {
	return (
		<Flex direction="column" align="start">
			{/* <Skeleton variant="rectangular" width={"50%"} height={"5%"} animation="wave" /> */}

			<Flex direction="column" gap={20} justify="start" margin="40px 0 0 0">
				<Skeleton variant="rectangular" width={"80%"} height={"5%"} animation="wave" className={RectangularSkeleton} />
				<Skeleton variant="rectangular" width={"80%"} height={"5%"} animation="wave" className={RectangularSkeleton} />
				<Skeleton variant="rectangular" width={"80%"} height={"5%"} animation="wave" className={RectangularSkeleton} />
				<Skeleton variant="rectangular" width={"80%"} height={"5%"} animation="wave" className={RectangularSkeleton} />
				<Skeleton variant="rectangular" width={"80%"} height={"5%"} animation="wave" className={RectangularSkeleton} />
				<Skeleton variant="rectangular" width={"80%"} height={"5%"} animation="wave" className={RectangularSkeleton} />
				<Skeleton variant="rectangular" width={"80%"} height={"5%"} animation="wave" className={RectangularSkeleton} />
				<Skeleton variant="rectangular" width={"80%"} height={"5%"} animation="wave" className={RectangularSkeleton} />
				<Skeleton variant="rectangular" width={"80%"} height={"5%"} animation="wave" className={RectangularSkeleton} />
				<Skeleton variant="rectangular" width={"80%"} height={"5%"} animation="wave" className={RectangularSkeleton} />
				<Skeleton variant="rectangular" width={"80%"} height={"5%"} animation="wave" className={RectangularSkeleton} />
				<Skeleton variant="rectangular" width={"80%"} height={"5%"} animation="wave" className={RectangularSkeleton} />
			</Flex>
		</Flex>
	);
};

export default UpdatesLoader;
