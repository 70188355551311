import { FC } from "react";
import { StyledWidgetTitle } from "./WidgetTitle.style";

interface WidgetTitleProps {
  text: string;
}

const WidgetTitle: FC<WidgetTitleProps> = ({ text }: WidgetTitleProps) => {
  return <StyledWidgetTitle>{text}</StyledWidgetTitle>;
};

export default WidgetTitle;
