import { ChartOptions, Plugin } from "chart.js";
import { AnyObject } from "chart.js/types/basic";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { colors } from "../../../../../../../config/style/lib/colors";
import { BarChartProps } from "../deps/types";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { css } from "@emotion/css";
import appConfig from "../../../../../../../config/config";
import classNames from "classnames";
import { breakpoints } from "../../../../../../../config/style/theme";

type Dataset = {
    data: number[];
    backgroundColor: string;
};

type Data = {
    labels: string[];
    datasets: Dataset[];
};

const MAX_PER_PAGE = 10;

type Steps = 1 | 10 | -1 | -10;

const Style = css({
    display: "flex",
    flexDirection: "column",
    "&__nav-container": {
        display: "flex",
        alignItems: "center",
        alignSelf: "center",
        color: appConfig.style.colors.color1,
        marginTop: "2.4rem",
    },
    "&__nav": {
        display: "flex",
        alignItems: "center",
        gap: "0.4rem",
        color: "#4D4D4D",
        "&.text": {
            marginInline: "2rem",
            marginTop: 3,
            justifyContent: "center",
            width: "13rem",
        },
        "*": {
            fontWeight: 700,
            fontSize: "2.5rem !important",
        },
    },
});

const PayoffBarChart = (props: BarChartProps) => {
    const [treshhold, setTreshhold] = useState<number>(0);
    const formatYAxisLabel = (value: string | number) => {
        const numericValue = parseFloat(value as string);
        return `${numericValue}M`;
    };

    useEffect(() => {
        setTreshhold(0);
    }, [props.data])

    const data: Data = {
        labels: props.data.labels,
        datasets: [
            {
                data: props.data.figures,
                backgroundColor: colors.primary,
            },
        ],
    };

    const chartOptions: ChartOptions<"bar"> = {
        layout: {
            padding: {
                top: 20,
            },
        },
        scales: {
            // x: {
            //     title: {
            //         display: true,
            //         text: props.xAxisLabel,
            //         color: "#000",
            //         font: {
            //             size: 14,
            //         },
            //     },
            // },
            x: {
                ticks: {
                    autoSkip: false,
                    maxRotation: 25,
                    minRotation: 0,

                }
            },
            y: {
                title: {
                    display: true,
                    text: props.yAxisLabel,
                    color: appConfig.style.colors.text4,
                    font: {
                        size: 14,
                        weight: "normal"
                    },
                },
                beginAtZero: true,
                suggestedMax: Math.max(...data.datasets[0].data.map(Number)) + 10,
                ticks: {
                    callback: formatYAxisLabel, // Format Y-axis labels using the formatYAxisLabel function
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                anchor: "end",
                align: "top",
            },
            title: {
                display: props.title ? true : false,
                text: props.title || "",
                font: {
                    size: 14,
                    weight: "normal",
                },
                color: appConfig.style.colors.text4,
                padding: { top: 0, bottom: 8 },
                align: "center",
            },
            tooltip: {
                displayColors: false,
                enabled: true,

                callbacks: {
                    label: (holder) => {
                        const { dataIndex } = holder;
                        const investmentMultiple = props.data.tooltips[dataIndex];
                        const existingDataLabel = `Payoff: ${holder.parsed.y}M`;
                        const additionalTooltipsLabel = `Investment Multiple: ${investmentMultiple}`;
                        return [existingDataLabel, additionalTooltipsLabel];
                    },
                },
            },
        },
    };

    // const test: Data = {
    //     labels: [...data.labels, ...data.labels, ...data.labels, ...data.labels, ...data.labels]
    //     datasets: [
    //         {
    //             ...data.datasets,
    //             data: [
    //                 ...data.datasets[0].data,
    //                 ...data.datasets[0].data,
    //                 ...data.datasets[0].data,
    //                 ...data.datasets[0].data,
    //                 ...data.datasets[0].data,
    //             ],
    //         },
    //     ],
    // };

    const currentSize = props.data.labels.length;

    const onTreshholdHandler = (step: Steps) => {
        if (currentSize < MAX_PER_PAGE) return;
        setTreshhold((prev) => {
            if (step === 1 && prev + step > currentSize - MAX_PER_PAGE) return prev;
            if (step === 10 && prev + step > currentSize - 10) return currentSize - 10;
            if (step === -10 && prev + step < 0) return 0;
            if (prev + step < 0 || prev + step > currentSize - 1) return prev;

            return prev + step;
        });
    };

    const getDataTosDisplay = (data: Data): Data => {
        return {
            labels: data.labels.slice(treshhold, treshhold + MAX_PER_PAGE),
            datasets: data.datasets.map((dataset) => ({
                ...dataset,
                borderRadius: 16,
                data: dataset.data.slice(treshhold, treshhold + MAX_PER_PAGE),
            })),
        };
    };

    return (
        <div className={Style}>
            <Bar
                data={getDataTosDisplay(data)}
                plugins={[ChartDataLabels as Plugin<"bar", AnyObject>]}
                options={chartOptions}
            />
            <div className={`${Style}__nav-container`}>
                <div className={`${Style}__nav`}>
                    <KeyboardDoubleArrowLeftIcon
                        onClick={() => onTreshholdHandler(-10)}
                        className={classNames({ disabled: treshhold === 0 || currentSize < MAX_PER_PAGE }, "pointer")}
                    />
                    <KeyboardArrowLeftIcon
                        onClick={() => onTreshholdHandler(-1)}
                        className={classNames({ disabled: treshhold === 0 || currentSize < MAX_PER_PAGE }, "pointer")}
                    />
                </div>
                <div className={`${Style}__nav text`}>
                    {treshhold + 1}-{currentSize < MAX_PER_PAGE ? currentSize : treshhold + MAX_PER_PAGE} of {currentSize}
                </div>
                <div className={`${Style}__nav`}>
                    <KeyboardArrowRightIcon
                        onClick={() => onTreshholdHandler(1)}
                        className={classNames(
                            {
                                disabled: treshhold === currentSize - MAX_PER_PAGE || currentSize < MAX_PER_PAGE,
                            },
                            "pointer"
                        )}
                    />
                    <KeyboardDoubleArrowRightIcon
                        onClick={() => onTreshholdHandler(10)}
                        className={classNames(
                            {
                                disabled: treshhold === currentSize - MAX_PER_PAGE || currentSize < MAX_PER_PAGE,
                            },
                            "pointer"
                        )}
                    />
                </div>
            </div>
        </div>
    );
};

export default PayoffBarChart;
