import { runInAction } from "mobx";

//Updates store observables using runInAction
export default class StoreUpdater<TStore> {
	constructor(private store: TStore) {}
	dispatcher() {
		return (newPropsValues: Partial<TStore>) => {
			runInAction(() => {
				for (var key in newPropsValues) {
					this.store[key as keyof TStore] = newPropsValues[key as keyof TStore] as any;
				}
			});
		};
	}
}
