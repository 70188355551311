import { useEffect, useMemo, useRef, useState } from 'react';
import useRootStore from '../../../Shared/Hooks/useRootStore';
import { useAppendState } from '../../../Shared/Hooks/useAppendState';
import { DataCollectionPayload } from '../../../Models/API/DataCollection/data-collection-payload';
import { DataCollectionComment, DataCollectionCommentFieldType } from '../../../Models/API/DataCollection/comment-response';
import { ForwardedRefType } from '../../../Shared/Hooks/useMultiStepForm';

type RefsType = Record<keyof DataCollectionPayload, ForwardedRefType<string | undefined>>;
type CommentsByQuestionType = Record<DataCollectionCommentFieldType, DataCollectionComment[]>;

const useDataCollection = () => {
	const { dataCollectionStore } = useRootStore();
	const [data, setData, onInputHandler] = useAppendState<DataCollectionPayload>();
	const [comments, setComments] = useState<DataCollectionComment[]>([]);
	const questionsRef = useRef<RefsType>({} as RefsType);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		(async () => {
			if (!dataCollectionStore.currentProjectId || data.valuationProjectId) return;
			const res = await dataCollectionStore.getDataCollection(dataCollectionStore.currentProjectId);

			if (!res.data?.data) return;
			const {
				aboutUs,
				capTableId,
				comments,
				companyProfitable,
				dataCollectionId,
				investmentExist,
				investmentType,
				issues,
				nextRoundValuation,
				valuationDate,
				timeToLiquidity,
				companyProfitableInFuture,
			} = res.data.data;
			setData({
				aboutUs,
				capTableId,
				companyProfitable,
				investmentExist,
				investmentType,
				nextRoundValuation,
				valuationDate,
				valuationProjectId: dataCollectionStore.currentProjectId,
				timeToLiquidity,
				companyProfitableInFuture,
			});
			setComments(comments);
		})();
	}, [dataCollectionStore.currentProjectId]);

	const commentsByQuestion = useMemo(() => {
		return comments.reduce(
			(acc, comment) => {
				acc[comment.fieldType].push(comment);
				return acc;
			},
			{
				[DataCollectionCommentFieldType.AboutUs]: [],
				[DataCollectionCommentFieldType.CapTable]: [],
				[DataCollectionCommentFieldType.CompanyProfitable]: [],
				[DataCollectionCommentFieldType.InvestmentExist]: [],
				[DataCollectionCommentFieldType.InvestmentType]: [],
				[DataCollectionCommentFieldType.NextRoundValuation]: [],
				[DataCollectionCommentFieldType.ValuationDate]: [],
			} as CommentsByQuestionType
		);
	}, [comments]);

	const getFieldTypeByKey = (key: keyof DataCollectionPayload) => {
		switch (key) {
			case 'aboutUs':
				return DataCollectionCommentFieldType.AboutUs;
			case 'companyProfitable':
				return DataCollectionCommentFieldType.CompanyProfitable;
			case 'investmentExist':
				return DataCollectionCommentFieldType.InvestmentExist;
			case 'investmentType':
				return DataCollectionCommentFieldType.InvestmentType;
			// case 'companyProfitableInFuture':
			// 	return DataCollectionCommentFieldType.CompanyProfitableInFuture;
			case 'nextRoundValuation':
				return DataCollectionCommentFieldType.NextRoundValuation;
			// case 'timeToLiquidity':
			// 	return DataCollectionCommentFieldType.TimeToLiquidity;
			case 'valuationDate':
				return DataCollectionCommentFieldType.ValuationDate;
			default:
				return null;
		}
	};

	return {
		commentsByQuestion,
		data,
		setData,
		onInputHandler,
		questionsRef,
		getFieldTypeByKey,
	};
};

export default useDataCollection;
