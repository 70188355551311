import React from 'react'
import Table, { TableColumn } from '../../../../Shared/Components/Table/Table'
import { observer } from 'mobx-react-lite'
import { TrusteeOrder } from '../../Types/trusteeTypes'
import { formatDate } from '../../../../Shared/Utilities'
import useRootStore from '../../../../Shared/Hooks/useRootStore'
import Button from '../../../../Shared/Components/Button/Button'
import { useHistory } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import PagedTable from '../../../../Shared/Components/Table/PagedTable/PagedTable'
import { trusteeOrderKeysMain } from '../../StaticData/tableTypes'

import AdditionalStyles from './AdditionalViews.module.css'

const TrusteeOrders = () => {

    const { trusteeStore } = useRootStore()
    const history = useHistory()

    const orderColumns: TableColumn<TrusteeOrder>[] = [
        { label: "Order number", name: "orderNumber" },
        { label: "Grant number", name: "grantNumber" },
        { label: "Employee number", name: "beneficiaryId" },
        { label: "Full name", name: "beneficiaryName" },
        {
            label: "Order type",
            name: "orderType",
            // format(val) {
            //     return orderTypes[val]?.label;
            // },
        },
        {
            label: "Order date",
            name: "orderDate",
            format(val) {
                return formatDate(val);
            },
        },
        { label: "Amount", name: "amount" },
        { label: "Price", name: "salePrice" },
    ]

    useEffectOnce(() => {
        if (!Array.isArray(trusteeStore.trusteeCancelations)) {
            trusteeStore.GetTrusteeCancelations()
        }
    })

    return (
        <div style={AdditionalStyles} className={AdditionalStyles.mainContainer}>
            <div className={AdditionalStyles.pageHeader}>
                <Button
                    qaid='trustee.orders.back'
                    label='Back'
                    inverse
                    cancel
                    onClick={() => history.push('/trustee')}
                />
            </div>

            {/* <Table
                columns={orderColumns}
                rows={trusteeStore.trusteeOrders}
            /> */}

            <div className={AdditionalStyles.pageBody}>
                <PagedTable
                    newDataReq={trusteeStore.GetTrusteeOrdersByParams}
                    tableData={trusteeStore.trusteeOrders.orders}
                    totalRows={trusteeStore.trusteeOrders.totalNumberOfOrders}
                    tableKeys={trusteeOrderKeysMain}
                    itemsInPage={5}
                />
            </div>
        </div>
    )
}

export default observer(TrusteeOrders)