import styled from "@emotion/styled";
import { StyledWidget } from "../../../shared/components/Widget/Widget.style";
import { breakpoints } from "../../../shared/dependencies/breakpoints";

export const StyledPostRoundWidget = styled(StyledWidget)`
	label: PostRoundWidget;
	grid-area: PostRound;
`;

export const PostRoundWidgetBody = styled.div`
	label: PostRoundWidgetBody;
	display: grid;
	align-items: center;
	justify-content: space-around;
	grid-template-areas: "ChartWrapper" "LegendWrapper" "ValuationWidget" "PPSWidget";
	height: 100%;

	@media (min-width: ${breakpoints.sm}) {
		grid-template-areas:
			"ChartWrapper LegendWrapper ValuationWidget"
			"ChartWrapper LegendWrapper PPSWidget";
	}
`;

export const ChartWrapper = styled.div`
	label: ChartWrapper;
	grid-area: ChartWrapper;
	justify-self: center;
`;

export const LegendWrapper = styled.div`
	grid-area: LegendWrapper;
	label: LegendWrapper;
	margin-bottom: 2rem;

	@media (min-width: ${breakpoints.sm}) {
		margin-bottom: 0;
	}
`;

export const ValuationWidgetWrapper = styled.div`
	label: ValuationWidgetWrapper;
	grid-area: ValuationWidget;
	align-self: flex-start;
`;

export const PPSWidgetWrapper = styled.div`
	label: PPSWidgetWrapper;
	grid-area: PPSWidget;
	align-self: flex-start;
`;
