import { css } from "@emotion/css";
import appConfig from "../../../../../../config/config";

const NewPlanStyle = css({
	".buttons .sswitches .switch": {
		display: "flex",
		flexDirection: "row",
	},

	".details": {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
	},

	".general-details": {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		paddingRight: "4rem",
		width: "100%",
	},

	".cancelation-details": {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		// width: '50%'
	},

	".pool-details": {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		width: "100%",
	},

	".input": {
		width: "20rem",
	},

	".sswitches": {
		border: `1px solid ${appConfig.style.colors.border1}`,
		borderRadius: "5px",
		padding: "1rem",
	},

	".switch": {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},

	".buttons": {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
	},
});

export default NewPlanStyle;
