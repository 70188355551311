import { css } from "@emotion/css";
import appConfig from "../../../../../../config/config";

const IntroStyle = css({
	label: "Intro",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	padding: "8rem 16.7rem",
	".title": {
		marginTop: "3rem",
		fontSize: "2.5rem",
		fontWeight: "700",
		color: appConfig.style.colors.color1,
		width: "fit-content",
	},

	".description": {
		color: appConfig.style.colors.background2,
		width: "fit-content",
		fontSize: "1.4rem",
	},

	".button": {
		marginTop: "6rem",
	},
});

export default IntroStyle;
