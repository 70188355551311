import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Dispatch, SetStateAction, useState } from "react";
import { IContact, IContactCreate, IContactDetails } from "../../../../Models/API/Contact/contact";
import Clickable from "../../../../Shared/Components/Clickable/Clickable";
import PhoneInput from "../../../../Shared/Components/Input/PhoneInput";
import TextInput from "../../../../Shared/Components/Input/TextInput";
import { AppendStateInputHandlerType } from "../../../../Shared/Hooks/useAppendState";
import { InputRefs, InputValidationRef } from "../../../../Shared/Hooks/useFormValidation";
import { ObjectValidationStatus } from "../../../../Shared/ObjectValidator";
import AddEditNewCompanyStyle from "../index.style";
import classNames from "classnames";
import Select from "../../../../Shared/Components/Select/Select";
import { roleOptions } from "../../../../Shared/Config";
import { countries } from "../../../../Shared/StaticData/countries";
import AutoCompleteInput from "../../../../Shared/Components/Input/AutoCompleteInput";
import { IC_USER_CIRCLE } from "../../../../Assets";
import ExpandToggle from "../../../../Shared/Components/ExpandToggle";

interface Errors extends Omit<ObjectValidationStatus<IContactCreate>, "contactDetails"> {
    contactDetails?: ObjectValidationStatus<IContactDetails> | null;
}

interface Props {
    contact: IContactCreate;
    onInputHandler: AppendStateInputHandlerType;
    setContact: Dispatch<SetStateAction<IContactCreate>>;
    formValidationState?: Errors;
    onSuggestionSelected?: (contact: IContact) => void;
    isViewMode?: boolean;
    disabled?: boolean;
    inputRefs: InputRefs<IContactCreate>;
    contactInputRefs?: InputRefs<IContactDetails>;
}

const ContactInfo = ({
    contact,
    inputRefs,
    onInputHandler,
    setContact,
    formValidationState,
    disabled,
    contactInputRefs,
    isViewMode,
}: Props) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    return (
        <div className={`${AddEditNewCompanyStyle}__contact-section`}>
            <div className={`${AddEditNewCompanyStyle}__sub-title`}>
                <img
                    src={IC_USER_CIRCLE}
                    alt="user"
                />
                Company contact info
            </div>
            <div className={classNames(`${AddEditNewCompanyStyle}__form`, { isOpen: isExpanded })}>
                <TextInput
                    label="First name"
                    qaid="ContactInfo.Input.FirstName"
                    onChange={onInputHandler}
                    value={contact.firstName}
                    name="firstName"
                    error={formValidationState?.firstName?.message}
                    disabled={disabled}
                    ref={(el: InputValidationRef) => (inputRefs.firstName = el)}
                    isViewMode={isViewMode}
                    required
                    containerStyle={{ gridArea: "1 / 1 / 2 / 2" }}
                />
                <TextInput
                    label="Last name"
                    qaid="ContactInfo.Input.LastName"
                    onChange={onInputHandler}
                    value={contact.lastName}
                    name="lastName"
                    error={formValidationState?.lastName?.message}
                    disabled={disabled}
                    ref={(el: InputValidationRef) => (inputRefs.lastName = el)}
                    isViewMode={isViewMode}
                    required
                    containerStyle={{ gridArea: "1 / 2 / 2 / 3" }}
                />
                <TextInput
                    label="Business email"
                    qaid="ContactInfo.Input.Email"
                    onChange={onInputHandler}
                    value={contact.email}
                    name="email"
                    error={formValidationState?.email?.message}
                    disabled={disabled}
                    ref={(el: InputValidationRef) => (inputRefs.email = el)}
                    isViewMode={isViewMode}
                    required
                    containerStyle={{ gridArea: "1 / 3 / 2 / 4" }}
                />

                <Select
                    label="Role type"
                    qaid="ContactInfo.Select.Role"
                    options={roleOptions}
                    value={contact.contactDetails?.role}
                    onChange={onInputHandler}
                    name="contactDetails.role"
                    error={formValidationState?.contactDetails?.role?.message}
                    ref={(el: InputValidationRef) => {
                        if (contactInputRefs) {
                            contactInputRefs.role = el;
                        }
                    }}
                    style={{ gridArea: "1 / 4 / 2 / 5" }}
                    disabled={disabled}
                    required
                />

                <Clickable
                    justify="start"
                    width="fit-content"
                    qaid="ContactInfo.Button.MoreInfo"
                    onClick={() => setIsExpanded(!isExpanded)}
                    gap="1.2rem"
                    style={{ gridArea: "2 / 1 / 3 / 4" }}
                >
                    Add more info (optional)
                    <ExpandToggle isOpen={isExpanded} />
                </Clickable>

                {isExpanded && (
                    <>
                        <PhoneInput
                            onChange={(mobile: string, mobilePrefix?: string) => {
                                setContact((userState) => ({
                                    ...userState,
                                    mobilePrefix: mobile ? mobilePrefix : undefined,
                                    mobile: mobile || undefined,
                                }));
                            }}
                            label="Mobile"
                            qaid="ContactInfo.Input.Mobile"
                            value={contact.mobile}
                            mobilePrefix={contact.mobilePrefix}
                            containerClassName="mb-0"
                            isViewMode={isViewMode}
                            disabled={disabled}
                            containerStyle={{ gridArea: "3 / 1 / 4 / 2" }}
                        />
                        <TextInput
                            label="ID"
                            qaid="ContactInfo.Input.ID"
                            value={contact.contactDetails?.idNumber}
                            onChange={onInputHandler}
                            name="contactDetails.idNumber"
                            containerClassName="mb-0"
                            isViewMode={isViewMode}
                            disabled={disabled}
                            containerStyle={{ gridArea: "3 / 2 / 4 / 3" }}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default ContactInfo;
