import { useEffect, useState } from 'react';
import GrantDetailsStyle from './index.style';
import useRootStore from '../../../../Shared/Hooks/useRootStore';
import { GrantDetails as GrantDetailsType } from '../../../../Models/API/Expensing/grant-details';
import { GrantOptionsPricing } from '../../../../Models/API/Expensing/options-pricing';
import Spinner from '../../../../Shared/Components/Spinner/Spinner';
import Image from '../../../../Shared/Components/Image';
import { IC_USER_PROFILE_PURPLE } from '../../../../Assets';
import Separator from '../../../../Shared/Components/Separator';
import TextInput from '../../../../Shared/Components/Input/TextInput';
import DatePickerInput from '../../../../Shared/Components/Input/DatePickerInput';
import NumberInput from '../../../../Shared/Components/Input/NumberInput';
import Select from '../../../../Shared/Components/Select/Select';
import { currencies, employeeStatusOptions } from '../../../../Shared/Config';
import { formatDecimal, isNumber } from '../../../../Shared/Utilities';
import Button from '../../../../Shared/Components/Button/Button';
import { ModalBodyProps } from '../../../../Shared/Components/Modal/types';
import { ExpensingAwardType } from '../../../../Models/API/Expensing/award-type';
import { Currencies } from '../../../../Models/API/enums';

type Props = ModalBodyProps & {
	grantId: number;
};

const GrantDetails = ({ grantId, removeModal }: Props) => {
	const { expensingStore } = useRootStore();
	const [data, setData] = useState<{ grantDetails: GrantDetailsType; optionPricing: GrantOptionsPricing }>();

	useEffect(() => {
		(async () => {
			const res = await expensingStore.getGrantDetails(grantId);
			if (res?.error) removeModal?.();
			else setData(res?.data?.data);
		})();
	}, [grantId]);

	if (!data) {
		return <Spinner />;
	}

	const { optionPricing, grantDetails } = data;

	const currency = currencies.find((c) => c.currencyId === optionPricing.convertedExercisePriceCurrency);
	const originalCurrency = currencies.find((c) => c.currencyId === Currencies[grantDetails.originalExercisePriceCurrency]);
	const isConverted = currency?.currencyId !== originalCurrency?.currencyId;

	return (
		<div className={GrantDetailsStyle}>
			<div className={`${GrantDetailsStyle}__header`}>
				<Image src={IC_USER_PROFILE_PURPLE} />
				<span className="beneficiary-name">{grantDetails.beneficiaryFullName}</span>
				<span className="grant-id">{grantDetails.grantNumber}</span>
			</div>
			<div className={`${GrantDetailsStyle}__grant-details`}>
				<span className="section-title">Grant Details</span>
				<div className="grant-form">
					<TextInput qaid="" label="Equity plan" value={grantDetails.equityPlanName} isViewMode />
					<TextInput qaid="" label="Award Type" value={ExpensingAwardType[grantDetails.awardType]} isViewMode />
					<DatePickerInput qaid="" label="Grant date" value={grantDetails.grantDate} isViewMode />
					<NumberInput qaid="" label="Amount granted" value={grantDetails.amountGranted} isViewMode />
					<DatePickerInput qaid="" label="Expiration date" value={grantDetails.expirationDate} isViewMode />
					<DatePickerInput qaid="" label="Vesting start date" value={grantDetails.vestingStartDate} isViewMode />
					<TextInput qaid="" label="Vesting schedule" value={grantDetails.vestingScheduleName} isViewMode />
					<NumberInput qaid="" label="Tax track" value={grantDetails.taxTrack} isViewMode />
					<TextInput qaid="" label="Department" value={grantDetails.vestingScheduleName} isViewMode />
					<Select
						qaid=""
						label="Employee status"
						options={employeeStatusOptions}
						value={grantDetails.employeeStatus}
						onChange={() => {}}
						isViewMode
					/>
					<TextInput qaid="" label="Site" value={grantDetails.site} onChange={() => {}} isViewMode />
					<DatePickerInput qaid="" label="Termination date" value={grantDetails.terminationDate} isViewMode />
				</div>
				<Separator />
			</div>
			<div className={`${GrantDetailsStyle}__bs-parameters`}>
				<span className="section-title">B&S parameters</span>
				<div className="bs-form">
					<TextInput
						qaid=""
						label="Fair market value"
						value={`${currency?.symbol}${formatDecimal(optionPricing.marketPrice, { decimalLength: 3 })}`}
						isViewMode
					/>
					<TextInput
						qaid=""
						label="Volatility"
						value={optionPricing.volatility ? formatDecimal(optionPricing.volatility * 100) + '%' : '-'}
						isViewMode
					/>
					<NumberInput qaid="" label="Expected life" value={optionPricing.expectedTerm || '-'} isViewMode />
					<TextInput
						qaid=""
						label="Risk free interest"
						value={optionPricing.volatility ? formatDecimal((optionPricing.riskFreeRate ?? 0) * 100) + '%' : '-'}
						isViewMode
					/>
					<TextInput
						qaid=""
						label="Exercise price"
						value={`${expensingStore.companyCurrency?.symbol}${formatDecimal(optionPricing.exercisePrice, { decimalLength: 3 })}${
							isConverted ? ` (${originalCurrency?.symbol}${formatDecimal(grantDetails.originalExercisePrice, { decimalLength: 3 })})` : ''
						}`}
						isViewMode
					/>
					<TextInput
						qaid=""
						label="B&S Value"
						value={`${currency?.symbol}${formatDecimal(optionPricing.fairValue, { decimalLength: 3 })}`}
						isViewMode
						containerClassName="bold"
					/>
				</div>
				<Separator />
			</div>

			<Button qaid="GrantDetails.Button.Done" label="Done" onClick={removeModal} position="end" />
		</div>
	);
};

export default GrantDetails;
