import styled from '@emotion/styled';
import { DataCollectionComment } from '../../../../../Models/API/DataCollection/comment-response';
import appConfig from '../../../../../config/config';
import { useRef, useState } from 'react';
import useRootStore from '../../../../../Shared/Hooks/useRootStore';

type Props = {
	comments: DataCollectionComment[];
};

const Container = styled.div({
	label: 'ChatContainer',
	display: 'flex',
	flexDirection: 'column',
	height: '100%',
});

const Header = styled.div({
	label: 'ChatHeader',
	display: 'flex',
	alignItems: 'center',
	width: '100%',
	flexBasis: '7rem',
	boxShadow: `0px 1px 4px 0px #0c0c0d24`,
	justifyContent: 'center',
	zIndex: 1,
	position: 'absolute',
	background: '#ffffff',
	top: 0,
	left: 0,
	height: '7rem',
});

const MessagesContainer = styled.div({
	label: 'MessagesContainer',
	flex: 1,
	padding: '2rem 3rem',
	paddingTop: '9rem',
	overflow: 'auto',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'flex-end',
	gap: '1rem',
});

const Message = styled.div({
	label: 'ChatMessage',
	background: appConfig.style.colors.background1,
	padding: '1.6rem 2.4rem',
	width: '60%',
	alignSelf: 'flex-end',
});

const Input = styled.textarea({
	label: 'ChatInput',
	borderRadius: 16,
	height: '7rem',
	outline: 'none',
	border: 'none',
	padding: '2rem 3rem',
	boxShadow: '0px 16px 32px -8px #000000db',
	maxHeight: '23rem',
});

const QuestionsHistory = ({ comments }: Props) => {
	const { appState } = useRootStore();
	const [msg, setMsg] = useState<string>();
	const inputRef = useRef<HTMLTextAreaElement>(null);

	return (
		<Container>
			<Header>altshare</Header>
			<MessagesContainer>
				{[
					...comments,
					...comments,
					...comments,
					...comments,
					...comments,
					...comments,
					...comments,
					...comments,
					...comments,
					...comments,
					...comments,
					...comments,
					...comments,
					...comments,
					...comments,
					...comments,
					...comments,
				].map((comment) => (
					<Message key={comment.id}>{comment.commentDescription}</Message>
				))}
			</MessagesContainer>
			<Input
				placeholder="Type your message..."
				ref={inputRef}
				value={msg}
				onChange={(e) => {
					setMsg(e.target.value);
					if (!inputRef.current) return;
					console.log(e.target.value.split('\n').length);
					inputRef.current.style.height = `calc(7rem + ${(e.target.value.split('\n').length - 1) * 2}rem)`;
				}}
			/>
		</Container>
	);
};
export default QuestionsHistory;
