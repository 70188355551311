import {
    CapTableData,
    CapTableProjectResponse,
    CapTableRequest,
    CreateProjectRequest,
    CreateProjectResponse,
    DataPlanResponse,
    PlanData,
    PreferenceShareClassRequest,
    PreferenceShareClassRight,
    ReviewersResponse,
    ScenarioRequest,
    ScenarioResponse,
    ShareClassRightIssueRequest,
    ShareClassRightRequest,
    ShareClassRightResponse,
    ShareClassRightsResponse,
    StepsResponse,
    ToggleReviewerRequest,
    UploadOptionsRequest,
    WaterfallAwardType,
    WaterfallDefaultParams,
    WaterfallProject,
} from "../Models/API/Waterfall/IForm";
import { IWaterfallSummaryData, SummaryStatus } from "../Screens/Waterfall/Components/AddEditWaterfall/Steps/Summary/deps/types";
import { waterfallApi } from "./Axios";

export class WaterfallService {
	private endPoints = {
		addPreferenceClassRight: '/preference_terms',
		createProject: 'project',
		deletePreferenceClassRight: '/preference_terms',
		deleteProject: (waterfallId: number) => `mainScreen/project/${waterfallId}`,
		getCapTableBases: (companyId: number) => `/captable/all/${companyId}`,
		getProject: (waterfallId: number) => `mainScreen/project/${waterfallId}`,
		getProjectReviewers: ({ companyId, waterfallId }: WaterfallDefaultParams) => `/project/users/${companyId}/${waterfallId}`,
		getProjects: (companyId: number) => `/mainScreen/projects/${companyId}`,
		getSavedCapTableBase: (companyId: number | string, waterfallId: number) => `captable/one/${companyId}/${waterfallId}`,
		getShareClassRight: ({ waterfallId, shareClassId }: WaterfallDefaultParams) => `/rights/shareclass/${waterfallId}/${shareClassId}`,
		getShareClassRights: (waterfallId: number) => `/rights/${waterfallId}`,
		getSingleCapTableData: '/captable',
		getIsCaptableChanged: ({ companyId, waterfallId }: WaterfallDefaultParams) => `/captable/wasCaptableUpdated/${companyId}/${waterfallId}`,
		getSummary: (waterfallId: number) => `/summary/${waterfallId}`,
		cancelSummary: (waterfallId: number) => `/summary/${waterfallId}`,
		runSummary: '/summary',
		checkSummary: '/summary/checkProject',
		getSummaryStatus: (waterfallId: number) => `/summary/status/${waterfallId}`,
		getTermsShareClassRights: (waterfallId: number) => `/preference_terms/${waterfallId}`,
		getUpdatePreferenceClassRights: ({ companyId, waterfallId }: WaterfallDefaultParams) => `/preference_terms/next/${companyId}/${waterfallId}`,
		updateLastModified: '/globalComponents',
		uploadOptions: '/options/uploadOptions',
		getOptions: (waterfallId: number) => `/options/getOptions/${waterfallId}`,
		updateOptionsMode: '/options/updateOptionMode',
		toggleProjectReviewer: '/project/addRemoveParticipant',
		updateClassRight: '/rights',
		updateScenarioStep: (waterfallId: number) => `project/update/${waterfallId}`,
		getSteps: (companyId: number, waterfallId: number) => `/globalComponents/getSteps/${companyId}/${waterfallId}`,
		getValuationDate: (waterfallId: number) => `/project/valuationDate/${waterfallId}`,
		duplicateProject: '/globalComponents/duplicate',
	};

	getSteps(payload: WaterfallDefaultParams) {
		return waterfallApi.get<StepsResponse>(this.endPoints.getSteps(payload.companyId, payload.waterfallId));
	}

	getProjects(companyId: number) {
		return waterfallApi.get<WaterfallProject[]>(this.endPoints.getProjects(companyId));
	}

	deleteProject(waterfallId: number) {
		// return new Promise((res, rej) => setTimeout(() => rej({ statusCode: 403, message: "Forbidden" }), 2000));
		return waterfallApi.delete<WaterfallProject[]>(this.endPoints.deleteProject(waterfallId));
	}

	duplicateProject(payload: CreateProjectRequest) {
		return waterfallApi.post<{ waterfallId: number }>(this.endPoints.duplicateProject, payload);
	}

	getProject(waterfallId: number) {
		return waterfallApi.get<ScenarioResponse>(this.endPoints.getProject(waterfallId));
	}

	updateLastModified(payload: WaterfallDefaultParams) {
		return waterfallApi.post(this.endPoints.updateLastModified, payload);
	}

	createProject(payload: CreateProjectRequest) {
		return waterfallApi.post<CreateProjectResponse>(this.endPoints.createProject, payload);
	}

	getSingleCapTableData(payload: CapTableRequest) {
		return waterfallApi.post<CapTableData>(this.endPoints.getSingleCapTableData, payload);
	}

	getProjectReviewers(payload: WaterfallDefaultParams) {
		return waterfallApi.get<ReviewersResponse>(this.endPoints.getProjectReviewers(payload));
	}

	updateScenarioStep(payload: ScenarioRequest) {
		return waterfallApi.post<CreateProjectResponse>(this.endPoints.updateScenarioStep(payload.waterfallId), payload);
	}

	toggleProjectReviewer(payload: ToggleReviewerRequest) {
		return waterfallApi.post<ReviewersResponse>(this.endPoints.toggleProjectReviewer, payload);
	}

	// step 2:
	getCapTableBases(companyId: number) {
		return waterfallApi.get<CapTableProjectResponse>(this.endPoints.getCapTableBases(companyId));
	}

	getSavedCapTableBase(companyId: number, waterfallId: number) {
		return waterfallApi.get<CapTableData>(this.endPoints.getSavedCapTableBase(companyId, waterfallId));
	}

	// step 3:

	uploadOptions(payload: UploadOptionsRequest) {
		let { isUnallocated, ...rst } = payload;
		return waterfallApi.post<DataPlanResponse>(this.endPoints.uploadOptions, {
			...rst,
			withUnallocated: isUnallocated ? 1 : 0,
		});
	}

	reimportOptions(companyId: number, waterfallId: number) {
		return waterfallApi.post<DataPlanResponse>('/options/reimport', { companyId, waterfallId });
	}

	getOptions = (waterfallId: number) => {
		return waterfallApi.get<DataPlanResponse | null>(this.endPoints.getOptions(waterfallId));
	};

	updateOptionsMode(payload: UploadOptionsRequest) {
		let { isUnallocated, ...rst } = payload;
		return waterfallApi.post<DataPlanResponse>(this.endPoints.updateOptionsMode, {
			...rst,
			withUnallocated: isUnallocated ? 1 : 0,
		});
	}

	getValuationDate(waterfallId: number) {
		return waterfallApi.get<string>(this.endPoints.getValuationDate(waterfallId));
	}

	addAwardType(payload: WaterfallAwardType, waterfallId: number) {
		return waterfallApi.post<DataPlanResponse>('/options/one', {
			...payload,
			waterfallId,
		});
	}

	updateAwardType(waterfallId: number, { shareClassId, ...data }: WaterfallAwardType) {
		return waterfallApi.patch<DataPlanResponse>('/options/one/' + shareClassId, { waterfallId, ...data });
	}

	deleteAwardType(waterfallId: number, shareClassId: number) {
		return waterfallApi.delete<unknown>('/options/one/' + shareClassId, { waterfallId });
	}

	// step 4

	getShareClassRights(waterfallId: number) {
		return waterfallApi.get<ShareClassRightsResponse>(this.endPoints.getShareClassRights(waterfallId));
	}

	getShareClassRight = async (payload: WaterfallDefaultParams) => {
		return waterfallApi.get<ShareClassRightResponse>(this.endPoints.getShareClassRight(payload));
	};

	updateClassRight(payload: ShareClassRightRequest | ShareClassRightIssueRequest) {
		return waterfallApi.patch(this.endPoints.updateClassRight, payload);
	}

	deleteClassRight({ shareClassId, waterfallId, companyId }: WaterfallDefaultParams) {
		return waterfallApi.delete(this.endPoints.getShareClassRights(shareClassId as number), {
			waterfallId,
			companyId,
		});
	}

	// step 5

	getPreferenceClassRights = async (waterfallId: number) => {
		return waterfallApi.get<PreferenceShareClassRight[]>(this.endPoints.getTermsShareClassRights(waterfallId));
	};

	addPreferenceClassRight = async (payload: PreferenceShareClassRequest) => {
		return waterfallApi.post<PreferenceShareClassRight>(this.endPoints.addPreferenceClassRight, payload);
	};

	deletePreferenceClassRight = async (payload: WaterfallDefaultParams) => {
		return waterfallApi.delete(this.endPoints.deletePreferenceClassRight, payload);
	};

	updatePreferenceClassRights = async (payload: WaterfallDefaultParams) => {
		return waterfallApi.get(this.endPoints.getUpdatePreferenceClassRights(payload));
	};

	// step 6
	runSummary(data: WaterfallDefaultParams) {
		return waterfallApi.post<IWaterfallSummaryData>(this.endPoints.runSummary, data);
	}

	// async GetWaterfallSummary(data: WaterfallDefaultParams): Promise<HigherLevelResponse<IWaterfallSummaryData>> {
	// getWaterfallSummary(data: WaterfallDefaultParams): Promise<IWaterfallSummaryData> {
	//     return waterfallApi.post(this.endPoints.checkSummary, data);
	// }

	getSummary(waterfallId: number) {
		return waterfallApi.get<IWaterfallSummaryData>(this.endPoints.getSummary(waterfallId));
	}

	cancelSummary(waterfallId: number) {
		return waterfallApi.delete(this.endPoints.cancelSummary(waterfallId));
	}

	getSummaryStatus(waterfallId: number) {
		// return new Promise((res) =>
		//     setTimeout(
		//         () =>
		//             res({
		//                 data: {
		//                     timeStamp: currentRunTimeDate,
		//                     status: SummaryStatusCode.RUNNING,
		//                     waterfallId,
		//                     estimatedTimeDTO: {
		//                         estimatedTime: 15000,
		//                         extraTime: 50000,
		//                     },
		//                 },
		//                 errorCode: undefined,
		//                 error: undefined,
		//             }),
		//         1000
		//     )
		// );
		return waterfallApi.get<SummaryStatus>(this.endPoints.getSummaryStatus(waterfallId));
	}
}
