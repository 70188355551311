import CapTableLoader from "../../Loaders/CapTableLoader";
import { useEffect, useRef, useState } from "react";
import SwitchSelectChartWidget from "../../../../Shared/Components/WidgetDashboard/CaptableDashboard/dependencies/components/SwitchSelectChartWidget/SwitchSelectChartWidget";
import ChartLegend from "../../../../Shared/Components/WidgetDashboard/shared/components/ChartLegend/ChartLegend";
import DoughnutChart from "../../../../Shared/Components/WidgetDashboard/shared/components/DoughnutChart/DoughnutChart";
import CircularSwitchToggle from "../../../../Shared/Components/CircularSwitchToggle/CircularSwitchToggle";
import {
	dashboardShareClassesColors,
	dashboardShareholderColors,
} from "../../../../Shared/Components/WidgetDashboard/shared/components/DoughnutChart/DoughnutChart.style";
import { CapTableStyle } from "./CapTable.style";
import Clickable from "../../../../Shared/Components/Clickable/Clickable";
import { IC_DASHBOARD_ARROW, IC_DASHBOARD_LOCK_CARD } from "../../../../Assets";
import { Routes } from "../../../../Routes";
import { useHistory } from "react-router-dom";
import Image from "../../../../Shared/Components/Image";
import { DashboardTypeItem, ICapTableDashboard } from "../../../../Models/API/CapTable/dashboard-cap-table";
import { isNullOrUndefined } from "../../../../Shared/Utilities";
import { ShareholderNameTypeEnum } from "../../../../Models/API/enums";
interface IProps {
	readonly data: ICapTableDashboard | null;
	readonly className?: string;
	readonly maxWidth?: string;
	readonly hasPermission: boolean;
	readonly publishedDate: Date | null;
}

const CapTable = (props: IProps) => {
	const history = useHistory();
	const cardRef = useRef<HTMLDivElement>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isShareClassesType, setIsShareClassesType] = useState<boolean>(false);

	useEffect(() => {
		if (!props.hasPermission) return;

		setIsLoading(isNullOrUndefined(props.data));
	}, [props.data]);

	const getShareholderNameType = (nameType: ShareholderNameTypeEnum) => {
		switch (nameType) {
			case ShareholderNameTypeEnum.Others:
				return "Others";
			case ShareholderNameTypeEnum.Options:
				return "Options";

			default:
				return "N/A";
		}
	};

	const labels = isShareClassesType
		? props.data?.dashboardShareClassesList.map((item: DashboardTypeItem) => item.shareClassName ?? "N/A")
		: props.data?.dashboardShareholderList.map((item) =>
				!item.firstName && !item.lastName ? getShareholderNameType(item.shareholderNameType) : `${item.firstName} ${item.lastName}`
		  );

	const figures = isShareClassesType
		? props.data?.dashboardShareClassesList.map((item: DashboardTypeItem) => Number(item.percent.toFixed(2)))
		: props.data?.dashboardShareholderList.map((item) => Number(item.totalPercent.toFixed(2)));

	const tooltipFigures = isShareClassesType
		? props.data?.dashboardShareClassesList.map((item: DashboardTypeItem) => Number(item.shares.toFixed(2)))
		: props.data?.dashboardShareholderList.map((item) => Number(item.totalShares.toFixed(2)));

	return (
		<div className={CapTableStyle}>
			{isLoading ? (
				<CapTableLoader />
			) : (
				<>
					<Clickable qaid="CapTable.Button.NavigateToCapTable" className="navArrow" onClick={() => props.hasPermission ? history.push(Routes.capTable.index):undefined}>
						{props.hasPermission ? (
							<Image className="navArrow__icon" src={IC_DASHBOARD_ARROW} tooltip="Navigate to Cap Table" alt="Navigate to Cap Table" />
						) : (
							<Image className="navArrow__icon" src={IC_DASHBOARD_LOCK_CARD} alt="Lock" />
						)}
					</Clickable>
					<SwitchSelectChartWidget
						className="chartContainer isStandalone"
						bodyClassName="chartContainer__body"
						legendChartNode={
							<ChartLegend
								labels={labels ?? []}
								figures={figures ?? []}
								colors={isShareClassesType ? dashboardShareClassesColors : dashboardShareholderColors}
							/>
						}
						doughnutChartNode={
							<DoughnutChart
								ap={{
									labels: labels ?? [],
									figures: figures ?? [],
									colors: isShareClassesType ? dashboardShareClassesColors : dashboardShareholderColors,
									className: "chartContainer__doughnut",
									tooltipFigures: tooltipFigures,
								}}
							/>
						}
						switchSelectNode={
							<CircularSwitchToggle
								value={isShareClassesType}
								actions={[
									{ value: false, label: "Shareholders" },
									{ value: true, label: "Share classes" },
								]}
								onChange={(value) => setIsShareClassesType(value)}
								type="secondary"
								size={"md"}
							/>
						}
						isNoData={
							!props.hasPermission || (props.data?.dashboardShareholderList.length === 0 && props.data?.dashboardShareClassesList.length === 0)
						}
						noDataContent={props.hasPermission ? undefined : "You need permission to access the Cap Table product"}
					/>
				</>
			)}
		</div>
	);
};

export default CapTable;
