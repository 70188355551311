import { useEffect, useState, ReactNode } from "react";
import Tooltip from "../Tooltip";
import { OverflowTextStyle } from "./OverflowText.Style";
import classNames from "classnames";

interface IProps {
    readonly children: ReactNode;
    readonly className?: string;
}

const OverflowText = (props: IProps) => {
    const [child, setChild] = useState<HTMLElement | null>(null);
    const [isOverflowing, setIsOverflowing] = useState(false);

    // Callback refs
    const childRefCallback = (node: HTMLElement | null) => setChild(node);

    useEffect(() => {
        if (child) {
            setIsOverflowing(child.offsetWidth < child.scrollWidth);
        }
    }, [child]);

    if (!props.children) return null;

    const handleTooltipText = () => {
        if (!isOverflowing) return "";
        if (props.children === "---------") return "";
        return props.children!.toString();
    };

    return (
        <Tooltip title={handleTooltipText()}>
            <div className={classNames(`${OverflowTextStyle}__text`, { [props.className || ""]: !!props.className })} ref={childRefCallback}>
                {props.children}
            </div>
        </Tooltip>
    );
};

export default OverflowText;
