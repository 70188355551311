export enum EditorMode {
	View = 1,
	Edit = 2,
}

export enum ContactTriggersEnum {
	"custom-pricing" = 1,
	"contact-us" = 3,
}

export enum FilterGrantEnum {
	planName,
	grantId,
	grantDate,
	grantNumber,
	grantType,
	granted,
	exercisePrice,
	vested,
	unvested,
	exercised,
	sellable,
	sold,
	canceled,
	outstanding,
	vestStartDate,
	vestSchedule,
	taxTrack,
	site,
	department,
	level,
}

export enum FilterTypeEnum {
	over,
	under,
	equals,
	contains,
}

export enum ConnectorEnum {
	and,
	or,
}

export enum KeyDownEnum {
	"Tab" = 9,
	"Enter" = 13,
	"Space" = 32,
	"ControlLeft" = 17,
	"ArrowDown" = 40,
	"ArrowUp" = 38,
}
