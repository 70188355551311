import useRootStore from "../../../../../Shared/Hooks/useRootStore";
import GrantsTableEdit from "./GrantsTableEdit";

const TableEdit = () => {
	const { equityPlansStore } = useRootStore();

	return equityPlansStore.importConfig.type === "Grants" ? <GrantsTableEdit /> : <div>test</div>;
};

export default TableEdit;
