import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import useRootStore from "../../Shared/Hooks/useRootStore";

export const ValidateEmployee = () => {
	const { auth } = useRootStore();
	const params = useParams<{ uniquekey: string }>();
	const history = useHistory();

	useEffect(() => {
		if (params.uniquekey) {
			(async () => {
				if (await auth.validateEmpFirstReg(params.uniquekey)) {
					auth.navToEmployeesWebsite();
				} else {
					history.push("/");
				}
			})();
		}
	}, []);

	return <></>;
};

export default ValidateEmployee;
