import { css } from "@emotion/css";
import { IContact, IContactCreate } from "../../../../../../Models/API/Contact/contact";
import appConfig from "../../../../../../config/config";
import Button from "../../../../../../Shared/Components/Button/Button";

const Style = css({
	label: "ContactSuggestion",
	display: "grid",
	gridTemplateColumns: "repeat(5, 1fr)",
	padding: "3rem 6rem 3rem 8rem",
	borderRadius: 16,
	border: `1px solid ${appConfig.style.colors.table}`,
	marginTop: "1.6rem",
	gap: "2.5rem",
	button: {
		placeSelf: "center",
		justifySelf: "flex-end",
	},
	"&__field": {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		overflow: "hidden",
		minWidth: "10rem",
		div: {
			width: "100%",
		},
		".label": {
			color: appConfig.style.colors.text2,
			marginBottom: "1.2rem",
		},
	},
});

interface Props {
	contact: IContact;
	onMerge: (contact: IContactCreate) => void;
	disabled?: boolean;
}

const ContactSuggestion = ({ contact, onMerge, disabled }: Props) => {
	return (
		<div className={Style}>
			<div className={`${Style}__field`}>
				<div className="label text-ellipsis">First name *</div>
				<div className="text-ellipsis">{contact.firstName}</div>
			</div>
			<div className={`${Style}__field`}>
				<div className="label text-ellipsis">Last name *</div>
				<div className="text-ellipsis">{contact.lastName}</div>
			</div>
			<div className={`${Style}__field`}>
				<div className="label text-ellipsis">Company email *</div>
				<div className="text-ellipsis">{contact.email}</div>
			</div>
			<div className={`${Style}__field`}>
				<div className="label text-ellipsis">Employee number</div>
				<div className="text-ellipsis">{contact.contactDetails?.employeeNumber}</div>
			</div>
			<Button qaid="NewContactCard.Button.Merge" label="Merge" onClick={() => onMerge(contact)} disabled={disabled} autoWidth />
		</div>
	);
};

export default ContactSuggestion;
