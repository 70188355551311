import { css } from "@emotion/css";
import { breakpoints } from "../../../../../config/style/theme";
import appConfig from "../../../../../config/config";

export const ChartStyle = css({
    label: "Chart",
    borderLeft: `3px solid ${appConfig.style.colors.table}`,

    ".complexConnector": {
        position: "absolute",
        top: "-15%",
        alignItems: "start",
        width: "100%",
        height: "calc(65% + 1.57rem)",

        "&__left": {
            left: "82%",
            width: "100%",
            borderTop: `3px solid ${appConfig.style.colors.table}`,
            borderRight: `3px solid ${appConfig.style.colors.table}`,
            borderLeft: `3px solid ${appConfig.style.colors.table}`,
            borderRadius: "10px 10px 0 0",
        },

        "&__right": {
            left: "182%",
            width: "19%",
            borderBottom: `3px solid ${appConfig.style.colors.table}`,
        },
    },

    ".infoContainer": {
        paddingLeft: "1.2rem",

        "&__color": {
            width: "1.6rem",
            height: "1.6rem",
            borderRadius: "5px",
        },

        "&__name": {
            color: "#4D4D4D",
            fontSize: "1.6rem",
            whiteSpace: "nowrap",
        },

        "&__totalAmount": {
            fontSize: "1.8rem",
            fontWeight: "bold",
        },
    },
    ".chartLabel": {
        color: "#4D4D4D",
        margin: "0",
        whiteSpace: "nowrap",
    },
    ".chartsContainer": {
        paddingLeft: "1.2rem",

        "&__amount": {
            height: "2.4rem",
            borderRadius: "5px",
            boxShadow: "1px 1px 1px 0px rgba(217, 217, 217, 0.50)",
            maxWidth: "73%",
            zIndex: 1,
        },

        "&__nextElementConnector": {
            position: "absolute",
            height: "3px",
            width: "100%",
            color: appConfig.style.colors.table,
            backgroundColor: appConfig.style.colors.table,
        },
    },

    [`@media screen and (max-width: ${breakpoints.lg})`]: {
        ".infoContainer": {
            "&__name": {
                letterSpacing: "0.1rem",
                fontSize: "1.8rem",
            },

            "&__totalAmount": {
                fontSize: "2rem",
            },
        },

        ".chartLabel": {
            fontSize: "1.6rem",
        },
    },
});
