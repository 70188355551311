import { css } from "@emotion/css";
import appConfig from "../../../../../config/config";

const ReportSettingsStyle = css({
    label: "ReportSettingsStyle",
    "&__label": {
        fontWeight: 500,
        color: appConfig.style.colors.text1,
    },
    "&__section": {
        display: "flex",
        flexDirection: "column",
    },
    "&__form": {
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gridGap: "2.4rem",
    },
});

export default ReportSettingsStyle;
