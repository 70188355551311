import { css } from '@emotion/css';
import { useHistory } from 'react-router-dom';
import { IC_LIMITATION, IC_LIMITATION_ADMIN } from '../../Assets';
import { LimitCodesEnum } from '../../Models/App/PaymentModels';
import { Routes } from '../../Routes';
import useGeneralModal from '../Hooks/useGeneralModal';
import Button from './Button/Button';
import Image from './Image';
import Flex from './Layout/Flex';
import Title from './Layout/Title';
import { ModalBodyProps } from './Modal/types';

const Style = css({
	label: 'Limitation',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',

	'.icon': {
		marginBottom: '7rem',
	},

	'.subTitle': {
		margin: '0.7rem 0 3rem 0',
		color: '#4D4D4D',
		textAlign: 'center',
	},
});

interface IProps extends ModalBodyProps {
	admin:
		| true
		| {
				fullName: string;
				email: string;
		  };
	limitCode: LimitCodesEnum | null;
}

const Limitation = ({ removeModal, admin, limitCode }: IProps) => {
	const { showAddStakeholders } = useGeneralModal();
	const history = useHistory();

	const isStakeholders = limitCode === LimitCodesEnum.Stakeholders;

	return (
		<div className={Style}>
			<Image width="13.5rem" className="icon" src={IC_LIMITATION_ADMIN} />
			<Title type="secondary">You've reached the plan limit</Title>
			{admin === true ? (
				<>
					<span className="subTitle">
						Would you like to upgrade your plan {isStakeholders ? 'or alternatively purchase extra stakeholders package' : ''}?
					</span>
					<Flex gap={40}>
						{isStakeholders ? (
							<Button
								cancel
								label="Add stakeholders"
								qaid="Limitation.Button.AddStakeholders"
								onClick={() => {
									removeModal?.();
									showAddStakeholders();
								}}
							/>
						) : (
							<Button cancel label="Not now" qaid="Limitation.Button.NotNow" onClick={removeModal} />
						)}
						<Button label="Upgrade now" qaid="Limitation.Button.Upgrade" onClick={() => history.push(Routes.payment.pricing)} />
					</Flex>
				</>
			) : (
				<span className="subTitle">
					Please refer to {admin.fullName}, ({admin.email}) to upgrade your plan
				</span>
			)}
		</div>
	);
};
export default Limitation;
