import { toJS } from "mobx";
import { IOrder } from "../../../../Models/App/EquityPlans/Order";
import Button from "../../../../Shared/Components/Button/Button";
import Table, { TableColumn } from "../../../../Shared/Components/Table/Table";
import useModal from "../../../../Shared/Hooks/useModal";
import SectionStyles from "../../General/styles/SectionStyles";
import { ReactElement, useEffect, useState } from "react";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import { observer } from "mobx-react-lite";
import { IGrantCancelation, IGrantCancelationTable } from "../../../../Models/App/EquityPlans/GrantCancelations";
import NewCancelation from "./NewGrantCancelation/NewGrantCancelation";
import { cancelationReason, cancelationType } from "../../../../Shared/StaticData/equityPlans";
import CancelationCard from "./CancelationCard";
import { useHistory } from "react-router-dom";
import Flex from "../../../../Shared/Components/Layout/Flex";
import AddButton from "../../../../Shared/Components/Button/AddButton";
import useEsopModals from "../../useEsopModals";
import { formatDate } from "../../../../Shared/Utilities";
interface CancelationProps {
    header: ReactElement;
}

const Cancelations = ({ header }: CancelationProps) => {
    const history = useHistory();

    const { equityPlansStore } = useRootStore();
    const { showModal } = useModal();
    const { showCancelationModal } = useEsopModals();

    const [tableRows, setTableRows] = useState<IGrantCancelationTable[]>([]);

    useEffect(() => {
        equityPlansStore.GetGrantCancelationsForCompany();
    }, []);

    useEffect(() => {
        equityPlansStore.GetGrantCancelationsForCompany();
    }, [equityPlansStore.dataAsOfDate, equityPlansStore.selectedPlan]);

    const tableColumns: TableColumn<IGrantCancelation>[] = [
        { label: "Cancelation number", name: "grantCancelationId" },
        { label: "Employee number", name: "employeeNumber" },
        {
            label: "Full name",
            name: "fullName",
            format(val) {
                return `${val.firstName} ${val.lastName}`;
            },
        },
        { label: "Grant number", name: "grantNumber" },
        {
            label: "Cancel type",
            name: "cancelType",
            format(val, obj) {
                if (!obj) return;
                return cancelationType.filter((ctyp) => obj.cancelType === ctyp.value)[0].label;
            },
        },
        {
            label: "Reason",
            name: "cancelationReason",
            format(val, obj) {
                if (!obj) return;
                return cancelationReason.filter((ctyp) => +obj.cancelationReason === ctyp.value)[0].label;
            },
        },
        {
            label: "Date",
            name: "cancelationDate",
            format(val) {
                return formatDate(val);
            },
        },
        { label: "Amount", name: "amountToCancel" },
    ];

    const newCancelationHandler = () => {
        showModal({
            title: "Create New Cancelation Order",
            body: <NewCancelation showBen={true} showGrant />,
            width: "60%",
        });
    };

    const openCancelationModal = (row: IGrantCancelation) => {
        showModal({
            title: `Order # ${row?.grantCancelationId}`,
            body: <CancelationCard />,
            height: "100%",
            width: "70%",
            isMust: true,
        });
    };

    return (
        <div className={SectionStyles}>
            <div className="section-table">
                <Table
                    customHeaderRender={header}
                    columns={tableColumns}
                    rows={equityPlansStore.companyCancelations}
                    scrollAfterRows={13}
                    showTotal={false}
                    filterBy={tableColumns.filter((col) => col.name !== "actions").map((col) => col.name as keyof IGrantCancelation)}
                    searchBy={["amountToCancel"]}
                    exportToExcel={{ fileName: "Cancelations", sheetName: "Cancelations" }}
                    fullscreen
                    renderBottom={
                        <Flex align="center" justify="start">
                            <AddButton onClick={() => showCancelationModal(true, true)} qaid="" label="Add new grant cancelation" />
                        </Flex>
                    }
                />
            </div>
        </div>
    );
};

export default observer(Cancelations);
