import { css } from "@emotion/css";
import appConfig from "../../../../config/config";
import { breakpoints } from "../../../../config/style/theme";
import styled from "@emotion/styled";
import { StyledWidgetDashboard } from "../../../../Shared/Components/WidgetDashboard/shared/components/WidgetDashboard/WidgetDashboard.style";
import DashboardCard from "../../../../Shared/Components/Dashboard/DashboardCard";

export const ExpensingDashboardStyle = css({
    label: "EquityDashboard",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    "&__table-container": {
        padding: "4rem",
    },
    "&__chart-container": {
        display: "flex",
        gap: "6rem",
        width: "100%",

        [`@media screen and (max-width: ${breakpoints.lg})`]: {
            gap: "3rem",
        },
        ".chart-part": {
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            alignItems: "center",
        },
        ".labels-part": {
            paddingBottom: "0.8rem",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            overflow: "auto",
            maxWidth: "60rem",
            "&.expense": {
                alignSelf: "center",
                marginTop: "4.5rem",
            },
            ".dash-title": {
                fontWeight: 700,
            },
            ".label-top": {
                borderBottom: `1px solid ${appConfig.style.colors.table}`,
                paddingBottom: "1.6rem",
                marginBottom: "2rem",
            },
        },
    },
    ".published": {
        color: appConfig.style.colors.orange,
    },
});

const DOUGNUT_CHART_WIDGET_WIDTH_XL = "75.6rem";

const Wrapper = styled(StyledWidgetDashboard)`
    label: ExpensingDashboard;
    grid-template-areas:
        "Chart Chart"
        "Top Top"
        "BottomLeft BottomRight";
    grid-template-rows: repeat(2, 1fr);
    grid-template-rows: 2fr 1fr 1fr;
    // @media (min-width: ${breakpoints.sm}) {
    //     grid-template-areas:
    //         "Chart Chart"
    //         "Top Top"
    //         "BottomLeft BottomRight";
    //     grid-template-columns: repeat(2, 1fr);
    //     grid-template-rows: 2fr 1fr 1fr;
    // }

    @media (min-width: ${breakpoints.md}) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: repeat(2, 1fr);
        grid-template-areas:
            "Chart Top Top"
            "Chart BottomLeft BottomRight";
    }

    @media (min-width: ${breakpoints.lg}) {
        grid-template-columns: 1.4fr 1fr 1fr;
    }

    // @media (min-width: ${breakpoints.xl}) {
    //     grid-template-columns: ${DOUGNUT_CHART_WIDGET_WIDTH_XL} 1fr 1fr;
    // }
`;

const ChartWidgetWrapper = styled(DashboardCard)`
    label: ChartWidget;
    grid-area: Chart;
`;

const BottomLeftWidgetWrapper = styled(DashboardCard)`
    label: BottomLeft;
    grid-area: BottomLeft;
`;

const BottomRightWidgetWrapper = styled(DashboardCard)`
    label: BottomRight;
    grid-area: BottomRight;
`;

const TopWidgetWrapper = styled(DashboardCard)`
    label: Top;
    grid-area: Top;
`;

export const ExpensingDashboardComponents = {
    Wrapper,
    ChartWidgetWrapper,
    BottomRightWidgetWrapper,
    BottomLeftWidgetWrapper,
    TopWidgetWrapper,
};
