import { useGoogleLogin } from "@react-oauth/google";
import classNames from "classnames";
import { SyntheticEvent, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { IC_G_GOOGLE, IC_REG_PROG_1 } from "../../../../Assets";
import { ExternalAuthEnum, UserStatus } from "../../../../Models/API/enums";
import { RegUser } from "../../../../Models/App/Auth/RegUser";
import { Routes } from "../../../../Routes";
import Button from "../../../../Shared/Components/Button/Button";
import CheckBox from "../../../../Shared/Components/CheckBox/CheckBox";
import Clickable from "../../../../Shared/Components/Clickable/Clickable";
import ErrorMessage from "../../../../Shared/Components/Layout/ErrorMessage";
import Flex from "../../../../Shared/Components/Layout/Flex";
import TextInput from "../../../../Shared/Components/TextInput";
import useComplexState from "../../../../Shared/Hooks/useComplexState";
import useModal from "../../../../Shared/Hooks/useModal";
import { useObjectValidation } from "../../../../Shared/Hooks/useObjectValidation";
import useRecaptcha from "../../../../Shared/Hooks/useRecaptcha";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import useScreenSize from "../../../../Shared/Hooks/useScreenSize";
import { commonValidators } from "../../../../Shared/ObjectValidator";
import { isNullOrUndefined } from "../../../../Shared/Utilities";
import VerifyMailMsg from "./VerifyMailMsg";

interface IProps {
    readonly navToAccountLogin: () => void;
}

export const AccountCreateFirstPhase = (props: IProps) => {
    const { auth, appState } = useRootStore();
    const { isMobile } = useScreenSize();

    const { showModal, clearModals } = useModal();

    const history = useHistory();
    const [userData, setUserData] = useComplexState<RegUser>({ isMarketingSubscription: true, isTermsOfUse: false });
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSubmittedGoogle, setIsSubmittedGoogle] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingGoogle, setIsLoadingGoogle] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<JSX.Element | null>(null);
    const [googleError, setGoogleError] = useState<string>();
    const { execute } = useRecaptcha();

    const [isValidUser, userValState, _, setValidationState] = useObjectValidation(userData, {
        email: [commonValidators.required(), commonValidators.emailValidator],
        isTermsOfUse: [commonValidators.required("Approval of Privacy Policy and Terms & Conditions is mandatory")],
    });

    const errorMessageUserAleadyRegister = (
        <div
            className="text-danger"
            style={{ width: "100%", textAlign: "left" }}
        >
            This email is already in use, please&nbsp;
            <Link
                to="#"
                className="account-link"
                onClick={props.navToAccountLogin}
            >
                log in
            </Link>
        </div>
    );

    const submitReg = async (e: any, withGoogle: boolean = false) => {
        e.preventDefault();
        if (withGoogle) {
            setIsSubmittedGoogle(true);

            if (userValState?.isTermsOfUse?.isValid) {
                login();
            }

            return;
        }
        await registration();
    };

    const login = useGoogleLogin({
        onSuccess: async (codeResponse) => {
            registrationGoogle(codeResponse.access_token);
        },
        onError: (error) => console.log("Login Failed:", error),
        hint: "",
        prompt: "select_account",
    });

    const registration = async () => {
        setIsSubmitted(true);
        setIsLoading(true);

        if (!isValidUser) {
            setIsLoading(false);

            return;
        }
        await execute(async (token) => {
            const res = await auth.userCreateAccountFirstPhase({
                email: userData.email!,
                isTermsOfUse: userData.isTermsOfUse!,
                recaptcha: token,
                externalAuthId: null,
                externalAuth: ExternalAuthEnum.None,
                isMarketingSubscription: userData.isMarketingSubscription,
            });

            if (res.isSuccess && res.data.IsExternalAuthId) {
                setValidationState({
                    email: { message: "Using a Gmail address? Continue with Google below", isValid: false },
                });

                setIsLoading(false);
                return;
            }

            if (res.isSuccess && res.data.status === UserStatus.PendingForAccountSetup) {
                showModal({
                    body: <VerifyMailMsg email={userData.email!} />,
                    isMust: true,
                    maxWidth: "100%",
                    width: "80rem",
                });
            } else if (res.data?.status) {
                setErrorMsg(errorMessageUserAleadyRegister);
            } else {
                setErrorMsg(<span>{res.error}</span>);
            }
        });

        setIsLoading(false);
    };

    const registrationGoogle = async (accessToken?: string) => {
        execute(async (token) => {
            setIsLoadingGoogle(true);

            const res = await auth.userCreateAccountFirstPhase({
                email: null,
                isTermsOfUse: userData.isTermsOfUse!,
                isMarketingSubscription: userData.isMarketingSubscription,
                recaptcha: token,
                externalAuthId: accessToken ?? null,
                externalAuth: ExternalAuthEnum.google,
            });

            if (res.isSuccess && res.data.status === UserStatus.PendingForAccountSetup) {
                history.push(Routes.account.accountSetup + "/gsign");
            } else if (res.data?.status) {
                setErrorMsg(errorMessageUserAleadyRegister);
            } else {
                setGoogleError(res.error);
            }

            setIsLoadingGoogle(false);
        });
    };

    return (
        <section
            className={classNames({
                "form-account-box": true,
                mobile: isMobile,
            })}
        >
            <div className={"card-container"}>
                <div
                    className={classNames({
                        "form-header": true,
                        mobile: isMobile,
                    })}
                >
                    <div className="form-progress">
                        <img
                            src={IC_REG_PROG_1}
                            alt="progress logo"
                        />
                    </div>
                    <div className={classNames("form-title", { mobile: isMobile })}>Create your free Company account</div>
                    <div className={classNames("form-sub-title", { mobile: isMobile })}>No credit card required</div>
                </div>
                <div
                    className={classNames({
                        "create-account-form": true,
                        mobile: isMobile,
                    })}
                >
                    <form onSubmit={submitReg}>
                        <div className="loginFields">
                            <div className="login-row">
                                <Flex
                                    className="form-input-group required"
                                    position="relative"
                                >
                                    <TextInput
                                        className={classNames({
                                            "account-input": true,
                                            mobile: isMobile,
                                        })}
                                        placeholder="Business e-mail *"
                                        qaid="AccountCreateFirstPhase.Input.BusinessEmail"
                                        value={userData.email}
                                        hasError={isSubmitted && !userValState?.email?.isValid}
                                        onChange={(e) => {
                                            setErrorMsg(null);
                                            setUserData({ email: e });
                                        }}
                                    />

                                    <div className="form-error">
                                        {(isSubmitted && userValState?.email?.message) ||
                                            (isSubmitted && userValState?.isTermsOfUse?.message)}
                                    </div>
                                    <ErrorMessage
                                        top={"5.5rem"}
                                        left={"0.8rem"}
                                    >
                                        {errorMsg}
                                    </ErrorMessage>
                                </Flex>
                            </div>
                            <Flex
                                margin="4rem 0 0 0"
                                gap="1.6rem"
                                direction="column"
                                align="start"
                            >
                                <CheckBox
                                    isChecked={userData.isTermsOfUse}
                                    onClick={() => {
                                        setUserData({ isTermsOfUse: !userData.isTermsOfUse });
                                        setIsSubmitted(false);
                                        setIsSubmittedGoogle(false);
                                    }}
                                    size="2.7rem"
                                    label={() => (
                                        <span>
                                            I accept altshare’s{" "}
                                            <a
                                                key="privacy"
                                                className="terms-link"
                                                onClick={(e: SyntheticEvent) => e.stopPropagation()}
                                                href={Routes.privacy}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Privacy Policy
                                            </a>{" "}
                                            and{" "}
                                            <a
                                                key="tos"
                                                className="terms-link"
                                                onClick={(e: SyntheticEvent) => e.stopPropagation()}
                                                href={Routes.tos}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Terms & Conditions
                                            </a>
                                        </span>
                                    )}
                                    qaid="AccountCreateFirstPhase.CheckBox.PrivacyPolicy"
                                    inputClassName="terms-cb-input"
                                    className={classNames("terms-cb", { mobile: isMobile })}
                                />
                                <CheckBox
                                    isChecked={userData.isMarketingSubscription}
                                    size="2.7rem"
                                    onClick={(e) => {
                                        setUserData({ isMarketingSubscription: !userData.isMarketingSubscription });
                                    }}
                                    label={"I agree to receive marketing communications from altshare"}
                                    qaid="AccountCreateFirstPhase.CheckBox.MarketingSubscriptions"
                                    inputClassName="terms-cb-input"
                                    className={classNames("terms-cb", { mobile: isMobile }, "hide")}
                                />
                            </Flex>

                            <div className="buttons">
                                <Button
                                    qaid="AccountCreateFirstPhase.Button.SignUp"
                                    onClick={submitReg}
                                    isLoading={isLoading}
                                    position="center"
                                    className={classNames({
                                        "account-orange-butt": true,
                                        "first-phase": true,
                                        mobile: isMobile,
                                    })}
                                >
                                    Continue
                                </Button>
                            </div>
                        </div>
                    </form>
                    <div className="form-or-section">
                        <div className="or-sign">Or</div>
                        <div className="goog-sign">
                            <Button
                                qaid="AccountCreateFirstPhase.Button.SignUp"
                                onClick={(e) => submitReg(e, true)}
                                isLoading={isLoadingGoogle}
                                position="center"
                                className={classNames({
                                    "g-butt": true,
                                    mobile: isMobile,
                                })}
                            >
                                <img
                                    className="ic"
                                    src={IC_G_GOOGLE}
                                    alt=""
                                />
                                &nbsp; Continue with Google
                            </Button>
                            {appState.isDev && (
                                <Button
                                    className={classNames({
                                        "g-butt": true,
                                        mobile: isMobile,
                                    })}
                                    style={{ marginTop: "2rem" }}
                                    onClick={async (e) => {
                                        setUserData({
                                            email: "altshare.test+" + Math.round(Math.random() * 10000) + "@gmail.com",
                                            isTermsOfUse: true,
                                        });
                                    }}
                                    qaid={""}
                                >
                                    Fill data (dev only)
                                </Button>
                            )}
                        </div>
                    </div>
                    <Flex position="relative">
                        {isSubmittedGoogle && (
                            <ErrorMessage
                                width="100%"
                                top={"1rem"}
                            >
                                {userValState?.isTermsOfUse?.message}
                            </ErrorMessage>
                        )}
                        <ErrorMessage
                            top={"1rem"}
                            width="100%"
                        >
                            {googleError}
                        </ErrorMessage>
                    </Flex>
                </div>
                {/* <div className="continue-msg">
					By clicking on continue, you agree to our{" "}
					<a className="account-link" href="/generalDocuments/TermsOfServiceV1.pdf" target="_blank">
						Terms of use
					</a>
					&nbsp;and&nbsp;
					<a className="account-link" href="/generalDocuments/PrivacyPolicyV1.pdf" target="_blank">
						Privacy Policy
					</a>
				</div> tbd daniel please put this herf in labels of checkboxes */}
            </div>
            <div>
                <div className="account-footer">
                    <div>Already have an account?&nbsp;</div>
                    <div>
                        <Clickable
                            className="account-link"
                            qaid="AccountPage.link.CreateNewUser"
                            onClick={props.navToAccountLogin}
                        >
                            log in
                        </Clickable>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AccountCreateFirstPhase;
