import { NumberOption } from "../All/NumberOption";

export enum SigninRuleEnum {
    Parallel,
    Sequential,
}

export const signinRuleOptions: NumberOption[] = [
    {
        label: "Sequential",
        value: SigninRuleEnum.Sequential,
    },
    {
        label: "Parallel",
        value: SigninRuleEnum.Parallel,
    },
];
