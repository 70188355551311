import { ServerResponseApi } from "../Models/API/All/server-response";
import { AddPeerRequest } from "../Models/API/Expensing/add-peer-request";
import { ExpensingCompany } from "../Models/API/Expensing/company";
import { ExpensingDashboardData } from "../Models/API/Expensing/dashboard-data";
import { ExpenseReport, ExpenseReportStatus } from "../Models/API/Expensing/expense-report";
import { ExportExcelResponse } from '../Models/API/Expensing/export-excel-response';
import { Grant } from "../Models/API/Expensing/grant";
import { GrantCalculation } from "../Models/API/Expensing/grant-calculation";
import { GrantDetails } from "../Models/API/Expensing/grant-details";
import { GrantForfeitureRatePayload } from "../Models/API/Expensing/grant-forfeiture-rate-payload";
import { GrantInfo } from "../Models/API/Expensing/grant-info";
import { GrantReport, GrantReportPayload } from "../Models/API/Expensing/grant-report";
import { GrantInfoResponse, GrantReportResponse } from '../Models/API/Expensing/grant-report-response';
import { GrantOptionsPricing, OptionsPricing } from '../Models/API/Expensing/options-pricing';
import { GlobalPeer, Peer } from '../Models/API/Expensing/peer';
import { TogglePeerStatusData } from '../Models/API/Expensing/toggle-peer-status-request';
import { UnderlyingAssetPrice } from '../Models/API/Expensing/underlying-asset-price';
import { UpdatePeerRequest } from '../Models/API/Expensing/update-peer-request';
import { EditableUnderlyingAssetPrice } from '../Models/App/Expensing/editable-underlying-asset-price';
import { OutstandinAwards } from '../Models/App/Expensing/outstanding-awards-response';
import { DateFormatsEnum, formatDate } from '../Shared/Utilities';
import { ExpensingBasicCompany } from './../Models/API/Expensing/options-migration';
import { expensingApi } from './Axios';

export class ExpensingService {
	private companyId: number = 0;

	constructor(companyId: number) {
		this.companyId = companyId;
	}

	// Expensing

	createReport = (data: GrantReportPayload) => {
		return expensingApi.post<ServerResponseApi<GrantReportResponse>>('/Expensing/ExpenseGrantReport', data);
	};

	updateReport = (data: GrantReportPayload | null, expenseCompanyReportId: number) => {
		return expensingApi.put<ServerResponseApi<GrantReportResponse>>('/Expensing/ExpenseGrantReport', data, {
			expenseCompanyReportId,
		});
	};

	getExpenseReports = () => {
		return expensingApi.get<ServerResponseApi<ExpenseReport[]>>('/Expensing/ExpenseCompanyReport', {
			companyAppId: this.companyId,
		});
	};

	getExportToExcel = (expenseCompanyReportId: number) => {
		return expensingApi.get<ServerResponseApi<ExportExcelResponse>>(`/Expensing/ExpenseCompanyReport/${expenseCompanyReportId}/Excel`);
	};

	getDashboardData = (expenseCompanyReportId: number) => {
		return expensingApi.get<ServerResponseApi<ExpensingDashboardData>>('/Expensing/Dashboard', { expenseCompanyReportId });
	};

	getGrantsReport = (expenseCompanyReportId: number) => {
		return expensingApi.get<ServerResponseApi<GrantInfoResponse>>('/Expensing/ExpenseGrantReport/ExpenseCompanyReport/' + expenseCompanyReportId);
	};

	getGrantDetails = (reportId: number, grantId: number) => {
		return expensingApi.get<ServerResponseApi<{ grantDetails: GrantDetails; optionPricing: GrantOptionsPricing }>>(
			`/Expensing/ExpenseGrantReport/ExpenseCompanyReport/${reportId}/Grant/${grantId}`
		);
	};

	deleteReport = (expenseCompanyReportId: number) => {
		return expensingApi.delete<ServerResponseApi<ExpenseReport>>('/Expensing/ExpenseCompanyReport', null, {
			expenseCompanyReportId,
		});
	};

	updateReportStatus = (status: ExpenseReportStatus, expenseCompanyReportId: number) => {
		return expensingApi.put<ServerResponseApi<ExpenseReport>>('/Expensing/ExpenseCompanyReport/Status', {
			expenseCompanyReportId,
			status,
		});
	};

	getDisclosureReport = (reportId: number) => {
		return expensingApi.get<ServerResponseApi<OutstandinAwards>>(`/Expensing/ExpenseCompanyReport/${reportId}/OutstandingAwards`);
	};

	// OptionsMigration

	createCompany = (company: ExpensingBasicCompany) => {
		return expensingApi.post<ServerResponseApi<ExpensingCompany>>('/OptionsMigration/Company', company);
	};

	updateCompany = (company: Partial<ExpensingBasicCompany>) => {
		return expensingApi.put<ServerResponseApi<ExpensingCompany>>('/OptionsMigration/Company', company);
	};

	getCompany = () => {
		return expensingApi.get<ServerResponseApi<ExpensingCompany>>(`/OptionsMigration/Company`);
	};

	getCompanyGrants = (reportEndDate: Date) => {
		return expensingApi.get<ServerResponseApi<Grant[]>>(`/OptionsMigration/Grant/Compay`, {
			reportEndDate: formatDate(reportEndDate, DateFormatsEnum.DASHED),
		});
	};

	updateGrantsForfeitureRate = (payload: GrantForfeitureRatePayload[]) => {
		return expensingApi.put<
			ServerResponseApi<
				{
					entityAppId: number;
					migrationId: number;
				}[]
			>
		>(`/OptionsMigration/Grant/ForfeitureRate`, payload);
	};

	// Peers

	getAvailablePeers = () => {
		return expensingApi.get<ServerResponseApi<GlobalPeer[]>>('/Peers/Peer');
	};

	getCompanyPeers = () => {
		return expensingApi.get<ServerResponseApi<Peer[]>>('/Peers/PeerCompany', {
			companyEntityAppId: this.companyId,
		});
	};

	addCompanyPeer = (peer: AddPeerRequest) => {
		return expensingApi.post<ServerResponseApi<Peer>>('/Peers/PeerCompany', {
			...peer,
			companyEntityAppId: this.companyId,
		});
	};

	togglePeerStatus = (data: TogglePeerStatusData) => {
		return expensingApi.put<ServerResponseApi<TogglePeerStatusData>>('/Peers/PeerCompany/IsActive', data, {
			companyAppId: this.companyId,
		});
	};

	updateCompanyPeer = (data: UpdatePeerRequest) => {
		return expensingApi.put<ServerResponseApi<Peer>>('/Peers/PeerCompany', {
			...data,
			companyEntityAppId: this.companyId,
		});
	};

	// Underlying asset

	addAssetPrice = (data: EditableUnderlyingAssetPrice) => {
		return expensingApi.post<ServerResponseApi<UnderlyingAssetPrice>>('/UnderlyingAssetPrice', data);
	};

	updateAssetPrice = (data: EditableUnderlyingAssetPrice) => {
		return expensingApi.put<ServerResponseApi<UnderlyingAssetPrice[]>>('/UnderlyingAssetPrice', data);
	};

	getAssetPrices = () => {
		return expensingApi.get<ServerResponseApi<UnderlyingAssetPrice[]>>('/UnderlyingAssetPrice/company', {
			companyAppId: this.companyId,
		});
	};

	deleteAssetPrice = (assetId: number) => {
		return expensingApi.delete<ServerResponseApi<UnderlyingAssetPrice>>(`/UnderlyingAssetPrice?underlyingAssetPriceId=${assetId}`);
	};

	getAssetPriceByGrant = () => {};

	// OptionsPricing

	runBlackAndScholes = (data: OptionsPricing[]) => {
		return expensingApi.post<ServerResponseApi<GrantOptionsPricing[]>>('/OptionsPricing/BlackAndScholes', data);
	};

	getPricingGrants = () => {
		return expensingApi.get<ServerResponseApi<GrantCalculation[]>>('/OptionsPricing/current/company');
	};
}
