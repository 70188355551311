import { useEffect, useState } from "react";
import Button from "../../../../Shared/Components/Button/Button";
import Table, { TableColumn } from "../../../../Shared/Components/Table/Table";
import DatePicker from "../../../../Shared/Components/Input/DatePickerInput";
import { ILog } from "../../../../Models/App/EquityPlans/Company";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";

interface DataLogProps {
	rtd?: () => void;
}

interface LogTable {
	logId: number;
	companyId?: number;
	createdBy: number;
	createdOn: Date | String;
	entityType?: number;
	actionType?: number;
	entityName: string;
	actionName: string;
	oldData: string;
	newData: string;
	isSucceeded: boolean;
	requestData?: string;
}

const DataLog = (props: DataLogProps) => {
	const { equityPlansStore } = useRootStore();

	// const [tableData, setTableData] = useState<LogTable[]>()
	const [date, setDate] = useState<Date | string>();

	console.log("Rendering....");

	const tableColumns: TableColumn<ILog>[] = [
		// {
		//     name: "fullName",
		//     label: "full name",
		//     format: (val: { firstName: string, lastName: string }) => `${val?.firstName}  ${val?.lastName}`,
		//     sortable: false,
		//     style: { flex: 1.5 },
		//     onCellClick: (value) => console.log(value),
		// },
		{
			name: "createdOn",
			label: "Date",
			sortable: true,
			defaultCellSize: 20,
		},
		{
			name: "createdBy",
			label: "Who To Blame",
			sortable: true,
			defaultCellSize: 20,
		},
		{
			name: "createdOn",
			label: "Change Date",
			sortable: true,
		},
		{
			name: "entityName",
			label: "What Changed",
			sortable: true,
		},
		{
			name: "actionName",
			label: "Action Performed",
			sortable: true,
		},
		{
			name: "oldData",
			label: "Previous Data",
			sortable: true,
			onCellClick: (value) => console.log(value),
		},
		{
			name: "newData",
			label: "New Data",
			sortable: true,
		},
	];

	// const LogtableData: ILog[] = [
	//     { logId: 0, createdBy: 0, createdOn: new Date().toISOString(), entityName: 'Grant', actionName: 'Create', oldData: 'Data', newData: 'Better Data', isSucceeded: true },
	//     { logId: 1, createdBy: 0, createdOn: new Date().toISOString(), entityName: 'Grant', actionName: 'Delete', oldData: 'Data', newData: 'Better Data', isSucceeded: true },
	//     { logId: 2, createdBy: 0, createdOn: new Date().toISOString(), entityName: 'Beneficiary', actionName: 'Ignore', oldData: 'Data', newData: 'Better Data', isSucceeded: true },
	//     { logId: 3, createdBy: 0, createdOn: new Date().toISOString(), entityName: 'Order', actionName: 'Delete', oldData: 'Data', newData: 'Better Data', isSucceeded: true },
	//     { logId: 4, createdBy: 0, createdOn: new Date().toISOString(), entityName: 'Grant', actionName: 'Eat', oldData: 'Data', newData: 'Better Data', isSucceeded: true },
	//     { logId: 5, createdBy: 0, createdOn: new Date().toISOString(), entityName: 'Beneficiary', actionName: 'Delete', oldData: 'Data', newData: 'Better Data', isSucceeded: true },
	//     { logId: 6, createdBy: 1, createdOn: new Date().toISOString(), entityName: 'Grant', actionName: 'Delete', oldData: 'Data', newData: 'Better Data', isSucceeded: true },
	//     { logId: 7, createdBy: 0, createdOn: new Date().toISOString(), entityName: 'Grant', actionName: 'Delete', oldData: 'Data', newData: 'Better Data', isSucceeded: true },
	//     { logId: 8, createdBy: 0, createdOn: new Date().toISOString(), entityName: 'Grant', actionName: 'Grant', oldData: 'Data', newData: 'Better Data', isSucceeded: true },
	//     { logId: 9, createdBy: 0, createdOn: new Date().toISOString(), entityName: 'Beneficiary', actionName: 'Delete', oldData: 'Data', newData: 'Better Data', isSucceeded: true },
	//     { logId: 10, createdBy: 0, createdOn: new Date().toISOString(), entityName: 'Order', actionName: 'Delete', oldData: 'Data', newData: 'Better Data', isSucceeded: true },
	//     { logId: 11, createdBy: 0, createdOn: new Date().toISOString(), entityName: 'Order', actionName: 'Delete', oldData: 'Data', newData: 'Better Data', isSucceeded: true },
	//     { logId: 12, createdBy: 0, createdOn: new Date().toISOString(), entityName: 'Order', actionName: 'Delete', oldData: 'Data', newData: 'Better Data', isSucceeded: true },
	//     { logId: 13, createdBy: 0, createdOn: new Date().toISOString(), entityName: 'Order', actionName: 'Delete', oldData: 'Data', newData: 'Better Data', isSucceeded: true },
	//     { logId: 14, createdBy: 0, createdOn: new Date().toISOString(), entityName: 'Order', actionName: 'Delete', oldData: 'Data', newData: 'Better Data', isSucceeded: true },
	//     { logId: 15, createdBy: 0, createdOn: new Date().toISOString(), entityName: 'Order', actionName: 'Delete', oldData: 'Data', newData: 'Better Data', isSucceeded: true },
	//     { logId: 16, createdBy: 0, createdOn: new Date().toISOString(), entityName: 'Order', actionName: 'Delete', oldData: 'Data', newData: 'Better Data', isSucceeded: true },
	//     { logId: 17, createdBy: 0, createdOn: new Date().toISOString(), entityName: 'Order', actionName: 'Delete', oldData: 'Data', newData: 'Better Data', isSucceeded: true },
	//     { logId: 18, createdBy: 0, createdOn: new Date().toISOString(), entityName: 'Order', actionName: 'Delete', oldData: 'Data', newData: 'Better Data', isSucceeded: true },
	//     { logId: 19, createdBy: 0, createdOn: new Date().toISOString(), entityName: 'Order', actionName: 'Delete', oldData: 'Data', newData: 'Better Data', isSucceeded: true },
	//     { logId: 20, createdBy: 0, createdOn: new Date().toISOString(), entityName: 'Order', actionName: 'Delete', oldData: 'Data', newData: 'Better Data', isSucceeded: true },
	//     { logId: 21, createdBy: 0, createdOn: new Date().toISOString(), entityName: 'Order', actionName: 'Delete', oldData: 'Data', newData: 'Better Data', isSucceeded: true },
	// ]

	useEffect(() => {
		console.log("equityPlansStore.dataLog", toJS(equityPlansStore.dataLog));
		// setTableData(LogtableData)
	}, [equityPlansStore.dataLog]);

	const dateChangeHandler = (newDate: Date | string) => {
		console.log("stupd changing....", newDate);
		setDate(newDate);
		equityPlansStore.GetDataLog(newDate);
	};

	return (
		<div className="container-fluid">
			<DatePicker label="From Date" value={date} qaid="" onChange={(e) => e && dateChangeHandler(e)} />
			<h1>Data Change Log</h1>
			<div className="table">
				<Table
					columns={tableColumns}
					rows={equityPlansStore.dataLog}
					color="secondary"
					scrollAfterRows={20}
					// onRowClick={(row) => {
					//     // console.log('Beneficiary row Clicked', toJS(row))
					//     // openGrntCard(row)
					// }}
					// showTotal={false}
					fullscreen
					// searchBy={['actionName', "entityName"]}
				/>
			</div>
		</div>
	);
};
export default observer(DataLog);
