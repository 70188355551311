import { useEffect } from 'react';
import { ModalBodyProps } from '../../../Shared/Components/Modal/types';
import useMultiStepForm from '../../../Shared/Hooks/useMultiStepForm';
import { isNumber } from '../../../Shared/Utilities';
import Grants from './Grants';
import Settings from './Settings';

type Props = ModalBodyProps & {
	startPage?: number;
};

const NewReport = ({ startPage, removeModal }: Props) => {
	useEffect(() => {
		isNumber(startPage) && goTo(startPage);
	}, []);

	const { goTo, step } = useMultiStepForm([
		{
			element: <Settings onSubmitHandler={removeModal} />,
		},
		{
			element: <Grants onSubmitHandler={removeModal} />,
		},
	]);

	return step;
};

export default NewReport;
