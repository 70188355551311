export enum EmployeeStatus {
    Employee = 0,
    ExEmployee = 1,
    Director = 2,
    Officer = 3,
    ShareHolder = 4,
    Founder = 5,
    ServiceProvider = 6,
    Death = 7,
    LeaveOfAbsence = 8,
    ChairmanAndAdvisor = 9,
}
