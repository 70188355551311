import { css } from "@emotion/css";
import appConfig from "../../../../../../../../config/config";
import { Cell, Row, Table } from "../../../../../../../../Shared/Components/Table/Table.Style";
import OverflowText from "../../../../../../../../Shared/Components/OverflowText";
import { ReactElement, isValidElement } from "react";
import classNames from "classnames";


const Style = css({
    label: "SummaryTable",
    boxShadow: appConfig.style.boxShadow.card1,
    border: `1px solid ${appConfig.style.colors.table}`,
    borderRadius: 16,
    overflow: 'auto',
    "&__cell": {
        overflow: "auto !important",
        "&.fixed-col": {
            flex: "0 0 15rem",
            fontWeight: 500,
            background: appConfig.style.colors.purple
        },
        "&.border-thick": {
            borderWidth: 2,
        }
    }
})

type Props = {
    data: (string | ReactElement)[][];
}

const SummaryTable = ({ data }: Props) => {

    return (
        <div className={Style}>
            <Table>
                {
                    data.map((row, idx) => <Row key={idx} header={idx === 0} data-qaid="SummaryTable.Table.Row">
                        {row.map((cell, cIdx) =>
                            <Cell
                                data-qaid="SummaryTable.Table.Cell"
                                border={{ bottom: idx + 1 < data.length, top: !!idx, right: true }}
                                key={cIdx}
                                className={classNames(`${Style}__cell`, { "fixed-col": !cIdx, "border-thick": !idx })}
                            // style={cIdx ? {} : { flex: "0 0 20rem" }}
                            >
                                {isValidElement(cell) ? cell : <OverflowText>{cell}</OverflowText>}
                            </Cell>
                        )
                        }
                    </Row>)
                }
            </Table>
        </div>
    )
}

export default SummaryTable;