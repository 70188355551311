import styled from "@emotion/styled";
import { HeadingProps } from "./Heading";
import { theme } from "../../../config/style/theme";

export const StyledHeading = styled.h3<HeadingProps>`
	label: Heading;
	color: ${(props) => props.ap.color && theme.colors[props.ap.color]};
	font-family: ${(props) => props.ap.font && theme.fonts[props.ap.font]};
	font-size: ${(props) => (props.ap.size ? theme.textSizes[props.ap.size] : "unset")};
	font-weight: ${(props) => (props.ap.isBold ? "bold" : "normal")};
	margin-bottom: ${(props) => (props.ap.spacingBottom ? theme.spacings[props.ap.spacingBottom] : "unset")};
	text-align: ${(props) => props.ap.align && props.ap.align};
	text-transform: ${(props) => props.ap.isUpperCase && "uppercase"};
`;
