export interface TypeDto {
	key: number;
	value: string;
	companyId: number;
	date?: Date;
}

// ENUMs

export enum EntityType {
	Company,
	Plan,
	Pool,
	PoolStep,
	Vesting,
	Grant,
	Order,
	GrantCancelation,
	Split,
	Beneficiary,
	Site,
	Department,
	Document,
	VestingCompany,
	VestingPlan,
	Acceleration,
	GrantEvent,
}

export enum EntityTrackHistoryType {
	Site,
	Department,
	SubDepartment,
	EmploymentStatus,
	ExpirationDate,
	LastDateToExercise,
	ExPrice,
	TaxTrack,
}

export enum ActionType {
	Create,
	Update,
	Delete,
}

export enum MultiType {
	Delete,
	SendInvition,
	Repricing,
	SiteUpdate,
}

export interface IDashboardData {
	granted: number;
	outstanding: number;
	exercised: number;
	sold: number;
	canceled: number;
	saleableShares: number;
	exercisable: number;
	activeBeneficiaries: number;
	inactiveBeneficiaries: number;
	unAllocated: number;
	promised: number;
	allocated: number;
	unVested: number;
}
