import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { CompanyCustomData } from "../../../../../Models/App/EquityPlans/Company";
import { TableColumn } from "../../../../../Shared/Components/Table/Table";
import useModal from "../../../../../Shared/Hooks/useModal";
import useRootStore from "../../../../../Shared/Hooks/useRootStore";
import EquityDataComp from "../EquityDataComp/EquityDataComp";
import EntityFactoryStyle from "./EntitiesFactory.style";
import { isNullOrUndefined } from "../../../../../Shared/Utilities";
import { IC_EDIT_GRAY, IC_TRASH2 } from "../../../../../Assets";
import Flex from "../../../../../Shared/Components/Layout/Flex";
import classNames from "classnames";
import useEsopModals from "../../../useEsopModals";
import { taxTracks, taxTracks2 } from "../../../../../Shared/StaticData/equityPlans";

interface IProps {
	readonly isRegistration?: boolean;
	className?: string;
}

const EntitiesFactory = (props: IProps) => {

	const { equityPlansStore } = useRootStore();
	const { showModal } = useModal();
	const { showSuccessModal, showErrorModal } = useEsopModals();


	const tttt = taxTracks

	//  Site

	const siteColumns: TableColumn<CompanyCustomData>[] = [
		{ label: "", name: "value" },
		{
			label: "",
			name: "menu",
			defaultCellSize: 20,
			menuItems: [
				// {
				// 	onClick: (obj) => {
				// 		siteEditHandler(obj);
				// 	},
				// 	element: <img src={IC_EDIT_GRAY} alt="edit" />,
				// 	qaid: "EntitiesFactory.Button.EditSite",
				// },
				{
					onClick: (obj) => {
						deleteSite(obj);
					},
					element: <img src={IC_TRASH2} alt="delete" />,
					qaid: "companyData.Button.DeleteSite",
				},
			],
		},
	];

	const [siteEdit, setSiteEdit] = useState<CompanyCustomData | undefined>();

	const siteEditHandler = async (obj: CompanyCustomData) => {
		console.log("siteEditHandler", toJS(obj));
		// dprtEdit && (await setSiteEdit(undefined));
		setSiteEdit(obj);
	};

	const addSite = async (value: string) => {
		console.log(`Adding Site ${value}`);

		const res = await equityPlansStore.AddSiteToCompany(value);
		if (res.data?.result) {
			equityPlansStore.GetEquitySettings()
			return true;
		} else {
			showErrorModal(res)
		}
		console.log("SITE RES", res)
	};

	const editSite = (value: string) => {
		console.log(`Editing Department ${siteEdit?.key} with: ${value}`);
		if (isNullOrUndefined(siteEdit)) return;
		//amir
		// equityPlansStore.UpdateCompanyDepartment(135, siteEdit?.key, value);
	};

	const deleteSite = async (obj: CompanyCustomData) => {
		console.log("Delete Site");

		showModal({
			type: "delete",
			body: <>Are you sure you want to delete this site?</>,
			onConfirm: async () => {
				const res = await equityPlansStore.DeleteSite(obj.key);
				// console.log("site delete response", res);
				if (!res.data) {
					showErrorModal(res);
				}
			},
		});
	};




	//  Department

	const dprtColumns: TableColumn<CompanyCustomData>[] = [
		{ label: "", name: "value" },
		{
			label: "",
			defaultCellSize: 20,
			name: "menu",
			menuItems: [
				// {
				// 	onClick: (obj) => {
				// 		dprtEditHandler(obj);
				// 	},
				// 	element: <img src={IC_EDIT_GRAY} alt="edit" />,
				// 	qaid: "companyData.Button.EditDep",
				// },
				{
					onClick: (obj) => deleteDeparment(obj),
					element: <img src={IC_TRASH2} alt="delete" />,
					qaid: "companyData.Button.DeleteDepartment",
				},
			],
		},
	];

	const [dprtEdit, setDprtEdit] = useState<CompanyCustomData | undefined>();

	const dprtEditHandler = async (obj: CompanyCustomData) => {
		console.log("dprtEditHandler", toJS(obj));
		dprtEdit && (await setDprtEdit(undefined));
		setDprtEdit(obj);
	};

	const addDeparment = async (value: string) => {
		console.log(`Adding Department ${value}`);

		const res = await equityPlansStore.AddDepartmentToCompany(value);
		if (res.data?.key) {
			equityPlansStore.GetEquitySettings()
			return true;
		} else {
			showErrorModal(res)
		}
		console.log("DEP RES", res);
	};

	const editDeparment = (value: string) => {
		console.log(`Editing Department ${dprtEdit?.key} with: ${value}`);

		if (isNullOrUndefined(dprtEdit)) return;
		equityPlansStore.UpdateCompanyDepartment(dprtEdit?.key, value);
	};

	const deleteDeparment = async (obj: CompanyCustomData) => {
		console.log(`Deleting Department ${obj.key} `);

		showModal({
			type: "delete",
			body: <>Are you sure you want to delete this Department?</>,
			onConfirm: async () => {
				const res = await equityPlansStore.DeleteDepartment(obj.key);
				console.log("department delete response", res);

				if (!res.data) showErrorModal(res)
			},
		});
	};




	//  Sub Department

	const subDprtColumns: TableColumn<CompanyCustomData>[] = [
		{ label: "", name: "value" },
		{
			label: "",
			defaultCellSize: 20,
			name: "menu",
			menuItems: [
				// {
				// 	onClick: (obj) => {
				// 		subDprtEditHandler(obj);
				// 	},
				// 	element: <img src={IC_EDIT_GRAY} alt="edit" />,
				// 	qaid: "companyData.Button.EditSubDep",
				// },
				{
					onClick: (obj) => deleteSubDeparment(obj),
					element: <img src={IC_TRASH2} alt="delete" />,
					qaid: "companyData.Button.DeleteSubDep",
				},
			],
		},
	];

	const [subDprtEdit, setSubDprtEdit] = useState<CompanyCustomData | undefined>();

	const subDprtEditHandler = async (obj: CompanyCustomData) => {
		console.log("subDprtEditHandler", toJS(obj));
		subDprtEdit && (await setSubDprtEdit(undefined));
		setSubDprtEdit(obj);
	};

	const addSubDeparment = async (value: string) => {
		const res = await equityPlansStore.AddSubDepartmentToCompany(value);
		if (res.data?.key) {
			equityPlansStore.GetEquitySettings();
			return true;
		} else {
			showErrorModal(res);
		}
		console.log("DEP RES", res);
	};

	const editSubDeparment = (value: string) => {
		console.log(`Editing Sub Department ${dprtEdit?.key} with: ${value}`);
	};

	const deleteSubDeparment = async (obj: CompanyCustomData) => {
		console.log(`Deleting sub Department ${obj.key} `);

		showModal({
			type: "delete",
			body: <>Are you sure you want to delete this Sub Department?</>,
			onConfirm: async () => {
				const res = await equityPlansStore.DeleteSubDepartment(obj.key);
				console.log("sub department delete response", res);

				if (!res) showErrorModal()
			},
		});
	};




	// Tax Track

	const taxTrackColumns: TableColumn<CompanyCustomData>[] = [
		{ label: "", name: "value" },
		{
			label: "",
			defaultCellSize: 20,
			name: "menu",
			menuItems: [
				// {
				// 	onClick: (obj) => {
				// 		taxTrackEditHandler(obj);
				// 	},
				// 	element: <img src={IC_EDIT_GRAY} alt="edit" />,
				// 	qaid: "companyData.Button.EditLegalEntity",
				// },
				{
					onClick: (obj) => {
						deleteTaxTrack(obj);
					},
					element: <img src={IC_TRASH2} alt="delete" />,
					qaid: "companyData.Button.DeleteTaxTrack",
				},
			],
		},
	];

	const [taxTrackEdit, setTaxTrackEdit] = useState<CompanyCustomData | undefined>();

	const taxTrackEditHandler = async (obj: CompanyCustomData) => {
		console.log("taxTrackEditHandler", toJS(obj));
		dprtEdit && (await setTaxTrackEdit(undefined));
		setTaxTrackEdit(obj);
	};

	const addTaxTrack = async (value: string) => {

		const res = await equityPlansStore.AddTaxTrackToCompany(value)

		if (res.data?.result) {
			if (value === 'ISO (US)') {
				await equityPlansStore.AddTaxTrackToCompany('NSO (US)')
			} else if (value === 'NSO (US)') {
				await equityPlansStore.AddTaxTrackToCompany('ISO(US)')
			}

			equityPlansStore.GetEquitySettings()
			return true;
		} else {
			showErrorModal(res)
		}

		console.log("DEP RES", res)
	};

	const editTaxTrack = (value: string) => {
		console.log(`Editing taxk track ${taxTrackEdit?.key} with: ${value}`);

		if (isNullOrUndefined(taxTrackEdit)) return;
		//amir
		// equityPlansStore.UpdateCompanyDepartment(135, taxTrackEdit?.key, value);
	};

	const deleteTaxTrack = async (obj: CompanyCustomData) => {
		console.log("Delete taxk track");
		showModal({
			type: "delete",
			body: <>Are you sure you want to delete this tax Track?</>,
			onConfirm: async () => {
				const res = await equityPlansStore.DeleteTaxTrack(obj.key);
				if (!res.data) {
					showErrorModal(res)
				}
			},
		});
	};

	return (
		<div className={classNames(EntityFactoryStyle, { [props.className ?? ""]: !!props.className })}>
			<Flex
				width="100%"
				align="start"
				justify="between"
				gap={!props.isRegistration ? "3rem" : ""}
				className={classNames({ registrationContainer: props.isRegistration })}
			>

				<EquityDataComp
					title="Tax tracks"
					btnLabel="Add tax track"
					tbColumns={taxTrackColumns}
					tbRows={equityPlansStore.companyData?.taxTracks ?? []}
					isEdit={taxTrackEdit?.key}
					key="taxTrack"
					dropValues={taxTracks2}
					required
					addFunc={(newValue: string) => addTaxTrack(newValue)}
					editFunc={(newValue: string) => editTaxTrack(newValue)}
				/>

				<EquityDataComp
					title="Sites"
					btnLabel="Add Site"
					tbColumns={siteColumns}
					tbRows={equityPlansStore.companyData?.sites ?? []}
					isEdit={siteEdit?.key}
					key="site"
					addFunc={(newValue: string) => addSite(newValue)}
					editFunc={(newValue: string) => editSite(newValue)}
				/>

				<EquityDataComp
					title="Departments"
					btnLabel="Add department"
					tbColumns={dprtColumns}
					tbRows={equityPlansStore.companyData?.departments ?? []}
					isEdit={dprtEdit?.key}
					key="department"
					addFunc={(newValue: string) => addDeparment(newValue)}
					editFunc={(newValue: string) => editDeparment(newValue)}
				/>

				<EquityDataComp
					title="Sub departments"
					btnLabel="Add Sub Department"
					tbColumns={subDprtColumns}
					tbRows={equityPlansStore.companyData?.subDepartments ?? []}
					isEdit={subDprtEdit?.key}
					key="subDepartment"
					addFunc={(newValue: string) => addSubDeparment(newValue)}
					editFunc={(newValue: string) => editSubDeparment(newValue)}
				/>

			</Flex>
		</div>
	);
};

export default observer(EntitiesFactory);







// Legal entity
// const legalEntityColumns: TableColumn<CompanyCustomData>[] = [
// 	{ label: "", defaultCellSize: 100, name: "value" },
// 	{
// 		label: "",
// 		defaultCellSize: 20,
// 		name: "menu",
// 		menuItems: [
// 			{
// 				onClick: (obj) => {
// 					legalEntityEditHandler(obj);
// 				},
// 				element: <img src={IC_EDIT_GRAY} alt="edit" />,
// 				qaid: "EntitiesFactory.Button.EditLegalEntity",
// 			},
// 			{
// 				onClick: (obj) => {
// 					deleteLegalEntity(obj);
// 				},
// 				element: <img src={IC_TRASH2} alt="delete" />,
// 				qaid: "EntitiesFactory.Button.DeleteLegalEntity",
// 			},
// 		],
// 	},
// ];

// const [legalEntityEdit, setLegalEntityEdit] = useState<CompanyCustomData | undefined>();

// const legalEntityEditHandler = async (obj: CompanyCustomData) => {
// 	console.log("legalEntityEditHandler", toJS(obj));
// 	dprtEdit && (await setLegalEntityEdit(undefined));
// 	setLegalEntityEdit(obj);
// };

// const addLegalEntity = (value: string) => {
// 	console.log(`Adding Department ${value}`);
// 	//amir
// 	equityPlansStore.AddLegalEntityToCompany(value);
// };

// const editLegalEntity = (value: string) => {
// 	console.log(`Editing Department ${legalEntityEdit?.key} with: ${value}`);

// 	if (isNullOrUndefined(legalEntityEdit)) return;
// 	//amir
// 	equityPlansStore.UpdateCompanyLegalEntity(135, legalEntityEdit?.key, value);
// };

// const deleteLegalEntity = async (obj: CompanyCustomData) => {
// 	console.log("Delete legal entity");
// };
