import styled from "@emotion/styled";
import { StyledWidget } from "../../../../shared/components/Widget/Widget.style";
import { breakpoints } from "../../../../shared/dependencies/breakpoints";

export const Wrapper = styled(StyledWidget)`
	label: SwitchSelectChartWidget;
	display: flex;
	flex-direction: column;
`;

export const BtnWrapper = styled.div`
	label: BtnWrapper;
	margin-bottom: 1.6rem;
`;

export const ChartWrapper = styled.div`
	label: ChartWrapper;
	display: unset;
	> img {
		width: 16.1rem;
		height: auto;
	}

	@media (min-width: ${breakpoints.sm}) {
		display: flex;
		align-items: center;
	}
`;
