import { useState } from "react";
import { IBaseGrant } from "../../../../../../Models/App/EquityPlans/GrantEvents";
import DatePickerInput from "../../../../../../Shared/Components/Input/DatePickerInput";
import NumberInput from "../../../../../../Shared/Components/Input/NumberInput";
import Table, { TableColumn } from "../../../../../../Shared/Components/Table/Table";
import { addDaysToDate, formatDate, formatNumber, isNullOrUndefined, isNumber } from "../../../../../../Shared/Utilities";
import { grantCancelationsMock } from "../../../../daniel.mocks";
import useRootStore from "../../../../../../Shared/Hooks/useRootStore";
import { Cell } from "../../../../../../Shared/Components/Table/Table.Style";
import classNames from "classnames";
import Tooltip from "../../../../../../Shared/Components/Tooltip";
import Flex from "../../../../../../Shared/Components/Layout/Flex";
import ErrorIcon from "@mui/icons-material/Error";
import { nulldefined } from "../../../../../../Shared/Interfaces/global";
import Select from "../../../../../../Shared/Components/Select/Select";
import useGrant from "../../GrantDetails/useGrant";

const EditVestSched = () => {
	const { equityPlansStore } = useRootStore();
	const [vestings, setVestings] = useState<IBaseGrant[] | nulldefined>(grantCancelationsMock || equityPlansStore.modifiedGrantEvents);
	const { vests, onInputHandler } = useGrant("beneficiaryGrantData");

	const onCellUpdate = (rowId: number, name: string, value: string | number | undefined) => {
		setVestings((prevState) => {
			if (isNullOrUndefined(prevState)) return;
			const rowIdx = prevState.findIndex((r) => r.id === rowId);
			return [
				...prevState.slice(0, rowIdx),
				{
					...prevState[rowIdx],
					[name]: value,
				},
				...prevState.slice(rowIdx + 1),
			];
		});
	};

	const vestColumns: TableColumn<IBaseGrant>[] = [
		{
			name: "date",
			label: "Date",
			format(val) {
				return formatDate(val);
			},
			inlineEdit: (row) => {
				return (
					<DatePickerInput
						value={row.date}
						name="date"
						minDate={equityPlansStore.beneficiaryGrantData.vestStartDate ? addDaysToDate(equityPlansStore.beneficiaryGrantData.vestStartDate, 1) : undefined}
						maxDate={equityPlansStore.beneficiaryGrantData.experationDate}
						onChange={(value, name) => onCellUpdate(row.id, name!, value?.toString())}
						qaid="EditVestSchedule.Input.Date"
						flat
					/>
				);
			},
		},
		{
			name: "granted",
			label: "Granted",
			format(val) {
				return formatNumber(val);
			},
		},
		{
			name: "vested",
			label: "Vested",
			render(obj, value) {
				const isError = obj.granted < obj.vested;
				return (
					<Flex className={classNames({ "text-color-error": isError })} align="center" gap={12} justify="between">
						<span className="text-ellipsis">{formatNumber(value)}</span>
						{isError && (
							<Tooltip title="Vested must be less or equal to granted amount">
								<ErrorIcon className="text-color-error" />
							</Tooltip>
						)}
					</Flex>
				);
			},
			inlineEdit: (row) => (
				<NumberInput
					value={row.vested}
					name="vested"
					onChange={(value, name) => onCellUpdate(row.id, name!, value)}
					qaid="EditVestSchedule.Input.Vested"
					flat
					error={row.granted >= row.vested ? "" : "Vested must be less or equal to granted amount"}
				/>
			),
		},
		{
			name: "exercised",
			label: "Exercised",
			format(val) {
				return formatNumber(val);
			},
		},
		{
			name: "sellable",
			label: "Shares",
			format(val) {
				return formatNumber(val);
			},
		},
		{
			name: "outstanding",
			label: "Outstanding",
			format(val) {
				return formatNumber(val);
			},
		},
	];

	const errors = vestings?.map((vest) => (vest.granted < vest.vested ? "Vested must be less or equal to granted amount" : undefined));

	return (
		<div>
			<Select
				label="Vesting Schedule"
				qaid="EditGrant.Input.VestingSchedule"
				options={vests}
				name="vestingId"
				onChange={(value, name) => {
					if (isNumber(value)) onInputHandler(value, name!);
				}}
			/>
			<Table color="secondary" columns={vestColumns} rows={vestings} scrollAfterRows={10} fullscreen />
			<Flex className="errors mt-5" direction="column" justify="start" align="start" gap={6}>
				{errors?.map(
					(error: any, idx: number) =>
						!!error && (
							<div key={idx} className="text-color-error">
								<span className="bold">Row {idx + 1}</span>: {error}
							</div>
						)
				)}
			</Flex>
		</div>
	);
};
export default EditVestSched;
