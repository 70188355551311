import styled from "@emotion/styled";

import { StyledWidgetDashboard } from "../shared/components/WidgetDashboard/WidgetDashboard.style";
import { breakpoints } from "../shared/dependencies/breakpoints";

const DOUGNUT_CHART_WIDGET_WIDTH_XL = "75.6rem";

export const Wrapper = styled(StyledWidgetDashboard)`
    label: CaptableDashboard;
    grid-template-areas:
        "Chart"
        "TotalInvested"
        "LastRoundValuation"
        "IssuedShares"
        "EquityAwards";

    @media (min-width: ${breakpoints.sm}) {
        grid-template-areas:
            "Chart TotalInvested"
            "Chart LastRoundValuation"
            "EquityAwards IssuedShares";
        grid-template-columns: 1fr 1fr;
    }

    @media (min-width: ${breakpoints.md}) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas:
            "Chart TotalInvested LastRoundValuation"
            "Chart IssuedShares EquityAwards";
    }

    @media (min-width: ${breakpoints.lg}) {
        grid-template-columns: 1.4fr 1fr 1fr;
    }

    @media (min-width: ${breakpoints.xl}) {
        grid-template-columns: ${DOUGNUT_CHART_WIDGET_WIDTH_XL} 1fr 1fr;
    }
`;

export const ChartWidgetWrapper = styled.div`
    label: ChartWidget;
    grid-area: Chart;
`;

export const TotalInvestedWidgetWrapper = styled.div`
    label: TotalInvested;
    grid-area: TotalInvested;
`;

export const LastRoundValuationWidgetWrapper = styled.div`
    label: LastRoundValuation;
    grid-area: LastRoundValuation;
`;

export const IssuedSharesWidgetWrapper = styled.div`
    label: IssuedShares;
    grid-area: IssuedShares;
`;

export const EquityAwardsWidgetWrapper = styled.div`
    label: EquityAwards;
    grid-area: EquityAwards;
`;
