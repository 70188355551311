import { ReactElement } from "react";
import ExpensingMenu from "./Components/Menu";

type HTMLProps = Omit<Partial<HTMLDivElement>, "children"> & {
    children?: ReactElement | ReactElement[];
};

type MainProps = {
    showActions?: boolean;
    showSelect?: boolean;
};

type InnerPageProps = {
    showActions?: boolean;
    showSelect?: false;
    showBack?: boolean;
};

type Props = HTMLProps & (MainProps | InnerPageProps);

const MenuWrapper = (props: Props) => {
    return (
        <section>
            <ExpensingMenu {...props} />
            {props.children}
        </section>
    );
};

export default MenuWrapper;
