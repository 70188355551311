import styled from "@emotion/styled";
import { breakpoints } from "../../../shared/dependencies/breakpoints";

export const DOUGHNUT_CHART_MAX_SIZE_XL = "17rem";
export const ChartWrapper = styled.div`
    label: NoDataChart;
    min-width: ${DOUGHNUT_CHART_MAX_SIZE_XL};
    max-height: ${DOUGHNUT_CHART_MAX_SIZE_XL};
`;

export const ContnetWrapper = styled.div`
    label: NoDataContent;
    flex-grow: 1;
    padding-inline: 1.6rem;
    max-width: 50%;
    line-height: 1.5;
    font-size: 1.4rem;

    @media (min-width: ${breakpoints.xs}) {
        padding-inline: unset;
    }
`;
