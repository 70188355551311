import { CircularProgress, CircularProgressProps } from "@mui/material";
import Background from "./Spinner.Style";

export interface ISpinnerProps extends CircularProgressProps {
    incorporated?: boolean;
    center?: boolean;
    margin?: boolean;
    attachParent?: boolean;
    background?: string;
}

const Spinner = ({ incorporated = false, center = false, margin = false, attachParent, background, ...rst }: ISpinnerProps) => {
    if (!incorporated) {
        return (
            <Background
                attachParent={attachParent}
                background={background}
            >
                <CircularProgress
                    size={rst.size || 64}
                    {...rst}
                />
            </Background>
        );
    }

    let styles = {};

    if (center) {
        styles = {
            ...styles,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        };
    }

    if (margin) {
        styles = {
            ...styles,
            margin: "15px",
        };
    }

    if (incorporated) {
        if (center) {
            styles = {
                ...styles,
                alignItems: "center",
                position: "absolute",
                width: "fit-content",
                height: "fit-content",
                margin: "auto",
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
            };
        }
        return (
            <div
                className="flex"
                style={{ ...styles, ...rst.style }}
            >
                <CircularProgress {...rst} />
            </div>
        );
    }

    return (
        <Background
            attachParent={attachParent}
            background={background}
        >
            <CircularProgress
                size={rst.size || "6.4rem"}
                {...rst}
            />
        </Background>
    );
};

export default Spinner;
