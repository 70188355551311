import { ExpensingAwardType } from '../../../Models/API/Expensing/award-type';
import { ExpenseReport } from '../../../Models/API/Expensing/expense-report';
import {
	AwardType,
	BlackAndScholeValuationEntries,
	ExpenseAmortizationEntries,
	ExportExcelResponse,
	TotalExpenseEntries,
} from '../../../Models/API/Expensing/export-excel-response';
import { GrantReportPayload } from '../../../Models/API/Expensing/grant-report';
import { TableColumn } from '../../../Shared/Components/Table/Table';
import { formatDate, formatDecimal, formatNumber, getPercentrage, isDateValid, isNumber, isString, onExportToExcel } from '../../../Shared/Utilities';
import * as ExcelJS from 'exceljs';
import appConfig from '../../../config/config';
import { currencies } from '../../../Shared/Config';
import { Currencies } from '../../../Models/API/enums';
import { EmployeeStatus } from '../../../Models/API/Expensing/employee-status-enum';
import { OutstandinAwards } from '../../../Models/App/Expensing/outstanding-awards-response';
import saveAs from 'file-saver';

export const isValidExpenseReport = (report: any): report is GrantReportPayload => {
	return (
		(isString(report?.expenseCompanyReportName) &&
			isNumber(report?.period) &&
			(isDateValid(report?.reportStartDate) || isString(report?.reportStartDate)) &&
			isDateValid(report?.reportEndDate)) ||
		isString(report?.reportEndDate)
	);
};

export function checkIfLastDayOfMonth(dt: Date) {
	var test = new Date(dt.getTime());
	test.setDate(test.getDate() + 1);
	return test.getDate() === 1;
}

export function exportExpensingToExcel(data: ExportExcelResponse) {
	const wb = new ExcelJS.Workbook();
	const exportToExcelData = data;

	const getTextWithCurrency = (value: number, currency: Currencies) => {
		const c = currencies.find((c) => c.currencyId === currency);
		return `${c?.symbol}${formatDecimal(value)}`;
	};

	const reportCurrency = data.reportCurrency;
	const reportStartDate = data.reportStartDate;
	const reportEndDate = data.reportEndDate;

	const bsWsColumns: TableColumn<BlackAndScholeValuationEntries>[] = [
		{ name: 'beneficiaryName', label: 'Beneficary Name' },
		{ name: 'grantNumber', label: 'Grant Number' },
		{ name: 'grantDate', label: 'Grant Date', format: (val) => formatDate(val) },
		{ name: 'granted', label: 'Granted', format: (val) => formatNumber(val) },
		{ name: 'vestingScheduleName', label: 'Vesting Name' },
		{ name: 'vestingStartDate', label: 'Vesting Start Date', format: (val) => formatDate(val) },
		{ name: 'exercisePrice', label: 'Exercise Price', format: (val) => getTextWithCurrency(val, reportCurrency) },
		{ name: 'marketPrice', label: 'Market Price', format: (val) => getTextWithCurrency(val, reportCurrency) },
		{ name: 'expectedLife', label: 'Expected Life', format: (val) => formatDecimal(val) },
		{ name: 'volatility', label: 'Volatility', format: (val) => formatDecimal(val) },
		{ name: 'riskFreeRate', label: 'Risk Free Rate', format: (val) => formatDecimal(val) },
		{ name: 'forfeitureRate', label: 'Forfeiture Rate', format: (val) => formatDecimal(val) },
		{ name: 'fairValue', label: 'B&S Value Per Award', format: (val) => getTextWithCurrency(val, reportCurrency) },
	];

	const bsWb = onExportToExcel({
		rows: exportToExcelData.blackAndScholeValuationEntries,
		exportToExcel: { fileName: 'Grants (Expensing)', sheetName: 'B&S Valuation Records' },
		color: 'primary',
		columns: bsWsColumns,
		isDownload: false,
		workbook: wb,
		frozen: [1],
	});

	const eaWsColumns: TableColumn<ExpenseAmortizationEntries>[] = [
		{ name: 'beneficiaryName', label: 'Beneficary Name' },
		{ name: 'employeeGrantNumber', label: 'Employee Grant Number' },
		{ name: 'department', label: `Department at ${formatDate(reportStartDate)}` },
		{ name: 'employeeType', label: `Employee type at ${formatDate(reportStartDate)}`, format: (val) => EmployeeStatus[val] },
		{ name: 'employmentEntity', label: `Entity at 1/1/2021 ${formatDate(reportStartDate)}` },
		{ name: 'awardType', label: 'Award Type', format: (val) => ExpensingAwardType[val] },
		{ name: 'grantDate', label: 'Grant Date', format: (val) => formatDate(val) },
		{ name: 'vestingStartDate', label: 'Vesting Start Date', format: (val) => formatDate(val) },
		{ name: 'fairValue', label: 'B&S value Per Award', format: (val) => getTextWithCurrency(val, reportCurrency) },
		{ name: 'totalExpensePostForfeiture', label: 'Total Expense (Post Forfeitures)', format: (val) => getTextWithCurrency(val, reportCurrency) },
		{ name: 'amortizedExpenseBefore', label: `Before period (${formatDate(reportStartDate)})`, format: (val) => getTextWithCurrency(val, reportCurrency) },
		{
			name: 'unamortizedExpenseBefore',
			label: `Before period (${formatDate(reportStartDate)})`,
			format: (val) => getTextWithCurrency(val, reportCurrency),
		},
		{
			name: 'expenseForPeriod',
			label: `Within period (${formatDate(reportStartDate)} to ${formatDate(reportEndDate)})`,
			format: (val) => getTextWithCurrency(val, reportCurrency),
		},
		{ name: 'amortizedExpenseAfter', label: `After period (${formatDate(reportEndDate)})`, format: (val) => getTextWithCurrency(val, reportCurrency) },
		{ name: 'unamortizedExpenseAfter', label: `After period (${formatDate(reportEndDate)})`, format: (val) => getTextWithCurrency(val, reportCurrency) },
		{ name: 'yearsOfAmortizationRemaining', label: `After period (${formatDate(reportEndDate)})`, format: (val) => formatDecimal(val) },
	];

	const eaWb = onExportToExcel({
		rows: exportToExcelData.expenseAmortizationEntries,
		exportToExcel: { fileName: 'Grants (Expensing)', sheetName: 'Expense Amortization' },
		color: 'primary',
		columns: eaWsColumns,
		isDownload: false,
		workbook: bsWb,
		frozen: [1, 2],
		onBeforeDataHandler: (ws) => {
			let header: string[] = [];
			ws.getRow(1).eachCell((cell) => header.push(cell.value?.toString() ?? ''));
			const borderColor = appConfig.style.colors.table.replace('#', '');

			ws.getCell(2, 13).value = 'Expense to Amortize for Period';

			for (let i = 0; i <= header.length; i++) {
				const lastIndex = header.lastIndexOf(header[i]); // Getting the last index of the title
				if (lastIndex === -1) break; // If not found, exit from the loop

				// Vertical merging if empty
				if (!ws.getCell(2, i + 1).value && lastIndex === i) {
					try {
						ws.mergeCells(1, i + 1, 2, i + 1);
					} catch (error) {}
				}

				// Settings right border to all the cells
				ws.getColumn(i + 1).eachCell((cell) => {
					cell.border = {
						...cell.border,
						right: { style: 'thin', color: { argb: borderColor } },
					};
				});

				// Settings right medium border by ranges
				ws.getColumn(lastIndex + 1).eachCell((cell) => {
					cell.border = {
						...cell.border,
						right: { style: 'thin', color: { argb: borderColor } },
					};
				});

				// 	// If last index is the current iterator, that means the title is only in one place, don't need to merge
				if (lastIndex === i) continue;

				// 	// Merging cells with try & catch (important to continue with the code)
				try {
					if (i === 10 || i === 13) {
						ws.mergeCells(1, i + 1, 1, lastIndex + 1);
					} else {
						ws.mergeCells(1, i + 1, 2, lastIndex + 1);
					}

					const mergedCell = ws.getCell(1, lastIndex);
					mergedCell.border = {
						...mergedCell.border,
						right: { style: 'thin', color: { argb: borderColor } },
					};
				} catch (error) {}
				//   i = lastIndex; // Setting the iterator to the last index to check the next title
			}
			return ws;
		},
		onAfterDataHandler: (ws) => {
			ws.getRow(1).height = 18;

			ws.getColumn(4).width = 26;
			ws.getColumn(5).width = 26;
			ws.getColumn(10).width = 28.5;
			ws.getColumn(13).width = 40;
			ws.getColumn(16).width = 29;

			ws.getCell(2, 11).value = 'Amortized Expense';
			ws.getCell(2, 12).value = 'Unmortized Expense';
			ws.getCell(2, 14).value = 'Amortized Expense';
			ws.getCell(2, 15).value = 'Unmortized Expense';
			ws.getCell(2, 16).value = 'Years of Amortization Remaining';

			ws.getRow(2).eachCell((cell) => {
				cell.fill = {
					type: 'pattern',
					pattern: 'solid',
					fgColor: {
						argb: appConfig.style.colors.color1.replace('#', ''),
					},
				};
				cell.font = {
					...cell.font,
					color: {
						argb: 'ffffff',
					},
					bold: true,
				};
				cell.alignment = {
					vertical: 'middle',
					horizontal: 'center',
				};
			});

			return ws;
		},
	});

	const epWsColumns: TableColumn<TotalExpenseEntries>[] = [
		{ name: 'beneficiaryName', label: 'Beneficary Name' },
		{ name: 'employeeGrantNumber', label: 'Employee Grant Number' },
		{ name: 'department', label: `Department at ${formatDate(reportStartDate)}` },
		{ name: 'employeeType', label: `Employee type at ${formatDate(reportStartDate)}`, format: (val) => EmployeeStatus[val] },
		{ name: 'employmentEntity', label: `Entity at 1/1/2021 ${formatDate(reportStartDate)}` },
		{ name: 'awardType', label: 'Award Type', format: (val) => ExpensingAwardType[val] },
		{ name: 'grantDate', label: 'Grant Date', format: (val) => formatDate(val) },
		{ name: 'fairValue', label: 'B&S value Per Award', format: (val) => getTextWithCurrency(val, reportCurrency) },
		{ name: 'totalExpensePreForfeiture', label: 'Total Expense (Pre Forfeitures)', format: (val) => getTextWithCurrency(val, reportCurrency) },
		{ name: 'grantedShares', label: 'Granted Shares', format: (val) => formatDecimal(val) },
		{ name: 'actualForfeiture', label: 'Forfeiture Adjustments', format: (val) => formatDecimal(val) },
		{ name: 'forfeitureRate', label: 'Forfeiture Adjustments', format: (val) => getPercentrage(val) },
		{ name: 'estimatedForfeitures', label: 'Forfeiture Adjustments', format: (val) => formatDecimal(val) },
		{ name: 'totalExpensePostForfeiture', label: 'Total Expense (Post Forfeitures)', format: (val) => getTextWithCurrency(val, reportCurrency) },
	];

	onExportToExcel({
		rows: exportToExcelData.totalExpenseEntries,
		exportToExcel: { fileName: 'Grants (Expensing).xlsx', sheetName: 'Expense Post Forfeitures' },
		color: 'primary',
		columns: epWsColumns,
		workbook: eaWb,
		frozen: [2],
		onBeforeDataHandler: (ws) => {
			let header: string[] = [];
			ws.getRow(1).eachCell((cell) => header.push(cell.value?.toString() ?? ''));
			const borderColor = appConfig.style.colors.table.replace('#', '');

			for (let i = 0; i <= header.length; i++) {
				const lastIndex = header.lastIndexOf(header[i]); // Getting the last index of the title
				if (lastIndex === -1) break; // If not found, exit from the loop

				// Vertical merging if empty
				if (!ws.getCell(2, i + 1).value && lastIndex === i) {
					try {
						ws.mergeCells(1, i + 1, 2, i + 1);
					} catch (error) {}
				}

				// Settings right border to all the cells
				ws.getColumn(i + 1).eachCell((cell) => {
					cell.border = {
						...cell.border,
						right: { style: 'thin', color: { argb: borderColor } },
					};
				});

				// Settings right medium border by ranges
				ws.getColumn(lastIndex + 1).eachCell((cell) => {
					cell.border = {
						...cell.border,
						right: { style: 'thin', color: { argb: borderColor } },
					};
				});

				// 	// If last index is the current iterator, that means the title is only in one place, don't need to merge
				if (lastIndex === i) continue;

				// 	// Merging cells with try & catch (important to continue with the code)
				try {
					if (i === 10) {
						ws.mergeCells(1, i + 1, 1, lastIndex + 1);
					} else {
						ws.mergeCells(1, i + 1, 2, lastIndex + 1);
					}

					const mergedCell = ws.getCell(1, lastIndex);
					mergedCell.border = {
						...mergedCell.border,
						right: { style: 'thin', color: { argb: borderColor } },
					};
				} catch (error) {}
				//   i = lastIndex; // Setting the iterator to the last index to check the next title
			}
			return ws;
		},
		onAfterDataHandler: (ws) => {
			ws.getRow(1).height = 18;

			ws.getColumn(4).width = 26;
			ws.getColumn(5).width = 26;
			ws.getColumn(9).width = 28;
			ws.getColumn(12).width = 29;
			ws.getColumn(14).width = 29;

			ws.getCell(2, 11).value = 'Actual Forfeitures';
			ws.getCell(2, 12).value = 'Annual Unvested Forfeiture Rate';
			ws.getCell(2, 13).value = 'Estimated Forfeitures';

			ws.getRow(2).eachCell((cell) => {
				cell.fill = {
					type: 'pattern',
					pattern: 'solid',
					fgColor: {
						argb: appConfig.style.colors.color1.replace('#', ''),
					},
				};
				cell.font = {
					...cell.font,
					color: {
						argb: 'ffffff',
					},
					bold: true,
				};
				cell.alignment = {
					vertical: 'middle',
					horizontal: 'center',
				};
			});

			return ws;
		},
	});
}

export function exportDisclosureReportToExcel(data: OutstandinAwards) {
	const wb = new ExcelJS.Workbook();
	const workbookName = `Altshare - Disclosure report.xlsx`;
	const worksheetName = 'Outstanding Awards';
	let ws = wb.addWorksheet(worksheetName, {
		properties: {
			defaultColWidth: 25,
			defaultRowHeight: 20,
		},
		pageSetup: {
			horizontalCentered: true,
			verticalCentered: true,
		},
	});
	const getTextWithCurrency = (value: number) => {
		const c = currencies.find((c) => c.currencyId === data.reportCurrency);
		return `${c?.symbol}${formatDecimal(value)}`;
	};

	ws.addRows([
		['', 'Number of Awards', 'Weighted Average Exercise Price', 'Weighted Average Remaining Contractual Life', 'Aggregate Intrinsic Value'],
		[
			'Outstanding, beginning of period',
			formatNumber(data.outstandingOpenBalance.amount),
			getTextWithCurrency(data.outstandingOpenBalance.weightedAverageExercisePrice),
			'',
			getTextWithCurrency(data.outstandingOpenBalance.aggregateIntrisicValue),
		],
		[
			'Granted',
			formatNumber(data.grantedDuringPeriod.amount),
			getTextWithCurrency(data.grantedDuringPeriod.weightedAverageExercisePrice),
			'',
			getTextWithCurrency(data.grantedDuringPeriod.aggregateIntrisicValue),
		],
		[
			'Exercised/Released',
			formatNumber(data.exercisedDuringPeriod.amount),
			getTextWithCurrency(data.exercisedDuringPeriod.weightedAverageExercisePrice),
			'',
			getTextWithCurrency(data.exercisedDuringPeriod.aggregateIntrisicValue),
		],
		[
			'Cancelled/Forfeited',
			formatNumber(data.forfeited.amount),
			getTextWithCurrency(data.forfeited.weightedAverageExercisePrice),
			'',
			getTextWithCurrency(data.forfeited.aggregateIntrisicValue),
		],
		[
			'Expired',
			formatNumber(data.expired.amount),
			getTextWithCurrency(data.expired.weightedAverageExercisePrice),
			'',
			getTextWithCurrency(data.expired.aggregateIntrisicValue),
		],
		[
			'Outstanding, End of period',
			formatNumber(data.outstandingCloseBalance.amount),
			getTextWithCurrency(data.outstandingCloseBalance.weightedAverageExercisePrice),
			formatDecimal(data.outstandingCloseBalance.weightedAverageContractualLife),
			getTextWithCurrency(data.outstandingCloseBalance.aggregateIntrisicValue),
		],
		[
			'Exercisable, End of period',
			formatNumber(data.exerciseable.amount),
			getTextWithCurrency(data.exerciseable.weightedAverageExercisePrice),
			formatDecimal(data.exerciseable.weightedAverageContractualLife),
			getTextWithCurrency(data.exerciseable.aggregateIntrisicValue),
		],
	]);

	// Setting background to header
	ws.getRow(1).eachCell((cell, idx) => {
		// if (idx === 1) return;
		cell.fill = {
			type: 'pattern',
			pattern: 'solid',
			fgColor: {
				argb: appConfig.style.colors.color1.replace('#', ''),
			},
		};
		cell.font = {
			...cell.font,
			color: {
				argb: 'ffffff',
			},
			bold: true,
		};
		cell.alignment = {
			wrapText: true,
		};
	});

	const borderColor = appConfig.style.colors.color1Secondary.replace('#', '');
	ws.eachRow((row) => {
		row.height = 20;
		row.eachCell((cell, idx) => {
			cell.border = {
				...cell.border,
				top: {
					style: 'thin',
					color: {
						argb: borderColor,
					},
				},
				bottom: {
					style: 'thin',
					color: {
						argb: borderColor,
					},
				},
				left: {
					style: 'thin',
					color: {
						argb: borderColor,
					},
				},
				right: {
					style: 'thin',
					color: {
						argb: borderColor,
					},
				},
			};
			cell.alignment = {
				...cell.alignment,
				vertical: 'middle',
				horizontal: idx === 1 ? 'left' : 'center',
			};
			const cellValue = cell.value?.toString() || '';
			const isNumber = /^[+-]?\d+(\.\d+)?$/.test(cellValue.replaceAll(',', ''));
			if (isNumber) {
				cell.value = parseFloat(cellValue.replaceAll(',', ''));
				cell.numFmt = cell.value % 1 === 0 ? '#,##0' : '#,##0.00';
			}
		});
	});

	ws.getRow(1).height = 30;

	ws.getColumn(1).width = 29;
	ws.getColumn(2).width = 20;
	ws.getColumn(3).width = 20;
	ws.getColumn(4).width = 25;
	ws.getColumn(5).width = 20;

	wb.xlsx.writeBuffer({ useStyles: true }).then((buffer) => {
		saveAs(new Blob([buffer], { type: 'application/octet-stream' }), workbookName);
	});
}
