import { css } from "@emotion/css";
import { breakpoints } from "../../../../config/style/theme";

export const ValuationStyle = css({
	label: "Valuation",

	".noData": {
		// marginTop: "1.3rem",

		"&__title": {
			fontSize: "1.8rem",
		},

		"&__background": {
			height: "100%",
			width: "100%",
			borderRadius: "8px",
			opacity: 0.8,
			background: "linear-gradient(0deg, #BDC3F1 0%, #F0F1FF 45.83%, rgba(255, 255, 255, 0.00) 100%)",
		},
	},

	".chartContainer": {
		height: "100%",
		width: "100%",
	},

	".infoContainer": {
		width: "fit-content",
		height: "fit-content",
		marginInlineStart: "1rem",

		"&__title": {
			whiteSpace: "nowrap",
			marginBottom: "1.6rem",
		},

		"&__info": {
			fontWeight: "bold",
		},
	},

	[`@media screen and (max-width: ${breakpoints.lg})`]: {
		".chartContainer": {
			height: "100%",
			width: "75% !important",
		},
		".infoContainer": {
			"&__title": {
				fontSize: "1.6rem",
				marginBottom: "0.8rem",
			},

			"&__info": {
				fontSize: "1.6rem",
			},
		},
	},
});
