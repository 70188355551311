import { useEffect, useState } from "react";
import useRootStore from "./useRootStore";
import { isNullOrUndefined } from "../Utilities";

type onSuccessFunction = (token: string) => void;
type onErrorFunction = (error: unknown) => void;

const useRecaptcha = () => {
    const { auth } = useRootStore();
    const [captchaReady, setCaptchaReady] = useState<boolean>(false);

    useEffect(() => {
        if (isNullOrUndefined(auth.recaptchaKey) || captchaReady) return;

        // Add reCaptcha
        const script = document.createElement("script");
        script.src = `https://www.google.com/recaptcha/api.js?render=${auth.recaptchaKey}`;
        script.addEventListener("load", () => {
            window.grecaptcha.ready(() => {
                setCaptchaReady(true);
            });
        });
        document.body.appendChild(script);

        return () => {
            // Clean up the tag and the badge
            document.querySelector(".grecaptcha-badge")?.remove();
            document.body.removeChild(script);
            setCaptchaReady(false);
        };
    }, [auth.recaptchaKey]);

    const execute = async (onSuccess: onSuccessFunction, onError?: onErrorFunction) => {
        try {
			if (!captchaReady) throw new Error('Unable to load reCAPTCHA');
			const token = await window.grecaptcha.execute(auth.recaptchaKey, {
				action: 'homepage',
			});
			return onSuccess(token);
		} catch (error) {
			let message = error;
			if (error instanceof Error) message = error.message;
			onError?.(message);
		}
    };

    return {
        execute,
    };
};

export default useRecaptcha;
