import { makeAutoObservable } from 'mobx';

import { RootStore } from './RootStore';
import EventHandler from '../Shared/Middleware/EventHandler';
import { makePersistable } from 'mobx-persist-store';
import { CompanyAdmin, IContact, IContactCreate } from '../Models/API/Contact/contact';
import ContactService from '../Services/ContactService';
import { UsersPermissionsByCompany } from '../Models/API/UsersAndPermissions/users-permissions-by-company';
import { mapToContactUpdate } from '../utils/mapper';
import { isNullOrUndefined, isNumber, isString } from '../Shared/Utilities';
import { ShareInvitation, UserPermissions } from '../Models/API/UsersAndPermissions/user-permissions-info';

export class ContactStore {
	contactService: ContactService = new ContactService(0);
	initiated = false;
	contact: IContact = {} as IContact;
	usersAndPermissionsList: UsersPermissionsByCompany[] | undefined = undefined;
	currentSecondaryContactId: number | null = null;
	similarContacts: IContact[] = [];
	companyContacts: IContact[] = [];
	lastFetchedContactsTime: Date | null = null;
	isContactUpdated = false;
	contactCreateState: IContactCreate = {} as IContactCreate;
	editContactId: number | undefined = 0;
	companyAdmin: CompanyAdmin = {} as CompanyAdmin;

	get contactId() {
		return this.contact?.contactId ?? 0;
	}

	get loggedContactId() {
		return this.companyContacts.find((c) => c.userId === this.rootStore.userStore.userId)?.contactId;
	}

	get contactProfile() {
		return this.contact;
	}

	get similarContactsList() {
		return this.similarContacts;
	}

	get companyContactsList() {
		return this.companyContacts;
	}

	get getContactById() {
		return (contactId: number) => this.companyContactsList.find((u) => u.contactId === contactId);
	}

	get getContactFullNameById() {
		return (contactId: number | null | undefined, fallback: string = '') => {
			if (isNullOrUndefined(contactId)) return '';
			const contact = this.getContactById(contactId);
			return contact ? `${contact.firstName} ${contact.lastName}` : fallback;
		};
	}

	set setCompanyAdmin(admin: CompanyAdmin) {
		this.companyAdmin = admin;
	}

	async getCompanyAdmin() {
		const res = await this.contactService.getCompanyAdmin();
		if (!res.data) return;

		this.setCompanyAdmin = res.data;
	}

	get getUserById() {
		return (userId: number) => this.companyContactsList.find((u) => u.userId === userId);
	}

	get contactUpdated() {
		return this.isContactUpdated;
	}

	get contactById() {
		return (contactId: number | null | undefined) => {
			if (isNullOrUndefined(contactId)) return;
			return this.companyContactsList.find((u) => u.contactId === contactId);
		};
	}

	get isContactUser() {
		return (contactId: number | null | undefined) => isNumber(this.contactById(contactId)?.userId);
	}

	get lastFetchedContacts() {
		return this.lastFetchedContactsTime;
	}

	setContact(contact: IContact) {
		this.contact = contact;
	}

	setEditContactId(id: number | undefined) {
		this.editContactId = id;
	}

	setCurrentSecondaryContactId(contactId: number | null) {
		this.currentSecondaryContactId = contactId;
	}

	setSimilarContacts(similarContacts: IContact[]) {
		this.similarContacts = similarContacts;
	}

	setCompanyContacts(companyContacts: IContact[]) {
		this.companyContacts = companyContacts;
	}

	setContactUpdated(isContactUpdated: boolean) {
		this.isContactUpdated = isContactUpdated;
	}

	setLastFetchedContacts(lastFetchedContactsTime: Date | null) {
		this.lastFetchedContactsTime = lastFetchedContactsTime;
	}

	setUsersAndPermissionsList(usersAndPermissionsList: UsersPermissionsByCompany[]) {
		this.usersAndPermissionsList = usersAndPermissionsList.filter((user) => user);
	}

	clear() {
		this.contact = {} as IContact;
		this.similarContacts = [];
		this.isContactUpdated = false;
		this.currentSecondaryContactId = null;
		this.contactCreateState = {} as IContactCreate;
	}

	onContactChange = new EventHandler<ContactStore, IContact>();

	constructor(private rootStore: RootStore) {
		makeAutoObservable(this);
		this.onContactChange.subscribe(this.initServices);
		rootStore.companyStore.onCompanyChange.subscribe(() => {
			if (!this.rootStore.companyStore.companyId) return;
			this.companyContacts = [];
			this.getCompanyContacts(true);
			this.getCompanyAdmin();
		});

		makePersistable(this, {
			name: 'ContactStore',
			properties: ['contact', 'similarContacts', 'companyContacts', 'companyAdmin'],
			storage: window.sessionStorage,
			expireIn: 10800000, // 3 hours
		});
	}

	private initServices = () => {
		this.contactService = new ContactService(this.rootStore.companyStore.companyId);

		RootStore.subscribeToLoading([this.contactService], this.rootStore);

		if (this.initiated) {
			this.init();
		}
	};

	resetStoreToDefaultValues = () => {
		this.contact = {} as IContact;
		this.similarContacts = [];
		this.companyContacts = [];
	};

	createContact = async (
		contact: IContactCreate,
		isUpdate: boolean = true,
		isUnique: boolean = true,
		isSearchByBoth: boolean = false
	): Promise<{ similarContacts: IContact[]; createdContactId: number | undefined }> => {
		const isLegalEntity = !!contact.isLegalEntity;

		let promises = [];
		let similarContacts: IContact[] = [];

		if (isSearchByBoth) {
			promises.push(
				this.getSimilarContacts(
					{
						email: contact.email,
					} as IContact,
					isLegalEntity
				)
			);

			promises.push(
				this.getSimilarContacts(
					{
						firstName: contact.firstName,
						lastName: contact.lastName,
					} as IContact,
					isLegalEntity
				)
			);
			similarContacts = (isUnique ? await Promise.all(promises) : [[]]).flat(1);
			similarContacts = similarContacts.filter(
				(cont) =>
					(isString(cont.email) && contact.email?.toLocaleLowerCase() === cont.email.toLocaleLowerCase()) ||
					(isNullOrUndefined(cont.email) &&
						(cont.firstName?.toLocaleLowerCase() === contact.firstName?.toLocaleLowerCase() ||
							cont.lastName?.toLocaleLowerCase() === contact.lastName?.toLocaleLowerCase()))
			);
			// similarContacts = similarContacts.reduce((acc, cont) => {
			//     const isUserMeargable = acc.some((c) => {
			//         return (
			//             (isString(cont.email) && c.email?.toLocaleLowerCase() === cont.email.toLocaleLowerCase()) ||
			//             (isNullOrUndefined(cont.email) &&
			//                 (cont.firstName.toLocaleLowerCase() === c.firstName.toLocaleLowerCase() ||
			//                     cont.lastName.toLocaleLowerCase() === c.lastName.toLocaleLowerCase()))
			//         );
			//     });
			//     if (isUserMeargable) {
			//         acc.push(cont);
			//     }
			//     return acc;
			// }, [] as IContact[]);
			// console.log("TCL: ContactStore -> similarContacts 3", similarContacts);
		} else {
			promises.push(
				this.getSimilarContacts(
					{
						firstName: contact.firstName,
						lastName: contact.lastName,
						companyName: contact.companyName,
						email: contact.email,
					} as IContact,
					isLegalEntity
				)
			);
			similarContacts = (isUnique ? await Promise.all(promises) : [[]]).flat(1);
		}

		if (similarContacts.length) {
			if (isLegalEntity) {
				const updatedContact = { ...mapToContactUpdate(contact), contactId: similarContacts[0].contactId };
				this.updateContact(updatedContact);
				this.setContact(updatedContact);
				this.setEditContactId(updatedContact.contactId);
				return { similarContacts: [], createdContactId: similarContacts[0].contactId };
			}

			return {
				similarContacts,
				createdContactId: undefined,
			};
		}

		contact.isLegalEntity = isLegalEntity;

		const res = await this.contactService.createContact({
			...contact,
			email: contact.email || undefined,
			companyId: this.rootStore.companyStore.companyId,
		});
		if (isNullOrUndefined(res.data)) {
			return {
				similarContacts: [],
				createdContactId: undefined,
			};
		}
		if (isUpdate) {
			this.setContact(res.data);
			this.setEditContactId(res.data.contactId);
		}
		this.setCompanyContacts([...this.companyContacts, res.data]);

		return {
			similarContacts: [],
			createdContactId: res.data.contactId,
		};
	};

	updateContact = async (contact: Partial<IContactCreate>, isUpdate: boolean = true) => {
		if (isNullOrUndefined(contact.contactId)) return;

		const res = await this.contactService.UpdateContact(contact, this.rootStore.companyStore.companyId);

		if (res.isSuccess) {
			if (isUpdate) {
				this.setContact(res.data);
				this.setContactUpdated(true);
			}
			this.setCompanyContacts(
				this.companyContacts.map((contact) => {
					if (contact.contactId === res.data.contactId) {
						return res.data;
					}
					return contact;
				})
			);

			if (this.usersAndPermissionsList) {
				const userIdx = this.usersAndPermissionsList.findIndex((user) => user.contactId === res.data.contactId);
				this.usersAndPermissionsList = [
					...this.usersAndPermissionsList.slice(0, userIdx),
					{
						...this.usersAndPermissionsList[userIdx],
						email: res.data.email || this.usersAndPermissionsList[userIdx]?.email,
					},
					...this.usersAndPermissionsList.slice(userIdx + 1),
				];
			}

			const contactIdx = this.companyContacts.findIndex((c) => c.contactId === res.data.contactId);

			if (contactIdx !== -1) {
				this.companyContacts = [
					...this.companyContacts.slice(0, contactIdx),
					{
						...res.data,
					},
					...this.companyContacts.slice(contactIdx + 1),
				];
			}
		}

		return res;
	};

	getContact = async (contactId: number) => {
		const res = await this.contactService.getContact(contactId);
		return res.data?.length ? res.data[0] : undefined;
	};

	getCompanyContacts = async (enforce: boolean = false): Promise<IContact[]> => {
		//check if last fetched contacts time is more than 10 min ago
		if (this.lastFetchedContacts && this.lastFetchedContacts.getTime() > new Date().getTime() - 600000 && !enforce) {
			return new Promise((res) => res(this.companyContacts));
		}

		this.setLastFetchedContacts(new Date());

		const res = await this.contactService.getCompanyContacts();

		if (res) {
			this.setCompanyContacts(res.data);
			return res.data;
		}

		this.setLastFetchedContacts(null);

		return [];
	};

	getSimilarContacts = async (data: Partial<IContact>, isLegalEntity: boolean) => {
		const res = await this.contactService.getContacts(data, isLegalEntity);

		if (res) {
			this.contact = {} as IContact;
			this.setSimilarContacts(res.data);
		}

		return res.data;
	};

	getUserPermissions = async () => {
		const res = await this.contactService.getUserPermissions();
		if (res.isSuccess && res.data) {
			this.setUsersAndPermissionsList(res.data);
		}
	};

	getCompanyEditors = () => {
		return this.contactService.getCompanyEditor();
	};

	inviteContact = async (contactId: number, permissions: Partial<UserPermissions>) => {
		try {
			return await this.contactService.inviteContact(contactId, permissions);
		} catch (error) {
			throw error;
		}
	};

	updateUserPermissions = async (userId: number, permissions: Partial<UserPermissions>) => {
		try {
			return await this.contactService.updateUserPermissions(userId, permissions);
		} catch (error) {
			throw error;
		}
	};

	onChangeUserPermission = async (contactId: number, userId: number = 0, userPermissions: Partial<UserPermissions>) => {
		if (userId) {
			await this.updateUserPermissions(userId, userPermissions);
		} else {
			await this.inviteContact(contactId, userPermissions);
		}
	};

	inviteUsers = (contactId: ShareInvitation[]) => {
		return this.contactService.inviteContacts(contactId);
	};

	updateContacts = async (contacts: Partial<IContactCreate>[]) => {
		const res = await this.contactService.updateContacts(contacts);
		if (res.data?.length) {
			this.companyContacts = this.companyContacts.map((contact) => {
				const existedContact = res.data.find((c) => c.contactId === contact.contactId);
				if (!existedContact) return contact;
				return {
					...contact,
					...existedContact,
				};
			});
		}

		return res;
	};

	getContactsById = (contactIds: number[]) => {
		return this.contactService.getUserPermissions(contactIds);
	};

	async init() {
		this.initiated = true;
	}
}
