import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Routes } from "../../../../Routes";
import TextInput from "../../../../Shared/Components/TextInput";
import useComplexState from "../../../../Shared/Hooks/useComplexState";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import { useTrans } from "../../../../Shared/Components/Trans";
import Button from "../../../../Shared/Components/Button/Button";
import { emptyCache } from "../../../../Shared/Utilities";
import { ApplicationEnum } from "../../../../Models/API/enums";
import { useObjectValidation } from "../../../../Shared/Hooks/useObjectValidation";
import { commonValidators } from "../../../../Shared/ObjectValidator";
import Flex from "../../../../Shared/Components/Layout/Flex";
import { IMG_RESET_PASSWORD, IMG_VERIFY_MAIL } from "../../../../Assets";
import ErrorMessage from "../../../../Shared/Components/Layout/ErrorMessage";
import classNames from "classnames";
import Text from "../../../../Shared/Components/Layout/Text";
import useScreenSize from "../../../../Shared/Hooks/useScreenSize";
import useRecaptcha from "../../../../Shared/Hooks/useRecaptcha";

interface User {
	email?: string;
	password?: string;
}

export const AccountForgotPassword = () => {
	const [userData, setUserData] = useComplexState<User>({});
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { execute } = useRecaptcha();

	const rootStore = useRootStore();
	const [errorMsg, setErrorMsg] = useState<JSX.Element | string>();
	const { t } = useTrans();
	const [didMount, setDidMount] = useState(false);
	const [isValidUser, userValState] = useObjectValidation(userData, {
		email: [commonValidators.required(), commonValidators.emailValidator],
	});

	const [emailSentState, setEmailSentState] = useState(false);
	const { isMobile, isTablet } = useScreenSize();

	useEffect(() => {
		setDidMount(true);
		return () => setDidMount(false);
	}, []);

	if (!didMount) {
		return null;
	}

	const handlePasswordReset = async () => {
		setIsSubmitted(true);

		if (!isValidUser || !userData.email) return;

		execute(async (token) => {
			setIsLoading(true);
			const res = await rootStore.auth.resetPassword(token, userData.email as string, ApplicationEnum.Cfo);

			if (res.isSuccess) {
				setIsLoading(false);

				if (res.data.isExternalAuth) {
					setErrorMsg("It seems that this is a gmail address, please log in with google.");
				} else {
					setErrorMsg(t("loginAndRegister.weHaveSentInstructionsToResetYour"));
					setEmailSentState(() => true);
				}
			} else {
				setIsLoading(false);
				setErrorMsg("Reset password failed. Please try again later");
			}
		});
	};

	const resendEmailText = (
		<Text size="1.6rem" width={"fit-content"} align="center">
			Didn't get an email?&nbsp;
			<Link className="account-link" onClick={() => handlePasswordReset()} to={undefined}>
				Click to resend
			</Link>
		</Text>
	);

	const sentEmailMessage = (
		<Text width={"fit-content"} size="2rem">
			<Flex width={"fit-content"} direction={isMobile ? "column" : "row"}>
				we've sent an email to&nbsp;
				<Text fontWeight={600}>{userData.email}</Text>
			</Flex>

			<Text width="100%" align="center">
				to reset your password
			</Text>
		</Text>
	);

	return (
		<Flex className={classNames("form-account-box login-box reset-password-box", { mobile: isMobile })}>
			<Flex
				direction="column"
				className={classNames({
					"password-reset-inner-container": !emailSentState,
					mobile: isMobile,
					tablet: isTablet,
				})}
			>
				<div className="form-login-title">{emailSentState ? "Check your email" : "Password reset"}</div>

				<img className="reset-password-img" src={emailSentState ? IMG_VERIFY_MAIL : IMG_RESET_PASSWORD} alt="reset password" />
				{emailSentState ? (
					<Flex direction="column" gap={20}>
						{sentEmailMessage}

						{resendEmailText}
					</Flex>
				) : (
					<Flex position="relative" direction="column" gap={20} padding={isMobile ? "0 3rem" : 0}>
						<TextInput
							qaid="AccountForgotPassword.Input.BusinessEmail"
							className="account-input account-input-password"
							placeholder="Business e-mail*"
							value={userData.email}
							hasError={isSubmitted && !userValState?.email?.isValid}
							onChange={(e) => {
								setErrorMsg("");
								setUserData({ email: e });
							}}
							onEnter={async () => {
								await handlePasswordReset();
							}}
						/>
						<ErrorMessage top={"5.5rem"} width="100%">
							{isSubmitted && userValState?.email?.message ? userValState?.email?.message : errorMsg}
						</ErrorMessage>
						<Button
							qaid="AccountForgotPassword.Button.Send"
							className={classNames(
								{
									"account-orange-butt--disabled": !isValidUser,
								},
								"account-orange-butt",
								"account-orange-butt--no-gap"
							)}
							position="center"
							isLoading={isLoading}
							onClick={async () => {
								handlePasswordReset();
							}}
							disabled={!isValidUser}
						>
							Send
						</Button>
					</Flex>
				)}
			</Flex>
			<Flex align="center" className={classNames("card-footer", { mobile: isMobile })}>
				<Link className="account-link" key="registration" to={Routes.account.login}>
					Back to Login
				</Link>
			</Flex>
		</Flex>
	);
};

export default AccountForgotPassword;
