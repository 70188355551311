import { css } from "@emotion/css";

const NewEquityPlanStyle = css({
	label: "NewEquityPlan",
	display: "flex",
	flexDirection: "column",
	padding: "6rem 7.7rem 6.7rem 7.7rem",
	width: '100rem',
	".innerButtons": {
		position: "relative",
	},
	"&__loading-spinner": {
		minHeight: "40rem",
	},
});

export default NewEquityPlanStyle;
