import { forwardRef, useContext, useImperativeHandle, useState } from "react";
import { ForwardedRef } from "../../../../../../Shared/Hooks/useMultiStepForm";
import { CapTableImportStep } from "../../index.style";
import { css } from "@emotion/css";
import Select from "../../../../../../Shared/Components/Select/Select";
import { ImportContext } from "../../ImportContext";
import { CapTableImportColumnType } from "../../../../types";
import Button from "../../../../../../Shared/Components/Button/Button";
import ImportCapTable from "../../ImportCapTable";
import { isNullOrUndefined, isNumber } from "../../../../../../Shared/Utilities";

const Style = css({
    label: "CommonShares",
    display: "flex",
    flexDirection: "column",
    "&__text": {
        marginTop: "0.8rem",
        marginBottom: "2.8rem",
    },
    "&__change": {
        marginBottom: "2rem",
        "&.select": {
            marginBottom: "2.8rem",
            width: "70%",
        },
    },
});

const CommonShares = forwardRef<ForwardedRef, unknown>((_, forwardedRef) => {
    const { columns, onFileUpload, file, setColumns } = useContext(ImportContext);
    const [isChange, setIsChange] = useState<boolean>(false);

    const cols =
        columns?.filter(
            (col) => col.type === CapTableImportColumnType.shareholders || col.type === CapTableImportColumnType.commonShares
        ) || [];
    const [selectedCol, setSelectedCol] = useState<number>(
        cols.find((col) => col.type === CapTableImportColumnType.commonShares)?.id ?? -1
    );

    useImperativeHandle(forwardedRef, () => ({
        async onValidate() {
            return true;
        },
    }));

    const onChangeHandler = async (value: number | string) => {
        if (!isNumber(value)) return;

        setSelectedCol(value);
        setColumns((prevState) => {
            const prevCol = prevState?.find((col) => col.id === value);
            if (isNullOrUndefined(prevState) || isNullOrUndefined(prevCol)) return prevState;

            return prevState.map((col) => {
                if (col.type === CapTableImportColumnType.commonShares) {
                    return { ...col, type: prevCol.type };
                }
                if (col.id === prevCol.id) {
                    return { ...col, type: CapTableImportColumnType.commonShares };
                }
                return col;
            });
        });
    };

    return (
        <CapTableImportStep
            size="md"
            className={Style}
            scroll
        >
            <div className="bold">Imported cap table</div>
            <div className={`${Style}__text`}>
                We've recognized that the below marked column contains Common/Ordinary shares.
                <br />
                If it's not correct, press Change button and select from the list the right column title representing Common/Ordinary
                shares.
            </div>
            {isChange ? (
                <Select
                    qaid=""
                    options={columns
                        ?.filter((col) => col.type !== CapTableImportColumnType.shareholders)
                        .map((col) => ({ value: col.id, label: col.title }))}
                    value={selectedCol}
                    className={`${Style}__change select`}
                    onChange={onChangeHandler}
                />
            ) : (
                <Button
                    qaid=""
                    inverse
                    label="Change"
                    className={`${Style}__change`}
                    onClick={() => setIsChange(true)}
                />
            )}
            <ImportCapTable
                columns={cols}
                focus={isChange ? undefined : "border"}
                highlight={CapTableImportColumnType.commonShares}
                disabled={CapTableImportColumnType.shareholders}
            />
        </CapTableImportStep>
    );
});

export default CommonShares;
