import { forwardRef, useEffect, useMemo, useState } from 'react';
import { IVestingSchedule } from '../../../../../Models/App/EquityPlans/Company';
import AddButton from '../../../../../Shared/Components/Button/AddButton';
import Switch from '../../../../../Shared/Components/CircularSwitchToggle/Switch';
import Flex from '../../../../../Shared/Components/Layout/Flex';
import Table, { TableColumn } from '../../../../../Shared/Components/Table/Table';
import useModal from '../../../../../Shared/Hooks/useModal';
import useRootStore from '../../../../../Shared/Hooks/useRootStore';
import EquitySectionStyle from '../../EquitySection.style';
import NewVestingSchedule from './NewVestingSchedule/NewVestingSchedule';
import { isNullOrUndefined } from '../../../../../Shared/Utilities';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { MultiStepElementProps, ForwardedRef } from '../../../../../Shared/Hooks/useMultiStepForm';
import { StepTitle } from '../../Registration/Registration.Style';
import Button from '../../../../../Shared/Components/Button/Button';
import { CompanyRegistrationEnum } from '../../Registration/Registration';
import useEsopModals from '../../../useEsopModals';

interface Props extends MultiStepElementProps {
	isRegistration?: boolean;
}

const VestingScheduleTable = forwardRef<ForwardedRef, Props>(({ isRegistration = false, ...props }, _) => {
	const { equityPlansStore } = useRootStore();
	const { showModal, removeCurrentModal } = useModal();
	const { showSuccessModal } = useEsopModals();
	// const [vestIds, setVestIds] = useState<{ [key: string]: boolean }>({});

	useEffect(() => {
		setActiveIds();
	}, [equityPlansStore.companyActiveVests]);

	const setActiveIds = () => {
		if (isNullOrUndefined(equityPlansStore.companyData?.vestings)) return;
		let obj: { [key: string]: boolean } = {};

		equityPlansStore.companyData?.vestings.forEach((vst) => {
			obj[vst.vestingId] = vst.active;
		});
		// setVestIds(obj);
	};

	const toggleVesting = (vestingId: number, active: boolean) => {
		const vestingIdx = equityPlansStore.companyData?.vestings.findIndex((vesting) => vesting.vestingId === vestingId);
		if (vestingIdx === -1 || isNullOrUndefined(vestingIdx) || isNullOrUndefined(equityPlansStore.companyData)) return;
		equityPlansStore.companyData = {
			...equityPlansStore.companyData,
			vestings: [
				...equityPlansStore.companyData.vestings.slice(0, vestingIdx),
				{
					...equityPlansStore.companyData.vestings[vestingIdx],
					active,
				},
				...equityPlansStore.companyData.vestings.slice(vestingIdx + 1),
			],
		};
	};

	const newVestSHandler = () => {
		showModal({
			body: (
				<NewVestingSchedule
					onSave={() => {
						removeCurrentModal();
						showSuccessModal('The vesting schedule was added successfully');
					}}
				/>
			),
			isMust: true,
			width: '60%',
		});
	};

	const handleCompanyVestConnection = (id: number, connect: boolean) => {
		// if (connect) {
		// 	console.log(`connecting vest ${id}`);
		// } else {
		// 	console.log(`DIS-connecting vest ${id}`);
		// }

		equityPlansStore.AddRemoveVestFromCompany(connect, id);

		toggleVesting(id, connect);

		// setVestIds((prevVal) => {
		// 	prevVal[id] = connect;
		// 	console.log(prevVal);
		// 	return prevVal;
		// });
	};

	const vestsColumns: TableColumn<IVestingSchedule>[] = [
		{ label: 'Name', name: 'vestingName', style: { flex: 2 } },
		{ label: 'Description', name: 'description', style: { flex: 7 } },
		{
			label: 'Active',
			name: 'active',
			style: { flex: 1 },
			render(obj) {
				return (
					<Switch
						qaid=""
						id={obj.vestingId.toString()}
						value={obj.active}
						checked={!!obj.active}
						onChange={(isChecked) => {
							handleCompanyVestConnection(obj.vestingId, isChecked);
							// setRender(render + 1);
						}}
						color="primary"
						size="small"
					/>
				);
			},
		},
		// {
		// 	label: "",
		// 	name: "menu",
		// 	menuItems: [
		// 		{
		// 			onClick: (obj) => { },
		// 			element: <img src={IC_EDIT_GRAY} alt="edit" />,
		// 			qaid: "CompanySettings.Button.EditVesting",
		// 		},
		// 		{
		// 			onClick: (obj) => { },
		// 			element: <img src={IC_TRASH2} alt="delete" />,
		// 			qaid: "CompanySettings.Button.DeleteVesting",
		// 		},
		// 	],
		// },
	];

	return (
		<div
			style={
				isRegistration
					? {
							padding: '6rem 7.7rem 6.7rem 7.7rem',
							width: '100rem',
					  }
					: {}
			}
		>
			{isRegistration && (
				<Flex direction="column" margin="0 0 1rem 0" justify="start" align="start">
					<span className={StepTitle}>Vesting Schedule Templates</span>
					<span className={'subTitle'}>Select vesting schedule templates</span>
				</Flex>
			)}
			<Table
				columns={vestsColumns}
				rows={equityPlansStore.companyData?.vestings}
				// border={{ bottom: true }}
				className={`${EquitySectionStyle}__table`}
				scrollAfterRows={5}
				// onRowClick={handleVestingModal}
				// onRowRender={handleVestingModal}
				renderBottom={
					<Flex align="center" justify="start">
						<AddButton onClick={isRegistration ? props.onNextStep : newVestSHandler} qaid="" label="Create new vesting schedule" />
					</Flex>
				}
			/>
			{isRegistration && (
				<Flex justify="end" gap="1rem" margin="2rem 0 0 0">
					<Button qaid="Registration.Button.Back" className={'buttonsContainer__button'} onClick={props.onPrevStep} cancel>
						Back
					</Button>
					<Button
						qaid="Registration.Button.Next"
						className={'buttonsContainer__button'}
						onClick={() => props.goTo?.(CompanyRegistrationEnum.companyData)}
					>
						Next
					</Button>
				</Flex>
			)}
		</div>
	);
});

export default observer(VestingScheduleTable);
