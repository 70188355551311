import { css } from "@emotion/css";
import appConfig from "../../../../config/config";

export const TableActionsStyle = css({
	label: "TableActions",
	// border: '1px solid black',
	padding: ".5rem 1rem",
	// '&:hover': {
	//     backgroundColor: appConfig.style.colors.background3,
	//     border: `1px solid ${appConfig.style.colors.border1}`,
	//     borderRadius: '12px',
	// }
});

export const TableActionsStyle2 = css`
	color: red;
`;
