import { css } from "@emotion/css";
import appConfig from "../../../config/config";

const EquitySettingsStyle = css({
	label: "EquitySettings",
	paddingTop: "1rem",
	// background: appConfig.style.colors.background1,
	display: "flex",
	flexDirection: "column",

	".return-btn": {
		padding: "3rem",
	},

	'.top': {
		display: "flex",
		flexDirection: 'row'
	},

	".es-section": {
		position: "relative",
		background: "#ffffff",
		padding: "4.2rem 4rem",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		"@media screen and (max-width: 1200px)": {
			// left: "4rem",
			// maxWidth: "unset",
			justifyContent: "flex-end",
		},
		"@media screen and (max-width: 1600px)": {
			// left: "4rem",
			paddingTop: "5.6rem",
		},

		"&:not(:last-child)": {
			borderBottom: `1px solid ${appConfig.style.colors.background1}`,
		},
	},
	"&__table": {
		width: "100%",
		maxWidth: "120.28rem",
	},
	"&__data": {
		width: "100%",
		maxWidth: "120.28rem",
	},
	"&__title": {
		position: "absolute",
		left: "4rem",
		top: "4rem",
		transition: "top .3s",
		fontSize: "2rem",

		"@media screen and (max-width: 1600px)": {
			// left: "4rem",
			top: "1.4rem",
		},
	},
	"&__equity-plans": {},
	"&__vesting-schedules": {},
	"&__company-data": {},
});

export default EquitySettingsStyle;
