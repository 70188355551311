import { css } from '@emotion/css';
import { breakpoints } from '../../../../config/style/theme';
import appConfig from '../../../../config/config';

export const CardInformationStyle = css({
	label: 'CardInformation',
	borderRadius: '16px',
	border: `1px solid  ${appConfig.style.colors.table}`,
	backgroundColor: '#F0F1FF',
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	padding: '4rem',
	filter: 'drop-shadow(3px 3px 3px rgba(201, 202, 208, 0.40))',

	'&__title': {
		fontWeight: 'bold',
		color: '#4D4D4D',
		marginBottom: '3rem',
	},

	'.stripInput': {
		border: '1px solid #BAC0D9',
		height: '4rem',
		borderRadius: 6,
		backgroundColor: '#FFF',
		width: '100%',
		margin: 0,

		'&:not(:first-child)': {
			paddingTop: '1.2rem !important',

			'&:focuse': {
				paddingTop: '1.2rem !important',

				'.__PrivateStripeElement': {
					iframe: {
						height: '2.1rem !important',
					},
				},
			},
		},

		'.__PrivateStripeElement': {
			iframe: {
				height: '2.1rem !important',
			},
		},
	},

	'.stripCardsType': {
		width: '24rem',
		paddingBottom: '0.3rem',
	},

	'.stripInputLabel': {
		fontSize: '1.3rem',
		marginBottom: '0.2rem',
		whiteSpace: 'nowrap',
		width: 'fit-content',
		color: appConfig.style.colors.text2,
	},

	'.checkBoxContainer': {
		gap: '2.6rem',
		margin: '3.2rem 0 4rem 0',

		'.checkBox': {
			'&__check': {
				width: '2.4rem',
				minWidth: '0',
				margin: '0rem 1.5rem 0 0',
			},
			'&__text': {
				fontSize: '1.2rem',
				color: '#4D4D4D',
				lineHeight: '2.5rem',
			},
		},
	},

	'.footer': {
		flex: 0,
		justifyContent: 'space-between',
		// height: '4rem',
		marginTop: 'auto',

		'.badgesContainer': {
			justifyContent: 'start',

			'&__strip': {
				width: '40rem',
			},

			'&__pci': {
				width: '10rem',
				marginLeft: '3rem',
			},
		},

		'&__button': {
			width: '16rem',
			fontSize: '1.6rem',
		},
	},

	[`@media screen and (max-width: ${breakpoints.md})`]: {
		'.footer': {
			'.badgesContainer': {
				'&__strip': {
					width: '30rem',
				},

				'&__pci': {
					width: '7rem',
				},
			},
			'&__button': {
				width: '14rem',
			},
		},
	},

	[`@media screen and (max-width: ${breakpoints.xs})`]: {
		flexDirection: 'column',
		padding: '0rem',
		backgroundColor: '#F5F5FD',
		border: 'none',
		filter: 'none',

		'.stripInput:not(:first-child)': {
			border: 'none',
		},

		'.stripInputLabel': {
			display: 'none',
		},

		'.mobileCardInformation': {
			flexDirection: 'column',
			border: '1px solid #BAC0D9',
			borderRadius: '8px',
			margin: '3.6rem 0 0 0',
			backgroundColor: '#FFF',

			'&__stripCardsType': {
				width: '12rem',
			},

			'&__bottom': {
				borderTop: '1px solid #BAC0D9',
			},

			'&__borderRight': {
				borderRight: '1px solid #BAC0D9',
			},
		},

		'.footer': {
			flexDirection: 'column',

			'.badgesContainer': {
				justifyContent: 'space-between',
				marginBottom: '1.1rem',

				'&__strip': {
					width: '22rem',
				},

				'&__pci': {
					width: '7rem',
					marginLeft: '0rem',
				},
			},

			'&__button': {
				width: '100%',
				marginLeft: 'unset',
				height: '4rem',
			},
		},
	},
	'&__add-payment': {
		display: 'flex',
		gap: '0.8rem',
		marginTop: '3.2rem',
		color: appConfig.style.colors.color1,
		fontWeight: 500,
		marginLeft: '1rem',
		cursor: 'pointer',
	},
});
