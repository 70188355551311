import styled from "@emotion/styled";
import { DashboardPostRoundEnum } from "../../../FundingDashboard/FundingDashboard";

export const defaultColors = ["#99A2EB", "#828282", "#F4B16D", "#F5DC7F", "#BAC0D9", "#F6818F", "#E8EAF5"];
export const chartSettingsByOrder = [
    {
        key: DashboardPostRoundEnum["Current shareholders"],
        value: "#99A2EB",
        preOrder: 1,
        postOrder: 1,
    },
    {
        key: DashboardPostRoundEnum["Outstanding options"],
        value: "#F6818F",
        preOrder: 2,
        postOrder: 4,
    },
    {
        key: DashboardPostRoundEnum["Unallocted options"],
        value: "#F5DC7F",
        preOrder: 3,
        postOrder: 5,
    },
    {
        key: DashboardPostRoundEnum["New investors"],
        value: "#F4B16D",
        postOrder: 2,
    },
    {
        key: DashboardPostRoundEnum["Converted SAFE / Note holders"],
        value: "#CCCBCB",
        postOrder: 3,
    },
];

export const opaicChartColors = [
    "rgba(255, 99, 132, 0.2)",
    "rgba(54, 162, 235, 0.2)",
    "rgba(255, 206, 86, 0.2)",
    "rgba(75, 192, 192, 0.2)",
    "rgba(153, 102, 255, 0.2)",
    "rgba(255, 159, 64, 0.2)",
];
export const dashboardShareholderColors = ["#99A2EB", "#F6818F", "#F4B16D", "#F5DC7F", "#CCCBCB"];
export const dashboardShareClassesColors = [
    "#99A2EB",
    "#F2AB64",
    "rgb(243, 213, 105)",
    "#f78c99",
    "#BDC3F1",
    "#f5c89b",
    "#fae7a6",
    "#cccccc",
];

export const DOUGHNUT_CHART_MAX_SIZE_SM = "22rem";
export const DOUGHNUT_CHART_MAX_SIZE_XL = "17rem";
export const DOUGHNUT_CHART_SPACING = "1.6rem";

export const Wrapper = styled.div`
    label: DoughnutChartWrapper;
    max-width: ${DOUGHNUT_CHART_MAX_SIZE_XL};
    max-height: ${DOUGHNUT_CHART_MAX_SIZE_XL};
`;
