import { toast } from 'react-toastify';
import { CompanyLimitationsPopUpType } from '../Models/API/enums';

import EventHandler from '../Shared/Middleware/EventHandler';
import httpClient from '../Shared/Middleware/HttpClient';
import { rootStore } from '..';

export abstract class BaseService {
	httpLogin = httpClient(null, process.env.REACT_APP_API_URL_LOGIN);
	httpUserManagement = httpClient(null, process.env.REACT_APP_API_URL_USER_MANAGEMENT);
	httpCompany = httpClient(null, process.env.REACT_APP_API_URL_COMPANY);
	httpFm = httpClient(null, process.env.REACT_APP_API_URL_FM);
	httpOptions = httpClient(null, process.env.REACT_APP_API_URL_OPTIONS);
	httpLocal = httpClient(null, '/');
	httpPayment = httpClient(null, process.env.REACT_APP_API_URL_PAYMENT);
	httpReference = httpClient(null, process.env.REACT_APP_API_URL_REFERENCE);
	httpNotification = httpClient(null, process.env.REACT_APP_API_URL_NOTIFICATION);
	httpPivotal = httpClient(null, process.env.REACT_APP_API_URL_PIVOTAL);
	httpOrder = httpClient(null, process.env.REACT_APP_API_URL_ORDER);
	httpShareRep = httpClient(null, process.env.REACT_APP_API_URL_SHAREREP);

	onError = new EventHandler<BaseService, ExecuteResponse<any>>();
	onRequestStart = new EventHandler<BaseService, any>();
	onRequestEnd = new EventHandler<BaseService, any>();
	onPlanLimitationEncounter = new EventHandler<BaseService, CompanyLimitationsPopUpType>();
	async safeExecuteAsync<TReturn>(callback: () => Promise<TReturn>): Promise<ExecuteResponse<TReturn>> {
		try {
			if (this.onRequestStart) {
				this.onRequestStart.trigger(this, null);
			}
			const data = await callback();
			if (data) {
				const _data = data as any;
				if (_data.companyLimitationsPopUpType) {
					this.onPlanLimitationEncounter.trigger(this, _data.companyLimitationsPopUpType);
					return { data, isSuccess: false };
				}
			}
			return { data, isSuccess: true };
		} catch (e: any) {
			const { error, data } = e;
			// Special case when request is canceled - we dont notify and the message is not important
			if (error && error.message === 'cancel-planned') {
				return Promise.resolve({
					isSuccess: false,
					error: 'Canceled request',
					exception: error,
					data: null as any,
				});
			}

			if (error?.response?.status === 402) {
				rootStore.companyStore.setPlanLimitation({
					show: true,
					limitCode: data.limitCode,
				});
			}

			let errorStr = 'Server error';

			// Try to extract some message
			if (error) {
				if (error.errors && error.errors.lenght > 0) {
					errorStr = error.errors[0];
				} else {
					errorStr =
						error.response?.data?.message ||
						error.title ||
						error.message ||
						(error.statusCode === 500 || error.response?.data?.statusCode === 500 ? '500 (Internal Server Error)' : null) ||
						error.Description ||
						'Server error';
				}
			}

			if (this.onError) {
				this.onError.trigger(this, {
					isSuccess: false,
					error: errorStr,
					exception: error,
					data: null,
				});
			}

			// if (error && error.StatusCode !== 401) {
			//   toastError(errorStr);
			// }

			return Promise.resolve({
				isSuccess: false,
				error: errorStr,
				exception: error,
				data: null as any,
			});
		} finally {
			if (this.onRequestEnd) {
				this.onRequestEnd.trigger(this, null);
			}
		}
	}
}

export interface ExecuteResponse<T> {
	data: T;
	error?: string;
	exception?: any;
	isSuccess: boolean;
	authToken?: string;
	headers?: any;
}
