import React, { Dispatch, useState } from "react";
import Flex from "../../../../Shared/Components/Layout/Flex";
import { BillingInfoStyle } from "./BillingInfo.Style";
import TextInput from "../../../../Shared/Components/Input/TextInput";
import Button from "../../../../Shared/Components/Button/Button";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import useScreenSize from "../../../../Shared/Hooks/useScreenSize";
import Clickable from "../../../../Shared/Components/Clickable/Clickable";
import { AppendStateInputHandlerType } from "../../../../Shared/Hooks/useAppendState";
import { ICheckout } from "../../../../Models/App/PaymentModels";
import { ObjectValidationStatus } from "../../../../Shared/ObjectValidator";
import { InputRefs, InputValidationRef } from "../../../../Shared/Hooks/useFormValidation";
import Image from "../../../../Shared/Components/Image";
import { IC_EDIT2 } from "../../../../Assets";
import classNames from "classnames";
import { countries } from "../../../../Shared/StaticData/countries";
import AutoCompleteInput from "../../../../Shared/Components/Input/AutoCompleteInput";
import { getCountry, isNumber } from "../../../../Shared/Utilities";

interface IProps {
    readonly flex?: number;
    readonly isViewMode?: boolean;
    readonly inputRefs: InputRefs<ICheckout>;
    readonly checkoutInfo: ICheckout;
    readonly formValidationState: ObjectValidationStatus<ICheckout> | null | undefined;
    readonly setCheckoutInfo: Dispatch<React.SetStateAction<ICheckout>>;
    readonly onInputHandler: AppendStateInputHandlerType;
    readonly onBillingEditHandler?: (isOnEdit: boolean) => void;
}

const BillingInfo = (props: IProps) => {
    const { auth, companyStore } = useRootStore();
    const { isMobile } = useScreenSize();

    const [isViewMode, setIsViewMode] = useState<boolean>(props.isViewMode || true);
    const [tempCheckoutInfo, setTempCheckoutInfo] = useState<ICheckout>(props.checkoutInfo);
    const isValid = props.formValidationState?.email?.isValid && props.formValidationState?.companyName?.isValid;

    const onToggleView = () => {
        setIsViewMode((prev) => !prev);
        if (props.onBillingEditHandler) props.onBillingEditHandler(!isViewMode);
    };

    const onCancelHandler = () => {
        props.setCheckoutInfo(tempCheckoutInfo);

        onToggleView();
    };

    const onSaveHandler = () => {
        if (!isValid) return;

        setTempCheckoutInfo(props.checkoutInfo);
        onToggleView();
    };

    return (
        <div
            className={BillingInfoStyle}
            style={{ flex: props.flex }}
        >
            <Flex
                align="start"
                justify="between"
                flex={0}
                position="relative"
            >
                <span className={`${BillingInfoStyle}__title`}>Billing info</span>
                {isMobile && (
                    <Clickable
                        justify="start"
                        flex={0}
                        qaid="BillingInfo.Button.Edit"
                        onClick={onToggleView}
                    >
                        Edit
                    </Clickable>
                )}
                {isViewMode && !isMobile && (
                    <Clickable
                        justify="start"
                        flex={0}
                        qaid="BillingInfo.Button.Edit"
                        onClick={onToggleView}
                    >
                        <Image
                            className={`${BillingInfoStyle}__editButton`}
                            src={IC_EDIT2}
                        />
                    </Clickable>
                )}
            </Flex>
            {isMobile && (
                <Flex
                    flex={0}
                    justify="start"
                    direction="column"
                    align="start"
                    gap={1}
                >
                    <span>{tempCheckoutInfo.companyName}</span>
                    <span>{tempCheckoutInfo.email}</span>
                </Flex>
            )}
            {(!isMobile || !isViewMode) && (
                <Flex className="formContainer">
                    <span className="formContainer__title">Billing info</span>
                    <Flex className="firstInputRow">
                        <TextInput
                            qaid="BillingInfo.Input.Email"
                            containerClassName="mb-0"
                            width="100%"
                            label="Billing email"
                            placeholder="Add"
                            required
                            name="email"
                            value={props.checkoutInfo.email}
                            onChange={props.onInputHandler}
                            isViewMode={isViewMode && !isMobile}
                            error={props.formValidationState?.email?.message}
                            ref={(el: InputValidationRef) => (props.inputRefs.email = el)}
                        />
                        <TextInput
                            qaid="BillingInfo.Input.CompanyName"
                            containerClassName="mb-0"
                            width="100%"
                            label="Company name"
                            placeholder="Add"
                            required
                            name="companyName"
                            value={props.checkoutInfo.companyName}
                            onChange={props.onInputHandler}
                            isViewMode={isViewMode && !isMobile}
                            error={props.formValidationState?.companyName?.message}
                            ref={(el: InputValidationRef) => (props.inputRefs.companyName = el)}
                        />
                        {!isMobile && (
                            <>
                                <TextInput
                                    qaid="BillingInfo.Input.AddressLine1"
                                    containerClassName="mb-0"
                                    width="100%"
                                    label="Address Line 1"
                                    placeholder="Add"
                                    name="firstAddressLine"
                                    value={props.checkoutInfo.firstAddressLine}
                                    onChange={props.onInputHandler}
                                    isViewMode={isViewMode}
                                />
                                <TextInput
                                    qaid="BillingInfo.Input.AddressLine2"
                                    containerClassName="mb-0"
                                    width="100%"
                                    label="Address Line 2"
                                    placeholder="Add"
                                    name="secondAddressLine"
                                    value={props.checkoutInfo.secondAddressLine}
                                    onChange={props.onInputHandler}
                                    isViewMode={isViewMode}
                                />
                            </>
                        )}
                    </Flex>
                    <span className="mobileAddressLabel">Address</span>
                    <Flex className="secondInputRow">
                        {isMobile && (
                            <>
                                <TextInput
                                    qaid="BillingInfo.Input.AddressLine1"
                                    className="borderNone"
                                    containerClassName="mb-0"
                                    width="100%"
                                    label={isMobile ? undefined : "Address Line 1"}
                                    placeholder={isMobile ? "Address Line 1" : "Add"}
                                    name="firstAddressLine"
                                    value={props.checkoutInfo.firstAddressLine}
                                    onChange={props.onInputHandler}
                                    isViewMode={isViewMode && !isMobile}
                                />
                                <TextInput
                                    qaid="BillingInfo.Input.AddressLine2"
                                    className="borderTopBottom"
                                    containerClassName="mb-0"
                                    width="100%"
                                    label={isMobile ? undefined : "Address Line 2"}
                                    placeholder={isMobile ? "Address Line 2" : "Add"}
                                    name="secondAddressLine"
                                    value={props.checkoutInfo.secondAddressLine}
                                    onChange={props.onInputHandler}
                                    isViewMode={isViewMode && !isMobile}
                                />
                            </>
                        )}
                        <Flex gap={isMobile ? 0 : "1.9rem"}>
                            <TextInput
                                qaid="BillingInfo.Input.City"
                                className="borderRight"
                                containerClassName="mb-0"
                                width="100%"
                                label={isMobile ? undefined : "City"}
                                placeholder={isMobile ? "City" : "Add"}
                                name="city"
                                value={props.checkoutInfo.city}
                                onChange={props.onInputHandler}
                                isViewMode={isViewMode && !isMobile}
                            />
                            <TextInput
                                qaid="BillingInfo.Input.Zip"
                                className="borderNone"
                                containerClassName="mb-0"
                                width="100%"
                                label={isMobile ? undefined : "Zip"}
                                placeholder={isMobile ? "Zip" : "Add"}
                                name="zipCode"
                                value={props.checkoutInfo.zipCode}
                                onChange={props.onInputHandler}
                                isViewMode={isViewMode && !isMobile}
                            />
                        </Flex>

                        <Flex
                            gap={isMobile ? 0 : "1.9rem"}
                            className="borderTop"
                        >
                            <AutoCompleteInput
                                qaid="BillingInfo.Select.Country"
                                containerClassName={classNames("mb-0", "borderRight", {
                                    borderNone: isMobile,
                                })}
                                className={classNames({
                                    borderNone: isMobile,
                                })}
                                name="country"
                                width="100%"
                                label={isMobile ? undefined : "Country"}
                                isViewMode={isViewMode && !isMobile}
                                placeholder={isMobile ? "Country" : "Add"}
                                options={countries}
                                autoComplete="nope"
                                value={getCountry(props.checkoutInfo.country)?.value}
                                onChange={(c) => {
                                    if (!isNumber(c)) return;
                                    const country = getCountry(c);
                                    props.setCheckoutInfo({ ...props.checkoutInfo, country: country?.label });
                                }}
                            />
                            <TextInput
                                qaid="BillingInfo.Input.State"
                                className="borderNone"
                                containerClassName="mb-0"
                                width="100%"
                                label={isMobile ? undefined : "State"}
                                placeholder={isMobile ? "Add" : "Add"}
                                name="state"
                                value={props.checkoutInfo.state}
                                onChange={props.onInputHandler}
                                isViewMode={isViewMode && !isMobile}
                            />
                        </Flex>
                    </Flex>
                    {(!isViewMode || isMobile) && (
                        <Flex className="buttonsContainer">
                            <Button
                                qaid="BillingInfo.Button.Cancel"
                                className="buttonsContainer__button"
                                label="Cancel"
                                cancel
                                onClick={onCancelHandler}
                            />
                            <Button
                                qaid="BillingInfo.Button.SaveChanges"
                                className="buttonsContainer__button"
                                label="Save changes"
                                disabled={!isValid}
                                onClick={onSaveHandler}
                            />
                        </Flex>
                    )}
                </Flex>
            )}
        </div>
    );
};

export default BillingInfo;
