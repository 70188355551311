import styled from "@emotion/styled";
import appConfig from "../../../config/config";

const Label = styled.div({
	label: "Label",
	color: appConfig.style.colors.text2,
	fontSize: "1.3rem",
	marginBottom: "0.4rem",
});

export default Label;
