import React, { FC, ReactNode } from "react";
import RadioButton, { RadioButtonOptions } from "../RadioButton/RadioButton";
import { StyledQuestionCard as Styled } from "./QuestionCard.Style";
import Hr from "../HorizontalRule/Hr";
import Tooltip from "../Tooltip";

export type SelectableOption = string | number | boolean | undefined;

export interface QuestionCardProps {
	children?: React.ReactNode;
	isActive?: boolean;
	isDisabled?: boolean;
	onChange: (value: any) => void;
	options: RadioButtonOptions[];
	question: string;
	selectedOption?: SelectableOption; // Use the type alias here
	tooltip?: ReactNode;
	withHrTop?: boolean;
	withHrBottom?: boolean;
	disableTooltip?: string;
}

const QuestionCard: FC<QuestionCardProps> = ({
	children,
	isActive,
	isDisabled,
	onChange,
	options,
	question,
	selectedOption,
	tooltip,
	withHrTop,
	withHrBottom,
	disableTooltip
}: QuestionCardProps) => {
	const OptionsElement = (<Styled.Options>
		{options?.map((option: RadioButtonOptions) => (
			<RadioButton key={option.value} {...option} onChange={onChange} checked={selectedOption === option.value} disabled={isDisabled} />
		))}
	</Styled.Options>)
	return (
		<>
			{withHrTop && (
				<Hr
					ap={{
						spacing: "sm",
					}}
				/>
			)}

			<Styled.QuestionCard className={`${isActive ? "QuestionCard is-active" : "QuestionCard"}`}>
				<Styled.CardMain>
					<Styled.Question>
						{question}
						{tooltip && tooltip}
					</Styled.Question>
					{
						disableTooltip ?
							<Tooltip title={disableTooltip}>
								{OptionsElement}
							</Tooltip>
							: OptionsElement
					}
				</Styled.CardMain>
				{children && <Styled.Children>{isActive && children}</Styled.Children>}
			</Styled.QuestionCard>
			{withHrBottom && (
				<Hr
					ap={{
						spacing: "sm",
					}}
				/>
			)}
		</>
	);
};

export default QuestionCard;
