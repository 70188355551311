import classNames from "classnames";
import { createRef, forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputRef, NumberInputProps } from "../../../Models/App/Inputs/types";
import {
    REGEX_NUMBERS_FLOAT,
    REGEX_PERCENTAGE_6,
    checkQaidConvention,
    formatDecimal,
    formatNumber,
    isNullOrUndefined,
    isObject,
} from "../../Utilities";
import Flex from "../Layout/Flex";
import { StyledInput, inputClassName } from "./Input.Style";
import InputWrapper from "./InputWrapper";
import OverflowText from "../OverflowText";

const DECIMAL_LENGTH = 6;

const NumberInput = forwardRef<InputRef, NumberInputProps>(
    ({ number = "int", percentage, onOptionSelect, selectedValue, ratio, onEnter, errorOnBlur, prependText = "", ...props }, ref) => {
        checkQaidConvention(props.qaid);
        const { t } = useTranslation();
        const [isFocusOnce, setIsFocusOnce] = useState<boolean>(false);
        const inputRef = createRef<HTMLInputElement>();
        const [internalValue, setInternalValue] = useState<string>("");
        const [error, setError] = useState<string>();

        const isInt = number === "int";

        useEffect(() => {
            if (isNullOrUndefined(props.value) || props.value === "") {
                setInternalValue("");
            } else if (percentage) {
                setInternalValue(formatDecimal(props.value)?.toString() || '');
            } else if (number || !isNaN(+props.value.toString().replaceAll(",", ""))) {
                // const value = REGEX_NUMBERS_FLOAT.test(props.value as string) ? "" : formatNumber(+props.value);
                setInternalValue(
                    isInt ? formatNumber(props.value as string) : formatDecimal(props.value as string, { decimalLength: DECIMAL_LENGTH })
                );
            }
        }, [props.value]);

        useImperativeHandle(ref, () => ({
            resetError() {
                setIsFocusOnce(false);
            },
            showError() {
                setIsFocusOnce(true);
                // !errorOnBlur && setIsFocusOnce(true);
            },
            focus(autoScroll: boolean = true) {
                inputRef?.current?.focus();
                autoScroll && inputRef?.current?.scrollIntoView({ behavior: "smooth" });
                // dateRef?.current?.setFocus();
            },
            clear() {
                setInternalValue("");
            },
            getName() {
                return props.name;
            },
        }));

        const showError = (isFocusOnce || props.forceValidation) && props.error;

        const onInputChange = (inputValue: string, inputName: string) => {
            if (percentage) {
                inputValue = inputValue.replace("%", "");
            }
            if (prependText) {
                inputValue = inputValue.replace(prependText, "");
            }
            // setIsFocusOnce(!errorOnBlur);
            const emitOnChange = (value?: number) => props.onChange?.(value, inputName);

            const isTargetEmpty = inputValue === "" || inputValue === undefined;

            if (percentage) {
                // percentage
                const intValue = +inputValue;
                // const regex = isObject(percentage) && percentage.decimalZeros ? REGEX_PERCENTAGE_3 : REGEX_PERCENTAGE;
                if (REGEX_PERCENTAGE_6.test(inputValue) || (intValue >= 0 && intValue < 100 && inputValue.endsWith("."))) {
                    setInternalValue(inputValue);
                    emitOnChange(parseFloat(inputValue));
                }
                if (isTargetEmpty) {
                    emitOnChange(undefined);
                    setInternalValue("");
                }
                return;
            }

            const valueWithoutCommas = inputValue.toString().replaceAll(",", "");

            if (!isInt) {
                // number === 'float'
                if (isObject(number) && valueWithoutCommas.split(".")[1]?.length - 1 === DECIMAL_LENGTH) {
                    return;
                }
                if (REGEX_NUMBERS_FLOAT.test(valueWithoutCommas)) {
                    setInternalValue(inputValue);
                    emitOnChange(parseFloat(valueWithoutCommas));
                }
            } else if (/^[0-9\b]+$/.test(valueWithoutCommas)) {
                // number === 'int'
                emitOnChange(+valueWithoutCommas);
            }
            if (isTargetEmpty) {
                setInternalValue("");
                emitOnChange(undefined);
            }
        };

        const onKeyDownHandler = (e: any) => {
            if (props.onKeyDown) return props.onKeyDown(e);
            if (percentage && e.key === "Backspace") {
                onInputChange?.(percentage ? e.target.value.substring(0, e.target.value.length - 2) : e.target.value, e.target.name);
            }
            if (e.key === "Enter") {
                onEnter?.(percentage ? e.target.value.replace("%", "") : e.target.value, e.target.name);
            }
        };

        return (
            <InputWrapper
                {...props}
                error={showError ? props.error : undefined}
                onOptionSelect={onOptionSelect}
                selectedValue={selectedValue}
            >
                {props.isViewMode ? (
                    <Flex
                        height="3.6rem"
                        align="end"
                        justify="start"
                    >
                        <OverflowText>{(number || percentage ? internalValue : props.value) || "---------"}</OverflowText>
                    </Flex>
                ) : (
                    <>
                        <StyledInput
                            {...props}
                            onKeyDown={onKeyDownHandler}
                            flex={props.options ? ratio || 1.5 : 1}
                            percentage={!!percentage && !!internalValue}
                            className={classNames(inputClassName, props.className || "", {
                                inverse: props.inverse,
                                flat: props.flat,
                            })}
                            disabled={props.disabled || props.isLoading || (!!props.options && isNullOrUndefined(selectedValue))}
                            onBlur={(e) => {
                                setIsFocusOnce(true);
                                props.onBlur?.(e);
                                const val = e.target.value.replace("%", "").replace(prependText, "");
                                if (val.endsWith(".")) {
                                    if (!percentage) return setInternalValue(val.replace(".", ""));
                                    setInternalValue(`${val}00`);
                                    props.onChange?.(parseFloat(`${val}00`), e.target.name);
                                }
                            }}
                            placeholder={props.placeholder ? t(props.placeholder) : props.placeholder || percentage ? "%" : prependText}
                            onChange={(e: any) => onInputChange(e.target.value, e.target.name)}
                            onClick={(e) => {
                                setIsFocusOnce(false);
                                e.stopPropagation();
                                props.onClick?.(e);
                            }}
                            isMerged={!!props.options && !!onOptionSelect}
                            value={
                                (number || percentage
                                    ? `${internalValue && !percentage && prependText ? prependText : ""}${internalValue}${
                                          internalValue && percentage ? "%" : ""
                                      }`
                                    : props.value) || ""
                            }
                            data-value={number || percentage ? internalValue : props.value}
                            onFocus={(e) => {
                                props.onFocus?.(e);
                            }}
                            ref={inputRef}
                            data-qaid={props.qaid}
                        />
                        {/* {percentage && <span className={`${Styles}__suffix`}>%</span>} */}
                    </>
                )}
            </InputWrapper>
        );
    }
);

export default NumberInput;
