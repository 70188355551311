import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { DataCollectionPayload } from '../../../../../Models/API/DataCollection/data-collection-payload';
import Spinner from '../../../../../Shared/Components/Spinner/Spinner';
import { useAppendState } from '../../../../../Shared/Hooks/useAppendState';
import useModal from '../../../../../Shared/Hooks/useModal';
import { formatDate, isNumber } from '../../../../../Shared/Utilities';
import CapTableExcelImport from '../../../../CapTable/Components/Import';
import { WaterfallStep } from '../../../../Waterfall/Components/AddEditWaterfall/index.style';
import useDataCollection from '../../../hooks/useDataCollection';
import importCapTableStyle from './index.style';
import useRootStore from '../../../../../Shared/Hooks/useRootStore';
import RadioButton from '../../../../../Shared/Components/RadioButton/RadioButton';
import { ProjectsUserInfo } from '../../../../../Models/API/CapTable';
import { ForwardedRef } from '../../../../../Shared/Hooks/useMultiStepForm';
import useGeneralModal from '../../../../../Shared/Hooks/useGeneralModal';
import AutoCompleteInput from '../../../../../Shared/Components/Input/AutoCompleteInput';
import { Collapse, Fade } from '@mui/material';

const ImportCapTable = forwardRef<ForwardedRef, any>((_, forwardedRef) => {
	const { capTableStore } = useRootStore();
	const { showModal } = useModal();
	const { data, setData } = useDataCollection();
	console.log('TCL: data', data);
	const { showErrorModal } = useGeneralModal();
	const [projects, setProjects] = useState<ProjectsUserInfo[] | null>();
	const [selectBy, setSelectedBy] = useState<'published' | 'import' | 'upload'>();

	useImperativeHandle(forwardedRef, () => ({
		onValidate: async () => {
			if (data.capTableId) return true;
			showErrorModal('Please select a cap table project first.', 'Invalid Cap Table');
			return false;
		},
	}));

	useEffect(() => {
		(async () => {
			const res = await capTableStore.loadUserProjects();
			setProjects(res.data.projectsUserList);
			const publishedCapTable = projects?.find((project) => project.isPublished);
			if (publishedCapTable) setSelectedBy('published');
		})();
	}, []);

	const onShowImportCapTable = () => {
		showModal({
			body: (
				<CapTableExcelImport
					onSubmit={(projectId) => {
						setData((prev) => ({ ...prev, capTableId: projectId }));
					}}
				/>
			),
			maximize: true,
		});
	};

	if (projects === undefined) return <Spinner center incorporated />;

	const publishedCapTable = projects?.find((project) => project.isPublished);

	const clearProjectId = () => setData((prev) => ({ ...prev, capTableId: null }));

	return (
		<WaterfallStep className={importCapTableStyle}>
			<div className={`${importCapTableStyle}__header`}>
				<span className="bold">Import cap table</span> (as of {formatDate(data.valuationDate)})
			</div>
			<div>
				<span>Please select where to import cap table from.</span>
				<div className={`${importCapTableStyle}__radios`}>
					<div className="flex flex-column">
						<RadioButton
							qaid=""
							name="capTableId"
							value={1}
							label={
								publishedCapTable ? `There is a published cap table as of ${formatDate(data.valuationDate)}` : 'There is no published cap table'
							}
							disabled={!publishedCapTable}
							checked={selectBy === 'published'}
							onChange={() => {
								setData((prev) => ({ ...prev, capTableId: publishedCapTable?.projectID ?? null }));
								setSelectedBy('published');
							}}
						/>
					</div>
					<div className="flex flex-column">
						<RadioButton
							qaid=""
							name="capTableId"
							value={1}
							label="Import cap table from drafts"
							onChange={() => {
								clearProjectId();
								setSelectedBy('import');
							}}
							checked={selectBy === 'import'}
						/>
						<Collapse in={selectBy === 'import'} unmountOnExit timeout={100}>
							<AutoCompleteInput
								qaid=""
								value={data.capTableId ?? undefined}
								name="capTableId"
								options={projects?.map((project) => ({ value: project.projectID, label: project.projectName }))}
								onChange={(capTableId) => isNumber(capTableId) && setData((prev) => ({ ...prev, capTableId }))}
								placeholder="Select draft..."
								width="23.5rem"
								containerStyle={{ marginTop: '2rem' }}
							/>
						</Collapse>
					</div>
					<div className="flex flex-column">
						<RadioButton
							qaid=""
							name="capTableId"
							value={1}
							label="Upload cap table from excel"
							checked={selectBy === 'upload'}
							onChange={() => {
								onShowImportCapTable();
								clearProjectId();
								setSelectedBy('upload');
							}}
						/>
					</div>
				</div>
			</div>
		</WaterfallStep>
	);
});

export default ImportCapTable;
