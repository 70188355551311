import { css } from '@emotion/css';
import CloseIcon from '@mui/icons-material/Close';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { IC_QUESTION_MARK_ORANGE, IC_QUESTION_MARK_ORANGE_FILL, IC_REORDER } from '../../../../Assets';
import { NumberOption } from '../../../../Models/API/All/NumberOption';
import { SignatoryDetails } from '../../../../Models/API/Document/template-info';
import { TemplateTypeEnum } from '../../../../Models/API/Document/template-type-enum';
import AddButton from '../../../../Shared/Components/Button/AddButton';
import Image from '../../../../Shared/Components/Image';
import AutoCompleteInput from '../../../../Shared/Components/Input/AutoCompleteInput';
import useRootStore from '../../../../Shared/Hooks/useRootStore';
import { getUniqueId, isNullOrUndefined, isNumber } from '../../../../Shared/Utilities';
import appConfig from '../../../../config/config';
import { getTemplateVariableName, isAdminOrEditor } from '../../helpers/utils';
import { UserEditorPermissions } from '../../../../Models/API/UsersAndPermissions/users-permissions-by-company';

const Style = css({
	label: 'Signatories',
	'.label': {
		marginBottom: '0.4rem',
		display: 'flex',
		color: appConfig.style.colors.text2,
		fontSize: '1.3rem',
		alignItems: 'flex-end',
		gap: '0.8rem',

		'@media (-webkit-device-pixel-ratio: 1.5)': {
			fontSize: '1.4rem',
		},

		'&__info': {
			width: '1.8rem',
		},

		'> span': {
			textAlign: 'initial',
		},
	},
	'.add-btn': {
		marginTop: 6,
		justifyContent: 'flex-start',
	},
	'&__list-container': {
		maxHeight: 'calc(22rem + 1px)',
		marginBottom: '2rem',
		'&.scroll': {
			overflow: 'auto',
			paddingRight: 6,
			width: 'calc(100% + 12px)',
		},
	},
	'&__signatory': {
		height: '5rem',
		display: 'flex',
		alignItems: 'center',
		paddingInline: '1.4rem',
		'> img': {
			marginLeft: 'auto',
		},
		'.name': {
			color: appConfig.style.colors.text1,
		},
		'.order': {
			flexBasis: '3.2rem',
		},
		'&:not(:first-child)': {
			border: `1px solid ${appConfig.style.colors.table}`,
			borderTop: 'none',
		},
		'&:last-child': {
			borderBottomLeftRadius: 8,
			borderBottomRightRadius: 8,
			border: `1px solid ${appConfig.style.colors.table}`,
			borderTop: 'none',
		},
		'&:first-child': {
			borderTopLeftRadius: 8,
			borderTopRightRadius: 8,
			border: `1px solid ${appConfig.style.colors.table}`,
		},
	},
	'&__delete-btn': {
		marginLeft: '1.6rem',
		width: '2.8rem',
		height: '2.8rem',
		background: 'rgba(255, 255, 255, 0.5)',
		boxShadow: `0px 1px 4px ${appConfig.style.colors.field}`,
		borderRadius: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'> svg': {
			fontSize: '2rem',
		},
	},
});

type Props = {
	data: SignatoryDetails[];
	setData: (signers: SignatoryDetails[]) => void;
	type?: TemplateTypeEnum;
};

const Signatories = ({ data, setData, type }: Props) => {
	const { contactStore } = useRootStore();
	const ref = useRef<HTMLDivElement>(null);
	const [editId, setEditId] = useState<Record<number, boolean>>({});
	const [editors, setEditors] = useState<UserEditorPermissions[]>();

	useEffect(() => {
		// if (contactStore.usersAndPermissionsList) return;
		(async () => {
			const res = await contactStore.getCompanyEditors();
			setEditors(res.data);
		})();
	}, []);

	const companySignatories: NumberOption[] = useMemo(() => {
		if (!editors) return [];

		let list: NumberOption[] =
			editors
				.filter((editor) =>
					type === TemplateTypeEnum.ShareCertificate ? editor.isCapTable : type === TemplateTypeEnum.Other ? true : editor.isEquityPlans
				)
				.map((user) => ({
					value: user.contactId,
					label: `${user.firstName} ${user.lastName}`,
				})) || [];

		const customOption = getTemplateVariableName(type);
		if (customOption) list = [customOption, ...list];

		return list;
	}, [editors, type]);

	const availableSignatories: NumberOption[] = useMemo(() => {
		return companySignatories.filter((s) => !data.some((added) => added.contactId === s.value));
	}, [data, companySignatories]);

	const onDragEnd = (result: any) => {
		if (isNullOrUndefined(result.destination)) return;

		const to = data.findIndex((c) => c.contactId === result.destination.index);
		const from = data.findIndex((c) => c.contactId === result.source.index);

		const arr: SignatoryDetails[] = data.concat([]);
		const oldStep = arr.splice(from, 1)[0];
		arr.splice(to, 0, oldStep);

		setData(arr.map((i, idx) => ({ ...i, positionForSignature: idx + 1 })));
	};

	const onToggleEdit = (contactId: number) => {
		setEditId((prevState) => ({
			...prevState,
			[contactId]: !prevState[contactId],
		}));
	};

	return (
		<div className={Style}>
			<div className="label">
				<span>Signatories (drag to reorder) *</span>
				<Image
					className={classNames('label__info')}
					src={IC_QUESTION_MARK_ORANGE}
					srcHover={IC_QUESTION_MARK_ORANGE_FILL}
					tooltip={
						<>
							Please select the designated signatories of documents created from this template. <br /> For sequential signing rule, the
							signatories order set will define the sequence of stepped signature flow.
						</>
					}
					pointer
				/>
			</div>
			<div className={classNames(`${Style}__list-container`, { scroll: data.length > 4 })}>
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId="signatoties">
						{(provided) => (
							<div ref={provided.innerRef} {...provided.droppableProps}>
								{data?.map((signatory, index) => (
									<Draggable
										draggableId={(signatory.contactId ?? 0).toString()}
										index={signatory.contactId ?? -1}
										key={signatory.contactId ?? 0}
									>
										{(provided, snapshot) => {
											// if (isNullOrUndefined(signatory.contactId)) signatory.contactId = -1;

											const s = companySignatories.find((s) => s.value === signatory?.contactId);
											return (
												<div
													className={`${Style}__signatory`}
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													onClick={() => onToggleEdit(signatory.positionForSignature)}
												>
													<span className="order">{signatory.positionForSignature ?? index}.</span>
													{!editId[signatory.positionForSignature] ? (
														<>
															<span ref={index === data.length ? ref : undefined} className="name">
																{isNumber(s?.value) ? s.label : 'Associated stakeholder'}
															</span>
															<Image src={IC_REORDER} width="1rem" />
														</>
													) : (
														<>
															<AutoCompleteInput
																qaid=""
																value={s?.value}
																options={availableSignatories}
																onBlur={() => onToggleEdit(signatory.positionForSignature)}
																onChange={(contactId) => {
																	if (!isNumber(contactId) || data.some((signer) => signer.contactId === contactId)) {
																		return setData(data.filter((d) => d.contactId !== -1));
																	}

																	setData([
																		...data.slice(0, signatory.positionForSignature - 1),
																		{
																			...data[signatory.positionForSignature - 1],
																			contactId,
																			isDesignatedCompanySignatory: contactId !== -1,
																		},
																		...data.slice(signatory.positionForSignature),
																	]);
																	onToggleEdit(signatory.positionForSignature);
																}}
															/>

															<span
																className={`${Style}__delete-btn`}
																onClick={() => {
																	const newData = data.reduce((acc, signer) => {
																		if (signer.contactId !== signatory.contactId) {
																			acc.push({ ...signer, positionForSignature: acc.length + 1 });
																		}
																		return acc;
																	}, [] as SignatoryDetails[]);
																	setData(newData);
																	// setData(data.filter((signer) => signer.contactId !== signatory.contactId));
																}}
															>
																<CloseIcon />
															</span>
														</>
													)}
												</div>
											);
										}}
									</Draggable>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</div>

			<AddButton
				className="add-btn"
				label="Add signatory"
				qaid="AddEditTemplate.Button.AddSignatory"
				disabled={data.some((signer) => !isNumber(signer.contactId)) || !isNumber(type)}
				onClick={() => {
					const newData = [
						...data,
						{
							contactId: null,
							positionForSignature: data.length + 1,
							isDesignatedCompanySignatory: true, // to check permission if admin or editor - admin = true
						} as SignatoryDetails,
					];
					setData(newData);
					onToggleEdit(newData.length);

					setTimeout(() => {
						ref.current?.scrollIntoView({ behavior: 'smooth' });
					}, 50);
				}}
			/>
		</div>
	);
};

export default observer(Signatories);
