import { ServerResponseApi } from '../Models/API/All/server-response';
import { DataCollectionCommentPayload } from '../Models/API/DataCollection/comment-payload';
import { DataCollectionComment } from '../Models/API/DataCollection/comment-response';
import { DataCollectionPayload } from '../Models/API/DataCollection/data-collection-payload';
import { DataCollectionProjectResponse } from '../Models/API/DataCollection/data-collection-project-response';
import { ValuationProject } from '../Models/API/DataCollection/valuation-project';
import { dataCollectionApi } from './Axios';

export class DataCollectionService {
	// Valuation Project

	createProject() {
		return dataCollectionApi.post<ServerResponseApi<ValuationProject>>('/valuationProject', { projectName: `Test_${+new Date()}` });
	}

	getProjects() {
		return dataCollectionApi.get<ServerResponseApi<ValuationProject[]>>('/valuationProject/company');
	}

	takeProjectOwnership(valuationProjectId: string) {
		return dataCollectionApi.post<ServerResponseApi<ValuationProject>>(`/valuationProject/owner/${valuationProjectId}`);
	}

	// Data Collection

	getDataCollection(valuationProjectId: string) {
		return dataCollectionApi.get<ServerResponseApi<DataCollectionProjectResponse>>(`/dataCollection/valuationProject/${valuationProjectId}`);
	}

	createDataCollection(data: Partial<DataCollectionPayload>) {
		return dataCollectionApi.post<ServerResponseApi<DataCollectionProjectResponse>>('/dataCollection', data);
	}

	updateDataCollection(data: DataCollectionPayload) {
		return dataCollectionApi.put<ServerResponseApi<DataCollectionProjectResponse>>('/dataCollection', data);
	}

	submitDataCollection(dataCollectionId: string, isCompleted: boolean) {
		return dataCollectionApi.post<ServerResponseApi<DataCollectionProjectResponse>>('/dataCollection', null, null, {
			dataCollectionId,
			dataCollectionCompleted: isCompleted,
		});
	}

	// Comment

	addComment(data: DataCollectionCommentPayload) {
		return dataCollectionApi.post<ServerResponseApi<DataCollectionComment>>('/comment', data);
	}

	// Document

	uploadDocument() {}
}
