import { useState } from "react";
import { IC_HINT } from "../../../../../Assets";
import { IContactCreate } from "../../../../../Models/API/Contact/contact";
import RadioButton from "../../../../../Shared/Components/RadioButton/RadioButton";
import { MergeContactProps } from "../../../types";
import ContactSuggestion from "./Cards/ContactSuggestion";
import NewContactCard from "./Cards/NewContactCard";
import MergeContactStyle from "./MergeContact.style";
import { isNullOrUndefined } from "../../../../../Shared/Utilities";

const MergeContact = ({ contact, contacts, removeModal, onConfirmHandler, onCreate, onMerge }: MergeContactProps) => {
	const [isNewUser, setIsNewUser] = useState<boolean>(true);

	const onCreateHandler = async (contact: IContactCreate) => {
		onCreate(contact);
		removeModal?.();
	};

	const onMergeHandler = async (contact: IContactCreate) => {
		if (isNullOrUndefined(contact.contactId)) return;
		onConfirmHandler?.();
		await onMerge(contact.contactId, contact);
	};

	return (
		<div className={MergeContactStyle}>
			<div className={`${MergeContactStyle}__warning-container`}>
				<div className={`${MergeContactStyle}__warning-img-wrapper`}>
					<img src={IC_HINT} alt="hint" />
				</div>
				<div className={`${MergeContactStyle}__warning-texts`}>
					<span className="danger">A user with this name already exists in the company's records.</span>
					<span>Please select whether to merge with one of the existing users or continue creating a new one</span>
				</div>
			</div>

			<div className={`${MergeContactStyle}__new-user-section`}>
				<RadioButton
					label="Create new user"
					qaid="MergeContact.Radio.NewUser"
					name="isNewUser"
					value={true}
					checked={isNewUser}
					onChange={(value) => setIsNewUser(value)}
				/>
				<NewContactCard contact={contact} onCreate={onCreateHandler} disabled={!isNewUser} />
			</div>
			<div className="separator">
				<span>Or</span>
			</div>
			<div className={`${MergeContactStyle}__user-suggestions-section`}>
				<RadioButton
					label="Merge with an existing user"
					qaid="MergeContact.Radio.MergeContact"
					name="isNewUser"
					value={false}
					checked={!isNewUser}
					onChange={(value) => setIsNewUser(value)}
				/>
				{contacts.map((contact, idx) => (
					<ContactSuggestion key={idx} contact={contact} onMerge={onMergeHandler} disabled={isNewUser} />
				))}
			</div>
		</div>
	);
};

export default MergeContact;
