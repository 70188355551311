import Flex from "../../../Shared/Components/Layout/Flex";
import { Skeleton } from "@mui/material";
import { RectangularSkeleton } from "../index.style";

const EquityLoader = () => {
	return (
		<Flex direction="column" align="start">
			{/* <Skeleton variant="rectangular" width={"22%"} height={"10%"} animation="wave" /> */}

			<Flex align="center" justify="center" gap={70}>
				<Skeleton variant="rectangular" width={"90%"} height={"60%"} animation="wave" className={RectangularSkeleton} />
			</Flex>
		</Flex>
	);
};

export default EquityLoader;
