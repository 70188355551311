/* tslint:disable */
//import { eInterestType } from './e-interest-type';
//import { eSafeType } from './e-safe-type';

export interface ConvertibleLoanTransactionData {
    transactionDate?: Date;
    moneyInvested?: number;
    valuationCap?: null | number;
    discount?: null | number;
    transactionId?: number;
    projectId?: number;
    projectPersonId?: number;
    //safeType?: eSafeType;
    maturityDate?: null | Date;
    interestRate?: number;
    interestType?: eInterestType;
    valuationCapType: CapTableValuationType;
}

export enum eInterestType {
    Compunded = 1,
    Annual = 2,
}

export enum CapTableValuationType {
    None,
    PreMoney,
    PostMoney,
}
