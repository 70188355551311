import React from 'react'
import TableDrawer from '../../../../ESOP/General/TableDrawer/TableDrawer'
import Table, { TableColumn } from '../../../../../Shared/Components/Table/Table'
import { cancelationsColumns, ordersColumns } from '../../TrusteeGrants/GrantCard/TrusteeGrantCard'
import cardStyle from '../../TrusteeGrants/GrantCard/TrusteeGrantCard.module.css'
import useRootStore from '../../../../../Shared/Hooks/useRootStore'
import { TrusteeBeneficiaries, TrusteeGrant } from '../../../Types/trusteeTypes'
import { observer } from 'mobx-react-lite'
import ViewOnlyBox from '../../../../../Shared/Components/Input/ViewOnlyBox'
import PagedTableDrawer from '../../../General/PagedTableDrawer/PagedTableDrawer'
import { cancelationOrderKeys, trusteeGrantKeys, trusteeGrantKeysShort, trusteeOrderKeys } from '../../../StaticData/tableTypes'
import { formatDate } from '../../../../../Shared/Utilities'
import { IC_USER_PROFILE_PURPLE } from '../../../../../Assets'
import { toJS } from 'mobx'

interface BenCardProps {
    beneficiary: TrusteeBeneficiaries
}

const TrusteeBeneficiaryCard = (props: BenCardProps) => {

    const { trusteeStore } = useRootStore()

    // const bnficri = trusteeStore?.trusteeBeneficiaries?.filter(ben => ben.beneficiaryId === props.benId)[0]

    const bnficri = props.beneficiary

    console.log('Ben card DATA', toJS(bnficri))

    if (bnficri === undefined) <></>

    const grantsColums: TableColumn<TrusteeGrant>[] = [
        { label: 'Grant number', name: 'grantNumber' },
        { label: 'Grant type', name: 'awardType' },
        { label: 'Grant date', name: 'grantDate' },
        { label: 'Granted', name: 'granted' },
        { label: 'Unvested', name: 'unVested' },
        { label: 'Exercisable', name: 'exercisable' },
        { label: 'Outstanding', name: 'outstanding' }
    ]


    return (
        <div style={cardStyle}>
            <div className={cardStyle.cardContainer}>
                <div className={cardStyle.beneficiary}>
                    <img className={cardStyle.icon} src={IC_USER_PROFILE_PURPLE} alt="" />
                    <h1>{bnficri?.fullName}</h1>
                </div>

                <div className={cardStyle.detailsContainer}>

                    <div className={cardStyle.row}>
                        <div className={cardStyle.details}>
                            <ViewOnlyBox value={bnficri?.firstName} label={'First name'} />
                        </div>
                        <div className={cardStyle.details}>
                            <ViewOnlyBox value={bnficri?.lastName} label={'Last name'} />
                        </div>
                        <div className={cardStyle.details}>
                            <ViewOnlyBox value={bnficri?.employeeNumber} label={'Employee number'} />
                        </div>
                        <div className={cardStyle.details}>
                            <ViewOnlyBox value={bnficri?.mobile} label={'Mobile'} />
                        </div>
                        <div className={cardStyle.details}>
                            <ViewOnlyBox value={bnficri?.businessEmail} label={'Buisness email'} />
                        </div>
                        <div className={cardStyle.details}>
                            <ViewOnlyBox value={bnficri?.site} label={'Site'} />
                        </div>
                    </div>

                    <div className={cardStyle.row}>
                        <div className={cardStyle.details}>
                            <ViewOnlyBox value={bnficri?.idSSN} label={'ID'} />
                        </div>
                        <div className={cardStyle.details}>
                            <ViewOnlyBox value={bnficri?.gender ? 'Male' : 'Female'} label={'Gender'} />
                        </div>
                        <div className={cardStyle.details}>
                            <ViewOnlyBox value={bnficri?.employeeStatus} label={'Employee status'} />
                        </div>
                        <div className={cardStyle.details}>
                            <ViewOnlyBox value={formatDate(bnficri?.startDate)} label={'Start date'} />
                        </div>
                        <div className={cardStyle.details}>
                            <ViewOnlyBox value={formatDate(bnficri?.endDate)} label={'End date'} />
                        </div>
                    </div>
                </div>


                {/* <TableDrawer
                    tableColumns={grantsColums}
                    tableData={trusteeStore.beneficiaryGrants}
                    header={'Grants'}
                /> */}

                <PagedTableDrawer
                    headers={trusteeGrantKeysShort}
                    tableData={trusteeStore.beneficiaryGrantsTable.grants}
                    totalRows={trusteeStore.beneficiaryGrantsTable.numberOfRows}
                    newDataReq={trusteeStore.GetTrusteeBenficiaryGrantsByParams}
                    header='Beneficiary grants'
                />
                {/* <TableDrawer
                    tableColumns={ordersColumns}
                    tableData={trusteeStore.beneficiaryOrders}
                    header={'Orders'}
                /> */}
                <PagedTableDrawer
                    headers={trusteeOrderKeys}
                    tableData={trusteeStore.beneficiaryOrdersTable.orders}
                    totalRows={trusteeStore.beneficiaryOrdersTable.totalNumberOfOrders}
                    newDataReq={trusteeStore.GetTrusteeBenficiaryOrdersByParams}
                    header='Beneficiary orders'
                />
                {/* <TableDrawer
                    tableColumns={cancelationsColumns}
                    tableData={trusteeStore.beneficiaryCancelations}
                    header={'Cancelations'}
                /> */}

                <PagedTableDrawer
                    headers={cancelationOrderKeys}
                    tableData={trusteeStore.beneficiaryCancelationsTable.orders}
                    totalRows={trusteeStore.beneficiaryCancelationsTable.totalNumberOfOrders}
                    newDataReq={trusteeStore.GetTrusteeBeneficiaryCancelationsByParams}
                    header='Beneficiary Cancelations'
                />
            </div>
        </div>
    )
}

export default observer(TrusteeBeneficiaryCard)