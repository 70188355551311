import React, { useEffect, useRef, useState } from 'react'
import PagedTable, { PagedTableKeys } from '../../../../Shared/Components/Table/PagedTable/PagedTable'
import useRootStore from '../../../../Shared/Hooks/useRootStore'
import { OrderByGrantTable, TrusteeGrant } from '../../Types/trusteeTypes'
import { observer } from 'mobx-react-lite'
import { PagedTableReqParams, TrusteeFileReq } from '../../Types/ReqRes'
import DropFiles from '../../../../Shared/Components/DropFiles/DropFiles'
import Button from '../../../../Shared/Components/Button/Button'
import { useHistory } from 'react-router-dom'
import { IC_REPORT, IC_SUBMIT_FILE } from '../../../../Assets'
import Select from '../../../../Shared/Components/Select/Select'
import TextInput from '../../../../Shared/Components/Input/TextInput'
import { trusteeFileTypes } from '../../StaticData/dropDownValues'
import { Loader } from '../../../../Shared/Components'
import useTrusteeModals from '../../useTrusteeModals'

import PageStyles from '../../Dashboard/AdditionalViews/AdditionalViews.module.css'


const SubmitFiles = () => {

    const history = useHistory()
    const { showSuccessModal } = useTrusteeModals()

    const { trusteeStore } = useRootStore()

    const clearRef = useRef<{ clearAll: () => void }>()

    // const [initialRows, setInitialRows] = useState<number>(1)

    const [fileName, setFileName] = useState<string>()
    const [fileComments, setFileComments] = useState<string>()
    const [fileType, setFileType] = useState<number>()
    const [droppedFile, setDroppedFile] = useState<string>()
    const [fileExtension, setFileExtension] = useState<string>()

    const [loader, setLoader] = useState(false)
    const [typeError, setTypeError] = useState(false)

    // const grantKeys: PagedTableKeys<TrusteeGrant>[] = [
    //     { key: 'grantNumber', displayValue: 'Grant number', entityEnumValue: OrderByGrantTable.GrantNumber, searchable: true },
    //     { key: 'beneficiaryName', displayValue: 'Beneficiary', entityEnumValue: OrderByGrantTable.BeneficiaryName, searchable: true },
    //     { key: 'awardType', displayValue: 'Grant type', entityEnumValue: OrderByGrantTable.AwardType },
    //     { key: 'grantDate', displayValue: 'Grant date', entityEnumValue: OrderByGrantTable.GrantDate },
    //     { key: 'granted', displayValue: 'Granted', entityEnumValue: OrderByGrantTable.Granted },
    //     { key: 'unVested', displayValue: 'Unvested', entityEnumValue: OrderByGrantTable.UnVested },
    //     { key: 'exercisable', displayValue: 'Exercisable', entityEnumValue: OrderByGrantTable.Exercisable },
    //     { key: 'saleable', displayValue: 'Saleable', entityEnumValue: OrderByGrantTable.Saleable },
    //     { key: 'exercised', displayValue: 'Exercised', entityEnumValue: OrderByGrantTable.Exercised },
    //     { key: 'sold', displayValue: 'Sold', entityEnumValue: OrderByGrantTable.Sold },
    //     { key: 'canceled', displayValue: 'Canceled', entityEnumValue: OrderByGrantTable.Canceled },
    //     { key: 'outstanding', displayValue: 'Outstanding', entityEnumValue: OrderByGrantTable.Outstanding }
    // ]

    // const getStuffByParams = async (params: PagedTableReqParams) => {

    //     const data = await trusteeStore.GetTrusteeGrantsByParamsAsync(params)
    //     return data
    // }

    const convertFileToBase64 = async (file: File) => {
        let binary = ''

        const abFile = await file.arrayBuffer()
        const bytes = new Uint8Array(abFile)
        const length = bytes.byteLength

        for (let i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i])
        }

        const base64string = window.btoa(binary)

        return base64string
    }

    const fileDroppedHandler = async (file: File) => {
        const base64File = await convertFileToBase64(file)
        setDroppedFile(base64File)

        const filePath = file.name.split('.')
        setFileName(filePath[0])
        setFileExtension(filePath[1])
    }

    // let clear = false

    const onFileTypeSelect = (type: number) => {
        setFileType(type)
        setTypeError(false)
    }

    const submitFile = async () => {

        if (!fileType) {
            setTypeError(true)

            return
        } else if (!droppedFile || !fileExtension || !fileName) return

        setLoader(true)

        const fileReq: TrusteeFileReq = {
            submitFilesToTrusteeType: fileType,
            comments: fileComments,
            file: droppedFile,
            fileName: fileName,
            fileType: fileExtension
        }

        console.log('Before RES', fileReq)
        // setClearFile(true)



        const res = await trusteeStore.TrusteeFileRequest(fileReq)
        console.log('FILE  RES', res)

        if (res.data) {
            clearRef.current?.clearAll()
            showSuccessModal('The file has been submitted to your trustee')
        }

        setLoader(false)
    }


    return (
        <>
            <div className={PageStyles.mainContainer}>

                <div className={PageStyles.btnHeader}>
                    <Button
                        qaid='trustee.orders.back'
                        label='Back'
                        inverse
                        cancel
                        onClick={() => history.push('/trustee')}
                    />
                </div>

                <div className={PageStyles.pageBody}>
                    <div className={PageStyles.pageHeader}>

                        <div className={PageStyles.headerMain}>
                            <img src={IC_SUBMIT_FILE} />
                            <h3>Submit files to trustee</h3>
                        </div>
                    </div>
                    <div className={PageStyles.importFiles}>
                        <div className={PageStyles.importInput}>
                            <Select
                                label='Select the file type from the following closed list'
                                onChange={onFileTypeSelect}
                                value={fileType}
                                options={trusteeFileTypes}
                                qaid=''
                                defaultValue='Please select file type...'
                            // style={typeError ? { borderBottom: '1px solid red', borderRadius: '10px' } : {}}
                            />
                        </div>
                        <div style={{ width: '100%', marginLeft: '4rem' }}>
                            <TextInput
                                label='Comments (optional)'
                                onChange={val => setFileComments(val)}
                                value={fileComments}
                                qaid=''
                            />
                        </div>
                    </div>

                    <div className={PageStyles.importContainer}>
                        <DropFiles onFileDropped={fileDroppedHandler} ref={clearRef} />
                        {loader && <Loader />}
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                        {typeError && <p style={{ color: 'red' }}>Please select file type</p>}
                        <div className={PageStyles.button}>
                            <Button
                                qaid='trustee.orders.back'
                                label='Submit'
                                onClick={submitFile}
                                disabled={loader || !!!droppedFile}
                            />
                        </div>

                    </div>

                </div>
                {/* <PagedTable
                tableData={trusteeStore.trusteeGrantsTable.grants}
                // tableKeys={grantKeys}
                totalRows={trusteeStore.trusteeGrantsTable.numberOfRows}
                onSort={params => getStuffByParams(params)}
                newDataReq={params => getStuffByParams(params)}
                headerChild={<>ASDFGSAGD</>}
            /> */}
            </div>
        </>
    )
}

export default observer(SubmitFiles)

const mock = [
    {
        "beneficiaryName": "Arnaud Gouachon",
        "grantId": "0x00000000000103FE",
        "beneficiaryId": "0x0000000000008EF5",
        "planName": "Sales Company- Legal Plan - 2019",
        "awardType": "Options",
        "grantDate": "2016-04-01T00:00:00",
        "grantNumber": "1006",
        "granted": 2000,
        "expirationDate": "2027-01-01T00:00:00",
        "erercisePrice": 1.12,
        "exxercisePriceCurrency": "USD",
        "taxTrack": "NQ",
        "department": "",
        "site": "Sales ROW",
        "vestingSchedule": "4 X 1 Year - 25 % ",
        "vestingStartDate": "2016-04-01T00:00:00",
        "unVested": 0,
        "exercisable": 2000,
        "outstanding": 2000,
        "saleable": 0,
        "sold": 0,
        "canceled": 0,
        "exercised": 0,
        "vestings": null
    },
    {
        "beneficiaryName": "Arnaud Gouachon",
        "grantId": "0x0000000000010423",
        "beneficiaryId": "0x0000000000008EF5",
        "planName": "Sales Company- Legal Plan - 2019",
        "awardType": "RSU",
        "grantDate": "2017-02-28T00:00:00",
        "grantNumber": "1043",
        "granted": 17200,
        "expirationDate": "2027-01-01T00:00:00",
        "erercisePrice": 0,
        "exxercisePriceCurrency": "USD",
        "taxTrack": "NQ",
        "department": "",
        "site": "Sales ROW",
        "vestingSchedule": "4 X 1 Year - 25 % ",
        "vestingStartDate": "2017-02-28T00:00:00",
        "unVested": 0,
        "exercisable": 8600,
        "outstanding": 8600,
        "saleable": 8600,
        "sold": 8600,
        "canceled": 0,
        "exercised": 8600,
        "vestings": null
    },
    {
        "beneficiaryName": "Arnaud Gouachon",
        "grantId": "0x0000000000010477",
        "beneficiaryId": "0x0000000000008EF5",
        "planName": "Sales Company- Legal Plan - 2019",
        "awardType": "Options",
        "grantDate": "2019-12-01T11:11:52",
        "grantNumber": "1049",
        "granted": 3254,
        "expirationDate": "2029-09-01T00:00:00",
        "erercisePrice": 4.55,
        "exxercisePriceCurrency": "USD",
        "taxTrack": "NQ",
        "department": "",
        "site": "Sales ROW",
        "vestingSchedule": "4 X 1 Year - 25 % ",
        "vestingStartDate": "2019-09-01T00:00:00",
        "unVested": 0,
        "exercisable": 3254,
        "outstanding": 3254,
        "saleable": 0,
        "sold": 0,
        "canceled": 0,
        "exercised": 0,
        "vestings": null
    },
    {
        "beneficiaryName": "Barack Obama",
        "grantId": "0x000000000001048A",
        "beneficiaryId": "0x0000000000008F0B",
        "planName": "Sales Company- Legal Plan - 2019",
        "awardType": "Options",
        "grantDate": "2019-12-01T11:11:52",
        "grantNumber": "1068",
        "granted": 2356,
        "expirationDate": "2029-09-01T00:00:00",
        "erercisePrice": 4.55,
        "exxercisePriceCurrency": "USD",
        "taxTrack": "ISO",
        "department": "",
        "site": "Sales INC",
        "vestingSchedule": "4 X 1 Year - 25 % ",
        "vestingStartDate": "2019-09-01T00:00:00",
        "unVested": 0,
        "exercisable": 2356,
        "outstanding": 2356,
        "saleable": 0,
        "sold": 0,
        "canceled": 0,
        "exercised": 0,
        "vestings": null
    },
    {
        "beneficiaryName": "Barack Obama",
        "grantId": "0x0000000000010414",
        "beneficiaryId": "0x0000000000008F0B",
        "planName": "Sales Company- Legal Plan - 2019",
        "awardType": "Options",
        "grantDate": "2017-02-28T00:00:00",
        "grantNumber": "1028",
        "granted": 8546,
        "expirationDate": "2027-01-01T00:00:00",
        "erercisePrice": 1.12,
        "exxercisePriceCurrency": "USD",
        "taxTrack": "ISO",
        "department": "",
        "site": "Sales INC",
        "vestingSchedule": "4 X 1 Year - 25 % ",
        "vestingStartDate": "2017-02-28T00:00:00",
        "unVested": 0,
        "exercisable": 8546,
        "outstanding": 8546,
        "saleable": 0,
        "sold": 0,
        "canceled": 0,
        "exercised": 0,
        "vestings": null
    }
]