import styled from "@emotion/styled";
import appConfig from "../../config/config";
import { css } from "@emotion/css";
import { breakpoints } from "../../config/style/theme";

export const DashboardStyle = styled.section({
	label: "Dashboard",
	backgroundColor: appConfig.style.colors.background1,
	overflow: "auto !important",

	[`@media screen and (max-width: ${breakpoints.xs})`]: {
		visibility: "hidden",
	},

	".grid-container": {
		display: "grid",
		gap: "2.4rem",
		padding: "2.4rem 4rem",
		gridTemplateColumns: "repeat(20, 1fr)",
		gridTemplateRows: "repeat(20, 1fr)",
		minHeight: "93rem",
		maxHeight: "calc(100vh - 7.3rem)",
		minWidth: "185rem",
	},
});

export const DashboardWidgetCaptable = styled.div({
	label: "DashboardWidgetCaptable",
	gridArea: "1 / 1 / 10 / 8",
});

export const DashboardWidgetValuation = styled.div({
	label: "DashboardWidgetValuation",
	gridArea: "1 / 8 / 10 / 16",
});

export const DashboardWidgetStakeholders = styled.div({
	label: "DashboardWidgetStakeholders",
	gridArea: "1 / 16 / 8 / 22",
});

export const DashboardWidgetEquity = styled.div({
	label: "DashboardWidgetEquity",
	gridArea: "10 / 1 / 22 / 16",
});

export const DashboardWidgetUpdates = styled.div({
	label: "DashboardWidgetUpdates",
	gridArea: "8 / 22 / 22 / 16",
});
export const RectangularSkeleton = css({
	borderRadius: "4px",
});

export default DashboardStyle;
