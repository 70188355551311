import { useState } from "react"
import { NumberOption } from "../../../Models/API/All/NumberOption"

import TabStyles from './Tabs.module.css'
import { useEffectOnce } from "react-use"

interface TabsProps {
    headlines: NumberOption[]
    selectedTab?: number
    setTab: (tab: NumberOption) => any
}

interface SingleTabProps {
    headline: NumberOption
    onSelect: (tab: NumberOption) => any
    selected?: boolean
}

const tabs: NumberOption[] = [
    { label: 'Pending', value: 0 },
    { label: 'Pending Employee Signature', value: 1 },
    { label: 'Signed', value: 2 }
]

const Tabs = (props: TabsProps) => {

    const [selected, setSelected] = useState<number>()

    // const [selectedTab, setSelectedTab] = useState<{ [key: string]: boolean }>({})

    // const setTabsMap = () => {
    //     let map: {[key: string]: boolean} = {};

    //     props.headlines.forEach(hl => {
    //         map[hl.value] = props.defaultTab === hl.value
    //     })

    //     setSelectedTab(map)
    // }

    // let mapp

    // console.log('selected', selected)

    // const tabSelectionHandler = (tab: number) => {
    //     setSelectedTab(oMap => {

    //         for (let index = 0; index < array.length; index++) {
    //             const element = array[index];

    //         }
    //     })
    // }

    // useEffectOnce(() => {
    //     setTabsMap()
    // })

    const tabSelectionHandler = (tab: NumberOption) => {
        console.log('selected tab', tab)
        props.setTab(tab)
    }

    return (
        <div style={TabStyles} className={TabStyles.tabsContainer}>
            {
                props.headlines?.map(tab => (
                    <Tab
                        headline={tab}
                        onSelect={tabSelectionHandler}
                        selected={props.selectedTab === tab.value}
                    />
                ))

            }
            {/* <Tab
                headline={{ label: 'Tab 1', value: 11 }}
                onSelect={tabSelectionHandler}
                selected={selected}
            />
            <Tab
                headline={{ label: 'Tab 2', value: 22 }}
                onSelect={tabSelectionHandler}
            />
            <Tab
                headline={{ label: 'Tab 3', value: 33 }}
                onSelect={tabSelectionHandler}
            /> */}
        </div>
    )
}

const Tab = (props: SingleTabProps) => {

    const tabClickedHandler = () => {
        props.onSelect(props.headline)
    }


    return (
        <div
            style={TabStyles}
            className={`${TabStyles.singleTab} ${props.selected && TabStyles.selected}`}
            onClick={tabClickedHandler}
        >
            {props.headline.label}
        </div>
    )
}

export default Tabs