import { makeAutoObservable } from 'mobx';
import { DataCollectionService } from '../Services/DataCollectionService';
import { RootStore } from './RootStore';
import { makePersistable } from 'mobx-persist-store';
import { ValuationProject } from '../Models/API/DataCollection/valuation-project';
import { DataCollectionPayload } from '../Models/API/DataCollection/data-collection-payload';
import { DataCollectionCommentPayload } from '../Models/API/DataCollection/comment-payload';

export default class DataCollectionStore {
	dataCollectionService: DataCollectionService = new DataCollectionService();
	projects: ValuationProject[] | undefined = undefined;
	projectId: string | null = null;

	constructor(private rootStore: RootStore) {
		makeAutoObservable(this);
		makePersistable(this, {
			name: 'DataCollectionStore',
			properties: ['projects', 'projectId'],
			storage: window.sessionStorage,
			expireIn: 10800000, // 3 hours
		});
		// rootStore.companyStore.onCompanyChange.subscribe(() => {
		// 	if (!this.rootStore.companyStore.companyId) return;
		// 	// on change
		// });
	}

	set currentProjectId(id: string | null) {
		this.projectId = id;
	}

	get currentProjectId() {
		return this.projectId;
	}

	resetValuationProject() {
		this.currentProjectId = null;
	}

	resetStoreToDefaultValues() {
		this.currentProjectId = null;
	}

	// Valuation Project

	async createProject() {
		const res = await this.dataCollectionService.createProject();
		if (res.data?.data) {
			this.projects?.push(res.data.data);
		}
		return res;
	}

	async getProjects() {
		const res = await this.dataCollectionService.getProjects();
		this.projects = res.data?.data;
	}

	takeProjectOwnership(valuationProjectId: string) {
		return this.dataCollectionService.takeProjectOwnership(valuationProjectId);
	}

	// Data Collection

	getDataCollection(valuationProjectId: string) {
		return this.dataCollectionService.getDataCollection(valuationProjectId);
	}

	createDataCollection(data: Partial<DataCollectionPayload>) {
		return this.dataCollectionService.createDataCollection(data);
	}

	updateDataCollection(data: DataCollectionPayload) {
		return this.dataCollectionService.updateDataCollection(data);
	}

	submitDataCollection(dataCollectionId: string, isCompleted: boolean) {
		return this.dataCollectionService.submitDataCollection(dataCollectionId, isCompleted);
	}

	// Comment

	addComment(data: DataCollectionCommentPayload) {
		return this.dataCollectionService.addComment(data);
	}
}
