import i18n from "./Middleware/i18n";
import { REGEX_EMAIL, formatNumber, isNullOrUndefined } from "./Utilities";

export type ValidationLevel = "warning" | "error" | undefined | null;
export type ValidationResult = {
    isValid: boolean;
    message?: string;
    level?: ValidationLevel;
};
export type ValidationPair = { prop: string; error: string };
export type ValidationCallback<T, P extends keyof T> = (value: T[P]) => ValidationResult;

export type ObjectValidationDescriptor<Type> = {
    [Property in keyof Type]+?: ValidationCallback<Type, Property> | Array<ValidationCallback<Type, Property>>;
};

export type ObjectValidationStatus<Type> = {
    [Property in keyof Type]+?: ValidationResult;
};
export default class ObjectValidator<T extends object> {
    private validationSchema: ObjectValidationDescriptor<T>;
    validationState: ObjectValidationStatus<T>;
    isValid: boolean = true;

    constructor(validationSchema: ObjectValidationDescriptor<T>) {
        this.validationSchema = validationSchema;
        this.validationState = {};
    }

    validate(input: T) {
        const validators = Object.entries(this.validationSchema) as [
            string,
            ValidationCallback<T, any> | Array<ValidationCallback<T, any>>
        ][];
        this.isValid = true;
        for (const [key, validator] of validators) {
            if (typeof validator === "function") {
                const status = validator((input as any)[key]) as ValidationResult;
                (this.validationState as any)[key] = status;

                if (status.isValid === false && (status.level === "error" || !status.level)) {
                    this.isValid = false;
                }
            } else {
                for (const subValidator of validator) {
                    const status = subValidator((input as any)[key]) as ValidationResult;
                    (this.validationState as any)[key] = status;

                    if (status.isValid === false) {
                        this.isValid = false;
                        break;
                    }
                }
            }
        }
    }

    getErrorsList() {
        const errors: Array<ValidationPair> = [];
        const validators = Object.entries(this.validationState) as [string, ValidationResult][];

        for (const [key, status] of validators) {
            if (status.isValid === false) {
                errors.push({ prop: key, error: status.message ?? "" });
            }
        }
        return errors;
    }
}

export const successResult: ValidationResult = {
    isValid: true,
    level: undefined,
};
export const minNumber = 0.000000000000001;

export const commonValidators = {
    emailValidator: (email?: string | null): ValidationResult => {
        if (email) {
            const trimmed = email?.replace(/\s/g, "");
            const isValid = REGEX_EMAIL.test(trimmed);
            if (isValid) {
                return successResult;
            } else {
                return {
                    isValid: false,
                    message: i18n.t("validations.pleaseEnterAValidEmail"),
                    level: "error",
                };
            }
        }
        return successResult;
    },

    minLength: (minLength: number, errorMessage?: string, level: ValidationLevel = "error") => {
        return (str: string | null | undefined): ValidationResult => {
            if (str) {
                if (str.length >= minLength) {
                    return successResult;
                } else {
                    return {
                        isValid: false,
                        message: errorMessage ?? `Min length is ${minLength}`,
                        level,
                    };
                }
            }
            return successResult;
        };
    },

    maxLength: (maxLength: number, errorMessage?: string, level: ValidationLevel = "error") => {
        return (val: string | null | undefined | number): ValidationResult => {
            const str = val?.toString();
            if (str) {
                if (str.length <= maxLength) {
                    return successResult;
                } else {
                    return {
                        isValid: false,
                        message: errorMessage ?? `Max length is ${maxLength}`,
                        level,
                    };
                }
            }
            return successResult;
        };
    },
    lengthRange: (minLength: number, maxLength: number, errorMessage?: string, level: ValidationLevel = "error") => {
        return (val: string | null | undefined | number): ValidationResult => {
            const str = val?.toString();
            if (str) {
                if (str.length <= maxLength && str.length >= minLength) {
                    return successResult;
                } else {
                    return {
                        isValid: false,
                        message: errorMessage ?? `Length must be between ${formatNumber(minLength)} and ${formatNumber(maxLength)}`,
                        level,
                    };
                }
            }
            return successResult;
        };
    },

    range: (min: number, max: number, errorMessage?: string, level: ValidationLevel = "error") => {
        return (val: null | undefined | number): ValidationResult => {
            if (val != null) {
                if (val >= min && val <= max) {
                    return successResult;
                } else {
                    return {
                        isValid: false,
                        message: errorMessage ?? `Value must be between ${formatNumber(min)} and ${formatNumber(max)}`,
                        level,
                    };
                }
            }
            return successResult;
        };
    },

    range2: (
        min: number,
        max: number,
        options?: {
            includeMin: boolean;
            includeMax: boolean;
        },
        errorMessage?: string,
        level: ValidationLevel = "error"
    ) => {
        return (val: null | undefined | number): ValidationResult => {
            if (
                isNullOrUndefined(val) ||
                ((val > min || (options?.includeMin && val === min)) && (val < max || (options?.includeMax && val === max)))
            ) {
                return successResult;
            }
            return {
                isValid: false,
                message:
                    errorMessage ??
                    `Value must be greater than${options?.includeMin ? " or equal to" : ""} ${formatNumber(min)} and less than${
                        options?.includeMax ? " or equal to" : ""
                    } ${formatNumber(max)}`,
                level,
            };
        };
    },

    isInt: (errorMessage?: string, level: ValidationLevel = "error") => {
        return (val: null | undefined | number): ValidationResult => {
            if (val != null) {
                if (Number.isInteger(val)) {
                    return successResult;
                } else {
                    return {
                        isValid: false,
                        message: errorMessage ?? `Only whole numbers allowed`,
                        level,
                    };
                }
            }
            return successResult;
        };
    },
    graterThan: (minValue: number, errorMessage?: string, level: ValidationLevel = "error") => {
        return (val: null | undefined | number): ValidationResult => {
            if (val != null) {
                if (val > minValue) {
                    return successResult;
                } else {
                    return {
                        isValid: false,
                        message: errorMessage ?? `Must be grater than ${formatNumber(minValue)}`,
                        level,
                    };
                }
            }
            return successResult;
        };
    },
    gte: (minValue: number, errorMessage?: string, level: ValidationLevel = "error") => {
        return (val: null | undefined | number): ValidationResult => {
            if (val != null) {
                if (val >= minValue) {
                    return successResult;
                } else {
                    return {
                        isValid: false,
                        message: errorMessage ?? `Must be grater than or equal to ${formatNumber(minValue)}`,
                        level,
                    };
                }
            }
            return successResult;
        };
    },
    lessThan: (maxValue: number, errorMessage?: string, level: ValidationLevel = "error") => {
        return (val: null | undefined | number): ValidationResult => {
            if (val !== null && val !== undefined && val <= maxValue) {
                return successResult;
            }

            return {
                isValid: false,
                message: errorMessage ?? `Must be less than ${formatNumber(maxValue)}`,
                level,
            };
        };
    },
    positive: (errorMessage?: string, level: ValidationLevel = "error") => {
        return (val: null | undefined | number): ValidationResult => {
            if (val != null) {
                if (val > 0 && val <= Number.MAX_SAFE_INTEGER) {
                    return successResult;
                } else {
                    return {
                        isValid: false,
                        message: errorMessage ?? `Only positive numbers allowed`,
                        level,
                    };
                }
            }
            return successResult;
        };
    },

    customValidation: (condition: boolean, errorMessage: string, level: ValidationLevel = "error") => {
        return (): ValidationResult => {
            if (condition) return successResult;
            return {
                isValid: false,
                message: errorMessage,
                level,
            };
        };
    },

    required: (errorMessage?: string, level: ValidationLevel = "error") => {
        return (val: string | any | null | undefined): ValidationResult => {
            const str = `${val}`;
            if ((val && str) || val === 0) {
                if (str.length > 0) {
                    return successResult;
                }
            }
            return {
                isValid: false,
                message: errorMessage ?? i18n.t("validations.requiredField"),
                level,
            };
        };
    },

    requiredIf: (predicate: (val: string | any | null | undefined) => boolean, errorMessage?: string, level: ValidationLevel = "error") => {
        return (val: string | any | null | undefined): ValidationResult => {
            if (predicate(val)) {
                const str = `${val}`;
                if ((val && str) || val === 0) {
                    if (str.length > 0) {
                        return successResult;
                    }
                }
                return {
                    isValid: false,
                    message: errorMessage ?? i18n.t("validations.requiredField"),
                    level,
                };
            } else {
                return successResult;
            }
        };
    },

    regex: (regex: RegExp, errorMessage?: string, level: ValidationLevel = "error") => {
        return (val: string | any | null | undefined): ValidationResult => {
            if (val) {
                const isValid = regex.test(val);
                if (isValid) {
                    return successResult;
                } else {
                    return {
                        isValid: false,
                        message: errorMessage ?? "Please enter a valid value",
                        level,
                    };
                }
            }
            return successResult;
        };
    },
};

export const commonRegEx = {
    letters: /(?:\p{L}+)|(?:[\u0590-\u05FF]+)/u,
};

export const getComposedRegex = (...regexes: RegExp[]) => new RegExp(regexes.map((regex) => `(${regex.source})`).join("|"));
