import { css } from "@emotion/css";
import appConfig from "../../../../config/config";

const SignDocumentStyle = css({
	label: 'SignDocument',
	position: 'fixed',
	top: 0,
	left: 0,
	height: '100%',
	width: '100%',
	background: appConfig.style.colors.background2 + '99',
	zIndex: 1060,
	display: 'flex',
	'&__preview-data': {
		maxWidth: '140rem',
		width: '90%',
		height: '80%',
		margin: 'auto',
		display: 'flex',
		alignItems: 'center',
		paddingInline: '4rem',
		gap: '11rem',
		justifyContent: 'center',
		'> embed': {
			height: '100%',
			borderRadius: 16,
			flex: 2.1,
			maxWidth: '77rem',
		},
	},
	'&__info': {
		display: 'flex',
		flexDirection: 'column',
		gap: '2.5rem',
		'&:not(.multiple)': {
			maxWidth: '40rem',
			flex: '0 0 40rem',
		},
	},
	'&__signature': {
		display: 'flex',
		flexDirection: 'column',
		background: '#ffffff',
		borderRadius: 8,
		padding: '2.5rem',
		position: 'relative',
		'.ex-text': {
			marginTop: '0.4rem',
			marginLeft: '0.4rem',
			color: appConfig.style.colors.text4,
		},
		'.tos': {
			marginTop: '1.4rem',
			marginBottom: '2rem',
		},
		'> button': {
			alignSelf: 'flex-end',
		},
		'.signature-input': {
			fontFamily: appConfig.style.fonts.fasthand,
			fontSize: '2rem',
			textTransform: 'capitalize',
		},
		'.signature-mark': {
			paddingTop: 2,
			height: '3.6rem',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			position: 'absolute',
			top: '2.5rem',
			left: '-8.4rem',
			color: '#ffffff',
			'> img': {
				position: 'absolute',
				top: 0,
				left: 0,
				zIndex: 0,
			},
			'> span': {
				zIndex: 1,
				marginLeft: '1.4rem',
				fontSize: '1.3rem',
			},
		},
		'&.multiple': {
			padding: '6rem',
			paddingBottom: '4rem',
			'.signature-mark': {
				top: '6rem',
				left: '-4.7rem',
			},
		},
	},
	'&__close-btn': {
		padding: '1.4rem',
		position: 'fixed',
		top: '1rem',
		right: '1rem',
		color: '#ffffff',
		cursor: 'pointer',
		'> svg': {
			fontSize: '2rem',
			fontWeight: 500,
		},
	},
});

export default SignDocumentStyle;
