import { ServerResponseApi } from "../Models/API/All/server-response";
import { DocumentSigner, ElectronicSignature, PendingSignaturesType } from "../Models/API/Document/eletronics-signature";
import { SendForSingaturePayload } from "../Models/API/Document/send-for-signature-payload";
import { SendForSignatureResponse } from "../Models/API/Document/send-for-signature-response";
import { DocumentTemplateData } from "../Models/API/Document/template-data";
import { DocumentTemplateInfo } from "../Models/API/Document/template-info";
import { FileExtensionEnum, TemplateTypeEnum } from "../Models/API/Document/template-type-enum";
import { DocumentTemplatePayload } from "../Models/App/Document/template-payload";
import { documentsApi, signatureApi } from "./Axios";

export class DocumentsService {
	private companyId: number = 0;

	constructor(companyId: number) {
		this.companyId = companyId;
	}

	// Template

	getTemplates() {
		return documentsApi.get<ServerResponseApi<DocumentTemplateInfo[]>>('/template/details');
	}

	getDefaultTemplate(documentType: TemplateTypeEnum, fileExtension: FileExtensionEnum) {
		return documentsApi.get<ServerResponseApi<DocumentTemplateData>>(`/template/default?documentType=${documentType}&fileExtension=${fileExtension}`);
	}

	getTemplate(templateId: string, isPreview: boolean = false) {
		return documentsApi.get<ServerResponseApi<DocumentTemplateData>>('/template', { templateId, preview: isPreview });
	}

	createTemplate(template: DocumentTemplatePayload) {
		return documentsApi.post<ServerResponseApi<DocumentTemplateInfo>>('/template', template);
	}

	updateTemplate(template: DocumentTemplatePayload) {
		return documentsApi.put<ServerResponseApi<DocumentTemplateInfo>>('/template', template);
	}

	deleteTemplate(templateId: string) {
		return documentsApi.delete<ServerResponseApi<DocumentTemplateData>>('/template', null, { templateId });
	}

	getDocument(docId: string) {
		return documentsApi.get<ServerResponseApi<DocumentTemplateData>>(`/document/${docId}`);
	}

	deleteDocument(documentId: string) {
		return documentsApi.delete<{ documentId: string; isDeleted: boolean }>('/document', undefined, { documentId });
	}

	cancelDocument(documentId: string) {
		return documentsApi.put<{ documentId: string; isCancelled: boolean }>('/document/cancel', undefined, { documentId });
	}

	getDocuments() {
		return signatureApi.get<ServerResponseApi<{
			electronicSignatures: ElectronicSignature[], pendingSignatures: PendingSignaturesType[]
		}>>('/electronicSignature/company');
	}

	signDocuments(documentIds: string[], signerName: string) {
		return signatureApi.post<ServerResponseApi<Omit<DocumentSigner, 'id' | 'auditTrails'>>>('/electronicSignature/sign', {
			documentIds,
			signerName,
		});
	}

	sendForSignature(payload: SendForSingaturePayload) {
		return signatureApi.post<ServerResponseApi<SendForSignatureResponse[]>>('/electronicSignature', payload);
	}

	getDocumentSignatures(docId: string) {
		return signatureApi.get<ServerResponseApi<ElectronicSignature>>(`/electronicSignature/document/${docId}`);
	}
}
