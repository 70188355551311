import { Investor } from "../Models/API/CapTable/financial-round";

import { action, makeAutoObservable, reaction, runInAction } from "mobx";
import { ErrorMessage, PersonCapTablePreviewInfo, ProjectsUserInfo } from "../Models/API/CapTable";

import { t } from "i18next";
import { clearPersistedStore, makePersistable } from "mobx-persist-store";
import { FinancialRound, ProjectPreviewInfo_Funding } from "../Models/API/CapTable/financial-round-response";
import { FundingSaveProductType } from "../Models/API/CapTable/funding-save-draft-request";
import { CapTableFullscreenMode, CapTableProductType, PersonTypesEnum, ProductTypeEnum, ePreRoundDataType } from "../Models/API/enums";
import IAutoCompleteUser, { IAutoCompleteRequest } from "../Models/App/AutoCompleteUser";
import CapTableService from "../Services/CapTableService";
import UserService, { escapeRegexCharacters } from "../Services/UserService";
import { FundingDashboardData } from "../Shared/Components/WidgetDashboard/FundingDashboard/FundingDashboard";
import { isDate, isNullOrUndefined } from "../Shared/Utilities";
import { RootStore } from "./RootStore";

// import { format } from "date-fns";
// import { getTimezoneOffset, zonedTimeToUtc  } from 'date-fns-tz'
// import isBefore from 'date-fns/isBefore'

// import 'moment-timezone';
// import moment from 'moment'

// moment.tz.setDefault('UTC');

export default class FundingSceStore {
    project?: ProjectPreviewInfo_Funding;
    projects?: ProjectsUserInfo[];
    capTableService: CapTableService = {} as any;
    userService: UserService = {} as any;
    lastProjectId: number = 0;
    // investors: Array<Investor> = [];
    // roundInfo?: FinancialRound;
    investmentAmount: number = 0;
    minInvestmentAmount: number = 0;
    isLoading = true;
    isEditing: boolean = true;
    hasFutureTransactions: boolean = false;
    fundingDashboardData: FundingDashboardData | null = null;
    private fullscreen: CapTableFullscreenMode = CapTableFullscreenMode.none;
    isProjectSaved: boolean = true;

    dataSource: CapTableProductType.CapTable | CapTableProductType.NextRound = CapTableProductType.CapTable;

    private _filteredDate: Date = new Date();
    public get filteredDate(): Date {
        return this._filteredDate;
    }
    public set filteredDate(value: Date) {
        this._filteredDate = value;
        // this.reloadCurrentProject();
    }

    get isValidProjectForPublish(): boolean {
        if (this.project) {
            if (this.project.optionTransactionsBalance && !this.project.optionTransactionsBalance?.isValid) {
                return false;
            }
            if (this.project.shareClassTransactionsBalanceList?.find((x) => !x.isValid)) {
                return false;
            }
            if (this.project.shareClassWithoutBalanceList?.find((x) => !x.isValid)) {
                return false;
            }
            if (this.project.shareHolderTransactionBalanceList?.find((x) => !x.isValid)) {
                return false;
            }
            if (this.project.shareClassHolderList?.find((x) => !x.isValid)) {
                return false;
            }
            return true;
        }

        return false;
    }

    get isFundingProject(): boolean {
        return this.project?.productType === ProductTypeEnum.NextRound;
    }

    get projectErrors(): ErrorMessage[] {
        if (!this.project) return [];
        const shareholderErrors: ErrorMessage[] =
            this.project.shareClassHolderList
                ?.filter((x) => !x.isValid)
                .map((person) => (person.errorShareHolderMassageList || []).map((error) => error))
                .flat() || [];

        const balanceListErrors: ErrorMessage[] =
            this.project.shareClassTransactionsBalanceList?.map((z, i) => z.errorMessageList.map((err) => err)).flat() || [];

        const optionBalanceErrors: ErrorMessage[] = this.project.optionTransactionsBalance?.errorMessageList.map((err) => err) || [];

        const shareholderTransactionErrors: ErrorMessage[] =
            this.project.shareHolderTransactionBalanceList?.map((z, i) => z.errorMessageList.map((err) => err)).flat() || [];

        const projectErrors: ErrorMessage[] = this.project?.errorMessageList?.map((err) => err) || [];

        // console.log("​FundingSceStore -> shareholderErrors", shareholderErrors);
        // console.log("​FundingSceStore -> balanceListErrors", balanceListErrors);
        // console.log("​FundingSceStore -> optionBalanceErrors", optionBalanceErrors);
        // console.log("​FundingSceStore -> shareholderTransactionErrors", shareholderTransactionErrors);
        // console.log("​FundingSceStore -> projectErrors", projectErrors);
        return [
            ...shareholderErrors,
            ...balanceListErrors,
            ...optionBalanceErrors,
            ...shareholderTransactionErrors,
            ...projectErrors,
        ].filter((error) => error);
    }

    get fullscreenMode() {
        return this.fullscreen;
    }

    setFullscreen(val: CapTableFullscreenMode) {
        this.fullscreen = val;
    }

    constructor(private rootStore: RootStore) {
        makeAutoObservable(this, {
            loadProject: action,
        });
        makePersistable(this, {
            name: "FundingStore",
            properties: ["project", "projects", "lastProjectId"],
            storage: window.sessionStorage,
            expireIn: 10800000, // 3 hours
        });
        // Track company change because services use companyID in the common values and they need to be reinstanciated after company change
        rootStore.companyStore.onCompanyChange.subscribe(this.initServices);

        reaction(
            () => this.dataSource,
            (c) => {
                this.project = undefined;
                this.loadAllUserProjects();
            }
        );
    }

    resetStoreToDefaultValues() {
        this.project = undefined;
        this.projects = undefined;
        this.investmentAmount = 0;
        // this.roundInfo = undefined;
        this.isLoading = true; // TODO: why isLoading was set to true by default
        this.isEditing = true; // TODO: why isEditing was set to true by default
        this.hasFutureTransactions = false;
        this.dataSource = CapTableProductType.CapTable;
        this.rootStore.fundingStore.initServices();
        // this.rootStore.fundingStore = new FundingSceStore(this.rootStore);
        clearPersistedStore(this);
    }

    // This will be called autmatically when company changes
    private initServices = () => {
        if (this.rootStore.companyStore.companyId) {
            this.capTableService = new CapTableService(this.rootStore.companyStore.companyId);
            this.userService = new UserService(this.rootStore.companyStore.companyId);

            RootStore.subscribeToLoading([this.capTableService], this.rootStore);
        }
    };
    init = async (setDefaultProject: boolean = true, defaultProjectId: number = 0) => {
        // const res = await this.loadUserProjects();
        const res = await this.loadAllUserProjects();

        if (!res.isSuccess) return -1;

        if (!this.projects?.length) return 0;

        // If user has projects then load first one.
        // Otherwise automatically create new porject with today date and load it

        // if (this.userProjects && this.userProjects.projectsUserList?.length) {
        // }
        let projectIdToLoad = defaultProjectId;

        // If there is published project then load it by default
        if (setDefaultProject) {
            let project = this.projects.find((project) =>
                this.lastProjectId ? project.projectID === this.lastProjectId : project.isPublished
            );
            projectIdToLoad = project?.projectID ?? this.projects[0].projectID;
        }

        await this.loadProject(projectIdToLoad);

        this.isLoading = false;
        return this.projects?.length ?? 0;
    };

    setLastProjectId(id: number) {
        this.lastProjectId = id;
    }

    loadProject = async (projectID: number) => {
        const res = await this.capTableService.loadCapTableForFundingRound(projectID);

        if (!res.isSuccess) return;

        runInAction(() => {
            this.setLastProjectId(res.data.projectID);
            const datesArray = res.data.shareClassesList?.map((sc) => +new Date(sc.shareClass?.roundDate ?? 0)) || [];
            this.filteredDate = new Date(Math.max(+new Date(res.data.filteredDate ?? 0), ...datesArray));
            this.hasFutureTransactions = this._hasFutureTransactions();

            if (this.projects?.find((x) => x.projectID === projectID && x.productType === ProductTypeEnum.NextRound)) {
                this.isEditing = true;
            }
            const showWOshares = res.data.shareClassHolderList?.find(
                (x) => x.personID === -1 && (x.numberOfSharesOutstanding || x.numberOfWarrants || x.converted || x.options)
            );

            const personsList = showWOshares
                ? [
                      ...(res.data.personsList || []),
                      {
                          firstName: t("captable.withOrWithoutOthers"),
                          personID: -1,
                          sourceUserID: -1,
                      },
                  ]
                : res.data.personsList;

            this.project = {
                ...res.data,
                personsList,
                shareClassHolderList: showWOshares
                    ? res.data.shareClassHolderList
                    : res.data.shareClassHolderList?.filter((p) => p.personID !== -1),
            };
            this.isProjectSaved = true;
        });
    };

    getUsersAutoComplete(query: IAutoCompleteRequest): Array<IAutoCompleteUser> {
        const escapedValue = escapeRegexCharacters((query.email ?? query.firstName ?? query.lastName ?? "").trim());

        if (escapedValue === "") {
            return [];
        }
        const regex = new RegExp("\\b" + escapedValue, "i");
        const mapper = (x: PersonCapTablePreviewInfo) => {
            return {
                email: x.email ?? "",
                firstName: x.firstName ?? "",
                lastName: x.lastName ?? "",
                userId: x.personID ?? 0,
                dialingCode: "",
                phoneNumber: "",
            };
        };
        if (query.email) {
            return this.project?.personsList?.filter((x) => regex.test(x.email ?? "")).map((x) => mapper(x)) ?? [];
        } else if (query.firstName) {
            return this.project?.personsList?.filter((x) => regex.test(x.firstName ?? "")).map((x) => mapper(x)) ?? [];
        } else if (query.lastName) {
            return this.project?.personsList?.filter((x) => regex.test(x.lastName ?? "")).map((x) => mapper(x)) ?? [];
        }

        return [];
    }

    // Daniel: to remove
    // loadUserProjects = async () => {
    // 	const res = await this.capTableService.GetAllProjectsByUser(this.rootStore.auth.user.userId, this.dataSource);
    // 	if (res.data.projectsUserList) {
    // 		this.projects = res.data.projectsUserList;
    // 	}
    // 	return res;
    // };

    loadAllUserProjects = async () => {
        const res = await this.capTableService.GetAllProjectsByUser(this.rootStore.auth.user.userId, CapTableProductType.All);
        if (res.isSuccess) {
            this.projects = res.data.projectsUserList?.sort((a, b) => (b.isPublished ? 1 : 0) - (a.isPublished ? 1 : 0)) ?? [];
        }
        return res;
    };

    // createNewProject = async (sourceProject: number) => {
    //     const projectRes = await this.capTableService.DuplicateCapTableAsNextRound(sourceProject);
    //     if (projectRes.isSuccess && projectRes.data?.projectID) {
    //         await this.loadUserProjects();
    //         await this.loadAllUserProjects();
    //         await this.loadProject(projectRes.data.projectID);

    //     }
    // };
    reloadCurrentProject = async () => {
        if (this.lastProjectId) {
            await this.loadProject(this.lastProjectId);
        }
    };

    publishProject = async (projectId: number, name: string, date: Date) => {
        const res = await this.capTableService.SaveSnapshotAsCaptable(projectId, name, FundingSaveProductType.Publish);

        if (res.data) {
            await this.loadAllUserProjects();
            await this.loadProject(res.data.projectID ?? 0);
        }
    };

    saveAsDraftProject = async (snapshotId: number, name: string, saveToFunding: boolean) => {
        const res = await this.capTableService.SaveSnapshotAsCaptable(
            snapshotId,
            name,
            saveToFunding ? FundingSaveProductType.NextRound : FundingSaveProductType.CapTable
        );

        if (res.isSuccess) {
            this.isProjectSaved = true;
            await this.loadAllUserProjects();
            if (res.data.projectID) {
                await this.loadProject(res.data.projectID);
            }
        }
    };

    addInvestor = async (investor: Investor) => {
        let shareholder = {
            firstName: investor.firstName ?? "",
            lastName: investor.lastName ?? "",
            email: investor.email ?? "",
            userID: investor.sourceUserID,
            contactId: investor.contactId,
        } as any;

        if (investor.personTypeEnum === PersonTypesEnum.LegalCompany) {
            shareholder = {
                firstName: "Not provided",
                lastName: "Not provided",
                organizationName: investor.organizationName,
                isLegalEntity: true,
                userID: investor.sourceUserID,
                contactId: investor.contactId,
            };
        }

        if (investor.sourceUserID) {
            await this.userService.updateUserCompany(shareholder);
        } else {
            const res = await this.capTableService.getOrAddShareHolderAdHoc2(shareholder, 0);

            investor.projectPersonID = res.projectPersonID ?? 0;
            investor.sourceUserID = res.userID ?? 0;
        }

        return investor;

        // const existingInvestorIndex = this.investors.findIndex((x) => x.sourceUserID === investor.sourceUserID);
        // if (existingInvestorIndex > -1) {
        // 	this.investors = [...this.investors.slice(0, existingInvestorIndex), investor, ...this.investors.slice(existingInvestorIndex + 1)];
        // } else {
        // 	this.investors = [...this.investors, investor];
        // }
    };

    async runFinancingRound(round: FinancialRound) {
        const res = await this.capTableService.runFinancingRound(round);

        if (res.isSuccess) {
            // this.project = res.data;
            this.setLastProjectId(res.data.projectID);

            const datesArray = res.data.shareClassesList?.map((sc) => +new Date(sc.shareClass?.roundDate ?? 0)) || [];
            this.filteredDate = new Date(Math.max(+new Date(res.data.filteredDate ?? 0), ...datesArray));
            this.hasFutureTransactions = this._hasFutureTransactions();

            const showWOshares = res.data?.shareClassHolderList?.some(
                (holder) =>
                    holder.personID === -1 &&
                    (holder.numberOfSharesOutstanding || holder.numberOfWarrants || holder.converted || holder.options)
            );

            if (showWOshares) {
                this.project = {
                    ...res.data,
                    personsList: [
                        ...(res.data.personsList || []),
                        {
                            firstName: t("captable.withOrWithoutOthers"),
                            personID: -1,
                            sourceUserID: -1,
                        },
                    ],
                };
            } else {
                this.project = res.data;
            }

            await this.loadAllUserProjects();
        }
        return res;
    }
    _hasFutureTransactions = () => {
        if (isNullOrUndefined(this.project) || !isDate(this.project.filteredDate)) return false;
        // let r = moment().startOf('day');
        // let r2 = moment(this.project!.filteredDate)
        // console.log(`r = ${r}, r2 = {${r2}}`);
        //   return moment().startOf('day').isBefore(moment(this.project!.filteredDate))
        const currentLocalDate = new Date();
        return new Date().setHours(0, -currentLocalDate.getTimezoneOffset(), 0, 0) < this.project.filteredDate.getTime();

        // const filter_date = zonedTimeToUtc(!!this.project?.filteredDate ? this.project.filteredDate : new Date())
        // const result = isBefore(filter_date, (new Date().setHours(0,0,0)));
        // return result;
    };

    getFundingDashboardData = async (props?: any) => {
        const res = await this.capTableService.GetFundingDashboard(props);

        if (res.isSuccess) {
            const data = res.data.data;
            this.fundingDashboardData = data;
            return this.fundingDashboardData;
        }
    };

    updateInstrument = (id: number, include: boolean, isUpdate?: boolean) => {
        if (isUpdate) {
            const instrumentIdx = this.project?.previousFundingRound?.preRoundDataList.findIndex((data) => data.id === id) ?? -1;
            if (instrumentIdx === -1 || !this.project?.previousFundingRound) return;
            this.project = {
                ...this.project,
                previousFundingRound: {
                    ...this.project.previousFundingRound,
                    preRoundDataList: [
                        ...this.project.previousFundingRound.preRoundDataList.slice(0, instrumentIdx),
                        {
                            ...this.project.previousFundingRound.preRoundDataList[instrumentIdx],
                            include,
                        },
                        ...this.project.previousFundingRound.preRoundDataList.slice(instrumentIdx + 1),
                    ],
                },
            };
        } else {
            const instrumentIdx = this.project?.preRoundDataList?.findIndex((data) => data.id === id) ?? -1;
            if (instrumentIdx === -1 || !this.project?.preRoundDataList) return;
            this.project = {
                ...this.project,
                preRoundDataList: [
                    ...this.project.preRoundDataList.slice(0, instrumentIdx),
                    {
                        ...this.project.preRoundDataList[instrumentIdx],
                        include,
                    },
                    ...this.project.preRoundDataList.slice(instrumentIdx + 1),
                ],
            };
        }
    };

    getFundingSnapshot = async (snapshotId?: number, isUpdate?: boolean) => {
        if (!this.project?.previousFundingRound || !snapshotId) return;
        const res = await this.capTableService.getFundingSnapshot(snapshotId);

        if (res.data.preRoundDataList) {
            if (isUpdate) {
                this.project.previousFundingRound.preRoundDataList = this.project.previousFundingRound.preRoundDataList.map((dataList) => {
                    const d = res.data.preRoundDataList?.find((x) => x.instrument === dataList.instrument);
                    return d
                        ? {
                              ...d,
                              include: dataList.include,
                          }
                        : dataList;
                });
            } else {
                this.project.preRoundDataList = this.project.preRoundDataList?.map((dataList) => {
                    const d = res.data.preRoundDataList?.find((x) => x.instrument === dataList.instrument);
                    return d
                        ? {
                              ...d,
                              include: dataList.include,
                          }
                        : dataList;
                });
            }
        }

        return res.data.preRoundDataList;
    };
}
