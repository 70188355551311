export interface IOrder {
	contactId: number;
	date: Date;
	grantNumber: string;
	amount: number;
	buyerFirstName: string | null;
	buyerLastName: string | null;
	buyerEmail: string | null;
	sellPrice: number;
	exercisePrice: number;
	orderId: number;
	currency: number;
	grantId: number;
	orderType: OrderType;
	isSecondary: boolean;
	note: string | null;
	orderNumber: string | number | null;
	employeeNumber?: string;
	employeeFirstName?: string;
	employeeLastName?: string;
	fullName: { firstName: string, lastName: string };
}

export interface OrderTypeAndAmount {
	amount: number;
	orderType: OrderType;
}

export enum OrderType {
	Sell,
	Exercise,
	SDS,
}


