import { Chart as ChartJS, ChartOptions, registerables } from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import { useEffect, useRef } from "react";
import { Scatter } from "react-chartjs-2";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { IC_RESET } from "../../../../../../../../Assets";
import Image from "../../../../../../../../Shared/Components/Image";
import { colors } from "../../../../../../../../config/style/lib/colors";
import { ScatterChartPoint, ScatterChartProps } from "../../deps/types";

export function removeLineOnSlopeZero(points: ScatterChartPoint[]) {
    const filteredArray = points.filter((point, index, array) => {
        if (index === 0 || index === array.length - 1) {
            return true;
        }

        const previousPoint = array[index - 1];
        const nextPoint = array[index + 1];

        return point.y !== previousPoint.y || point.y !== nextPoint.y;
    });

    return filteredArray.reduce((acc: ScatterChartPoint[][], point) => {
        if (acc.length === 0 || acc[acc.length - 1].some((p) => p.y === point.y)) {
            acc.push([point]);
        } else {
            acc[acc.length - 1].push(point);
        }

        return acc;
    }, []);
}

const ValuationCardScatterChart = (props: ScatterChartProps) => {
    const scatterRef = useRef<ChartJSOrUndefined<"scatter", ScatterChartPoint[], unknown> | null>();
    ChartJS.register(...registerables, zoomPlugin);
    // ChartJS.defaults.font = {
    //     size: appState.fontSize
    // }

    const dataVisible = props.data.filter(bp => !bp.hide);
    const datasets = removeLineOnSlopeZero([{ x: 0, y: 0 }, ...props.data]);
    const MAX_POINT_X = Math.max(...dataVisible.map(d => d.x), props.exitValuePoint.x);
    const MAX_POINT_Y = Math.max(...dataVisible.map(d => d.y), props.exitValuePoint.y);

    const GRAPH_TRESHHOLD = MAX_POINT_X / 100 * 9;

    const options: ChartOptions<"scatter"> = {
        scales: {
            x: {
                max: MAX_POINT_X + GRAPH_TRESHHOLD,
                beginAtZero: true,
                type: "linear",
                title: {
                    display: true,
                    text: props.xAxisLabel,
                    font: {
                        size: 14,
                    },
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: props.yAxisLabel,
                    font: {
                        size: 14,
                    },
                },
                max: MAX_POINT_Y + GRAPH_TRESHHOLD,
            },
        },
        plugins: {
            legend: {
                display: false,

            },
            zoom: {
                zoom: {
                    wheel: {
                        enabled: true,
                        speed: 0.1
                    },
                },
                limits: {
                    y: {
                        min: 0,
                        max: MAX_POINT_Y + GRAPH_TRESHHOLD,

                    },
                    x: {
                        min: 0,
                        max: MAX_POINT_X + GRAPH_TRESHHOLD
                    },
                },
                pan: {
                    enabled: true,
                    mode: "xy",
                    scaleMode: "x",
                },
            },
        },
    };

    const datasetsConfig = datasets.map((dataset, index) => ({
        label: `dataset-${index + 1}`,
        data: dataset,
        borderColor: colors.primary,
        pointBackgroundColor: colors.action,
        pointBorderColor: colors.action,
        pointRadius: 3,
        showLine: true,
        showPoint: true,
    }));

    const data = {
        datasets: [
            {
                label: "exitValue",
                data: [props.exitValuePoint],
                borderColor: colors.primary,
                pointBackgroundColor: colors.success,
                pointBorderColor: colors.success,
                pointRadius: 3,
                showLine: true,
                showPoint: true,
            },
            ...datasetsConfig,
        ],
    };

    return (
        <div style={{ position: 'relative' }}>
            <Scatter
                ref={scatterRef}
                data={data}
                options={options}
            />
            <Image
                src={IC_RESET}
                pointer
                tooltip="Reset zoom"
                width="4rem"
                style={{ position: 'absolute', bottom: 63, right: 28, zIndex: 3 }}
                onClick={() => scatterRef.current?.resetZoom()}
            />
        </div>
    );
};

export default ValuationCardScatterChart;
